import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function ActionStepsGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(80, 61);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const background = isDarkTheme ? '#333333' : '#F1F1F1';
	const gray1 = isDarkTheme ? '#BBBBBB' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#444444' : '#EAEAEA';
	const gray3 = isDarkTheme ? '#444444' : '#D3D3D3';
	const gray4 = isDarkTheme ? '#444444' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={background}
					d="M.959 32.004a11.794 11.794 0 0 0 6.493 8.974c4.202 2.011 9.197 1.332 13.66 2.677 5.784 1.737 10.004 6.603 15.133 9.807 6.107 3.812 13.379 5.17 20.464 6.46 4.703.855 9.69 1.684 14.145-.047 6.083-2.364 9.664-9.791 7.722-16.02-3.295-10.567-18.149-13.407-22.612-23.535-2.306-5.246-1.493-11.852-5.208-16.22-4.397-5.18-12.503-4.377-19.113-2.786-5.027 1.201-10.01 2.59-15.001 3.962-4.035 1.107-8.256 2.319-11.256 5.236-3.708 3.605-2.218 6.431-2.19 10.665.035 3.734-2.751 6.74-2.237 10.827z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray3}
					d="M18.097 58.627h35.679V10.714H18.097v47.913z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray1}
					d="M19.437 57.491h32.998V11.863H19.437v45.628z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray3}
					d="M29.66 10.66v1.623c0 .896.727 1.623 1.623 1.623h8.713c.897 0 1.623-.727 1.623-1.623V10.66H29.66z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M24.301 48.097h5.223v-5.222h-5.223v5.222zm-.273.272h5.767v-5.765h-5.767v5.765z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M27.056 47.075a.408.408 0 0 1-.314-.148l-1.78-2.152a.407.407 0 1 1 .628-.518L27 45.963l2.553-4.284a.408.408 0 0 1 .7.417l-2.849 4.779a.406.406 0 0 1-.319.198c-.009-.001-.019.002-.029.002z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M24.302 25.465h5.223v-5.222h-5.223v5.222zm-.271.271h5.767v-5.765h-5.767v5.765z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M27.058 24.823a.402.402 0 0 1-.314-.148l-1.78-2.152a.407.407 0 1 1 .629-.518l1.411 1.706 2.554-4.282a.408.408 0 0 1 .7.417l-2.849 4.78a.406.406 0 0 1-.319.198l-.032-.001z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M33.682 21.878h7.52v-.563h-7.52v.563zM45.886 22.128h1.708v-.563h-1.708v.563zM33.682 24.643h7.52v-.562h-7.52v.562zM45.046 24.643h2.548v-.562h-2.548v.562zM45.5 23.386h2.094v-.563H45.5v.563zM39.24 23.386h2.095v-.563H39.24v.563zM33.682 23.386h4.654v-.563h-4.654v.563zM24.302 37.105h5.223v-5.222h-5.223v5.222zm-.271.271h5.767v-5.764h-5.767v5.764z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M27.058 36.167a.402.402 0 0 1-.314-.148l-1.78-2.151a.409.409 0 0 1 .629-.519l1.411 1.707 2.554-4.284a.408.408 0 0 1 .7.417l-2.849 4.779a.405.405 0 0 1-.319.197c-.013.002-.022.002-.032.002z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M33.682 32.917h7.52v-.563h-7.52v.563zM45.886 32.917h1.708v-.563h-1.708v.563zM33.682 35.424h7.52v-.562h-7.52v.562zM45.046 35.424h2.548v-.562h-2.548v.562zM45.5 34.167h2.094v-.563H45.5v.563zM39.24 34.167h2.095v-.563H39.24v.563zM33.682 34.167h4.654v-.563h-4.654v.563zM33.682 43.894h7.52v-.562h-7.52v.562zM45.883 43.894h1.708v-.562h-1.708v.562zM33.682 46.41h7.52v-.563h-7.52v.563zM45.042 46.41h2.548v-.563h-2.548v.563zM45.498 45.145h2.094v-.563h-2.094v.563zM39.237 45.145h2.095v-.563h-2.095v.563zM33.682 45.145h4.654v-.563h-4.654v.563z"
				/>
			</Svg>
		</View>
	);
}
