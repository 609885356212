import * as React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Switch } from '../switch';
import BaseInputLabel from './BaseInputLabel';

export interface IInputSwitchProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	onInputChange?: (value: boolean) => void;
}

export default function InputSwitch<T extends FieldValues>({
	label,
	control,
	name,
	onInputChange,
}: IInputSwitchProps<T>) {
	const styles = useInputSwitchScreenStyles();

	const {
		field: { onChange: onFieldChange, value },
	} = useController({
		name,
		control,
	});

	const onPressSwitch = () => {
		onFieldChange(!value);
		if (onInputChange) {
			onInputChange(!value);
		}
	};

	return (
		<View style={styles.switchRow}>
			<Switch
				accessibilityLabel={label}
				aria-label={label}
				value={Boolean(value)}
				onValueChange={onPressSwitch}
			/>
			<View style={styles.switchRowLabel}>
				<BaseInputLabel>{label}</BaseInputLabel>
			</View>
		</View>
	);
}

const useInputSwitchScreenStyles = () => {
	return StyleSheet.create({
		switchRow: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		switchRowLabel: {
			paddingLeft: 10,
			flexGrow: 1,
			flexShrink: 1,
			flexBasis: 0,
		},
	});
};
