import React from 'react';
import { MenuStackScreenNavigationProp } from './types';

const ModalNavigationContext = React.createContext<MenuStackScreenNavigationProp | undefined>(
	undefined
);

function ModalNavigationProvider({
	children,
	value,
}: {
	children: React.ReactNode;
	value: MenuStackScreenNavigationProp;
}) {
	return (
		<ModalNavigationContext.Provider value={value}>{children}</ModalNavigationContext.Provider>
	);
}

function useModalNavigation() {
	const context = React.useContext(ModalNavigationContext);
	if (context === undefined) {
		throw new Error('useModalNavigation must be used within a ModalNavigationProvider');
	}
	return context;
}

export { ModalNavigationProvider, useModalNavigation };
