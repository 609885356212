import * as React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

export default function ButtonRow({ children, style, ...viewProps }: ViewProps) {
	const buttonArray = React.Children.toArray(children);
	const styles = useStyles();

	return (
		<View style={[styles.row, style]} {...viewProps}>
			{buttonArray.map((child, index) => (
				<View key={index} style={styles.rowItem}>
					{child}
				</View>
			))}
		</View>
	);
}

function useStyles() {
	const gap = 12;

	return StyleSheet.create({
		row: {
			marginLeft: -gap,
			marginTop: -gap,
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		rowItem: {
			paddingLeft: gap,
			paddingTop: gap,
			flexGrow: 1,
			flexShrink: 0,
			minWidth: '50%',
			maxWidth: '100%',
		},
	});
}
