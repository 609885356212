import { assessmentQueryKey } from '@mobe/api/assessment/AssessmentApiHooks';
import { getAssessment } from '@mobe/api/assessment/AssessmentService';
import Box from '@mobe/components/box/Box';
import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useQueryClient } from 'react-query';
import {
	OnboardingIntroScreenNavigationProp,
	OnboardingIntroScreenRouteProp,
} from '../OnboardingStackScreen';
import { assessmentScreenSequence } from '../assessmentScreenSequence';
import OnboardingIntroGraphic from '../components/graphics/OnboardingIntroGraphic';
import { useOnboardingContext } from '../useOnboardingContext';

interface IOnboardingIntroScreenProps {
	navigation: OnboardingIntroScreenNavigationProp;
	route: OnboardingIntroScreenRouteProp;
}

export default function OnboardingIntroScreen({ navigation }: IOnboardingIntroScreenProps) {
	const { constrainText, constrain, vr } = useStyleHelpers();
	const { t } = useTranslation();
	const parentNavigation = navigation.getParent();
	const onboardingContext = useOnboardingContext();
	const queryClient = useQueryClient();

	useFocusEffect(
		React.useCallback(() => {
			queryClient.prefetchQuery(assessmentQueryKey, getAssessment, { staleTime: Infinity });

			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: undefined,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	function handleContinuePress() {
		navigation.navigate(assessmentScreenSequence[0]);
	}

	function handleSkipPress() {
		if (parentNavigation?.canGoBack()) {
			parentNavigation.goBack();
		} else {
			navigation.replace('MENU_STACK');
		}
	}

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			footer={
				<Button title={t('onboarding.introScreen.startButton')} onPress={handleContinuePress} />
			}
		>
			<View style={{ justifyContent: 'space-between', flex: 1 }}>
				<View
					style={{
						marginBottom: 'auto',
						marginTop:
							Platform.OS === 'web' || onboardingContext === 'activity' ? 'auto' : undefined,
					}}
				>
					<Heading
						accessibilityAutoFocus
						level="h1"
						align="center"
						style={[constrainText(300), vr(8)]}
					>
						{t('onboarding.introScreen.heading')}
					</Heading>
					<View style={[constrain(250), vr(4)]}>
						<OnboardingIntroGraphic />
					</View>
					<Text size="lg" weight="medium" align="center" style={[constrainText(325), vr(8)]}>
						{t('onboarding.introScreen.description')}
					</Text>
				</View>
				{onboardingContext === 'initial' && (
					<Box>
						<Text weight="semiBold" align="center">
							{t('onboarding.introScreen.skipText')}
						</Text>
						<TextButton
							title={t('onboarding.introScreen.skipButton')}
							align="center"
							onPress={handleSkipPress}
						/>
					</Box>
				)}
			</View>
		</ScreenTemplateWithFooter>
	);
}
