import { useAssessmentMutation, useAssessmentQuery } from '@mobe/api/assessment/AssessmentApiHooks';
import LikertScaleInput from '@mobe/components/input/InputLikertScale';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingSleepScreenNavigationProp,
	OnboardingSleepScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import OnboardingSleepGraphic from '../components/graphics/OnboardingSleepGraphic';
import useShouldDelayMutation from '../useShouldDelayMutation';

interface IOnboardingSleepScreenProps {
	navigation: OnboardingSleepScreenNavigationProp;
	route: OnboardingSleepScreenRouteProp;
}

export default function OnboardingSleepScreen({ route }: IOnboardingSleepScreenProps) {
	const shouldDelay = useShouldDelayMutation();
	const assessmentMutation = useAssessmentMutation(shouldDelay);
	const assessmentQuery = useAssessmentQuery();
	const value = assessmentQuery.data?.sleep;
	const { t } = useTranslation();
	const inputLabels = t('onboarding.sleepScreen', { returnObjects: true });

	function handleOnPress(value: number) {
		assessmentMutation.mutate({ sleep: value });
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={<OnboardingSleepGraphic />}
			value={value}
			isLoading={assessmentMutation.isLoading}
		>
			<LikertScaleInput
				{...inputLabels}
				value={value}
				onPress={handleOnPress}
				accessibilityAutoFocus
			/>
		</OnboardingScreenTemplate>
	);
}
