import Button from '@mobe/components/button/Button';
import { Callout } from '@mobe/components/callout';
import { InputPassword, useControlledInputProps } from '@mobe/components/input';
import { PasswordRequirementsList } from '@mobe/components/passwordRequirementsList';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { validatePassword } from '@mobe/utils/validationUtils';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	SettingsEditPasswordScreenNavigationProp,
	SettingsEditPasswordScreenRouteProp,
} from '../SettingsStackScreen';
import useSettingsEditPasswordScreenController from './useSettingsEditPasswordScreenController';

export interface ISettingsEditPasswordScreenProps {
	navigation: SettingsEditPasswordScreenNavigationProp;
	route: SettingsEditPasswordScreenRouteProp;
}

export default function SettingsEditPasswordScreen({
	navigation,
	route,
}: ISettingsEditPasswordScreenProps) {
	const controller = useSettingsEditPasswordScreenController({ navigation, route });
	const { t } = useTranslation();

	const currentPasswordInputProps = useControlledInputProps({
		name: 'currentPassword',
		control: controller.form.control,
	});

	const newPasswordInputProps = useControlledInputProps({
		name: 'newPassword',
		control: controller.form.control,
	});

	const canSubmit =
		validatePassword(newPasswordInputProps.value).valid && currentPasswordInputProps.value;

	// Focus first form field for a11y
	useFocusEffect(
		React.useCallback(() => {
			setTimeout(() => {
				controller.form.setFocus('currentPassword');
			}, 500);
		}, [controller.form.setFocus])
	);

	return (
		<ScreenTemplateWithFooter
			footer={
				<Button
					title={t('settings.editPassword.submitButton')}
					disabled={!canSubmit}
					loading={controller.isFetching}
					onPress={controller.handleSubmitPress}
				/>
			}
		>
			{controller.errorMessage.length > 0 && (
				<Callout type="error" message={controller.errorMessage} />
			)}
			<InputPassword
				label={t('settings.editPassword.currentPasswordInputLabel')}
				textContentType="password"
				showVisibilityToggle
				enterKeyHint="next"
				onSubmitEditing={() => controller.form.setFocus('newPassword')}
				errorMessage={controller.form.formState.errors.currentPassword?.message}
				{...currentPasswordInputProps}
			/>
			<InputPassword
				label={t('settings.editPassword.newPasswordInputLabel')}
				textContentType="newPassword"
				showVisibilityToggle
				enterKeyHint="go"
				onSubmitEditing={controller.handleSubmitPress}
				errorMessage={controller.form.formState.errors.newPassword?.message}
				{...newPasswordInputProps}
			/>
			<PasswordRequirementsList password={newPasswordInputProps.value || ''} />
		</ScreenTemplateWithFooter>
	);
}

export function useSettingsEditPasswordScreenOptions() {
	const { t } = useTranslation();

	return ({ navigation }: ISettingsEditPasswordScreenProps): StackNavigationOptions => ({
		title: t('settings.editPassword.screenTitle'),
		headerRight: undefined,
	});
}
