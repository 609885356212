import * as React from 'react';
import Text, { TextProps } from '../text/Text';

export default function BaseInputError({ children, ...textProps }: TextProps) {
	const hasChildren = Boolean(children);

	return (
		<Text
			accessibilityElementsHidden={!hasChildren}
			importantForAccessibility={hasChildren ? 'yes' : 'no-hide-descendants'}
			color="error"
			weight="medium"
			{...textProps}
		>
			{/* A single blank space renders in absence of text in order to displace the exact same line height */}
			{children || ' '}
		</Text>
	);
}
