import { useEffect, useState } from 'react';

export type SecureStoreState<T> = {
	value: T | undefined;
	loadValue: () => Promise<void>;
	setValue: (newValue: T) => Promise<void>;
	removeItem: () => Promise<void>;
};

export default function useSecureStoreState<T>(key: string) {
	const [value, setValue] = useState<T>();

	async function loadItem() {
		try {
			const item = await localStorage.getItem(key);
			if (item) {
				setValue(JSON.parse(item));
			}
		} catch (error) {
			console.warn(error);
		}
	}

	async function setItem(newValue: T) {
		if (newValue === undefined) {
			return;
		}

		try {
			await localStorage.setItem(key, JSON.stringify(newValue));
			setValue(newValue);
		} catch (error) {
			console.warn(error);
		}
	}

	async function removeItem() {
		try {
			await localStorage.removeItem(key);
			setValue(undefined);
		} catch (error) {
			console.warn(error);
		}
	}

	useEffect(() => {
		loadItem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { value, loadValue: loadItem, setValue: setItem, removeItem };
}
