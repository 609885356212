import IconButton from '@mobe/components/iconButton/IconButton';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useAlert } from '@mobe/utils/useAlert';
import useTransitions from '@mobe/utils/useTransitions';
import {
	CompositeNavigationProp,
	getFocusedRouteNameFromRoute,
	RouteProp,
} from '@react-navigation/native';
import {
	createStackNavigator,
	StackNavigationOptions,
	StackNavigationProp,
} from '@react-navigation/stack';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingStackScreenNavigationProp,
	OnboardingStackScreenRouteProp,
} from '../navigation/modal/types';
import * as OnboardingAnalyticsEvents from './analyticsEvents';
import { assessmentScreenSequence } from './assessmentScreenSequence';
import OnboardingActivityScreen from './screens/OnboardingActivityScreen';
import OnboardingConfirmationScreen from './screens/OnboardingConfirmationScreen';
import OnboardingIntroScreen from './screens/OnboardingIntroScreen';
import OnboardingMedicationScreen from './screens/OnboardingMedicationScreen';
import OnboardingNotesScreen from './screens/OnboardingNotesScreen';
import OnboardingNutritionScreen from './screens/OnboardingNutritionScreen';
import OnboardingSleepScreen from './screens/OnboardingSleepScreen';
import OnboardingStressScreen from './screens/OnboardingStressScreen';
import { OnboardingProvider } from './useOnboardingContext';

export type OnboardingContext = 'initial' | 'activity';

export interface IOnboardingStackScreenParams {
	context: OnboardingContext;
}

export type OnboardingParamList = {
	ONBOARDING_INTRO_SCREEN: undefined;
	ONBOARDING_ACTIVITY_SCREEN: undefined;
	ONBOARDING_NUTRITION_SCREEN: undefined;
	ONBOARDING_SLEEP_SCREEN: undefined;
	ONBOARDING_STRESS_SCREEN: undefined;
	ONBOARDING_MEDICATION_SCREEN: undefined;
	ONBOARDING_NOTES_SCREEN: undefined;
	ONBOARDING_CONFIRMATION_SCREEN: undefined;
};

type OnboardingStackRouteProps<T extends keyof OnboardingParamList> = RouteProp<
	OnboardingParamList,
	T
>;
type OnboardingStackNavigationProps<T extends keyof OnboardingParamList> = CompositeNavigationProp<
	StackNavigationProp<OnboardingParamList, T>,
	OnboardingStackScreenNavigationProp
>;

export type OnboardingScreenNavigationProp = OnboardingStackNavigationProps<
	keyof OnboardingParamList
>;

export type OnboardingIntroScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_INTRO_SCREEN'>;
export type OnboardingIntroScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_INTRO_SCREEN'>;

export type OnboardingActivityScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_ACTIVITY_SCREEN'>;
export type OnboardingActivityScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_ACTIVITY_SCREEN'>;

export type OnboardingNutritionScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_NUTRITION_SCREEN'>;
export type OnboardingNutritionScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_NUTRITION_SCREEN'>;

export type OnboardingSleepScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_SLEEP_SCREEN'>;
export type OnboardingSleepScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_SLEEP_SCREEN'>;

export type OnboardingStressScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_STRESS_SCREEN'>;
export type OnboardingStressScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_STRESS_SCREEN'>;

export type OnboardingMedicationScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_MEDICATION_SCREEN'>;
export type OnboardingMedicationScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_MEDICATION_SCREEN'>;

export type OnboardingNotesScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_NOTES_SCREEN'>;
export type OnboardingNotesScreenRouteProp = OnboardingStackRouteProps<'ONBOARDING_NOTES_SCREEN'>;

export type OnboardingConfirmationScreenNavigationProp =
	OnboardingStackNavigationProps<'ONBOARDING_CONFIRMATION_SCREEN'>;
export type OnboardingConfirmationScreenRouteProp =
	OnboardingStackRouteProps<'ONBOARDING_CONFIRMATION_SCREEN'>;

interface IOnboardingStackScreenProps {
	navigation: OnboardingStackScreenNavigationProp;
	route: OnboardingStackScreenRouteProp;
}

const Onboarding = createStackNavigator<OnboardingParamList>();

export default function OnboardingStackScreen({ navigation, route }: IOnboardingStackScreenProps) {
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();
	const transitions = useTransitions();

	const activeRoute = (getFocusedRouteNameFromRoute(route) ||
		'ONBOARDING_INTRO_SCREEN') as keyof OnboardingParamList;
	const shouldShowExitAlert = assessmentScreenSequence.includes(activeRoute);

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('beforeRemove', (event) => {
			if (!shouldShowExitAlert) {
				handleRemoved();
				return;
			}

			event.preventDefault();

			mobeAlert(t('onboarding.exitAlert.title'), t('onboarding.exitAlert.description'), [
				{
					text: t('onboarding.exitAlert.leave'),
					onPress: () => {
						handleRemoved();
						navigation.dispatch(event.data.action);
					},
				},
				{
					text: t('onboarding.exitAlert.stay'),
					onPress: noop,
				},
			]);
		});

		return unsubscribe;
	});

	/** Actions to perform when the screen is removed */
	function handleRemoved() {
		if (route.params?.context === 'initial' && activeRoute !== 'ONBOARDING_CONFIRMATION_SCREEN') {
			OnboardingAnalyticsEvents.assessmentFirstSignInSkip();
		}
	}

	return (
		<OnboardingProvider context={route.params?.context}>
			<Onboarding.Navigator
				initialRouteName="ONBOARDING_INTRO_SCREEN"
				screenOptions={{
					...transitions.SlideFromRight,
					headerShown: false,
					title: t('onboarding.screenTitle'),
				}}
			>
				<Onboarding.Screen name="ONBOARDING_INTRO_SCREEN" component={OnboardingIntroScreen} />
				<Onboarding.Screen name="ONBOARDING_ACTIVITY_SCREEN" component={OnboardingActivityScreen} />
				<Onboarding.Screen
					name="ONBOARDING_NUTRITION_SCREEN"
					component={OnboardingNutritionScreen}
				/>
				<Onboarding.Screen name="ONBOARDING_SLEEP_SCREEN" component={OnboardingSleepScreen} />
				<Onboarding.Screen name="ONBOARDING_STRESS_SCREEN" component={OnboardingStressScreen} />
				<Onboarding.Screen
					name="ONBOARDING_MEDICATION_SCREEN"
					component={OnboardingMedicationScreen}
				/>
				<Onboarding.Screen name="ONBOARDING_NOTES_SCREEN" component={OnboardingNotesScreen} />
				<Onboarding.Screen
					name="ONBOARDING_CONFIRMATION_SCREEN"
					component={OnboardingConfirmationScreen}
				/>
			</Onboarding.Navigator>
		</OnboardingProvider>
	);
}

export function useOnboardingStackScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();
	const subScreenStyleProps = useSubScreenStyleProps();

	return ({ navigation, route }: IOnboardingStackScreenProps): StackNavigationOptions => ({
		title: t('onboarding.screenTitle'),
		...(route.params?.context === 'initial' ? subScreenStyleProps : modalStyleProps),
		headerTitle: '',
		headerShown: true,
		headerLeft: function HeaderBackIcon() {
			return (
				<IconButton
					name="close"
					aria-label={t('accessibility.closeButtonLabel')}
					onPress={() => {
						if (navigation.canGoBack()) {
							navigation.goBack();
						} else {
							navigation.replace('MENU_STACK');
						}
					}}
					style={{ marginBottom: route.params?.context === 'initial' ? 8 : 0 }}
				/>
			);
		},
	});
}
