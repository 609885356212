import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { noop } from 'lodash';
import * as React from 'react';
import {
	Modal,
	ModalProps,
	Pressable,
	ScrollView,
	StyleSheet,
	View,
	useWindowDimensions,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Heading from '../heading/Heading';
import IconButton from '../iconButton/IconButton';

export interface IPopupProps extends ModalProps {
	heading?: string | React.ReactNode;
	children: React.ReactNode;
	showHeader?: boolean;
	footer?: React.ReactNode;
	showClose?: boolean;
	onClosePress?: () => void;
}

export default function Popup({
	heading = '',
	children,
	showHeader = true,
	footer,
	showClose = true,
	onClosePress = noop,
	...modalProps
}: IPopupProps) {
	const styles = usePopupStyles();

	function handleClosePress() {
		onClosePress();
	}

	return (
		<Modal animationType="fade" transparent statusBarTranslucent {...modalProps}>
			<View style={styles.container}>
				<Pressable style={styles.sneezeGuard} onPress={handleClosePress} />
				<View style={styles.popup}>
					{showHeader && (
						<View style={styles.popupHeader}>
							<Heading accessibilityAutoFocus level="h3" align="center">
								{heading}
							</Heading>
							{showClose && (
								<IconButton
									name="close"
									onPress={handleClosePress}
									style={styles.popupHeaderClose}
								/>
							)}
						</View>
					)}
					<ScrollView
						overScrollMode="never"
						bounces={false}
						bouncesZoom={false}
						contentContainerStyle={styles.popupBody}
						style={styles.popupScrollView}
					>
						{children}
					</ScrollView>
					{footer && <View style={styles.popupFooter}>{footer}</View>}
				</View>
			</View>
		</Modal>
	);
}

function usePopupStyles() {
	const rules = useStyleRules();
	const safeInsets = useSafeAreaInsets();
	const windowDimensions = useWindowDimensions();
	const { popupShadow } = useStyleHelpers();
	const maxHeight =
		windowDimensions.height - safeInsets.top - safeInsets.bottom - rules.spacing.appVerticalMargin;

	return StyleSheet.create({
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		sneezeGuard: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: rules.colors.sneezeGuard,
		},
		popup: {
			maxHeight,
			width: '100%',
			maxWidth: rules.spacing.maxWidthNarrow,
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: rules.colors.cardBackground,
			borderRadius: rules.borderRadius,
			...popupShadow,
		},
		popupHeader: {
			width: '100%',
			paddingVertical: 12,
			paddingHorizontal: 40,
			borderBottomColor: rules.colors.stroke,
			borderBottomWidth: 1,
		},
		popupHeaderClose: {
			position: 'absolute',
			top: 15,
			left: 15,
		},
		popupScrollView: {
			maxHeight: '100%',
			width: '100%',
		},
		popupBody: {
			paddingHorizontal: 12,
			paddingVertical: 12,
		},
		popupFooter: {
			width: '100%',
			paddingHorizontal: 12,
			paddingVertical: 16,
			borderTopColor: rules.colors.stroke,
			borderTopWidth: 1,
		},
	});
}
