import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingIntroGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(281, 281);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#A7D6E3"
					d="M241.823 237.827C266.083 212.576 281 178.282 281 140.5 281 62.904 218.096 0 140.5 0S0 62.904 0 140.5c0 48.313 24.387 90.928 61.521 116.213.223.152 180.302-18.886 180.302-18.886z"
				/>
				<Path
					fill="#73ABCC"
					d="M241.799 237.829a141.474 141.474 0 0 0 8.469-9.636L233.15 34.885a140.675 140.675 0 0 0-31.668-20.996L58.277 26.57a141.335 141.335 0 0 0-23.878 21.827l17.835 201.41a140.746 140.746 0 0 0 9.306 6.905c.223.152 180.259-18.883 180.259-18.883z"
				/>
				<Path
					fill="#73ABCC"
					d="M239.161 225.723 62.376 241.377a13.002 13.002 0 0 1-4.196-.305 11.296 11.296 0 0 1-3.675-1.603c-1.076-.731-1.958-1.634-2.595-2.657a7.456 7.456 0 0 1-1.119-3.304L33.452 37.698a7.439 7.439 0 0 1 .506-3.46c.444-1.123 1.153-2.171 2.084-3.083a11.257 11.257 0 0 1 3.342-2.233 12.974 12.974 0 0 1 4.088-1.041l176.785-15.654a12.993 12.993 0 0 1 4.204.31c1.353.33 2.604.877 3.681 1.612 1.076.734 1.957 1.641 2.593 2.667a7.478 7.478 0 0 1 1.11 3.315l17.339 195.81c.104 1.159-.073 2.331-.521 3.45s-1.157 2.162-2.088 3.071a11.28 11.28 0 0 1-3.336 2.224 13.031 13.031 0 0 1-4.078 1.037z"
				/>
				<Path
					fill="#5A95B8"
					d="M220.827 27.987 40.895 43.92 59.62 255.388l.638.447 1.263.878 178.076-16.758-18.77-211.968z"
				/>
				<Path
					fill="#FFF"
					d="M222.964 24.853 43.032 40.786l19.12 215.926.086.762 179.847-16.695-19.121-215.926z"
				/>
				<Path
					fill="#6582A9"
					d="M60.998 47.079a2.096 2.096 0 0 1-1.532-.478 2.096 2.096 0 0 1-.739-1.424l-1.638-18.501a2.094 2.094 0 0 1 1.903-2.272 2.096 2.096 0 0 1 1.532.478 2.096 2.096 0 0 1 .739 1.424l1.638 18.501a2.096 2.096 0 0 1-.478 1.532 2.096 2.096 0 0 1-1.425.74zM97.415 43.854a2.096 2.096 0 0 1-1.532-.478 2.096 2.096 0 0 1-.74-1.424l-1.638-18.501a2.094 2.094 0 0 1 1.903-2.272 2.096 2.096 0 0 1 1.532.478 2.096 2.096 0 0 1 .739 1.424l1.638 18.501a2.058 2.058 0 0 1-.478 1.531 2.096 2.096 0 0 1-1.424.741zM133.833 40.629a2.094 2.094 0 0 1-2.045-1.116 2.082 2.082 0 0 1-.227-.786l-1.638-18.501a2.096 2.096 0 0 1 2.716-2.186 2.08 2.08 0 0 1 1.23 1.03c.127.245.205.512.228.786l1.638 18.501a2.1 2.1 0 0 1-.47 1.531 2.127 2.127 0 0 1-1.432.741zM170.25 37.404a2.096 2.096 0 0 1-1.532-.478 2.1 2.1 0 0 1-.513-.638 2.1 2.1 0 0 1-.227-.786l-1.638-18.501a2.098 2.098 0 0 1 3.947-1.156c.127.244.204.512.227.786l1.638 18.501a2.066 2.066 0 0 1-.47 1.53 2.114 2.114 0 0 1-1.432.742zM206.667 34.18a2.096 2.096 0 0 1-1.532-.478 2.096 2.096 0 0 1-.74-1.424l-1.638-18.501a2.095 2.095 0 0 1 1.902-2.272 2.094 2.094 0 0 1 2.045 1.116c.127.244.204.512.227.786l1.639 18.501a2.083 2.083 0 0 1-.471 1.531 2.123 2.123 0 0 1-1.432.741z"
				/>
				<Path
					fill="#E9EBEE"
					d="m113.261 112.667-36.473 10.197L66.579 86.35l36.482-10.197 10.2 36.514zm-34.711 7.096 31.637-8.843-8.852-31.636-31.637 8.843 8.852 31.636zM162.232 73.042l-34.548 3.059.358 4.032 34.547-3.059-.357-4.032zM207.802 77.404l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032zM208.54 85.735l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032zM209.278 94.068l-79.38 7.029.357 4.033 79.38-7.029-.357-4.033zM210.016 102.4l-79.38 7.029.357 4.033 79.38-7.029-.357-4.033zM117.76 180.197l-37.728 3.341-3.342-37.736 37.728-3.341 3.342 37.736zm-35.445.614 32.718-2.897-2.898-32.727-32.718 2.897 2.898 32.727z"
				/>
				<Path
					fill="#7CC5AB"
					d="m98.678 172.9-15.427-12.113 3.621-4.62 9.866 7.751 13.269-25.476 5.204 2.703L98.678 172.9z"
				/>
				<Path
					fill="#E9EBEE"
					d="m167.959 137.719-34.547 3.06.357 4.032 34.547-3.059-.357-4.033zM213.53 142.082l-79.38 7.029.357 4.033 79.38-7.03-.357-4.032zM214.267 150.414l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032zM215.005 158.746l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032zM215.743 167.078l-79.38 7.029.357 4.033 79.38-7.029-.357-4.033zM123.487 244.875l-37.728 3.341-3.342-37.736 37.728-3.341 3.342 37.736zm-35.445.615 32.719-2.898-2.898-32.727-32.719 2.898 2.898 32.727z"
				/>
				<Path
					fill="#7CC5AB"
					d="m104.406 237.578-15.428-12.113 3.622-4.612 9.866 7.742 13.268-25.475 5.204 2.703-16.532 31.755z"
				/>
				<Path
					fill="#E9EBEE"
					d="m173.686 202.398-34.547 3.059.357 4.032 34.547-3.059-.357-4.032zM219.257 206.76l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032zM219.994 215.092l-79.379 7.029.357 4.033 79.38-7.03-.358-4.032zM220.732 223.424l-79.38 7.029.357 4.033 79.38-7.03-.357-4.032zM221.47 231.756l-79.38 7.029.357 4.032 79.38-7.029-.357-4.032z"
				/>
			</Svg>
		</View>
	);
}
