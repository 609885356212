import { Platform } from 'react-native';
import { VictoryThemeDefinition } from 'victory-native';
import { useStyleRules } from './styleRules/StyleRulesProvider';

export const BAR_MAX_WIDTH = 12;

export function getBarWidth(props: {
	barRatio?: any;
	scale?: any;
	data?: any;
	defaultBarWidth?: any;
}) {
	// These are the default calculations for barWidth from victory
	// https://github.com/FormidableLabs/victory/blob/main/packages/victory-bar/src/bar-helper-methods.js
	try {
		const { scale, data, defaultBarWidth } = props;
		const range = scale.x.range();
		const extent = Math.abs(range[1] - range[0]);
		const bars = data.length + 2;
		const barRatio = props.barRatio || 0.5;
		const defaultWidth = barRatio * (data.length < 2 ? defaultBarWidth : extent / bars);

		// This is what victory would return
		const barWidth = Math.max(1, defaultWidth);

		// Use victory bar width but make sure it can only get to max width we want
		return Math.min(barWidth, BAR_MAX_WIDTH);
	} catch (error) {
		return BAR_MAX_WIDTH;
	}
}

export default function useChartTheme() {
	const styleRules = useStyleRules();
	const labelColor = styleRules.colors.text;

	// Android using default sans-serif font because it was filling in the 4's strange with 'custom font'
	const fontFamily = Platform.OS !== 'android' ? styleRules.fontFamilies.sans : 'sans-serif';

	const chartTheme: VictoryThemeDefinition = {
		bar: {
			style: {
				data: {
					fill: styleRules.colors.success,
				},
				labels: {
					fill: labelColor,
					fontFamily,
				},
			},
		},

		// X axis
		axis: {
			style: {
				axis: { stroke: styleRules.colors.strokeLight },
				grid: { stroke: 'transparent' },
				tickLabels: {
					fill: labelColor,
					fontSize: 12,
					fontFamily,
					padding: 10,
				},
			},
		},

		// Y axis
		dependentAxis: {
			style: {
				axis: { stroke: styleRules.colors.strokeLight },
				grid: { stroke: styleRules.colors.stroke, strokeDasharray: '5' },
				tickLabels: {
					fill: labelColor,
					fontSize: 12,
					fontFamily,
					padding: 10,
				},
			},
		},
	};

	return chartTheme;
}
