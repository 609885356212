import { SurveyQuestion } from '../../components/survey/Survey';
import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

interface SurveyData {
	token: string;
	questions: SurveyQuestion[];
}

export async function getSurvey(token: string) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<SurveyData>(
					`surveys/${token}/survey-questions`
				);

				return mobeStandardSuccessProcessor<SurveyData>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<SurveyData>(error);
			}
		})()
	);
}

interface ISurveyResponseWithId {
	questionId: number;
	answerId: number;
}

interface ISurveyResponseWithValue {
	questionId: number;
	answerValue: string;
}

export type SurveyResponses = (ISurveyResponseWithId | ISurveyResponseWithValue)[];

interface ISurveySubmission {
	token: string;
	responses: SurveyResponses;
}

export async function submitSurvey({ token, responses }: ISurveySubmission) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.post(`surveys/${token}/answers`, responses);

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}

export async function assignSurvey(surveyId: string) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.post<SurveyData>(`surveys/${surveyId}/assign`);

				return mobeStandardSuccessProcessor<SurveyData>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<SurveyData>(error);
			}
		})()
	);
}
