import { useLinkTo } from '@react-navigation/native';
import { Platform } from 'react-native';

export default function useRedirectLinkTo() {
	const linkTo = useLinkTo();

	function redirectTo(path: string) {
		if (path) {
			// After upgrading to RN Navigation v6.x.x having a slash in the path string here was break on web
			// not including the slash for web seems to make it work, but its still necessary for native
			linkTo(`${Platform.OS === 'web' ? '' : '/'}${path}`);
		}
	}

	return redirectTo;
}
