import { useTrackersQuery, useValidicUserPlatformsQuery } from '@mobe/api/track/trackApiHooks';
import Blocks from '@mobe/components/layout/Blocks';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import useOnBottomTabPress from '@mobe/utils/useOnBottomTabPress';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import BottomTabScreenTemplate from '../navigation/bottomTabs/components/BottomTabScreenTemplate';
import { TrackScreenNavigationProp, TrackScreenRouteProp } from '../navigation/bottomTabs/types';
import TrackerCard from './components/TrackerCard';

interface ITrackScreenProps {
	navigation: TrackScreenNavigationProp;
	route: TrackScreenRouteProp;
}

export default function TrackScreen({ navigation }: ITrackScreenProps) {
	const { wrapper } = useStyleHelpers();
	const { isLargeDisplay } = useLayout();
	const { t } = useTranslation();

	const trackersQuery = useTrackersQuery();
	const sortedTrackers = trackersQuery.isSuccess
		? [
				...trackersQuery.data.filter((tracker) => tracker.isFavorite),
				...trackersQuery.data.filter((tracker) => !tracker.isFavorite),
		  ]
		: [];

	// Prefetch and cache platform query
	useValidicUserPlatformsQuery();

	useOnBottomTabPress(navigation.getParent(), navigation.goBack);

	return (
		<BottomTabScreenTemplate screenTitle={t('track.screenTitle')}>
			<ScrollView>
				<View style={wrapper}>
					<Blocks blocksPerRow={isLargeDisplay ? 3 : 2} increment={4}>
						{sortedTrackers.map((tracker, index) => (
							<TrackerCard
								key={tracker.trackerAbbreviation}
								tracker={tracker}
								accessibilityAutoFocus={index === 0}
							/>
						))}
					</Blocks>
				</View>
			</ScrollView>
		</BottomTabScreenTemplate>
	);
}
