import { IStyleRules, IStyleRulesSettings } from './types';

const palette = {
	blue: '#1A7EAD',
	blue2: '#E7F2F8',
	blue3: '#81C9F2',
	blue4: '#315C72',
	red: '#E02200',
	green: '#20A78C',
	green2: '#008066',
	orange: '#FFB135',
	white: '#ffffff',
	gray1: '#F9F9F9',
	gray2: '#EBEBEB',
	gray3: '#D7D7D7',
	gray4: '#CCCCCC',
	gray5: '#65676A',
	gray6: '#444444',
	gray7: '#333333',
	gray8: '#2B2E30',
	gray9: '#222222',
	gray10: '#111111',
	black: '#000000',
	semiTransparent1: '#00000044',
	semiTransparent2: '#00000088',
};

export const lightThemeColors = {
	primary: palette.blue,
	primaryLight: palette.blue3,
	error: palette.red,
	success: palette.green,
	successDark: palette.green2,
	cardBackground: palette.white,
	fieldBackground: palette.white,
	fieldDisabledBackground: palette.gray1,
	background: palette.gray1,
	backgroundPrimary: palette.blue2,
	strokeLight: palette.gray2,
	stroke: palette.gray3,
	disabled: palette.gray4,
	textLight: palette.gray5,
	text: palette.gray8,
	textInverted: palette.white,
	shadow: palette.black,
	favorite: palette.orange,
	goal: palette.orange,
	sneezeGuard: palette.semiTransparent1,
};

const darkThemeColors = {
	primary: palette.blue3,
	primaryLight: palette.blue,
	error: palette.red,
	success: palette.green,
	successDark: palette.green,
	cardBackground: palette.gray9,
	fieldBackground: palette.gray9,
	fieldDisabledBackground: palette.gray6,
	background: palette.gray10,
	backgroundPrimary: palette.blue4,
	strokeLight: palette.gray7,
	stroke: palette.gray6,
	disabled: palette.gray6,
	textLight: palette.gray4,
	text: palette.white,
	textInverted: palette.gray8,
	shadow: 'transparent',
	favorite: palette.orange,
	goal: palette.orange,
	sneezeGuard: palette.semiTransparent2,
};

const staticColors = {
	staticLight: palette.white,
	staticDark: palette.gray8,
};

const maxFontScale = 2;
const maxFontSize = 30;

const fontSizes = {
	xs: 12,
	sm: 14,
	md: 16,
	lg: 18,
	xl: 20,
	xxl: 24,
	xxxl: 32,
};

const fontFamilies = {
	sans: 'inter',
	sansMedium: 'interMedium',
	sansSemiBold: 'interSemiBold',
	sansBold: 'interBold',
	serif: 'clarendon',
};

const spacing = {
	baseline: 4,
	appHorizontalMargin: 16,
	appVerticalMargin: 24,
	maxWidth: 896,
	maxWidthNarrow: 438,
	modalMaxWidth: 720,
	webNavWidth: 320,
	bottomTabBarHeight: 66,
};

const borderRadius = 5;
const borderRadiusModal = 15;

const breakpoints = {
	large: 700,
	webDesktop: 1024,
};

export default function buildStyleRules({
	colorScheme,
	fontScale,
}: IStyleRulesSettings): IStyleRules {
	// hard code lightTheme until other color schemes are defined
	const colors = {
		...(colorScheme === 'light' ? lightThemeColors : darkThemeColors),
		...staticColors,
	};
	const scale = Math.min(fontScale, maxFontScale);

	return {
		settings: {
			colorScheme,
			fontScale: scale,
		},
		colors,
		staticFontSizes: fontSizes,
		fontSizes: {
			xs: Math.min(fontSizes.xs * scale, maxFontSize),
			sm: Math.min(fontSizes.sm * scale, maxFontSize),
			md: Math.min(fontSizes.md * scale, maxFontSize),
			lg: Math.min(fontSizes.lg * scale, maxFontSize),
			xl: Math.min(fontSizes.xl * scale, maxFontSize),
			xxl: Math.min(fontSizes.xxl * scale, 32),
			xxxl: Math.min(fontSizes.xxxl * scale, 36),
		},
		fontFamilies,
		spacing,
		borderRadius,
		borderRadiusModal,
		breakpoints,
	};
}
