import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import Text from '@mobe/components/text/Text';
import useTextStyles from '@mobe/components/text/textStyles';
import env from '@mobe/env/env';
import MobeLinking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { AuthStackScreenNavigationProp } from '../../AuthenticationStackScreen';

export default function AcceptNote() {
	const { constrainText } = useStyleHelpers();
	const inlineButtonStyles = useTextStyles({ color: 'primary', size: 'sm' });
	const navigation = useNavigation<AuthStackScreenNavigationProp>();
	const { t } = useTranslation();

	function handleTermsAndConditionsPress() {
		Platform.OS === 'web'
			? MobeLinking.openUrl(env.TERMS_AND_CONDITIONS_URL)
			: navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'termsAndConditions' });
	}

	function handlePrivacyPolicyPress() {
		Platform.OS === 'web'
			? MobeLinking.openUrl(env.PRIVACY_POLICY_URL)
			: navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'privacyPolicy' });
	}

	return (
		<>
			<Text size="sm" align="center" style={constrainText(300)}>
				{t('auth.termsOfServiceIntro')}
			</Text>

			<Text size="sm" align="center" style={constrainText(300)}>
				<MobeParsedText
					parse={[
						{
							pattern: new RegExp(t('auth.termsAndConditionsButton'), 'i'),
							style: inlineButtonStyles,
							onPress: () => handleTermsAndConditionsPress(),
						},
						{
							pattern: new RegExp(t('auth.privacyPolicyButton'), 'i'),
							style: inlineButtonStyles,
							onPress: () => handlePrivacyPolicyPress(),
						},
					]}
				>
					{t('auth.termsOfServiceLinks', {
						termsAndConditionsButton: t('auth.termsAndConditionsButton'),
						privacyPolicyButton: t('auth.privacyPolicyButton'),
					})}
				</MobeParsedText>
			</Text>
		</>
	);
}
