import { remoteConfig as WebFirebaseRemoteConfig } from './firebase.web';

const remoteConfig = {
	fetchConfig: async (minimumFetchIntervalMillis?: number) => {
		if (minimumFetchIntervalMillis) {
			WebFirebaseRemoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
		}

		return await WebFirebaseRemoteConfig.fetchAndActivate();
	},

	refreshConfig: async () => {
		return await WebFirebaseRemoteConfig.fetchAndActivate();
	},

	setDefaults: async (initialDefaults: { [key: string]: string | number | boolean }) => {
		WebFirebaseRemoteConfig.defaultConfig = { ...initialDefaults };
		return Promise.resolve();
	},

	getValue: (key: string) => {
		return WebFirebaseRemoteConfig.getValue(key);
	},

	getAll: () => {
		return WebFirebaseRemoteConfig.getAll();
	},
};

export default remoteConfig;
