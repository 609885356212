import useHeadingStyles from '@mobe/components/heading/headingStyles';
import { IconNames } from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import useTransitions from '../../useTransitions';
import useStyleHelpers from '../helpers/styleHelpers';
import { useStyleRules } from '../styleRules/StyleRulesProvider';
import useNavigationTitleMaxWidth from './useNavigationTitleMaxWidth';

export default function useSubScreenStyleProps({
	backIcon = 'left',
	hasExtraIcons = false,
}: {
	backIcon?: IconNames;

	/**
	 * Set this to `true` on screens with extra header icons and no title, such as Explore Detail
	 * and Tracker Detail. If this is false, icons may be pushed off the side of the screen.
	 */
	hasExtraIcons?: boolean;
} = {}): StackNavigationOptions {
	const headerTitleStyles = useHeadingStyles({ level: 'h3' });
	const rules = useStyleRules();
	const { headerShadow } = useStyleHelpers();
	const headerTitleMaxWidth = useNavigationTitleMaxWidth();
	const transitions = useTransitions();
	const { t } = useTranslation();

	const subScreenStyleProps: StackNavigationOptions = {
		...transitions.SlideFromRight,
		headerTitleAlign: 'center',
		headerLeft: (props) => (
			<IconButton
				aria-label={t('accessibility.backButtonLabel')}
				name={backIcon}
				hitSlop={15}
				onPress={props.onPress}
				style={{ width: 25 }}
			/>
		),
		headerBackTitleVisible: false,
		headerTitleAllowFontScaling: false,
		headerTitleStyle: [headerTitleStyles, { textAlign: 'center' }],
		headerTitleContainerStyle: {
			maxWidth: headerTitleMaxWidth,

			...Platform.select({
				web: {
					alignItems: 'center',
					right: 0,
					left: 0,
				},
				android: {
					// Android squashes titles more than it needs new when they overflow.
					// This ensures that the title makes use of the entirety of the available space.
					flexGrow: 100,
				},
			}),
		},
		headerRightContainerStyle: {
			paddingRight: rules.spacing.appHorizontalMargin,

			...(hasExtraIcons && {
				flexShrink: 0,
				flexBasis: undefined,
			}),
		},
		headerLeftContainerStyle: {
			paddingLeft: rules.spacing.appHorizontalMargin,

			...(hasExtraIcons && {
				flexShrink: 0,
				flexBasis: undefined,
			}),
		},
		headerStyle: {
			backgroundColor: rules.colors.cardBackground,
			borderBottomColor: rules.colors.strokeLight,
			borderBottomWidth: StyleSheet.hairlineWidth,
			...headerShadow,
		},
		cardStyle: {
			backgroundColor: rules.colors.cardBackground,
			flex: 1,
		},
		gestureEnabled: false,
	};

	return subScreenStyleProps;
}
