import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import PaperClipGraphic from '../graphics/PaperClipGraphic';
import { Text } from '../text';

interface INoteProps {
	message: string;
	style?: StyleProp<ViewStyle>;
}

export default function Note({ message, style }: INoteProps) {
	const styles = useStyles();

	return (
		<View style={[styles.note, style]}>
			<Text>{message}</Text>
			<View style={styles.paperClip}>
				<PaperClipGraphic />
			</View>
		</View>
	);
}

const PAPER_CLIP_OFFSET = 4;

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		note: {
			backgroundColor: rules.settings.colorScheme === 'dark' ? rules.colors.strokeLight : '#FCF8EC',
			padding: 10,
			paddingLeft: 30,
			marginTop: PAPER_CLIP_OFFSET,
		},
		paperClip: {
			width: 13,
			height: 25,
			position: 'absolute',
			top: -PAPER_CLIP_OFFSET,
			left: 8,
		},
	});
}
