import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MoodGraphic } from '../graphics';
import { IInputRadioGraphic, InputRadioGraphic } from '../input';

export default function MoodPicker({ value, onSelect, label, isDisabled }: IInputRadioGraphic) {
	const { t } = useTranslation();

	return (
		<InputRadioGraphic
			onSelect={onSelect}
			value={value}
			label={label}
			isDisabled={isDisabled}
			options={[
				{
					name: 1,
					accessibilityLabel: t('trackers.mood.valueOptions.1'),
					graphic: function Graphic(isSelected) {
						return <MoodGraphic expression={1} active={isSelected} colored={!value} />;
					},
				},
				{
					name: 2,
					accessibilityLabel: t('trackers.mood.valueOptions.2'),
					graphic: function Graphic(isSelected) {
						return <MoodGraphic expression={2} active={isSelected} colored={!value} />;
					},
				},
				{
					name: 3,
					accessibilityLabel: t('trackers.mood.valueOptions.3'),
					graphic: function Graphic(isSelected) {
						return <MoodGraphic expression={3} active={isSelected} colored={!value} />;
					},
				},
				{
					name: 4,
					accessibilityLabel: t('trackers.mood.valueOptions.4'),
					graphic: function Graphic(isSelected) {
						return <MoodGraphic expression={4} active={isSelected} colored={!value} />;
					},
				},
				{
					name: 5,
					accessibilityLabel: t('trackers.mood.valueOptions.5'),
					graphic: function Graphic(isSelected) {
						return <MoodGraphic expression={5} active={isSelected} colored={!value} />;
					},
				},
			]}
		/>
	);
}
