import { yupResolver } from '@hookform/resolvers/yup';
import {
	IAppointment,
	useUpdateAppointmentMutation,
} from '@mobe/api/appointments/appointmentApiHooks';
import useUpdateCallNumberToast from '@mobe/components/toast/useUpdateCallNumberToast';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { validatePhoneNumber } from '@mobe/utils/validationUtils';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface IAppointmentPhoneNumberController {
	appointment: IAppointment;
}

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		phoneNumber: Yup.string()
			.label(t('profile.edit.alternatePhoneInputLabel'))
			.test('empty-or-valid-phone-number', t('forms.errors.phone'), (value) => {
				return !value || validatePhoneNumber(value);
			}),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function useAppointmentPhoneNumberController({
	appointment,
}: IAppointmentPhoneNumberController) {
	const updateCallNumberToast = useUpdateCallNumberToast();
	const validationSchema = useValidationSchema();
	const updateAppointmentMutation = useUpdateAppointmentMutation();
	const genericErrorAlert = useGenericErrorAlert();
	const [isInUpdateMode, setIsInUpdateMode] = React.useState(false);

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			phoneNumber: appointment.phoneNumber || '',
		},
	});

	function startPhoneNumberUpdate() {
		setIsInUpdateMode(true);

		// Keyboard doesn't appear unless the focus waits until the end of the event cycle
		setTimeout(() => {
			form.setFocus('phoneNumber');
		});
	}

	async function submit(formData: FormSchema) {
		updateAppointmentMutation.mutate(
			{
				confirmationId: appointment.confirmationId,
				updatedAppointmentPayload: {
					newPhoneNumber: formData.phoneNumber,
					isPhoneNumberTemporary: true,
				},
			},
			{
				onSuccess: () => {
					setIsInUpdateMode(false);
					updateCallNumberToast();
				},
				onError: () => {
					setIsInUpdateMode(false);
					genericErrorAlert();
				},
			}
		);
	}

	function handleSubmitPress() {
		form.handleSubmit(submit)();
	}

	return {
		form,
		handleSubmitPress,
		isInUpdateMode,
		updateAppointmentMutation,
		startPhoneNumberUpdate,
	};
}
