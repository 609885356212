import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery } from '@mobe/api/guides/guidesApiHooks';
import Button from '@mobe/components/button/Button';
import { MyGuideGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import ProfileHeader from '@mobe/components/profileHeader/ProfileHeader';
import ScreenTemplateCentered from '@mobe/components/screenTemplate/ScreenTemplateCentered';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { MyGuideScreenNavigationProp } from '../navigation/menu/types';

interface IMyGuideScreenProps {
	navigation: MyGuideScreenNavigationProp;
}

function SelectGuideView() {
	const { vr, constrainText, constrain } = useStyleHelpers();
	const navigation = useNavigation<MyGuideScreenNavigationProp>();
	const { t } = useTranslation();

	function handleSelectGuidePress() {
		GlobalAnalyticsEvents.startGuideMatchingPress();
		navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM });
	}

	return (
		<ScreenTemplateCentered>
			<View style={[vr(8), constrain(250)]}>
				<MyGuideGraphic />
			</View>
			<View style={[vr(8), constrainText(350)]}>
				<Text accessibilityAutoFocus align="center" color="light">
					{t('myGuide.selectGuideView.description')}
				</Text>
			</View>
			<Button title={t('myGuide.selectGuideView.button')} onPress={handleSelectGuidePress} />
		</ScreenTemplateCentered>
	);
}

function GuideProfileView({ navigation }: IMyGuideScreenProps) {
	const { vr, wrapperHorizontal } = useStyleHelpers();
	const { t } = useTranslation();
	const guideQuery = useGuideQuery();
	const appointmentsQuery = useAppointmentsQuery();
	const futureAppointments = appointmentsQuery.data?.futureAppointments || [];
	const apptsWithGuide = futureAppointments.filter(
		(appointment) => appointment.guideDIKey === guideQuery.data?.guideDIKey
	);

	function handleViewUpcomingAppointmentButtonPress() {
		navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
			confirmationId: apptsWithGuide[0].confirmationId,
		});
	}

	function handleViewAllUpcomingAppointmentsButtonPress() {
		navigation.navigate('CALLS_SCREEN');
	}

	function handleScheduleAppointmentButtonPress() {
		if (!guideQuery.data) {
			return;
		}

		GlobalAnalyticsEvents.startGuideSchedulingPress(guideQuery.data?.coachType);
		navigation.navigate('APPOINTMENT_SCREEN', {
			coachType: guideQuery.data?.coachType,
			allowCoachToggle: false,
		});
	}

	function renderFooterButton() {
		if (apptsWithGuide.length > 1) {
			return (
				<Button
					title={t('myGuide.guideProfileView.viewAllScheduledAppointmentsButton')}
					onPress={handleViewAllUpcomingAppointmentsButtonPress}
				/>
			);
		}

		if (apptsWithGuide.length) {
			return (
				<Button
					title={t('myGuide.guideProfileView.viewScheduledAppointmentButton')}
					onPress={handleViewUpcomingAppointmentButtonPress}
				/>
			);
		}

		return (
			<Button
				title={t('myGuide.guideProfileView.scheduleAppointmentButton')}
				onPress={handleScheduleAppointmentButtonPress}
			/>
		);
	}

	return guideQuery.isSuccess && guideQuery.data ? (
		<ScreenTemplateWithFooter fixedPositionFooter noPadding footer={renderFooterButton()}>
			<ProfileHeader
				imageSrc={guideQuery.data.avatarUrl ? { uri: guideQuery.data.avatarUrl } : undefined}
				style={vr(5)}
			/>
			<View style={[wrapperHorizontal, vr(10)]}>
				<Heading level="h3" accessibilityAutoFocus style={vr(3)}>
					{guideQuery.data.preferredName}
				</Heading>
				<Text>{guideQuery.data.bio}</Text>
			</View>
		</ScreenTemplateWithFooter>
	) : null;
}

export default function MyGuideScreen({ navigation }: IMyGuideScreenProps) {
	const guideQuery = useGuideQuery(true);

	return guideQuery.isSuccess && guideQuery.data ? (
		<GuideProfileView navigation={navigation} />
	) : guideQuery.isSuccess && !guideQuery.data ? (
		<SelectGuideView />
	) : null;
}

export function useMyGuideScreenOptions(): DrawerNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('myGuide.screenTitle'),
	};
}
