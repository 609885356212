import { Awful, Bad, Good, Great, Ok } from '@mobe/components/graphics/MoodGraphic';
import * as React from 'react';
import TrackerBarChart, { ITrackerBarChartProps } from './TrackerBarChart';

export default function MoodChart(props: ITrackerBarChartProps) {
	return (
		<TrackerBarChart
			{...props}
			padding={{ top: 20, bottom: 40, left: 0, right: 35 }}
			customYTickLabelComponent={<MoodCustomYTickLabelComponent />}
		/>
	);
}

// https://formidable.com/open-source/victory/docs/victory-axis#ticklabelcomponent
interface IVictoryTickLabelComponentProps {
	x: number;
	y: number;
	index: number;
	ticks: number[];
}

const GRAPHIC_Y_OFFSET = 12;
const GRAPHIC_SCALE = 0.4;

function MoodCustomYTickLabelComponent(props: unknown) {
	// eslint-disable-next-line react/prop-types
	const { x, y, index, ticks } = props as IVictoryTickLabelComponentProps;
	const yOffset = y - GRAPHIC_Y_OFFSET;

	switch (index) {
		case 5:
			return <Great colored x={x} y={yOffset} scale={GRAPHIC_SCALE} />;
		case 4:
			return <Good colored x={x} y={yOffset} scale={GRAPHIC_SCALE} />;
		case 3:
			return <Ok colored x={x} y={yOffset} scale={GRAPHIC_SCALE} />;
		case 2:
			return <Bad colored x={x} y={yOffset} scale={GRAPHIC_SCALE} />;
		case 1:
			return <Awful colored x={x} y={yOffset} scale={GRAPHIC_SCALE} />;
		default:
			// This is opacity zero because we don't want an icon for zero
			return <Awful colored x={x} y={yOffset} scale={GRAPHIC_SCALE} opacity={0} />;
	}
}
