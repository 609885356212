import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIErrorData } from '../APIResponse';
import notificationsService, { INotificationPreferencesResponse } from './notificationsService';

const GET_NOTIFICATIONS_PREFERENCES = 'notificationsService.getNotificationPreferences';

export function useNotificationsPreferencesQuery() {
	return useQuery<INotificationPreferencesResponse, APIErrorData>(
		GET_NOTIFICATIONS_PREFERENCES,
		async () => {
			const response = await notificationsService.getNotificationPreferences();

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		}
	);
}
export function useUpdateNotificationsPreferenceMutation() {
	const queryClient = useQueryClient();
	return useMutation<void, Error, Partial<INotificationPreferencesResponse>>(
		async (preference: Partial<INotificationPreferencesResponse>) => {
			const response = await notificationsService.updateNotificationPreferences(preference);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onMutate: async (preference) => {
				await queryClient.cancelQueries(GET_NOTIFICATIONS_PREFERENCES);

				const previousPreferences = queryClient.getQueryData<INotificationPreferencesResponse>(
					GET_NOTIFICATIONS_PREFERENCES
				);

				if (previousPreferences) {
					const optimisticUpdate = { ...previousPreferences };
					queryClient.setQueryData<INotificationPreferencesResponse>(
						GET_NOTIFICATIONS_PREFERENCES,
						{
							...optimisticUpdate,
							...preference,
						}
					);

					return optimisticUpdate;
				}

				return previousPreferences;
			},
			// Invalidate query on both success and error so that settings reflect actual preferences
			onSettled: () => {
				queryClient.invalidateQueries(GET_NOTIFICATIONS_PREFERENCES);
			},
		}
	);
}
