import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { TextStyle, ViewStyle } from 'react-native';

interface ICheckboxStyles {
	container: ViewStyle;
	checkbox: ViewStyle;
	icon: TextStyle;
	label: ViewStyle;
}

export default function useCheckboxStyles(disabled: boolean): ICheckboxStyles {
	const rules = useStyleRules();
	return {
		container: {
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'flex-start',
		},
		checkbox: {
			width: 24,
			height: 24,

			borderWidth: 2,
			borderRadius: 2,
			borderColor: disabled ? rules.colors.strokeLight : rules.colors.primary,
			backgroundColor: disabled
				? rules.colors.fieldDisabledBackground
				: rules.colors.fieldBackground,
			alignItems: 'center',
			justifyContent: 'center',
			marginRight: 8,
		},
		icon: { color: disabled ? rules.colors.textLight : rules.colors.primary, fontSize: 20 },
		label: {
			flexShrink: 1,
			flexGrow: 1,
			marginLeft: 6,
		},
	};
}
