import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export interface INotificationPreferencesResponse {
	isChatNotificationEnabled: boolean;
	isActivityNotificationEnabled: boolean;
	isAppointmentReminderEnabled: boolean;
}

const notificationsPreferencesUrl = 'push-notification-preferences';

class NotificationsService {
	async getNotificationPreferences() {
		try {
			const data = await mobeAuthenticatedAPI.get<INotificationPreferencesResponse>(
				notificationsPreferencesUrl
			);

			return mobeStandardSuccessProcessor<INotificationPreferencesResponse>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<INotificationPreferencesResponse>(error);
		}
	}
	async updateNotificationPreferences(preference: Partial<INotificationPreferencesResponse>) {
		try {
			await mobeAuthenticatedAPI.patch<unknown>(notificationsPreferencesUrl, preference);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}
}

const notificationsService = new NotificationsService();

export default notificationsService;
