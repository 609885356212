import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingNutritionGraphic(props: SvgProps) {
	const rules = useStyleRules();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(600, 287);

	return (
		<View style={[wrapperStyles, { maxWidth: rules.spacing.modalMaxWidth, width: '140%' }]}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#F9F9F9" d="M0 0h600v287H0z" />
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#A26752"
					d="m239.187 168.216-.959-6.835-14.894-3.736-.802 4.942 6.299 4.762-5.338 8.541-16.281-8.541-3.465 5.068 6.258 14.503-1.057 27.835 18.793 2.173 1.241-29.588 11.029-13.244-.824-5.88z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#6B7A4A"
					d="m186.37 154.751-.003-.002.019-.027a19.018 19.018 0 0 1 3.334-4.673l14.22-19.936c2.958-5.992 10.501-8.766 17.517-6.212 6.958 2.533 10.947 9.41 9.465 15.855-.402 1.746-.552 3.54-.661 5.326l-1.252 20.717a18.745 18.745 0 0 1-.136 2.255l-.003.068-.002-.001a19.009 19.009 0 0 1-.992 4.083c-3.89 10.687-16.531 15.898-28.236 11.638-11.703-4.26-18.039-16.377-14.149-27.065.255-.7.551-1.375.879-2.026z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#9CC569"
					d="m189.373 155.035-.003-.001.019-.026a18.073 18.073 0 0 1 2.986-4.307l12.744-18.37.001.001c2.679-5.505 9.322-8.144 15.424-5.923 6.053 2.203 9.44 8.41 8.048 14.304-.378 1.595-.537 3.23-.659 4.86l-1.404 18.889c-.015.682-.06 1.369-.153 2.054l-.005.062-.001-.001a18.28 18.28 0 0 1-.929 3.734c-3.564 9.793-14.706 14.728-24.888 11.023-10.179-3.706-15.544-14.649-11.979-24.442.233-.641.503-1.258.799-1.857z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#BB8159"
					d="M198.892 159.757c-1.958 5.379.272 11.13 4.982 12.844 4.709 1.715 10.114-1.258 12.072-6.636 1.958-5.38 1.035-14.723-3.674-16.437-4.71-1.714-11.422 4.849-13.38 10.229z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#BBBDBF"
					d="m236.584 287-2.529-75.311-29.693-4.808L193.422 287z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M199.276 285.246c0-.966-.785-1.75-1.753-1.75a1.752 1.752 0 1 0 0 3.504c.968 0 1.753-.785 1.753-1.754zM208.981 266.103a1.753 1.753 0 1 0-3.506.004 1.753 1.753 0 0 0 3.506-.004zM222.26 265.135a1.753 1.753 0 1 0-3.506.004 1.753 1.753 0 0 0 3.506-.004zM205.512 252.491a1.753 1.753 0 1 0-3.506.004 1.753 1.753 0 0 0 3.506-.004zM219.387 249.812a1.753 1.753 0 1 0-3.506.004 1.753 1.753 0 0 0 3.506-.004zM231.297 276.998a1.753 1.753 0 1 0-3.507-.001 1.753 1.753 0 0 0 3.507.001zM217.472 283.334a1.754 1.754 0 1 0-3.507 0 1.754 1.754 0 0 0 3.507 0zM233.115 263.56a1.753 1.753 0 1 0-3.507-.001 1.753 1.753 0 0 0 3.507.001zM233.115 246.478a1.753 1.753 0 1 0-3.507 0 1.754 1.754 0 1 0 3.507 0zM206.426 233.4a1.753 1.753 0 1 0-3.507 0 1.753 1.753 0 0 0 3.507 0zM227.777 229.931a1.753 1.753 0 1 0-3.507-.001 1.753 1.753 0 0 0 3.507.001zM216.034 221.924a1.753 1.753 0 1 0-3.506 0 1.753 1.753 0 0 0 3.506 0zM228.578 218.721a1.753 1.753 0 1 0-3.507 0 1.753 1.753 0 0 0 3.507 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#BBBDBF"
					d="m203.849 208.161-3.576-4.514h-5.871l2.402-5.871 5.071-2.669s2.935 0 3.469 1.334c.534 1.335 4.169 3.435 4.169 3.435l4.906 3.237 4.537-1.601 2.936-.801 3.202 2.669 3.484 1.767 2.655-2.3 3.203-1.335 4.27 1.068 3.203 4.003-.534 3.203-4.003 4.537-3.021-2.597-6.588-.072-7.74-.801-5.948-2.399-5.261-.27-4.965-.023z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M210.695 209.914a1.753 1.753 0 1 0-3.506 0 1.753 1.753 0 0 0 3.506 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#A26752"
					d="m239.187 168.217-.959-6.836-14.894-3.736-.802 4.942 6.299 4.762 11.18 6.747-.824-5.879z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E9D3B3"
					d="m305.963 186.032-7.748-68.224 8.376-21.544c-1.174-4.269-2.099-8.608-3.445-12.829-.474-1.487-.598-1.91-1.983-2.352-.869-.277-1.735-.561-2.602-.841l-13.659-4.414c-.167-.054-2.821-1.01-2.868-.927-1.173 2.072-2.604 4.122-3.576 6.27-1.006 2.223-1.887 4.509-2.814 6.766-.502 1.243-.823 1.941-.649 3.184.183 1.302 2.836 14.546 3.302 17.275l5.545 7.601-2.967 68.166 25.088 1.869z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#EB8B36"
					d="M319.499 72.815c4.01 12.487-2.863 25.861-15.349 29.87-12.488 4.01-25.862-2.863-29.871-15.35-4.01-12.487 2.863-25.861 15.35-29.87 12.486-4.01 25.86 2.863 29.87 15.35z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F1A965"
					d="M283.721 72.564a1.867 1.867 0 1 1-3.556 1.144 1.867 1.867 0 0 1 1.206-2.35 1.866 1.866 0 0 1 2.35 1.206zM290.127 76.835a1.869 1.869 0 1 1-3.56 1.14 1.869 1.869 0 0 1 3.56-1.14zM282.386 80.038a1.866 1.866 0 0 1-1.206 2.349 1.87 1.87 0 0 1-1.143-3.557 1.868 1.868 0 0 1 2.349 1.208z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7CA555"
					d="M293.102 61.926s-7.11-5.975-7.155-10.991c-.046-5.015 9.453-13.216 12.72-13.564 0 0 1.908 14.878-3.098 17.498-5.005 2.62-2.467 7.057-2.467 7.057z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#6B8948"
					d="M291.392 57.923s-3.351-4.401.162-8.667c3.513-4.263 5.602-10.282 5.602-10.282s-3.469 8.977-4.813 11.4c-1.342 2.42-1.375 4.887-.951 7.549z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#504E51"
					d="M282.733 117.063s9.292 4.332 16.471 1.411l.33 2.734s-7.454 5.02-17.439.317l.638-4.462z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FEC14D"
					d="m317.989 287-9.144-134.823-30.569-2.756L271.436 287z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E9D3B3"
					d="m278.206 108.366-7.473-7.206-.8-16.815 10.408-6.939 2.936 6.405-4.804 8.007 5.338 9.075-5.605 7.473z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E3CABE"
					d="M386.16 164.507c-.15-.693-.494-1.29-1.217-1.662-.627-.322-2.05-.827-2.752-.87-1.446-.091-2.095 1.138-3.459 1.614-1.249.435-1.201-.335-2.207-.974-1.804-1.143-3.584-.616-4.801 1.108 0 0-3.625-2.801-6.419 1.162 0 0-2.853-2.187-4.975 1.632-.953 1.714-.022 6.038.224 7.909.617 4.671 1.703 9.498 3.261 13.95.31.885 1.93 2.948 1.887 3.815-.221 4.47-.445 8.94-.667 13.41-.109 2.184-.67 4.838-.349 7.006.16 1.086-.147.856 1.104 1.534 2.277 1.239 5.192 1.732 7.748 1.834 2.242.085 4.5-.19 6.649-.836 1.142-.344 2.402-.947 2.695-2.105.145-.577.016-1.18-.072-1.767-.806-5.431-2.362-10.652-3.536-16.1l6.349-9.335v-.024c.102-3.608.206-7.215.307-10.823l.2-7.123c.03-1.039.254-2.306.03-3.355z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#00715A"
					d="M357.699 138.332s2.489 2.155 4.826.851c2.34-1.307 3.098-.757 2.42-3.839-1.258-5.722-.211-5.174 1.846-5.418 5.124-.605 2.253 2.137 2.166 5.395-.089 3.26.879 4.539 3.844 6.435 2.965 1.9 4.678.825 4.678.825s-2.054 5.648-9.004 4.422c-6.949-1.225-10.963-5.773-10.776-8.671z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#CC2D3D"
					d="M362.716 160.385c-2.101 11.913-3.613 18.824-7.097 20.443-9.601 4.461-13.48-3.608-12.527-15.903.185-2.366-.136-10.389.615-13.009 1.614-5.639 4.08-11.1 7.698-12.778 5.011-2.327 7.32-.882 8.87 1.565 5.134 8.084 4.542 7.769 2.441 19.682zM366.762 161.211c-2.101 11.915-3.044 18.926-.325 21.637 7.496 7.476 13.901 1.22 17.21-10.659.637-2.286 3.682-9.717 3.872-12.436.413-5.852-.038-11.825-2.861-14.64-3.915-3.901-6.577-3.332-8.873-1.565-7.587 5.843-6.922 5.748-9.023 17.663z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DB323E"
					d="M366.481 140.699c-1.979-.239-3.929.447-5.212 1.874-2.169 2.411-5.394 7.364-7.24 16.665-2.967 14.94.089 24.035 8.422 25.641 10.097 1.946 11.88-32.732 11.228-38.82-.41-3.841-4.347-5.015-7.198-5.36z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#95CCBF"
					d="M361.985 207.01 353.634 287h43.864l-14.852-81.704 2.81-.233v-5.843h-27.352l1.618 7.977z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E3CABE"
					d="m385.498 185.765 5.338-6.939-4.537-10.943-5.071-2.402-2.936 4.804 3.469 3.47-2.669 8.274 6.406 3.736z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E5E5E5"
					d="M457.474 92.767c-.061-.554.109-1.064.617-1.483-.209.497-.412.992-.617 1.483zM442.141 153.123c.579-.344 1.156-.693 1.727-1.047-.654.487-1.271.861-1.727 1.047z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DAA98A"
					d="m161.103 149.352 7.206-15.747-15.747-5.338-9.246 1.264-4.632 19.821v8.54l12.01 2.135 10.409-10.675z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DAA98A"
					d="M152.573 246.055c.065-6.015-.177-82.18 1.193-84.3 1.878-2.907 8.388-8.346 8.648-8.744l-.54-5.325-18.072 6.892s-6.524-9.04-6.469-8.817l-.089-.047 10.02-3.446-3.07-4.169-13.634 1.944-1.695 5.499.901 7.655 4.113 7.873c.766 1.468 1.9 2.832 2.316 4.434.635 2.442-12.219 70.954-13.859 83.493l30.237-2.942z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#C5D390"
					d="m169.378 127.825-1.179.923-11.739 9.189c-.513.401-1.503.113-1.329-.548l3.028-11.501-7.623-.278c-.197 3.408-.395 6.817-.591 10.226-.034.586-.931.981-1.396.397l-6.869-8.633-4.427 4.371a51.364 51.364 0 0 1 1.941 4.39c1.055 2.685 1.943 5.447 2.353 8.25.028.194.043.386.064.579.275 2.6-.13 5.158-.636 7.726-.784 3.975-1.811 7.928-2.996 11.833 5.03.838 10.106 2.601 15.136 3.438-.136-.022 4.659-17.315 5.181-18.656.698-1.79 1.512-3.554 2.599-5.178 2.17-3.243 5.548-6.029 8.68-8.629a119.791 119.791 0 0 1 5.452-4.275 6753.292 6753.292 0 0 1-5.649-3.624z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#758098"
					d="m155.848 287-1.137-8.765 2.655-81.373-28.566-10.004-16.568 91.318.869 8.824z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DAA98A"
					d="m137.332 145.76-.089-.046 10.02-3.446-3.07-4.169-13.634 1.944-1.695 5.499.901 7.655 4.112 7.873c.767 1.468 1.9 2.832 2.316 4.434l4.092-15.619s-3.008-4.347-2.953-4.125z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#60A680"
					d="M145.607 114.817c1.932-4.401 6.471-6.794 11.005-6.258 2.78-4.343 8.35-6.163 13.218-4.025 2.246.986 3.941 2.675 5.03 4.677 1.392.014 2.804.301 4.159.898 4.401 1.934 6.789 6.486 6.246 11.029 2.632 3.017 3.472 7.381 1.757 11.291-1.867 4.257-6.175 6.638-10.555 6.31-2.998 3.266-7.814 4.465-12.097 2.583-1.623-.713-2.939-1.809-3.977-3.102-1.654.11-3.351-.118-4.974-.831-3.969-1.744-6.323-5.62-6.353-9.696-3.94-2.89-5.502-8.213-3.459-12.876z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#60A680"
					d="M118.071 127.187c-1.605-4.545.067-9.416 3.738-12.146-.947-5.086 1.866-10.252 6.877-12.04 2.311-.825 4.701-.76 6.865-.05a10.658 10.658 0 0 1 3.647-2.207c4.53-1.618 9.386.061 12.099 3.752 3.982.393 7.582 2.999 9.009 7.036 1.553 4.392.043 9.096-3.376 11.873.051 4.449-2.639 8.642-7.046 10.215-1.672.598-3.381.703-5.022.473-1.13 1.219-2.523 2.221-4.193 2.819-4.087 1.458-8.455.25-11.267-2.705a10.636 10.636 0 0 1-11.331-7.02z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#71BE94"
					d="M130.778 113.892c.501-4.765 4.105-8.412 8.598-9.276 1.33-4.967 6.094-8.384 11.391-7.827 2.445.257 4.58 1.348 6.229 2.917a10.693 10.693 0 0 1 4.246-.406c4.791.504 8.46 4.103 9.327 8.583 3.431 2.069 5.567 5.958 5.122 10.192-.484 4.608-3.872 8.174-8.155 9.188-1.867 4.009-6.1 6.609-10.762 6.119-1.765-.186-3.357-.828-4.744-1.742-1.545.605-3.234.903-5.002.717-4.321-.454-7.751-3.423-9.023-7.283-4.641-1.552-7.758-6.134-7.227-11.182z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#9A6B57"
					d="m456.466 149.139 4.362-10.525 1.325-4.069-5.318-13.468-3.007 1.662-.011-.075-4.975-.444-2.596 5.151-2.094-2.43-4.788.753-.751 2.622-1.583-3.606-3.55 2.114 2.358 4.971-.187.068-.951 13.225.95 3.787 3.897 5.769-1.254 60.32 19.644-5.249-2.356-57.527.885-3.049z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#466099"
					d="m471.283 287-12.977-118.11-23.403-.271V287z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#C8D7A3"
					d="M461.449 56.801a7.587 7.587 0 0 0 3.953-.781c-.871.44 1.15 10.1 1.354 11.282.323 1.872 3.111 10.016-1.637 7.275-2.112-1.218-1.361-4.294-1.463-6.244-.207-3.884-1.288-7.746-2.207-11.532z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#B6C494"
					d="m466.107 77.078 1.267-30.964s2.071-2.297 3.871.978c1.799 3.275-1.987 27.603-1.987 27.603l-3.151 2.383z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#C8D7A3"
					d="m466.068 76.121 7.559-25.007s2.182-1.294 2.969 1.899c.789 3.193-7.439 22.05-7.439 22.05l-3.089 1.058z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F09641"
					d="M419.169 156.099c4.974-2.448 9.585-5.303 10.933-6.127 16.63-10.162 31.129-24.907 39.165-43.304 3.299-7.552 7.62-17.763 5.013-26.243-3.486-11.346-14.819-11.281-21.741-3.529-4.417 4.945-6.421 12.209-8.832 18.334-3.178 8.08-6.332 16.202-10.465 23.808-6.834 12.565-15.627 25.469-26.632 34.445-.961.784-2.805 3.324-2.324 4.697 1.269 3.622 8.385 1.117 14.883-2.081z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#D67F2D"
					d="M457.343 109.063a.966.966 0 0 1-.258-.025l-.02-.005c-1.812-.493-3.776-1.639-5.579-2.324-1.935-.736-3.847-1.548-5.578-2.685-4.402-2.892-4.667-3.584-2.342-7.82-.572 1.041 3.836 5.002 4.689 5.662 2.318 1.795 4.913 3.287 7.378 4.88.732.471 1.477.956 2.014 1.63.086.111.173.24.159.378-.021.206-.235.3-.463.309zM460.09 121.412a2.842 2.842 0 0 1-.682-.078c-.976-.203-2.044-.905-2.88-1.411a17.442 17.442 0 0 1-4.725-4.186c-.207-.265-.411-.551-.453-.878-.039-.327.144-.7.478-.771a.685.685 0 0 1 .1-.013c.293-.011.569.194.798.388 2.392 2.027 4.924 4.266 8.086 4.639.305.036.622.057.89.2s.469.456.364.729c-.074.191-.27.307-.446.422-.832.542-.929.934-1.53.959zM452.564 98.714c-1.177.008-2.198-.797-3.11-1.56-1.057-.883-4.491-3.29-4.789-5.229.178-.468.353-.939.533-1.412.049-.045.106-.089.163-.132 1.96 2.303 3.736 4.581 6.122 6.488.591.471 1.274 1.118 1.081 1.845zM432.559 148.409h-.029c-.987-.038-1.998-1.297-2.664-1.996a10.234 10.234 0 0 1-1.506-2.043c-.171-.305-.6-.977-.774-1.56-.066-.214-.05-.906.155-.915.057-.002.13.049.219.181 1.039 1.52 2.024 3.504 3.671 4.296.896.43 1.883.582 2.82.841-.027.026-.057.051-.087.076-.458.333-.922.662-1.387.986-.169.083-.31.129-.418.134z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#9A6B57"
					d="m462.073 134.467-5.036-12.702-9.471-2.469-1.383 5.399 5.698 2.172-.399 9.9-3.016 4.663 13.607-6.963z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#2F508A"
					d="m437.321 195.84 1.333-.054.081-24.311-1.867.076.453 24.289zM441.321 195.678l1.333-.054.348-24.322-1.845.609.164 23.767zM446.202 171.172l-.081 24.311 1.043.559.904-24.946-1.866.076zM449.07 195.737l.785-.406 1.98-23.587-1.067.044-1.698 23.949zM454.089 194.358l1.066-.044.146-22.71-1.227.05.015 22.704zM457.789 193.406l1.067-.044-.888-21.867-1.067.044.888 21.867z"
				/>
			</Svg>
		</View>
	);
}
