import { noop } from 'lodash';

export default {
	requestUserPermission: async () => await Promise.resolve(),
	setBadgeCount: noop,
	getToken: () => Promise.resolve(''),
	getIsHeadless: () => Promise.resolve(false),
	getInitialNotification: () => Promise.resolve(null),
	initForegroundMessageHandler: () => noop,
	initBackgroundAndQuitMessageHandler: noop,
	initOnNotificationOpenedAppHandler: () => noop,
	hasPermissions: () => Promise.resolve(false),
};
