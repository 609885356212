import { Pathway } from '@mobe/api/goals/goalsService';
import Icon, { IconNames, IIconProps } from '@mobe/components/icon/Icon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ICON_MAP: Record<Pathway, IconNames> = {
	[Pathway.Eat]: 'eat',
	[Pathway.Move]: 'move',
	[Pathway.Sleep]: 'sleep',
	[Pathway.Smile]: 'smile',
	[Pathway.Medication]: 'medication',
	[Pathway.Motivate]: 'motivate',
};

export interface IPathwayIcon extends Omit<IIconProps, 'name'> {
	pathway: Pathway;
}

export default function PathwayIcon({ pathway, ...iconProps }: IPathwayIcon) {
	const { t } = useTranslation();

	// Return nothing if the provided pathway doesn't exist in our record
	if (!Object.values(Pathway).includes(pathway)) {
		return null;
	}

	const pathwayLabel = t('plan.pathways', { returnObjects: true })[pathway];

	return <Icon {...iconProps} aria-label={pathwayLabel} role="img" name={ICON_MAP[pathway]} />;
}
