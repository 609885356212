import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function ActivitiesSimpleGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(70, 62);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const gray1 = isDarkTheme ? '#BBBBBB' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#444444' : '#EAEAEA';
	const gray3 = isDarkTheme ? '#444444' : '#D3D3D3';
	const gray4 = isDarkTheme ? '#444444' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={gray1}
					d="M12.5 34h45a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-45a2 2 0 0 1-2-2V36a2 2 0 0 1 2-2z"
				/>
				<Path
					fill={gray3}
					d="M57.5 59h-45c-1.654 0-3-1.346-3-3V36c0-1.654 1.346-3 3-3h45c1.654 0 3 1.346 3 3v20c0 1.654-1.346 3-3 3zm-45-24c-.551 0-1 .448-1 1v20c0 .552.449 1 1 1h45a1 1 0 0 0 1-1V36a1 1 0 0 0-1-1h-45z"
				/>
				<Path
					fill={gray2}
					d="M29.085 46.332a.813.813 0 0 0-.814.814v3.257a.816.816 0 0 1-.814.814h-6.514a.816.816 0 0 1-.814-.814v-6.515c0-.448.366-.814.814-.814h4.479a.813.813 0 1 0 0-1.628h-4.479a2.446 2.446 0 0 0-2.443 2.443v6.515a2.446 2.446 0 0 0 2.443 2.443h6.516a2.446 2.446 0 0 0 2.443-2.443v-3.257a.818.818 0 0 0-.817-.815zM34.381 43.886h10.994a.813.813 0 1 0 0-1.628H34.381a.813.813 0 1 0 0 1.628zM48.687 46.328H34.381a.813.813 0 1 0 0 1.628h14.306a.813.813 0 1 0 0-1.628zM34.381 52.027h14.277a.813.813 0 1 0 0-1.628H34.381a.813.813 0 1 0 0 1.628z"
				/>
				<Path
					fill={gray4}
					d="m31.767 39.238-7.974 7.976-1.46-1.46a.814.814 0 1 0-1.151 1.151l2.036 2.036c.158.16.366.239.576.239a.81.81 0 0 0 .576-.239l8.551-8.551a.814.814 0 0 0 0-1.151.817.817 0 0 0-1.154-.001z"
				/>
				<Path
					fill={gray1}
					d="M12.5 4h45a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2h-45a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"
				/>
				<Path
					fill={gray3}
					d="M57.5 29h-45c-1.654 0-3-1.346-3-3V6c0-1.654 1.346-3 3-3h45c1.654 0 3 1.346 3 3v20c0 1.654-1.346 3-3 3zm-45-24c-.551 0-1 .449-1 1v20c0 .551.449 1 1 1h45c.552 0 1-.449 1-1V6c0-.551-.448-1-1-1h-45z"
				/>
				<Path
					fill={gray2}
					d="M29.085 16.332a.813.813 0 0 0-.814.814v3.257a.816.816 0 0 1-.814.814h-6.514a.816.816 0 0 1-.814-.814v-6.516c0-.448.366-.814.814-.814h4.479a.813.813 0 1 0 0-1.628h-4.479a2.446 2.446 0 0 0-2.443 2.443v6.516a2.446 2.446 0 0 0 2.443 2.443h6.516a2.446 2.446 0 0 0 2.443-2.443v-3.257a.818.818 0 0 0-.817-.815zM34.381 13.886h10.994a.813.813 0 1 0 0-1.628H34.381a.813.813 0 1 0 0 1.628zM48.687 16.328H34.381a.813.813 0 1 0 0 1.628h14.306a.813.813 0 1 0 0-1.628zM34.381 22.027h14.277a.813.813 0 1 0 0-1.628H34.381a.813.813 0 1 0 0 1.628z"
				/>
				<Path
					fill={gray4}
					d="m31.767 9.238-7.974 7.976-1.46-1.46a.814.814 0 1 0-1.151 1.151l2.036 2.036c.158.16.366.239.576.239a.81.81 0 0 0 .576-.239l8.551-8.551a.814.814 0 0 0 0-1.151.817.817 0 0 0-1.154-.001z"
				/>
			</Svg>
		</View>
	);
}
