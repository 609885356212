import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useImageAcquisitionService, {
	IMediaSelection,
} from '@mobe/utils/useImageAcquisitionService';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, View } from 'react-native';
import BottomSheetModal from '../bottomSheetModal/BottomSheetModal';
import Icon from '../icon/Icon';
import Row from '../layout/Row';
import VrArray from '../layout/VrArray';
import { Text } from '../text';

interface IImageSelectSheetProps {
	isOpen: boolean;
	onClose: () => void;
	heading?: string;
	subHeading?: string;
	maxWidth?: number;
	cropToSquare?: boolean;
	onSelect: (image: string | File, mediaSelection: IMediaSelection) => void;
}

export default function ImageSelectSheet({
	isOpen,
	onClose,
	heading,
	subHeading,
	maxWidth = 600,
	cropToSquare,
	onSelect,
}: IImageSelectSheetProps) {
	const imageAcquisitionService = useImageAcquisitionService();
	const { vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();
	const [modalFocusTargetRef, setFocusToModal] = useAccessibilityFocus(1000);

	const fileInput: React.MutableRefObject<any> = React.useRef();

	const selectFromMediaLibrary = async (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
		const result = await imageAcquisitionService.getImageFromLibrary.execute(
			{
				maxWidth,
				cropToSquare,
			},
			event
		);
		processSelection(result);
	};

	const selectFromCamera = async () => {
		const result = await imageAcquisitionService.getImageFromCamera.execute({
			maxWidth,
			cropToSquare,
		});
		processSelection(result);
	};

	const processSelection = async (result: IMediaSelection | undefined) => {
		if (result === undefined) {
			return;
		}

		onClose();
		const image = result?.file ?? result?.uri;

		onSelect(image, result);
	};

	// Triggers input click on web.
	// This assumes that the provided onClose callback will reset isOpen.
	React.useEffect(() => {
		if (isOpen && Platform.OS === 'web') {
			fileInput.current.click();
			onClose();
		}
	}, [isOpen, onClose]);

	return Platform.OS === 'web' ? (
		<input
			ref={fileInput}
			style={{ display: 'none' }}
			type="file"
			onChange={(event) => {
				selectFromMediaLibrary(event);
			}}
			accept="image/png, image/jpeg"
		/>
	) : (
		<BottomSheetModal visible={isOpen} onOpen={setFocusToModal} onClosePress={onClose}>
			<View style={{ paddingHorizontal: 20, paddingVertical: 12 }}>
				{Boolean(heading || subHeading) && (
					<View style={vr(6)}>
						{Boolean(heading) && (
							<Text weight="medium" size="lg">
								{heading}
							</Text>
						)}
						{Boolean(subHeading) && <Text style={vrTop(2)}>{subHeading}</Text>}
					</View>
				)}
				<VrArray>
					<Pressable
						ref={modalFocusTargetRef}
						aria-label={t('forms.imageUpload.takePhoto')}
						role="button"
						onPress={selectFromCamera}
					>
						<Row gutterSize={8} importantForAccessibility="no-hide-descendants">
							<Row.Item>
								<Icon name="camera" color="primary" />
							</Row.Item>
							<Row.Item>
								<Text color="primary" weight="medium">
									{t('forms.imageUpload.takePhoto')}
								</Text>
							</Row.Item>
						</Row>
					</Pressable>
					<Pressable
						aria-label={t('forms.imageUpload.choosePhoto')}
						role="button"
						onPress={() => selectFromMediaLibrary(undefined)}
					>
						<Row gutterSize={8} importantForAccessibility="no-hide-descendants">
							<Row.Item>
								<Icon name="image" color="primary" />
							</Row.Item>
							<Row.Item>
								<Text color="primary" weight="medium">
									{t('forms.imageUpload.choosePhoto')}
								</Text>
							</Row.Item>
						</Row>
					</Pressable>
				</VrArray>
			</View>
		</BottomSheetModal>
	);
}
