import Analytics from '@mobe/utils/analytics';

export function visionCardMinimize() {
	Analytics.logEvent('plan_vision_card_minimize');
}

export function visionCardUploadPress() {
	Analytics.logEvent('plan_vision_card_upload_press');
}

export function visionCardMinimizedUploadPress() {
	Analytics.logEvent('plan_vision_card_minimized_press');
}

export function visionImageUpdatePress() {
	Analytics.logEvent('plan_vision_image_update_press');
}

export function goalCompleteUndoPress() {
	Analytics.logEvent('plan_goal_complete_undo');
}

export function actionStepCompleteUndoPress() {
	Analytics.logEvent('plan_action_step_complete_undo');
}

export function actionStepCompletePressHome() {
	Analytics.logEvent('plan_action_step_complete_home');
}

export function actionStepCompletePressPlan() {
	Analytics.logEvent('plan_action_step_complete_plan');
}

export function actionStepCompletePressDetail() {
	Analytics.logEvent('plan_action_step_complete_detail');
}

export function actionStepCompletePressGoal() {
	Analytics.logEvent('plan_action_step_complete_goal');
}
