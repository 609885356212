import env from '@mobe/env/env';
import crashlytics from '@mobe/utils/crashlytics';
import {
	IMobeAPIStandardError,
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardSuccessProcessor,
	mobeUnauthenticatedAPI,
} from '../mobeAPI';

// TODO: we do not use any of this data
export interface IAuthenticationProfileData {
	avatarUrl: string;
	firstName: string;
	lastName: string;
	memberDIKey: number;
	preferredName: string;
	userId: number;
}

export interface IValidicData {
	uid: string;
	validicId: string;
	marketplaceToken: string;
	marketplaceUrl: string;
	mobileToken: string;
}

export interface IAuthenticationData {
	loginMessage: string | null;
	token: string;
	tokenExpirationTimeInSeconds: number;
}

export interface IAuthenticationResponse extends IAuthenticationData {
	profileInfo: IAuthenticationProfileData;
	validicInfo: IValidicData | null;
}

export interface IAuthenticationError extends IMobeAPIStandardError {
	remaining?: number;
	message?: string;
	data?: {
		token?: string;
		resetPasswordGuid?: string;
	};
}
export enum LoginAPIErrorCode {
	None = 'NON_API_NONE',
	Mismatch = 'MISMATCH',
	AccountLocked = 'LOCKED_ACCOUNT',
	NeedsValidation = 'NOT_VALIDATED',
	AccountDisabledOnServer = 'CUSTOMER_END',
	PasswordExpired = 'EXPIRED_PASSWORD',
	// NOTE: the Unknown status code isn't provided by the API; it's used internally
	Unknown = 'NON_API_UNKNOWN',
}

export enum LogoutType {
	Manual = 'ManuallyTriggeredLogout',
	SessionExpired = 'SessionExpirationLogout',
}

enum AuthenticationFlowResponses {
	RegisteredConsumerFoundByEmail = 'RegisteredConsumerFoundByEmail',
	RegisteredConsumerFoundByPhone = 'RegisteredConsumerFoundByPhone',
	NonRegisteredConsumerFoundByPhone = 'NonRegisteredConsumerFoundByPhone',
}

interface IFindUserAccountResponse {
	authenticationFlow: AuthenticationFlowResponses;
	email: string;
}

export enum IFindUserAccountErrorCode {
	INVALID_EMAIL_OR_PHONE = 'INVALID_EMAIL_OR_PHONE',
}

export enum AccountState {
	FullyPreparedAccount = 'FullyPreparedAccount',
	LegacyAccount = 'LegacyAccount',
}

export interface IFindUserAccount {
	accountState: AccountState;
	accountFoundViaPhoneNumber: boolean;
	email: string;
}

export interface IVerifyCodeResponse {
	accessToken: string;
}

export enum VerifyCodeErrorCode {
	INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
}

class AuthenticationService {
	async sendVerificationCode(phoneNumber: string) {
		try {
			await mobeUnauthenticatedAPI.post('verification-codes', {
				phoneNumber,
			});

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	async verifyCode(phoneNumber: string, code: string) {
		try {
			const data = await mobeUnauthenticatedAPI.post<IVerifyCodeResponse>(
				`verification-codes/${code}/verify`,
				{
					phoneNumber,
				}
			);

			return mobeStandardSuccessProcessor<IVerifyCodeResponse, VerifyCodeErrorCode>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<IVerifyCodeResponse, VerifyCodeErrorCode>(error);
		}
	}

	async login(username: string, password: string) {
		crashlytics.log('login service');
		try {
			const data = await mobeUnauthenticatedAPI.post<IAuthenticationResponse>(
				'authentication/login',
				{
					username,
					password,
				}
			);

			if (env.isDev) {
				console.info(`authenticationService.login(): authenticated with token [${data.token}]`);
				console.info(
					`authenticationService.login(): Validic user token [${data.validicInfo?.validicId}]`
				);
			}

			return mobeStandardSuccessProcessor<
				IAuthenticationResponse,
				LoginAPIErrorCode,
				IAuthenticationError
			>(data);
		} catch (error) {
			crashlytics.log('login service error');
			return mobeStandardErrorProcessor<
				IAuthenticationResponse,
				LoginAPIErrorCode,
				IAuthenticationError
			>(error);
		}
	}

	async logout(logoutType = LogoutType.Manual) {
		try {
			await mobeAuthenticatedAPI.post('authentication/logout', undefined, {
				headers: {
					LogOutType: logoutType,
				},
			});

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	async setDeviceToken(deviceToken: string) {
		try {
			await mobeAuthenticatedAPI.put('device-tokens', {
				deviceToken,
			});

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}
}

const authenticationService = new AuthenticationService();

export default authenticationService;
