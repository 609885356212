import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import React, { ReactNode, useEffect, useState } from 'react';

export function FontsLoader({ children }: { children: ReactNode }) {
	const [areFontsLoaded, setAreFontsLoaded] = useState(false);

	useEffect(() => {
		// trigger loading of fonts
		loadAllAsyncResources();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Load fonts prior to rendering the app
	const loadAllAsyncResources = async () => {
		try {
			await Promise.all([loadFonts()]);
		} catch (error) {
			console.warn(error);
		}

		setAreFontsLoaded(true);
	};

	const loadFonts = () => {
		return Font.loadAsync({
			...Ionicons.font,
			icons: require('@mobe/assets/icons/icons.ttf'),
			clarendon: require('@mobe/assets/fonts/Clarendon.otf'),
			inter: require('@mobe/assets/fonts/Inter-Regular.ttf'),
			interMedium: require('@mobe/assets/fonts/Inter-Medium.ttf'),
			interSemiBold: require('@mobe/assets/fonts/Inter-SemiBold.ttf'),
			interBold: require('@mobe/assets/fonts/Inter-Bold.ttf'),
		});
	};

	if (!areFontsLoaded) {
		return null;
	}

	return <>{children}</>;
}
