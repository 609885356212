import BackButton from '@mobe/components/backButton/BackButton';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useLayout from '@mobe/utils/styles/useLayout';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileScreenNavigationProp, ProfileScreenRouteProp } from '../navigation/menu/types';
import ProfileEditScreen, { useProfileEditScreenOptions } from './screens/ProfileEditScreen';
import ProfileRootScreen, { useProfileRootScreenOptions } from './screens/ProfileRootScreen';

export type ProfileStackParamList = {
	PROFILE_ROOT_SCREEN: undefined;
	PROFILE_EDIT_SCREEN: undefined;
};

export type ProfileRootScreenRouteProp = RouteProp<ProfileStackParamList, 'PROFILE_ROOT_SCREEN'>;
export type ProfileRootScreenNavigationProp = CompositeNavigationProp<
	StackNavigationProp<ProfileStackParamList, 'PROFILE_ROOT_SCREEN'>,
	ProfileScreenNavigationProp
>;

export type ProfileEditScreenRouteProp = RouteProp<ProfileStackParamList, 'PROFILE_EDIT_SCREEN'>;
export type ProfileEditScreenNavigationProp = CompositeNavigationProp<
	StackNavigationProp<ProfileStackParamList, 'PROFILE_EDIT_SCREEN'>,
	ProfileScreenNavigationProp
>;

const ProfileStack = createStackNavigator<ProfileStackParamList>();

interface IProfileScreenProps {
	navigation: ProfileScreenNavigationProp;
	route: ProfileScreenRouteProp;
}

export default function ProfileScreen({ navigation }: IProfileScreenProps) {
	const profileRootScreenOptions = useProfileRootScreenOptions();
	const profileEditScreenOptions = useProfileEditScreenOptions();
	const subScreenOptions = useSubScreenStyleProps();
	const layout = useLayout();

	return (
		<ProfileStack.Navigator initialRouteName="PROFILE_ROOT_SCREEN" screenOptions={subScreenOptions}>
			<ProfileStack.Screen
				name="PROFILE_ROOT_SCREEN"
				component={ProfileRootScreen}
				options={{
					...profileRootScreenOptions,
					headerRight: () => <ChatButton />,
					headerLeft: () => (layout.isWebDesktop ? null : <BackButton />),
				}}
			/>
			<ProfileStack.Screen
				name="PROFILE_EDIT_SCREEN"
				component={ProfileEditScreen}
				options={profileEditScreenOptions}
			/>
		</ProfileStack.Navigator>
	);
}

export function useProfileScreenOptions(): DrawerNavigationOptions {
	const { t } = useTranslation();
	return {
		title: t('profile.screenTitle'),
		headerShown: false,
	};
}
