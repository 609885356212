import { noop } from 'lodash';
import React from 'react';
import { AppState, AppStateStatus } from 'react-native';

export const useAppStateChange = ({
	onActive = noop,
	onBackground = noop,
}: {
	onActive?: () => void;
	onBackground?: () => void;
}) => {
	const appState = React.useRef(AppState.currentState);

	React.useEffect(() => {
		const subscription = AppState.addEventListener('change', _handleAppStateChange);

		return () => subscription.remove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onActive, onBackground]);

	const _handleAppStateChange = (nextAppState: AppStateStatus) => {
		if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
			onActive();
		}

		if (nextAppState === 'background') {
			onBackground();
		}

		appState.current = nextAppState;
	};

	return;
};
