import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPassword } from '@mobe/api/account/accountApiHooks';
import { ResetPasswordErrorCode } from '@mobe/api/account/accountService';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import React from 'react';
import { Control, FieldErrors, FormState, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import * as Yup from 'yup';
import { IResetPasswordScreenProps } from './ResetPasswordScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		password: Yup.string().required().label(t('auth.resetPassword.passwordInputLabel')),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

interface IResetPasswordScreenController {
	handleSubmitPress: () => void;
	handleBackPress: () => void;
	isFetching: boolean;
	resetPasswordError: ResetPasswordErrorCode;

	control: Control<FormSchema>;
	formState: FormState<FormSchema>;
	errors: FieldErrors<FormSchema>;
}

export default function useResetPasswordController(props: IResetPasswordScreenProps) {
	const auth = useAuth();
	const validationSchema = useValidationSchema();
	const persistentState = usePersistentState();
	const resetPassword = useResetPassword();
	const resetPasswordTokenFromScreenParams = props.route.params?.resetToken;

	const [resetPasswordError, setResetPasswordError] = React.useState(ResetPasswordErrorCode.None);

	const { control, formState, handleSubmit } = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: '',
		},
	});

	async function submitResetPassword({ password }: FormSchema) {
		const resetPasswordToken = resetPasswordTokenFromScreenParams || auth.resetPasswordToken;

		setResetPasswordError(ResetPasswordErrorCode.None);

		const response = await resetPassword.execute(resetPasswordToken, password);

		if (response.success) {
			// since the user desires to change their password, clear previously saved credentials and bio auth state
			persistentState.resetSavedLoginCredentials();

			auth.resetStateVariables();
			auth.userHasResetPassword = true;

			props.navigation.navigate('LOGIN_SCREEN');
		} else {
			setResetPasswordError(response.errorCode);
		}
	}

	function handleSubmitPress() {
		Keyboard.dismiss();

		if (resetPassword.isPending) {
			return;
		}

		handleSubmit(submitResetPassword)();
	}

	function handleBackPress() {
		Keyboard.dismiss();
		props.navigation.navigate('LOGIN_SCREEN');
	}

	const controller: IResetPasswordScreenController = {
		handleSubmitPress,
		handleBackPress,
		isFetching: resetPassword.isPending,
		resetPasswordError,

		control,
		formState,
		errors: formState.errors,
	};

	return controller;
}
