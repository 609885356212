import {
	useAllActivitiesQuery,
	useMarkActivityCompleteMutation,
	useRefreshAllActivities,
} from '@mobe/api/activities/activitiesApiHooks';
import { ActivityType } from '@mobe/api/activities/activitiesService';
import { Button } from '@mobe/components/button';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import StepIndicator from '@mobe/components/stepIndicator/StepIndicator';
import TextButton from '@mobe/components/textButton/TextButton';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, useWindowDimensions } from 'react-native';
import { OnboardingScreenNavigationProp } from '../OnboardingStackScreen';
import * as OnboardingAnalyticsEvents from '../analyticsEvents';
import { assessmentScreenSequence } from '../assessmentScreenSequence';
import { useOnboardingContext } from '../useOnboardingContext';

export default function OnboardingScreenTemplate({
	children,
	graphic,
	routeName,
	value,
	isLoading = false,
	beforeNavigation = noop,
}: {
	children: React.ReactNode;
	graphic?: React.ReactNode;
	routeName: (typeof assessmentScreenSequence)[number];
	value?: number;
	isLoading?: boolean;
	beforeNavigation?: () => Promise<void> | void;
}) {
	const windowDimensions = useWindowDimensions();
	const navigation = useNavigation<OnboardingScreenNavigationProp>();
	const parentNavigation = navigation.getParent();
	const onboardingContext = useOnboardingContext();
	const markActivityCompleteMutation = useMarkActivityCompleteMutation();
	const refreshAllActivities = useRefreshAllActivities();
	const activitiesQuery = useAllActivitiesQuery();
	const routeIndex = assessmentScreenSequence.indexOf(routeName);
	const previousScreen = assessmentScreenSequence[routeIndex - 1];
	const nextScreen = assessmentScreenSequence[routeIndex + 1];
	const isLastScreen = routeIndex + 1 === assessmentScreenSequence.length;
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();
	const { assessmentQuickEntryVariant } = useRemoteConfigData();
	const shouldUseQuickEntry = assessmentQuickEntryVariant && onboardingContext === 'initial';

	useFocusEffect(
		React.useCallback(() => {
			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: () => (
					<StepIndicator
						totalSteps={assessmentScreenSequence.length}
						currentStepIndex={routeIndex}
					/>
				),
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	React.useEffect(() => {
		if (value && shouldUseQuickEntry && !isLoading) {
			navigateToNextScreen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, shouldUseQuickEntry, isLoading]);

	function navigateToPreviousScreen() {
		if (previousScreen) {
			navigation.navigate(previousScreen);
		} else {
			navigation.navigate('ONBOARDING_INTRO_SCREEN');
		}
	}

	function navigateToNextScreen() {
		if (nextScreen) {
			navigation.navigate(nextScreen);
		} else {
			submitAssessment();
		}
	}

	async function submitAssessment() {
		const activity = activitiesQuery.data?.activities.find(
			(activity) => activity.type === ActivityType.Assessment
		);

		if (!activity) {
			genericErrorAlert();
			return;
		}

		await markActivityCompleteMutation.mutateAsync({ activityId: activity?.id });

		if (onboardingContext === 'initial') {
			await refreshAllActivities();

			OnboardingAnalyticsEvents.assessmentFirstSignInComplete();
		}

		if (onboardingContext === 'activity') {
			OnboardingAnalyticsEvents.assessmentActivityComplete();
		}

		navigation.navigate('ONBOARDING_CONFIRMATION_SCREEN');
	}

	async function handlePreviousPress() {
		await beforeNavigation();
		navigateToPreviousScreen();
	}

	async function handleNextPress() {
		await beforeNavigation();
		navigateToNextScreen();
	}

	return (
		<ScreenTemplateWithFooter
			noPadding
			footer={
				shouldUseQuickEntry ? (
					isLastScreen ? (
						<Button
							title={t('onboarding.submitButton')}
							onPress={handleNextPress}
							loading={markActivityCompleteMutation.isLoading}
						/>
					) : (
						<View style={{ justifyContent: 'center', flexDirection: 'row' }}>
							<TextButton title="Skip question" onPress={handleNextPress} />
						</View>
					)
				) : (
					<ButtonRow>
						<Button
							variant="secondary"
							title={t('onboarding.previousButton')}
							onPress={handlePreviousPress}
						/>
						<Button
							title={isLastScreen ? t('onboarding.submitButton') : t('onboarding.nextButton')}
							onPress={handleNextPress}
							loading={markActivityCompleteMutation.isLoading}
						/>
					</ButtonRow>
				)
			}
			footerStyle={shouldUseQuickEntry && { borderTopColor: 'transparent' }}
		>
			{graphic && (
				<View
					style={{
						maxHeight: windowDimensions.height * 0.35,
						overflow: 'hidden',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{graphic}
				</View>
			)}
			<View style={{ flexGrow: 1, paddingHorizontal: 8 }}>{children}</View>
		</ScreenTemplateWithFooter>
	);
}
