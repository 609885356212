import useHTMLStyles from '@mobe/utils/styles/useHTMLStyles';
import { POST_MESSAGE_STRING_PATH } from './webMessagingUtils';
import { addWistiaWebEventHandlers, addWistiaWebSdkVideoPlayerScripts } from './wistiaUtils';

export function useContentWrapper(html?: string) {
	const styles = useHTMLStyles();

	return `
<html>
	<head>
		<style type="text/css">${styles}</style>
		<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0">
		${addWistiaWebSdkVideoPlayerScripts()}
	</head>
		<body>
			<div id="web-view-content">${html}</div>
			<script>
				${addWistiaWebEventHandlers()}

				// Post window height to parent
				function getWebViewContentHeight() {
					const webViewContentScrollHeight = document.querySelector("#web-view-content").scrollHeight;
					const webViewContentOffsetHeight = document.querySelector("#web-view-content").offsetHeight;

					return String(Math.max(webViewContentScrollHeight, webViewContentOffsetHeight));
				}

				${POST_MESSAGE_STRING_PATH}(getWebViewContentHeight());

				setTimeout(() => {
					${POST_MESSAGE_STRING_PATH}(getWebViewContentHeight());
				}, 1000);
			</script>
		</body>
</html>`;
}
