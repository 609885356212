import { IAvailableTimeSlot } from '@mobe/api/appointments/appointmentsService';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import Blocks from '@mobe/components/layout/Blocks';
import Text from '@mobe/components/text/Text';
import { addMinutes, format } from 'date-fns';
import * as React from 'react';

interface IApptTimeProps {
	appts: IAvailableTimeSlot[];
	apptDurationMinutes: number;
	selectedTime?: Date;
	onTimeSelect: (date: Date, availableGuideDiKeys: string[]) => void;
}

export default function ApptTime({
	appts,
	apptDurationMinutes,
	selectedTime,
	onTimeSelect,
}: IApptTimeProps) {
	function handleTimeSelect(date: Date, availableGuideDiKeys: string[]) {
		onTimeSelect(date, availableGuideDiKeys);
	}

	return (
		<Blocks role="radiogroup">
			{appts.map((appt) => (
				<BoxButton
					key={appt.utcDateTimeObj.toISOString()}
					selected={selectedTime?.getTime() === appt.utcDateTimeObj.getTime()}
					onPress={() => handleTimeSelect(appt.utcDateTimeObj, appt.availableGuideDiKeys)}
					role="radio"
				>
					<Text align="center">
						{format(appt.utcDateTimeObj, 'h:mm')}&ndash;
						{format(addMinutes(appt.utcDateTimeObj, apptDurationMinutes), 'p')}
					</Text>
				</BoxButton>
			))}
		</Blocks>
	);
}
