const downloadDocument = (objectURL: string, name = '') => {
	const a = document.createElement('a');
	a.href = objectURL;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	a.remove();
};

const openUrl = (url: string, target = '_blank') => {
	window.open(url, target);
};

const openDocument = (object: Blob | File | MediaSource, name: string | undefined) => {
	const objectURL = URL.createObjectURL(object);
	downloadDocument(objectURL, name);
	URL.revokeObjectURL(objectURL);
};

export default {
	openDocument,
	openUrl,
};
