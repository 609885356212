import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { ContactOptions } from '@mobe/api/appointments/appointmentsService';
import { CoachType } from '@mobe/api/guides/guidesApi';
import Checkbox from '@mobe/components/checkbox';
import { IControlledCheckboxProps } from '@mobe/components/checkbox/useControlledCheckboxProps';
import { InputText, formatPhoneNumber } from '@mobe/components/input';
import { IControlledInputProps } from '@mobe/components/input/useControlledInputProps';
import Row from '@mobe/components/layout/Row';
import { Radio } from '@mobe/components/radio';
import * as React from 'react';
import { FieldValues, UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface IContactOptionsViewProps<T extends FieldValues> {
	contactOptionControl: UseControllerReturn<T, any>;
	primaryPhoneNumberProps: IControlledInputProps;
	primaryPhoneNumberErrorMessage: string | undefined;
	otherPhoneNumberProps: IControlledInputProps;
	otherPhoneNumberErrorMessage: string | undefined;
	saveOtherAsAlternatePhoneNumberProps: IControlledCheckboxProps;
	guideType?: CoachType;
}

export default function ContactOptionsView<T extends FieldValues>({
	contactOptionControl,
	primaryPhoneNumberProps,
	primaryPhoneNumberErrorMessage,
	otherPhoneNumberProps,
	otherPhoneNumberErrorMessage,
	saveOtherAsAlternatePhoneNumberProps,
}: IContactOptionsViewProps<T>) {
	const profileQuery = useProfileQuery();
	const { t } = useTranslation();

	return (
		<>
			{!profileQuery.data?.phoneNumber && (
				<InputText
					type="phone"
					label={t('appointments.contactOptionsView.primaryPhoneInputLabel')}
					errorMessage={primaryPhoneNumberErrorMessage}
					{...primaryPhoneNumberProps}
				/>
			)}

			{Boolean(profileQuery.data?.phoneNumber) && (
				<>
					<View role="radiogroup">
						<Row>
							<Row.Item>
								<Radio
									aria-label={`${t(
										'appointments.contactOptionsView.primaryPhoneInputLabel'
									)}, ${formatPhoneNumber(profileQuery.data?.phoneNumber || '')}`}
									checked={contactOptionControl.field.value === ContactOptions.Primary}
									onPress={() => {
										contactOptionControl.field.onChange(ContactOptions.Primary);
										contactOptionControl.field.onBlur();
									}}
								/>
							</Row.Item>
							<Row.Item fill>
								<InputText
									type="phone"
									label={t('appointments.contactOptionsView.primaryPhoneInputLabel')}
									disabled
									value={profileQuery.data?.phoneNumber}
								/>
							</Row.Item>
						</Row>

						{Boolean(profileQuery.data?.alternatePhoneNumber) && (
							<Row>
								<Row.Item>
									<Radio
										aria-label={`${t(
											'appointments.contactOptionsView.alternatePhoneInputLabel'
										)}, ${formatPhoneNumber(profileQuery.data?.alternatePhoneNumber || '')}`}
										checked={contactOptionControl.field.value === ContactOptions.Alternate}
										onPress={() => {
											contactOptionControl.field.onChange(ContactOptions.Alternate);
											contactOptionControl.field.onBlur();
										}}
									/>
								</Row.Item>
								<Row.Item fill>
									<InputText
										type="phone"
										label={t('appointments.contactOptionsView.alternatePhoneInputLabel')}
										disabled
										value={profileQuery.data?.alternatePhoneNumber}
									/>
								</Row.Item>
							</Row>
						)}

						<Row>
							<Row.Item>
								<Radio
									aria-label={t('appointments.contactOptionsView.otherPhoneInputLabel')}
									accessibilityHint={t('appointments.contactOptionsView.otherPhoneInputHint')}
									checked={contactOptionControl.field.value === ContactOptions.Other}
									onPress={() => {
										contactOptionControl.field.onChange(ContactOptions.Other);
										contactOptionControl.field.onBlur();
									}}
								/>
							</Row.Item>
							<Row.Item fill>
								<InputText
									type="phone"
									label={t('appointments.contactOptionsView.otherPhoneInputLabel')}
									disabled={contactOptionControl.field.value !== ContactOptions.Other}
									errorMessage={otherPhoneNumberErrorMessage}
									{...otherPhoneNumberProps}
								/>
							</Row.Item>
						</Row>

						{/* Checkbox to save other as alternate only shows if there is no alternate number */}
						{!profileQuery.data?.alternatePhoneNumber && (
							<View style={{ marginLeft: 36 }}>
								<Checkbox
									label={t('appointments.contactOptionsView.saveOtherAsAlternateLabel')}
									disabled={contactOptionControl.field.value !== ContactOptions.Other}
									{...saveOtherAsAlternatePhoneNumberProps}
								/>
							</View>
						)}
					</View>
				</>
			)}
		</>
	);
}
