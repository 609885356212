import { useClaimAccount } from '@mobe/api/account/accountApiHooks';
import { AccountRegistrationErrorCode } from '@mobe/api/account/accountService';
import { Button } from '@mobe/components/button';
import Checkbox from '@mobe/components/checkbox';
import { InputPassword, InputText, useControlledInputProps } from '@mobe/components/input';
import InputReadOnly from '@mobe/components/input/InputReadOnly';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import { PasswordRequirementsList } from '@mobe/components/passwordRequirementsList';
import { Text } from '@mobe/components/text';
import useTextStyles from '@mobe/components/text/textStyles';
import env from '@mobe/env/env';
import MobeLinking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform, View } from 'react-native';
import { AuthStackScreenNavigationProp } from '../AuthenticationStackScreen';
import useClaimAccountForm, { ClaimAccountFormSchema } from './useClaimAccountForm';

interface IClaimAccountFormProps {
	accessToken: string;
	initialValues?: ClaimAccountFormSchema;
	hideTermsAcceptance?: boolean;
	onSubmissionSuccess?: () => void;
	onSubmissionError?: (errorCode: AccountRegistrationErrorCode) => void;
	onFormStateDirty?: () => void;
}

export default function ClaimAccountForm({
	accessToken,
	initialValues,
	hideTermsAcceptance = false,
	onSubmissionSuccess = noop,
	onSubmissionError = noop,
	onFormStateDirty = noop,
}: IClaimAccountFormProps) {
	const claimAccount = useClaimAccount();
	const navigation = useNavigation<AuthStackScreenNavigationProp>();
	const form = useClaimAccountForm(initialValues);
	const inlineButtonStyles = useTextStyles({ color: 'primary', weight: 'bold' });
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const [acceptTermsState, setAcceptTermsState] = React.useState<'idle' | 'checked' | 'notChecked'>(
		'idle'
	);

	const emailInputProps = useControlledInputProps({
		name: 'email',
		control: form.control,
	});

	const passwordInputProps = useControlledInputProps({
		name: 'password',
		control: form.control,
	});

	React.useEffect(() => {
		if (form.formState.isDirty) {
			onFormStateDirty();
		}
	}, [form.formState]);

	// Work around for iOS user utilizing password autofill
	// https://github.com/facebook/react-native/issues/23921
	const isSubmissionDelayed = React.useRef<boolean>(false);

	async function performClaimAccount({ email, password }: ClaimAccountFormSchema) {
		const response = await claimAccount.execute(accessToken, email, password);

		if (response.success) {
			onSubmissionSuccess();

			navigation.navigate('VERIFY_EMAIL_PROMPT_SCREEN', {
				accessToken,
			});
		} else {
			onSubmissionError(response.errorCode);
		}
	}

	function handleSubmitPress() {
		Keyboard.dismiss();

		if (!hideTermsAcceptance && acceptTermsState !== 'checked') {
			return;
		}

		if (claimAccount.isPending || isSubmissionDelayed.current) {
			return;
		}

		// Work around for iOS user utilizing password autofill: delaying submission and double validating
		// https://github.com/facebook/react-native/issues/23921
		if (Platform.OS === 'ios') {
			isSubmissionDelayed.current = true;
			setTimeout(triggerFormValidationAndSubmit, 250);
		} else {
			form.handleSubmit(performClaimAccount)();
		}
	}

	async function triggerFormValidationAndSubmit() {
		// Manual form validation again for autofill issue
		const valid = await form.trigger();
		isSubmissionDelayed.current = false;

		if (valid) {
			setTimeout(() => form.handleSubmit(performClaimAccount)(), 0);
		}
	}

	function handleTermsAndConditionsPress() {
		if (Platform.OS === 'web') {
			MobeLinking.openUrl(env.TERMS_AND_CONDITIONS_URL);
			return;
		}

		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'termsAndConditions' });
	}

	function handlePrivacyPolicyPress() {
		if (Platform.OS === 'web') {
			MobeLinking.openUrl(env.PRIVACY_POLICY_URL);
			return;
		}

		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'privacyPolicy' });
	}

	return (
		<>
			{initialValues?.email ? (
				<InputReadOnly label={t('auth.claimAccount.emailInputLabel')} value={initialValues.email} />
			) : (
				<InputText
					label={t('auth.claimAccount.emailInputLabel')}
					type="email"
					errorMessage={form.formState.errors.email?.message}
					enterKeyHint="next"
					onSubmitEditing={() => form.setFocus('password')}
					{...emailInputProps}
				/>
			)}

			<View style={vr(2)}>
				<InputPassword
					label={t('auth.claimAccount.passwordInputLabel')}
					textContentType="newPassword"
					showVisibilityToggle
					collapseEmptyError
					errorMessage={form.formState.errors.password?.message}
					enterKeyHint="go"
					onSubmitEditing={(event) => {
						// Work around for iOS user utilizing password autofill
						// https://github.com/facebook/react-native/issues/23921
						if (Platform.OS === 'ios' && event.nativeEvent.text.length === 0) {
							form.setValue('password', '');
						}

						setTimeout(() => handleSubmitPress(), 0);
					}}
					onEndEditing={(event) => {
						// Work around for iOS user utilizing password autofill
						// https://github.com/facebook/react-native/issues/23921
						if (Platform.OS === 'ios' && event.nativeEvent.text.length === 0) {
							form.setValue('password', '');
						}
					}}
					{...passwordInputProps}
				/>
			</View>

			<View style={vr(6)}>
				<PasswordRequirementsList password={passwordInputProps.value || ''} />
			</View>

			{!hideTermsAcceptance ? (
				<View style={vr(6)}>
					<Checkbox
						label={() => (
							<Text weight="semiBold">
								<MobeParsedText
									parse={[
										{
											pattern: new RegExp(t('auth.claimAccount.termsAndConditionsButton'), 'i'),
											style: inlineButtonStyles,
											onPress: () => handleTermsAndConditionsPress(),
										},
										{
											pattern: new RegExp(t('auth.claimAccount.privacyPolicyButton'), 'i'),
											style: inlineButtonStyles,
											onPress: () => handlePrivacyPolicyPress(),
										},
									]}
								>
									{t('auth.claimAccount.termsOfService', {
										termsAndConditionsButton: t('auth.claimAccount.termsAndConditionsButton'),
										privacyPolicyButton: t('auth.claimAccount.privacyPolicyButton'),
									})}
								</MobeParsedText>
							</Text>
						)}
						checked={acceptTermsState === 'checked'}
						onPress={() => {
							if (acceptTermsState === 'checked') {
								setAcceptTermsState('notChecked');
							} else {
								setAcceptTermsState('checked');
							}
						}}
					/>
					{acceptTermsState === 'notChecked' && (
						<Text color="error">{t('auth.claimAccount.errors.mustAgreeToTerms')}</Text>
					)}
				</View>
			) : null}

			<Button
				title={t('auth.claimAccount.submitButton')}
				loading={claimAccount.isPending}
				onPress={handleSubmitPress}
				disabled={
					!form.formState.isValid || (!hideTermsAcceptance && acceptTermsState !== 'checked')
				}
			/>
		</>
	);
}
