import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';

export default function useAddMetaDescription(metaDescriptionContent: string) {
	useFocusEffect(
		React.useCallback(() => {
			const meta = document.createElement('meta');
			meta.name = 'description';
			meta.content = metaDescriptionContent;
			document.head.appendChild(meta);

			return () => document.head.removeChild(meta);
		}, [metaDescriptionContent])
	);
}
