import Button from '@mobe/components/button/Button';
import ScreenTemplateWithFooter, {
	IScreenTemplateWithFooterProps,
} from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler, Platform, StyleSheet, View } from 'react-native';
import { GuideMatchFormScreen } from '../GuideMatchStackScreen';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchScreenTemplate extends IScreenTemplateWithFooterProps {
	routeName: GuideMatchFormScreen;
	canProgress?: boolean;
	nextButtonRef?: React.MutableRefObject<any>;
}

export default function GuideMatchScreenTemplate({
	children,
	routeName,
	canProgress = false,
	nextButtonRef,
	...screenTemplateProps
}: IGuideMatchScreenTemplate) {
	const formController = useGuideMatchForm();
	const { t } = useTranslation();
	const styles = guideMatchScreenTemplateStyles();
	const screenIndex = formController.indexOfScreen(routeName);
	const shouldShowBackButton = screenIndex > 0;

	// Handle native back button press via guideMatchFrom rather than react-navigation
	useFocusEffect(
		React.useCallback(() => {
			if (Platform.OS === 'web') {
				return;
			}

			const onBackPress = () => {
				formController.handleNativeBackButtonPress();

				// returning true denotes event is handled, react-navigation's listener won't be called
				return true;
			};

			BackHandler.addEventListener('hardwareBackPress', onBackPress);

			return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
		}, [formController])
	);

	return (
		<ScreenTemplateWithFooter
			footer={
				<View style={styles.footer}>
					{shouldShowBackButton && (
						<View style={styles.buttonContainer}>
							<Button
								variant="secondary"
								title={t('appointments.guideMatch.previousStepButton')}
								onPress={() => formController.handleNavButtonPress(screenIndex - 1)}
							/>
						</View>
					)}
					<View style={styles.buttonContainer}>
						<Button
							ref={nextButtonRef}
							title={
								formController.currentStepIsLastStep
									? t('appointments.guideMatch.lastStepButton')
									: t('appointments.guideMatch.nextStepButton')
							}
							disabled={!canProgress}
							loading={formController.isFetching}
							onPress={() => formController.handleNavButtonPress(screenIndex + 1)}
						/>
					</View>
				</View>
			}
			{...screenTemplateProps}
		>
			{children}
		</ScreenTemplateWithFooter>
	);
}

function guideMatchScreenTemplateStyles() {
	const gutterSize = 20;

	return StyleSheet.create({
		footer: {
			flexDirection: 'row',
			marginLeft: 0 - gutterSize,
		},
		buttonContainer: {
			flex: 1,
			paddingLeft: gutterSize,
		},
	});
}
