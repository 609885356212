import * as StoreReview from 'expo-store-review';
import { Linking, Platform } from 'react-native';

const appStoreId = 145718169;
const androidPackageName = 'com.mobeforlife.healthguide';

function tryToLinkToAppStore() {
	// Then open stores directly
	// https://docs.expo.dev/versions/latest/sdk/storereview/#write-reviews
	if (Platform.OS === 'ios') {
		Linking.openURL(
			`itms-apps://itunes.apple.com/app/viewContentsUserReviews/id${appStoreId}?action=write-review`
		);
	}
	if (Platform.OS === 'android') {
		Linking.openURL(`market://details?id=${androidPackageName}&showAllReviews=true`);
	}
}

export async function storeRatingReviewPrompt() {
	if (await StoreReview.hasAction()) {
		await StoreReview.requestReview();
	} else {
		tryToLinkToAppStore();
	}
}
