import { AuthProvider } from '@mobe/api/authentication/AuthContext';
import env from '@mobe/env/env';
import { ChatHubConnectionProvider } from '@mobe/features/chat/chatHubConnectionContext';
import Navigation from '@mobe/features/navigation/Navigation';
import { ValidicServiceProvider } from '@mobe/features/track/ValidicServiceProvider';
import '@mobe/utils/i18n';
import { InternetConnectionProvider } from '@mobe/utils/internetConnectionContext';
import { StyleRulesProvider } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { AccessibilityInfoProvider } from '@mobe/utils/useAccessibilityInfo';
import useColorScheme from '@mobe/utils/useColorScheme';
import { PersistentStateProvider } from '@mobe/utils/usePersistentState';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Button, Linking, Platform, StyleSheet, Text, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppDataPreloader from './AppDataPreloader';
import { FontsLoader } from './FontsLoader';
import analytics from './utils/analytics';
import crashlytics from './utils/crashlytics';
import { lightThemeColors } from './utils/styles/styleRules/buildStyleRules';

// keep splash screen up
SplashScreen.preventAutoHideAsync();

/**
 * App code
 */

/**
 * The text inputs in web seem to have a blue outline when focused.
 * This is not needed in our case, because we already change the border color
 * of the input when focused. The solution to the problem is to create a css
 * file and inject those styles inside of the head tag in the web app using
 * webpack
 */
try {
	require('./web.css');
	// eslint-disable-next-line no-empty
} catch (error) {}

// NOTE: This log is for verifying the date of each web build- seen by inspecting the console of 'connect' website corresponding to that build
const BUILD_DATE = env.BUILD_DATE;
if (BUILD_DATE && Platform.OS === 'web') {
	console.log(`BUILD DATE: ${BUILD_DATE}`);
}

const appQueryClient = new QueryClient();

export default function App() {
	function handleError(error: Error) {
		crashlytics.recordError(error, 'app');
		analytics.logEvent('error_app');
	}

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
			<SafeAreaProvider>
				<QueryClientProvider client={appQueryClient}>
					<GestureHandlerRootView style={{ flex: 1 }}>
						<FontsLoader>
							<PersistentStateProvider>
								<AuthProvider>
									<AppDataPreloader />
									<AccessibilityInfoProvider>
										<StyleRulesProvider>
											<ChatHubConnectionProvider>
												<ValidicServiceProvider>
													<InternetConnectionProvider>
														<Navigation />
														<StatusBarWrapper />
													</InternetConnectionProvider>
												</ValidicServiceProvider>
											</ChatHubConnectionProvider>
										</StyleRulesProvider>
									</AccessibilityInfoProvider>
								</AuthProvider>
							</PersistentStateProvider>
						</FontsLoader>
					</GestureHandlerRootView>
				</QueryClientProvider>
			</SafeAreaProvider>
		</ErrorBoundary>
	);
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
	const { t } = useTranslation();

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'white',
			paddingHorizontal: 20,
		},
		header: {
			color: lightThemeColors.text,
			fontSize: 16,
			fontWeight: 'bold',
		},
		text: {
			color: lightThemeColors.text,
			textAlign: 'center',
		},
	});

	React.useEffect(() => {
		SplashScreen.hideAsync();
	}, []);

	return (
		<View style={styles.container}>
			<Text style={[styles.header, { marginBottom: 10 }]}>{t('errorFallbackView.heading')}</Text>
			<Text style={[styles.text, { marginBottom: 10 }]}>{t('errorFallbackView.subHeading')}</Text>
			<Text
				style={[
					styles.text,
					{ color: lightThemeColors.primary, textDecorationLine: 'underline', marginBottom: 20 },
				]}
				onPress={() => {
					Linking.openURL(`tel:${t('errorFallbackView.callForHelpNumber')}`);
				}}
			>
				{t('errorFallbackView.callForHelp')}
			</Text>
			<Button
				title={t('errorFallbackView.buttonText')}
				color={lightThemeColors.primary}
				onPress={resetErrorBoundary}
			/>
		</View>
	);
}

function StatusBarWrapper() {
	const colorScheme = useColorScheme();

	return <StatusBar style={colorScheme === 'dark' ? 'light' : 'dark'} />;
}
