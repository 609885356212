/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

type AnyFunction = (...args: any[]) => any;

type PromisedTypeRecursive<T> = T extends PromiseLike<infer U> ? PromisedTypeRecursive<U> : T;

export default function useMonitoredPromise<
	TFunc extends AnyFunction,
	TError = unknown,
	TValue = PromisedTypeRecursive<ReturnType<TFunc>>,
>(asyncFn: (...args: Parameters<TFunc>) => ReturnType<TFunc>, defaultValue?: TValue) {
	const [isPending, setIsPending] = React.useState(false);
	const [error, setError] = React.useState<TError | unknown>();
	const [value, setValue] = React.useState<TValue>();

	if (defaultValue) {
		setValue(defaultValue);
	}

	return {
		isPending,
		value,
		error,
		execute: async (...args: Parameters<TFunc>): Promise<ReturnType<TFunc>> => {
			try {
				setIsPending(true);
				const response = await asyncFn(...args);

				setValue(response);
				setIsPending(false);

				return response;
			} catch (error) {
				setError(error);
				setIsPending(false);
				throw error;
			}
		},
	};
}
