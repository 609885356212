import { useAuth } from '@mobe/api/authentication/AuthContext';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import useTrackerContent from './useTrackerContent';

export default function useShouldShowConnectHealthData(trackerAbbreviation: TrackerAbbreviation) {
	const trackerContent = useTrackerContent();
	const tracker = trackerContent[trackerAbbreviation];
	const persistentState = usePersistentState();
	const auth = useAuth();

	if (!Object.values(TrackerAbbreviation).includes(trackerAbbreviation)) {
		return false;
	}

	return (
		tracker.showConnectHealthDataCTA &&
		!persistentState.hasDismissedTrackerCTAFromTrack &&
		!auth.hasValidValidicData
	);
}
