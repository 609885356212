import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import React from 'react';
import { View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

export default function EmptyCallsGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(280, 280);
	const rules = useStyleRules();

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle cx="140" cy="140" r="140" fill={rules.colors.strokeLight} />
				<Path d="M243.268 80.2739H26.2854V243.237H243.268V80.2739Z" fill="white" />
				<Path
					d="M29.6281 50.0348C27.7846 50.0348 26.2889 51.5283 26.2889 53.3714V80.2778H243.264V50.0259L29.6281 50.0348Z"
					fill="#5A9CC3"
				/>
				<Path d="M261.993 80.2739H243.266V243.237H261.993V80.2739Z" fill="#D0E5F7" />
				<Path
					d="M258.654 50.0361H243.273V80.288H262V53.387C262.002 52.5017 261.652 51.652 261.027 51.0249C260.402 50.3978 259.553 50.0447 258.668 50.0433L258.654 50.0361Z"
					fill="#5990B5"
				/>
				<Path
					d="M54.2322 61.179C54.2322 63.3776 52.4495 65.1598 50.2505 65.1598C48.0514 65.1598 46.2687 63.3776 46.2687 61.179C46.2687 58.9805 48.0514 57.1982 50.2505 57.1982C51.3065 57.1982 52.3193 57.6176 53.066 58.3642C53.8127 59.1107 54.2322 60.1233 54.2322 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M50.2613 59.4885C45.0394 59.4885 40.8062 55.2563 40.8062 50.0357C40.8062 44.815 45.0394 40.5828 50.2613 40.5828C55.4832 40.5828 59.7164 44.815 59.7164 50.0357H63.2976C63.2976 42.8376 57.461 37.0024 50.2613 37.0024C43.0615 37.0024 37.225 42.8376 37.225 50.0357C37.225 57.2337 43.0615 63.0689 50.2613 63.0689V59.4885Z"
					fill="#243942"
				/>
				<Path
					d="M87.8038 61.179C87.8038 63.3776 86.0211 65.1598 83.8221 65.1598C81.623 65.1598 79.8403 63.3776 79.8403 61.179C79.8403 58.9805 81.623 57.1982 83.8221 57.1982C84.8781 57.1982 85.8909 57.6176 86.6376 58.3642C87.3843 59.1107 87.8038 60.1233 87.8038 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M83.8185 59.4879C78.5966 59.4879 74.3635 55.2557 74.3635 50.0351C74.3635 44.8144 78.5966 40.5822 83.8185 40.5822C89.0404 40.5822 93.2736 44.8144 93.2736 50.0351H96.8548C96.8548 44.7635 93.6785 40.011 88.8069 37.9938C83.9353 35.9766 78.328 37.092 74.5998 40.8198C70.8716 44.5477 69.7567 50.1538 71.7751 55.0239C73.7934 59.894 78.5475 63.069 83.8203 63.0683L83.8185 59.4879Z"
					fill="#243942"
				/>
				<Path
					d="M121.367 61.179C121.367 63.3776 119.584 65.1598 117.385 65.1598C115.186 65.1598 113.403 63.3776 113.403 61.179C113.403 58.9805 115.186 57.1982 117.385 57.1982C118.441 57.1978 119.454 57.617 120.201 58.3637C120.948 59.1103 121.367 60.1231 121.367 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M117.386 59.4885C112.165 59.4885 107.931 55.2563 107.931 50.0357C107.931 44.815 112.165 40.5828 117.386 40.5828C122.608 40.5828 126.841 44.815 126.841 50.0357H130.423C130.423 42.8376 124.586 37.0024 117.386 37.0024C110.187 37.0024 104.35 42.8376 104.35 50.0357C104.35 57.2337 110.187 63.0689 117.386 63.0689V59.4885Z"
					fill="#243942"
				/>
				<Path
					d="M154.936 61.179C154.936 63.3776 153.154 65.1598 150.955 65.1598C148.756 65.1598 146.973 63.3776 146.973 61.179C146.973 58.9805 148.756 57.1982 150.955 57.1982C152.011 57.1982 153.023 57.6176 153.77 58.3642C154.517 59.1107 154.936 60.1233 154.936 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M150.955 59.4879C145.733 59.4879 141.5 55.2557 141.5 50.0351C141.5 44.8144 145.733 40.5822 150.955 40.5822C156.177 40.5822 160.41 44.8144 160.41 50.0351H163.991C163.991 44.7635 160.815 40.011 155.943 37.9938C151.071 35.9766 145.464 37.092 141.736 40.8198C138.008 44.5477 136.893 50.1538 138.911 55.0239C140.929 59.894 145.684 63.069 150.956 63.0683L150.955 59.4879Z"
					fill="#243942"
				/>
				<Path
					d="M188.501 61.179C188.5 63.3761 186.718 65.1566 184.52 65.1563C182.322 65.1559 180.541 63.3749 180.541 61.1778C180.54 58.9808 182.321 57.1992 184.519 57.1982C185.575 57.1978 186.588 57.617 187.335 58.3637C188.082 59.1103 188.501 60.1231 188.501 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M184.521 59.4878C179.299 59.4888 175.065 55.2574 175.064 50.0368C175.063 44.8161 179.295 40.5832 184.517 40.5822C189.739 40.5812 193.973 44.8126 193.974 50.0332H197.555C197.555 42.8352 191.719 37 184.519 37C177.319 37 171.483 42.8352 171.483 50.0332C171.483 57.2313 177.319 63.0665 184.519 63.0665L184.521 59.4878Z"
					fill="#243942"
				/>
				<Path
					d="M222.067 61.179C222.066 63.3761 220.284 65.1566 218.086 65.1563C215.889 65.1559 214.107 63.3749 214.107 61.1778C214.107 58.9808 215.888 57.1992 218.085 57.1982C219.141 57.1978 220.154 57.617 220.901 58.3637C221.648 59.1103 222.067 60.1231 222.067 61.179Z"
					fill="#243942"
				/>
				<Path
					d="M218.087 59.4882C212.865 59.4892 208.631 55.2578 208.63 50.0372C208.629 44.8165 212.862 40.5835 218.083 40.5826C223.305 40.5816 227.539 44.813 227.54 50.0336H231.122C231.122 44.762 227.945 40.0095 223.074 37.9923C218.202 35.9751 212.595 37.0905 208.866 40.8184C205.138 44.5462 204.023 50.1523 206.042 55.0224C208.06 59.8926 212.814 63.0676 218.087 63.0668V59.4882Z"
					fill="#243942"
				/>
				<G opacity="0.6">
					<G opacity="0.6">
						<Path d="M189.946 179.379H158.487V195.765H189.946V179.379Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M151.581 179.379H120.122V195.765H151.581V179.379Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M189.946 137.733H158.487V154.121H189.946V137.733Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M151.581 137.733H120.122V154.121H151.581V137.733Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M74.8529 137.733H43.3942V154.121H74.8529V137.733Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M74.8529 158.557H43.3942V174.943H74.8529V158.557Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M113.216 137.733H81.7573V154.121H113.216V137.733Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M113.216 179.379H81.7573V195.765H113.216V179.379Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M228.309 137.733H196.85V154.121H228.309V137.733Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M228.309 158.557H196.85V174.943H228.309V158.557Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M151.581 158.557H120.122V174.943H151.581V158.557Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M189.946 158.557H158.487V174.943H189.946V158.557Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M228.309 179.379H196.85V195.765H228.309V179.379Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M113.216 158.557H81.7573V174.943H113.216V158.557Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M74.8529 116.909H43.3942V133.295H74.8529V116.909Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M113.216 116.909H81.7573V133.295H113.216V116.909Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M151.581 116.909H120.122V133.295H151.581V116.909Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M151.581 200.202H120.122V216.59H151.581V200.202Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M189.946 116.909H158.487V133.295H189.946V116.909Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M74.8529 179.379H43.3942V195.765H74.8529V179.379Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M74.8529 200.202H43.3942V216.59H74.8529V200.202Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M113.216 200.202H81.7573V216.59H113.216V200.202Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M228.309 116.909H196.85V133.295H228.309V116.909Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M189.946 200.202H158.487V216.59H189.946V200.202Z" fill="#E8E8E8" />
					</G>
					<G opacity="0.6">
						<Path d="M228.309 200.202H196.85V216.59H228.309V200.202Z" fill="#E8E8E8" />
					</G>
				</G>
				<Path
					d="M243.266 207.363C243.266 207.363 242.376 221.89 224.981 226.773H243.266V207.363Z"
					fill="#D0E5F7"
				/>
				<Path
					d="M206.697 207.363H8C8 207.363 8.88997 221.89 26.2854 226.773H224.982C207.592 221.89 206.697 207.363 206.697 207.363Z"
					fill="#D0E5F7"
				/>
				<Path d="M243.268 106.801H26.2854V108.58H243.268V106.801Z" fill="#D0E5F7" />
				<Path d="M243.268 80.2739H26.2854V82.0534H243.268V80.2739Z" fill="#D0E5F7" />
				<Path d="M261.993 80.2739H256.97V243.237H261.993V80.2739Z" fill="#D0E5F7" />
				<Path d="M243.268 238.939H26.2854V243.237H243.268V238.939Z" fill="#D0E5F7" />
			</Svg>
		</View>
	);
}
