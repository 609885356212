import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestFitnessGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(52, 35);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="#1A7EAD" fillRule="nonzero">
					<Path d="M2.54 2.356a6.252 6.252 0 00-1.942 7.42c1.125 2.622 3.891 4.171 6.746 3.778 2.854-.393 5.086-2.63 5.443-5.457a6.282 6.282 0 00-3.185-6.252 6.438 6.438 0 00-7.063.51zm6.332 8.03a3.981 3.981 0 01-4.377.331A3.885 3.885 0 012.52 6.844 3.912 3.912 0 015.39 3.563a3.97 3.97 0 014.162 1.376 3.867 3.867 0 01-.681 5.446zM51.434 32.792a4.066 4.066 0 00-.417-4.633L43.789 19.4c-1.912-2.265-5.28-2.663-7.679-.907l-7.526 5.473-3.69-4.454 7.239-5.407A6.87 6.87 0 0034.76 7.15c-.565-2.627-2.618-4.691-5.265-5.296L21.846.123c-2.335-.555-4.694.82-5.335 3.107a4.074 4.074 0 00.501 3.168c.62 1 1.625 1.71 2.783 1.966l4.377 1.008-4.839 3.558a5.684 5.684 0 00-7.245.245 5.185 5.185 0 00-.44 7.205l10.04 12.195H1.311a1.232 1.232 0 00-1.132.58 1.202 1.202 0 000 1.262c.238.387.675.61 1.132.58h49.43a1.221 1.221 0 001.205-.852 1.202 1.202 0 00-.512-1.375v.022zM38.927 27.19l4.44 5.385H31.535l7.39-5.385zM28.223 8.53a1.215 1.215 0 00-.924-.93l-6.963-1.592a1.955 1.955 0 01-1.245-.863 1.687 1.687 0 01-.203-1.32 2.003 2.003 0 012.406-1.358l7.65 1.748a4.513 4.513 0 013.394 3.444 4.461 4.461 0 01-1.704 4.502l-7.369 5.54a2.209 2.209 0 00-.4 3.14l3.944 4.774a2.323 2.323 0 003.16.406l7.595-5.568a3.203 3.203 0 014.32.5l7.24 8.738c.317.376.456.868.383 1.353-.084.504-.381.95-.817 1.225a2.203 2.203 0 01-2.817-.518l-5.166-6.231a2.368 2.368 0 00-3.189-.39l-9.272 6.85a3.21 3.21 0 01-4.321-.496L13.53 18.854a2.805 2.805 0 01.18-3.897 3.212 3.212 0 014.552.312c.414.509 1.163.6 1.69.206l7.763-5.725c.391-.276.58-.754.484-1.22h.023z" />
				</G>
			</Svg>
		</View>
	);
}
