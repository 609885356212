import IMask from 'imask/esm/imask';

export default function formatPhoneNumber(phoneNumber: string): string {
	if (typeof phoneNumber !== 'string') {
		console.error(
			`formatPhoneNumber: phone number should be a string but instead received "${typeof phoneNumber}"`
		);
		return `${phoneNumber}`;
	}

	const mask = IMask.createMask({ mask: '000-000-0000' });
	return mask.resolve(phoneNumber);
}
