import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { G, GProps, Path } from 'react-native-svg';

export default function Sparkles(props: GProps) {
	const { colors } = useStyleRules();

	return (
		<G {...props}>
			<Path
				fill={colors.goal}
				d="M10.377,7.137c0.129,0,0.238,0.044,0.327,0.133c0.089,0.089,0.146,0.207,0.173,0.356l0.026,0.202
	l0.053,0.393c0.116,0.834,0.245,1.558,0.386,2.171c0.173,0.754,0.401,1.373,0.684,1.859c0.282,0.485,0.653,0.873,1.112,1.162
	c0.459,0.29,1.043,0.52,1.75,0.691c0.619,0.148,1.366,0.278,2.24,0.389l0.383,0.046c0.143,0.015,0.261,0.071,0.351,0.17
	C17.955,14.806,18,14.926,18,15.068c0,0.135-0.045,0.252-0.137,0.35c-0.092,0.099-0.209,0.16-0.351,0.181l-0.383,0.046
	c-0.875,0.11-1.623,0.238-2.245,0.384c-0.711,0.166-1.296,0.396-1.754,0.691c-0.459,0.294-0.83,0.683-1.112,1.168
	c-0.282,0.485-0.508,1.103-0.678,1.853c-0.117,0.515-0.226,1.114-0.327,1.794l-0.054,0.378l-0.053,0.395
	c-0.009,0.067-0.017,0.135-0.026,0.203c-0.021,0.142-0.077,0.258-0.169,0.35C10.619,22.954,10.508,23,10.379,23
	c-0.136,0-0.25-0.046-0.342-0.138c-0.092-0.092-0.148-0.209-0.169-0.35c-0.047-0.343-0.095-0.668-0.144-0.977l-0.059-0.362
	c-0.09-0.53-0.184-1.004-0.281-1.422C9.21,19.008,8.982,18.396,8.7,17.914c-0.282-0.482-0.653-0.866-1.112-1.152
	c-0.459-0.286-1.043-0.517-1.75-0.691c-0.133-0.033-0.272-0.064-0.415-0.096l-0.296-0.062c-0.051-0.01-0.102-0.02-0.154-0.03
	l-0.319-0.06c-0.054-0.01-0.109-0.019-0.165-0.029l-0.342-0.057C3.855,15.69,3.548,15.644,3.224,15.6
	c-0.143-0.021-0.259-0.081-0.347-0.181c-0.089-0.099-0.133-0.216-0.133-0.35c0-0.142,0.044-0.262,0.133-0.361
	c0.089-0.099,0.204-0.156,0.347-0.17l0.383-0.046l0.367-0.048c0.718-0.1,1.345-0.213,1.878-0.34c0.711-0.17,1.298-0.4,1.76-0.691
	c0.463-0.29,0.833-0.678,1.112-1.162c0.279-0.485,0.503-1.104,0.674-1.859c0.064-0.283,0.125-0.59,0.186-0.919l0.059-0.338
	l0.058-0.354c0.01-0.061,0.019-0.121,0.028-0.183l0.056-0.377c0.01-0.064,0.018-0.129,0.027-0.194l0.054-0.401
	c0.021-0.148,0.075-0.267,0.163-0.356c0.089-0.089,0.204-0.133,0.347-0.133V7.137z M3.745,4.11c0.197,0,0.31,0.099,0.342,0.296
	l0.023,0.177L4.145,4.84l0.036,0.24l0.036,0.226l0.036,0.21l0.036,0.194l0.036,0.179C4.335,5.947,4.347,6,4.359,6.053
	c0.089,0.39,0.226,0.679,0.413,0.87c0.187,0.191,0.469,0.335,0.847,0.43l0.159,0.038C5.833,7.404,5.892,7.418,5.952,7.43
	l0.189,0.038l0.206,0.039l0.221,0.039l0.236,0.039C6.845,7.593,6.886,7.6,6.927,7.606l0.259,0.039
	C7.274,7.66,7.349,7.698,7.411,7.763c0.062,0.064,0.092,0.145,0.092,0.244c0,0.198-0.105,0.319-0.316,0.36L6.928,8.419L6.685,8.471
	L6.348,8.545L6.143,8.592L5.864,8.66L5.697,8.703c-0.026,0.007-0.052,0.013-0.078,0.02c-0.378,0.102-0.66,0.249-0.847,0.44
	c-0.187,0.191-0.325,0.473-0.413,0.845c-0.017,0.074-0.036,0.155-0.053,0.24l-0.036,0.18c-0.005,0.031-0.012,0.063-0.017,0.095
	l-0.036,0.203l-0.036,0.219L4.146,11.18L4.11,11.43c-0.005,0.043-0.012,0.087-0.017,0.131c-0.006,0.099-0.042,0.179-0.107,0.239
	c-0.065,0.061-0.145,0.09-0.24,0.09c-0.204,0-0.32-0.113-0.347-0.34l-0.03-0.262l-0.031-0.246c-0.005-0.039-0.011-0.079-0.016-0.117
	l-0.032-0.222c-0.005-0.036-0.011-0.071-0.016-0.106l-0.034-0.199c-0.005-0.031-0.011-0.063-0.017-0.093l-0.035-0.175
	c-0.012-0.056-0.023-0.109-0.035-0.159C3.063,9.593,2.926,9.308,2.739,9.117c-0.187-0.191-0.467-0.338-0.842-0.44L1.74,8.635
	C1.685,8.622,1.628,8.607,1.568,8.594L1.381,8.552L1.18,8.51L0.964,8.469L0.732,8.427c-0.04-0.007-0.08-0.015-0.121-0.021
	L0.357,8.363C0.119,8.327,0,8.207,0,8.003C0,7.811,0.092,7.694,0.276,7.65l0.42-0.083L0.93,7.519l0.22-0.047
	c0.036-0.008,0.07-0.016,0.104-0.024l0.197-0.046l0.182-0.045L1.8,7.313c0.027-0.007,0.053-0.015,0.078-0.021
	c0.381-0.107,0.666-0.253,0.857-0.44c0.19-0.188,0.33-0.465,0.419-0.833C3.182,5.896,3.211,5.756,3.24,5.6l0.034-0.195
	C3.279,5.37,3.284,5.336,3.289,5.3l0.032-0.22l0.031-0.237l0.03-0.253c0.005-0.044,0.01-0.088,0.015-0.133
	c0.027-0.234,0.143-0.35,0.347-0.35L3.745,4.11z M8.418,0.001c0.111,0,0.181,0.058,0.208,0.175l0.029,0.147l0.063,0.303l0.039,0.18
	l0.038,0.163L8.83,1.114C8.835,1.137,8.842,1.159,8.847,1.18c0.062,0.234,0.148,0.412,0.261,0.536C9.22,1.84,9.39,1.937,9.618,2.008
	C9.656,2.02,9.697,2.032,9.74,2.044l0.138,0.037l0.155,0.038l0.171,0.039c0.03,0.007,0.061,0.013,0.091,0.02l0.299,0.063
	c0.129,0.021,0.194,0.095,0.194,0.223c0,0.116-0.053,0.188-0.16,0.216l-0.332,0.07l-0.179,0.04L9.952,2.83L9.805,2.868L9.673,2.905
	c-0.021,0.006-0.04,0.012-0.059,0.018C9.382,2.994,9.209,3.09,9.094,3.21C8.978,3.33,8.891,3.508,8.833,3.745L8.8,3.884L8.767,4.04
	L8.731,4.214L8.674,4.508l-0.04,0.219C8.613,4.854,8.542,4.918,8.42,4.918c-0.116,0-0.187-0.064-0.214-0.191l-0.04-0.219
	L8.109,4.214L8.058,3.959L8.027,3.812c-0.005-0.024-0.01-0.045-0.015-0.067c-0.054-0.237-0.14-0.416-0.255-0.536
	c-0.116-0.12-0.289-0.214-0.52-0.282L7.113,2.892L6.899,2.836L6.651,2.775L6.466,2.732l-0.2-0.045
	C6.13,2.666,6.062,2.592,6.062,2.464c0-0.116,0.053-0.188,0.16-0.216l0.237-0.053l0.187-0.044L6.817,2.11l0.155-0.039
	c0.025-0.007,0.048-0.012,0.071-0.019l0.13-0.037c0.021-0.006,0.04-0.012,0.059-0.018C7.46,1.926,7.63,1.831,7.743,1.71
	c0.112-0.12,0.199-0.299,0.261-0.536l0.034-0.138c0.005-0.025,0.012-0.049,0.017-0.075L8.09,0.798L8.128,0.62l0.039-0.195
	l0.04-0.212C8.234,0.071,8.305,0,8.421,0L8.418,0.001z"
			/>
		</G>
	);
}
