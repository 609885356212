import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function CalendarGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(211, 201);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fillRule="nonzero" fill="none">
					<Circle fill="#E4F3FF" cx={100.441} cy={100.441} r={100.441} />
					<Path
						d="M199.44 80.536c-.868-7.066-2.354-11.648-4.215-14.463-6.664-10.578-18.219-.748-23.268-.717-6.47.039-25.942-9.846-25.315 18.435.3 13.414 9.138 20.658 13.076 24.076 6.82 5.917 10.713.914 14.865.62 2.815-.2 5.4 2.188 9.04 1.562l.128-.024h.022c2.539-.489 5.59-2.458 9.581-7.766a28.6 28.6 0 006.086-21.723z"
						fill="#E71D2C"
					/>
					<Path
						d="M178.089 65.208c.066.946-1.99 3.219-5.071 3.436-3.081.217-5.805-1.8-5.873-2.737-.068-.937 2.663.619 5.745.4 3.082-.219 5.135-2.046 5.199-1.099z"
						fill="#1A1818"
						opacity={0.2}
					/>
					<Path
						d="M172.64 59.864a7.12 7.12 0 00-2.927-6.958c-3.806-2.654-8.529-3.958-11.7-1.122a28.847 28.847 0 01-6.488 4.7s12.287 9.431 21.115 3.38z"
						fill="#A5E974"
					/>
					<Path
						d="M172.293 67.107a40.069 40.069 0 01-.169-9.8c.305-1.46.515-2.94.627-4.428 0 0 3.234-.2 3.048 1.147-.153 1.107-1.779 3.918-2.031 7.341-.177 2.409-.167 5.022-.167 5.022s-.202 1.579-1.308.718z"
						fill="#8F8379"
					/>
					<Path
						d="M206.569 126.746h-73.658c-3.568 0-6.459-2.132-6.459-4.758v-5.836c0-2.627 2.891-4.758 6.459-4.758h72.825c.149 0 .3 0 .443.011a14.589 14.589 0 00.372 15.3c.004.014.01.028.018.041z"
						fill="#EDE1E5"
					/>
					<Path
						d="M206.544 126.71c-.2.019-.413.03-.623.035h-73.017c-3.568 0-6.458-2.132-6.458-4.758v-5.836c0-2.627 2.891-4.758 6.458-4.758h72.825c.149 0 .3 0 .444.011a14.589 14.589 0 00.371 15.3v.006z"
						fill="#EEEBD4"
					/>
					<Path
						d="M209.536 127.269c0 .29-.319.524-.711.524h-1.492l.033.04h-75.559c-4.073 0-7.373-2.432-7.373-5.432v-6.656c0-3 3.3-5.432 7.373-5.432h77.055c.375.015.673.244.673.523s-.319.524-.712.524h-2.619c-.011.016-.02.032-.03.049a9.247 9.247 0 00-.442-.011h-72.825c-3.568 0-6.458 2.132-6.458 4.757v5.836c0 2.628 2.891 4.758 6.458 4.758h75.917c.393-.004.712.231.712.52z"
						fill="#00A382"
					/>
					<Path
						d="M204.214 119.338a7.688 7.688 0 01-.022-.577 14.458 14.458 0 011.984-7.351 8.122 8.122 0 00-.444-.011h-72.825c-3.568 0-6.459 2.132-6.459 4.758v3.181h77.766z"
						fill="#EEEBD4"
					/>
					<Path
						d="M206.569 161.832h-73.658c-3.568 0-6.459-2.132-6.459-4.758v-5.836c0-2.629 2.891-4.758 6.459-4.758h72.825c.149 0 .3 0 .443.011a14.6 14.6 0 00.372 15.3c.004.014.01.028.018.041z"
						fill="#EDE1E5"
					/>
					<Path
						d="M206.544 161.792c-.2.019-.413.03-.623.035h-73.017c-3.568 0-6.458-2.132-6.458-4.758v-5.836c0-2.628 2.891-4.758 6.458-4.758h72.825c.149 0 .3 0 .444.011a14.594 14.594 0 00.371 15.3v.006z"
						fill="#EEEBD4"
					/>
					<Path
						d="M209.536 162.361c0 .288-.319.524-.711.524h-1.492l.033.041h-75.559c-4.073 0-7.373-2.433-7.373-5.433v-6.655c0-3 3.3-5.432 7.373-5.432h77.055c.375.014.673.244.673.524s-.319.523-.712.523h-2.619a.853.853 0 00-.03.051 8.102 8.102 0 00-.442-.01h-72.825c-3.568 0-6.458 2.132-6.458 4.757v5.836c0 2.628 2.891 4.759 6.458 4.759h75.917c.393-.014.712.221.712.515z"
						fill="#FFDC59"
					/>
					<Path
						d="M204.214 154.426a7.708 7.708 0 01-.022-.577 14.459 14.459 0 011.984-7.351 9.266 9.266 0 00-.444-.011h-72.825c-3.568 0-6.459 2.132-6.459 4.758v3.18l77.766.001z"
						fill="#EEEBD4"
					/>
					<Path
						d="M127.401 144.321h73.664c3.567 0 6.458-2.132 6.458-4.758v-5.836c0-2.627-2.891-4.758-6.458-4.758h-72.823c-.149 0-.3 0-.442.011a14.6 14.6 0 01-.372 15.3l-.027.041z"
						fill="#EDE1E5"
					/>
					<Path
						d="M127.423 144.286c.205.019.413.03.623.035h73.016c3.568 0 6.459-2.132 6.459-4.758v-5.836c0-2.627-2.891-4.758-6.459-4.758h-72.825c-.148 0-.3 0-.442.011a14.6 14.6 0 01-.372 15.3v.006z"
						fill="#EEEBD4"
					/>
					<Path
						d="M124.434 144.845c0 .29.318.524.711.524h1.49l-.032.04h75.559c4.073 0 7.372-2.432 7.372-5.433v-6.651c0-3-3.3-5.432-7.372-5.432h-77.055c-.375.014-.673.244-.673.524s.318.523.711.523h2.619l.03.051c.146-.008.294-.011.442-.011h72.831c3.568 0 6.458 2.132 6.458 4.758v5.836c0 2.628-2.891 4.758-6.458 4.758h-75.926c-.386-.007-.707.223-.707.513z"
						fill="#8E7EDA"
					/>
					<Path
						d="M129.756 136.915c.015-.2.022-.392.022-.577a14.458 14.458 0 00-1.984-7.351c.146-.007.294-.011.442-.011h72.825c3.567 0 6.458 2.132 6.458 4.758v3.181h-77.763z"
						fill="#EEEBD4"
					/>
					<Path
						d="M8.336 95.226a.673.673 0 01-.613.716H5.695a.673.673 0 01-.612-.716v-3.665a.674.674 0 01.612-.715h2.028c.365.032.637.35.613.715v3.665z"
						fill="#FA9CAD"
					/>
					<Path
						fill="#2EBCA0"
						d="M8.375 94.198v52.718l-.911-1.268-.008.01-.752 1.046-.758-1.057-.021.028-.914 1.271V94.198z"
					/>
					<Path fill="#313133" d="M7.311 149.385l-.583 1.335-.608-1.335z" />
					<Path
						fill="#F3E8BE"
						d="M8.376 146.916v.036l-1.064 2.435H6.125l-1.107-2.435.914-1.272.021-.029.758 1.057.752-1.046.008-.01z"
					/>
					<Path fill="#00A382" d="M7.45 94.198v51.461l-.752 1.046-.757-1.057-.021.028V94.198z" />
					<Path fill="#E0E1E3" d="M5.008 92.976h3.405v3.949H5.008z" />
					<Path
						d="M26.14 105.237a.673.673 0 01-.646.685l-2.02-.1a.674.674 0 01-.577-.745l.181-3.662a.674.674 0 01.647-.685l2.02.1c.362.05.618.38.576.744l-.181 3.663z"
						fill="#FA9CAD"
					/>
					<Path
						fill="#F16F5D"
						d="M26.228 104.215l-2.6 52.654-.847-1.311-.008.01-.8 1.01-.7-1.093-.022.028-.975 1.224 2.6-52.69z"
					/>
					<Path fill="#313133" d="M22.444 159.281l-.648 1.305-.541-1.364z" />
					<Path
						fill="#F3E8BE"
						d="M23.628 156.866v.036l-1.188 2.38-1.188-.059-.986-2.487.975-1.225.022-.027.7 1.093.8-1.01.008-.01z"
					/>
					<Path
						fill="#FF438F"
						d="M25.312 104.166l-2.539 51.4-.8 1.01-.7-1.093-.022.028 2.54-51.416z"
					/>
					<Path fill="#E0E1E3" d="M22.732 106.769l.193-3.946 3.402.167-.193 3.946z" />
					<Path
						d="M7.477 155.64a.673.673 0 00-.505-.795l-2.008-.287a.673.673 0 00-.707.622l-.519 3.629a.673.673 0 00.505.795l2.008.287a.674.674 0 00.707-.622l.519-3.629z"
						fill="#FA9CAD"
					/>
					<Path
						fill="#009FD5"
						d="M7.368 156.665l7.461-52.185-1.081 1.122-.006-.011-.6-1.142-.9.939-.017-.031-.725-1.388-7.465 52.223z"
					/>
					<Path fill="#313133" d="M14.127 101.879l-.388-1.405-.785 1.234z" />
					<Path
						fill="#F3E8BE"
						d="M14.828 104.476v-.034l-.708-2.562-1.178-.169-1.441 2.255.725 1.388.017.031.9-.939.6 1.142.006.012z"
					/>
					<Path
						fill="#0088C4"
						d="M6.459 156.533l7.282-50.943-.6-1.142-.9.939-.017-.032-7.285 50.96z"
					/>
					<Path fill="#E0E1E3" d="M3.863 157.397l.56-3.91 3.371.484-.56 3.91z" />
					<Path
						d="M15.654 156.741a.673.673 0 00-.505-.795l-2.008-.287a.673.673 0 00-.707.621l-.519 3.63a.674.674 0 00.505.795l2.008.287a.674.674 0 00.707-.622l.519-3.629z"
						fill="#FA9CAD"
					/>
					<Path
						fill="#8E7EDA"
						d="M15.546 157.765l7.46-52.185-1.081 1.122-.005-.011-.6-1.142-.9.939-.017-.031-.725-1.388-7.465 52.223z"
					/>
					<Path fill="#313133" d="M22.303 102.979l-.388-1.405-.785 1.235z" />
					<Path
						fill="#F3E8BE"
						d="M23.006 105.576v-.036l-.708-2.562-1.178-.169-1.441 2.255.725 1.388.017.031.9-.939.6 1.142.006.011z"
					/>
					<Path
						fill="#6457A1"
						d="M14.636 157.633l7.282-50.943-.6-1.141-.9.939-.017-.031-7.284 50.961z"
					/>
					<Path fill="#E0E1E3" d="M12.04 158.497l.557-3.91 3.371.481-.557 3.91z" />
					<Path
						d="M12.783 146.22a.673.673 0 00-.5-.795l-2.008-.286a.673.673 0 00-.707.621l-.518 3.63a.673.673 0 00.505.795l2.007.287a.674.674 0 00.707-.622l.514-3.63z"
						fill="#FA9CAD"
					/>
					<Path
						fill="#F5EE6F"
						d="M12.677 147.243l7.46-52.185-1.081 1.122-.006-.012-.6-1.143-.9.939-.017-.031-.725-1.388-7.465 52.223z"
					/>
					<Path fill="#313133" d="M19.432 92.459l-.388-1.405-.786 1.234z" />
					<Path
						fill="#F3E8BE"
						d="M20.134 95.056v-.036l-.708-2.562-1.178-.168-1.441 2.255.725 1.388.017.032.9-.939.6 1.142.006.011z"
					/>
					<Path
						fill="#FFC230"
						d="M11.767 147.113l7.282-50.943-.6-1.143-.9.939-.017-.032-7.283 50.962z"
					/>
					<Path fill="#E0E1E3" d="M9.17 147.977l.56-3.909 3.37.482-.56 3.91z" />
					<Path fill="#FFC230" d="M2.825 121.937h24.456v39.728H2.825z" />
					<Path fill="#FFF" d="M25.098 71.437h121.904v91.577H25.098z" />
					<Path
						d="M26.976 54.445c-1.036 0-1.876.84-1.876 1.875v15.12H147v-17l-120.024.005z"
						fill="#5A9CC3"
					/>
					<Path fill="#D0E5F7" d="M147.001 71.437h10.521v91.577h-10.521z" />
					<Path
						d="M155.646 54.445h-8.641v17h10.521V56.328a1.875 1.875 0 00-1.872-1.879l-.008-.004z"
						fill="#5990B5"
					/>
					<Path
						d="M40.799 60.707a2.237 2.237 0 11-2.237-2.237 2.237 2.237 0 012.237 2.237z"
						fill="#243942"
					/>
					<Path
						d="M38.568 59.757a5.312 5.312 0 115.312-5.312h2.012a7.324 7.324 0 10-7.324 7.324v-2.012zM59.66 60.707a2.237 2.237 0 11-4.474 0 2.237 2.237 0 014.474 0z"
						fill="#243942"
					/>
					<Path
						d="M57.421 59.757a5.312 5.312 0 115.312-5.312h2.012a7.324 7.324 0 10-7.323 7.324l-.001-2.012zM78.516 60.707a2.237 2.237 0 11-4.474 0 2.237 2.237 0 014.474 0z"
						fill="#243942"
					/>
					<Path
						d="M76.28 59.757a5.312 5.312 0 115.312-5.312h2.012a7.324 7.324 0 10-7.324 7.324v-2.012zM97.376 60.707a2.237 2.237 0 11-4.474 0 2.237 2.237 0 014.474 0z"
						fill="#243942"
					/>
					<Path
						d="M95.139 59.757a5.312 5.312 0 115.312-5.312h2.012a7.324 7.324 0 10-7.323 7.324l-.001-2.012zM116.233 60.707a2.236 2.236 0 11-4.472-.002 2.236 2.236 0 014.472.002z"
						fill="#243942"
					/>
					<Path
						d="M113.997 59.757a5.312 5.312 0 115.311-5.313h2.012a7.324 7.324 0 10-7.324 7.324l.001-2.011zM135.091 60.707a2.236 2.236 0 11-4.472-.002 2.236 2.236 0 014.472.002z"
						fill="#243942"
					/>
					<Path
						d="M132.855 59.757a5.312 5.312 0 115.311-5.313h2.012a7.324 7.324 0 10-7.323 7.324v-2.011z"
						fill="#243942"
					/>
					<G opacity={0.6} fill="#E8E8E8">
						<Path d="M99.371 127.129h17.674v9.208H99.371zM77.817 127.129h17.674v9.208H77.817zM99.371 103.726h17.674v9.209H99.371zM77.817 103.726h17.674v9.209H77.817zM34.71 103.726h17.674v9.209H34.71zM34.71 115.428h17.674v9.208H34.71zM56.263 103.726h17.674v9.209H56.263zM56.263 127.129h17.674v9.208H56.263zM120.924 103.726h17.674v9.209h-17.674zM120.924 115.428h17.674v9.208h-17.674zM77.817 115.428h17.674v9.208H77.817zM99.371 115.428h17.674v9.208H99.371zM120.924 127.129h17.674v9.208h-17.674zM56.263 115.428h17.674v9.208H56.263zM34.71 92.024h17.674v9.208H34.71zM56.263 92.024h17.674v9.208H56.263zM77.817 92.024h17.674v9.208H77.817zM77.817 138.831h17.674v9.209H77.817zM99.371 92.024h17.674v9.208H99.371zM34.71 127.129h17.674v9.208H34.71zM34.71 138.831h17.674v9.209H34.71zM56.263 138.831h17.674v9.209H56.263zM120.924 92.024h17.674v9.208h-17.674zM99.371 138.831h17.674v9.209H99.371zM120.924 138.831h17.674v9.209h-17.674z" />
					</G>
					<G fill="#D0E5F7">
						<Path d="M147.001 142.855s-.5 8.163-10.273 10.907h10.273v-10.907z" />
						<Path d="M126.456 142.855H14.825s.5 8.163 10.273 10.907h111.631c-9.77-2.744-10.273-10.907-10.273-10.907z" />
					</G>
					<Path
						fill="#D0E5F7"
						d="M25.098 86.344h121.904v1H25.098zM25.098 71.437h121.904v1H25.098zM154.7 71.437h2.822v91.577H154.7zM25.098 160.599h121.904v2.415H25.098z"
					/>
				</G>
			</Svg>
		</View>
	);
}

export default CalendarGraphic;
