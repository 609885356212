import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { View, ViewProps } from 'react-native';

interface IVrArrayProps extends ViewProps {
	increment?: number;
	children: React.ReactNode;
}

export default function VrArray({ increment = 5, children, ...viewProps }: IVrArrayProps) {
	const { notLastChild, vr } = useStyleHelpers();
	const childrenArray = React.Children.toArray(children);

	return (
		<View {...viewProps}>
			{childrenArray.map((child, index) => (
				<View key={index} style={notLastChild(childrenArray.length, index, vr(increment))}>
					{child}
				</View>
			))}
		</View>
	);
}
