import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type APIHeaders = Record<string, string>;

declare global {
	interface FormData {
		append(name: string, value: FormDataValue, fileName?: string): void;
		append(name: string, value: string | number | boolean | null | undefined): void;
		set(name: string, value: FormDataValue, fileName?: string): void;
		set(name: string, value: string | number | boolean | null | undefined): void;
	}
}

class APIService {
	private _baseUrl: string;
	private _headers: APIHeaders;

	constructor(baseUrl: string, headers: APIHeaders = {}) {
		this._baseUrl = baseUrl;
		this._headers = headers;
	}

	private async _makeRequest<T>(apiRequestFn: () => Promise<AxiosResponse<T>>) {
		const response = await apiRequestFn();
		return this._onSuccess<T>(response);
	}

	private _createConfig(additionalConfig: AxiosRequestConfig = {}) {
		return {
			...additionalConfig,
			headers: {
				...this._headers,
				...additionalConfig.headers,
			},
		};
	}

	public set baseUrl(value: string) {
		this._baseUrl = value;
	}

	public isApiUrl(path: string) {
		return path.indexOf(this._baseUrl) === 0;
	}

	public buildUrl(...pathPieces: string[]) {
		return [this._baseUrl, ...pathPieces].join('/').replace(/([^:])\/+/g, '$1/');
	}

	public get headers() {
		return this._headers;
	}

	public set headers(value: APIHeaders) {
		this._headers = value;
	}

	set bearerToken(value: string) {
		const newHeaders = {
			...this.headers,
		};

		if (value) {
			newHeaders.Authorization = `Bearer ${value}`;
		} else {
			delete newHeaders.Authorization;
		}

		this.headers = newHeaders;
	}

	// NOTE: slight deviation from standard axios API to allow easier passing of params
	get<T>(subPath: string, params = {}, config: AxiosRequestConfig = {}) {
		const url = this.buildUrl(subPath);

		return this._makeRequest<T>(() => {
			return axios.get<T>(url, {
				...this._createConfig(config),
				params,
			});
		});
	}

	getBlob(path: string, params = {}, config: AxiosRequestConfig = {}) {
		return this._makeRequest<Blob>(() => {
			return axios.get<Blob>(path, {
				...this._createConfig(config),
				params,
				responseType: 'blob',
			});
		});
	}

	post<T>(subPath: string, data = {}, config: AxiosRequestConfig = {}) {
		const url = this.buildUrl(subPath);

		return this._makeRequest<T>(() => {
			return axios.post<T>(url, data, this._createConfig(config));
		});
	}

	put<T>(subPath: string, data = {}, config: AxiosRequestConfig = {}) {
		const url = this.buildUrl(subPath);
		return this._makeRequest<T>(() => {
			return axios.put<T>(url, data, this._createConfig(config));
		});
	}

	patch<T>(subPath: string, data = {}, config: AxiosRequestConfig = {}) {
		const url = this.buildUrl(subPath);
		return this._makeRequest<T>(() => {
			return axios.patch<T>(url, data, this._createConfig(config));
		});
	}

	delete<T>(subPath: string, config: AxiosRequestConfig = {}) {
		const url = this.buildUrl(subPath);
		return this._makeRequest<T>(() => {
			return axios.delete<T>(url, this._createConfig(config));
		});
	}

	private _sendBlob<T>(
		method: 'POST' | 'PATCH',
		subPath: string,
		blobObj: Record<string, string | File>,
		dataObj: Record<string, string | number | boolean | null | undefined> = {}
	) {
		const formData = new FormData();

		Object.entries(blobObj).forEach((pair) => {
			if (typeof pair[1] === 'string') {
				formData.append(pair[0], {
					name: pair[0],
					// TODO: all blobs shouldn't be treated as images; this needs to be improved to be truly generic if non-images are to be used
					type: 'image/jpeg',
					uri: pair[1],
				});
			} else {
				// add formdata for blob items on mobile
				Object.entries(blobObj).forEach((pair) => {
					formData.append(pair[0], pair[1]);
				});
			}
		});

		// add formdata for non-blob items
		Object.entries(dataObj).forEach((pair) => formData.append(pair[0], pair[1]));

		switch (method) {
			case 'POST':
				return this.post<T>(subPath, formData);
			case 'PATCH':
				return this.patch<T>(subPath, formData);
		}
	}

	/**
	 * Posts blob data and optionally additional form data
	 *
	 * @param subPath API subpath
	 * @param blobUriObj An object containing key:uri pairs for blob data
	 * @param dataObj An object containing key:value pairs for the data to post.
	 */
	postBlob<T>(
		subPath: string,
		blobObj: Record<string, string | File>,
		dataObj: Record<string, string | number | boolean | null | undefined> = {}
	) {
		return this._sendBlob<T>('POST', subPath, blobObj, dataObj);
	}

	/**
	 * Patches blob data and optionally additional form data
	 *
	 * @param subPath API subpath
	 * @param blobUriObj An object containing key:uri pairs for blob data
	 * @param dataObj An object containing key:value pairs for the data to post.
	 */
	patchBlob<T>(
		subPath: string,
		blobObj: Record<string, string | File>,
		dataObj: Record<string, string | number | boolean | null | undefined> = {}
	) {
		return this._sendBlob<T>('PATCH', subPath, blobObj, dataObj);
	}
	_onSuccess<T>(response: AxiosResponse<T>) {
		return response.data;
	}
}

export default APIService;
