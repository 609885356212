import { IValidicData } from '../authentication/authenticationService';
import {
	IMobeAPIStandardError,
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
	mobeUnauthenticatedAPI,
} from '../mobeAPI';

export enum EligibilityUserStatus {
	RegisteredConsumer = 'RegisteredConsumer',
	NonRegisteredConsumer = 'NonRegisteredConsumer',
	NonRegisteredConsumerWithStoredPhone = 'NonRegisteredConsumerWithStoredPhone',
}

export interface IAccountEligibilityCheckResponse {
	consumerId: number;
	accessToken: string;
	eligibilityFlow: EligibilityUserStatus;
	phoneNumber: string;
	email: string;
	hasCoach: boolean;
}

export enum AccountRegistrationErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidPassword = 'INVALID_PASSWORD',
	EmailAlreadyRegistered = 'EXISTING_EMAIL',
	AccountAlreadyRegistered = 'EXISTING_REGISTRATION',
	ServerError = 'SERVER_ERROR',
}

export enum ResetPasswordErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidPassword = 'INVALID_PASSWORD',
	InvalidResetCode = 'INVALID_RESET_CODE',
	ExistingPassword = 'EXISTING_PASSWORD',
}

export enum ChangeEmailErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	UserDoesNotExist = 'USER_DOES_NOT_EXIST',
	IdenticalEmailProvided = 'IDENTICAL_EMAIL',
	EmailAlreadyUsed = 'EXISTING_EMAIL',
}

export enum ChangePasswordErrorCode {
	// app created
	None = 'NON_API_NONE',
	Unknown = 'NON_API_UNKNOWN',

	// API provided
	InvalidNewPassword = 'INVALID_NEW_PASSWORD',
	InvalidOldPassword = 'INVALID_OLD_PASSWORD',
	PreviouslyUsedPassword = 'USED_PASSWORD',
}

export interface IUpdatableUserProfile {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	alternatePhoneNumber: string;
	preferredName: string;
	preferredPronouns: string;
	height: number; // in inches
	avatar?: string | File;
	visionStatement: string;
	visionImage?: string | File;
}

export interface IFullUserProfile extends Omit<IUpdatableUserProfile, 'avatar' | 'visionImage'> {
	userId: number;
	email: string;
	memberDiKey: number;
	avatarUrl: string;
	visionImageUrl: string;
}

class AccountService {
	/**
	 * Perform sign-up eligibility check
	 *
	 * @param firstName
	 * @param lastName
	 * @param dateOfBirth yyyy-mm-dd
	 * @returns
	 */
	async eligibilityCheck(firstName: string, lastName: string, dateOfBirth: string) {
		try {
			const data = await mobeUnauthenticatedAPI.post<IAccountEligibilityCheckResponse>(
				'account/eligibility',
				{
					firstName,
					lastName,
					dateOfBirth,
				}
			);

			return mobeStandardSuccessProcessor<IAccountEligibilityCheckResponse>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<IAccountEligibilityCheckResponse>(error);
		}
	}

	async claimAccount(registrationToken: string, email: string, password: string) {
		try {
			await mobeUnauthenticatedAPI.post(
				'account/register',
				{
					email,
					password,
				},
				{
					headers: {
						Authorization: `Bearer ${registrationToken}`,
					},
				}
			);

			return mobeStandardSuccessProcessor<
				null,
				AccountRegistrationErrorCode,
				IMobeAPIStandardError
			>();
		} catch (error) {
			return mobeStandardErrorProcessor<null, AccountRegistrationErrorCode, IMobeAPIStandardError>(
				error
			);
		}
	}

	async resendWelcomeEmail(registrationToken: string) {
		try {
			await mobeUnauthenticatedAPI.post(
				'account/resend-welcome-email',
				{},
				{
					headers: {
						Authorization: `Bearer ${registrationToken}`,
					},
				}
			);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	async forgotPassword({ email }: { email: string }) {
		return mobeStandardQueryAndMutationProcessor(
			await (async () => {
				try {
					await mobeUnauthenticatedAPI.post('account/forgot-password', {
						email,
					});

					return mobeStandardSuccessProcessor();
				} catch (error) {
					return mobeStandardErrorProcessor<null>(error);
				}
			})()
		);
	}

	async resetPassword(resetToken: string, password: string) {
		try {
			await mobeAuthenticatedAPI.post('account/reset-password', {
				guid: resetToken,
				password,
			});

			return mobeStandardSuccessProcessor<null, ResetPasswordErrorCode, IMobeAPIStandardError>();
		} catch (error) {
			return mobeStandardErrorProcessor<null, ResetPasswordErrorCode, IMobeAPIStandardError>(error);
		}
	}

	async changeEmail(newEmail: string) {
		try {
			await mobeAuthenticatedAPI.post('account/change-email', {
				newEmail: newEmail,
				verifyEmail: newEmail,
			});

			return mobeStandardSuccessProcessor<null, ChangeEmailErrorCode, IMobeAPIStandardError>();
		} catch (error) {
			return mobeStandardErrorProcessor<null, ChangeEmailErrorCode, IMobeAPIStandardError>(error);
		}
	}

	async getEmailVerified(guid: string) {
		try {
			await mobeUnauthenticatedAPI.get(`account/verify-email-validation/${guid}`);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor<null>(error);
		}
	}

	async verifyEmail(guid: string) {
		try {
			await mobeUnauthenticatedAPI.post(`account/email-validation/${guid}`);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor<null>(error);
		}
	}

	async changePassword(oldPassword: string, newPassword: string) {
		try {
			await mobeAuthenticatedAPI.post('account/change-password', {
				oldPassword,
				newPassword,
			});

			return mobeStandardSuccessProcessor<null, ChangePasswordErrorCode, IMobeAPIStandardError>();
		} catch (error) {
			return mobeStandardErrorProcessor<null, ChangePasswordErrorCode, IMobeAPIStandardError>(
				error
			);
		}
	}

	async getProfile() {
		try {
			const data = await mobeAuthenticatedAPI.get<IFullUserProfile>('account/profile');

			data.phoneNumber = data.phoneNumber || '';
			data.alternatePhoneNumber = data.alternatePhoneNumber || '';

			return mobeStandardSuccessProcessor<IFullUserProfile>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<IFullUserProfile>(error);
		}
	}

	async updateProfile(updatedProfileData: Partial<IUpdatableUserProfile>) {
		try {
			const patchData = { ...updatedProfileData };
			const blobPatchData: Record<string, string | File> = {};

			if (patchData.avatar) {
				blobPatchData.avatar = patchData.avatar;
				delete patchData.avatar;
			}

			if (patchData.visionImage) {
				blobPatchData.visionImage = patchData.visionImage;
				delete patchData.visionImage;
			}

			await mobeAuthenticatedAPI.patchBlob(
				'account/profile',
				blobPatchData,
				patchData as Omit<IUpdatableUserProfile, 'avatar' | 'visionImage'>
			);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	async createValidicUser() {
		try {
			const validicData = await mobeAuthenticatedAPI.put<IValidicData>(
				'tracking-connections/enable-integration'
			);

			return mobeStandardSuccessProcessor<IValidicData>(validicData);
		} catch (error) {
			return mobeStandardErrorProcessor<IValidicData>(error);
		}
	}

	async deleteAccount() {
		return mobeStandardQueryAndMutationProcessor(
			await (async () => {
				try {
					await mobeAuthenticatedAPI.delete('account');

					return mobeStandardSuccessProcessor();
				} catch (error) {
					return mobeStandardErrorProcessor(error);
				}
			})()
		);
	}
}

const accountService = new AccountService();

export default accountService;
