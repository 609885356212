import { useAuth } from '@mobe/api/authentication/AuthContext';
import Heading from '@mobe/components/heading/Heading';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { IEmbeddedVideoViewProps } from './EmbeddedVideoView';
import GuideConnectCTA from './GuideConnectCTA';
import IsHelpfulModule from './IsHelpfulModule';
import './exploreContentViewStyles.css';
import { useSetupWistiaWebSdkScriptsAndHandlers } from './wistiaUtils';

export default function EmbeddedVideoView({
	contentItem,
	showGuideConnectCTA = true,
}: IEmbeddedVideoViewProps) {
	const auth = useAuth();
	const { t } = useTranslation();
	const { wrapperHorizontal, vr } = useStyleHelpers();

	useSetupWistiaWebSdkScriptsAndHandlers();

	React.useEffect(() => {
		function handleMessage(event: MessageEvent) {
			const messageData = event.data.message;

			if (!messageData) {
				return;
			}

			// Fire user activity event to refresh app timeout
			if (messageData === 'play' || messageData === 'watching') {
				auth.onUserActivity();
			}
		}

		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, []);

	if (!contentItem || !contentItem.contentHtml) {
		return null;
	}

	return (
		<View style={{ flex: 1 }}>
			<ScrollView contentContainerStyle={{ flexGrow: 1 }}>
				<View style={[wrapperHorizontal, { paddingVertical: 12 }]}>
					{contentItem.title && <Heading level="h2">{contentItem.title}</Heading>}
					{contentItem.timeToConsume && (
						<Text color="light" style={vr(3)}>
							{t('explore.consumeTimeVideo', { minutes: contentItem.timeToConsume })}
						</Text>
					)}
				</View>
				<View style={wrapperHorizontal}>
					<div
						id="explore-content"
						className="video-content"
						dangerouslySetInnerHTML={{ __html: contentItem.contentHtml }}
					/>
				</View>
				<View style={{ marginTop: 'auto' }}>
					<IsHelpfulModule contentId={contentItem.sharedContentId} />
					{showGuideConnectCTA && (
						<GuideConnectCTA
							showGuide={contentItem.callToActions.includes('GuideProfile')}
							showPharmacist={contentItem.callToActions.includes('PharmacistProfile')}
						/>
					)}
				</View>
			</ScrollView>
		</View>
	);
}
