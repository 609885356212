import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

export interface IControlledSliderProps {
	value: number;
	onValueChange: (number: number) => void;
}

export default function useControlledSliderProps<TFieldValues extends FieldValues = FieldValues>({
	name,
	control,
}: UseControllerProps<TFieldValues>) {
	const { field } = useController({ name, control });

	const sliderProps: IControlledSliderProps = {
		value: field.value,
		onValueChange: field.onChange,
	};

	return sliderProps;
}
