import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileEditScreenNavigationProp } from '../ProfileStackScreen';
import ProfileEditForm from './ProfileEditForm';

export default function ProfileEditScreen({
	navigation,
}: {
	navigation: ProfileEditScreenNavigationProp;
}) {
	const profileQuery = useProfileQuery();

	return (
		<DeferredLoadingIndicator isLoading={profileQuery.isLoading}>
			{profileQuery.isSuccess && <ProfileEditForm navigation={navigation} />}
		</DeferredLoadingIndicator>
	);
}

export function useProfileEditScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('profile.edit.screenTitle'),
	};
}
