import env from '@mobe/env/env';
import dynamicLinks from '@mobe/utils/dynamicLinks';
import messaging from '@mobe/utils/messaging';
import { LinkingOptions } from '@react-navigation/native';
import { Linking, Platform } from 'react-native';
import { AuthenticationStackParamList } from '../auth/AuthenticationStackScreen';
import { CONTENT_MODULE_ID_PARAM_KEY } from '../explore/screens/ExploreContentModuleScreen';
import { PLAN_SCROLL_T0_MODULE_PARAM_KEY } from '../plan/PlanScreen';
import { ACTION_STEP_DETAIL_ID_PARAM_KEY } from '../plan/screens/ActionStepDetailScreen';
import { GOAL_DETAIL_ID_PARAM_KEY } from '../plan/screens/GoalDetailScreen';
import { RootStackParamList } from './Navigation';

// Defining the auth stack screen linking urls here so they can be used in other functions
const AUTH_STACK_SCREEN_PATHS: Record<keyof AuthenticationStackParamList, string> = {
	APP_INTRO_SCREEN: 'intro',
	PROMPT_FOR_SMS_CODE_SCREEN: 'sms/enter',
	VERIFY_SMS_SCREEN: 'sms/verify',
	ELIGIBILITY_CHECK_SCREEN: 'eligibility-check',
	CLAIM_ACCOUNT_SCREEN: 'claim-account',
	LOGIN_SCREEN: 'sign-in',
	VERIFY_EMAIL_PROMPT_SCREEN: 'verify-email',
	VERIFIED_EMAIL_SCREEN: 'email-verified',
	FORGOT_PASSWORD_SCREEN: 'forgot-password',
	RESET_PASSWORD_SCREEN: 'reset-password',
	TERMS_AND_CONDITIONS_MODAL_SCREEN: 'auth/terms-and-conditions',
	SUCCESS_CLOSE_TAB_SCREEN: 'success',
	SCHEDULE_SCREEN: 'schedule',
	UNAUTHENTICATED_SURVEY_SCREEN: 'survey',
};

export function isURLAuthScreenURL(urlOrPath: string) {
	return Object.values(AUTH_STACK_SCREEN_PATHS).some((pathname) => urlOrPath.startsWith(pathname));
}

export function getPathAndSearchParamsFromURL(urlOrPath: string) {
	let path = urlOrPath;

	try {
		const url = new URL(urlOrPath);
		path = `${url.pathname}${url.search}`;
	} catch (error) {
		if (env.isDev) {
			console.log(`${urlOrPath}: not a valid url, hope its a valid path`);
		}
	}

	// Remove any starting slashes
	path = path.length && path[0] == '/' ? path.slice(1) : path;

	return path;
}

function processForRedirectAfterAuth(urlOrPath: string, isAuthenticated: boolean) {
	const redirectPath = getPathAndSearchParamsFromURL(urlOrPath);

	return !isAuthenticated && !isURLAuthScreenURL(redirectPath)
		? `mobehealthguide://${AUTH_STACK_SCREEN_PATHS.LOGIN_SCREEN}?redirectTo=${encodeURIComponent(
				redirectPath
		  )}`
		: `mobehealthguide://${redirectPath}`;
}

const getLinkingConfig = (isAuthenticated: boolean): LinkingOptions<RootStackParamList> => ({
	// Linking prefixes
	// https://reactnavigation.org/docs/5.x/navigation-container/#linkingprefixes
	// https://reactnavigation.org/docs/5.x/deep-linking/#multiple-subdomains
	prefixes: ['https://*.mobeforlife.com', 'http://localhost:19006', 'mobehealthguide://'],

	// Handles initial (quit state) deep linking
	// https://reactnavigation.org/docs/5.x/navigation-container/#linkinggetinitialurl
	// THIS DOES NOT WORK ON WEB!
	async getInitialURL() {
		// Check if app was opened from a deep link
		const initialDynamicLink = await dynamicLinks.getInitialLink();

		if (initialDynamicLink?.url) {
			return processForRedirectAfterAuth(initialDynamicLink.url, isAuthenticated);
		}

		// Check if app was opened from a message with a url data item
		const initialRemoteMessage = await messaging.getInitialNotification();
		const redirectToPath = initialRemoteMessage?.data?.redirectToPath;

		if (redirectToPath && typeof redirectToPath === 'string') {
			return processForRedirectAfterAuth(redirectToPath, isAuthenticated);
		}

		// iOS Dynamic Link initial link from quit state bug...
		// ... the issue may still happen trying to get initial link after install from app store
		// Use RN Linking to get initial url and try to use RNFirebase to manually resolve the link
		// https://github.com/invertase/react-native-firebase/issues/2660
		if (Platform.OS === 'ios') {
			try {
				const initialURLFromRN = await Linking.getInitialURL();

				if (initialURLFromRN) {
					const initialDynamicLink = await dynamicLinks.resolveLink(initialURLFromRN || '');

					if (initialDynamicLink?.url) {
						return processForRedirectAfterAuth(initialDynamicLink.url, isAuthenticated);
					}
				}
			} catch (error) {
				console.warn(error);
			}
		}
	},

	// Setup various service handlers that may need to trigger linking when app is in foreground and background
	// https://reactnavigation.org/docs/5.x/navigation-container#linkingsubscribe
	// THIS DOES NOT WORK ON WEB!
	subscribe(listener) {
		const unsubscribeDynamicLinksForeground = dynamicLinks.initForegroundDynamicLinkHandler(
			(link) => {
				if (link.url) {
					listener(processForRedirectAfterAuth(link.url, isAuthenticated));
				}
			}
		);

		// Handle foregrounded app messages
		const unsubscribeMessagingForeground = messaging.initForegroundMessageHandler(
			(remoteMessage) => {
				let badgeCount = undefined;

				if (Platform.OS === 'ios') {
					badgeCount = Number(remoteMessage.notification?.ios?.badge);
				} else if (Platform.OS === 'android') {
					badgeCount = Number(remoteMessage.notification?.android?.count);
				}

				// Only set badge to zero if we get a zero badge count message while app in foreground
				if (badgeCount === 0) {
					messaging.setBadgeCount(0);
				}
			}
		);

		// Handle background message that opened app
		const unsubscribeMessagingOpenedApp = messaging.initOnNotificationOpenedAppHandler(
			(remoteMessage) => {
				const redirectToPath = remoteMessage?.data?.redirectToPath;

				// Url to link to from message...
				if (redirectToPath && typeof redirectToPath === 'string') {
					listener(processForRedirectAfterAuth(redirectToPath, isAuthenticated));
				}
			}
		);

		return () => {
			unsubscribeDynamicLinksForeground();
			unsubscribeMessagingForeground();
			unsubscribeMessagingOpenedApp();
		};
	},

	// Configuration for matching screens with paths
	// https://reactnavigation.org/docs/5.x/configuring-links#mapping-path-to-route-names
	config: {
		screens: {
			// Not Found Screen / 404
			// https://reactnavigation.org/docs/5.x/configuring-links#handling-unmatched-routes-or-404
			// Not capitalizing below as the screen name is used as url slug for not found screen
			['not-found']: '*',
			MAINTENANCE_SCREEN: 'maintenance',

			// Auth stack
			AUTH_STACK: {
				initialRouteName: 'LOGIN_SCREEN',
				screens: {
					...AUTH_STACK_SCREEN_PATHS,
				},
			},

			// Modal stack/core authenticated app, menu, bottom tab navigators
			MODAL_STACK: {
				initialRouteName: 'MENU_STACK',
				screens: {
					MENU_STACK: {
						initialRouteName: 'BOTTOM_TAB_STACK',
						screens: {
							BOTTOM_TAB_STACK: {
								initialRouteName: 'HOME_SCREEN',
								screens: {
									HOME_SCREEN: 'home',
									ACTIVITIES_SCREEN: {
										initialRouteName: 'ACTIVITIES_NEW_SCREEN',
										screens: {
											ACTIVITIES_NEW_SCREEN: 'activities/new',
											ACTIVITIES_HISTORY_SCREEN: 'activities/history',
										},
									},
									TRACK_SCREEN: 'track',
									PLAN_SCREEN: `plan/:${PLAN_SCROLL_T0_MODULE_PARAM_KEY}?`,
									PROGRESS_SCREEN: 'progress',
									EXPLORE_SCREEN: 'explore',
								},
							},
							PROFILE_SCREEN: {
								initialRouteName: 'PROFILE_ROOT_SCREEN',
								screens: {
									PROFILE_ROOT_SCREEN: 'profile',
									PROFILE_EDIT_SCREEN: 'profile/edit',
								},
							},
							MY_GUIDE_SCREEN: 'my-guide',
							MY_PHARMACIST_SCREEN: 'my-pharmacist',
							CALLS_SCREEN: 'upcoming-calls',
							CONNECT_HEALTH_DATA_SCREEN: 'connect-health-data',
							SETTINGS_SCREEN: {
								initialRouteName: 'SETTINGS_ROOT_SCREEN',
								screens: {
									SETTINGS_ROOT_SCREEN: 'settings',
									SETTINGS_EDIT_PASSWORD_SCREEN: 'settings/edit-password',
									SETTINGS_EDIT_EMAIL_SCREEN: 'settings/edit-email',
									SETTINGS_ACCOUNT_SCREEN: 'settings/manage-account',
								},
							},
							DEV_TOOLS_SCREEN: 'dev-tools',
						},
					},
					EDUCATIONAL_NOTICE_SCREEN: 'notice',
					CHAT_SCREEN: {
						initialRouteName: 'CHAT_ROOT_SCREEN',
						screens: {
							CHAT_ROOT_SCREEN: 'chat',
							CHAT_DIRECT_MESSAGE_SCREEN: 'chat/message',
						},
					},
					APPOINTMENT_SCREEN: {
						path: 'appointment',
						screens: {
							APPT_GUIDE_MATCH_INTRO_SCREEN: 'guide-match/intro',
							APPT_PHARMACIST_INTRO_SCREEN: 'my-pharmacist',
							APPT_CONFIRMATION_SCREEN: 'confirmation',
							APPT_GUIDE_MATCH_SCREEN: {
								initialRouteName: 'GUIDE_MATCH_LANGUAGE_SCREEN',
								screens: {
									GUIDE_MATCH_LANGUAGE_SCREEN: 'guide-match/language',
									GUIDE_MATCH_GENDER_SCREEN: 'guide-match/gender',
									GUIDE_MATCH_SCHEDULE_SCREEN: 'guide-match/schedule',
									GUIDE_MATCH_INTERESTS_SCREEN: 'guide-match/interests',
									GUIDE_MATCH_SELECTION_SCREEN: 'guide-match/select-guide',
									GUIDE_MATCH_CONTACT_SCREEN: 'guide-match/contact',
								},
							},
							APPT_FOLLOW_UP_SCREEN: {
								initialRouteName: 'FOLLOW_UP_SCHEDULE_SCREEN',
								screens: {
									FOLLOW_UP_SCHEDULE_SCREEN: 'schedule',
									FOLLOW_UP_CONTACT_SCREEN: 'contact',
								},
							},
						},
					},
					CLIENT_PROGRAM_REFERRALS_DETAIL_SCREEN: 'recommended-program',
					ASSIGN_SURVEY_SCREEN: 'survey/assign',
					GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN: 'appointment/detail',
					APPOINTMENT_RESCHEDULE_SCREEN: 'appointment/reschedule',
					ACTIVITIES_DETAIL_SCREEN: 'activities/detail',
					TRACK_DETAIL_SCREEN: 'track/detail',
					TRACK_GOAL_MODAL_SCREEN: 'track/goal',
					TRACK_ENTRY_MODAL_SCREEN: 'track/entry',
					GOAL_HISTORY_SCREEN: 'plan/goal-history',
					GOAL_DETAIL_SCREEN: `plan/goal/:${GOAL_DETAIL_ID_PARAM_KEY}`,
					ACTION_STEPS_HISTORY_SCREEN: 'plan/action-step-history',
					ACTION_STEP_DETAIL_SCREEN: `plan/action-step/:${ACTION_STEP_DETAIL_ID_PARAM_KEY}`,
					ACTIVITIES_HISTORY_SCREEN: 'plan/activities-history',
					EXPLORE_VIEW_ALL_SCREEN: 'explore/category',
					EXPLORE_CONTENT_MODULE_SCREEN: `explore/content-module/:${CONTENT_MODULE_ID_PARAM_KEY}`,
					EXPLORE_DETAIL_SCREEN: 'explore/detail',
					FILE_VIEWER_SCREEN: 'file-viewer',
					TERMS_AND_CONDITIONS_MODAL_SCREEN: 'terms-and-conditions',
					MARKETPLACE_DETAIL_SCREEN: 'marketplace-detail',
					ONBOARDING_SCREEN: {
						screens: {
							ONBOARDING_INTRO_SCREEN: 'assessment',
							ONBOARDING_ACTIVITY_SCREEN: 'assessment/activity',
							ONBOARDING_NUTRITION_SCREEN: 'assessment/nutrition',
							ONBOARDING_SLEEP_SCREEN: 'assessment/sleep',
							ONBOARDING_STRESS_SCREEN: 'assessment/stress',
							ONBOARDING_MEDICATION_SCREEN: 'assessment/medication',
							ONBOARDING_CONFIRMATION_SCREEN: 'assessment/complete',
						},
					},
				},
			},
		},
		/**
		 * At the time of writing this, there doesn't appear to be a viable way to appropriately type
		 * a screen when it has its own nested navigator as well as its own parameters
		 * https://github.com/react-navigation/react-navigation/issues/9897
		 */
	} as any,
});

export default getLinkingConfig;
