import React from 'react';
import useBioAuthService, { IBioAuthContentObj } from './useBioAuthService';

export const useBioAuthContent = () => {
	const bioAuthService = useBioAuthService();

	const [bioAuthContent, setBioAuthContent] = React.useState<IBioAuthContentObj>({
		settingsLabel: '',
		promptMessage: '',
		cancelLabel: '',
	});

	React.useEffect(() => {
		(async () => {
			const content = await bioAuthService.getBioAuthMethodContent();
			setBioAuthContent(content);
		})();
	}, []);

	return bioAuthContent;
};
