import {
	BottomTabStackNavigationProps,
	BottomTabStackParamList,
} from '@mobe/features/navigation/bottomTabs/types';
import { useIsFocused } from '@react-navigation/native';
import React from 'react';

/**
 * Hook for handling the the bottom tab stack tabPress on nested top tab screens.
 * On bottom tab press of an already active tab, this will execute provided callback.
 */
export default function useOnBottomTabPress(
	parentNavigation: BottomTabStackNavigationProps<keyof BottomTabStackParamList>,
	callBack: () => void
) {
	const isFocused = useIsFocused();

	React.useEffect(() => {
		const unsubscribe = parentNavigation.addListener('tabPress', () => {
			if (isFocused) {
				callBack();
			}
		});
		return unsubscribe;
	});
}
