import { ExploreContentType } from '../explore/exploreService';
import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';
import { TrackerAbbreviation } from '../track/trackService';

export interface IGetActivitiesResponse {
	active: IActivityFull[];
	completed: IActivityFull[];
	completedSummary: {
		currentWeekCount: number;
		lifetimeCount: number;
	};
}

export enum ActivityType {
	MedSummary = 'MedSummary',
	SharedContent = 'SharedContent',
	Survey = 'Survey',
	System = 'System',
	GuideMatch = 'GuideMatch',
	Assessment = 'About',
	Track = 'Tracker',
	ClientProgramReferral = 'ClientProgramReferral',
}

/**
 * The shared interface between dashboard activities and regular activities
 */
export interface IActivity {
	id: number;
	title: string;
	type: ActivityType;
	sharedContentType: ExploreContentType | null;
	timeToConsumeInMinutes: number | null;

	/**
	 * Overloaded property used as contentId for shared content, id for PDF, or id for survey
	 */
	activityTargetId: string;

	/**
	 * Property represents either the URL for a survey or tracker abbreviation for a tracker activity
	 */
	activityTargetUrl: string | TrackerAbbreviation | null;
	description: string | null;
	note: string | null;
}

/**
 * The full activity interface is applicable to all activities other than what comes from the dashboard
 */
export interface IActivityFull extends IActivity {
	/**
	 * Date string
	 */
	dateCompleted: string;
	isCompleted: boolean;
}

export async function getAllActivities() {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const response = await mobeAuthenticatedAPI.get<IGetActivitiesResponse>('activities');

				return mobeStandardSuccessProcessor<IGetActivitiesResponse>(response);
			} catch (error) {
				return mobeStandardErrorProcessor<IGetActivitiesResponse>(error);
			}
		})()
	);
}

export async function markActivityComplete(activityId: number) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.post(`activities/${activityId}/complete`);

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}

export async function markActivityDismissed(activityId: number) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.post(`activities/${activityId}/dismiss`);

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}
