import { IExploreContentItemFull } from '@mobe/api/explore/exploreService';
import Heading from '@mobe/components/heading/Heading';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import MobePdf from '@mobe/components/mobePdf/MobePdf';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions, View } from 'react-native';

interface IPdfViewProps {
	contentItem: IExploreContentItemFull;
}

export default function PdfView({ contentItem }: IPdfViewProps) {
	const { t } = useTranslation();
	const { wrapper } = useStyleHelpers();
	const styleRules = useStyleRules();
	const windowDimensions = useWindowDimensions();

	return (
		<ScreenTemplate noPadding>
			<View style={wrapper}>
				{contentItem.title && <Heading level="h2">{contentItem.title}</Heading>}
				{contentItem.timeToConsume && (
					<Text color="light">
						{t('explore.consumeTime', { minutes: contentItem.timeToConsume })}
					</Text>
				)}
			</View>
			{contentItem.imageUrl && (
				<View style={{ maxHeight: 400 }}>
					<MobeImage
						source={{ uri: contentItem.imageUrl }}
						renderWidth={Math.min(windowDimensions.width, styleRules.spacing.maxWidth)}
						style={{ width: '100%', height: '100%', aspectRatio: 1.5 }}
						sizesArray={[750, 780, 828]}
					/>
				</View>
			)}
			<View style={{ marginBottom: 30 }}>
				<MobePdf title={contentItem.title} url={contentItem.fileUrl} style={{ flex: 1 }} />
			</View>
		</ScreenTemplate>
	);
}
