import Button from '@mobe/components/button/Button';
import Icon from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import ImageSelectSheet from '@mobe/components/imageSelectSheet/ImageSelectSheet';
import { BaseInput } from '@mobe/components/input';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { IMediaSelection } from '@mobe/utils/useImageAcquisitionService';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, Pressable, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useChatHubConnection } from '../chatHubConnectionContext';

const IMAGE_HEIGHT = 80;

interface IMessageToolbarProps {
	message: string;
	attachmentMediaUri: string;
	canSendMessage: boolean;
	isMessageSending: boolean;
	onChangeText: (text: string) => void;
	onMediaSelection: (mediaSelection: IMediaSelection) => void;
	onSendPress: () => void;
	onRemoveAttachment: () => void;
}

function MessageToolbar(
	{
		message,
		attachmentMediaUri,
		canSendMessage,
		isMessageSending,
		onChangeText,
		onMediaSelection,
		onSendPress,
		onRemoveAttachment,
	}: IMessageToolbarProps,
	ref: any
) {
	const { vr } = useStyleHelpers();
	const styles = useStyles();
	const { t } = useTranslation();
	const chatHub = useChatHubConnection();
	const [isImageUploadOpen, setIsImageUploadOpen] = React.useState(false);
	const [imageUploadButtonRef, setFocusToImageUploadButton] = useAccessibilityFocus();

	return (
		<View style={styles.container}>
			<View style={[styles.inputContainer, vr(4)]}>
				<BaseInput
					ref={ref}
					placeholder={t('chat.messageToolbar.messagePlaceholder')}
					autoCapitalize="sentences"
					autoCorrect={true}
					inputLeft={
						attachmentMediaUri
							? () => (
									<>
										{Platform.OS !== 'web' ? (
											<Image
												source={{ uri: attachmentMediaUri }}
												style={styles.attachmentPreviewImage}
											/>
										) : (
											<img src={attachmentMediaUri} height={IMAGE_HEIGHT} />
										)}
										<Pressable
											hitSlop={10}
											onPress={onRemoveAttachment}
											style={styles.removeAttachmentButtonStyles}
										>
											<Icon name="close" color="background" size={10} />
										</Pressable>
									</>
							  )
							: undefined
					}
					inputLeftContainerStyle={{
						paddingVertical: 10,
					}}
					autoHeight
					value={message}
					onChangeText={onChangeText}
				/>
				{chatHub.status === 'init' ||
				chatHub.status === 'reconnecting' ||
				chatHub.status === 'disconnected' ? (
					<View style={styles.connectionStatusIndicatorContainer}>
						<Text size="xs" color="light">
							{chatHub.status === 'init' || chatHub.status === 'reconnecting'
								? t('chat.messageToolbar.connecting')
								: chatHub.status === 'disconnected'
								? t('chat.messageToolbar.disconnected')
								: ''}
						</Text>
					</View>
				) : null}
			</View>
			<View style={styles.actionsContainer}>
				<View style={styles.actions}>
					<IconButton
						ref={imageUploadButtonRef}
						name="camera"
						size={30}
						aria-label={t('chat.messageToolbar.mediaButton')}
						onPress={() => setIsImageUploadOpen(true)}
					/>
				</View>
				<View>
					<Button
						size="sm"
						title={t('chat.messageToolbar.sendButton')}
						loading={isMessageSending}
						disabled={!canSendMessage}
						onPress={() => {
							onSendPress();
						}}
					/>
				</View>
			</View>
			<ImageSelectSheet
				isOpen={isImageUploadOpen}
				onClose={() => {
					setIsImageUploadOpen(false);
					setFocusToImageUploadButton();
				}}
				heading={t('chat.messageToolbar.imageSelection')}
				onSelect={(uri, mediaSelection) => onMediaSelection(mediaSelection)}
			/>
		</View>
	);
}

export default React.forwardRef(MessageToolbar);

function useStyles() {
	const styleRules = useStyleRules();
	const safeAreaInsets = useSafeAreaInsets();

	return StyleSheet.create({
		container: {
			paddingTop: 16,
			paddingHorizontal: styleRules.spacing.appHorizontalMargin,
			paddingBottom: Math.max(safeAreaInsets.bottom, 16),
			borderTopWidth: StyleSheet.hairlineWidth,
			backgroundColor: styleRules.colors.cardBackground,
			borderColor: styleRules.colors.stroke,
		},
		inputContainer: {},
		connectionStatusIndicatorContainer: {
			position: 'absolute',
			backgroundColor: styleRules.colors.cardBackground,
			paddingHorizontal: 10,
			paddingVertical: 4,
			top: -60,
			right: 0,
			borderRadius: 50,
		},
		actionsContainer: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		actions: {
			flexDirection: 'row',
			flexGrow: 1,
		},
		actionCamera: {
			marginLeft: 16,
		},
		removeAttachmentButtonStyles: {
			position: 'absolute',
			top: 5,
			right: 0,
			width: 22,
			height: 22,
			borderRadius: 15,
			backgroundColor: styleRules.colors.primary,
			borderColor: styleRules.colors.strokeLight,
			borderWidth: 1,
			justifyContent: 'center',
			alignItems: 'center',
		},
		attachmentPreviewImage: {
			height: IMAGE_HEIGHT,
			aspectRatio: 1,
			resizeMode: 'contain',
		},
	});
}
