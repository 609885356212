import Icon from '@mobe/components/icon/Icon';
import { Text } from '@mobe/components/text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Animated,
	Easing,
	LayoutChangeEvent,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
} from 'react-native';

type OverflowMode = 'wrap' | 'scroll';

interface ISubStepCountProps {
	totalCount: number;
	completedCount: number;
	overflowMode?: OverflowMode;
	isComplete?: boolean;
	style?: StyleProp<ViewStyle>;
}

export default function SubStepCount({
	totalCount,
	completedCount,
	overflowMode = 'wrap',
	isComplete = false,
	style,
}: ISubStepCountProps) {
	const styles = useStyles(overflowMode, isComplete);
	const { t } = useTranslation();

	/** Cached completedCount. Animation is only triggered after completedCount is greater than this value */
	const initialCompleteCount = React.useRef(completedCount);

	const [rowWidth, setRowWidth] = React.useState(0);
	const [wrapperWidth, setWrapperWidth] = React.useState(0);

	/** Calculated width of each individual item */
	const offsetIncrement = rowWidth / totalCount;
	/** The count after which the row will start animating to the left */
	const scrollStartCount = Math.floor(wrapperWidth / 2 / offsetIncrement);
	/** Calculated value for row offset animation. Will always be a multiple of offsetIncrement */
	const scrollOffset =
		overflowMode === 'scroll'
			? Math.min(
					Math.max(completedCount - scrollStartCount, 0) * offsetIncrement,
					Math.max(rowWidth - wrapperWidth, 0)
			  )
			: 0;

	React.useEffect(() => {
		if (overflowMode === 'wrap' || isComplete) return;

		if (rowWidth && wrapperWidth) {
			scrollOffsetAnimation.start();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowWidth, wrapperWidth, overflowMode]);

	const scrollOffsetRef = React.useRef(new Animated.Value(0)).current;
	const scrollOffsetAnimation = Animated.timing(scrollOffsetRef, {
		useNativeDriver: true,
		toValue: -scrollOffset,
		duration: 600,
		easing: Easing.inOut(Easing.cubic),
	});

	const fadeOutNumberRef = React.useRef(new Animated.Value(0)).current;
	const fadeOutNumberAnimation = Animated.timing(fadeOutNumberRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: 150,
		easing: Easing.ease,
	});

	const fadeInCheckmarkRef = React.useRef(new Animated.Value(0)).current;
	const fadeInCheckmarkAnimation = Animated.timing(fadeInCheckmarkRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: 375,
		easing: Easing.in(Easing.sin),
	});

	const afterGlowRef = React.useRef(new Animated.Value(0)).current;
	const afterGlowAnimation = Animated.timing(afterGlowRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: 400,
		easing: Easing.out(Easing.quad),
	});

	React.useEffect(() => {
		if (!isComplete && completedCount > initialCompleteCount.current) {
			fadeOutNumberRef.setValue(0);
			fadeInCheckmarkRef.setValue(0);
			afterGlowRef.setValue(0);
			Animated.sequence([
				fadeOutNumberAnimation,
				Animated.parallel([
					fadeInCheckmarkAnimation,
					Animated.sequence([Animated.delay(300), afterGlowAnimation]),
				]),
				Animated.delay(200),
				scrollOffsetAnimation,
			]).start();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completedCount]);

	const rowAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [{ translateX: scrollOffsetRef }],
	};

	const fadeOutAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				scale: fadeOutNumberRef.interpolate({
					inputRange: [0, 1],
					outputRange: [1, 0.7],
				}),
			},
		],
		opacity: fadeOutNumberRef.interpolate({
			inputRange: [0, 1],
			outputRange: [1, 0],
		}),
	};

	const fadeInAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				scale: fadeInCheckmarkRef.interpolate({
					inputRange: [0, 0.6, 1],
					outputRange: [0.5, 1.4, 1],
				}),
			},
		],
		opacity: fadeInCheckmarkRef,
	};

	const checkmarkAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		opacity: fadeInCheckmarkRef.interpolate({
			inputRange: [0, 0.6, 1],
			outputRange: [0, 0, 1],
		}),
	};

	const afterGlowAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				scale: afterGlowRef.interpolate({
					inputRange: [0, 1],
					outputRange: [1, 1.5],
				}),
			},
		],
		opacity: afterGlowRef.interpolate({
			inputRange: [0, 0.01, 1],
			outputRange: [0, 0.7, 0],
		}),
	};

	function handleOnLayoutWrapper(event: LayoutChangeEvent) {
		if (overflowMode === 'wrap') return;

		const width = event.nativeEvent.layout.width;
		setWrapperWidth(width);
	}

	function handleOnLayoutRow(event: LayoutChangeEvent) {
		if (overflowMode === 'wrap') return;

		const width = event.nativeEvent.layout.width;
		setRowWidth(width);
	}

	return (
		<View style={[styles.wrapper, style]} onLayout={handleOnLayoutWrapper}>
			<Animated.View
				accessible
				role="progressbar"
				aria-valuetext={t('plan.actionStepDetail.subStepCountAccessibilityLabel', {
					count: completedCount,
					total: totalCount,
				})}
				aria-valuenow={completedCount}
				aria-valuemin={0}
				aria-valuemax={totalCount}
				style={[styles.row, !isComplete && rowAnimatedStyles]}
				onLayout={handleOnLayoutRow}
			>
				{Array(totalCount)
					.fill('')
					.map((unused, index) => {
						const shouldAnimate =
							index + 1 === completedCount &&
							completedCount !== initialCompleteCount.current &&
							!isComplete;
						return (
							<Animated.View key={index} style={styles.subStep}>
								<Animated.View
									style={[
										styles.circle,
										styles.circle_number,
										shouldAnimate && fadeOutAnimatedStyles,
									]}
								>
									<Text aria-hidden size="sm" weight="medium" color="light">
										{index + 1}
									</Text>
								</Animated.View>
								{index + 1 <= completedCount && (
									<>
										<Animated.View
											style={[
												styles.circle,
												styles.circle_glow,
												shouldAnimate && afterGlowAnimatedStyles,
											]}
										/>
										<Animated.View
											style={[
												styles.circle,
												styles.circle_checked,
												shouldAnimate && fadeInAnimatedStyles,
											]}
										>
											<Animated.View style={shouldAnimate && checkmarkAnimatedStyles}>
												<Icon aria-hidden name="checkmark" color="cardBackground" size={8} />
											</Animated.View>
										</Animated.View>
									</>
								)}
							</Animated.View>
						);
					})}
			</Animated.View>
		</View>
	);
}

function useStyles(overflowMode: OverflowMode, isComplete: boolean) {
	const rules = useStyleRules();
	const gap = 10;
	const circleDiameter = 22 * rules.settings.fontScale;

	return StyleSheet.create({
		wrapper: {
			flexDirection: isComplete ? 'row-reverse' : 'row',
		},
		row: {
			flexDirection: 'row',
			alignContent: 'center',
			alignItems: 'center',
			minWidth: '100%',

			...(overflowMode === 'wrap' && {
				width: '100%',
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'center',
				rowGap: gap,
				columnGap: gap,
			}),
		},
		subStep: {
			width: circleDiameter,
			height: circleDiameter,

			...(overflowMode === 'scroll' && {
				marginRight: gap,
			}),
		},
		circle: {
			position: 'absolute',
			alignItems: 'center',
			justifyContent: 'center',
			width: circleDiameter,
			height: circleDiameter,
			borderRadius: circleDiameter / 2,
		},
		circle_number: {
			backgroundColor: rules.colors.strokeLight,
		},
		circle_checked: {
			backgroundColor: isComplete ? rules.colors.stroke : rules.colors.primary,
		},
		circle_glow: {
			backgroundColor: rules.colors.primary,
		},
	});
}
