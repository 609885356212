import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function TrackerEmotionalHealthGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(240, 240);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#EB956C"
					d="M25.76 89.51c-.12-.09-.27-.17-.43-.17-.19 0-.35.12-.44.28-.12.2-.15.44-.2.66-.06.24-.11.49-.17.73-.12.49-.23.98-.35 1.47-.05.22-.09.44-.1.67 0 .23.01.46.01.7 0 .91-.13 1.82-.25 2.73-.12-.11-.26-.21-.39-.31-.01-.15-.01-.3-.02-.44-.01-.33-.03-.66-.05-.99-.04-.67-.09-1.33-.16-1.99a8.38 8.38 0 0 1-.05-.47c.01-.02.02-.05.03-.08.09-.21.18-.42.25-.63.16-.42.29-.85.38-1.29.15-.77.18-1.75-.4-2.36-.22-.23-.58-.4-.88-.25-.22.11-.32.33-.4.54-.1.23-.2.46-.3.7-.19.46-.39.91-.58 1.37-.18.43-.33.88-.42 1.34-.09.47-.11.96-.13 1.44-.02.35-.03.69-.05 1.04-.21-.56-.46-1.09-.75-1.61-.1-.18-.2-.35-.31-.51 0-.22-.22-.7-.25-.92-.1-.85-1.2-2.8-2.15-3.2-.2-.09-.62-.12-.84-.09-.22.02-.4.16-.43.39-.01.11 0 .22.02.33l.09.4.18.85c.23 1.12.59 2.12.82 3.23.12.56.7 1.57.81 2.13l.18.83c.05.22.14.67.14.67-.02.09-.03.19-.03.28 0 .18.01.36.05.53.07.36.95 1.65.78 1.49.3.29 1.09 1.02 1.42 1.29 1.29 1.05 2.27 1.43 3.82 2.01.7.27 1.44.48 2.2.48.72 0 1.45-.22 2.01-.68.27-.23.51-.52.64-.85.07-.18.11-.36.1-.54s-.06-.36-.14-.53c-.16-.36-.38-.68-.6-1.01-.23-.35-.48-.69-.72-1.04-.23-.35-.46-.7-.64-1.07-.17-.35-.32-.71-.43-1.08a14.5 14.5 0 0 1-.31-1.13c-.09-.39-.16-.78-.26-1.18 0-.01 0-.02-.01-.04.09-.23.17-.46.24-.7.12-.42.19-.84.19-1.27 0-.4-.05-.81-.18-1.19-.12-.33-.29-.7-.58-.92"
				/>
				<Path
					fill="#FFB082"
					d="M43.62 110.17c-.74-1.74-1.33-3.58-2.39-5.17v-.47c-.05-.74-.22-1.47-.58-2.13-.36-.64-.91-1.16-1.6-1.43-.68-.27-1.42-.35-2.15-.33-.44 0-.89.03-1.33.09-.19.02-.8-.06-.84-.07-.31-.12-1.46-1.08-1.81-1.43-.08-.33-.32-.45-.49-.75-.45-.78-1.17-1.36-1.87-1.92-.65-.51-1.3-1.09-2.08-1.4-.35-.13-.72-.22-1.09-.19-.34.02-.68.12-.98.29-.28.16-.53.41-.65.71-.13.32-.14.68-.05 1.02.09.35.27.67.46.97.24.39.49.76.73 1.15.48.76.96 1.51 1.44 2.27-.02 0-.04-.01-.05-.02-.91-.27-1.84-.5-2.78-.6-.33-.04-.67-.06-1.01-.06-.03-.02-.06-.05-.1-.07-.16-.12-.33-.24-.5-.36-.33-.23-.66-.47-.99-.71-.32-.22-.64-.42-.99-.57-.36-.15-.73-.27-1.09-.39-.22-.07-.43-.13-.64-.2-.06-.02-.12-.04-.19-.05a17.066 17.066 0 0 0-.85-1.12c-.5-.63-1.04-1.22-1.61-1.78-.15-.15-.3-.29-.46-.43-.1-.26-.22-.51-.35-.76-.2-.4-.45-.79-.73-1.15-.54-.71-1.21-1.32-2.02-1.71-.39-.19-.83-.38-1.27-.32-.39.06-.73.35-.74.76 0 .35.21.66.4.93.25.36.47.73.66 1.12.39.78.7 1.66 1.32 2.3.07.08.15.15.24.21l.08.14c.16.3.33.6.49.9.33.6.67 1.2 1 1.8.19.34.38.68.61 1 .02.02.06 0 .05-.02.13.2.27.39.43.56.35.38.78.65 1.24.89.46.23.92.45 1.38.69.92.49 1.8 1.05 2.64 1.67.4.29.79.61 1.18.93.3.26.61.51.94.74.2.14.41.27.63.38.32.19.65.34 1 .47.89.35 1.84.56 2.75.87.92.31 1.82.68 2.7 1.1.89.42 1.76.88 2.61 1.37.4.23.79.46 1.18.7.49.81 3.21 6.92 3.39 7.55.02.08.11.13.18.11a15.65 15.65 0 0 0 5.29-3.12c.37-.34.75-.69 1.08-1.08.16-.19-1.42-2.94-1.81-3.87l-.01-.01z"
				/>
				<Path
					fill="#FFC24D"
					d="M194.37 123.83c-20.23-4.26-33.01-24.95-28.53-46.22 3.53-16.77 16.73-28.89 32.08-31.19 4.11-.61 8.38-.51 12.65.39 12.13 2.55 21.58 11.01 26.24 21.96 3.11 7.32 4.09 15.74 2.29 24.26-4.47 21.27-24.5 35.06-44.74 30.81"
				/>
				<Path
					fill="#3B3B4D"
					d="M195.14 74.5a5.434 5.434 0 0 1-6.44 4.2 5.434 5.434 0 0 1-4.2-6.44 5.439 5.439 0 0 1 6.44-4.2c2.94.62 4.82 3.5 4.2 6.44zM221.97 83.45c-.65 3.08-3.67 5.05-6.75 4.4s-5.05-3.67-4.4-6.75a5.696 5.696 0 0 1 6.75-4.4c3.08.65 5.05 3.67 4.4 6.75zM180.02 98c4.28 1.26 8.18 2.44 17.02 4.99 9.13 2.64 13.81 3.99 17.52 5.13.83.26 1.71-.22 1.97-1.05s-.22-1.71-1.05-1.97c-3.75-1.15-8.44-2.5-17.57-5.14-8.83-2.55-12.7-3.73-17.01-4.99-.84-.25-1.71.23-1.96 1.07-.25.84.23 1.72 1.07 1.96"
				/>
				<Path
					fill="#FFC24D"
					d="M74.52 54.29c-3.45 21.48-22.81 36.23-43.25 32.95C10.85 83.96-2.92 63.88.53 42.41 3.98 20.93 23.34 6.17 43.78 9.46c20.43 3.28 34.2 23.36 30.75 44.84"
				/>
				<Path
					fill="#3B3B4D"
					d="M29.8 38.24c-.48 2.97-3.27 4.99-6.24 4.51s-4.99-3.27-4.51-6.24A5.432 5.432 0 0 1 25.28 32c2.97.48 4.99 3.27 4.51 6.24M57.79 46.12a5.333 5.333 0 0 1-6.12 4.42 5.34 5.34 0 0 1-4.43-6.12 5.339 5.339 0 0 1 10.54 1.7M14.47 55.95c.83 3.21 2.13 6.61 4.45 9.59a19.09 19.09 0 0 0 4.29 4.04c1.73 1.18 3.74 2.15 6.09 2.84 2.33.69 4.65.98 6.87.98 3.56 0 6.84-.73 9.53-1.74 1.35-.5 2.54-1.08 3.56-1.67 1.01-.6 1.85-1.22 2.49-1.87.61-.63.6-1.63-.02-2.24-.63-.61-1.63-.6-2.24.03-.36.37-.99.86-1.83 1.35-1.26.74-2.96 1.49-4.94 2.05-1.97.56-4.21.92-6.55.92-1.94 0-3.96-.25-5.96-.85-2.06-.61-3.77-1.44-5.2-2.42-2.16-1.47-3.72-3.3-4.91-5.32-1.19-2.03-1.98-4.26-2.56-6.48a1.562 1.562 0 0 0-1.92-1.13c-.85.22-1.36 1.08-1.14 1.92"
				/>
				<Path
					fill="#70408C"
					d="M167.03 230.47c-7.87-9.64-14.58-18.82-17.68-25.53-.36-.77-.66-1.5-.92-2.2-9.24-25.12-52.33-3.67-58.29-.57l-.68.37c-.3.94-.7 2.22-1.2 3.81-1.64 5.28-4.26 13.92-7.09 24.11h85.85l.01.01z"
				/>
				<Path
					fill="#EB956C"
					d="M192.24 143.66c.29.93 1.18 1.45 2.05 1.77 2.01.74 4.19.72 6.29.49 1.06-.11 2.11-.27 3.16-.43 1.05-.16 2.09-.36 3.12-.6.51-.13 1.02-.26 1.52-.43.44-.15.88-.35 1.2-.7.23-.26.4-.56.56-.89.12-.12.23-.25.35-.36.25-.24.51-.46.78-.66.47-.35.99-.6 1.47-.93.23-.16.45-.35.64-.57.2-.24.36-.51.49-.8.13-.3.25-.61.37-.92.12-.32.85-2.12 1.05-2.58.2-.47.42-.94.67-1.39.21-.4.45-.78.68-1.16.23-.37.44-.77.53-1.2.04-.19.05-.4-.08-.57-.12-.16-.31-.24-.5-.28-.39-.08-.8.05-1.14.23-.35.19-.65.46-.93.75-.27.29-.51.6-.73.93-.22.35-.42.7-.6 1.06-.09.18-.18.36-.25.54-.08.19-.13.39-.19.59v.05c-.1.2-.2.4-.3.58-.54.98-1.22 1.88-2.04 2.64-.2.19-.42.37-.63.54 0-.23 0-.46-.01-.68 0-.32-.01-.64-.02-.96-.02-.64-.04-1.29-.05-1.93-.02-.62-.03-1.24-.09-1.85s-.2-1.2-.39-1.78c-.19-.58-.42-1.16-.63-1.73-.22-.6-.45-1.2-.68-1.81-.09-.22-.22-.49-.5-.48-.25 0-.46.25-.61.42-.32.39-.5.88-.54 1.38-.05.54.06 1.07.26 1.57.07.19.15.37.24.54l.03.6c.02.43.04.87.05 1.3 0 .43 0 .87-.02 1.3-.01.42-.04.84-.07 1.26-.32.64-.75 1.22-1.28 1.7-.26.25-.56.47-.87.65l-.36-.15c.06-.23.12-.46.17-.7.1-.4.19-.8.29-1.19l.12-.54c.04-.18.09-.35.09-.53 0-.35-.21-.65-.37-.95-.36-.65-.73-1.29-1.08-1.94-.17-.3-.33-.6-.55-.87-.22-.27-.46-.51-.71-.75-.53-.51-1.06-1.03-1.59-1.54-.05-.04-.12-.04-.16 0-.35.26-.47.69-.46 1.11.01.38.13.75.26 1.11.15.37.33.73.54 1.07.21.34.46.66.73.95.04.05.09.09.13.13.08.23.16.47.25.7.11.29.21.58.32.87.05.14.1.29.15.42-.18.32-.35.65-.47.99-.11.26-.2.54-.27.81-.03.1-.06.2-.08.31-.11.06-.2.13-.3.19-.41.27-.83.54-1.2.85-.18.15-.35.32-.5.5-.02.04-.05.07-.09.11-.13.03-.26.06-.38.09-.27.06-.53.12-.8.19l-.8.2c-.54.13-1.08.27-1.62.42-.51.13-1.04.26-1.55.4-.39.11-.77.23-1.13.42-.35.19-.67.45-.85.81-.22.4-.22.87-.09 1.29"
				/>
				<Path
					fill="#FFB082"
					d="M220.11 136.33c-.33-.15-.74-.09-1.07 0-.75.2-1.35.74-1.83 1.32-.26.32-.51.65-.74.99-.12.17-.23.35-.33.53-.08.05-.16.11-.23.16-.52.39-1.01.81-1.44 1.29-.22.24-.43.49-.63.75-.07.09-.15.19-.21.29-.25-.02-.51-.02-.75-.03-.65 0-1.31.06-1.95.19-.33.06-.64.14-.96.25-.02 0-.04 0-.05.01-.13-.05-.25-.09-.38-.14-.16-.05-.33-.09-.5-.13.28-.48.57-.96.85-1.43.14-.24.28-.47.42-.71.13-.23.27-.45.39-.69.21-.42.31-.93-.01-1.33-.29-.36-.77-.53-1.21-.54-.47-.01-.94.12-1.35.35-.43.24-.8.56-1.16.89-.35.33-.7.68-1.02 1.05-.33.37-.63.75-.88 1.18-.04.06-.08.13-.12.19-.07.05-.14.09-.22.13-.56.33-1.2.53-1.84.61-.33.04-.65.04-.98.02-.16-.02-.32-.04-.48-.07-.13-.02-.27-.07-.42-.08-.4-.09-.81-.13-1.22-.11a3.71 3.71 0 0 0-1.08.24c-.57.06-1.13-.06-1.67-.19-.59-.15-1.17-.31-1.78-.37-1.22-.12-2.47.11-3.54.72-.96.55-1.77 1.43-2.19 2.46-.21.52-.33 1.17-.23 1.76-.33.5-.63 1.01-.89 1.54-.43.88-.8 1.78-1.09 2.71-.3.92-.54 1.88-.7 2.84-.08.47-.16.94-.2 1.42-.02.23.04.43.25.55.19.11.43.16.64.23.73.25 1.5.25 2.25.4.27-.36.46-.71.75-1.08 3.2-3.96 7.25-5.2 8.88-5.56.88-.29 1.77-.56 2.67-.78.96-.24 1.94-.43 2.91-.66 1.61-.39 3.19-.92 4.65-1.7.89-.17 1.79-.34 2.68-.52l1.42-.3c.23-.05.47-.11.7-.16.03 0 .06-.02.09-.02.48 0 .96-.06 1.33-.4.19-.17.33-.37.48-.58l.53-.71c.35-.47.69-.95 1.04-1.43.21-.3.44-.6.64-.91.13-.12.25-.24.36-.37.27-.3.5-.65.71-1 .19-.31.36-.63.53-.96.2-.37.4-.76.51-1.18.09-.36.04-.75-.32-.92l-.01-.01zM105.29 102.36c2.34 8.73 1.92 9.88.59 12.43-1.32 2.53-5.37 1.51-5.16 4.35.18 2.3 5.09 8.65 13.97 8.23 9.15-.44 11.87-13.83 11.87-13.83s-10.42-10.95-15.31-20.11-5.96 8.93-5.96 8.93z"
				/>
				<Path
					fill="#C94F38"
					d="M112.2 102.96c-.66 0-1.32.32-1.74.89-.61.82-2.15 1.23-2.82 1.39-.46.11-.94.48-1.38.92.68 3.02.55 3.05.35 4.33l.42.4c3.72 0 6.81-6.29 6.61-7.16-.3-1.38-.53-.77-1.44-.77z"
				/>
				<Path
					fill="#78A3CC"
					d="M192.02 157.85c-2.63 2.04-16.63 17.78-28.15 18.31-4.75.22-9.26-1.68-14.86-9.02-.73-1.01-6.76-8.22-7.46-9.51-4.39 26.37 8.68 42.43 7.8 47.31-.1.54-.26 1.08-.5 1.61-1.99 4.39-15.52 6.81-29.6 7.8-9.3.23-18.44-.75-24.29-2.64-3.03-.98-5.18-2.2-5.99-3.63-.33-.57-.56-1.14-.7-1.72-.41-1.61-.19-3.27.37-5.04 1.4-4.43 4.94-9.44 6.13-15.53.26-1.3.4-2.66.4-4.08-.05-9.52-1.77-22.19-6.25-25.77-.05-.04-18.21-1.46-26.11-2.95-13.29-2.51-30-40.23-30-40.23s.74-.95 1.84-2.26c2.15-2.54 5.7-6.47 8.01-7.53.82-.37 1.47-.39 1.87.15 4.1 5.68 18.71 47.34 44.87 18.71 2.96-2.48 7.84-3.51 15.94-5.47 3.09-.75 6.28-1.27 9.37-1.42.44-.02.89-.03 1.34-.04 3.35-.05 6.83.3 10.15.91 6.05 1.12 11.56 3.1 14.81 5.11.75.47 1.39.94 1.88 1.4 13.92 13.11 12.06 30.16 22.06 34.04 6.94 2.7 16.34-8.38 22.05-12.44 1.07-.77 2.16-.08 3.12 1.32 1.37 2.04 2.44 5.56 2.69 8.41.19 1.97-.02 3.6-.78 4.19"
				/>
				<Path
					fill="#FFB082"
					d="M105.63 82.37c-6.58 6.63-10.91 25.51-3.09 24.96 7.77-.54 14.67-3.22 16.67-11.08 2-7.85 3.38-12.41 3.38-12.41l-16.95-1.47h-.01z"
				/>
				<Path
					fill="#4F3617"
					d="M102.66 82.36c1.91 4.05 6.88 7.42 8.12 9.04.71.91 2.9.87 3.57-.08.75-1.05 2.53-1.09 4.14.06 1.92 1.39-.33 3.6-1.5 5.14-.87 1.15.86 3.26-.08 4.35-1.82 2.12-5.62 9.86 3.08 18.37 2.94 2.87-4.83 6.74-6.54 12.85-1.71 6.11 2.64 14.49 10.34 16.67 7.11 1.97-1.73-12.32 5.18-12.49 13.94-.33 6.39-7.45 7.18-11.48.58-2.94 5.09-3.91 8.03-7.68 2.82-3.61 4.57-8.51 3.65-14.77-1.24-8.38-5.34-7.51-8.91-9.59-3.57-2.08-3.18-20.41-17.24-20.74-9.75-.22-15.81 2.64-17.69 5.18-.8 1.07-1.91 3.95-1.34 5.15"
				/>
				<Path
					fill="#EDEDF0"
					d="M141.31 184.92s0 .02.01.03c0 .02.01.04.02.05a.689.689 0 0 1-.03-.08z"
				/>
			</Svg>
		</View>
	);
}
