import { Text } from '@mobe/components/text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

interface ITargetDateProps {
	date: Date;
}

export default function TargetDate({ date }: ITargetDateProps) {
	const styles = useStyles();
	const { t } = useTranslation();

	return (
		<View style={styles.targetDate}>
			<Text>
				{t('plan.goalDetail.endDate')}
				<Text weight="semiBold">{format(new Date(date), 'MMMM d, y')}</Text>
			</Text>
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		targetDate: {
			alignSelf: 'center',
			justifyContent: 'center',
			paddingHorizontal: 18,
			borderRadius: 18,
			minHeight: 36,
			backgroundColor: rules.colors.strokeLight,
		},
	});
}
