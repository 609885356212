import {
	useTrackerAggregatedEntriesQuery,
	useTrackerTypeSourcesQuery,
	useTrackersDetailQuery,
	useTrackersQuery,
} from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import {
	TrackerAbbreviation,
	TrackerAggregatedEntriesInterval,
} from '@mobe/api/track/trackService';
import Box from '@mobe/components/box/Box';
import Button from '@mobe/components/button/Button';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import Icon from '@mobe/components/icon/Icon';
import InputDateInterval from '@mobe/components/input/InputDateInterval';
import InputDateRange from '@mobe/components/input/InputDateRange';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import { endOfMonth, startOfMonth, subDays } from 'date-fns';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import {
	TrackDetailScreenNavigationProp,
	TrackDetailScreenRouteProp,
} from '../../navigation/modal/types';
import ConnectHealthData from '../components/ConnectHealthData';
import ConnectedSourcesInput from '../components/ConnectedSourcesInput';
import GoalModule from '../components/GoalModule';
import MonthHighlightModule from '../components/MonthHighlightModule';
import FavoriteButton from '../components/TrackerFavoriteButton';
import TrackerSummary from '../components/TrackerSummary';
import useDateRangeSelect from '../useDateRangeSelect';
import useShouldShowConnectHealthData from '../useShouldShowConnectHealthData';
import useTrackerContent from '../useTrackerContent';

export interface ITrackDetailScreenParams {
	trackerAbbreviation: TrackerAbbreviation;
}

export interface ITrackDetailScreenProps {
	navigation: TrackDetailScreenNavigationProp;
	route: TrackDetailScreenRouteProp;
}

export default function TrackDetailScreen({ navigation, route }: ITrackDetailScreenProps) {
	const { vr } = useStyleHelpers();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerTypeId = getTrackerIdFromAbbreviation(route.params.trackerAbbreviation);
	const trackerSourcesQuery = useTrackerTypeSourcesQuery(trackerTypeId);
	const trackerDetailQuery = useTrackersDetailQuery(trackerTypeId);
	const mostRecentEntryDateString = trackerDetailQuery.data?.lastEntry;
	const dateRangeSelect = useDateRangeSelect(mostRecentEntryDateString);
	const trackerAggregatedEntriesQuery = useTrackerAggregatedEntriesQuery({
		trackerTypeId,
		startDate: dateRangeSelect.startDate,
		endDate: dateRangeSelect.endDate,
		interval: dateRangeSelect.selectedRangeAggregationInterval,
		enabled: trackerDetailQuery.isSuccess,
	});

	const now = new Date(Date.now());
	const currentMonthAggregatedQuery = useTrackerAggregatedEntriesQuery({
		trackerTypeId,
		startDate: startOfMonth(now),
		endDate: endOfMonth(now),
		interval: TrackerAggregatedEntriesInterval.Day,
	});
	const monthlyGoalMetCount = currentMonthAggregatedQuery.data?.data.filter(
		(day) => (day.value || 0) >= (day.goalValue || Infinity)
	).length;

	const currentAndPreviousMonthQuery = useTrackerAggregatedEntriesQuery({
		trackerTypeId,
		startDate: startOfMonth(subDays(startOfMonth(now), 1)),
		endDate: endOfMonth(now),
		interval: TrackerAggregatedEntriesInterval.Month,
	});
	const previousMonthValue = currentAndPreviousMonthQuery.data?.data[0].value || undefined;
	const currentMonthValue = currentAndPreviousMonthQuery.data?.data[1].value || undefined;

	const trackerContent = useTrackerContent();
	const tracker = trackerContent[route.params.trackerAbbreviation];
	const { t } = useTranslation();
	const trackerAbbreviation = route.params.trackerAbbreviation;
	const shouldShowConnectHealthData = useShouldShowConnectHealthData(trackerAbbreviation);
	const trackerSources = trackerSourcesQuery.data || [];
	const hasNewSource = trackerDetailQuery.data?.hasNewSource || false;
	const goal = trackerDetailQuery.data?.goal;

	const isLoading =
		trackerSourcesQuery.isLoading ||
		trackerDetailQuery.isLoading ||
		trackerAggregatedEntriesQuery.isLoading ||
		currentMonthAggregatedQuery.isLoading ||
		currentAndPreviousMonthQuery.isLoading;

	function handleAddEntryPress() {
		navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: route.params.trackerAbbreviation,
		});
	}

	function handleSetGoalPress(goal?: number) {
		navigation.navigate('TRACK_GOAL_MODAL_SCREEN', {
			trackerAbbreviation: route.params.trackerAbbreviation,
			goal,
		});
	}

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			footer={
				<Button
					title={t('track.detail.addEntryButton')}
					accessibilityLabel={t('track.detail.addEntryAccessibilityLabel', {
						trackerTitle: tracker.title,
					})}
					onPress={handleAddEntryPress}
				/>
			}
		>
			<DeferredLoadingIndicator isLoading={isLoading}>
				<VrArray>
					<View>
						<InputDateInterval dateRangeSelect={dateRangeSelect} style={vr(4)} />
						<InputDateRange dateRangeSelect={dateRangeSelect} style={vr(2)} />

						<Text color="light" align="center" style={vr(2)}>
							{dateRangeSelect.selectedRange === 'week' || dateRangeSelect.selectedRange === 'month'
								? capitalize(tracker.chartLabel)
								: capitalize(tracker.chartLabelDaysCombined)}
						</Text>

						{trackerAggregatedEntriesQuery.data ? (
							<FetchingIndicator isFetching={trackerAggregatedEntriesQuery.isFetching}>
								{tracker.renderChart({
									rawData: trackerAggregatedEntriesQuery.data?.data,
									interval: trackerAggregatedEntriesQuery.data.interval,
								})}
							</FetchingIndicator>
						) : null}
					</View>
					{shouldShowConnectHealthData && (
						<ConnectHealthData trackerAbbreviation={trackerAbbreviation} variant="connect" />
					)}
					{Boolean(trackerSources.length) && (
						<View>
							<ConnectedSourcesInput
								sources={trackerSources}
								trackerTypeId={trackerTypeId}
								trackerTitle={tracker.title.toLowerCase()}
								hasNewSource={hasNewSource}
								trackerAbbreviation={trackerAbbreviation}
							/>
						</View>
					)}
					{tracker.canSetGoal && (
						<GoalModule
							tracker={tracker}
							goalValue={goal}
							goalMetCount={monthlyGoalMetCount}
							onUpdatePress={() => handleSetGoalPress(goal ?? undefined)}
						/>
					)}
					{(currentMonthValue !== undefined || previousMonthValue !== undefined) && (
						<MonthHighlightModule
							aggregateUnitString={tracker.aggregateUnit}
							currentMonthValue={currentMonthValue}
							previousMonthValue={previousMonthValue}
							minScale={Math.max(tracker.minChartScale, goal || 0)}
							dataConversionFactor={tracker.secondaryInput?.conversionFactor}
						/>
					)}
					<TrackerSummary title={tracker.title} description={tracker.longDescription} />
					<Pressable
						role="button"
						onPress={() => navigation.navigate('TRACK_ENTRIES_SCREEN', { trackerAbbreviation })}
					>
						<Box variant="outline">
							<Row>
								<Row.Item fill>
									<Text weight="semiBold">{t('track.detail.viewAllData')}</Text>
								</Row.Item>
								<Row.Item>
									<Icon name="right" size={15} />
								</Row.Item>
							</Row>
						</Box>
					</Pressable>
				</VrArray>
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	);
}

export function useTrackDetailScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps({ hasExtraIcons: true });
	const trackerContent = useTrackerContent();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackersQuery = useTrackersQuery();

	return ({ route }: ITrackDetailScreenProps): StackNavigationOptions => ({
		title: trackerContent[route.params.trackerAbbreviation].title,
		...subScreenStyleProps,
		headerRight: () => {
			const trackerId = getTrackerIdFromAbbreviation(route.params.trackerAbbreviation);
			const isFavorite =
				trackersQuery.data?.find((tracker) => tracker.trackerTypeId === trackerId)?.isFavorite ??
				false;

			return <FavoriteButton favorited={isFavorite} trackerTypeId={trackerId} size={25} />;
		},
	});
}
