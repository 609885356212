import { NotificationsSettingsProvider } from '@mobe/api/notifications/notificationsSettingsProvider';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { SettingsScreenNavigationProp } from '../navigation/menu/types';
import SettingsAccountScreen, {
	useSettingsAccountScreenOptions,
} from './screens/SettingsAccountScreen';
import SettingsEditEmailScreen, {
	useSettingsEditEmailScreenOptions,
} from './screens/SettingsEditEmailScreen';
import SettingsEditPasswordScreen, {
	useSettingsEditPasswordScreenOptions,
} from './screens/SettingsEditPasswordScreen';
import SettingsRootScreen, { useSettingsRootScreenOptions } from './screens/SettingsRootScreen';

export type SettingsStackParamList = {
	SETTINGS_ROOT_SCREEN: undefined;
	SETTINGS_EDIT_PASSWORD_SCREEN: undefined;
	SETTINGS_EDIT_EMAIL_SCREEN: undefined;
	SETTINGS_ACCOUNT_SCREEN: undefined;
};

type SettingsStackRouteProps<T extends keyof SettingsStackParamList> = RouteProp<
	SettingsStackParamList,
	T
>;
type SettingsStackNavigationProps<T extends keyof SettingsStackParamList> = CompositeNavigationProp<
	StackNavigationProp<SettingsStackParamList, T>,
	SettingsScreenNavigationProp
>;

export type SettingsRootScreenRouteProp = SettingsStackRouteProps<'SETTINGS_ROOT_SCREEN'>;
export type SettingsRootScreenNavigationProp = SettingsStackNavigationProps<'SETTINGS_ROOT_SCREEN'>;

export type SettingsEditPasswordScreenRouteProp =
	SettingsStackRouteProps<'SETTINGS_EDIT_PASSWORD_SCREEN'>;
export type SettingsEditPasswordScreenNavigationProp =
	SettingsStackNavigationProps<'SETTINGS_EDIT_PASSWORD_SCREEN'>;

export type SettingsEditEmailScreenRouteProp =
	SettingsStackRouteProps<'SETTINGS_EDIT_EMAIL_SCREEN'>;
export type SettingsEditEmailScreenNavigationProp =
	SettingsStackNavigationProps<'SETTINGS_EDIT_EMAIL_SCREEN'>;

export type SettingsAccountScreenRouteProp = SettingsStackRouteProps<'SETTINGS_ACCOUNT_SCREEN'>;
export type SettingsAccountScreenNavigationProp =
	SettingsStackNavigationProps<'SETTINGS_ACCOUNT_SCREEN'>;

const Settings = createStackNavigator<SettingsStackParamList>();

export default function SettingsStackScreen() {
	const settingsRootScreenOptions = useSettingsRootScreenOptions();
	const settingsEditPasswordScreenOptions = useSettingsEditPasswordScreenOptions();
	const settingsEditEmailScreenOptions = useSettingsEditEmailScreenOptions();
	const settingsAccountScreenOptions = useSettingsAccountScreenOptions();
	const subScreenOptions = useSubScreenStyleProps();

	return (
		<NotificationsSettingsProvider>
			<Settings.Navigator
				initialRouteName="SETTINGS_ROOT_SCREEN"
				screenOptions={{
					...subScreenOptions,
				}}
			>
				<Settings.Screen
					name="SETTINGS_ROOT_SCREEN"
					component={SettingsRootScreen}
					options={settingsRootScreenOptions}
				></Settings.Screen>
				<Settings.Screen
					name="SETTINGS_EDIT_PASSWORD_SCREEN"
					component={SettingsEditPasswordScreen}
					options={settingsEditPasswordScreenOptions}
				></Settings.Screen>
				<Settings.Screen
					name="SETTINGS_EDIT_EMAIL_SCREEN"
					component={SettingsEditEmailScreen}
					options={settingsEditEmailScreenOptions}
				></Settings.Screen>
				<Settings.Screen
					name="SETTINGS_ACCOUNT_SCREEN"
					component={SettingsAccountScreen}
					options={settingsAccountScreenOptions}
				></Settings.Screen>
			</Settings.Navigator>
		</NotificationsSettingsProvider>
	);
}

export const settingsStackScreenOptions = (): DrawerNavigationOptions => {
	return {
		headerShown: false,
		title: 'Settings',
	};
};
