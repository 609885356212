import { mobeAuthenticatedAPI } from '../mobeAPI';

export enum CoachesQueryKeys {
	Coaches = 'coaches',
}

export enum CoachType {
	GSM = 'GSM',
	MSK = 'MSK',
	Spine = 'Spine',
	Pharmacist = 'Pharmacist',
}

export interface ICoach {
	coachId: number;
	guideDIKey: string;
	preferredName: string | null;
	coachType: CoachType;
	chatChannelId: number;
	coachTypeId: number;
	coachTypeDisplayName: string | null;
	displayOrder: number;
	avatarUrl: string | null;
	bio: string | null;
}

export async function fetchCoaches() {
	return await mobeAuthenticatedAPI.get<ICoach[]>('guides');
}
