import {
	useAllActivitiesQuery,
	useMarkActivityCompleteMutation,
} from '@mobe/api/activities/activitiesApiHooks';
import { ActivityType, IActivityFull } from '@mobe/api/activities/activitiesService';
import {
	useContentDetailQuery,
	useSetFavoriteStatusMutation,
} from '@mobe/api/explore/exploreApiHooks';
import { Button } from '@mobe/components/button';
import LoadingOverlay from '@mobe/components/loadingOverlay/LoadingOverlay';
import MobePdf from '@mobe/components/mobePdf/MobePdf';
import { useScreenTemplateWithFooterStyles } from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import ShareButton from '@mobe/components/shareButton/ShareButton';
import useActivityToast from '@mobe/components/toast/useActivityToast';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useTransitions from '@mobe/utils/useTransitions';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import ExploreFavoriteButton from '../../explore/components/ExploreFavoriteButton';
import SharedContentView from '../../explore/components/SharedContentView';
import {
	ActivitiesDetailScreenNavigationProp,
	ActivitiesDetailScreenRouteProp,
} from '../../navigation/modal/types';
import SurveyView from '../components/SurveyView';

export interface IActivitiesDetailScreenParams {
	activityId: number;
}

interface IActivitiesDetailScreenProps {
	navigation: ActivitiesDetailScreenNavigationProp;
	route: ActivitiesDetailScreenRouteProp;
}

export default function ActivitiesDetailScreen({
	navigation,
	route,
}: IActivitiesDetailScreenProps) {
	const { t } = useTranslation();
	const activityToast = useActivityToast();
	const activitiesQuery = useAllActivitiesQuery();
	const activities = activitiesQuery.data?.activities;
	const activity = activities?.find((activity) => activity.id === route.params.activityId);
	const markActivityCompleteMutation = useMarkActivityCompleteMutation();
	const screenTemplateStyles = useScreenTemplateWithFooterStyles();
	const { cardBackground, fill } = useStyleHelpers();
	const [localFileUrl, setLocalFileUrl] = React.useState<string>('');

	React.useEffect(() => {
		if (!localFileUrl || activity?.type !== ActivityType.MedSummary || Platform.OS === 'web') {
			return;
		}

		// Med summaries render a Share button
		navigation.setOptions({
			headerRight: () => (
				<ShareButton
					url={localFileUrl}
					isSecurePdf
					showAlert
					continueLabel={t('fileViewer.continueLabel')}
					alertTitle={t('fileViewer.shareNoticeTitle')}
					alertBody={t('fileViewer.shareNoticeBody')}
				/>
			),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localFileUrl, activity?.type]);

	function closeActivity() {
		if (navigation.canGoBack()) {
			navigation.pop();
		}
	}

	function handleCompletePress() {
		markActivityCompleteMutation.mutate(
			{ activityId: route.params.activityId },
			{
				onSuccess: activityToast,
				onSettled: closeActivity,
			}
		);
	}

	function handleSurveyComplete() {
		markActivityCompleteMutation.mutate(
			{ activityId: route.params.activityId },
			{
				onSuccess: activityToast,
				onSettled: closeActivity,
			}
		);
	}

	if (activity === undefined) {
		return null;
	}

	// Survey view
	if (activity.activityTargetId && activity.type === ActivityType.Survey) {
		return (
			<View style={[cardBackground, fill]}>
				<SurveyView
					token={activity.activityTargetId}
					title={activity.title}
					description={activity.description}
					onSurveyComplete={handleSurveyComplete}
				/>
				<LoadingOverlay visible={markActivityCompleteMutation.isLoading} />
			</View>
		);
	}

	// View for all shared content
	if (activity.activityTargetId && activity.type === ActivityType.SharedContent) {
		return (
			<View style={[cardBackground, fill]}>
				<SharedContentView
					contentId={parseInt(activity.activityTargetId, 10)}
					context="activities"
				/>
				<View style={screenTemplateStyles.footerContainer}>
					<View style={screenTemplateStyles.footer}>
						<Button
							title={t('activities.sharedContent.complete')}
							onPress={handleCompletePress}
							loading={markActivityCompleteMutation.isLoading}
						/>
					</View>
				</View>
			</View>
		);
	}
	// View for med summaries
	if (activity.activityTargetId && activity.type === ActivityType.MedSummary) {
		return (
			<View style={[cardBackground, fill]}>
				<MobePdf
					title={activity.title}
					style={{ flex: 1 }}
					idSlugSource={{
						id: parseInt(activity.activityTargetId, 10),
						documentSlug: 'econsult-document-file',
					}}
					cache
					onLoadComplete={(numberOfPages, cacheFilePath) => {
						setLocalFileUrl(`file://${cacheFilePath}`);
					}}
				/>
				<View style={screenTemplateStyles.footerContainer}>
					<View style={screenTemplateStyles.footer}>
						<Button
							title={t('activities.sharedContent.complete')}
							onPress={handleCompletePress}
							loading={markActivityCompleteMutation.isLoading}
						/>
					</View>
				</View>
			</View>
		);
	}

	return null;
}

export function useActivitiesDetailScreenOptions() {
	const modalStyleProps = useModalStyleProps();
	const subScreenStyleProps = useSubScreenStyleProps();
	const subScreenStylePropsSurvey = useSubScreenStyleProps({ backIcon: 'close' });
	const activitiesQuery = useAllActivitiesQuery();
	const activities = activitiesQuery.data?.activities;
	const transitions = useTransitions();

	return ({ route }: IActivitiesDetailScreenProps): StackNavigationOptions => {
		const activity = activities?.find((activity) => activity.id === route.params.activityId);
		const androidScreenStyleProps =
			activity?.type === ActivityType.Survey ? subScreenStylePropsSurvey : subScreenStyleProps;

		if (activity === undefined) {
			return {};
		}

		return {
			...(Platform.OS === 'android' ? androidScreenStyleProps : modalStyleProps),
			...(Platform.OS === 'android' && transitions.DefaultTransition),
			title: activity.type === ActivityType.SharedContent ? '' : activity.title,
			headerRight: () =>
				activity.type === ActivityType.SharedContent ? (
					<SharedContentFavoriteButton activity={activity} />
				) : null,
		};
	};
}

function SharedContentFavoriteButton({ activity }: { activity: IActivityFull }) {
	const setFavoriteStatus = useSetFavoriteStatusMutation();
	const contentId = parseInt(activity.activityTargetId, 10) || null;
	const favorited = useContentDetailQuery(contentId).data?.isFavorite;

	return (
		<ExploreFavoriteButton
			favorited={favorited}
			onPress={() => {
				if (contentId === null) return;
				setFavoriteStatus.mutate({
					sharedContentId: contentId,
					isFavorite: !favorited,
				});
			}}
		/>
	);
}
