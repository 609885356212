import { IAppointment } from '@mobe/api/appointments/appointmentApiHooks';
import env from '@mobe/env/env';
import useDeviceCalendar from '@mobe/utils/useDeviceCalendar';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { Alarm } from 'expo-calendar';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

interface ICalendarEventDetails {
	alarms: Alarm[];
	allDay: boolean;
	availability: string;
	calendarId: string;
	creationDate?: string | Date | undefined;
	endDate: string | Date;
	id: string;
	isDetached?: boolean | undefined;
	lastModifiedDate?: string | Date | undefined;
	notes: string | undefined;
	organizer?: string | undefined;
	originalStartDate?: string | Date | undefined;
	startDate: string | Date;
	status: string;
	timeZone: string | Date;
	title: string;
	url?: string | undefined;
}

export default function useDeleteFromCalendar(
	appointment: IAppointment | undefined,
	guideName: string | undefined
) {
	const { deleteEventFromCalendar, getExistingEvents } = useDeviceCalendar();
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();

	if (!appointment || !guideName) {
		return async () => genericErrorAlert;
	}

	const duration = appointment.durationInMinutes;
	const startDate = appointment.appointmentStartDate;

	/**
	 * Checks if event(s) with specific start time + duration + title already exist on calendar
	 * Then passes that event to be deleted
	 */
	async function handleDeleteFromCalendar() {
		// NOTE: startDate begins in format `2022-02-01T15:00:00Z` and needs to be converted to `2022-02-01T15:00:00.000Z`
		const formattedStartDate = new Date(startDate);
		const existingEvents = await getExistingEvents(formattedStartDate, duration);
		const mobeEvents: ICalendarEventDetails[] = [];

		if (existingEvents.length > 0) {
			// Account for fact that user may have another event on their calendar with same start time and duration:
			existingEvents.forEach((appt) => {
				if (
					appt.title === t('appointments.appointmentCard.guide.title', { guideName }) ||
					appt.title ===
						t('appointments.appointmentCard.pharmacist.title', { pharmacistName: guideName })
				) {
					mobeEvents.push(appt);
				}
			});

			// NOTE: We currently do not allow users to schedule overlapping appointments with multiple guides/pharmacist.
			// NOTE: If the user had multiple events on their calendar with same start date, duration and title as a MOBE event, then this check would not account for that.
			// NOTE: We are checking for a MOBE event as specifically as we can, but it is imperfect.
			const calendarEventId = mobeEvents[0].id;
			deleteFromCalendar(calendarEventId);
		}

		/**
		 *  deletes event via expo-calendar deleteEventAsync()
		 */
		async function deleteFromCalendar(calendarEventId: string) {
			deleteEventFromCalendar(calendarEventId)
				.then(() => {
					noop();
				})
				.catch((error) => {
					if (env.isDev) {
						console.warn(
							`useDeleteFromCalendar: There was a problem deleting event '${calendarEventId}'`
						);
					}
				});
		}
	}
	return handleDeleteFromCalendar;
}
