import React from 'react';

export type TVideoFormats = 'youtube' | 'wistia' | 'file';

export const useMobeVideoPlayer = (type: TVideoFormats, uri: string) => {
	const [isWistia, setIsWistia] = React.useState(false);
	const [isYoutube, setIsYoutube] = React.useState(false);
	const [isFile, setIsFile] = React.useState(false);
	const [wistiaId, setWistiaId] = React.useState('');
	const [youtubeId, setYoutubeId] = React.useState('');

	React.useEffect(() => {
		setVideoFormat(type, uri);
	}, [type, uri]);

	const setVideoFormat = (type: TVideoFormats, uri: string) => {
		const setUpWistiaFormat = (uri: string) => {
			setIsWistia(true);
			const uriSections = uri.split('medias/');
			const id = uriSections.length > 1 ? uriSections[1] : '';
			setWistiaId(id);
		};

		const setUpYouTubeFormat = (uri: string) => {
			setIsYoutube(true);
			if (uri.includes('watch?v=')) {
				const uriSections = uri.split('watch?v=');
				const id = uriSections.length > 1 ? uriSections[1] : '';
				setYoutubeId(id);
			}
		};

		type === 'wistia'
			? setUpWistiaFormat(uri)
			: type === 'youtube'
			? setUpYouTubeFormat(uri)
			: setIsFile(true);
	};

	return {
		setVideoFormat,
		isWistia,
		isYoutube,
		isFile,
		wistiaId,
		youtubeId,
	};
};
