import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#D0D17C"
					d="M55.257 49.6c-3.898-5.437-6.154-10.584-6.77-15.44l-1.835.117c.804 4.015-.047 4.635-2.549 1.861l-1.276.937c2.835 6.339 1.68 7.269-3.466 2.791l-.627 1.063c3.308 3.474 2.845 4.354-1.387 2.641l-.93.575c5.623 3.81 5.201 4.383-1.264 1.721l-.597 1.576c5.853 1.95 10.197 4.591 13.034 7.926.637.75 1.176 1.835 2.113 2.141 2.315.771 6.052-2.259 6.867-4.587.51-1.468-.516-2.222-1.306-3.322"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#5E9613"
					d="M55.523 28.79a1.714 1.714 0 0 0-.677-1.092 2.715 2.715 0 0 0-2.081-2.934 3.87 3.87 0 0 0-4.21-2.304 3.485 3.485 0 0 0-6.06-1.019 3.825 3.825 0 0 0-5.78.678 5.948 5.948 0 0 0-8.505 5.282 2.931 2.931 0 0 0-.772 1.441 3.74 3.74 0 0 0-3.112 4.518 2.894 2.894 0 0 0-1.501 1.051 4.221 4.221 0 0 0-1.249 5.837l.042.064a4.688 4.688 0 0 0 2.711 6.359 3.323 3.323 0 0 0 3.263 4.759 4.18 4.18 0 0 0 5.183-.589 2.567 2.567 0 0 0 1.437-2.42 3.307 3.307 0 0 0 1.797-3.455 4.216 4.216 0 0 0 3.386-4.909l-.001-.008-.035-.169a4.209 4.209 0 0 0 4.246-2.775h-.007a4.734 4.734 0 0 0 2.896-1.762 2.639 2.639 0 0 0 3.386-.896h.009a3.321 3.321 0 0 0 4.246-2.914c.17-.266.296-.557.375-.862a1.72 1.72 0 0 0 1.013-1.881z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F8BB05"
					d="M75.233 40.783a6.046 6.046 0 0 0-10.169-2.637 6.43 6.43 0 0 0-11.065 3.481c6.444 1.69 10.475 1.867 12.093.532a10.38 10.38 0 0 0 9.141-1.376z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#5E9613"
					d="M70.418 30.394c-.367-.69-1.051-1.045-1.53-.789a.742.742 0 0 0-.229.203 9.665 9.665 0 0 0-5.158 9.093c-1.12.759-1.515 1.467-1.184 2.126a5.112 5.112 0 0 0 6.122.155c.695-.437.349-1.34-1.035-2.71a4.74 4.74 0 0 1 2.316-6.292h.012a.752.752 0 0 0 .48-.065c.48-.265.571-1.031.206-1.721z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F8BB05"
					d="M77.832 42.235a5.91 5.91 0 0 0-8.964-1.656 16.62 16.62 0 0 0-3.697.546c-1.946.187-3.455-1.45-5.35-.379a5.58 5.58 0 0 0-5.724-.227c-7.176 3.906-.554 24.006 7.309 19.961a6.879 6.879 0 0 0 5.091 1.122 5.971 5.971 0 0 0 4.544-2.671 5.08 5.08 0 0 0 4.641-.704c2.141-1.565 2.078-4.995 2.233-8.327.123-2.685.976-5.468-.083-7.665z"
				/>
				<Path
					fill="#0E5817"
					d="m34.773 48.637-1.035-.323a.605.605 0 0 0-.227-.373l-.748-.565-6.403-7.992a.602.602 0 1 0-.956.732l.015.019 3.437 4.291-5.794-4.378a.603.603 0 0 0-.728.96l5.669 4.283-5.195-2.76a.603.603 0 1 0-.567 1.063l5.168 2.746-5.131-1.603a.602.602 0 0 0-.386 1.14l.024.008 7.881 2.463-9.427-1.736a.602.602 0 0 0-.222 1.184l12.876 2.371c.13.024.264.005.383-.056l.197.246a1.04 1.04 0 0 0 1.171-1.714l-.002-.006z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#D34A0F"
					d="M67.759 60.319c1.818-1.063 1.916-1.797.331-1.709a49.866 49.866 0 0 1-3.251.017c-8.198-.247-15.026-4.261-21.691-8.723-2.554-1.709-5.018-4.433-8.331-2.775a4.821 4.821 0 0 0-2.658 4.241c-.146 9.76 27.492 14.028 35.606 8.949"
				/>
				<Path
					fill="#32863C"
					d="m21.033 49.825-1.054-.33a.612.612 0 0 0-.233-.38l-.734-.554-6.544-8.166a.613.613 0 0 0-.958.764l3.523 4.396-5.922-4.474a.612.612 0 1 0-.742.975l5.768 4.359-5.285-2.809a.612.612 0 1 0-.578 1.08l5.259 2.791-5.223-1.632a.613.613 0 1 0-.367 1.169l8.02 2.506-9.595-1.765a.614.614 0 0 0-.225 1.205l13.109 2.412a.614.614 0 0 0 .399-.061l.193.241a1.053 1.053 0 0 0 1.196-1.727h-.007z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#F25200"
					d="M54.614 61.72c1.85-1.084 1.951-1.829.336-1.74a54.77 54.77 0 0 1-3.309.019c-8.346-.253-15.296-4.338-22.081-8.88-2.599-1.741-5.107-4.518-8.48-2.825a4.905 4.905 0 0 0-2.712 4.318c-.147 9.936 27.986 14.281 36.247 9.111"
				/>
			</Svg>
		</View>
	);
}

export default SvgComponent;
