import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

interface IBlocksProps extends ViewProps {
	increment?: number;
	blocksPerRow?: number;
	children: React.ReactNode;
}

export default function Blocks({
	increment = 4,
	blocksPerRow = 2,
	children,
	style,
	...viewProps
}: IBlocksProps) {
	const childrenArray = React.Children.toArray(children);
	const styleRules = useStyleRules();
	const gutter = styleRules.spacing.baseline * increment;

	const styles = StyleSheet.create({
		container: {
			flexWrap: 'wrap',
			flexDirection: 'row',
			marginLeft: -gutter,
			marginTop: -gutter,
		},
		block: {
			width: `${100 / blocksPerRow}%`,
			paddingLeft: gutter,
			paddingTop: gutter,
		},
	});

	return (
		<View style={[styles.container, style]} {...viewProps}>
			{childrenArray.map((child, index) => (
				<View key={index} style={styles.block}>
					{child}
				</View>
			))}
		</View>
	);
}
