import {
	useMarkActivityCompleteMutation,
	useMarkActivityDismissedMutation,
} from '@mobe/api/activities/activitiesApiHooks';
import { useClientProgramReferralsQuery } from '@mobe/api/client-program-referrals/clientProgramReferralsApiHooks';
import { CLIENT_PROGRAM_REFERRALS_QUERY_KEY } from '@mobe/api/client-program-referrals/queryKeys';
import { Button } from '@mobe/components/button';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import HTMLView from '@mobe/components/htmlView/HTMLView';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import MobePdf from '@mobe/components/mobePdf/MobePdf';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import TextButton from '@mobe/components/textButton/TextButton';
import linking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import useScreenTransitioning from '@mobe/utils/useScreenTransitioning';
import useTransitions from '@mobe/utils/useTransitions';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { noop } from 'lodash';
import * as React from 'react';
import { isMacOs, isWindows } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useIsMutating, useQueryClient } from 'react-query';
import {
	ClientProgramReferralsDetailScreenNavigationProp,
	ClientProgramReferralsDetailScreenRouteProp,
} from '../navigation/modal/types';

export interface IClientProgramReferralsDetailScreenParams {
	activityId?: number;
	programId: number;
}

interface IClientProgramReferralsDetailScreenProps {
	navigation: ClientProgramReferralsDetailScreenNavigationProp;
	route: ClientProgramReferralsDetailScreenRouteProp;
}

export default function ClientProgramReferralsDetailScreen({
	navigation,
	route,
}: IClientProgramReferralsDetailScreenProps) {
	const { t } = useTranslation();
	const { vrTop } = useStyleHelpers();
	const { isTransitioning, setIsTransitioning } = useScreenTransitioning();
	const genericErrorAlert = useGenericErrorAlert();
	const { mobeAlert } = useAlert();
	const markActivityCompleteMutation = useMarkActivityCompleteMutation();
	const markActivityDismissedMutation = useMarkActivityDismissedMutation();
	const numMutationsInProgress = useIsMutating();
	const isMutating = numMutationsInProgress > 0;
	const clientProgramReferralsQuery = useClientProgramReferralsQuery();
	const queryClient = useQueryClient();
	const program = clientProgramReferralsQuery.data?.find(
		(program) => program.id === route.params.programId
	);

	useFocusEffect(
		React.useCallback(() => {
			navigation.setOptions({
				title: program?.programName || '',
			});
		}, [route.params.programId, clientProgramReferralsQuery.data])
	);

	// Being extra cautious with webviews on Android. Even animating opacity may result in a crash.
	React.useEffect(() => {
		const removeListener = navigation.addListener('beforeRemove', () => {
			setIsTransitioning(true);
		});

		return removeListener;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [route.params.programId]);

	function tryToCompleteActivity(callback: () => void) {
		// If detail isn't in activity context or the activity mutation is successful
		// Don't try to mutate at all/or again, fire callback and return early..
		if (!route.params.activityId || markActivityCompleteMutation.isSuccess) {
			callback();
			return;
		}

		markActivityCompleteMutation.mutate(
			{ activityId: route.params.activityId },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(CLIENT_PROGRAM_REFERRALS_QUERY_KEY);
					callback();
				},
				onError: () => genericErrorAlert(),
			}
		);
	}

	function dismissActivity(callback: () => void) {
		// If detail isn't in activity context or the activity mutation is successful
		// Don't try to mutate at all/or again, fire callback and return early..
		if (!route.params.activityId || markActivityDismissedMutation.isSuccess) {
			callback();
			return;
		}

		markActivityDismissedMutation.mutate(
			{ activityId: route.params.activityId },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(CLIENT_PROGRAM_REFERRALS_QUERY_KEY);
					callback();
				},
				onError: () => genericErrorAlert(),
			}
		);
	}

	function formatPhoneNumber(phoneNumber: string) {
		// Strip country code if length is 11
		if (phoneNumber.length === 11) {
			phoneNumber = phoneNumber.substring(1);
		}

		return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	}

	function displayWebPhoneNumberAlert(phoneNumber: string) {
		mobeAlert(
			program?.programName || '',
			t('clientProgramReferrals.webPhoneAlert', { phone: formatPhoneNumber(phoneNumber) }),
			[
				{
					style: 'default',
					text: t('clientProgramReferrals.webPhoneAlertConfirm'),
					onPress: noop,
				},
			]
		);
	}

	function renderUrlButton() {
		return program?.url ? (
			<Button
				title={t('clientProgramReferrals.urlButton')}
				loading={isMutating}
				onPress={() => {
					if (typeof program.url === 'string') {
						linking.openUrl(program.url);
						tryToCompleteActivity(noop);
					}
				}}
			/>
		) : null;
	}

	function renderPhoneButton() {
		return program?.phone ? (
			<Button
				title={t('clientProgramReferrals.callButton')}
				loading={isMutating}
				onPress={() => {
					if (typeof program.phone === 'string') {
						if (isMacOs || isWindows) {
							displayWebPhoneNumberAlert(program.phone);
						} else {
							linking.openUrl(`tel:${program.phone}`);
						}

						tryToCompleteActivity(noop);
					}
				}}
			/>
		) : null;
	}

	function renderCompleteActivityButton() {
		return !(program?.url || program?.phone || !route.params.activityId) ? (
			<Button
				title={t('clientProgramReferrals.completeActivityButton')}
				loading={isMutating}
				onPress={() => {
					tryToCompleteActivity(() => navigation.pop());
				}}
			/>
		) : null;
	}

	function renderDismissActivityButton() {
		return route.params.activityId ? (
			<TextButton
				title={t('clientProgramReferrals.dismissButton')}
				loading={isMutating}
				align="center"
				style={vrTop(4)}
				onPress={() => {
					dismissActivity(() => navigation.pop());
				}}
			/>
		) : null;
	}

	function renderFooter() {
		return route.params.activityId ? (
			<>
				<VrArray increment={3}>
					{renderUrlButton()}
					{renderPhoneButton()}
					{renderCompleteActivityButton()}
				</VrArray>
				{markActivityCompleteMutation.isSuccess ? null : renderDismissActivityButton()}
			</>
		) : program?.url || program?.phone ? (
			<VrArray increment={3}>
				{renderUrlButton()}
				{renderPhoneButton()}
			</VrArray>
		) : undefined;
	}

	// Being extra cautious with web views on Android. Even animating opacity may result in a crash.
	if (Platform.OS === 'android') {
		if (clientProgramReferralsQuery.isLoading || isTransitioning) {
			return null;
		}
	}

	return (
		<DeferredLoadingIndicator isLoading={clientProgramReferralsQuery.isLoading}>
			{!isTransitioning && clientProgramReferralsQuery.isSuccess ? (
				<ScreenTemplateWithFooter noPadding footer={renderFooter()} fixedPositionFooter>
					{program?.contentHtml ? (
						<View>
							{/* Hiding hero for now */}
							{/* {program.imageUrl ? <Hero url={program.imageUrl} /> : null} */}
							{/* <View style={program.imageUrl ? vrTop(3) : undefined}> */}
							<View style={vrTop(3)}>
								<HTMLView html={program.contentHtml} />
							</View>
						</View>
					) : program?.fileUrl ? (
						<MobePdf
							url={program.fileUrl}
							trustAllCerts={Platform.OS === 'android' ? false : true}
							style={{ flex: 1 }}
						/>
					) : null}
				</ScreenTemplateWithFooter>
			) : null}
		</DeferredLoadingIndicator>
	);
}

export function useClientProgramReferralsDetailScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps({ hasExtraIcons: true });
	const transitions = useTransitions();

	return (screenProps: IClientProgramReferralsDetailScreenProps): StackNavigationOptions => ({
		title: '',
		...subScreenStyleProps,
		...(Platform.OS === 'android' && transitions.DefaultTransition),
		headerRight: function HeaderRight() {
			return (
				<Row gutterSize={20} align="top">
					<Row.Item>
						<ChatButton />
					</Row.Item>
				</Row>
			);
		},
	});
}
