import { AvatarGraphic } from '@mobe/components/graphics';
import Row from '@mobe/components/layout/Row';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import Text from '@mobe/components/text/Text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { addProtocolToUrl } from '@mobe/utils/validationUtils';
import format from 'date-fns/format';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, Pressable, StyleSheet, View } from 'react-native';
import { OnLoadEvent } from 'react-native-fast-image';
import ImageModal from './ImageModal';

export interface IMessage {
	id: number;
	createdAt: string;
	text: string;
	attachmentId: number | null;
}

export interface IMessagesSet {
	id: number;
	messages: IMessage[];
	fromUserId: number;
	isSentByCoach: boolean;
}

interface IMessageProps {
	messageSet: IMessagesSet;
	user: {
		name: string;
		avatarUrl: string;
	};
	sectionIndex: number;
}

const MESSAGE_IMAGE_MAX_SIZE = 200;
const AVATAR_IMAGE_MAX_WIDTH = 50;

export default function Message({ messageSet, user, sectionIndex }: IMessageProps) {
	const styleRules = useStyleRules();
	const styles = useMessageStyles();
	const { t } = useTranslation();
	const [attachmentImageWidth, setAttachmentImageWidth] = React.useState(MESSAGE_IMAGE_MAX_SIZE);
	const [attachmentImageHeight, setAttachmentImageHeight] = React.useState(MESSAGE_IMAGE_MAX_SIZE);

	function setAttachmentImageDimensions(event: OnLoadEvent) {
		const width = event.nativeEvent.width;
		const height = event.nativeEvent.height;

		if (width > height) setAttachmentImageHeight((height / width) * MESSAGE_IMAGE_MAX_SIZE);
		if (height > width) setAttachmentImageWidth((width / height) * MESSAGE_IMAGE_MAX_SIZE);
	}

	function renderMessage(message: IMessage, index: number) {
		const isMostRecentMessage = sectionIndex === 0 && index === messageSet.messages.length - 1;

		return (
			<View key={message.id} style={{ marginTop: index > 0 ? 8 : 0 }}>
				<ImageModal imageId={message.attachmentId}>
					{(modalProps) => (
						<>
							{message.attachmentId ? (
								<View style={styles.attachmentImageContainer}>
									<Pressable
										onPress={modalProps.show}
										role="button"
										aria-label={t('chat.directMessageScreen.imageAccessibilityLabel', {
											user: user.name,
										})}
									>
										<MobeImage
											apiImageId={message.attachmentId}
											renderWidth={MESSAGE_IMAGE_MAX_SIZE}
											style={{ width: attachmentImageWidth, height: attachmentImageHeight }}
											resizeMode="contain"
											onLoad={setAttachmentImageDimensions}
										/>
									</Pressable>
								</View>
							) : null}
							{Boolean(message.text) && (
								<Text
									size="lg"
									style={{ userSelect: 'text' }}
									{...{
										// Add tab index to first message so we can give keyboard users a anchor
										// to use arrows for scrolling the message list
										tabIndex: isMostRecentMessage ? 0 : undefined,
									}}
								>
									<MobeParsedText
										style={{ userSelect: 'text' }}
										parse={[
											{
												type: 'url',
												style: {
													color: styleRules.colors.primary,
													textDecorationLine: 'underline',
												},
												onPress: (url) => Linking.openURL(addProtocolToUrl(url)),
											},
											{
												type: 'phone',
												style: {
													color: styleRules.colors.primary,
													textDecorationLine: 'underline',
												},
												onPress: (phone) => Linking.openURL(`tel:${phone}`),
											},
											{
												type: 'email',
												style: {
													color: styleRules.colors.primary,
													textDecorationLine: 'underline',
												},
												onPress: (email) => Linking.openURL(`mailto:${email}`),
											},
										]}
									>
										{message.text.trim()}
									</MobeParsedText>
								</Text>
							)}
						</>
					)}
				</ImageModal>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<View>
				{user.avatarUrl ? (
					<View style={styles.avatarImageWrapper}>
						<MobeImage
							source={{ uri: user.avatarUrl }}
							renderWidth={AVATAR_IMAGE_MAX_WIDTH}
							style={styles.avatarImage}
						/>
					</View>
				) : (
					<View style={styles.avatarGraphicContainer}>
						<AvatarGraphic />
					</View>
				)}
			</View>
			<View style={styles.body}>
				<Row gutterSize={8}>
					<Row.Item>
						<Text weight="bold">{user.name}</Text>
					</Row.Item>
					<Row.Item>
						<Text size="sm" color="light">
							{format(new Date(messageSet.messages[0].createdAt), 'p')}
						</Text>
					</Row.Item>
				</Row>
				{messageSet.messages.map(renderMessage)}
			</View>
		</View>
	);
}

function useMessageStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			flexDirection: 'row',
			paddingVertical: 16,
		},
		body: {
			flexShrink: 1,
			marginLeft: 10,
		},
		attachmentImageContainer: {
			maxWidth: MESSAGE_IMAGE_MAX_SIZE + 1,
			marginTop: 10,
			marginBottom: 10,
			borderColor: styleRules.colors.strokeLight,
			borderWidth: 1,
			borderRadius: styleRules.borderRadius,
			alignSelf: 'flex-start',
		},
		avatarGraphicContainer: {
			width: AVATAR_IMAGE_MAX_WIDTH,
			height: 50,
			aspectRatio: 1,
			overflow: 'hidden',
			borderRadius: 25,
			alignItems: 'center',
		},
		avatarImage: {
			height: 50,
			width: AVATAR_IMAGE_MAX_WIDTH,
			borderRadius: 50,
		},
		avatarImageWrapper: {
			...(Platform.OS === 'web' && {
				width: AVATAR_IMAGE_MAX_WIDTH,
				height: 50,
				borderRadius: 50,
				overflow: 'hidden',
			}),
		},
	});
}
