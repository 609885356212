import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(33, 57);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fillRule="nonzero" fill="none">
					<Path
						d="M31.646 24.312h-.514v8.575h.52A1.35 1.35 0 0033 31.54V25.66c0-.745-.606-1.348-1.354-1.348z"
						fill="#2B98BA"
					/>
					<Path
						d="M31.132 16.856v-.006c-.005-3.289-2.682-5.953-5.983-5.953H5.978a5.99 5.99 0 00-2.813.702h-.006A5.957 5.957 0 000 16.854V40.14a5.959 5.959 0 003.462 5.413 5.932 5.932 0 002.516.551h19.18a5.866 5.866 0 002.103-.384 5.959 5.959 0 003.875-5.578V16.856h-.004zm-17.59 12.007a.84.84 0 01-1.589.535 3.774 3.774 0 011.75-4.445 3.803 3.803 0 014.723.811.834.834 0 01-.487 1.375.84.84 0 01-.794-.296 2.122 2.122 0 00-2.618-.435 2.106 2.106 0 00-.986 2.455zm3.76-5.391a5.866 5.866 0 00-5.917 1.864c-1.214 1.492-1.29 3.43-.21 5.46a.834.834 0 01-.355 1.119.84.84 0 01-1.129-.337c-1.391-2.62-1.25-5.28.392-7.296a7.606 7.606 0 017.681-2.418c2.5.713 4.145 2.811 4.513 5.756a.834.834 0 01-.327.77.841.841 0 01-1.338-.563c-.285-2.28-1.46-3.826-3.31-4.355zm6.415 8.275a.835.835 0 01-.522-1.061 7.745 7.745 0 00-1.165-7.146 7.955 7.955 0 00-6.472-3.326h-.142a7.771 7.771 0 00-7.263 5.056A7.858 7.858 0 0010 34.09c.332.314.35.835.042 1.171a.84.84 0 01-1.175.063 9.511 9.511 0 01-2.271-10.673 9.372 9.372 0 018.801-6.11h.295a9.769 9.769 0 017.737 4.072 9.697 9.697 0 011.352 8.614.834.834 0 01-1.063.52z"
						fill="#13171B"
					/>
					<Path
						d="M25.149 10.9c.768 0 1.53.147 2.242.434-1.569-2.452-1.8-7.205-1.806-9.707A1.637 1.637 0 0023.946 0H7.06c-.434-.001-.85.17-1.156.476a1.62 1.62 0 00-.477 1.151c-.006 2.519-.242 7.337-1.851 9.774a5.993 5.993 0 012.399-.502h19.174zM5.975 46.104a5.932 5.932 0 01-2.516-.551c1.633 2.424 1.87 7.282 1.878 9.818v.005c0 .432.173.847.48 1.152.308.304.725.474 1.159.472h16.881a1.632 1.632 0 001.639-1.624c.005-2.48.229-7.183 1.761-9.657a5.865 5.865 0 01-2.102.385H5.975z"
						fill="#2B98BA"
					/>
					<Path
						d="M23.39 22.558a9.605 9.605 0 00-7.697-4.017h-.295a9.372 9.372 0 00-8.801 6.109 9.511 9.511 0 002.271 10.673.841.841 0 001.397-.431.834.834 0 00-.265-.803 7.858 7.858 0 01-1.845-8.821 7.771 7.771 0 017.263-5.056h.142c2.57 0 4.982 1.24 6.472 3.326a7.745 7.745 0 011.162 7.146.84.84 0 001.588.541 9.517 9.517 0 00-1.393-8.667z"
						fill="#DD3626"
					/>
					<Path
						d="M20.613 27.827a.841.841 0 001.338.563.834.834 0 00.327-.77c-.368-2.946-2.013-5.043-4.512-5.756a7.606 7.606 0 00-7.682 2.418c-1.641 2.016-1.783 4.675-.391 7.296a.84.84 0 001.128.337.834.834 0 00.354-1.12c-1.077-2.028-1.003-3.968.211-5.46a5.866 5.866 0 015.918-1.863c1.847.527 3.023 2.074 3.31 4.355z"
						fill="#2B957B"
					/>
					<Path
						d="M14.516 26.416a2.031 2.031 0 012.63.428.84.84 0 001.441-.249.834.834 0 00-.16-.83 3.803 3.803 0 00-4.724-.811 3.774 3.774 0 00-1.75 4.445.84.84 0 001.588-.535 2 2 0 01.975-2.448z"
						fill="#27A8E0"
					/>
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
