import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingMedicationGraphic(props: SvgProps) {
	const rules = useStyleRules();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(600, 287);

	return (
		<View style={[wrapperStyles, { maxWidth: rules.spacing.modalMaxWidth, width: '140%' }]}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#F9F9F9" d="M0 0h600v287H0z" />
				<Path
					fill="#301C11"
					d="M182.291 67.392c-15.149 10.965-32.924 60.732 3.209 78.108s92.266 9.225 76.869-28.557c-15.397-37.782-9.894-60.001-46.253-64.309-36.36-4.308-33.825 14.758-33.825 14.758z"
				/>
				<Path
					fill="#46A57D"
					d="M243.366 213.676c1.212 11.379 2.793 22.758 5.047 34.198.046.38.096.734.147.987.103.515.206 1.113.309 1.546l.35 1.278.33 1.134a53.489 53.489 0 0 0 1.422 4.122c.99 2.556 2 4.947 3.051 7.317a329.25 329.25 0 0 0 6.472 13.852 631.196 631.196 0 0 0 4.477 8.89h21.185a451.748 451.748 0 0 1-7.132-17.094c-1.772-4.514-3.504-9.049-5.05-13.522-.7-2.226-1.443-4.534-2.061-6.595a88.809 88.809 0 0 1-.824-3.01l-.165-.639v-.494l-.124-1.299c-1.34-10.615-2.102-21.498-2.494-32.402a640.872 640.872 0 0 1-.33-16.49c-.041-5.421 0-11.027.165-16.263l-27.331.042c.226 5.977.577 11.584 1.01 17.314.432 5.73.948 11.419 1.546 17.128z"
				/>
				<Path
					fill="#FFF"
					d="M265.151 172.802c.825 13.645 1.464 52.21-5.874 111.181h-79.913c.103-28.568 2.432-55.92-10.594-109.635a14.17 14.17 0 0 1 1.977-11.514 14.161 14.161 0 0 1 9.957-6.11c4.514-.577 9.667-1.092 15.047-1.34a237.947 237.947 0 0 1 34.71 0c7.503.681 15.542 1.876 21.663 2.927a15.728 15.728 0 0 1 13.027 14.491z"
				/>
				<Path
					fill="#9D7A6F"
					d="M201.728 117.624c2.618 11.172 5.586 31.619-1.937 39.163 0 0 4.128 11.396 25.709 20.713 11.893-7.194 9.022-20.713 9.022-20.713-12.574-2.968-12.594-12.223-10.76-20.901l-22.034-18.262z"
				/>
				<Path
					fill="#B38A78"
					d="M189.176 97.135c5.029 18.159 6.802 25.951 17.767 33.763 16.49 11.79 36.855 2.555 37.102-16.49.247-17.17-8.039-44.007-26.94-48.212-18.901-4.204-32.979 12.759-27.929 30.939z"
				/>
				<Path
					fill="#301C11"
					d="M192.886 106.721c4.431-3.834 8.842-20.613 6.534-27.744 15.85 1.299 29.063-4.287 35.452 1.113.681-8.245-6.657-19.417-19.705-19.293-13.047.124-44.419 15.809-22.281 45.924z"
				/>
				<Path
					fill="#B38A78"
					d="M185.074 109.729a14.427 14.427 0 0 0 8.657 6.184c4.741 1.113 6.74-3.071 5.132-7.338-1.443-3.855-5.627-9.069-10.306-8.245-4.679.825-5.874 5.607-3.483 9.399z"
				/>
				<Path
					fill="#4DB388"
					d="M168.825 287h91.52c2.624-23.332 9.311-85.078 8.413-100.635-1.01-19.19-4.122-26.796-16.737-29.104-12.614-2.309-24.466-3.628-24.466-3.628a35.802 35.802 0 0 1 10.669 10.84c2.739 4.336 3.084 8.946 3.776 14.027s-1.52 10.09-3 15-.764 9.871-4.148 13.724c-8.493-15.789-40.709-40.523-32.98-53.406a92.486 92.486 0 0 0-22.487 1.608c-12.367 2.391-15.995 10.945-12.944 29.991 6.333 39.235 3.657 85.248 2.384 101.583z"
				/>
				<Path
					fill="#46A57D"
					d="M170.723 287c.326-9.813.953-28.761 1.134-29.83l4.468-29.759L178.5 202c-.812 1.729-7.924 12.5-7.924 12.5l-.926 72.5h1.073z"
				/>
				<Path
					fill="#7CC5AB"
					d="M343.491 197.527h96.91a6.49 6.49 0 0 0 6.491-6.488v-41.55a6.49 6.49 0 0 0-6.491-6.489h-96.91a6.49 6.49 0 0 0-6.491 6.489v41.55a6.49 6.49 0 0 0 6.491 6.488z"
				/>
				<Path fill="#7CC5AB" d="M355.136 188.715v24.6l25.082-25.073-25.082.473z" />
				<Path
					fill="#FFF"
					d="M425.869 175.556a4.786 4.786 0 1 0 0-9.572 4.787 4.787 0 1 0 0 9.572zM409.813 175.556a4.787 4.787 0 1 0 0-9.572 4.787 4.787 0 1 0 0 9.572zM393.757 175.556a4.786 4.786 0 1 0 0-9.572 4.787 4.787 0 1 0 0 9.572zM377.7 175.556a4.786 4.786 0 1 0 0-9.572 4.787 4.787 0 1 0 0 9.572zM361.644 175.556a4.786 4.786 0 1 0 0-9.572 4.787 4.787 0 1 0 0 9.572z"
				/>
				<Path
					fill="#62A583"
					d="M382.634 55H317.37a4.37 4.37 0 0 0-4.37 4.369v27.984a4.37 4.37 0 0 0 4.37 4.369h65.264a4.37 4.37 0 0 0 4.37-4.369V59.369a4.37 4.37 0 0 0-4.37-4.369z"
				/>
				<Path fill="#62A583" d="M374.789 85.787v16.565l-16.892-16.885 16.892.32z" />
				<Path
					fill="#FFF"
					d="M327.157 76.925a3.224 3.224 0 1 0 .001-6.449 3.224 3.224 0 0 0-.001 6.449zM337.97 76.925a3.224 3.224 0 1 0 .001-6.449 3.224 3.224 0 0 0-.001 6.449zM348.783 76.925a3.224 3.224 0 1 0 .001-6.449 3.224 3.224 0 0 0-.001 6.449zM359.596 76.925a3.224 3.224 0 1 0 .001-6.449 3.224 3.224 0 0 0-.001 6.449zM370.408 76.925a3.224 3.224 0 1 0 .001-6.449 3.224 3.224 0 0 0-.001 6.449z"
				/>
				<Path
					fill="#46A57D"
					d="m227.691 196.959-22.162-16.096-.048-3.758-6.834 4.136s1.611-24.104 3.066-28.241l14.597 25.622 11.381 18.337zM238.458 204.158l5.993-26.727-2.448-2.852 7.863-1.412s-17.14-17.024-20.967-19.167l5.98 28.876 3.579 21.282z"
				/>
				<Path
					fill="#B38A78"
					d="M217.002 221.469c0 2.535-1.423 4.596-3.154 4.596-1.731 0-3.154-2.061-3.154-4.596 0-2.536 1.423-4.597 3.154-4.597 1.731 0 3.154 2.061 3.154 4.597z"
				/>
				<Path
					fill="#70BFE0"
					d="m202.882 264.052 18.118-68.7a5.05 5.05 0 0 1 4.886-3.607l49.097 1.835a2.973 2.973 0 0 1 2.467 1.192 2.963 2.963 0 0 1 .44 2.703l-18.098 68.721a5.09 5.09 0 0 1-4.905 3.587l-49.098-1.835a2.973 2.973 0 0 1-2.467-1.192 2.97 2.97 0 0 1-.44-2.704z"
				/>
				<Path
					fill="#B38A78"
					d="m196.431 240.885.866-7.688 11.708-12.12a10.68 10.68 0 0 1 6.43-3.154l4.989-.577a3.443 3.443 0 0 1 3.751 2.515l2.432 9.399a3.605 3.605 0 0 1-2.226 4.122l-10.862 3.669s-10.059 7.173-17.088 3.834z"
				/>
				<Path
					fill="#4DB388"
					d="M190.68 177.585c-.742.969-1.69 2.288-2.535 3.463l-2.576 3.731a180.978 180.978 0 0 0-4.947 7.647 176.712 176.712 0 0 0-8.678 15.789 101.892 101.892 0 0 0-6.184 16.036 42.961 42.961 0 0 0-1.917 14.779l-4.638-7.647c-1.299-.825-1.257-.536-.969-.474.518.165 1.049.289 1.587.371 1.587.277 3.192.442 4.803.494 3.707.107 7.417-.01 11.11-.35a114.47 114.47 0 0 0 11.295-1.567 78.313 78.313 0 0 0 10.677-2.782l6.843 16.489a94.705 94.705 0 0 1-12.944 4.762 130.277 130.277 0 0 1-13.027 3.133 113.4 113.4 0 0 1-13.604 1.731 57.87 57.87 0 0 1-7.565 0 29.024 29.024 0 0 1-4.535-.639 18.014 18.014 0 0 1-6.678-2.823l-3.896-2.556-.742-5.071a64.515 64.515 0 0 1 1.216-23.539 126.03 126.03 0 0 1 6.513-20.385 201.496 201.496 0 0 1 8.843-18.675 246.926 246.926 0 0 1 5.009-8.925l2.68-4.39a114.799 114.799 0 0 1 2.906-4.514l21.953 15.912z"
				/>
			</Svg>
		</View>
	);
}
