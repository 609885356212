import { useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { getClientProgramReferrals } from './clientProgramReferralsService';
import { CLIENT_PROGRAM_REFERRALS_QUERY_KEY } from './queryKeys';

export function useClientProgramReferralsQuery() {
	const auth = useAuth();

	return useQuery(
		CLIENT_PROGRAM_REFERRALS_QUERY_KEY,
		async () => await getClientProgramReferrals(),
		{
			enabled: auth.isAuthenticated,
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false,
		}
	);
}
