import Icon, { IconNames } from '@mobe/components/icon/Icon';
import * as React from 'react';
import { View } from 'react-native';

export default function TabBarIcon({
	focused,
	iconName,
	iconNameFocused,
	iconWidth = 25,
}: {
	focused: boolean;
	iconName: IconNames;
	iconNameFocused?: IconNames;
	iconWidth?: number;
}) {
	const focusedIconName = iconNameFocused ? iconNameFocused : iconName;

	return (
		<View style={{ alignItems: 'center', width: 35 }}>
			<Icon
				size={iconWidth}
				color={focused ? 'primary' : 'textLight'}
				name={focused ? focusedIconName : iconName}
			/>
		</View>
	);
}
