import { Button } from '@mobe/components/button';
import EnvelopeGraphic from '@mobe/components/graphics/EnvelopeGraphic';
import EnvelopeGraphicError from '@mobe/components/graphics/EnvelopeGraphicError';
import Heading from '@mobe/components/heading/Heading';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import Text from '@mobe/components/text/Text';
import useTextStyles from '@mobe/components/text/textStyles';
import TextButton from '@mobe/components/textButton/TextButton';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, View } from 'react-native';
import {
	VerifiedEmailScreenNavigationProp,
	VerifiedEmailScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export default function VerifiedEmailScreen(props: {
	navigation: VerifiedEmailScreenNavigationProp;
	route: VerifiedEmailScreenRouteProp;
}) {
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const inlineButtonStyles = useTextStyles({ color: 'primary', size: 'md' });

	function handleSupportPress() {
		GlobalAnalyticsEvents.supportNumberPress();
		Linking.openURL(`tel:${t('auth.callForHelpNumber')}`);
	}

	return (
		<AuthScreenTemplate center>
			{props.route.params?.success ? (
				<>
					<View style={[constrain(200), vr(10), { marginTop: '20%' }]}>
						<EnvelopeGraphic variant="success" />
					</View>
					<Heading level="h2" accessibilityAutoFocus align="center" style={vr(5)}>
						{t('auth.verifiedEmail.title')}
					</Heading>
					<Text weight="medium" align="center" style={[constrainText(350), vr(8)]}>
						{t('auth.verifiedEmail.description')}
					</Text>
					<Button
						title={t('auth.verifiedEmail.returnButton')}
						style={vr(5)}
						onPress={() => {
							props.navigation.navigate('LOGIN_SCREEN');
						}}
					/>
				</>
			) : (
				<>
					<View style={[constrain(200), vr(10), { marginTop: '20%' }]}>
						<EnvelopeGraphicError />
					</View>
					<Heading level="h2" align="center" style={vr(5)}>
						{t('auth.verifiedEmail.titleError')}
					</Heading>
					<Text weight="medium" align="center" style={[constrainText(400), vr(6)]}>
						<MobeParsedText
							parse={[
								{
									pattern: new RegExp(t('helpNumberFormatted'), 'i'),
									style: inlineButtonStyles,
									onPress: () => handleSupportPress(),
								},
							]}
						>
							{t('auth.verifiedEmail.descriptionError', { phoneNumber: t('helpNumberFormatted') })}
						</MobeParsedText>
					</Text>
					<TextButton
						title={t('auth.verifiedEmail.returnButtonError')}
						align="center"
						style={vr(5)}
						onPress={() => {
							props.navigation.navigate('LOGIN_SCREEN');
						}}
					/>
				</>
			)}
		</AuthScreenTemplate>
	);
}

export function useVerifiedEmailScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.verifiedEmail.screenTitle'),
		headerShown: false,
	};
}
