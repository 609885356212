import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import {
	ActivityIndicator,
	Animated,
	Easing,
	Platform,
	StyleProp,
	View,
	ViewStyle,
} from 'react-native';

interface IDeferredLoadingIndicatorProps {
	children: React.ReactNode;
	isLoading: boolean;
	style?: StyleProp<ViewStyle>;

	/**
	 * Time in milliseconds before activity indicator shows.
	 */
	deferDuration?: number;
}

export default function DeferredLoadingIndicator({
	children,
	isLoading,
	style,
	deferDuration = 500,
}: IDeferredLoadingIndicatorProps) {
	const styleRules = useStyleRules();
	const fadeInChildrenAnimation = React.useRef(new Animated.Value(0)).current;
	const fadeInSpinnerAnimation = React.useRef(new Animated.Value(0)).current;
	const initialLoadingState = React.useRef(isLoading);
	const [initialLoadComplete, setInitialLoadComplete] = React.useState(
		!initialLoadingState.current
	);

	React.useEffect(() => {
		// No animation necessary if initial isLoading state is false
		if (!initialLoadingState.current) {
			return;
		}

		// Fade in content when content is loaded
		if (!isLoading) {
			Animated.timing(fadeInChildrenAnimation, {
				useNativeDriver: true,
				toValue: 1,
				duration: 400,
				easing: Easing.out(Easing.quad),
			}).start();
		}
	}, [fadeInChildrenAnimation, isLoading, initialLoadingState]);

	React.useEffect(() => {
		if (!initialLoadingState.current) {
			return;
		}

		const timeout = setTimeout(() => {
			if (!initialLoadComplete) {
				Animated.timing(fadeInSpinnerAnimation, {
					useNativeDriver: true,
					toValue: 1,
					duration: 400,
					easing: Easing.out(Easing.quad),
				}).start();
			}
		}, deferDuration);

		if (!isLoading) {
			clearTimeout(timeout);
			setInitialLoadComplete(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fadeInSpinnerAnimation, isLoading, initialLoadingState, deferDuration]);

	const childrenOpacity = fadeInChildrenAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [0, 1],
	});

	const spinnerOpacity = fadeInSpinnerAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [0, 1],
	});

	return (
		<View style={[{ flex: 1 }, style]}>
			{isLoading && !initialLoadComplete && (
				<Animated.View
					style={{
						opacity: spinnerOpacity,
						top: 30,
						width: '100%',
						position: 'absolute',
						alignItems: 'center',
					}}
				>
					<ActivityIndicator size="large" color={styleRules.colors.primary} />
				</Animated.View>
			)}
			<Animated.View
				style={{ flex: 1, opacity: initialLoadingState.current ? childrenOpacity : 1 }}
				// https://github.com/facebook/react-native/issues/25093#issuecomment-694256720
				needsOffscreenAlphaCompositing={Platform.OS === 'android'}
			>
				{children}
			</Animated.View>
		</View>
	);
}
