import * as React from 'react';
import { View } from 'react-native';
import Svg, { Ellipse, G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G transform="translate(-1659.225 -1430.354)">
					<Path
						fill="#CFEEFF"
						d="M1713.738 1446.804a78.034 78.034 0 00-3.607-.307c-1.7-.108-3.6-.186-5.63-.227h-6.552c-7.729.156-13.563.847-13.594 1.679v.009a.962.962 0 00.009.16l4.831 48.318a2.049 2.049 0 001.868 2h20.324a2.048 2.048 0 001.868-2l4.832-48.318c0-.051.009-.1.009-.152v-.016c-.021-.44-1.667-.842-4.358-1.146zm-29.309 1.312l-.02-.025.02.025z"
					/>
					<Path
						fill="#9ED7F7"
						d="M1689.012 1463.702l2.642 26.416c.263 2.631 2.113 4.612 4.309 4.612h10.513c2.2 0 4.046-1.981 4.309-4.612l2.641-26.416h-24.414z"
					/>
					<Ellipse fill="#81CEF8" cx={1701.221} cy={1463.702} rx={12.141} ry={1.217} />
					<G opacity={0.3} fill="#FFF">
						<Path d="M1693.74 1451.941l-4.2 30.617-3.032-30.324a1.927 1.927 0 01.147-.966l.354.059.459.069c.23.033.471.064.722.1l.307.038.134.015.228.025.233.024.238.024c.241.025.488.048.742.071l.258.023.215.017h.045l.535.042.357.027.451.032h.031l.571.036.155.01.738.041.3.016.012.004zM1693.432 1451.925l.3.016-.3-.016zM1692.538 1451.875l.155.009-.155-.009z" />
						<Path d="M1691.968 1451.838l.57.038a48.097 48.097 0 01-.57-.038zM1691.485 1451.805l.451.031-.451-.031zM1690.593 1451.736l.535.042-.535-.042zM1690.333 1451.715l.216.017-.216-.017zM1690.076 1451.692l.257.023-.257-.023zM1689.096 1451.595l.238.025-.238-.025zM1688.863 1451.571l-.228-.025.228.025zM1688.195 1451.491l.307.036-.307-.036zM1687.467 1451.397l-.459-.069c.149.023.303.042.459.069z" />
					</G>
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
