import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import {
	SuccessCloseTabScreenNavigationProp,
	SuccessCloseTabScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export interface ISuccessCloseTabScreenProps {
	navigation: SuccessCloseTabScreenNavigationProp;
	route: SuccessCloseTabScreenRouteProp;
}

export default function SuccessCloseTabScreen() {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = StyleSheet.create({
		hideContainer: {
			display: 'none',
		},
		showContainer: {
			display: 'flex',
		},
	});

	// Show blank screen when routing within Native App
	let displayStatus;
	if (Platform.OS !== 'web') {
		displayStatus = styles.hideContainer;
	} else {
		displayStatus = styles.showContainer;
	}

	return (
		<View style={displayStatus}>
			<AuthScreenTemplate heading={t('auth.success.heading')}>
				<Text weight="medium" align="center" style={{ ...vr(6) }}>
					{t('auth.success.description')}
				</Text>
			</AuthScreenTemplate>
		</View>
	);
}

export function useSuccessCloseTabScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return { title: t('auth.success.screenTitle'), headerShown: false };
}
