import useMonitoredPromise from '@mobe/utils/useMonitoredPromise';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import accountService, { IUpdatableUserProfile } from './accountService';

export enum AccountQueryKeys {
	Profile = 'account.profile',
}

export enum AccountMutationKeys {
	UpdateProfile = 'account.updateProfile',
}

export async function fetchProfile() {
	const response = await accountService.getProfile();

	if (!response.success) {
		throw response.error;
	}

	return response.data;
}

export function useProfileQuery() {
	const auth = useAuth();

	return useQuery(AccountQueryKeys.Profile, fetchProfile, {
		enabled: auth.isAuthenticated,
		staleTime: Infinity,
	});
}

interface IUpdateProfileMutationVariables {
	updatedProfileData: Partial<IUpdatableUserProfile>;
}

export function useUpdateProfileMutation() {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ updatedProfileData }: IUpdateProfileMutationVariables) => {
			const response = await accountService.updateProfile(updatedProfileData);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			mutationKey: AccountMutationKeys.UpdateProfile,
			onSuccess: () => {
				queryClient.invalidateQueries(AccountQueryKeys.Profile);
			},
		}
	);
}

interface IEligibilityCheckMutationVariables {
	firstName: string;
	lastName: string;
	dateForServer: string;
}

export function useEligibilityCheckMutation() {
	return useMutation(
		async ({ firstName, lastName, dateForServer }: IEligibilityCheckMutationVariables) => {
			const response = await accountService.eligibilityCheck(firstName, lastName, dateForServer);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		}
	);
}

export function useForgotPasswordMutation() {
	return useMutation(accountService.forgotPassword);
}

export function useClaimAccount() {
	return useMonitoredPromise<typeof accountService.claimAccount>(accountService.claimAccount);
}

export function useResendWelcomeEmail() {
	return useMonitoredPromise<typeof accountService.resendWelcomeEmail>(
		accountService.resendWelcomeEmail
	);
}

export function useResetPassword() {
	return useMonitoredPromise<typeof accountService.resetPassword>(accountService.resetPassword);
}

export function useChangeEmail() {
	return useMonitoredPromise<typeof accountService.changeEmail>(accountService.changeEmail);
}

export function useChangePassword() {
	return useMonitoredPromise<typeof accountService.changePassword>(accountService.changePassword);
}

export function useCreateValidicUser() {
	return useMonitoredPromise<typeof accountService.createValidicUser>(
		accountService.createValidicUser
	);
}

export function useGetEmailVerified() {
	return useMonitoredPromise<typeof accountService.getEmailVerified>(
		accountService.getEmailVerified
	);
}

export function useVerifyEmail() {
	return useMonitoredPromise<typeof accountService.verifyEmail>(accountService.verifyEmail);
}

export function useDeleteAccountMutation() {
	return useMutation(accountService.deleteAccount);
}
