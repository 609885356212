import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

export function ScreenFocusInertToggle({ children }: { children: React.ReactNode }) {
	const isFocused = useIsFocused();

	if (Platform.OS === 'web') {
		return (
			<div
				style={{ display: 'flex', flex: 1, minHeight: 0 }}
				{...{ inert: !isFocused ? 'true' : undefined }}
			>
				{children}
			</div>
		);
	} else {
		return children;
	}
}
