import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function PhoneGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(48, 52);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle fill="#F1F1EF" cx={24} cy={27.98} r={24} />
				<Path d="M27.265.931 9.645 4.91a2.544 2.544 0 0 0-1.921 3.042l8.237 36.477a2.544 2.544 0 0 0 3.042 1.921l17.62-3.979a2.543 2.543 0 0 0 1.921-3.042L30.308 2.852A2.544 2.544 0 0 0 27.265.931z" />
				<Path
					fill="#07343B"
					d="m8.467 5.275-.428.783.1.442.84-.554-.512-.671zM38.331 41.043l.464-.842-1.011-.084.547.926z"
				/>
				<Path
					fill="#2B2E30"
					d="m27.743.063-17.62 3.979a2.544 2.544 0 0 0-1.921 3.042l8.237 36.477a2.543 2.543 0 0 0 3.042 1.921l17.62-3.979a2.543 2.543 0 0 0 1.921-3.042L30.786 1.984A2.546 2.546 0 0 0 27.743.063z"
				/>
				<Path
					fill="#86BBD2"
					d="M30.482 5.038 9.789 9.711l6.954 30.795 20.693-4.673-6.954-30.795z"
				/>
				<Path
					fill="#4E5153"
					d="m21.862 5.099-4.262.962a.403.403 0 0 1-.48-.303.403.403 0 0 1 .303-.48l4.262-.962a.4.4 0 0 1 .479.303.403.403 0 0 1-.302.48zM31.036 40.525l-6.684 1.509a.403.403 0 0 1-.48-.303.399.399 0 0 1 .303-.48l6.684-1.509a.4.4 0 0 1 .177.783z"
				/>
				<Path
					opacity={0.28}
					fill="#FFF"
					d="M16.743 40.507 30.482 5.038l6.954 30.796-20.693 4.673z"
				/>
				<Path
					fill="#6CBCE5"
					d="M40.765 6.294a.603.603 0 0 1-.354-.026.618.618 0 0 1-.3-.898l1.695-2.684a.61.61 0 0 1 .864-.223.615.615 0 0 1 .168.879l-1.682 2.664a.611.611 0 0 1-.391.288zM45.778 8.405l-2.716.613a.61.61 0 0 1-.692-.471.61.61 0 0 1 .423-.722l2.716-.613a.611.611 0 0 1 .502 1.104.613.613 0 0 1-.233.089zM37.312 5.379a.61.61 0 0 1-.732-.463L35.967 2.2a.611.611 0 0 1 1.105-.502c.045.071.075.15.088.233l.613 2.716a.6.6 0 0 1-.078.462.601.601 0 0 1-.383.27z"
				/>
			</Svg>
		</View>
	);
}
