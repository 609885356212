import * as React from 'react';
import { AccessibilityInfo, Platform } from 'react-native';
import Analytics from './analytics';

function screenReaderEnabledEvent(isEnabled: boolean) {
	if (isEnabled) {
		Analytics.logEvent(`screen_reader_enabled_${Platform.OS}`);
	}
}

function reducedMotionEnabledEvent(isEnabled: boolean) {
	if (isEnabled) {
		Analytics.logEvent(`reduced_motion_enabled`);
	}
}

interface IAccessibilityInfo {
	screenReaderEnabled: boolean;
	reduceMotionEnabled: boolean;
}

const AccessibilityInfoContext = React.createContext<IAccessibilityInfo | undefined>(undefined);

function AccessibilityInfoProvider({ children }: { children: React.ReactNode }) {
	const [screenReaderEnabled, setScreenReaderEnabled] = React.useState(false);
	const [reduceMotionEnabled, setReduceMotionEnabled] = React.useState(false);

	React.useEffect(() => {
		const screenReaderChangedSubscription = AccessibilityInfo.addEventListener(
			'screenReaderChanged',
			(isScreenReaderEnabled) => {
				if (Platform.OS === 'web') return;
				setScreenReaderEnabled(isScreenReaderEnabled);
				screenReaderEnabledEvent(isScreenReaderEnabled);
			}
		);
		const reduceMotionChangedSubscription = AccessibilityInfo.addEventListener(
			'reduceMotionChanged',
			(isReduceMotionEnabled) => {
				setReduceMotionEnabled(isReduceMotionEnabled);
				reducedMotionEnabledEvent(isReduceMotionEnabled);
			}
		);

		AccessibilityInfo.isScreenReaderEnabled().then((isScreenReaderEnabled) => {
			if (Platform.OS === 'web') return;
			setScreenReaderEnabled(isScreenReaderEnabled);
			screenReaderEnabledEvent(isScreenReaderEnabled);
		});
		AccessibilityInfo.isReduceMotionEnabled().then((isReduceMotionEnabled) => {
			setReduceMotionEnabled(isReduceMotionEnabled);
			reducedMotionEnabledEvent(isReduceMotionEnabled);
		});

		return () => {
			reduceMotionChangedSubscription.remove();
			screenReaderChangedSubscription.remove();
		};
	}, []);

	const value: IAccessibilityInfo = {
		screenReaderEnabled,
		reduceMotionEnabled,
	};

	return (
		<AccessibilityInfoContext.Provider value={value}>{children}</AccessibilityInfoContext.Provider>
	);
}

function useAccessibilityInfo() {
	const context = React.useContext(AccessibilityInfoContext);
	if (context === undefined) {
		throw new Error('useAccessibilityInfo must be used within AccessibilityInfoProvider');
	}
	return context;
}

export { AccessibilityInfoProvider, useAccessibilityInfo };
