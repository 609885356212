import { mobeUnauthenticatedAPI } from '../mobeAPI';

interface IVersionResponse {
	build: string;
	date: string;
}

export function getAPIVersion() {
	return mobeUnauthenticatedAPI.get<IVersionResponse>('version');
}
