import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function ProgressTrophy(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(54.4, 85.6);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="none"
					d="M3.5 14.8c1.2 8.8 5.1 13.5 8.8 16 .7.5 1.5 1 2.3 1.3-3-6.2-4.3-13.8-4.9-19.5h-4c-2.5.1-2.2 2.2-2.2 2.2z"
				/>
				<Path fill="none" d="M26.7 67.9h5.9z" />
				<Path
					fill="none"
					d="M40.9 31.7c4-2.2 8.6-6.8 10-16.4 0 0 .4-2-2.2-2.2h-4.5c-.6 5.7-2 13.4-5.2 19.6.7-.3 1.3-.6 1.9-1z"
				/>
				<Path
					fill="#FFB425"
					d="M52.2 9.9l-7.7-.1c-.1 1-.2 2-.3 3.2h4.5c2.6.2 2.2 2.2 2.2 2.2-1.4 9.7-6 14.3-10 16.4-.6.3-1.3.6-1.9.9-.8 1.6-1.8 3.2-3 4.7.7-.2 1.3-.4 1.9-.6 16.2-5.8 16.5-23.8 16.5-23.8 0-1.3-.9-2.5-2.2-2.9z"
				/>
				<Path
					fill="#FFB425"
					d="M12.3 30.8c-3.7-2.5-7.6-7.2-8.8-16 0 0-.3-2.1 2.3-2.2h4c-.1-1.2-.2-2.2-.3-3.2H2.3C1 9.7 0 10.9 0 12.3c0 0 .1 17.1 15.1 23.7l2.4.9c-1.1-1.5-2.1-3.1-2.9-4.8-.8-.4-1.6-.8-2.3-1.3z"
				/>
				<Path
					d="M44.2 13c.1-1.1.2-2.2.3-3.2.1-.9.1-1.8.1-2.5h-.3L9.7 7h-.3c0 .7.1 1.5.1 2.5.1 1 .1 2 .3 3.2.6 5.6 1.9 13.2 4.9 19.5.8 1.7 1.8 3.3 2.9 4.8 1.6 2.1 3.6 3.9 6 5.1l.7.5c.1.1.2.2.4.3l-.1 18.3c-.2 2.7-1.1 6.8-4.3 6.8h13.3c-4.9-.1-4.3-9.3-4.3-9.3l.1-15.7c.2-.1.3-.2.5-.3l.6-.5c2.3-1.1 4.3-2.8 5.8-4.8 1.1-1.4 2.1-3 3-4.7 2.9-6.3 4.3-14 4.9-19.7z"
					fill="#FFC93B"
				/>
				<Path
					fill="#FAAC1D"
					d="M44.3 7.4h.3c1-.2 1.8-1 1.8-2.1V2.1c0-1.2-.9-2.1-2.1-2.1H9.7C8.6 0 7.6.9 7.6 2.1v2.8c0 1 .8 1.9 1.8 2.1h.3l34.6.4z"
				/>
				<Path d="M11.9 69.7l-.1 13.4h29.7l.1-13.4H11.9z" fill="#6E3D24" />
				<Path fill="#5E3521" d="M9.9 83.1l-.1 2.5h33.7v-2.5z" />
				<Path fill="#FFE396" d="M17.4 80.6H36v-7.5H17.5z" />
				<Path fill="#FAAC1D" d="M19.3 67.9v1.8h14.8v-1.8z" />
			</Svg>
		</View>
	);
}
