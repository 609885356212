import React from 'react';
import { OnboardingContext } from './OnboardingStackScreen';

const OnboardingRouteContext = React.createContext<OnboardingContext>('activity');

function OnboardingProvider({
	children,
	context = 'activity',
}: {
	children: React.ReactNode;
	context?: OnboardingContext;
}) {
	return (
		<OnboardingRouteContext.Provider value={context}>{children}</OnboardingRouteContext.Provider>
	);
}

function useOnboardingContext() {
	const context = React.useContext(OnboardingRouteContext);
	if (context === undefined) {
		throw new Error('useOnboardingContext must be used within an OnboardingProvider');
	}
	return context;
}

export { OnboardingProvider, useOnboardingContext };
