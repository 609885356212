import { IDateRangeSelect } from '@mobe/features/track/useDateRangeSelect';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';
import IconButton from '../iconButton/IconButton';
import Row from '../layout/Row';
import { Text } from '../text';

interface IInputDateRangeProps {
	dateRangeSelect: IDateRangeSelect;
	style?: StyleProp<ViewStyle>;
}

export default function InputDateRange({ dateRangeSelect, style }: IInputDateRangeProps) {
	const { t } = useTranslation();

	return (
		<Row gutterSize={10} style={[{ alignSelf: 'center' }, style]}>
			<Row.Item>
				<IconButton
					accessibilityLabel={t('dateRangeSelect.prevRangeLabel', {
						rangeLabel: dateRangeSelect.selectedRangeLabel,
					})}
					name="left"
					color="primary"
					size={16}
					onPress={dateRangeSelect.getPreviousRange}
				/>
			</Row.Item>
			<Row.Item>
				<Text weight="semiBold">{dateRangeSelect.label}</Text>
			</Row.Item>
			<Row.Item>
				<IconButton
					accessibilityLabel={t('dateRangeSelect.nextRangeLabel', {
						rangeLabel: dateRangeSelect.selectedRangeLabel,
					})}
					name="right"
					disabled={!dateRangeSelect.canGetNextRange}
					color={dateRangeSelect.canGetNextRange ? 'primary' : 'disabled'}
					size={16}
					onPress={dateRangeSelect.getNextRange}
				/>
			</Row.Item>
		</Row>
	);
}
