import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GuideAppointmentDetailScreenNavigationProp,
	GuideAppointmentDetailScreenRouteProp,
} from '../../navigation/modal/types';
import GuideAppointmentDetailView from './GuideAppointmentDetailView';

export interface IGuideAppointmentDetailScreenParams {
	confirmationId: string;
}

export interface IGuideAppointmentDetailScreenProps {
	navigation: GuideAppointmentDetailScreenNavigationProp;
	route: GuideAppointmentDetailScreenRouteProp;
}

export default function GuideAppointmentDetailScreen({
	navigation,
	route,
}: IGuideAppointmentDetailScreenProps) {
	const appointmentsQuery = useAppointmentsQuery();
	const allAppointments = appointmentsQuery.data?.allAppointments || [];
	const appointment = allAppointments.find(
		(appointment) => appointment.confirmationId === route.params.confirmationId
	);

	return (
		<ScreenTemplate noPadding>
			<DeferredLoadingIndicator isLoading={!appointment}>
				{appointment !== undefined && (
					<GuideAppointmentDetailView appointment={appointment} navigation={navigation} />
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplate>
	);
}

export function useGuideAppointmentDetailScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	return ({ navigation, route }: IGuideAppointmentDetailScreenProps): StackNavigationOptions => ({
		title: t('appointments.appointmentDetail.screenTitle'),
		...modalStyleProps,
	});
}
