import { MoodGraphic } from '@mobe/components/graphics';
import { Text } from '@mobe/components/text';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { IDefaultTrackerTableProps } from './DefaultTrackerTable';
import TrackerTable from './TrackerTable';

export default function MoodTrackerTable({
	entries,
	unit,
	...trackerTableProps
}: IDefaultTrackerTableProps) {
	const { t } = useTranslation();

	return (
		<TrackerTable
			{...trackerTableProps}
			tableColumnHeadings={[
				t('track.detail.dateTableHeading'),
				t('track.detail.timeTableHeading'),
				unit,
			]}
			tableRows={entries?.map((entry) => {
				return {
					entry,
					columns: [
						<Text key={`${entry.consumerTrackerId}-1`}>
							{format(new Date(entry.activityDateTime), 'LL/dd/yy')}
						</Text>,
						<Text key={`${entry.consumerTrackerId}-2`}>
							{format(new Date(entry.activityDateTime), 'p')}
						</Text>,
						<Text key={`${entry.consumerTrackerId}-3`}>
							<View style={{ maxWidth: 25 }}>
								<MoodGraphic expression={entry.value} colored accessible />
							</View>
						</Text>,
					],
				};
			})}
		/>
	);
}
