import env from '@mobe/env/env';
import axios from 'axios';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import { APIResponse } from './APIResponse';
import { default as APIService } from './APIService';

export interface IMobeAPIStandardError {
	code: string;
}

export const mobeStandardSuccessProcessor = <
	SuccessDataType = void,
	ErrorCodeType extends string | null = null,
	ErrorDataType extends IMobeAPIStandardError = IMobeAPIStandardError,
>(
	data?: SuccessDataType
) => {
	return APIResponse.success<SuccessDataType, ErrorCodeType, ErrorDataType>(data);
};

export const mobeStandardErrorProcessor = <
	SuccessDataType,
	ErrorCodeType extends string | null = null,
	ErrorDataType extends IMobeAPIStandardError = IMobeAPIStandardError,
>(
	error: unknown
) => {
	// if the error is not an axios error, re-throw it instead of processing the error away
	if (!axios.isAxiosError(error)) {
		throw error;
	}

	if (env.isDev) {
		console.error(`(!) API Service Error [${error.config.url}]:`, error.message);
		console.error('(!) Params: ', error.config.params);
		console.error('(!) Error response: ', error.response?.data);
	}

	if (error.response && error.response.data) {
		const data = error.response.data;
		const code = (data.code || 'UNKNOWN').toUpperCase() as ErrorCodeType;

		return APIResponse.error<SuccessDataType, ErrorCodeType, ErrorDataType>(code, data);
	}

	// TODO: what to do here? (no response received; failure before hitting server)
	return APIResponse.error<SuccessDataType, ErrorCodeType, ErrorDataType>(
		'UNKNOWN' as ErrorCodeType,
		{} as ErrorDataType
	);
};

// Process our APIResponse object for common react query and mutation functions
// The query function either needs to return data or throw an error
export function mobeStandardQueryAndMutationProcessor<
	SuccessDataType = void,
	ErrorCodeType extends string | null = null,
	ErrorDataType extends IMobeAPIStandardError = IMobeAPIStandardError,
>(response: APIResponse<SuccessDataType, ErrorCodeType, ErrorDataType>) {
	if (!response.success) {
		throw response.error;
	}

	return response.data;
}

// WARNING:
// The slice(0, 20) below was requested to make sure these strings are not longer than the allowed
// char limit on the database table. Do not change unless you know the database settings have changed
const customDeviceHeaders = {
	'X-Device-Platform': Platform.select({
		ios: 'iOS',
		android: 'Android',
		web: 'Web',
		default: 'Unknown Platform',
	}).slice(0, 20),
	'X-Device-OS-Version': `${Device.osName || 'Unknown OS'} ${
		Device.osVersion || 'Unknown Version'
	}`.slice(0, 100),
	'X-App-Version': env.APP_VERSION.slice(0, 20),
	'X-Device-Model': Device.modelName?.slice(0, 20) || 'Unknown',
};

export const mobeUnauthenticatedAPI = new APIService(env.MOBE_API_URL, customDeviceHeaders);
export const mobeAuthenticatedAPI = new APIService(env.MOBE_API_URL, customDeviceHeaders);
