import { useValidicUserPlatformsQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerSource } from '@mobe/api/track/trackService';
import useNativeTrackers, { ITrackerDevice } from '../connectHealthData/useNativeTrackers';

export default function useTrackerDisplayUtils() {
	const nativeTrackers = useNativeTrackers();
	const validicUserPlatformsQuery = useValidicUserPlatformsQuery();

	function getTrackerName(source: TrackerSource) {
		if (source === TrackerSource.GoogleFit || source === TrackerSource.AppleHealth) {
			return (
				nativeTrackers.find(
					(nativeTracker: ITrackerDevice) => nativeTracker.trackerSourceName === source
				)?.displayName || source
			);
		}

		return (
			validicUserPlatformsQuery.data?.find(
				(marketplaceTracker) => marketplaceTracker.trackerSourceName === source
			)?.displayName || source
		);
	}

	return { getTrackerName };
}
