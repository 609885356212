import { CommonActions, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../iconButton/IconButton';

export default function BackButton({
	onBackPress,

	/**
	 * Not utilized, but defining this prop makes React Nav integration cleaner.
	 */
	tintColor,
}: {
	onBackPress?: () => void;
	tintColor?: string;
}) {
	const navigation = useNavigation();
	const { t } = useTranslation();

	function handleBackPress() {
		if (onBackPress) {
			onBackPress();
			return;
		}

		if (navigation.canGoBack()) {
			navigation.goBack();
		} else {
			navigation.dispatch(CommonActions.navigate('BOTTOM_TAB_STACK'));
		}
	}

	return (
		<IconButton
			aria-label={t('accessibility.backButtonLabel')}
			size={20}
			hitSlop={15}
			name="left"
			onPress={handleBackPress}
		/>
	);
}
