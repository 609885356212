import {
	DateRangeSelectionValues,
	IDateRangeSelect,
} from '@mobe/features/track/useDateRangeSelect';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import Color from 'color';
import * as React from 'react';
import { Platform, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Text } from '../text';

export interface IDateSelectItem {
	label: string;
	value: DateRangeSelectionValues;
}

interface IInputDateIntervalProps {
	dateRangeSelect: IDateRangeSelect;
	style?: StyleProp<ViewStyle>;
}

export default function InputDateInterval({ dateRangeSelect, style }: IInputDateIntervalProps) {
	const styles = useStyles();

	return (
		<View style={[styles.container, style]} role="radiogroup">
			{dateRangeSelect.items.map((item) => (
				<Pressable
					key={item.value}
					style={[
						styles.option,
						dateRangeSelect.selectedRange === item.value && styles.option_selected,
					]}
					onPress={() => dateRangeSelect.handleDateRangeSelection(item.value)}
					role="radio"
					aria-checked={dateRangeSelect.selectedRange === item.value}
				>
					<Text align="center" weight="semiBold">
						{item.label}
					</Text>
				</Pressable>
			))}
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		container: {
			backgroundColor: rules.colors.background,
			borderRadius: rules.borderRadius,
			flexDirection: 'row',
			padding: 5,
		},
		option: {
			paddingVertical: 3,
			flexGrow: 1,
			flexBasis: 0,
			shadowColor: rules.colors.background,
			elevation: 2,
		},
		option_selected: {
			borderRadius: 3,
			backgroundColor: rules.colors.cardBackground,
			...cardShadow,

			// Slightly lighter shadow override
			...Platform.select({
				native: {
					shadowOpacity: 0.15,
				},
				web: {
					boxShadow: `${Color(rules.colors.shadow).alpha(0.15).string()} 0 1px 3px`,
				},
			}),
		},
	});
}
