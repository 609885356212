import * as React from 'react';
import { StyleSheet } from 'react-native';
import MobeFetchedImage from './MobeFetchedImage';
import { IMobeImagePropType, getImageSource } from './utils';

export default function MobeImage({
	apiImageId,
	source,
	renderWidth,
	sizesArray,
	useIntrinsicAspectRatio = false,
	style,
	...imageProps
}: IMobeImagePropType) {
	const processedSource = getImageSource({ apiImageId, source, renderWidth, sizesArray });

	// StyleSheets can be tokenized. Flattening ensures an object.
	const styles = StyleSheet.flatten(style);
	const width =
		typeof styles.width === 'string' || typeof styles.width === 'number' ? styles.width : undefined;
	const height =
		typeof styles.height === 'string' || typeof styles.height === 'number'
			? styles.height
			: undefined;

	if (!processedSource) {
		return null;
	}

	return (
		<MobeFetchedImage
			uri={
				typeof processedSource === 'object' ? processedSource.uri || '' : processedSource.toString()
			}
			width={useIntrinsicAspectRatio && width ? 'auto' : width}
			height={useIntrinsicAspectRatio && height ? 'auto' : height}
			resizeMode={imageProps.resizeMode}
		/>
	);
}
