import { useContentDetailQuery, useSetViewTimeMutation } from '@mobe/api/explore/exploreApiHooks';
import { ExploreContentType } from '@mobe/api/explore/exploreService';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { differenceInSeconds } from 'date-fns';
import * as React from 'react';
import { InteractionManager, Platform } from 'react-native';
import ExternalUrlView from '../components/ExternalUrlView';
import MobeContentView from '../components/MobeContentView';
import PdfView from '../components/PdfView';
import EmbeddedVideoView from './EmbeddedVideoView';

interface ISharedContentViewProps {
	contentId: number;
	context?: 'explore' | 'activities';
}

export default function SharedContentView({
	contentId,
	context = 'explore',
}: ISharedContentViewProps) {
	const contentItem = useContentDetailQuery(contentId);
	const setViewTime = useSetViewTimeMutation();
	const navigation = useNavigation();
	const consumptionStartTime = React.useMemo(() => new Date(Date.now()), []);

	// Android is afflicted by app crashing issues when a webview attempts to load during screen transitions.
	// This flag ensures that content components aren't instantiated until after transitions resolve.
	// iOS isn't an issue, so it is not deferred like Android, which improves perceived performance slightly.
	const [isTransitioning, setIsTransitioning] = React.useState(() =>
		Platform.OS === 'android' ? true : false
	);

	useFocusEffect(
		React.useCallback(() => {
			const task = InteractionManager.runAfterInteractions(() => {
				setIsTransitioning(false);
			});

			return () => task.cancel();
		}, [])
	);

	React.useEffect(() => {
		const removeListener = navigation.addListener('beforeRemove', () => {
			setIsTransitioning(true);
			setViewTime.mutate({
				sharedContentId: contentId,
				viewTime: differenceInSeconds(new Date(Date.now()), consumptionStartTime),
			});
		});

		return removeListener;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	let contentView: React.ReactNode = null;

	if (contentItem.data?.contentUrl) {
		if (Platform.OS === 'web') {
			contentView = <MobeContentView contentItem={contentItem.data} showGuideConnectCTA={false} />;
		} else {
			contentView = <ExternalUrlView url={contentItem.data.contentUrl} />;
		}
	} else if (contentItem.data?.fileUrl) {
		contentView = <PdfView contentItem={contentItem.data} />;
	} else if (
		contentItem.data?.contentHtml &&
		contentItem.data?.contentType === ExploreContentType.Video
	) {
		contentView = (
			<EmbeddedVideoView
				contentItem={contentItem.data}
				showGuideConnectCTA={context !== 'activities'}
			/>
		);
	} else if (contentItem.data?.contentHtml) {
		contentView = (
			<MobeContentView
				showLikeDislike
				contentItem={contentItem.data}
				showGuideConnectCTA={context !== 'activities'}
			/>
		);
	}

	// Being extra cautious with webviews on Android. Even animating opacity may result in a crash.
	if (Platform.OS === 'android') {
		return !(contentItem.isLoading || isTransitioning) ? contentView : null;
	}

	return (
		<DeferredLoadingIndicator isLoading={contentItem.isLoading || isTransitioning}>
			{contentView}
		</DeferredLoadingIndicator>
	);
}
