import { useValidicUserPlatformsQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerSource } from '@mobe/api/track/trackService';
import Button from '@mobe/components/button/Button';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import env from '@mobe/env/env';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useAlert } from '@mobe/utils/useAlert';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, Linking, Platform, Pressable, View } from 'react-native';
import WebView from 'react-native-webview';
import {
	MarketplaceDetailScreenNavigationProp,
	MarketplaceDetailScreenRouteProp,
} from '../../navigation/modal/types';
import * as ConnectHealthDataAnalyticsEvents from '../analyticsEvents';
import ConnectTrackerGraphic from '../components/ConnectTrackerGraphic';
import useSelectedDevice from '../useSelectedDevice';

//NOTE: in the mobile app, the "Success" screen conditionally renders blank
const SUCCESS_REDIRECT_URL = env.REDIRECT_TO_SUCCESS_SCREEN_URL;
const CONNECT_HEALTH_DATA_REDIRECT_URL = env.REDIRECT_TO_CONNECT_HEALTH_DATA_URL;

const OPEN_IN_BROWSER_CONFIG = [
	TrackerSource.Fitbit,
	TrackerSource.Misfit,
	TrackerSource.Withings,
	TrackerSource.UnderArmour,
	TrackerSource.Omron,
];

type MarketplaceScreenState =
	| 'introConnect'
	| 'connect'
	| 'disconnect'
	| 'connectingGoogleFit'
	| 'complete';
export interface IMarketplaceDetailScreenParams {
	trackerSourceName: TrackerSource;
}

export interface IMarketplaceDetailScreenProps {
	navigation: MarketplaceDetailScreenNavigationProp;
	route: MarketplaceDetailScreenRouteProp;
}

export default function MarketplaceDetailScreen({
	route,
	navigation,
}: IMarketplaceDetailScreenProps) {
	const { constrain, vr } = useStyleHelpers();
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const validicUserPlatformsQuery = useValidicUserPlatformsQuery();
	const trackerSourceName = route.params.trackerSourceName;
	const selectedNativeTracker = useSelectedDevice(trackerSourceName).selectedNativeTracker;
	const selectedMarketplaceTracker =
		useSelectedDevice(trackerSourceName).selectedMarketplaceTracker;
	const selectedDevice = useSelectedDevice(trackerSourceName).selectedDevice;

	const connectUrl = React.useMemo(() => selectedMarketplaceTracker?.connectUrl, []);
	const disconnectUrl = React.useMemo(() => selectedMarketplaceTracker?.disconnectUrl, []);
	const { mobeAlert } = useAlert();

	// Simple state machine to manage views
	const [currentView, setCurrentView] = React.useState<MarketplaceScreenState>(() => {
		if (connectUrl || selectedNativeTracker) {
			return 'introConnect';
		}
		return 'disconnect';
	});

	useFocusEffect(
		React.useCallback(() => {
			navigation.setOptions({
				headerTitle: selectedDevice?.displayName,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	// Trigger alert before leaving device connection WebView to indicate that device will not be connected
	React.useEffect(() => {
		// This controls the modal screen hiding when the user "completes" connection or taps off the google fit auth screen
		if (currentView === 'complete' || currentView === 'connectingGoogleFit') {
			navigation.pop();
		}

		if (currentView === 'introConnect') {
			return;
		}

		if (currentView === 'connect' && devicePlatformNeedsBrowser) {
			const browserConnectUrl = connectUrl + '&redirect_uri=' + CONNECT_HEALTH_DATA_REDIRECT_URL;
			Linking.openURL(browserConnectUrl);
			setCurrentView('complete');
		}
	}, [currentView]);

	React.useEffect(() => {
		if (currentView === 'introConnect') {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			event.preventDefault();

			mobeAlert(
				t('connectHealthData.earlyExitAlert.title'),
				t('connectHealthData.earlyExitAlert.body'),
				[
					{
						style: 'destructive',
						text: t('connectHealthData.earlyExitAlert.leave'),
						onPress: () => {
							ConnectHealthDataAnalyticsEvents.connectHealthDataEarlyExitConfirm();
							navigation.dispatch(event.data.action);
						},
					},
					{
						style: 'cancel',
						text: t('connectHealthData.earlyExitAlert.stay'),
						onPress: () => ConnectHealthDataAnalyticsEvents.connectHealthDataEarlyExitCancel(),
					},
				]
			);
		});

		return removeListener;
	}, [currentView]);

	if (!selectedDevice) {
		return null;
	}

	const logoSource = selectedDevice.logoSource;
	const devicePlatformNeedsBrowser = OPEN_IN_BROWSER_CONFIG.includes(
		selectedDevice.trackerSourceName
	);

	/**
	 * Interstitial view that displays in webview before the specific Marketplace device connection.
	 */
	function renderMarketplaceIntroView() {
		if (!selectedDevice) {
			return null;
		}

		function handleButtonPress() {
			if (Platform.OS === 'web') {
				const webTabConnectUrl = connectUrl + `&redirect_uri=` + SUCCESS_REDIRECT_URL;
				return openNewWebTab(webTabConnectUrl, () =>
					ConnectHealthDataAnalyticsEvents.connectHealthDataSuccess()
				);
			}

			// This is set up to handle other native trackers (besides Google Fit) in the future
			if (selectedNativeTracker) {
				selectedNativeTracker.connect();
				setCurrentView('complete');
			} else {
				setCurrentView('connect');
			}
		}

		function handleGoogleFitSignInPress() {
			async function tryToConnect() {
				// Using this "connecting" state only for google fit to make sure we can keep the intro screen rendered
				// while the user is using the validic/google oauth popup
				setCurrentView('connectingGoogleFit');

				// Awaiting here doesn't seem to do anything because validic subscribe doesn't do anything
				// to await google oauth popup...
				await selectedNativeTracker?.connect();
			}

			tryToConnect();
		}

		return (
			<ScreenTemplateWithFooter
				fixedPositionFooter
				centered
				footer={
					selectedDevice.trackerSourceName === 'google_fit_sdk' ? (
						<Pressable onPress={handleGoogleFitSignInPress} style={{ alignItems: 'center' }}>
							<Image
								source={require('@mobe/assets/images/google-fit-signin.png')}
								style={{ width: 325, height: 75 }}
								resizeMode="contain"
							/>
						</Pressable>
					) : (
						<Button
							title={t('connectHealthData.marketplaceDetailIntro.nextStepButton')}
							onPress={() => handleButtonPress()}
						/>
					)
				}
			>
				<View style={[constrain(325), vr(8)]}>
					<ConnectTrackerGraphic logoSource={logoSource} />
				</View>
				<Text
					accessibilityAutoFocus
					size="lg"
					weight="medium"
					align="center"
					style={constrain(350)}
				>
					{t('connectHealthData.marketplaceDetailIntro.connectDeviceDescription')}
				</Text>
			</ScreenTemplateWithFooter>
		);
	}

	function openNewWebTab(url: string, onCloseWebview: () => void) {
		window.open(url, '_blank', 'noopener'); // noopener so that session storage doesn't persist into new window
		onCloseWebview();
		setCurrentView('complete');
		validicUserPlatformsQuery.refetch();
	}

	function renderConnectWebview() {
		const webviewConnectUrl = connectUrl + `&redirect_uri=` + SUCCESS_REDIRECT_URL;

		return renderWebview(webviewConnectUrl, () => {
			ConnectHealthDataAnalyticsEvents.connectHealthDataSuccess();
		});
	}

	function renderDisconnectWebview() {
		const webviewDisconnectUrl = disconnectUrl + `&redirect_uri=` + SUCCESS_REDIRECT_URL;

		return renderWebview(webviewDisconnectUrl, () => {
			ConnectHealthDataAnalyticsEvents.disconnectHealthDataSuccess();
		});
	}

	/**
	 * View that renders actual Marketplace device connection/disconnection webview.
	 */
	function renderWebview(url: string, onCloseWebview: () => void) {
		return (
			<View style={{ flex: 1 }}>
				{url && (
					<WebView
						source={{ uri: url }}
						startInLoadingState={true}
						onNavigationStateChange={(WebviewState) => {
							if (
								WebviewState.url === CONNECT_HEALTH_DATA_REDIRECT_URL ||
								WebviewState.url === SUCCESS_REDIRECT_URL
							) {
								onCloseWebview();
								validicUserPlatformsQuery.refetch();
								setCurrentView('complete');
							}
						}}
						renderLoading={() => (
							<ActivityIndicator
								size="large"
								color={styleRules.colors.primary}
								style={{ position: 'absolute', height: '100%', width: '100%' }}
							/>
						)}
					/>
				)}
			</View>
		);
	}

	return (
		<>
			{/* connectingGoogleFit is the state we stay in while google oauth pop up is active */}
			{(currentView === 'introConnect' || currentView === 'connectingGoogleFit') &&
				renderMarketplaceIntroView()}
			{currentView === 'connect' && renderConnectWebview()}
			{currentView === 'disconnect' && renderDisconnectWebview()}
		</>
	);
}

export function useMarketplaceDetailScreenOptions(): StackNavigationOptions {
	const modalStyleProps = useModalStyleProps();

	// Screen title for both connection and disconnection
	return {
		title: '',
		...modalStyleProps,
	};
}
