import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import Icon from '@mobe/components/icon/Icon';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useCalendarTheme from '@mobe/utils/styles/useCalendarTheme';
import {
	addDays,
	addMinutes,
	addMonths,
	differenceInCalendarDays,
	differenceInCalendarMonths,
	min as earliestDate,
	format,
	isAfter,
	isSameDay,
	isSameMonth,
} from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Calendar, DateObject, DotMarking } from 'react-native-calendars';

const DATE_FORMAT = 'yyyy-MM-dd';

type MarkedDates = { [key: string]: DotMarking };

interface IApptCalendarProps {
	dates: Date[];
	dotDates?: Date[];
	selectedDate: Date;
	onDateSelect: (date: Date) => void;
	onMonthChange?: () => void;
}

export default function ApptCalendar({
	dates,
	dotDates = [],
	selectedDate,
	onDateSelect,
	onMonthChange = noop,
}: IApptCalendarProps) {
	const { t } = useTranslation();
	const rules = useStyleRules();
	const calendarTheme = useCalendarTheme();
	const allAppointments = useAppointmentsQuery().data?.allAppointments || [];
	const earliestExistingApptDate = allAppointments.length
		? new Date(allAppointments[0].appointmentStartDate)
		: null;
	const earliestAvailableApptDate = dates[0];
	const startDate = earliestExistingApptDate
		? earliestDate([earliestExistingApptDate, earliestAvailableApptDate])
		: earliestAvailableApptDate;
	const latestApptDate = dates[dates.length - 1];

	const [monthOffset, setMonthOffset] = React.useState(() => {
		return differenceInCalendarMonths(selectedDate, startDate);
	});

	/**
	 * Number of calendar days from earliest to latest available appointment date.
	 */
	const dateRangeInDays: number = differenceInCalendarDays(latestApptDate, startDate);

	/**
	 * Object for configuring behavior of dates within range, as defined by dateRangeInDays.
	 * This is where calendar dates are marked as disabled (enabled is implicit) or selected.
	 */
	const markedDates = constructMarkedDateObject();
	function constructMarkedDateObject() {
		const markedDates: MarkedDates = {};

		addDateEntriesInRange(markedDates);
		addEnabledDateEntries(markedDates);
		addSelectedDateEntry(markedDates);

		return markedDates;
	}

	/**
	 * Add entries for every date within inferred date range. Configure each date as disabled.
	 */
	function addDateEntriesInRange(object: MarkedDates) {
		for (let i = 0; i <= dateRangeInDays; i++) {
			const date = addDays(startDate, i);
			object[format(date, DATE_FORMAT)] = {
				disabled: true,
				marked: dotDates.some((dotDate) => isSameDay(date, dotDate)),
				dotColor: rules.colors.primary,
			};
		}
	}

	/**
	 * Replace disabled entries with dates that have open appointment slots.
	 */
	function addEnabledDateEntries(object: MarkedDates) {
		dates.forEach((date) => {
			object[format(date, DATE_FORMAT)] = {
				...object[format(date, DATE_FORMAT)],
				disabled: false,
			};
		});
	}

	/**
	 * Update existing enabled entry with `selected` flag.
	 */
	function addSelectedDateEntry(object: MarkedDates) {
		object[format(selectedDate, DATE_FORMAT)] = {
			...object[format(selectedDate, DATE_FORMAT)],
			selected: true,
			disabled: false,
			selectedColor: rules.colors.primary,
			dotColor: rules.colors.cardBackground,
		};
	}

	/**
	 * Returns true if currently viewed month (as determined by monthOffset and startDate) is
	 * last month with selectable dates.
	 */
	function isLastMonthWithAppts(): boolean {
		const currentlyDisplayedMonth = addMonths(startDate, monthOffset);

		return (
			isSameMonth(currentlyDisplayedMonth, latestApptDate) ||
			isAfter(currentlyDisplayedMonth, latestApptDate)
		);
	}

	function handleDayPress(day: DateObject) {
		if (!Object.keys(markedDates).includes(day.dateString)) {
			return;
		}

		if (markedDates[day.dateString].disabled) {
			return;
		}

		// Date string to date results in time of `00:00.00.000`
		const date = new Date(day.dateString);

		// Time zone offset applied to date to ensure that date is correct relative to device clock
		onDateSelect(addMinutes(date, date.getTimezoneOffset()));
	}

	function handlePrevMonthPress(subtractMonth: () => void) {
		setMonthOffset(monthOffset - 1);
		subtractMonth();
		onMonthChange();
	}

	function handleNextMonthPress(addMonth: () => void) {
		setMonthOffset(monthOffset + 1);
		addMonth();
		onMonthChange();
	}

	function renderArrow(direction: 'left' | 'right') {
		// On iOS the calendar component is rendering the month and arrows as an 'adjustable'
		const accessibilityLabel =
			direction === 'left'
				? t('calendar.accessibilityLabels.previousMonth')
				: t('calendar.accessibilityLabels.nextMonth');

		if (direction === 'left' && monthOffset === 0) {
			return;
		}

		if (direction === 'right' && isLastMonthWithAppts()) {
			return;
		}

		return (
			<Icon
				name={direction}
				color="primary"
				accessibilityLabel={accessibilityLabel}
				aria-label={accessibilityLabel}
			/>
		);
	}

	return (
		<View style={{ maxWidth: 500, width: '100%', alignSelf: 'center' }}>
			<Calendar
				current={selectedDate}
				markedDates={markedDates}
				minDate={dates[0]}
				maxDate={dates[dates.length - 1]}
				onDayPress={handleDayPress}
				monthFormat={'MMMM yyyy'}
				hideExtraDays={true}
				onPressArrowLeft={handlePrevMonthPress}
				onPressArrowRight={handleNextMonthPress}
				disableArrowLeft={monthOffset === 0}
				disableArrowRight={isLastMonthWithAppts()}
				renderArrow={renderArrow}
				theme={calendarTheme}
			/>
		</View>
	);
}
