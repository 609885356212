import Analytics from '@mobe/utils/analytics';

/**
 * Fires upon completion of assessment only in the context of initial sign in
 */
export function assessmentFirstSignInComplete() {
	Analytics.logEvent('about_you_first_sign_in_complete');
}

export function assessmentFirstSignInSkip() {
	Analytics.logEvent('about_you_first_sign_in_skip');
}

/**
 * Fires upon completion of assessment only in the context of opening it as an activity
 */
export function assessmentActivityComplete() {
	Analytics.logEvent('about_you_activity_complete');
}
