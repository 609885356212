import { useAllInterestsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import {
	InterestEmotionalGraphic,
	InterestFitnessGraphic,
	InterestGeneralGraphic,
	InterestMindsetGraphic,
	InterestNutritionGraphic,
	InterestSleepGraphic,
} from '@mobe/components/graphics';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	GuideMatchInterestsScreenNavigationProp,
	GuideMatchInterestsScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { MAX_INTERESTS } from '../constants';
import { useGuideMatchForm } from '../guideMatchProvider';

type ConfigItem = {
	label: string;
	graphic: JSX.Element;
};

interface IGuideMatchInterestsScreenProps {
	navigation: GuideMatchInterestsScreenNavigationProp;
	route: GuideMatchInterestsScreenRouteProp;
}

export default function GuideMatchInterestsScreen({ route }: IGuideMatchInterestsScreenProps) {
	const { vr, constrain } = useStyleHelpers();
	const { t } = useTranslation();
	const formController = useGuideMatchForm();
	const allInterests = useAllInterestsQuery();
	const interestsControl = formController.interestsControl;

	const interests = Array.isArray(interestsControl.field.value) ? interestsControl.field.value : [];
	const canProgress = !interestsControl.fieldState.error && interestsControl.fieldState.isDirty;
	const [isFooterFixedPosition, setIsFooterFixedPosition] = React.useState(false);

	// Bump footer to fixed position after any interaction
	React.useEffect(() => {
		if (canProgress) {
			setIsFooterFixedPosition(true);
		}
	}, [canProgress]);

	// TODO: Need to map config from a slug rather than the number id when the api is updated
	function getOptionConfig(id: number) {
		const configMap: Record<number, ConfigItem> = {
			15: {
				label: t('appointments.guideMatch.interestsStep.options.nutrition'),
				graphic: InterestNutritionGraphic({}),
			},
			16: {
				label: t('appointments.guideMatch.interestsStep.options.sleep'),
				graphic: InterestSleepGraphic({}),
			},
			17: {
				label: t('appointments.guideMatch.interestsStep.options.fitness'),
				graphic: InterestFitnessGraphic({}),
			},
			18: {
				label: t('appointments.guideMatch.interestsStep.options.emotional'),
				graphic: InterestEmotionalGraphic({}),
			},
			19: {
				label: t('appointments.guideMatch.interestsStep.options.mind'),
				graphic: InterestMindsetGraphic({}),
			},
			20: {
				label: t('appointments.guideMatch.interestsStep.options.general'),
				graphic: InterestGeneralGraphic({}),
			},
		};

		const config = configMap[id];

		if (!config) {
			return {
				label: '',
				graphic: null,
			};
		}

		return config;
	}

	function handleSelection(value: number) {
		const newInterests = Array.from(interests);

		if (newInterests.includes(value)) {
			interestsControl.field.onChange(newInterests.filter((interest) => interest !== value));
			return;
		}

		if (newInterests.length < MAX_INTERESTS) {
			newInterests.push(value);
			interestsControl.field.onChange(newInterests);
		}
	}

	return (
		<GuideMatchScreenTemplate
			routeName={route.name}
			canProgress={canProgress}
			fixedPositionFooter={isFooterFixedPosition}
		>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(4)}
			>
				{t('appointments.guideMatch.interestsStep.description')}
			</Text>
			<Text id="interests-sub-copy" size="lg" weight="medium" align="center" style={vr(4)}>
				{t('appointments.guideMatch.interestsStep.subCopy')}
			</Text>
			<VrArray>
				{allInterests.data?.map((option) => {
					const optionConfig = getOptionConfig(option.id);
					return (
						<BoxButton
							key={option.id}
							selected={interests.includes(option.id)}
							onPress={() => handleSelection(option.id)}
							style={{ minHeight: 80 }}
							role="checkbox"
							accessibilityHint={t('appointments.guideMatch.interestsStep.interestCheckboxHint', {
								count: interests.length,
							})}
							aria-describedby="interests-sub-copy"
						>
							<Row>
								<Row.Item>
									<View style={constrain(32)}>{optionConfig.graphic}</View>
								</Row.Item>
								<Row.Item fill>
									<Text size="lg" weight="medium">
										{optionConfig.label}
									</Text>
								</Row.Item>
							</Row>
						</BoxButton>
					);
				})}
			</VrArray>
		</GuideMatchScreenTemplate>
	);
}
