import { snakeCase } from 'lodash';
import crashlytics from './crashlytics';

export interface IAnalyticsModule {
	logEvent: (eventName: string, eventParams?: { [key: string]: unknown }) => Promise<void>;
	logScreenView: (
		screenName?: string | undefined,
		screenClassOverride?: string | undefined
	) => Promise<void>;
	logLogin: (params: { method?: string }) => Promise<void>;
}

const CUSTOM_EVENT_NAME_PREFIX = 'm';

export function buildCustomEventName(eventName: string) {
	return `${CUSTOM_EVENT_NAME_PREFIX}_${snakeCase(eventName)}`;
}

// Perform extra app specific processing of event params
export function processEventParams(params: Record<string, unknown>) {
	try {
		const result: Record<string, unknown> = {};

		for (const prop in params) {
			const value = params[prop];
			// If a param value is a number we want it to be a string because of
			// a GA bug converting numbers to read in scientific notation...
			if (typeof value === 'number') {
				result[prop] = value.toString();
			} else if (typeof value === 'object') {
				continue;
			} else {
				// Everything else is fine right now, booleans, strings
				result[prop] = value;
			}
		}

		return result;
	} catch (error) {
		if (error instanceof Error) {
			crashlytics.recordError(error, 'process event params error');
		}
	}
}
