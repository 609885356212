import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import Color from 'color';
import * as React from 'react';
import { Platform, Switch as RNSwitch, SwitchProps } from 'react-native';

export default function Switch(props: SwitchProps) {
	const styleRules = useStyleRules();
	const isDarkScheme = styleRules.settings.colorScheme === 'dark';

	return (
		<RNSwitch
			thumbColor={Platform.select({
				ios: styleRules.colors.cardBackground,
				android: props.value
					? styleRules.colors.primary
					: isDarkScheme
					? styleRules.colors.stroke
					: styleRules.colors.strokeLight,
				default: undefined,
			})}
			trackColor={Platform.select({
				ios: {
					true: styleRules.colors.primary,
				},
				android: {
					true: isDarkScheme
						? styleRules.colors.backgroundPrimary
						: Color(styleRules.colors.primary).lighten(1).hex(),
					false: styleRules.colors.textLight,
				},
				default: undefined,
			})}
			ios_backgroundColor={styleRules.colors.stroke}
			{...props}
		/>
	);
}
