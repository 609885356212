import { BottomTabBarButtonProps } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { Platform, Pressable, View } from 'react-native';

export default function TabBarButton({ children, ...tabBarButtonProps }: BottomTabBarButtonProps) {
	function renderChildren() {
		// Hiding icon and text descendants for talk back...
		return Platform.OS === 'android' ? (
			<View importantForAccessibility="no-hide-descendants">{children}</View>
		) : (
			children
		);
	}

	return <Pressable {...tabBarButtonProps}>{renderChildren()}</Pressable>;
}
