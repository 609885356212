import React from 'react';
import { BottomTabStackScreenNavigationProp } from './types';

const MenuNavigationContext = React.createContext<BottomTabStackScreenNavigationProp | undefined>(
	undefined
);

function MenuNavigationProvider({
	children,
	value,
}: {
	children: React.ReactNode;
	value: BottomTabStackScreenNavigationProp;
}) {
	return <MenuNavigationContext.Provider value={value}>{children}</MenuNavigationContext.Provider>;
}

function useMenuNavigation() {
	const context = React.useContext(MenuNavigationContext);
	if (context === undefined) {
		throw new Error('useMenuNavigation must be used within a MenuNavigationProvider');
	}
	return context;
}

export { MenuNavigationProvider, useMenuNavigation };
