import { Theme as INavigationTheme } from '@react-navigation/native';
import { useStyleRules } from '../styleRules/StyleRulesProvider';

export function useNavigationTheme(): INavigationTheme {
	const { settings, colors } = useStyleRules();

	return {
		dark: settings.colorScheme === 'dark',
		colors: {
			primary: colors.primary,
			background: colors.cardBackground,
			card: colors.cardBackground,
			text: colors.text,
			border: colors.stroke,
			notification: colors.primary,
		},
	};
}
