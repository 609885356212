import { GenderPreference } from '@mobe/api/appointments/appointmentsService';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import VrArray from '@mobe/components/layout/VrArray';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GuideMatchGenderScreenNavigationProp,
	GuideMatchGenderScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchGenderScreenProps {
	navigation: GuideMatchGenderScreenNavigationProp;
	route: GuideMatchGenderScreenRouteProp;
}

export default function GuideMatchGenderScreen({ route }: IGuideMatchGenderScreenProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const formController = useGuideMatchForm();
	const genderControl = formController.genderControl;

	const genderOptions: Array<{ label: string; value: GenderPreference }> = [
		{
			label: t('appointments.guideMatch.genderStep.options.male'),
			value: GenderPreference.Male,
		},
		{
			label: t('appointments.guideMatch.genderStep.options.female'),
			value: GenderPreference.Female,
		},
		{
			label: t('appointments.guideMatch.genderStep.options.noPreference'),
			value: GenderPreference.NoPreference,
		},
	];

	return (
		<GuideMatchScreenTemplate routeName={route.name} canProgress={!genderControl.fieldState.error}>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(8)}
			>
				{t('appointments.guideMatch.genderStep.description')}
			</Text>
			<VrArray role="radiogroup">
				{genderOptions.map((option) => (
					<BoxButton
						key={option.value}
						selected={option.value === genderControl.field.value}
						onPress={() => genderControl.field.onChange(option.value)}
						role="radio"
					>
						<Text size="lg" weight="medium">
							{option.label}
						</Text>
					</BoxButton>
				))}
			</VrArray>
		</GuideMatchScreenTemplate>
	);
}
