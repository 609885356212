import { useValidicUserPlatformsQuery } from '@mobe/api/track/trackApiHooks';
import { IMarketplaceTracker, TrackerSource } from '@mobe/api/track/trackService';
import BulletListItem from '@mobe/components/bulletListItem/BulletListItem';
import Button from '@mobe/components/button/Button';
import CardButton from '@mobe/components/cardButton/CardButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import Text from '@mobe/components/text/Text';
import env from '@mobe/env/env';
import { tryToFocusFirstElementWithIdContainingSubstring } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useAccessibilityInfo } from '@mobe/utils/useAccessibilityInfo';
import { useAlert } from '@mobe/utils/useAlert';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Image, Platform, View } from 'react-native';
import { useModalNavigation } from '../../navigation/modal/ModalNavigationProvider';
import useConnectTrackerStyles from '../ConnectTrackersStyles';
import * as ConnectHealthDataAnalyticsEvents from '../analyticsEvents';
import useNativeTrackers, { ITrackerDevice } from '../useNativeTrackers';
import useSelectedDevice from '../useSelectedDevice';

const WEB_TAB_REDIRECT_URL = env.REDIRECT_TO_SUCCESS_SCREEN_URL;

export default function ConnectTrackersView() {
	const nativeTrackers = useNativeTrackers();
	const validicUserPlatforms = useValidicUserPlatformsQuery();
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();
	const remoteConfigData = useRemoteConfigData();
	const { screenReaderEnabled } = useAccessibilityInfo();
	const appState = React.useRef(AppState.currentState);
	const { t } = useTranslation();

	let availableNativeTrackers;
	if (remoteConfigData.featureIntegratedTrackers.showGoogleFit) {
		availableNativeTrackers = nativeTrackers;
	} else {
		availableNativeTrackers = nativeTrackers.filter(
			(tracker) => tracker.trackerSourceName !== TrackerSource.GoogleFit
		);
	}

	// NOTE: manage refresh of trackers' connected status when user returns to app from having been Linked to Native Browser:
	React.useEffect(() => {
		const subscription = AppState.addEventListener('change', (nextAppState) => {
			if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
				validicUserPlatforms.refetch();
			}
			appState.current = nextAppState;
		});

		return () => subscription.remove();
	}, []);

	useFocusEffect(
		React.useCallback(() => {
			// Try to focus first tracker card on web
			tryToFocusFirstElementWithIdContainingSubstring('tracker-card');
		}, [validicUserPlatforms.isSuccess])
	);

	return (
		<ScreenTemplate scrollViewBackgroundColor={styleRules.colors.background}>
			{screenReaderEnabled ? (
				<Heading accessibilityAutoFocus level="h4" style={vr(4)}>
					{t('connectHealthData.marketplace.accessibilityHeading')}
				</Heading>
			) : null}

			<DeferredLoadingIndicator isLoading={validicUserPlatforms.isLoading}>
				{validicUserPlatforms.isSuccess && (
					<VrArray>
						{availableNativeTrackers
							.filter((item) => item.isAvailable && item.isConnected)
							.map((item) => (
								<NativeTrackerCard key={item.displayName} tracker={item} />
							))}
						{validicUserPlatforms.data
							.filter((item) => item.isConnected)
							.map((item) => (
								<MarketplaceTrackerCard key={item.displayName} tracker={item} />
							))}
						{availableNativeTrackers
							.filter((item) => item.isAvailable && !item.isConnected)
							.map((item) => (
								<NativeTrackerCard key={item.displayName} tracker={item} />
							))}
						{validicUserPlatforms.data
							.filter((item) => !item.isConnected)
							.map((item) => (
								<MarketplaceTrackerCard key={item.displayName} tracker={item} />
							))}
					</VrArray>
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplate>
	);
}

function NativeTrackerCard({ tracker }: { tracker: ITrackerDevice }) {
	return <TrackerCard tracker={tracker} />;
}

function MarketplaceTrackerCard({
	tracker,
}: {
	tracker: Omit<IMarketplaceTracker, 'connect' | 'disconnect'>;
}) {
	const modalNavigation = useModalNavigation();

	return (
		<TrackerCard
			tracker={{
				...tracker,
				connect: () => {
					modalNavigation.navigate('MARKETPLACE_DETAIL_SCREEN', {
						trackerSourceName: tracker.trackerSourceName,
					});
				},
				disconnect: () => {
					modalNavigation.navigate('MARKETPLACE_DETAIL_SCREEN', {
						trackerSourceName: tracker.trackerSourceName,
					});
				},
			}}
		/>
	);
}

function TrackerCard({ tracker }: { tracker: ITrackerDevice }) {
	const { t } = useTranslation();
	const trackerStyles = useConnectTrackerStyles();
	const modalNavigation = useModalNavigation();
	const { vr, vrTop } = useStyleHelpers();
	const remoteConfigData = useRemoteConfigData();
	const validicUserPlatformsQuery = useValidicUserPlatformsQuery();
	const selectedDevice = useSelectedDevice(tracker.trackerSourceName);
	const integrations =
		remoteConfigData.integratedTrackersOverview.platforms[tracker.trackerSourceName]
			?.integrations || [];
	const details =
		remoteConfigData.integratedTrackersOverview.platforms[tracker.trackerSourceName]?.details || '';
	const { mobeAlert } = useAlert();

	const buttonCaption = tracker.isConnected
		? t('connectHealthData.marketplace.disconnect')
		: t('connectHealthData.marketplace.connect');

	function handleButtonPress() {
		if (tracker.isConnected) {
			// Trigger alert before disconnecting a tracking device, to explain already-collected data will still be displayed
			mobeAlert('', t('connectHealthData.disconnectAlert.body'), [
				{
					style: 'destructive',
					text: t('connectHealthData.disconnectAlert.leave'),
					onPress: () => {
						if (Platform.OS === 'web') {
							handleWebDisconnect();
						} else {
							ConnectHealthDataAnalyticsEvents.disconnectHealthDataConfirm();
							tracker.disconnect();
						}
					},
				},
				{
					style: 'cancel',
					text: t('connectHealthData.disconnectAlert.stay'),
					onPress: () => ConnectHealthDataAnalyticsEvents.disconnectHealthDataCancel(),
				},
			]);
		} else {
			if (tracker.displayName === 'Google Fit') {
				modalNavigation.navigate('MARKETPLACE_DETAIL_SCREEN', {
					trackerSourceName: tracker.trackerSourceName,
				});
			} else {
				tracker.connect();
			}
		}
	}

	function handleWebDisconnect() {
		// Only marketplace trackers will be available on the web (no native trackers)
		const selectedMarketplaceTracker = selectedDevice.selectedMarketplaceTracker;

		if (!selectedMarketplaceTracker) {
			return;
		}

		const disconnectUrlWithRedirect =
			selectedMarketplaceTracker.disconnectUrl + `&redirect_uri=` + WEB_TAB_REDIRECT_URL;

		window.open(disconnectUrlWithRedirect, '_blank', 'noopener'); // noopener so that session storage doesn't persist into new window
		ConnectHealthDataAnalyticsEvents.disconnectHealthDataSuccess();
		validicUserPlatformsQuery.refetch();
	}

	return (
		<CardButton
			id={`${tracker.displayName}-tracker-card`}
			cardBodyStyle={trackerStyles.cardBody}
			cardButtonLeft={
				<View style={trackerStyles.deviceLogoContainer}>
					{tracker.logoSource && (
						<Image source={tracker.logoSource} style={trackerStyles.deviceLogo} />
					)}
				</View>
			}
			cardButtonRight={
				<View style={trackerStyles.connectionStatusContainer}>
					{tracker.isConnected && (
						<Row gutterSize={5} style={vr(2)}>
							<Row.Item>
								<Icon name="checkmark" color="successDark" size={10} />
							</Row.Item>
							<Row.Item>
								<Text color="successDark" weight="bold">
									{t('connectHealthData.marketplace.connected')}
								</Text>
							</Row.Item>
						</Row>
					)}
					<Button
						title={buttonCaption}
						aria-label={`${buttonCaption} ${tracker.displayName}`}
						size={'sm'}
						variant={tracker.isConnected ? 'secondary' : 'primary'}
						onPress={handleButtonPress}
					/>
				</View>
			}
			cardButtonFooter={
				tracker.isConnected &&
				Boolean(integrations.length || details) && (
					<View style={{ width: '100%', paddingBottom: 4 }}>
						<Text weight="semiBold" style={vr(1)}>
							{remoteConfigData.integratedTrackersOverview.heading}
						</Text>
						{integrations.map((integration) => (
							<BulletListItem key={integration}>
								<Text>{integration}</Text>
							</BulletListItem>
						))}
						{Boolean(details) && (
							<Text size="sm" style={vrTop(2)}>
								{details}
							</Text>
						)}
					</View>
				)
			}
			onPress={handleButtonPress}
		>
			<Text size="sm">{tracker.displayName}</Text>
		</CardButton>
	);
}
