import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAlert } from './useAlert';

export default function useGenericErrorAlert() {
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();

	function genericErrorAlert(onConfirm?: () => void) {
		mobeAlert(t('forms.genericServerErrorAlert.title'), t('forms.genericServerErrorAlert.body'), [
			{
				text: t('forms.genericServerErrorAlert.confirm'),
				onPress: () => {
					if (onConfirm) {
						onConfirm();
					} else {
						noop();
					}
				},
			},
		]);
	}

	return genericErrorAlert;
}
