import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import Button from '@mobe/components/button/Button';
import { GuideIntroGraphic } from '@mobe/components/graphics';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { GuideMatchIntroScreenNavigationProp } from '../AppointmentStackScreen';
import { IGuideMatchScreenParams } from './guideMatch/GuideMatchStackScreen';

export default function GuideIntroScreen({
	navigation,
}: {
	navigation: GuideMatchIntroScreenNavigationProp;
}) {
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const permissionsQuery = usePermissionsQuery();

	const guideMatchScreenParams: IGuideMatchScreenParams = {
		shouldShowLanguage: permissionsQuery.data?.canSelectGsmOrMskLanguage || false,
		shouldShowGender: permissionsQuery.data?.canSelectGsmOrMskGender || false,
	};

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			centered
			footer={
				<Button
					title={t('appointments.guideMatch.introView.button')}
					onPress={() => navigation.replace('APPT_GUIDE_MATCH_SCREEN', guideMatchScreenParams)}
					disabled={permissionsQuery.isLoading}
				/>
			}
		>
			<View style={[vr(8), constrain(250)]}>
				<GuideIntroGraphic />
			</View>
			<View style={constrainText(350)}>
				<Text accessibilityAutoFocus align="center" color="light">
					{t('appointments.guideMatch.introView.description')}
				</Text>
			</View>
		</ScreenTemplateWithFooter>
	);
}
