import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import useTransitions from '@mobe/utils/useTransitions';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ApptFollowUpStackScreenNavigationProp,
	ApptFollowUpStackScreenRouteProp,
} from '../../AppointmentStackScreen';
import { GuideTypeValue } from '../../useGuideTypeOptions';
import { FollowUpProvider } from './FollowUpProvider';
import FollowUpContactScreen from './screens/FollowUpContactScreen';
import FollowUpScheduleScreen from './screens/FollowUpScheduleScreen';

export interface IFollowUpStackScreenParams {
	coachType: CoachType;
	allowCoachToggle?: boolean;
}

export type FollowUpStackParamList = {
	FOLLOW_UP_SCHEDULE_SCREEN: undefined;
	FOLLOW_UP_CONTACT_SCREEN: undefined;
};

export type FollowUpScheduleScreenNavigationProp = StackNavigationProp<
	FollowUpStackParamList,
	'FOLLOW_UP_SCHEDULE_SCREEN'
>;
export type FollowUpScheduleScreenRouteProp = RouteProp<
	FollowUpStackParamList,
	'FOLLOW_UP_SCHEDULE_SCREEN'
>;

export type FollowUpContactScreenNavigationProp = StackNavigationProp<
	FollowUpStackParamList,
	'FOLLOW_UP_CONTACT_SCREEN'
>;
export type FollowUpContactScreenRouteProp = RouteProp<
	FollowUpStackParamList,
	'FOLLOW_UP_CONTACT_SCREEN'
>;

interface IFollowUpStackScreenProps {
	navigation: ApptFollowUpStackScreenNavigationProp;
	route: ApptFollowUpStackScreenRouteProp;
}

const FollowUpStack = createStackNavigator<FollowUpStackParamList>();

export default function FollowUpStackScreen({ route }: IFollowUpStackScreenProps) {
	const { t } = useTranslation();
	const transitions = useTransitions();
	const guide = useGuideQuery().data;
	const pharmacist = usePharmacistQuery().data;

	// This screen defaults to pharmacist so that it can be used as the initial pharmacist scheduling
	let initialGuide = null;
	let initialGuideType = GuideTypeValue.Pharmacist;

	// Treat all other coachTypes that are passed to this screen as a guide type
	if (route.params.coachType && route.params.coachType !== CoachType.Pharmacist && guide) {
		initialGuide = guide;
		initialGuideType = GuideTypeValue.Guide;
	}

	if (route.params.coachType === CoachType.Pharmacist && pharmacist) {
		initialGuide = pharmacist;
	}

	return (
		<FollowUpProvider
			initialGuideType={initialGuideType}
			initialGuide={initialGuide}
			allowCoachToggle={route.params.allowCoachToggle ?? true}
		>
			<FollowUpStack.Navigator
				initialRouteName="FOLLOW_UP_SCHEDULE_SCREEN"
				screenOptions={{
					gestureEnabled: false,
					headerTitleAllowFontScaling: false,
					headerShown: false,
					...transitions.SlideFromRight,
				}}
			>
				<FollowUpStack.Screen
					name="FOLLOW_UP_SCHEDULE_SCREEN"
					component={FollowUpScheduleScreen}
					options={{ title: t('appointments.followUp.screenTitle') }}
				/>
				<FollowUpStack.Screen
					name="FOLLOW_UP_CONTACT_SCREEN"
					component={FollowUpContactScreen}
					options={{ title: t('appointments.followUp.screenTitle') }}
				/>
			</FollowUpStack.Navigator>
		</FollowUpProvider>
	);
}
