import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import IntegratedDevicesGraphic from './IntegratedDevicesGraphic';

export default function IntegratedDevicesGraphicWithBackground() {
	const styleRules = useStyleRules();
	return (
		<View
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: styleRules.colors.strokeLight,
				aspectRatio: 1,
				width: '100%',
				borderRadius: 500, // arbitrarily large number to ensure circle regardless of width
			}}
		>
			<View style={{ width: '105%', marginLeft: -25, marginTop: -20 }}>
				<IntegratedDevicesGraphic />
			</View>
		</View>
	);
}
