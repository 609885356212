import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import {
	InteractionManager,
	KeyboardAvoidingView,
	Platform,
	StyleProp,
	View,
	ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export interface IScreenTemplateWithFooterProps {
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
	bottomPadding?: number;
}

export default function AutoKeyboardAvoidingView({
	children,
	style,
	bottomPadding = 12,
}: IScreenTemplateWithFooterProps) {
	const safeAreaInsets = useSafeAreaInsets();
	const [keyboardOffset, setKeyboardOffset] = React.useState(0);

	// Difference between minimum bottom padding for footer and the bottom safe inset
	const insetMinFooterPadDifference = Math.max(safeAreaInsets.bottom - bottomPadding, 0);

	// The only purpose of this view is to acquire the KeyboardAvoidingView's offset from the top of the screen.
	// The measure() callback does not exist on KeyboardAvoidingView, and thus is handled with a native view.
	// UseHeaderHeight() is not used since it does not work predictably with nested navigators.
	const offsetAcquiringView = React.useRef<View>(null);

	// Defer offset measurement until after screen transitions have occurred. This is critical for modals.
	useFocusEffect(
		React.useCallback(() => {
			const task = InteractionManager.runAfterInteractions(() => {
				if (offsetAcquiringView) {
					offsetAcquiringView.current?.measure((x, y, width, height, pageX, pageY) =>
						setKeyboardOffset(Math.max(pageY - insetMinFooterPadDifference, 0))
					);
				}
			});

			return () => task.cancel();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
			style={[{ flex: 1 }, style]}
			keyboardVerticalOffset={keyboardOffset}
		>
			{/* collapsable property fixes measurement bug in Android https://github.com/facebook/react-native/issues/3282 */}
			<View ref={offsetAcquiringView} collapsable={false}></View>
			{children}
		</KeyboardAvoidingView>
	);
}
