import * as React from 'react';

export default function useButtonActive(disabled = false) {
	const [active, setActive] = React.useState(false);

	function handlePressIn() {
		if (disabled) {
			return;
		}

		setActive(true);
	}

	function handlePressOut() {
		if (disabled) {
			return;
		}

		setActive(false);
	}

	return { active, handlePressIn, handlePressOut };
}
