export interface IMetadataPagination {
	total: number;
	take: number;
	skip: number;
}

export interface IPagination {
	total: number;
	limit: number;
	page: number;
}

export interface IMetadataPaginationRequest {
	take?: number;
	skip?: number;
}

export interface IPaginationRequest {
	limit?: number;
	page?: number;
}

export function metadataToPagination(metadata: IMetadataPagination): IPagination {
	return {
		total: metadata.total,
		limit: metadata.take,
		page: metadata.skip / metadata.take + 1,
	};
}

export function paginationToMetadataRequest(
	pagination: IPaginationRequest
): IMetadataPaginationRequest {
	return {
		take: pagination.limit,
		skip:
			pagination.page !== undefined && pagination.limit !== undefined
				? (pagination.page - 1) * pagination.limit
				: undefined,
	};
}
