import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Heading from '@mobe/components/heading/Heading';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, useWindowDimensions, View, ViewStyle } from 'react-native';
import useHomeScreenLocalTime from '../useHomeScreenLocalTime';
import HeroGraphic from './heroGraphic/HeroGraphic';

export default function Hero({
	style,
	accessibilityAutoFocus,
}: {
	style?: StyleProp<ViewStyle>;
	accessibilityAutoFocus?: boolean;
}) {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const styleRules = useStyleRules();
	const { isLargeDisplay } = useLayout();
	const profileQuery = useProfileQuery();
	const persistentState = usePersistentState();
	const preparedName = profileQuery.data?.preferredName
		? profileQuery.data.preferredName
		: profileQuery.data?.firstName
		? profileQuery.data.firstName
		: '';
	const timeOfDayForGraphic = useHomeScreenLocalTime().localTimeForGraphic();
	const localTimeGreeting = useHomeScreenLocalTime().localTimeText(preparedName);
	const styles = useStyles();
	const welcomeHeading = persistentState.hasSeenWelcomeVideo
		? localTimeGreeting
		: t('home.welcome', { name: preparedName });

	return (
		<View style={[style, styles.hero]}>
			<View style={styles.background}>
				<HeroGraphic timeOfDay={timeOfDayForGraphic} />
			</View>
			<View style={styles.heroInner}>
				<DeferredLoadingIndicator deferDuration={1000} isLoading={profileQuery.isLoading}>
					<View style={styles.welcomeContainer}>
						<Heading
							level={isLargeDisplay ? 'h1' : 'h2'}
							accessibilityAutoFocus={accessibilityAutoFocus}
							style={[
								vr(2),
								{
									color:
										timeOfDayForGraphic === 'evening'
											? styleRules.colors.staticLight
											: styleRules.colors.staticDark,
								},
							]}
							align="center"
						>
							{welcomeHeading}
						</Heading>
					</View>
				</DeferredLoadingIndicator>
			</View>
		</View>
	);
}

function useStyles() {
	const { isLargeDisplay } = useLayout();
	const windowDimensions = useWindowDimensions();

	return StyleSheet.create({
		welcomeContainer: {
			justifyContent: 'center',
			minHeight: 100,

			...(isLargeDisplay && {
				minHeight: 200,
			}),
		},
		hero: {
			position: 'relative',
			overflow: 'hidden',

			...(isLargeDisplay && {
				minHeight: Math.min(300, windowDimensions.height / 3),
			}),
		},
		background: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			maxHeight: 380,
			overflow: 'hidden',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		heroInner: {
			paddingTop: 20,
			paddingBottom: 20,
			paddingHorizontal: 30,
			alignContent: 'center',
		},
	});
}
