import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestEmotionalGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(44, 48);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="#1A7EAD" fillRule="nonzero">
					<Path d="M8.954 47.489c.212.277.525.46.87.507.066.005.133.005.199 0a1.348 1.348 0 001.325-1.139l2.234-17.048a1.362 1.362 0 00-.427-1.173 12.787 12.787 0 01-1.626-1.782 1.341 1.341 0 00-1.881-.245 1.345 1.345 0 00-.245 1.879c.444.58.925 1.132 1.439 1.651L8.692 46.5c-.046.351.048.707.262.99zM37.498 29.911v7.22a1.53 1.53 0 01-1.529 1.532H28.01a.653.653 0 00-.261.063c-3.411-.063-4.236-1.293-4.241-1.293a1.338 1.338 0 10-2.49.985c.096.257 1.068 2.472 5.486 2.904l-.614 5.125c-.081.72.425 1.374 1.142 1.475a.75.75 0 00.16 0 1.342 1.342 0 001.33-1.14l.648-5.408h6.822a4.216 4.216 0 004.207-4.214v-6.155l1.643-.348A2.844 2.844 0 0043.865 29a2.35 2.35 0 00-.3-2.146c-2.15-3.32-3.412-6.065-3.412-7.522v-.507c0-.421.04-.774.04-1.105A17.346 17.346 0 0029.96 1.914a1.34 1.34 0 00-1.092 2.448 14.658 14.658 0 018.67 13.341c0 .28 0 .57-.04 1.02v.569c0 2.471 1.939 6.047 3.661 8.74l-2.598.57a1.349 1.349 0 00-1.063 1.31z" />
					<Path d="M13.593 2.124l-.392.399-.392-.387a7.654 7.654 0 00-10.609 0 7.51 7.51 0 000 10.625L12.9 23.5a.392.392 0 00.568 0l10.739-10.75a7.53 7.53 0 000-10.626 7.694 7.694 0 00-10.614 0zm8.897 8.906l-9.29 9.304-9.283-9.293a5.087 5.087 0 010-7.186 5.067 5.067 0 017.175 0l1.808 1.84a.398.398 0 00.568 0L15.31 3.85a5.196 5.196 0 017.169 0 5.096 5.096 0 01.011 7.18z" />
				</G>
			</Svg>
		</View>
	);
}
