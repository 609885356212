import { noop } from 'lodash';
import { Image } from 'react-native';
import { FastImageProps as FIProps, Source } from 'react-native-fast-image';

export type FastImageProps = FIProps;
export type FastImageSource = Source;
const FastImage = Image as any;

FastImage.resizeMode = '';
FastImage.cacheControl = '';
FastImage.priority = '';
FastImage.preload = (sources: any) => noop(sources);
FastImage.clearMemoryCache = noop;
FastImage.clearDiskCache = noop;

export default FastImage;
