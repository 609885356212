import { IAppointment } from '@mobe/api/appointments/appointmentApiHooks';
import { ICoach } from '@mobe/api/guides/guidesApi';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { GuideApptCard } from '../../appointments/components/GuideApptCard';
import { HomeScreenNavigationProp } from '../../navigation/bottomTabs/types';
import { GuideScheduleCard } from './GuideScheduleCard';

interface IAppointmentCardProps {
	guide?: ICoach;
	appointment?: IAppointment;
	style?: StyleProp<ViewStyle>;
}

export default function AppointmentCard({ guide, appointment, style }: IAppointmentCardProps) {
	const navigation = useNavigation<HomeScreenNavigationProp>();

	// Render appointment detail card if appointment exists
	// It's possible for this appointment to be with a non-assigned guide
	if (appointment) {
		return (
			<GuideApptCard
				appointment={appointment}
				onPress={() =>
					navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
						confirmationId: appointment.confirmationId,
					})
				}
				style={style}
			/>
		);
	}

	// Render schedule appointment card if there is no schedule appt and guide is assigned
	if (guide) {
		return <GuideScheduleCard guide={guide} style={style} />;
	}

	// Render nothing if there is no guide and no appt
	return null;
}
