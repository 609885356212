import {
	useActionStepDetailQuery,
	useOptimisticallyUpdateActionStep,
	useSetSubStepOptions,
	useSetSubStepStatusMutation,
} from '@mobe/api/goals/goalsApiHooks';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Row from '@mobe/components/layout/Row';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { max } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	ActionStepsDetailScreenNavigationProp,
	ActionStepsDetailScreenRouteProp,
} from '../../navigation/modal/types';
import * as PlanAnalyticsEvents from '../analyticsEvents';
import CompletionButton from '../components/CompletionButton';
import PathwayIcon from '../components/PathwayIcon';
import SubStepCount from '../components/SubStepCount';
import TargetDate from '../components/TargetDate';
import TrackerModule from '../components/TrackerModule';
import PathwayBlobGraphic from '../components/graphics/PathwayBlobGraphic';

export const ACTION_STEP_DETAIL_ID_PARAM_KEY = 'actionStepId';

export interface IActionStepDetailScreenParams {
	[ACTION_STEP_DETAIL_ID_PARAM_KEY]: number;
}

interface IActionStepDetailScreenProps {
	navigation: ActionStepsDetailScreenNavigationProp;
	route: ActionStepsDetailScreenRouteProp;
}

export default function ActionStepDetailScreen({
	route,
	navigation,
}: IActionStepDetailScreenProps) {
	const styleRules = useStyleRules();
	const styles = useStyles();
	const { t } = useTranslation();
	const { vrTop, wrapper } = useStyleHelpers();
	const setSubStepStatusMutation = useSetSubStepStatusMutation();
	const optimisticallyUpdateActionStep = useOptimisticallyUpdateActionStep();
	const setSubStepOptions = useSetSubStepOptions();
	const actionStepDetailQuery = useActionStepDetailQuery(
		route.params[ACTION_STEP_DETAIL_ID_PARAM_KEY]
	);
	const actionStep = actionStepDetailQuery.data;

	function handleCompleteActionPress() {
		if (!actionStep) return;

		optimisticallyUpdateActionStep({
			id: actionStep.id,
			subStepsComplete: actionStep.subStepsComplete + 1,
		});

		setSubStepStatusMutation.mutate(
			{ actionStepId: actionStep.id, status: 'Completed' },
			setSubStepOptions({
				actionStep,
				onSuccess: PlanAnalyticsEvents.actionStepCompletePressDetail,
				onLastStepSuccess: navigation.pop,
			})
		);
	}

	function handleAddEntryPress() {
		if (!actionStep?.trackerAbbreviation) return;
		navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: actionStep?.trackerAbbreviation,
			displaySuccessToast: true,
		});
	}

	function handleViewDetailPress() {
		if (!actionStep?.trackerAbbreviation) return;
		navigation.navigate('TRACK_DETAIL_SCREEN', {
			trackerAbbreviation: actionStep?.trackerAbbreviation,
		});
	}

	return (
		<ScreenTemplateWithFooter
			noPadding
			fullWidth
			scrollViewBackgroundColor={styleRules.colors.background}
			footer={
				<DeferredLoadingIndicator isLoading={actionStepDetailQuery.isLoading} deferDuration={5000}>
					{actionStep && <TargetDate date={new Date(actionStep.endDate)} />}
				</DeferredLoadingIndicator>
			}
			footerStyle={styles.footer}
		>
			<DeferredLoadingIndicator isLoading={actionStepDetailQuery.isLoading}>
				{actionStep && (
					<>
						<View style={styles.hero}>
							<View style={styles.heroBody}>
								{actionStep.pathwayName !== null && (
									<View style={styles.pathwayContainer}>
										<View style={styles.pathwayContainerGraphic}>
											<PathwayBlobGraphic />
										</View>
										<PathwayIcon
											style={styles.pathwayIcon}
											size={30}
											color="primary"
											pathway={actionStep.pathwayName}
										/>
									</View>
								)}
								<Text
									accessibilityAutoFocus
									size="lg"
									weight="medium"
									align="center"
									role="heading"
									aria-level="2"
								>
									{actionStep?.title}
								</Text>
								{actionStep.subStepsTotal > 1 && (
									<SubStepCount
										totalCount={actionStep.subStepsTotal}
										completedCount={actionStep.subStepsComplete}
										style={vrTop(4)}
									/>
								)}
							</View>
						</View>
						{actionStep.status === 'InProgress' && (
							<View style={styles.completeButtonWrapper}>
								<CompletionButton
									onPressAnimationComplete={handleCompleteActionPress}
									style={styles.completeButton}
								>
									{(renderCheckmark) => (
										<Row>
											<Row.Item>{renderCheckmark()}</Row.Item>
											<Row.Item>
												<Text weight="semiBold">
													{actionStep.subStepsComplete + 1 < actionStep.subStepsTotal
														? t('plan.actionStepDetail.completeSubStepButton', {
																count: actionStep.subStepsComplete + 1,
																total: actionStep.subStepsTotal,
														  })
														: t('plan.actionStepDetail.completeButton')}
												</Text>
											</Row.Item>
										</Row>
									)}
								</CompletionButton>
							</View>
						)}
						{actionStep.trackerAbbreviation && (
							<View style={wrapper}>
								<TrackerModule
									trackerAbbreviation={actionStep.trackerAbbreviation}
									startDate={new Date(actionStep.startDate)}
									endDate={max([new Date(actionStep.endDate), new Date(Date.now())])}
									onAddEntryPress={handleAddEntryPress}
									onViewDetailPress={handleViewDetailPress}
								/>
							</View>
						)}
					</>
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { isLargeDisplay } = useLayout();

	return StyleSheet.create({
		hero: {
			alignItems: 'center',
			justifyContent: 'center',
			paddingTop: 15,
			paddingBottom: 20,
			borderBottomWidth: 1,
			borderColor: rules.colors.strokeLight,
			backgroundColor: rules.colors.cardBackground,
		},
		heroBody: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			maxWidth: rules.spacing.maxWidth,
		},
		pathwayContainer: {
			alignSelf: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			width: 54,
			height: 54,
			marginBottom: 15,
		},
		pathwayContainerGraphic: {
			position: 'absolute',
		},
		pathwayIcon: {
			zIndex: 1,
		},
		completeButtonWrapper: {
			flexDirection: 'row',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			borderBottomWidth: 1,
			borderColor: rules.colors.strokeLight,
			backgroundColor: rules.colors.cardBackground,
		},
		completeButton: {
			flexGrow: isLargeDisplay ? 0 : 1,
			paddingVertical: 16,
		},
		footer: {
			backgroundColor: rules.colors.background,
			borderTopWidth: 0,
		},
	});
}

export function useActionStepDetailScreenOptions() {
	const { t } = useTranslation();
	const subScreenStyleProps = useSubScreenStyleProps();

	return () => ({
		title: t('plan.actionStepDetail.screenTitle'),
		headerRight: () => <ChatButton />,
		...subScreenStyleProps,
	});
}
