import { useClientProgramReferralsQuery } from '@mobe/api/client-program-referrals/clientProgramReferralsApiHooks';
import { ClientProgramReferralsResponse } from '@mobe/api/client-program-referrals/clientProgramReferralsService';
import CardButton from '@mobe/components/cardButton/CardButton';
import Icon from '@mobe/components/icon/Icon';
import VrArray from '@mobe/components/layout/VrArray';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';

type ClientProgramReferralModuleProps = {
	onProgramPress: (programId: number) => void;
};

export default function ClientProgramReferralsModule({
	onProgramPress,
}: ClientProgramReferralModuleProps) {
	const clientProgramReferralsQuery = useClientProgramReferralsQuery();
	const { vr, wrapperHorizontal } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = useStyles();

	function renderProgramCards(data: ClientProgramReferralsResponse) {
		return data.map((program) => (
			<CardButton
				key={program.id}
				onPress={() => onProgramPress(program.id)}
				cardBodyStyle={styles.buttonBody}
				style={{ padding: 0 }}
				cardButtonRight={
					<View style={styles.buttonRight}>
						<Icon size={15} name="right"></Icon>
					</View>
				}
			>
				<View style={styles.buttonBodyText}>
					<Text weight="medium">{program.programName}</Text>
				</View>
			</CardButton>
		));
	}

	return clientProgramReferralsQuery.isSuccess ? (
		<View>
			<View style={[vr(6), wrapperHorizontal]}>
				<ModuleHeading title={t('explore.clientProgramReferralsHeading')} style={vr(5)} />
				<VrArray increment={3}>{renderProgramCards(clientProgramReferralsQuery.data)}</VrArray>
			</View>
		</View>
	) : null;
}

function useStyles() {
	const styleRules = useStyleRules();
	const layout = useLayout();

	return StyleSheet.create({
		buttonBody: {
			paddingVertical: 0,
			paddingRight: 0,
			paddingLeft: 16,
			minHeight: 60,
		},
		buttonBodyText: {
			paddingVertical: 12,

			...(Platform.OS === 'web' && {
				paddingVertical: '3.5%',
				paddingHorizontal: '3.5%',
			}),
		},
		buttonRight: {
			backgroundColor: styleRules.colors.backgroundPrimary,
			borderRadius: styleRules.borderRadius,
			flexGrow: 1,
			alignItems: 'center',
			justifyContent: 'center',
			width: layout.isLargeDisplay ? 80 : 66,
			paddingLeft: 8,
		},
	});
}
