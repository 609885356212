import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G>
					<Path
						fill="#543B39"
						d="M40.848 15.301c.139-.442-.534-1.308-.695-1.728-.519-1.36.21-2.934-.185-3.866a2.669 2.669 0 00-1.8-1.525c-1.159-.328-3.489.372-3.7 1.866-.219 1.568 1.087 1.849 1.714 2.914.735 1.25-.506 2.13-1.652 3.166a5.49 5.49 0 00-1.959 3.643 4.207 4.207 0 001.572 3.641c.666.5 2.564 1.494 3.729.577.573-.451 0-2.175.707-2.486 2.124-.939 2.55-1.928 2.286-3.552a5.286 5.286 0 01-.017-2.65z"
					/>
					<Path
						fill="#F7A882"
						d="M49.601 26.59c-.067-.557-1.224-1.584-1.6-2-.589-.643-1.282-1.761-2.067-2.615-.891-.969-1.7-1.406-1.176-2.892.308-.867.677-1.043 1.189-1.116a1.46 1.46 0 001.368-.975c.2-.381.36-.79.557-1.157a.792.792 0 01.413-.418.462.462 0 00.285-.64 2.332 2.332 0 01-.3-1.394c.216-1.229.744-2.692-.065-3.716-.306-.388-2.058-.136-3.075.708-.9.748-1.318 1.528-2.726 1.489-1.15-.031-2.388-.434-1.954 1.426.091.392.744 1.132.813 1.554a10.91 10.91 0 01-.214 3.308c-.371 1.661-1.928 1.537-3.11 2.322-1.059.7-1.358 2.632-1.1 4a6.638 6.638 0 001.392 3.266c.715.628 1.819.211 2.636-.067a11.594 11.594 0 012.847-.706c.59-.042 6.145.539 5.887-.377zM63.633 73.949c-.16-1.108-1.492-.8-2.214-1.557-.9-.95-.9-2.664-1.191-3.869-.141-.578-.115-1.308-.835-1.207-.523.074-1 .541-1.531.667-.878.208-2.016-.029-1.244 1.211.709 1.139 1.42 2.25 2.087 3.413.51.889.8 1.79 1.247 2.7a1.556 1.556 0 001.938.983 2.16 2.16 0 001.743-2.341z"
					/>
					<Path
						fill="#E75C61"
						d="M68.514 75.421c-.045-.181-.229-.338-.638-.43a15.675 15.675 0 01-2.4-.465 4.75 4.75 0 01-1.465-1.1c-.3-.313-.833-1.109-1.265-1.212-.352-.084-.6.27-.718.57-.1.273-.113.975-.286 1.411a1.242 1.242 0 01-1.5.692c-.5-.172-.581-.793-.9-.925-.613-.255-.724 1.984-.5 2.749a1.149 1.149 0 00-.066.294c-.1.922.905.958 1.619.945.563-.01 1.107-.16 1.672-.146.51.013 1.009.15 1.518.179a8.836 8.836 0 003.183-.37 2.436 2.436 0 001.746-2.192z"
					/>
					<Path
						fill="#4D2A61"
						d="M60.169 67.501c-.447-1.641-.61-3.479-1.119-5.1a30.967 30.967 0 01-1.022-3.762c-.037-.2-.071-.4-.1-.614a5.893 5.893 0 00-.59-2.086 6.048 6.048 0 00-1.385-1.592 13.97 13.97 0 01-2.8-3.642c-1.765-3.369-3.617-7.415-6.6-9.973-.319-.274-.7.124-.739 1.138a11.867 11.867 0 01-1.9 5.634c-.583.787-1.548 2.375-.687 2.992 1.174.841 2.322 2.739 4.081 3.975.913.641 3.8 2.574 4.792 3.087a3.778 3.778 0 011.611 1.463l.006.006c.243.442.382.935.405 1.439-.025 1.66.149 3.316.517 4.935.3 1.02 1.145 3.446 2.336 3.582a4.46 4.46 0 001.935-.211c.4-.142 1.413-.706 1.259-1.271z"
					/>
					<Path
						fill="#E97F59"
						d="M38.958 29.529a11.322 11.322 0 00-.885-2.657 10.264 10.264 0 01-.411-1.6c-.255-1.622-1.257.626-1.6 1.147a41.766 41.766 0 00-3.231 5.117c-.456 1.014-.158 1.287.384 2.486.366.915.787 1.806 1.263 2.669.485.8 1.113 1.706 1.517 2.551.229.48.339 1.3.89 1.223a.917.917 0 00.647-1.093 39.122 39.122 0 00-1.16-5.164c-.272-.8-.593-1.331-.3-1.835.501-.857 3.011-1.635 2.886-2.844z"
					/>
					<Path
						fill="#F7A882"
						d="M26.289 62.397a2.324 2.324 0 00-.3-.945c-.271-.387-.816-.653-.924-1.121-.22-.948-.378-1.124-1.248-.7-1.055.514-1.785 1.037-2.845 1.528-.9.416-2.057.089-2.687.338-.667.264-.633 1.251-.45 2.251.119.653.265 2.312 1.37 2.108.584-.108 1.263-1.573 2.2-1.979 1.278-.559 4.97-1.02 4.884-1.48z"
					/>
					<Path
						fill="#E34347"
						d="M21.011 64.888c-.309-.279-1.069-.293-1.425-.567a1.685 1.685 0 01-.668-1.536c.115-.759 1.392-1.506.2-2.033a3.698 3.698 0 00-2.2-.233 1.013 1.013 0 00-.755-.2c-.371.06-.562.737-.61 1.011a23.3 23.3 0 00.106 3.935 15.226 15.226 0 00-.1 3.085c.134.682.717 2.3 1.588 2.442.277.044.395 0 .415-.091a.74.74 0 00.27-.21c.365-.419.278-1.095.364-1.592.085-.512.29-.996.6-1.413a5.006 5.006 0 011.458-1.307c.577-.339 1.402-.709.757-1.291z"
					/>
					<Path
						fill="#6F3F8B"
						d="M46.332 39.59c-2.313-2.2-7.393-3.134-9.839-.655a6.74 6.74 0 00-1.978 4.9 6.577 6.577 0 001.62 3.285 13.272 13.272 0 01-.381 2.385c-.209 1.922-.2 4.081-.985 5.879-.833 1.9-2.14.81-4.809 1.307a16.539 16.539 0 00-3.727 1.434c-.576.305-1.094.7-1.68 1.057-.739.447.047 2.062.357 2.6.391.684.682 1.021 1.465.837 1.787-.42 3.749-1.2 5.665-1.561 1.723-.327 3.989.07 5.187-.713.68-.445.969-1.319 1.449-1.945.651-.848 1.474-1.787 2.2-2.578a20.811 20.811 0 003.258-5.164c.3-.876.435-2.373.769-3.24.355-.922 1.046-2 1.387-2.939.465-1.27 1.165-3.819.042-4.889z"
					/>
					<Path
						fill="#E7595F"
						d="M49.59 26.439a3.128 3.128 0 00-.644-.925c-.16-.069.066.407-.558.492a5.109 5.109 0 01-2.661-.453 4.738 4.738 0 01-2.053-1.82c-.587-1.1-2.021-4.59-4.885-3.808-.278.076-.231.218.052.16 2.337-.482 3.308 1.7 3.858 3.4.167.497.125 1.04-.115 1.505a4.187 4.187 0 01-2.069 1.556c-.841.276-2.248.069-2.312.354.078.428.216.843.41 1.233.27.655.467 1.338.585 2.037a9.371 9.371 0 01-1.443 5.615c-.371.71-1.43 2.706-.489 2.676a7.778 7.778 0 013.129.374c1.166.374 4.693 2.057 6.084 2.4.644.158.787.239.841-.981.031-.695-.177-1.944-.19-2.591a14.44 14.44 0 01.541-4.52c.469-2.066.993-2.272 1.925-3.378a2.977 2.977 0 00-.006-3.326z"
					/>
					<Path
						fill="#E97F59"
						d="M47.164 17.265a1.41 1.41 0 01-1.219.7 1.16 1.16 0 00-.937.571 2.188 2.188 0 01-.472-.11 4.099 4.099 0 01-1.989-2.4c-.013-.037-.06-.187-.073-.227-.075-.267.115-.311.262-.077.033.049.063.1.09.152.858 1.455 2.889 2.406 4.183 1.5.045-.034.111-.084.155-.109z"
					/>
					<Path
						fill="#E34347"
						d="M49.789 29.493a2.081 2.081 0 01-.192.272c-.932 1.107-1.456 1.311-1.925 3.378a14.47 14.47 0 00-.542 4.52c0 .246.032.583.063.94-.548-.3-.808-2.188-.844-2.528a16.071 16.071 0 00-.428-3.509c-.3-.754-.91-.2-1.532-.351a2.214 2.214 0 01-1.283-1.319c-.28-.578-1.053-2.462-.606-3.078s1.241 1.08 1.875 1.475c1.339.832 3.942.688 5.414.2z"
					/>
					<Path
						fill="#F7A882"
						d="M56.253 29.247c-.946-.97-1.449-1.236-2.73-.574-.572.3-1.046.542-1.254 1.158-.16.474-.144 1-.472 1.327a6.9 6.9 0 01-2.008 1.131c-.693.288-1.41.51-2.144.665a10.037 10.037 0 00-3.7 1.188l-.111.077c0-.024-.01-.049-.014-.073-.232-1.364.362-3.651-.273-5.411a7.884 7.884 0 01-.81-2.515c.076-.81.094-1.625.051-2.437a4.667 4.667 0 00-2.483-3.471c-.67-.279-2.268-.459-2.95.77-1.6 2.889.857 7.411 2.383 10.419.864 1.7.614 3.294 1.527 5.329.18.38.411.735.686 1.053a.74.74 0 00.456.3 7.483 7.483 0 002.938-.652 23.519 23.519 0 005.807-3.6 5.393 5.393 0 012.25-1.29c1.264-.273 1.566-1.034 1.712-2.295.44.071.973.529 1.368.051.283-.335.021-.891-.229-1.15z"
					/>
					<Path
						fill="#FFF"
						d="M47.888 26.27a5.041 5.041 0 01-3.2-1.15 4.931 4.931 0 01-.516-.506c-.2-.23-.553-.73-.279-.573.312.2.609.424.887.669.369.276.769.509 1.191.694a5.05 5.05 0 001.867.336c.274-.01.541-.087.779-.223.127-.106.033-.332.168-.281.257.1.412.283.373.423-.152.556-.779.618-1.27.611zM47.365 41.63a.216.216 0 01-.062-.009 37.927 37.927 0 01-4.816-1.747 12.157 12.157 0 00-4.731-1.293c-.349 0-.697.029-1.041.089a.28.28 0 01-.041 0 .23.23 0 01-.224-.214.187.187 0 01.161-.208 6.665 6.665 0 011.137-.1 11.01 11.01 0 014.133.995l.225.094a46.182 46.182 0 005.311 1.963.206.206 0 01.151.233.213.213 0 01-.205.191l.002.006z"
					/>
					<Path
						fill="#352726"
						d="M48.676 9.683a4.202 4.202 0 00-1.735-2.058c-2.124-1.435-6.4-1.553-7.771.633a5.362 5.362 0 00-.307 4.3c.213.59 2.108 3.333 2.416 3.261.621-.145.267-.784.013-1.441 0-.012-.366-.8-.116-1.24a.7.7 0 01.772-.362c.346.065.653.261.856.548.834.842 2.159.236 2.839-.675a5.58 5.58 0 011.775-1.715c1.021-.515 1.436-.499 1.258-1.251z"
					/>
					<Path
						fill="#FFF"
						d="M44.1 6.639c-.676-.645-1.637-1.01-2.3-.065a4.24 4.24 0 00-.493 2.244 22.771 22.771 0 00.161 3.343c.029.255.411.165.382-.089a21.584 21.584 0 01-.146-3.4 3.82 3.82 0 01.317-1.643.951.951 0 011.376-.447c.232.013.427.03.703.057z"
					/>
					<Path fill="#EAE9E8" d="M42.231 11.891h-.456v3.424h.456a1.712 1.712 0 000-3.424z" />
					<Circle fill="#FFF" cx={41.776} cy={13.602} r={1.712} />
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
