import { noop } from 'lodash';
import * as React from 'react';
import { Animated, Easing, LayoutChangeEvent, ViewProps } from 'react-native';

/**
 * useDismissible provides direct access to the underlying logic of <Dismissible> which allows
 * for more control by the parent component. To be used in conjunction with an <Animated.View>.
 */
export default function useDismissible({
	onAnimationComplete = noop,
}: {
	onAnimationComplete?: () => void;
}) {
	const fadeOutAnimation = React.useRef(new Animated.Value(0)).current;
	const slideUpAnimation = React.useRef(new Animated.Value(0)).current;
	const [childrenHeight, setChildrenHeight] = React.useState(1000);
	const [isDismissed, setIsDismissed] = React.useState(false);

	React.useEffect(() => {
		if (isDismissed) {
			Animated.sequence([
				Animated.timing(fadeOutAnimation, {
					useNativeDriver: false,
					toValue: 1,
					duration: 250,
					easing: Easing.ease,
				}),
				Animated.delay(100),
				Animated.timing(slideUpAnimation, {
					useNativeDriver: false,
					toValue: 1,
					duration: 500,
					easing: Easing.ease,
				}),
			]).start(onAnimationComplete);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDismissed]);

	const opacity = fadeOutAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 0],
	});

	const scale = fadeOutAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 0.95],
	});

	const maxHeight = slideUpAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [childrenHeight, 0],
	});

	function handleOnLayout(event: LayoutChangeEvent) {
		const height = event.nativeEvent.layout.height;

		// Ignore layout height changes if the value is zero.
		// OnLayout fires on web on screen change and returns zero.
		if (height > 0) {
			setChildrenHeight(event.nativeEvent.layout.height);
		}
	}

	function dismiss() {
		setIsDismissed(true);
	}

	const animatedViewProps: Animated.AnimatedProps<ViewProps> = {
		style: { maxHeight, opacity, transform: [{ scale }] },
		onLayout: handleOnLayout,
	};

	return { animatedViewProps, dismiss };
}
