import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useLayout from '@mobe/utils/styles/useLayout';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import {
	StackNavigationOptions,
	StackNavigationProp,
	createStackNavigator,
} from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatStackScreenNavigationProp, ChatStackScreenRouteProp } from '../navigation/modal/types';
import ChatDirectMessageScreen, {
	useChatDirectMessageScreenOptions,
} from './screens/ChatDirectMessageScreen';
import ChatRootScreen, { useChatRootScreenOptions } from './screens/ChatRootScreen';

export interface IChatScreenParams {
	channelId: number;
}

export type ChatStackParamList = {
	CHAT_ROOT_SCREEN: undefined;
	CHAT_DIRECT_MESSAGE_SCREEN: IChatScreenParams;
};

export type ChatRootScreenRouteProp = RouteProp<ChatStackParamList, 'CHAT_ROOT_SCREEN'>;
export type ChatRootScreenNavigationProp = CompositeNavigationProp<
	StackNavigationProp<ChatStackParamList, 'CHAT_ROOT_SCREEN'>,
	ChatStackScreenNavigationProp
>;

export type ChatDirectMessageScreenRouteProp = RouteProp<
	ChatStackParamList,
	'CHAT_DIRECT_MESSAGE_SCREEN'
>;
export type ChatDirectMessageScreenNavigationProp = CompositeNavigationProp<
	StackNavigationProp<ChatStackParamList, 'CHAT_DIRECT_MESSAGE_SCREEN'>,
	ChatStackScreenNavigationProp
>;

const ChatStack = createStackNavigator<ChatStackParamList>();

interface IChatStackProps {
	navigation: ChatStackScreenNavigationProp;
	route: ChatStackScreenRouteProp;
}

export default function ChatStackScreen({ navigation, route }: IChatStackProps) {
	const ChatDirectMessageScreenOptions = useChatDirectMessageScreenOptions();
	const ChatRootScreenOptions = useChatRootScreenOptions();
	const channelId = route.params?.channelId;
	const initialRouteName = route.params?.channelId
		? 'CHAT_DIRECT_MESSAGE_SCREEN'
		: 'CHAT_ROOT_SCREEN';

	return (
		<ChatStack.Navigator initialRouteName={initialRouteName}>
			<ChatStack.Screen
				name="CHAT_ROOT_SCREEN"
				component={ChatRootScreen}
				options={{
					...ChatRootScreenOptions,
				}}
			/>
			<ChatStack.Screen
				name="CHAT_DIRECT_MESSAGE_SCREEN"
				component={ChatDirectMessageScreen}
				initialParams={{ channelId }}
				options={ChatDirectMessageScreenOptions}
			/>
		</ChatStack.Navigator>
	);
}

export function useChatStackScreenOptions(): StackNavigationOptions {
	const layout = useLayout();
	const subScreenStyleProps = useSubScreenStyleProps();
	const modalStyleProps = useModalStyleProps();
	const styleProps = layout.isWebDesktop ? modalStyleProps : subScreenStyleProps;
	const { t } = useTranslation();

	return {
		title: t('chat.screenTitle'),
		...styleProps,
		headerShown: false,
	};
}
