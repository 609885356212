import messaging from '@mobe/utils/messaging';
import { useAppStateChange } from '@mobe/utils/useAppStateChange';
import React from 'react';

export interface INotificationsSettingsContext {
	notificationsPermitted: boolean;
}

const NotificationsSettingsContext = React.createContext<INotificationsSettingsContext | undefined>(
	undefined
);

function NotificationsSettingsProvider({ children }: { children: React.ReactNode }) {
	const [notificationsPermitted, setNotificationsPermitted] = React.useState<boolean>(false);
	const updateNotificationsPermitted = async () => {
		const notificationsEnabledOnDevice = await messaging.hasPermissions();

		setNotificationsPermitted(notificationsEnabledOnDevice);
	};

	useAppStateChange({ onActive: updateNotificationsPermitted });

	React.useEffect(() => {
		updateNotificationsPermitted();
	}, []);

	return (
		<NotificationsSettingsContext.Provider
			value={{
				notificationsPermitted,
			}}
		>
			{children}
		</NotificationsSettingsContext.Provider>
	);
}

function useNotificationsSettings() {
	const context = React.useContext(NotificationsSettingsContext);
	if (context === undefined) {
		throw new Error('useNotificationsSettings must be used within a NotificationsSettingsProvider');
	}

	return context;
}

export { NotificationsSettingsProvider, useNotificationsSettings };
