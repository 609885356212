import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IScreenTemplateCentered {
	children: React.ReactNode;
}

export default function ScreenTemplateCentered({ children }: IScreenTemplateCentered) {
	const styles = useScreenTemplateCenteredStyles();

	return (
		<ScrollView
			overScrollMode="never"
			bounces={false}
			bouncesZoom={false}
			style={styles.body}
			contentContainerStyle={styles.bodyInner}
			centerContent
			automaticallyAdjustContentInsets={false}
		>
			{children}
		</ScrollView>
	);
}

function useScreenTemplateCenteredStyles() {
	const safeInsets = useSafeAreaInsets();
	const rules = useStyleRules();

	return StyleSheet.create({
		body: {
			backgroundColor:
				Platform.OS !== 'web' ? rules.colors.cardBackground : rules.colors.background,
		},
		bodyInner: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			paddingTop: rules.spacing.appVerticalMargin,
			paddingBottom: rules.spacing.appVerticalMargin + safeInsets.bottom,
			backgroundColor: rules.colors.cardBackground,
			alignSelf: 'center',
			width: '100%',
			maxWidth: rules.spacing.maxWidth,

			...(Platform.OS !== 'ios' && {
				flexGrow: 1,
				justifyContent: 'center',
				alignItems: 'center',
			}),
		},
	});
}
