import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G transform="translate(-430.299 -321.663)">
					<Circle fill="#13181C" cx={466.009} cy={330.7} r={4.537} />
					<Path
						fill="#40506D"
						d="M464.47 362.414l-.962.026a2.784 2.784 0 00-2.692 3.1l1.576 13.762c.017.148.048.295.093.437l-.26.724a21.805 21.805 0 00-1.194 9.318l.457 7.817a1.307 1.307 0 002.579.157 137.736 137.736 0 002.871-17.875c.009-.115.009-.23 0-.345.02-.09.035-.181.045-.272l1.239-12.83a3.667 3.667 0 00-3.752-4.019z"
					/>
					<Path
						fill="#787D9A"
						d="M474.052 356.684c.454.636 2.776 4.325-.237 9.068a.3.3 0 01-.458.049c-.779-.768-3.046-3.012-3.046-3.09 0-.095 1.946-4.593 1.946-4.593l1.629-1.452a.112.112 0 01.166.018z"
					/>
					<Path
						fill="#566184"
						d="M473.876 356.667s2.472 4.759-.5 9.171c0 0-5.5-1.43-5.145-1.808s5.645-7.363 5.645-7.363z"
					/>
					<Path
						fill="#40506D"
						d="M479.224 356.79v-.01a1.76 1.76 0 00-.426-1.207 2.025 2.025 0 00-2.943-.291l-9 7.818a2.833 2.833 0 103.988 4.009l3.726-4.241c.004.736.054 1.47.149 2.2l.715 5.464a1.069 1.069 0 002.114.041 101.343 101.343 0 001.664-13.56 1.83 1.83 0 00.013-.223z"
					/>
					<Path
						fill="#787D9A"
						d="M470.846 367.108l-1.663 1.742a5.912 5.912 0 00-.961 1.038 1.882 1.882 0 00-.308.953l-.394-3.033 3.326-.7z"
					/>
					<Path
						fill="#787D9A"
						d="M473.823 356.554a.408.408 0 00-.335.113c-.094.09-.177.191-.248.3a3.999 3.999 0 01-.5.587l-2.942 2.557a3.15 3.15 0 00-1.257-.05l-5.268.845c-1.687.27-3.1.033-2.565 3.544l.08 1.025.081.093-.057-.023.18 11.83a6.91 6.91 0 006.641-1.094l.34-6.615 4.824-3.24.859-.545c-1.623-3.241-1.756-5.664-.566-7.882.108-.204.228-.403.357-.595.07-.106.137-.213.206-.319.086-.133.173-.266.266-.4.025-.034.087-.016.118-.012a.349.349 0 00-.214-.119z"
					/>
					<Path
						fill="#787D9A"
						d="M473.668 357.012s.152-.264.283-.186 0-.173 0-.173a.667.667 0 00-.171-.035.926.926 0 00-.113.176 5.12 5.12 0 00.001.218zM473.381 365.354a.2.2 0 00.282.018l.018-.018c.156-.158.123.119.052.244s-.18.313-.251.332-.32.016-.323-.055c.06-.18.134-.354.222-.521z"
					/>
					<Path
						fill="#40506D"
						d="M462.361 379.061a6.62 6.62 0 01-.153 1.518c-.174.683 1.042-.406 1.063-.453a8.896 8.896 0 00-.311-1.256c-.032-.007-.599.191-.599.191z"
					/>
					<Path
						fill="#FFF"
						d="M462.118 397.53l1.719.107a.1.1 0 10.013-.201l-1.719-.106c-.055-.004-.103.038-.107.094s.039.102.094.106z"
					/>
					<Path
						fill="#2AA48E"
						d="M465.595 400.142v-.009a24.292 24.292 0 00-1.127-2.701.666.666 0 00-.5-.369c-.019-.43-.3-.79-1.019-.79-.681 0-.946.283-.982.783h-.249a.623.623 0 00-.6.455l-.579 2.074a3.789 3.789 0 00-.111.686.814.814 0 00.732.888c.026.002.052.004.078.004h3.574a.806.806 0 00.783-1.021zM480.703 374.841a.806.806 0 00.787-1.021v-.009a8.773 8.773 0 00-.041-.122 8.773 8.773 0 00-2.684-1.339.87.87 0 01-.452-.346l-.525-.8c-.278-.566.018-1.311-.95-1.256-.655.037-.686.41-.613 1.119l-.414-.225a.558.558 0 00-.8.336v.008l-.579 2.074c-.064.203-.111.41-.141.621a.636.636 0 00.6.733l5.812.227z"
					/>
					<G>
						<Path
							fill="#CE7068"
							d="M485.511 355.213c1.166.056 1.141-.272 1.146-.433s-.759-.325-1.33-.358a5.568 5.568 0 00-2.86.358 6.34 6.34 0 01-.6.222l-5.5-1.6-1.745-.68a14.759 14.759 0 00-2.419-.706l-.39-.078 1.136-8.273a2.5 2.5 0 10-4.985-.261l.306 9.714v.02c-.005.142.002.284.022.424a1.656 1.656 0 001.22 1.466c.039.015.079.029.12.042l.222.069a22.302 22.302 0 006.021 1.4l5.531.533c.114.019.23.019.344 0a6.95 6.95 0 001.754.657c1.395.444 3.532-1.314 3.627-1.7.091-.359-1.62-.816-1.62-.816z"
						/>
						<Path
							fill="#FEC14D"
							d="M472.323 342.899a1.416 1.416 0 00-.765-1.851l-.02-.008-2.538-.93a10.1 10.1 0 00-8.084.476l-1.88.952a1.561 1.561 0 00-.481 2.322l-.232.022 1.477 6.06.922 5.2-.24 7.528a.264.264 0 00.24.286h.003l1.342.1a12.09 12.09 0 009.2-3.906l-.476-6.621 1.532-9.63z"
						/>
						<Path
							fill="#CE7068"
							d="M476.65 339.768a4.045 4.045 0 00-.37-.716 11.735 11.735 0 00-.829-.855 1.04 1.04 0 00-1.471.021l-.011.012c.552-1.175.2-1.289.041-1.362s-.5.746-.932 1.216c-.173.19-.729 1.378-.994 1.855a4.047 4.047 0 00.011 1.786c-.018.019-.036.037-.053.058l-3.629 4.455-1.3 1.342c-.433.447-.837.921-1.21 1.419l-3.789-6.311a2.5 2.5 0 10-4.05 2.919l6.368 7.43c.129.198.297.369.494.5a1.654 1.654 0 001.97-.044c.161-.114.306-.249.433-.4l.15-.178a22.324 22.324 0 003.636-5l2.487-4.6c.145-.051.284-.118.414-.2a11.257 11.257 0 002.478-2.579s.269-.523.156-.768z"
						/>
					</G>
					<G>
						<Path
							fill="#CE7068"
							d="M467.676 339.516a1.819 1.819 0 01-1.773 1.863h-.15a1.82 1.82 0 01-1.863-1.773l-.05-2.021-.06-2.384a1.821 1.821 0 011.773-1.863h.15a1.819 1.819 0 011.863 1.773l.106 4.252.004.153z"
						/>
						<Path
							fill="#BB5C58"
							d="M467.672 339.358c-.246.068-.5.106-.755.112h-.12a3.136 3.136 0 01-2.957-1.887l-.06-2.384a1.821 1.821 0 011.773-1.863h.15a1.819 1.819 0 011.863 1.773l.106 4.249z"
						/>
						<Path
							fill="#CE7068"
							d="M466.579 328.294a3.201 3.201 0 013.28 3.12l.101 4.021a3.201 3.201 0 01-6.4.16l-.101-4.021a3.2 3.2 0 013.12-3.28z"
						/>
						<Path
							fill="#CE7068"
							d="M464.278 333.668a1.092 1.092 0 01-2.181.067 1.09 1.09 0 01.825-1.091 1.216 1.216 0 011.356 1.024z"
						/>
					</G>
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
