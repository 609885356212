import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import { IInputSelectItem } from '@mobe/components/input/InputSelect';
import { useTranslation } from 'react-i18next';
import useCanScheduleAppointment from './useCanScheduleAppointment';

// Not using CoachType enum on purpose because those values can change
// In the guide scheduling ui there is only ever a guide or pharmacist state
export enum GuideTypeValue {
	Guide = 'guide',
	Pharmacist = 'pharmacist',
}

export default function useGuideTypeOptions() {
	const guide = useGuideQuery().data;
	const pharmacist = usePharmacistQuery().data;
	const { canScheduleGuide, canSchedulePharmacist } = useCanScheduleAppointment();
	const { t } = useTranslation();
	const options: IInputSelectItem<GuideTypeValue>[] = [];

	if (guide && canScheduleGuide) {
		options.push({
			label: t('appointments.scheduleGuideTypeSelect.options.guideLabel', {
				guideName: guide.preferredName,
			}),
			value: GuideTypeValue.Guide,
		});
	}

	if (pharmacist && canSchedulePharmacist) {
		options.push({
			label: t('appointments.scheduleGuideTypeSelect.options.pharmacistLabel', {
				guideName: pharmacist.preferredName,
			}),
			value: GuideTypeValue.Pharmacist,
		});
	}

	return options;
}
