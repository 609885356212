import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { useCoachesQuery } from '@mobe/api/guides/guidesApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import { Announce } from '@mobe/components/announce/Announce';
import AutoKeyboardAvoidingView from '@mobe/components/autoKeyboardAvoidingView/AutoKeyboardAvoidingView';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import { ChatGraphic } from '@mobe/components/graphics';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useIntersection } from '@mobe/utils/useIntersection';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SectionList, StyleSheet, View } from 'react-native';
import { useChatHubConnection } from '../chatHubConnectionContext';
import DateHeader from './DateHeader';
import Message, { IMessagesSet } from './Message';
import MessageToolbar from './MessageToolbar';
import useChat from './useChat';

interface IChatProps {
	channelId: number;
}

export default function Chat({ channelId }: IChatProps) {
	const coachesQuery = useCoachesQuery();
	const profileQuery = useProfileQuery();
	const {
		messageInputRef,
		currentMessage,
		attachmentMediaUri,
		currentChatsAsSectionData,
		chatsQuery,
		isSendingMessage,
		handleChangeText,
		handleEndReached,
		handleSendMessage,
		handleRemoveAttachment,
		processMedia,
	} = useChat({ channelId });

	const styles = useChatStyles();
	const { constrain, constrainText, vr } = useStyleHelpers();
	const { t } = useTranslation();
	const chatHub = useChatHubConnection();
	const permissionsQuery = usePermissionsQuery();

	const haveMessages = currentChatsAsSectionData.length > 0;
	const isLoadingInitialMessageData = !haveMessages && chatsQuery.isFetching;
	const isPendingInitialLoad = isLoadingInitialMessageData;
	const isConnectedToChatServer = chatHub.status === 'connected';
	const shouldShowNoMessagesView = !isPendingInitialLoad && !haveMessages;
	const shouldShowMessagesList = !isPendingInitialLoad && haveMessages;
	const haveValidMessageForSending =
		(currentMessage && currentMessage.length !== 0) || Boolean(attachmentMediaUri);
	const canSendMessage =
		!isPendingInitialLoad && isConnectedToChatServer && haveValidMessageForSending;

	function renderMessage({
		item: messageSet,
		section,
	}: {
		item: IMessagesSet;
		section: {
			data: IMessagesSet[];
			title: string;
		};
	}) {
		const sectionIndex = currentChatsAsSectionData.findIndex(
			(sectionDataSection) => sectionDataSection.title === section.title
		);

		const guideThatMatchesChannelId = coachesQuery.data?.find(
			(coach) => coach?.chatChannelId === channelId
		);

		const user =
			messageSet.isSentByCoach && guideThatMatchesChannelId
				? {
						name: guideThatMatchesChannelId.preferredName || '',
						avatarUrl: guideThatMatchesChannelId.avatarUrl || '',
				  }
				: {
						name: profileQuery.data?.preferredName || '',
						avatarUrl: profileQuery.data?.avatarUrl || '',
				  };

		return <Message messageSet={messageSet} user={user} sectionIndex={sectionIndex} />;
	}

	function renderDateHeader({ section: { title } }: { section: { title: string } }) {
		return <DateHeader title={title} />;
	}

	const lastMessage =
		currentChatsAsSectionData[0]?.data[0]?.messages[
			currentChatsAsSectionData[0]?.data[0]?.messages?.length - 1
		];
	const isLastMessageFromGuide = currentChatsAsSectionData[0]?.data[0]?.isSentByCoach;

	return (
		<AutoKeyboardAvoidingView style={styles.container}>
			<DeferredLoadingIndicator isLoading={isPendingInitialLoad}>
				{/* No messages view */}
				{shouldShowNoMessagesView && (
					<View style={styles.noMessagesContainer}>
						<View>
							<View style={[vr(8), constrain(280)]}>
								<ChatGraphic />
							</View>
							<View style={[vr(8), constrainText(300)]}>
								<Text accessibilityAutoFocus weight="bold" align="center" color="light">
									{permissionsQuery.data?.hasMedServicesAccess
										? t('chat.noMessagesView.description')
										: t('chat.noMessagesView.descriptionNoPharmacist')}
								</Text>
							</View>
						</View>
					</View>
				)}

				{/* Messages list */}
				{shouldShowMessagesList && (
					<SectionList
						sections={currentChatsAsSectionData}
						keyExtractor={(item) => item.id.toString()}
						// For why web is handled differently:
						// https://github.com/necolas/react-native-web/issues/1254#issuecomment-986606712
						inverted={Platform.OS !== 'web'}
						stickySectionHeadersEnabled={false}
						renderItem={renderMessage}
						renderSectionFooter={renderDateHeader}
						style={styles.messagesList}
						contentContainerStyle={styles.messagesListInner}
						ListFooterComponent={
							Platform.OS === 'web' ? (
								<WebChatListFooterEndReachedElement onEndReached={handleEndReached} />
							) : null
						}
						ListHeaderComponent={
							Platform.OS === 'web' ? (
								<Announce
									message={
										isLastMessageFromGuide
											? t('chat.messageReceivedFromGuideAnnouncement', {
													date: format(new Date(lastMessage.createdAt), 'p'),
													message: lastMessage.text,
											  })
											: t('chat.messageReceivedFromUserAnnouncement', { message: lastMessage.text })
									}
								/>
							) : null
						}
						onEndReached={Platform.OS !== 'web' ? handleEndReached : noop}
					/>
				)}

				{/* Message toolbar */}
				<MessageToolbar
					ref={messageInputRef}
					message={currentMessage}
					attachmentMediaUri={attachmentMediaUri}
					canSendMessage={canSendMessage}
					isMessageSending={isSendingMessage}
					onChangeText={handleChangeText}
					onSendPress={handleSendMessage}
					onMediaSelection={processMedia}
					onRemoveAttachment={handleRemoveAttachment}
				/>
			</DeferredLoadingIndicator>
		</AutoKeyboardAvoidingView>
	);
}

// This component helps us trigger onEndReach on web. It gets added to the bottom of the list and uses
// intersection observer to detect end reached by the element being intersected
function WebChatListFooterEndReachedElement({ onEndReached }: { onEndReached: () => void }) {
	const elementRef = React.useRef<HTMLDivElement>(null);
	const endReached = useIntersection(elementRef);

	React.useEffect(() => {
		if (endReached) {
			onEndReached();
		}
	}, [endReached]);

	return <div ref={elementRef} />;
}

function useChatStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: 'space-between',
			backgroundColor: styleRules.colors.background,
		},
		messagesList: {
			flexGrow: 1,
			paddingHorizontal: styleRules.spacing.appHorizontalMargin,

			// For why web is handled differently:
			// https://github.com/necolas/react-native-web/issues/1254#issuecomment-986606712
			flexDirection: Platform.OS === 'web' ? 'column-reverse' : undefined,
		},
		messagesListInner: {
			paddingVertical: 16,

			// For why web is handled differently:
			// https://github.com/necolas/react-native-web/issues/1254#issuecomment-986606712
			flexDirection: Platform.OS === 'web' ? 'column-reverse' : undefined,
		},
		noMessagesContainer: {
			flex: 1,
			justifyContent: 'center',
		},
	});
}
