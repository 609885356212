import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import Text from '../text/Text';

interface IBulletListItemProps extends ViewProps {
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
}

export default function BulletListItem({ children, style }: IBulletListItemProps) {
	const styles = StyleSheet.create({
		container: {
			flexDirection: 'row',
		},
		bullet: {
			marginRight: 10,
		},
		text: {
			flexGrow: 1,
			flexShrink: 1,
		},
	});

	return (
		<View accessible style={[styles.container, style]}>
			<View style={styles.bullet}>
				<Text color="light">{'\u2022'}</Text>
			</View>
			<View style={styles.text}>{children}</View>
		</View>
	);
}
