import { Button } from '@mobe/components/button';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	AppointmentRescheduleScreenNavigationProp,
	AppointmentRescheduleScreenRouteProp,
} from '../../navigation/modal/types';
import ScheduleApptView from '../components/ScheduleApptView';
import useAppointmentRescheduleController from './appointmentRescheduleController';

export interface IAppointmentRescheduleScreenParams {
	confirmationId: string;
}

export interface IAppointmentRescheduleScreenProps {
	navigation: AppointmentRescheduleScreenNavigationProp;
	route: AppointmentRescheduleScreenRouteProp;
}

export default function AppointmentRescheduleScreen({
	navigation,
	route,
}: IAppointmentRescheduleScreenProps) {
	const { vr } = useStyleHelpers();
	const controller = useAppointmentRescheduleController({ navigation, route });
	const { t } = useTranslation();
	const initialDate = controller.initialApptDate;
	const [submitButtonRef, setFocusToSubmitButton] = useAccessibilityFocus();

	const canProgress =
		!controller.apptDateControl.fieldState.error && controller.apptDateControl.fieldState.isDirty;

	return initialDate ? (
		<ScreenTemplateWithFooter
			fixedPositionFooter={canProgress}
			footer={
				<Button
					ref={submitButtonRef}
					title={t('appointments.reschedule.rescheduleButton')}
					disabled={!canProgress}
					loading={controller.isRescheduling}
					onPress={controller.handleSubmitPress}
				/>
			}
		>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(6)}
			>
				{t('appointments.reschedule.description', {
					guide: controller.guideName,
					date: format(initialDate, 'MMM d'),
					time: format(initialDate, 'p'),
				})}
			</Text>

			<DeferredLoadingIndicator isLoading={controller.apptDatesQuery.isLoading}>
				{controller.apptDatesQuery.status === 'success' && (
					<FetchingIndicator isFetching={controller.apptDatesQuery.isFetching}>
						<ScheduleApptView
							apptDates={controller.apptDatesQuery.data.availableDays}
							apptDurationMinutes={controller.getApptDurationInMinutes()}
							selectedApptDate={controller.apptDateControl.field.value}
							useInitialDate
							onApptSelection={controller.apptDateControl.field.onChange}
							onTimeSelection={() => setFocusToSubmitButton()}
							onRetry={controller.handleNoTimeSlotsRetryPress}
						/>
					</FetchingIndicator>
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	) : (
		<ScreenTemplate />
	);
}

export function useAppointmentRescheduleScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	return {
		title: t('appointments.reschedule.screenTitle'),
		...modalStyleProps,
	};
}
