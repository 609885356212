import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import IMask from 'imask/esm/imask';
import { noop } from 'lodash';
import * as React from 'react';
import {
	NativeSyntheticEvent,
	Platform,
	StyleProp,
	TextInput,
	TextInputFocusEventData,
	TextInputProps,
	TextStyle,
	View,
	ViewStyle,
} from 'react-native';
import useBaseInputStyles from './baseInputStyles';

export type BaseInputType = 'text' | 'email' | 'phone' | 'date' | 'integer' | 'number';

export interface IBaseInputProps extends TextInputProps {
	hasError?: boolean;
	disabled?: boolean;
	autoHeight?: boolean;

	/**
	 * Style for the View wrapping the input.
	 */
	style?: StyleProp<ViewStyle>;

	/**
	 * Style for the actual input.
	 */
	inputStyle?: StyleProp<TextStyle>;

	/**
	 * Selecting an input type will apply appropriate defaults to the native input,
	 * such as keyboard type and other expected properties.
	 */
	type?: BaseInputType;

	/**
	 * Setting this to true will cause onChangeText to return the masked value rather than the
	 * unmasked value of masked input types, such as date or phone. In the case of date, the
	 * returned value would be '12/12/2012' rather than '12122012'.
	 */
	useMaskedValue?: boolean;

	/**
	 * Function which returns a React Element to display on the left side of the input.
	 */
	inputLeft?: (props: { hasError: boolean; disabled: boolean }) => React.ReactNode;
	inputLeftContainerStyle?: ViewStyle;

	/**
	 * Function which returns a React Element to display on the right side of the input.
	 */
	inputRight?: (props: { hasError: boolean; disabled: boolean }) => React.ReactNode;
	inputRightContainerStyle?: ViewStyle;
}

function BaseInput(
	{
		hasError = false,
		disabled = false,
		autoHeight = false,
		style = {},
		inputStyle = {},
		type = 'text',
		useMaskedValue = false,
		inputLeft,
		inputLeftContainerStyle = {},
		inputRight,
		inputRightContainerStyle = {},
		onFocus = () => noop(),
		onBlur = () => noop(),
		value = '',
		onChangeText = (text: string) => noop(text),
		...textInputProps
	}: IBaseInputProps,
	ref: any
) {
	const auth = useAuth();
	const [hasFocus, setHasFocus] = React.useState<boolean>(false);
	const styleRules = useStyleRules();
	const styles = useBaseInputStyles({
		hasError,
		hasFocus,
		disabled,
		autoHeight,
		isSecureText: Boolean(textInputProps.secureTextEntry),
	});

	function onFocusHandler(event: NativeSyntheticEvent<TextInputFocusEventData>) {
		auth.onUserActivity();
		setHasFocus(true);
		onFocus(event);
	}

	function onBlurHandler(event: NativeSyntheticEvent<TextInputFocusEventData>) {
		auth.onUserActivity();
		setHasFocus(false);
		onBlur(event);
	}

	function renderInputLeft(): React.ReactNode | null {
		if (typeof inputLeft === 'function') {
			return (
				<View style={[styles.inputLeft, inputLeftContainerStyle]}>
					{inputLeft({ hasError, disabled })}
				</View>
			);
		}
		return null;
	}

	function renderInputRight(): React.ReactNode | null {
		if (typeof inputRight === 'function') {
			return (
				<View style={[styles.inputRight, inputRightContainerStyle]}>
					{inputRight({ hasError, disabled })}
				</View>
			);
		}
		return null;
	}

	const defaultProps: TextInputProps = {
		keyboardType: Platform.OS === 'ios' ? 'ascii-capable' : 'default',
		autoCapitalize: 'none',
		autoCorrect: false,
		allowFontScaling: false,
		style: [styles.input, inputStyle],
		placeholderTextColor: styleRules.colors.textLight,
		underlineColorAndroid: 'transparent',
		readOnly: disabled,
		['aria-disabled']: disabled,
		multiline: autoHeight,
		onFocus: onFocusHandler,
		onBlur: onBlurHandler,
		value,
		onChangeText,
	};

	function renderInput() {
		if (type === 'phone') {
			const masked = IMask.createMask({ mask: '000-000-0000' });
			masked.resolve(value);

			return (
				<TextInput
					ref={ref}
					{...defaultProps}
					keyboardType="number-pad"
					textContentType="telephoneNumber"
					autoComplete="tel"
					placeholder="___-___-____"
					value={masked.value}
					onChangeText={(value) => {
						masked.resolve(value);
						onChangeText(useMaskedValue ? value : masked.unmaskedValue);
					}}
					{...textInputProps}
				/>
			);
		}

		if (type === 'date') {
			const date = new Date(Date.now());
			const currentYear = date.getFullYear();
			const masked = IMask.createMask({
				mask: 'MM/DD/YYYY',
				blocks: {
					MM: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 12,
					},
					DD: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 31,
					},
					YYYY: {
						mask: IMask.MaskedRange,
						from: currentYear - 150,
						to: currentYear,
					},
				},
			});
			masked.resolve(value);

			return (
				<TextInput
					ref={ref}
					{...defaultProps}
					keyboardType="number-pad"
					placeholder="mm/dd/yyyy"
					maxLength={masked.mask.length}
					aria-valuetext={masked.unmaskedValue}
					value={masked.value}
					onChangeText={(value) => {
						masked.resolve(value);
						onChangeText(useMaskedValue ? value : masked.unmaskedValue);
					}}
					{...textInputProps}
				/>
			);
		}

		if (type === 'email') {
			return (
				<TextInput
					ref={ref}
					{...defaultProps}
					autoComplete="email"
					textContentType="emailAddress"
					keyboardType="email-address"
					{...textInputProps}
				/>
			);
		}

		if (type === 'integer') {
			const numberRegex = new RegExp(/^[0-9]*$/);

			return (
				<TextInput
					ref={ref}
					{...defaultProps}
					maxLength={20}
					keyboardType="number-pad"
					autoComplete="off"
					onChangeText={(newValue) => onChangeText(numberRegex.test(newValue) ? newValue : value)}
					{...textInputProps}
				/>
			);
		}

		if (type === 'number') {
			const numberToTenthDecimalRegex = new RegExp(/^[0-9]*\.?[0-9]?$/);

			return (
				<TextInput
					ref={ref}
					{...defaultProps}
					maxLength={20}
					keyboardType="decimal-pad"
					autoComplete="off"
					onChangeText={(newValue) =>
						onChangeText(numberToTenthDecimalRegex.test(newValue) ? newValue : value)
					}
					{...textInputProps}
				/>
			);
		}

		return <TextInput ref={ref} {...defaultProps} {...textInputProps} />;
	}

	return (
		<View style={[styles.inputWrapper, style]}>
			{renderInputLeft()}
			{renderInput()}
			{renderInputRight()}
		</View>
	);
}

export default React.forwardRef(BaseInput);
