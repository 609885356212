import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function ActionStepsSimpleGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(70, 62);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const gray1 = isDarkTheme ? '#BBBBBB' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#444444' : '#EAEAEA';
	const gray3 = isDarkTheme ? '#444444' : '#D3D3D3';
	const gray4 = isDarkTheme ? '#444444' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray3}
					d="M10.805 61.5h48.391V.57H10.805V61.5z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray1}
					d="M12.623 60.056h44.755V2.031H12.623v58.025z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray3}
					d="M26.486.57v1.994c0 1.14.986 2.064 2.201 2.064h11.817c1.216 0 2.201-.924 2.201-2.064V.57H26.486z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M19.219 48.11h7.084v-6.641h-7.084v6.641zm-.369.346h7.822v-7.331H18.85v7.331z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M22.957 46.81a.568.568 0 0 1-.426-.188l-2.414-2.737a.496.496 0 0 1 .074-.729.578.578 0 0 1 .778.069l1.914 2.17 3.463-5.447a.573.573 0 0 1 .758-.179.5.5 0 0 1 .191.71l-3.865 6.078a.556.556 0 0 1-.432.252c-.013-.002-.027.001-.041.001z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M19.221 19.329h7.084v-6.641h-7.084v6.641zm-.369.345h7.822v-7.331h-7.822v7.331z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M22.957 18.513a.564.564 0 0 1-.426-.188l-2.414-2.737a.496.496 0 0 1 .074-.729.58.58 0 0 1 .778.069l1.914 2.17 3.463-5.446a.575.575 0 0 1 .758-.181.5.5 0 0 1 .191.71l-3.865 6.078a.556.556 0 0 1-.432.252c-.013.002-.027.002-.041.002z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M31.944 14.762h10.199v-.716H31.944v.716zM48.493 15.09h2.317v-.716h-2.317v.716zM31.944 18.285h10.199v-.715H31.944v.715zM47.354 18.285h3.456v-.715h-3.456v.715zM47.969 16.684h2.84v-.716h-2.84v.716zM39.481 16.684h2.841v-.716h-2.841v.716zM31.944 16.684h6.312v-.716h-6.312v.716zM19.221 34.132h7.084v-6.641h-7.084v6.641zm-.369.347h7.822v-7.331h-7.822v7.331z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray4}
					d="M22.957 32.941a.564.564 0 0 1-.426-.188l-2.414-2.735a.495.495 0 0 1 .074-.729.579.579 0 0 1 .778.068l1.914 2.171 3.463-5.447a.573.573 0 0 1 .758-.179.5.5 0 0 1 .191.71L23.43 32.69a.554.554 0 0 1-.432.25l-.041.001z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill={gray2}
					d="M31.944 28.809h10.199v-.716H31.944v.716zM48.493 28.809h2.317v-.716h-2.317v.716zM31.944 32.004h10.199v-.714H31.944v.714zM47.354 32.004h3.456v-.714h-3.456v.714zM47.969 30.396h2.84v-.716h-2.84v.716zM39.481 30.396h2.841v-.716h-2.841v.716zM31.944 30.396h6.312v-.716h-6.312v.716zM31.944 42.769h10.199v-.715H31.944v.715zM48.493 42.769h2.317v-.715h-2.317v.715zM31.944 45.965h10.199v-.715H31.944v.715zM47.349 45.965h3.456v-.715h-3.456v.715zM47.966 44.356h2.84v-.716h-2.84v.716zM39.477 44.356h2.841v-.716h-2.841v.716zM31.944 44.356h6.312v-.716h-6.312v.716z"
				/>
			</Svg>
		</View>
	);
}
