import { useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { CoachesQueryKeys, CoachType, fetchCoaches, ICoach } from './guidesApi';

export function useCoachesQuery<TSelectData = ICoach[]>(
	select?: ((data: ICoach[]) => TSelectData) | undefined,
	refetch?: boolean
) {
	const auth = useAuth();

	return useQuery<ICoach[], unknown, TSelectData>(CoachesQueryKeys.Coaches, fetchCoaches, {
		enabled: auth.isAuthenticated,
		staleTime: refetch ? 0 : Infinity,
		select,
	});
}

export function useGuideQuery(refetch?: boolean) {
	return useCoachesQuery<ICoach | undefined>(
		(data) => data.find((guide) => guide.coachType !== CoachType.Pharmacist),
		refetch
	);
}

export function usePharmacistQuery(refetch?: boolean) {
	return useCoachesQuery<ICoach | undefined>(
		(data) => data.find((guide) => guide.coachType === CoachType.Pharmacist),
		refetch
	);
}
