import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Svg, { Circle, G, GProps, Path } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

interface IMoodGraphicProps extends GProps {
	colored: boolean;
}

export function Great({ colored, ...props }: IMoodGraphicProps) {
	return (
		<G fill={colored ? '#2AA48E' : '#D7D7D7'} {...props}>
			<Path d="M28.502 57C12.768 56.981.019 44.232 0 28.498.02 12.766 12.77.018 28.502 0 44.232.02 56.979 12.768 57 28.498 56.981 44.23 44.234 56.98 28.502 57zm0-53.883c-14.01.018-25.363 11.371-25.378 25.381.018 14.009 11.37 25.36 25.378 25.378 14.011-.016 25.365-11.37 25.381-25.382-.018-14.01-11.372-25.361-25.381-25.377z" />
			<Path d="M22.872 24.46a1.299 1.299 0 01-1.3-1.3 3.25 3.25 0 00-3.245-3.246 3.25 3.25 0 00-3.245 3.245 1.299 1.299 0 01-1.3 1.3 1.3 1.3 0 01-1.3-1.3 5.85 5.85 0 015.84-5.84 5.852 5.852 0 015.839 5.84c0 .714-.575 1.295-1.29 1.3zm20.347 0a1.299 1.299 0 01-1.3-1.3 3.25 3.25 0 00-3.245-3.246 3.25 3.25 0 00-3.244 3.245 1.299 1.299 0 01-1.3 1.3 1.3 1.3 0 01-1.3-1.3 5.851 5.851 0 015.839-5.84 5.852 5.852 0 015.84 5.84c0 .714-.576 1.295-1.29 1.3zM28.81 47.076c-7.808-.008-14.135-6.335-14.143-14.143a1.3 1.3 0 011.3-1.3h25.685a1.3 1.3 0 011.3 1.3c-.008 7.807-6.335 14.134-14.142 14.143zM17.337 34.233a11.56 11.56 0 0011.47 10.247 11.56 11.56 0 0011.471-10.247h-22.94z" />
		</G>
	);
}

export function Good({ colored, ...props }: IMoodGraphicProps) {
	return (
		<G fill={colored ? '#82AF4B' : '#D7D7D7'} {...props}>
			<Path d="M28.5 57C12.768 56.981.019 44.232 0 28.498.02 12.766 12.769.018 28.5 0 44.232.019 56.981 12.768 57 28.502 56.98 44.234 44.231 56.982 28.5 57zm0-53.883c-14.01.016-25.364 11.37-25.38 25.381.018 14.01 11.371 25.362 25.38 25.378 14.01-.016 25.364-11.37 25.38-25.382-.018-14.01-11.371-25.361-25.38-25.377z" />
			<Path d="M23.22 25.162a1.299 1.299 0 01-.919-.38 1.299 1.299 0 01-.38-.92 2.966 2.966 0 00-2.962-2.963 2.966 2.966 0 00-2.962 2.963 1.299 1.299 0 01-.38.92 1.299 1.299 0 01-.92.38 1.3 1.3 0 01-1.3-1.3 5.568 5.568 0 015.562-5.565 5.568 5.568 0 015.562 5.563 1.299 1.299 0 01-.38.92 1.299 1.299 0 01-.92.382zm19.082 0a1.299 1.299 0 01-.92-.38 1.299 1.299 0 01-.38-.92 2.966 2.966 0 00-5.93 0 1.299 1.299 0 01-.38.92 1.299 1.299 0 01-.92.38 1.299 1.299 0 01-.919-.38 1.299 1.299 0 01-.38-.92 5.568 5.568 0 015.562-5.565 5.568 5.568 0 015.562 5.563c.001.717-.578 1.3-1.295 1.302zM28.5 42.394a29.992 29.992 0 01-8.323-1.148 23.917 23.917 0 01-6.875-3.167 1.3 1.3 0 01-.342-1.807 1.299 1.299 0 011.806-.34 21.338 21.338 0 006.13 2.816 27.417 27.417 0 007.602 1.046 27.41 27.41 0 007.602-1.046 21.336 21.336 0 006.13-2.816 1.299 1.299 0 011.806.34 1.3 1.3 0 01-.34 1.807 23.914 23.914 0 01-6.875 3.167 29.992 29.992 0 01-8.321 1.148z" />
		</G>
	);
}

export function Ok({ colored, ...props }: IMoodGraphicProps) {
	return (
		<G fill={colored ? '#EFB925' : '#D7D7D7'} {...props}>
			<Path d="M28.5 57C12.768 56.981.019 44.232 0 28.498.02 12.766 12.769.018 28.5 0 44.232.019 56.981 12.768 57 28.502 56.98 44.234 44.231 56.982 28.5 57zm0-53.883c-14.01.016-25.364 11.37-25.38 25.381.018 14.01 11.371 25.362 25.38 25.378 14.01-.016 25.364-11.37 25.38-25.382-.019-14.01-11.372-25.36-25.38-25.377z" />
			<G transform="translate(16.526 17.588)">
				<Circle cx={3.67} cy={3.67} r={3.67} />
				<Circle cx={20.278} cy={3.67} r={3.67} />
			</G>
			<Path d="M40.218 39.11H16.78a1.559 1.559 0 0 1-1.103-.457 1.559 1.559 0 0 1-.457-1.104 1.559 1.559 0 0 1 .457-1.103 1.559 1.559 0 0 1 1.103-.457h23.437c.861 0 1.56.699 1.56 1.56a1.559 1.559 0 0 1-.457 1.104 1.559 1.559 0 0 1-1.103.456z" />
		</G>
	);
}

export function Bad({ colored, ...props }: IMoodGraphicProps) {
	return (
		<G fill={colored ? '#F6891F' : '#D7D7D7'} {...props}>
			<Path d="M21.22 17.691l-4.943 2.853a3.305 3.305 0 106.61 0 3.289 3.289 0 00-1.667-2.853zm14.56 0l4.942 2.853a3.305 3.305 0 11-6.61 0 3.289 3.289 0 011.668-2.853z" />
			<Path d="M28.5 57C12.768 56.98.019 44.231 0 28.498.02 12.767 12.769.02 28.5 0 44.233.02 56.981 12.769 57 28.502 56.98 44.233 44.231 56.98 28.5 57zm0-53.883c-14.011.016-25.365 11.37-25.38 25.381.017 14.01 11.37 25.363 25.38 25.378 14.011-.016 25.365-11.37 25.38-25.382-.017-14.01-11.37-25.361-25.38-25.377z" />
			<Path d="M35.184 41.625h-13.37a1.56 1.56 0 01-1.56-1.56 1.559 1.559 0 011.56-1.56h13.37a1.559 1.559 0 011.56 1.56 1.56 1.56 0 01-1.56 1.56z" />
		</G>
	);
}

export function Awful({ colored, ...props }: IMoodGraphicProps) {
	return (
		<G fill={colored ? '#E64E35' : '#D7D7D7'} {...props}>
			<Path d="M38.133 41.565a1.301 1.301 0 01-.361-.051 33.424 33.424 0 00-9.273-1.276 33.422 33.422 0 00-9.272 1.276 1.299 1.299 0 01-.989-.11 1.299 1.299 0 01-.62-.778 1.3 1.3 0 01.888-1.61 36.021 36.021 0 019.993-1.378 36.021 36.021 0 019.993 1.378 1.3 1.3 0 01-.36 2.55z" />
			<Path d="M28.499 57C12.767 56.981.019 44.232 0 28.498.02 12.766 12.768.018 28.499 0 44.23.018 56.979 12.766 57 28.498 56.981 44.232 44.231 56.982 28.499 57zm0-53.883c-14.01.016-25.364 11.37-25.38 25.381.018 14.01 11.371 25.363 25.38 25.378 14.01-.016 25.363-11.37 25.379-25.382-.019-14.01-11.371-25.36-25.38-25.377z" />
			<Path d="M37.557 19.552a2.975 2.975 0 00-1.763 2.71 2.975 2.975 0 105.95 0 2.87 2.87 0 00-.032-.308l-4.155-2.402zm6.613 2.144a1.55 1.55 0 01-.778-.21l-6.105-3.525a1.559 1.559 0 01-.727-.947 1.56 1.56 0 01.156-1.184 1.56 1.56 0 012.13-.57l6.106 3.525a1.559 1.559 0 01.727.947 1.56 1.56 0 01-.156 1.184 1.558 1.558 0 01-1.353.78zM19.442 19.552a2.975 2.975 0 011.763 2.71 2.975 2.975 0 11-5.95 0 2.888 2.888 0 01.032-.308l4.155-2.402zm-6.615 2.145a1.559 1.559 0 01-1.352-.78 1.56 1.56 0 01-.156-1.184 1.559 1.559 0 01.727-.947l6.106-3.525a1.56 1.56 0 012.13.57c.21.359.267.785.16 1.186a1.559 1.559 0 01-.728.95l-6.106 3.525a1.552 1.552 0 01-.781.205z" />
		</G>
	);
}

export default function MoodGraphic({
	expression,
	active = false,
	colored = false,
	accessible = false,
}: {
	expression: number;
	active?: boolean;
	colored?: boolean;
	accessible?: boolean;
}) {
	const { t } = useTranslation();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(57, 57);
	let graphic;

	switch (expression) {
		case 5:
			graphic = <Great colored={active || colored} />;
			break;
		case 4:
			graphic = <Good colored={active || colored} />;
			break;
		case 3:
			graphic = <Ok colored={active || colored} />;
			break;
		case 2:
			graphic = <Bad colored={active || colored} />;
			break;
		case 1:
			graphic = <Awful colored={active || colored} />;
			break;
		default:
			break;
	}

	const moodTranslations = t('trackers.mood.valueOptions', { returnObjects: true }) as Record<
		string,
		string
	>;

	const accessibilityLabel = expression in moodTranslations ? moodTranslations[expression] : '';

	return (
		<View style={wrapperStyles} accessible={accessible} aria-label={accessibilityLabel}>
			<Svg {...dimensionProps} style={{ transform: [{ scale: active ? 1.16 : 1 }] }}>
				{graphic}
			</Svg>
		</View>
	);
}
