import Text from '@mobe/components/text/Text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

interface IDateHeaderProps {
	title: string;
}

export default function DateHeader({ title }: IDateHeaderProps) {
	const styles = useStyles();

	return (
		<View style={styles.container}>
			<View style={styles.line}></View>
			<Text color="light" style={styles.text}>
				{title}
			</Text>
		</View>
	);
}

function useStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			marginTop: 16,
		},
		line: {
			borderTopWidth: StyleSheet.hairlineWidth,
			borderColor: styleRules.colors.stroke,
			width: '100%',
			height: 1,
			position: 'absolute',
			top: 13,
			left: 0,
		},
		text: {
			paddingHorizontal: 12,
			backgroundColor: styleRules.colors.background,
			textAlign: 'center',
			zIndex: 10,
		},
	});
}
