import { useActionStepsInfiniteQuery, useActionStepsQuery } from '@mobe/api/goals/goalsApiHooks';
import { Button } from '@mobe/components/button';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Blocks from '@mobe/components/layout/Blocks';
import ProgressCard from '@mobe/components/progressCard/ProgressCard';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { StackNavigationOptions } from '@react-navigation/stack';
import { FlashList } from '@shopify/flash-list';
import { format, isSameMonth } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
	ActionStepsHistoryScreenNavigationProp,
	ActionStepsHistoryScreenRouteProp,
} from '../../navigation/modal/types';
import ActionStepCard from '../components/ActionStepCard';

interface IActionStepsHistoryScreenProps {
	route: ActionStepsHistoryScreenRouteProp;
	navigation: ActionStepsHistoryScreenNavigationProp;
}

export default function ActionStepsHistoryScreen({ navigation }: IActionStepsHistoryScreenProps) {
	const { t } = useTranslation();
	const styleRules = useStyleRules();
	const safeInsets = useSafeAreaInsets();
	const { vr, vrTop, wrapperHorizontal, background, fill } = useStyleHelpers();
	const completedActionStepsQuery = useActionStepsQuery({ status: 'Completed' });
	const actionStepsInfiniteQuery = useActionStepsInfiniteQuery({
		status: 'Completed',
		limit: 24,
		page: 1,
	});

	const actionSteps = actionStepsInfiniteQuery.data?.pages.flatMap((page) => page);

	return (
		<View style={[background, fill]}>
			<DeferredLoadingIndicator
				isLoading={actionStepsInfiniteQuery.isLoading || completedActionStepsQuery.isLoading}
			>
				{actionSteps && (
					<FlashList
						data={actionSteps}
						keyExtractor={(item) => item.id.toString()}
						contentContainerStyle={{
							paddingTop: styleRules.spacing.appVerticalMargin,
							paddingBottom: safeInsets.bottom + styleRules.spacing.appVerticalMargin,
						}}
						ItemSeparatorComponent={() => <View style={vr(4)} />}
						estimatedItemSize={Platform.OS === 'web' ? 92 : 70}
						scrollIndicatorInsets={Platform.OS === 'ios' ? { right: 1 } : undefined}
						ListHeaderComponent={
							completedActionStepsQuery.isSuccess ? (
								<View style={wrapperHorizontal}>
									<Blocks style={vr(8)}>
										<ProgressCard
											count={completedActionStepsQuery.data.weekTotal}
											title={t('plan.actionStepsHistory.completedThisWeek')}
										/>
										<ProgressCard
											count={completedActionStepsQuery.data.pagination.total}
											title={t('plan.actionStepsHistory.completedTotal')}
											isTotalCompleted
										/>
									</Blocks>
								</View>
							) : null
						}
						ListFooterComponent={
							actionStepsInfiniteQuery.hasNextPage ? (
								<Button
									title={t('plan.actionStepsHistory.viewMoreButton')}
									variant="secondary"
									style={[vrTop(6), { alignSelf: 'center' }]}
									onPress={() => actionStepsInfiniteQuery.fetchNextPage()}
									size="sm"
								/>
							) : null
						}
						renderItem={({ item: actionStep, index }) => {
							const isNextMonth =
								index > 0 &&
								!isSameMonth(
									new Date(actionStep.endDate),
									new Date(actionSteps[index - 1].endDate)
								);

							return (
								<View style={wrapperHorizontal}>
									{(index === 0 || isNextMonth) && (
										<Text weight="semiBold" style={vr(2)}>
											{format(new Date(actionStep.endDate), 'MMMM yyyy')}
										</Text>
									)}
									<ActionStepCard actionStepData={actionStep} isComplete />
								</View>
							);
						}}
					/>
				)}
			</DeferredLoadingIndicator>
		</View>
	);
}

export function useActionStepsHistoryScreenOptions() {
	const { t } = useTranslation();
	const subScreenStyleProps = useSubScreenStyleProps();

	return (): StackNavigationOptions => ({
		title: t('plan.actionStepsHistory.screenTitle'),
		headerRight: () => <ChatButton />,
		...subScreenStyleProps,
	});
}
