// Firebase options
export default {
	apiKey: 'AIzaSyAxyhGx6AAGt9b8G8X3ZqwI5M5h42iw3hA',
	authDomain: 'mobe-digital-prod.firebaseapp.com',
	projectId: 'mobe-digital-prod',
	storageBucket: 'mobe-digital-prod.appspot.com',
	messagingSenderId: '4321188558',
	appId: '1:4321188558:web:82203725c61390ddda8e9f',
	measurementId: 'G-4C2MK3M433',
};
