import firebaseWebOptions from '@mobe/env/firebaseWebOptions';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/remote-config';

const app = firebase.initializeApp(firebaseWebOptions);

export const analytics = firebase.analytics();
export const performance = firebase.performance();
export const remoteConfig = firebase.remoteConfig();

export default app;
