import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import VrArray from '@mobe/components/layout/VrArray';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, View, ViewStyle } from 'react-native';
import { GuideApptCard } from '../../appointments/components/GuideApptCard';
import useCanScheduleAppointment from '../../appointments/useCanScheduleAppointment';
import { HomeScreenNavigationProp } from '../../navigation/bottomTabs/types';
import * as HomeAnalytics from '../analyticsEvents';
import AppointmentCard from './AppointmentCard';
import ScheduleCallButton from './ScheduleCallButton';

export default function CallsModule({ style }: { style?: StyleProp<ViewStyle> }) {
	const navigation = useNavigation<HomeScreenNavigationProp>();
	const { t } = useTranslation();
	const { vr, vrTop } = useStyleHelpers();
	const appointmentsQuery = useAppointmentsQuery();
	const futureAppointments = appointmentsQuery.data?.futureAppointments || [];
	const { canScheduleGuide, canScheduleAppt } = useCanScheduleAppointment();
	const { scheduleCallVariant } = useRemoteConfigData();

	const guide = useGuideQuery().data;
	const pharmacist = usePharmacistQuery().data;

	const guideAppointment = futureAppointments.find((appt) => appt.isGuide);
	const pharmacistAppointment = futureAppointments.find((appt) => appt.isPharmacist);

	const shouldShowScheduleButton = canScheduleAppt && !(guideAppointment && pharmacistAppointment);

	function handleSchedulePress() {
		const coachType = canScheduleGuide ? CoachType.GSM : CoachType.Pharmacist;
		GlobalAnalyticsEvents.startGuideSchedulingPress();
		HomeAnalytics.scheduleCallCtaPress();
		navigation.navigate('APPOINTMENT_SCREEN', { coachType });
	}

	function handleApptCardPress(confirmationId: string) {
		navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', { confirmationId });
	}

	return (
		<View style={style}>
			<ModuleHeading
				title={t('home.upcomingCallsTitle')}
				count={futureAppointments.length}
				buttonTitle={t('home.upcomingCallsViewAll')}
				onButtonPress={() => navigation.navigate('CALLS_SCREEN')}
				showButton={futureAppointments.length > 0}
				style={vr(5)}
			/>
			{scheduleCallVariant ? (
				<VrArray>
					{guideAppointment && (
						<GuideApptCard
							appointment={guideAppointment}
							onPress={() => handleApptCardPress(guideAppointment.confirmationId)}
						/>
					)}
					{pharmacistAppointment && (
						<GuideApptCard
							appointment={pharmacistAppointment}
							onPress={() => handleApptCardPress(pharmacistAppointment.confirmationId)}
						/>
					)}
					{shouldShowScheduleButton && <ScheduleCallButton onPress={handleSchedulePress} />}
				</VrArray>
			) : (
				<>
					<AppointmentCard guide={guide} appointment={guideAppointment} />
					<AppointmentCard
						guide={pharmacist}
						appointment={pharmacistAppointment}
						style={vrTop(guide || guideAppointment ? 5 : 0)}
					/>
				</>
			)}
		</View>
	);
}
