import * as React from 'react';
import { Platform } from 'react-native';
import Text, { TextProps } from '../text/Text';
import useHeadingStyles, { HeadingColors, HeadingLevels } from './headingStyles';

type Props = {
	/**
	 * Sets heading style
	 */
	level?: HeadingLevels;

	/**
	 * Sets heading semantic level
	 */
	semanticLevel?: 1 | 2 | 3 | 4 | 5 | 6;

	color?: HeadingColors;
};

export type HeadingProps = Props & TextProps;

export default function Heading({
	level = 'h3',
	semanticLevel = 2,
	color,
	style,
	role = 'heading',
	...textProps
}: HeadingProps) {
	const headingStyles = useHeadingStyles({ level, color });
	const webProps =
		Platform.OS === 'web'
			? {
					['aria-level']: semanticLevel,
			  }
			: {};

	return <Text role={role} style={[headingStyles, style]} {...textProps} {...webProps}></Text>;
}
