import { useFocusEffect } from '@react-navigation/native';
import * as Localization from 'expo-localization';
import * as React from 'react';

export default function useLocalizationSettings() {
	const [localizationSettings, setLocalizationSettings] = React.useState<Localization.Localization>(
		{ ...Localization }
	);

	useFocusEffect(
		React.useCallback(() => {
			async function initLocalizationSettings() {
				const localSettingsAsync = await Localization.getLocalizationAsync();

				if (!localSettingsAsync) {
					return;
				}

				setLocalizationSettings(localSettingsAsync);
			}

			initLocalizationSettings();
		}, [])
	);

	return {
		...localizationSettings,

		/**
		 * Remove the _ from the usual timezone value returned by localization settings which can sometimes have _  i.e. America/New_York
		 * @example
		 * `America/New York`
		 */
		timezoneDisplayLabel: localizationSettings.timezone.replace('_', ' '),
	};
}
