import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Ellipse, G, Path, Svg, SvgProps } from 'react-native-svg';

export default function HeaderGraphic() {
	const originalWidth = 404;
	const originalHeight = 91;
	const aspectRatio = originalWidth / originalHeight;
	const colorScheme = useColorScheme();
	const grayColor = colorScheme === 'dark' ? '#555555' : '#EBEBEB';

	const styles = StyleSheet.create({
		wrapper: {
			width: '100%',
			maxHeight: '100%',
			aspectRatio,
			paddingHorizontal: 8,
			paddingTop: 4,

			...(Platform.OS === 'web' && {
				height: '100%',
				width: undefined,
				maxHeight: undefined,
			}),
		},
	});

	const svgProps: SvgProps = {
		viewBox: `0 0 ${originalWidth} ${originalHeight}`,
		height: '100%',

		...(Platform.OS !== 'web' && {
			width: '100%',
		}),
	};

	return (
		<View style={styles.wrapper}>
			<Svg {...svgProps}>
				<G transform="translate(.50985 .96448)" fillRule="nonzero" fill="none">
					<Ellipse fill={grayColor} cx="393.04631" cy="77.59654" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="393.03755" cy="44.08216" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="358.21208" cy="44.36213" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="288.55346" cy="44.37197" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="288.55456" cy="78.00337" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="253.71923" cy="77.51999" rx="5.76373" ry="5.75688" />
					<Ellipse
						fill={grayColor}
						transform="rotate(-80.783 253.72019 44.02266)"
						cx="253.72019"
						cy="44.02266"
						rx="9.89877"
						ry="9.90995"
					/>
					<Ellipse fill={grayColor} cx="218.88609" cy="44.37197" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="218.89595" cy="78.00337" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="184.06171" cy="44.02419" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="149.22638" cy="44.37197" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="149.22748" cy="78.00337" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="114.402" cy="77.51999" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="79.56886" cy="78.00337" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="44.74339" cy="77.51999" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="9.91025" cy="78.01321" rx="9.91025" ry="9.89847" />
					<Path
						d="M184.37158 85.68067c-3.93065 0-7.11707-3.18263-7.11707-7.10861 0-3.92598 3.18642-7.10861 7.11707-7.10861s7.11707 3.18263 7.11707 7.10861c-.00783 3.92273-3.18967 7.1008-7.11707 7.10861zm0-11.26442c-2.29792 0-4.16075 1.86062-4.16075 4.15581s1.86283 4.1558 4.16075 4.1558c2.29792 0 4.16075-1.86061 4.16075-4.1558-.00602-2.2927-1.86533-4.1498-4.16075-4.1558z"
						fill="#FEC14B"
					/>
					<Path
						d="M80.07253 51.81742c-3.93065 0-7.11707-3.18263-7.11707-7.10861 0-3.92598 3.18642-7.10862 7.11707-7.10862s7.11707 3.18264 7.11707 7.10862c-.00783 3.92273-3.18967 7.1008-7.11707 7.10861zm0-11.27207c-2.29792 0-4.16075 1.86062-4.16075 4.1558 0 2.2952 1.86283 4.1558 4.16075 4.1558 2.29792 0 4.16075-1.8606 4.16075-4.1558-.003-2.29483-1.8632-4.15525-4.16075-4.16127v.00547z"
						fill="#28AA91"
					/>
					<Path
						d="M323.8629 85.03762c-3.93065 0-7.11707-3.18264-7.11707-7.10862s3.18642-7.10861 7.11707-7.10861 7.11707 3.18263 7.11707 7.10861c-.00422 3.92423-3.18817 7.1044-7.11707 7.10862zm0-11.27208c-2.29792 0-4.16075 1.86062-4.16075 4.1558 0 2.2952 1.86283 4.15581 4.16075 4.15581 2.29792 0 4.16075-1.86061 4.16075-4.1558-.00602-2.2927-1.86533-4.1498-4.16075-4.1558zM323.8629 55.88574c-6.43868-.00663-11.6566-5.21836-11.66324-11.64938.00664-6.43103 5.22456-11.64275 11.66324-11.64938 4.21996-.08169 8.15484 2.12021 10.28878 5.75746 2.13395 3.63724 2.13395 8.14222 0 11.77947-2.13394 3.63724-6.06882 5.83914-10.28878 5.75745v.00438zm-7.72148-11.64938c-.00265 3.12184 1.87792 5.93783 4.76467 7.13465 2.88674 1.19682 6.21106.53874 8.42257-1.66732 2.21152-2.20607 2.87462-5.5256 1.68005-8.41044-1.19456-2.88484-4.0115-4.76677-7.13705-4.7681-4.26395.00361-7.72119 3.45234-7.73024 7.71121z"
						fill="#43B4E4"
					/>
					<Path
						d="M114.62537 55.2569c-6.43868-.00663-11.6566-5.21835-11.66324-11.64938.00664-6.43102 5.22456-11.64275 11.66324-11.64938 4.21996-.08169 8.15484 2.12022 10.28878 5.75746 2.13394 3.63724 2.13394 8.14223 0 11.77947-2.13394 3.63724-6.06882 5.83914-10.28878 5.75745v.00438zm0-19.35732c-2.04873-.00377-4.01464.80745-5.46332 2.2544-1.44867 1.44695-2.26085 3.41053-2.25708 5.45682 0 4.25878 3.45655 7.7112 7.7204 7.7112s7.72038-3.45242 7.72038-7.7112-3.45653-7.71122-7.72038-7.71122zM9.68688 51.81633c-1.88696 0-3.69654-.74915-5.0302-2.08246-1.33367-1.33332-2.08204-3.14144-2.0803-5.02615 0-3.9275 3.18765-7.11135 7.1198-7.11135 3.93217 0 7.11989 3.18386 7.11989 7.11135.00845 1.88483-.74108 3.69416-2.08045 5.02196-1.3393 1.33432-3.1549 2.08188-5.04655 2.0779l-.00219.00875zm-4.16075-7.10861c.00362 2.29368 1.86433 4.15219 4.16075 4.1558 2.29792 0 4.16075-1.86062 4.16075-4.1558 0-2.2952-1.86283-4.15581-4.16075-4.15581-2.29792 0-4.16075 1.86062-4.16075 4.1558zM44.23972 55.2569c-6.43868-.00663-11.6566-5.21835-11.66324-11.64938 0-6.43377 5.22181-11.64938 11.66324-11.64938s11.66324 5.2156 11.66324 11.64938c0 6.43377-5.22181 11.64938-11.66324 11.64938zm-7.72038-11.64938c0 4.25878 3.45653 7.7112 7.72038 7.7112s7.72038-3.45242 7.72038-7.7112-3.45653-7.7112-7.72038-7.7112c-4.2606.00782-7.71255 3.45566-7.72038 7.7112z"
						fill="#28AA91"
					/>
					<Path
						d="M358.41573 89.51714c-4.21995.08169-8.15483-2.12021-10.28878-5.75746-2.13394-3.63724-2.13394-8.14222 0-11.77947 2.13394-3.63724 6.06883-5.83914 10.28878-5.75745 6.35215.12296 11.43724 5.3014 11.43724 11.64719s-5.08509 11.52422-11.43724 11.64719zm.00986-19.3573c-3.67504.00717-6.83362 2.60536-7.54524 6.20657-.71162 3.6012 1.22178 7.2032 4.6185 8.60447 3.39672 1.40127 7.31147.21183 9.35155-2.84134 2.04008-3.05316 1.63775-7.12036-.96109-9.71573-1.44973-1.44596-3.41494-2.25786-5.46372-2.25726v.00329z"
						fill="#FEC14B"
					/>
					<Ellipse fill={grayColor} cx="393.03755" cy="10.56559" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="358.21317" cy="10.87618" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="288.55456" cy="10.87618" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="184.06062" cy="10.50763" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="149.22748" cy="10.88493" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="114.402" cy="10.50763" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="79.56886" cy="10.88493" rx="9.91025" ry="9.89847" />
					<Ellipse fill={grayColor} cx="44.73353" cy="10.51747" rx="5.76373" ry="5.75688" />
					<Ellipse fill={grayColor} cx="9.91025" cy="10.88493" rx="9.91025" ry="9.89847" />
					<Path
						d="M323.8629 18.5666c-3.9274-.00782-7.10924-3.18587-7.11707-7.1086 0-3.92236 3.18348-7.10206 7.1105-7.10206 3.93065-.00272 7.11928 3.17771 7.122 7.1037.00272 3.92597-3.1815 7.11081-7.11215 7.11353l-.00328-.00656zm0-11.26441c-2.29642.00361-4.15713 1.86211-4.16075 4.1558 0 2.29519 1.86283 4.1558 4.16075 4.1558 2.29792 0 4.16075-1.86061 4.16075-4.1558s-1.86283-4.1558-4.16075-4.1558z"
						fill="#43B4E4"
					/>
					<Path
						d="M252.8389 18.5666c-3.93065 0-7.11707-3.18263-7.11707-7.1086 0-3.92599 3.18642-7.10862 7.11707-7.10862s7.11707 3.18263 7.11707 7.10861c-.00783 3.92274-3.18967 7.1008-7.11707 7.10862zm0-11.26441c-2.29792 0-4.16075 1.86061-4.16075 4.1558s1.86283 4.1558 4.16075 4.1558c2.29792 0 4.16075-1.86061 4.16075-4.1558-.00722-2.29184-1.86618-4.14761-4.16075-4.15362V7.3022zM217.64334 23.29876c-6.43868-.00663-11.6566-5.21836-11.66324-11.64938C205.9801 5.2156 211.20191 0 217.64334 0s11.66324 5.2156 11.66324 11.64938c0 6.43377-5.22181 11.64938-11.66324 11.64938zm-7.72038-11.64938c0 4.25878 3.45653 7.7112 7.72038 7.7112s7.72038-3.45242 7.72038-7.7112-3.45653-7.7112-7.72038-7.7112c-4.2606.00782-7.71255 3.45566-7.72038 7.7112z"
						fill="#F04C31"
					/>
				</G>
			</Svg>
		</View>
	);
}
