import { yupResolver } from '@hookform/resolvers/yup';
import { useChangeEmail } from '@mobe/api/account/accountApiHooks';
import { ChangeEmailErrorCode } from '@mobe/api/account/accountService';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useAlert } from '@mobe/utils/useAlert';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import * as React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ISettingsEditEmailScreenProps } from './SettingsEditEmailScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		newEmail: Yup.string().label(t('settings.editEmail.newEmailInputLabel')).email().required(),
		verifyEmail: Yup.string()
			.label(t('settings.editEmail.verifyEmailInputLabel'))
			.required()
			.email()
			.oneOf(
				[Yup.ref('newEmail'), null],
				t('settings.editEmail.errors.verificationMismatch', {
					newEmailInputLabel: t('settings.editEmail.newEmailInputLabel'),
					verifyEmailInputLabel: t('settings.editEmail.verifyEmailInputLabel'),
				})
			),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export interface ISettingsEditEmailScreenController {
	form: UseFormReturn<FormSchema>;
	isFetching: boolean;
	errorMessage: string;
	handleSubmitPress: () => void;
}

export default function useSettingsEditEmailScreenController({
	navigation,
	route,
}: ISettingsEditEmailScreenProps): ISettingsEditEmailScreenController {
	const persistentState = usePersistentState();
	const auth = useAuth();
	const changeEmail = useChangeEmail();
	const validationSchema = useValidationSchema();
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			newEmail: '',
			verifyEmail: '',
		},
	});

	const [errorCode, setErrorCode] = React.useState(ChangeEmailErrorCode.None);

	let errorMessage = '';
	if (errorCode !== ChangeEmailErrorCode.None) {
		switch (errorCode) {
			case ChangeEmailErrorCode.EmailAlreadyUsed:
				errorMessage = t('settings.editEmail.errors.emailAlreadyUsed');
				break;
			case ChangeEmailErrorCode.UserDoesNotExist:
				errorMessage = t('settings.editEmail.errors.userDoesNotExist');
				break;
			case ChangeEmailErrorCode.IdenticalEmailProvided:
				errorMessage = t('settings.editEmail.errors.identicalEmailProvided');
				break;
			default:
				errorMessage = t('settings.editEmail.errors.default');
				break;
		}
	}

	async function performChangeEmail({ newEmail, verifyEmail }: FormSchema) {
		const response = await changeEmail.execute(newEmail);

		if (response.success) {
			// since the user desires to change their email, clear previously saved credentials and bio auth state
			persistentState.resetSavedLoginCredentials();

			mobeAlert('', t('settings.editEmail.submissionAlertBody'), [
				{
					text: t('settings.editEmail.submissionAlertConfirmButton'),
					onPress: () => auth.logout(),
				},
			]);
		} else {
			setErrorCode(response.errorCode);
		}
	}

	function handleSubmitPress() {
		form.handleSubmit(performChangeEmail)();
	}

	return {
		form,
		isFetching: changeEmail.isPending,
		errorMessage,
		handleSubmitPress,
	};
}
