import { useAssessmentMutation, useAssessmentQuery } from '@mobe/api/assessment/AssessmentApiHooks';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import Heading from '@mobe/components/heading/Heading';
import InputTextArea from '@mobe/components/input/InputTextArea';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	OnboardingNotesScreenNavigationProp,
	OnboardingNotesScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';

const MAX_LENGTH = 1000;

interface IOnboardingNotesScreenProps {
	navigation: OnboardingNotesScreenNavigationProp;
	route: OnboardingNotesScreenRouteProp;
}

export default function OnboardingNotesScreen({ route }: IOnboardingNotesScreenProps) {
	const assessmentMutation = useAssessmentMutation();
	const assessmentQuery = useAssessmentQuery();
	const [value, setValue] = React.useState(assessmentQuery.data?.additionalNotes || '');
	const { vr, constrainText, constrain } = useStyleHelpers();
	const { t } = useTranslation();

	async function mutateNoteValue() {
		if (value === assessmentQuery.data?.additionalNotes) return;
		await assessmentMutation.mutateAsync({ additionalNotes: value });
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			isLoading={assessmentMutation.isLoading}
			beforeNavigation={mutateNoteValue}
		>
			<View
				style={{
					marginTop: 'auto',
					marginBottom: 'auto',
					paddingVertical: 12,
					paddingHorizontal: 8,
				}}
			>
				<Heading
					level="h3"
					align="center"
					style={[constrainText(350), vr(6)]}
					accessibilityAutoFocus
				>
					{t('onboarding.notesScreen.label')}
				</Heading>
				<View style={[constrain(500), { width: '100%' }]}>
					<FetchingIndicator isFetching={assessmentMutation.isLoading}>
						<InputTextArea
							value={value}
							onChangeText={(text) => setValue(text)}
							onBlur={mutateNoteValue}
							numberOfLines={9}
							maxLength={MAX_LENGTH}
							placeholder={t('onboarding.notesScreen.inputPlaceholder')}
						/>
					</FetchingIndicator>
					<Text color="light" align="right">{`${value.length} / ${MAX_LENGTH}`}</Text>
				</View>
			</View>
		</OnboardingScreenTemplate>
	);
}
