import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { addMinutes } from 'date-fns';
import * as Calendar from 'expo-calendar';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import { useAlert } from './useAlert';
import useLocalizationSettings from './useLocalizationSettings';

export default function useDeviceCalendar() {
	const profileQuery = useProfileQuery();
	const localizationSettings = useLocalizationSettings();
	const { mobeAlert } = useAlert();
	const { t } = useTranslation();

	function permissionsAlert() {
		mobeAlert(
			t('deviceCalendarPermissionsAlert.heading'),
			t('deviceCalendarPermissionsAlert.description'),
			[
				{
					text: t('deviceCalendarPermissionsAlert.cancel'),
					style: 'cancel',
					onPress: noop,
				},
				{
					text: t('deviceCalendarPermissionsAlert.updatePermissions'),
					onPress: () => Linking.openSettings(),
				},
			]
		);
	}

	function inaccessibleCalendarAlert() {
		mobeAlert(
			t('deviceCalendarUnavailableAlert.heading'),
			t('deviceCalendarUnavailableAlert.description'),
			[
				{
					text: t('deviceCalendarUnavailableAlert.confirm'),
					style: 'default',
					onPress: noop,
				},
			]
		);
	}

	async function getPermissionsStatus() {
		const { status } = await Calendar.getCalendarPermissionsAsync();

		return status;
	}

	async function requestCalendarPermissions() {
		const { status } = await Calendar.requestCalendarPermissionsAsync();

		if (status === 'denied') {
			permissionsAlert();
		}

		if (status !== 'granted') {
			return new Promise((resolve, reject) => reject('App lacks permission'));
		}
	}

	/**
	 * Existing MOBE app calendar or undefined.
	 */
	async function getCalendarID() {
		let existingCalendar: Calendar.Calendar | undefined = undefined;

		const permissionsStatus = await getPermissionsStatus();
		if (permissionsStatus === 'denied') {
			return;
		}

		if (Platform.OS === 'ios') {
			existingCalendar = await Calendar.getDefaultCalendarAsync();
		} else {
			existingCalendar = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT)
				.then((calendars) => {
					let maybeMatchedCalendar: Calendar.Calendar | undefined = undefined;

					// If calendar exists that matches participant's email, then default to that calendar
					maybeMatchedCalendar = calendars.find(
						(calendar) =>
							calendar.title.toLowerCase() === profileQuery.data?.email.toLowerCase() &&
							calendar.accessLevel === Calendar.CalendarAccessLevel.OWNER
					);

					if (maybeMatchedCalendar) {
						return maybeMatchedCalendar;
					}

					// Else see if any calendar is marked as primary (unsure if this is ever true)
					maybeMatchedCalendar = calendars.find((calendar) => calendar.isPrimary);

					if (maybeMatchedCalendar) {
						return maybeMatchedCalendar;
					}

					// Finally, grab first owned calendar if nothing else worked
					return calendars.find(
						(calendar) => calendar.accessLevel === Calendar.CalendarAccessLevel.OWNER
					);
				})
				.catch(() => undefined);
		}

		return existingCalendar?.id;
	}

	async function addEventToCalendar({
		startDate,
		duration = 30,
		title,
		notes,
	}: {
		startDate: Date;
		duration: number;
		title: string;
		notes?: string;
	}) {
		await requestCalendarPermissions();
		const calendarID = await getCalendarID();

		if (calendarID === undefined) {
			inaccessibleCalendarAlert();
			throw new Error('No calendar found');
		}

		return Calendar.createEventAsync(calendarID, {
			title,
			startDate,
			notes,
			endDate: addMinutes(startDate, duration),
			timeZone: localizationSettings.timezone,
			endTimeZone: localizationSettings.timezone,
			accessLevel: Calendar.EventAccessLevel.DEFAULT,
			// alarms: [
			// 	{
			// 		absoluteDate:
			// 			Platform.OS === 'ios'
			// 				? subMinutes(startDate, 30).toISOString()
			// 				: startDate.toISOString(),
			// 		relativeOffset: -30,
			// 		method: Calendar.AlarmMethod.ALERT,
			// 	},
			// ],
			guestsCanModify: false,
			guestsCanInviteOthers: false,
			creationDate: new Date(Date.now()),
		});
	}

	async function hasExistingEvents(startDate: Date, duration: number, title: string) {
		await requestCalendarPermissions();
		const calendarID = await getCalendarID();

		if (calendarID === undefined) {
			return false;
		}
		const events = await getExistingEvents(startDate, duration);

		return events.some((event) => event.title === title);
	}

	async function getExistingEvents(startDate: Date, duration: number) {
		const calendarID = await getCalendarID();

		if (calendarID === undefined) {
			return [];
		}

		const platformDuration = Platform.select({
			// https://docs.expo.io/versions/latest/sdk/calendar/#calendargeteventsasynccalendarids-startdate-enddate
			// on iOS, all events that overlap at all with the [startDate, endDate] interval are returned
			// duration - 1 so that if there is a subsequent event it is not picked up
			ios: duration - 1,

			// on Android, only events that begin on or after the startDate and end on or before the endDate will be returned.
			android: duration,
			default: duration,
		});

		const endDate = addMinutes(startDate, platformDuration);

		const events = await Calendar.getEventsAsync([calendarID], startDate, endDate);

		return events;
	}

	async function deleteEventFromCalendar(eventId: string) {
		const calendarID = await getCalendarID();
		if (calendarID === undefined) {
			return;
		}

		return new Promise((resolve, reject) => {
			Calendar.deleteEventAsync(eventId)
				.then((success) => resolve(success))
				.catch((error) => reject(error));
		});
	}

	return {
		addEventToCalendar,
		deleteEventFromCalendar,
		hasExistingEvents,
		getExistingEvents,
		getPermissionsStatus,
	};
}
