import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export interface IMedication {
	/** Date */
	reviewDate: string | null;
	reviewPdfLink: string;
}

export interface IProgress {
	callsWithGuide: number;
	/**
	 * Date
	 */
	lastGuideCall: string | null;
	callsWithPharmacist: number;
	/**
	 * Date
	 */
	lastPharmacistCall: string | null;
	activitiesThisWeek: number;
	activitiesAllTime: number;
	trackingEntries: number;
	goals: number;
	medications: IMedication[];
}

class ProgressService {
	public getProgress = async () => {
		try {
			const data = await mobeAuthenticatedAPI.get<IProgress>('progress');

			const processedData: IProgress = {
				activitiesAllTime: data.activitiesAllTime || 0,
				activitiesThisWeek: data.activitiesThisWeek || 0,
				callsWithGuide: data.callsWithGuide || 0,
				callsWithPharmacist: data.callsWithPharmacist || 0,
				goals: data.goals || 0,
				lastGuideCall: data.lastGuideCall || null,
				lastPharmacistCall: data.lastPharmacistCall || null,
				medications: data.medications || [],
				trackingEntries: data.trackingEntries || 0,
			};

			return mobeStandardSuccessProcessor<IProgress>(processedData);
		} catch (error) {
			return mobeStandardErrorProcessor<IProgress>(error);
		}
	};
}

const progressService = new ProgressService();
export default progressService;
