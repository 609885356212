import {
	useOptimisticallyUpdateActionStep,
	useSetSubStepOptions,
	useSetSubStepStatusMutation,
} from '@mobe/api/goals/goalsApiHooks';
import { IActionStep } from '@mobe/api/goals/goalsService';
import { Button } from '@mobe/components/button';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import { useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, StyleProp, View, ViewStyle } from 'react-native';
import { BottomTabScreenNavigationProp } from '../../navigation/bottomTabs/types';
import ActionStepCard from './ActionStepCard';
import ConnectBox from './ConnectBox';
import ActionStepsGraphic from './graphics/ActionStepsGraphic';
import ActionStepsSimpleGraphic from './graphics/ActionStepsSimpleGraphic';

interface IActionStepsModuleProps {
	actionSteps: IActionStep[];
	hasCompletedActionSteps: boolean;
	style?: StyleProp<ViewStyle>;
	headerButtonType?: 'viewHistory' | 'viewAll';
	count?: number;
	onShowMorePress?: () => void;
	onComplete?: () => void;
	onCardPress?: (event: GestureResponderEvent) => void;
	total?: number;
}

export default function ActionStepsModule({
	actionSteps,
	hasCompletedActionSteps,
	style,
	headerButtonType = 'viewHistory',
	count,
	onShowMorePress = noop,
	onComplete = noop,
	onCardPress = noop,
	total = 0,
}: IActionStepsModuleProps) {
	const { vr, vrTop, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const { isLargeDisplay } = useLayout();
	const navigation = useNavigation<BottomTabScreenNavigationProp>();
	const setSubStepStatusMutation = useSetSubStepStatusMutation();
	const optimisticallyUpdateActionStep = useOptimisticallyUpdateActionStep();
	const setSubStepOptions = useSetSubStepOptions();
	const visualState = actionSteps.length
		? 'hasActiveActions'
		: hasCompletedActionSteps
		? 'allActionsCompleted'
		: 'noActions';

	const initialActionStepCount = React.useRef(actionSteps.length);
	const [focusTargetRef, setFocus] = useAccessibilityFocus();

	function handleHeadingButtonPress() {
		if (headerButtonType === 'viewHistory') {
			navigation.navigate('ACTION_STEPS_HISTORY_SCREEN');
		}

		if (headerButtonType === 'viewAll') {
			navigation.navigate('PLAN_SCREEN', { scrollToModule: 'actionSteps' });
		}
	}

	function handleCardPress(actionStepId: number, event: GestureResponderEvent) {
		onCardPress(event);
		navigation.navigate('ACTION_STEP_DETAIL_SCREEN', { actionStepId });
	}

	function handleCardCompletePress(actionStep: IActionStep) {
		optimisticallyUpdateActionStep({
			id: actionStep.id,
			subStepsComplete: actionStep.subStepsComplete + 1,
		});

		setSubStepStatusMutation.mutate(
			{ actionStepId: actionStep.id, status: 'Completed' },
			setSubStepOptions({
				actionStep,
				onSuccess: onComplete,
			})
		);
	}

	return (
		<View style={style}>
			<ModuleHeading
				title={t('plan.actionStepsHeading')}
				buttonTitle={
					headerButtonType === 'viewHistory' ? t('plan.viewHistoryButton') : t('plan.viewAllButton')
				}
				onButtonPress={handleHeadingButtonPress}
				showButton={
					(headerButtonType === 'viewHistory' && hasCompletedActionSteps) ||
					(headerButtonType === 'viewAll' && actionSteps.length > 0)
				}
				style={vr(visualState !== 'noActions' ? 2 : 5)}
				count={count}
			/>
			{visualState !== 'noActions' && <Text style={vr(5)}>{t('plan.actionStepsSubHeading')}</Text>}
			{visualState === 'hasActiveActions' && (
				<>
					<VrArray increment={4}>
						{actionSteps.map((actionStep, index) => (
							<View key={actionStep.id}>
								<ActionStepCard
									key={actionStep.id}
									actionStepData={actionStep}
									onPress={(event) => handleCardPress(actionStep.id, event)}
									onCompletePress={() => handleCardCompletePress(actionStep)}
								/>
								{index === initialActionStepCount.current - 1 && (
									<View ref={focusTargetRef} accessible focusable />
								)}
							</View>
						))}
					</VrArray>
					{total > actionSteps.length && (
						<Button
							title={t('plan.viewAllButton')}
							variant="secondary"
							style={[vrTop(6), { alignSelf: 'center' }]}
							onPress={() => {
								setFocus();
								onShowMorePress();
							}}
							size="sm"
						/>
					)}
				</>
			)}
			{visualState === 'allActionsCompleted' && (
				<ConnectBox
					title={t('plan.connectBox.actionStep')}
					graphic={<ActionStepsSimpleGraphic />}
				/>
			)}
			{visualState === 'noActions' && (
				<Row>
					<Row.Item>
						<View style={constrain(isLargeDisplay ? 100 : 70)}>{<ActionStepsGraphic />}</View>
					</Row.Item>
					<Row.Item fill>
						<Text
							color="light"
							style={[!isLargeDisplay && constrainText(210), { alignSelf: 'flex-start' }]}
						>
							{t('plan.actionStepsDescription')}
						</Text>
					</Row.Item>
				</Row>
			)}
		</View>
	);
}
