import { CoachType } from '@mobe/api/guides/guidesApi';
import { useTranslation } from 'react-i18next';

export default function useGuidePhoneNumber(coachType?: CoachType) {
	const { t } = useTranslation();

	if (!coachType) {
		return '';
	}

	let phoneNumber: string;

	switch (coachType) {
		case CoachType.GSM:
			phoneNumber = t('guideApptPhoneNumberFormatted');
			break;
		case CoachType.MSK:
		case CoachType.Spine:
			phoneNumber = t('mskApptPhoneNumberFormatted');
			break;
		case CoachType.Pharmacist:
			phoneNumber = t('pharmacistApptNumberFormatted');
			break;
		default:
			phoneNumber = '';
			break;
	}

	return phoneNumber;
}
