import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { noop } from 'lodash';
import * as React from 'react';
import BaseInputLabel from './BaseInputLabel';

type InputSelectItemValue = string | number;

export interface IInputSelectItem<T extends InputSelectItemValue> {
	label: string;
	value: T;
}

export interface IInputSelectProps<T extends InputSelectItemValue> {
	label?: string;
	value: T;
	items: IInputSelectItem<T>[];
	onSelect?: (value: T) => void;
}

export default function InputSelect<T extends InputSelectItemValue>({
	label,
	value,
	items,
	onSelect = noop,
}: IInputSelectProps<T>) {
	const styleRules = useStyleRules();
	const { vr } = useStyleHelpers();

	return (
		<>
			{Boolean(label) && <BaseInputLabel style={vr(1)}>{label}</BaseInputLabel>}
			<select
				value={value}
				onChange={(event) => {
					onSelect(event.target.value as typeof value);
				}}
				style={{
					flexGrow: 1,
					flexShrink: 1,
					height: 44 * styleRules.settings.fontScale,
					paddingLeft: 10,
					paddingRight: 10,
					fontSize: styleRules.fontSizes.lg,
					color: styleRules.colors.text,
					borderRadius: 5,
					borderColor: styleRules.colors.stroke,
					backgroundColor: styleRules.colors.fieldBackground,
				}}
			>
				{items.map((item, index) => (
					<option key={`${item.value}-${index}`} value={item.value}>
						{item.label}
					</option>
				))}
			</select>
		</>
	);
}
