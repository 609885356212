import useColorScheme from '@mobe/utils/useColorScheme';
import { useTranslation } from 'react-i18next';

export default function useLocalTimeGreeting() {
	const { t } = useTranslation();
	const now = new Date(Date.now());
	const currentHour = now.getHours();
	const colorScheme = useColorScheme();

	const localTimeText = (name: string) => {
		let greeting = t('home.welcome', { name });

		if (currentHour >= 2 && currentHour < 12) {
			greeting = t('home.morningGreeting', { name });
		} else if (currentHour >= 12 && currentHour < 17) {
			greeting = t('home.afternoonGreeting', { name });
		} else {
			greeting = t('home.eveningGreeting', { name });
		}
		return greeting;
	};

	const localTimeForGraphic = () => {
		if (colorScheme === 'dark') {
			return 'evening';
		}

		if (currentHour >= 2 && currentHour < 12) {
			return 'morning';
		} else if (currentHour >= 12 && currentHour < 19) {
			return 'day';
		} else {
			return 'evening';
		}
	};

	return { localTimeText, localTimeForGraphic };
}
