import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import MobeImage from '../mobeImage/MobeImage';
import { MobeImageSource } from '../mobeImage/utils';
import Text from '../text/Text';

interface IAvatarProps {
	letter: string;
	imageSrc?: MobeImageSource;
	backgroundColor?: 'primary' | 'tertiary';
	size?: number;
}

export default function Avatar({
	letter,
	imageSrc,
	backgroundColor = 'primary',
	size = 40,
}: IAvatarProps) {
	const rules = useStyleRules();

	const styles = StyleSheet.create({
		circle: {
			width: size,
			height: size,
			aspectRatio: 1,
			backgroundColor: {
				primary: rules.colors.primary,
				tertiary: rules.colors.success,
			}[backgroundColor],
			borderRadius: size / 2,
			alignItems: 'center',
			justifyContent: 'center',
		},
		imageCircleMask: {
			height: '100%',
			width: '100%',
			overflow: 'hidden',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: size / 2,
			backgroundColor: rules.colors.cardBackground,
		},
		image: {
			height: '100%',
			width: '100%',
		},
		text: {
			fontSize: 30,
			lineHeight: 30,
			marginBottom: Platform.OS !== 'web' ? -6 : 0,
			marginRight: -1,
		},
	});

	return (
		<View style={styles.circle}>
			{imageSrc ? (
				<View style={styles.imageCircleMask}>
					{imageSrc && (
						<MobeImage
							source={imageSrc}
							renderWidth={size}
							style={styles.image}
							resizeMode="cover"
						/>
					)}
				</View>
			) : (
				<Text weight="semiBold" color="inverted" style={styles.text}>
					{letter}
				</Text>
			)}
		</View>
	);
}
