export interface APIErrorData<TCode extends string | null = '', TData = null> {
	errorCode: TCode;
	errorData: TData;
}

export class APIResponse<SuccessDataType, ErrorCodeType extends string | null, ErrorDataType> {
	private _success: boolean;
	private _data: SuccessDataType;
	private _errorCode: ErrorCodeType;
	private _errorData: ErrorDataType;

	public static success<
		SuccessDataType = null,
		ErrorCodeType extends string | null = null,
		ErrorDataType = null
	>(data?: SuccessDataType) {
		const safeData = data || ({} as SuccessDataType);

		return new APIResponse<SuccessDataType, ErrorCodeType, ErrorDataType>(
			true,
			safeData,
			'NONE' as ErrorCodeType,
			{} as ErrorDataType
		);
	}

	public static error<
		SuccessDataType = null,
		ErrorCodeType extends string | null = null,
		ErrorDataType = null
	>(errorCode = 'UNKNOWN' as ErrorCodeType, errorData: ErrorDataType = {} as ErrorDataType) {
		return new APIResponse<SuccessDataType, ErrorCodeType, ErrorDataType>(
			false,
			{} as SuccessDataType,
			errorCode,
			errorData
		);
	}

	constructor(
		success: boolean,
		data: SuccessDataType,
		errorCode: ErrorCodeType,
		errorData: ErrorDataType
	) {
		this._success = success;

		this._data = data;

		this._errorCode = errorCode;
		this._errorData = errorData;
	}

	public get success() {
		return this._success;
	}

	public get data(): SuccessDataType {
		return this._data;
	}

	public get error() {
		const error: APIErrorData<ErrorCodeType, ErrorDataType> = {
			errorCode: this.errorCode,
			errorData: this.errorData,
		};

		return error;
	}

	public get errorCode(): ErrorCodeType {
		return this._errorCode;
	}

	public get errorData(): ErrorDataType {
		return this._errorData;
	}
}
