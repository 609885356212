import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import Color from 'color';
import * as React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Text from '../text/Text';

type CalloutType = 'default' | 'error';

interface IErrorProps extends ViewProps {
	type?: CalloutType;
	message?: string | undefined;
	children?: React.ReactNode;
}

export default function Callout({ type = 'default', message, children, style = {} }: IErrorProps) {
	const styles = useCalloutStyles(type);

	function renderMessage() {
		if (message) {
			if (type === 'error') {
				return <Text color="error">{message}</Text>;
			} else {
				return <Text>{message}</Text>;
			}
		}

		return children;
	}

	return (
		<View aria-live="assertive" style={[styles.container, style]}>
			{renderMessage()}
		</View>
	);
}

function useCalloutStyles(type: CalloutType) {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			padding: styleRules.spacing.appHorizontalMargin,
			marginBottom: styleRules.spacing.appHorizontalMargin,
			backgroundColor: {
				default: styleRules.colors.backgroundPrimary,
				error: Color(styleRules.colors.error).lighten(1.15).hex(),
			}[type],
		},
	});
}
