import { FieldValues, RefCallBack, useController, UseControllerProps } from 'react-hook-form';

export interface IControlledCalendarProps {
	ref: RefCallBack;
	value: Date;
	onChangeDate: (date: Date) => void;
	hasError: boolean;
}

export default function useControlledCalendarInputProps<
	TFieldValues extends FieldValues = FieldValues
>({ name, control }: UseControllerProps<TFieldValues>) {
	const { field, fieldState } = useController({ name, control });

	const inputProps: IControlledCalendarProps = {
		ref: field.ref,
		value: field.value,
		onChangeDate: field.onChange,
		hasError: Boolean(fieldState.error),
	};

	return inputProps;
}
