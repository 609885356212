import Analytics from '@mobe/utils/analytics';

export function connectHealthCtaPress(trackerAbbreviation: string) {
	Analytics.logEvent('validic_tracker_detail_cta_press', { trackerAbbreviation });
}

export function connectHealthCtaDismiss(trackerAbbreviation: string) {
	Analytics.logEvent('validic_tracker_detail_cta_dismiss', { trackerAbbreviation });
}

export function connectHealthDataManage(trackerAbbreviation: string) {
	Analytics.logEvent('validic_tracker_detail_manage', { trackerAbbreviation });
}
