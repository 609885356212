import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function STGoalGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(80, 68);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const background = isDarkTheme ? '#333333' : '#F1F1F1';
	const gray1 = isDarkTheme ? '#AAAAAA' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#666666' : '#D3D3D3';
	const gray3 = isDarkTheme ? '#CCCCCC' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={gray2}
					d="M27.226 61.144a.707.707 0 0 1-.273-.054c-.809-.324-4.037-3.499-5.388-4.849a.736.736 0 0 1 .525-1.249.74.74 0 0 1 .519.206c2.035 2.036 4.422 4.282 4.903 4.528a.74.74 0 0 1 .183 1.251.747.747 0 0 1-.469.167z"
				/>
				<Path
					fill={gray2}
					d="M21.937 60.99a.74.74 0 0 1-.668-.425.744.744 0 0 1 .103-.785c1.231-1.47 3.521-4.206 6.124-4.444a.737.737 0 0 1 .133 1.468c-1.997.182-4.033 2.615-5.127 3.921a.72.72 0 0 1-.253.195.701.701 0 0 1-.312.07z"
				/>
				<Path
					fill={background}
					d="M.959 37.843a11.802 11.802 0 0 0 6.493 8.976c4.202 2.011 9.197 1.332 13.66 2.676 5.784 1.737 10.004 6.603 15.133 9.806 6.107 3.812 13.379 5.17 20.464 6.46 4.703.855 9.69 1.684 14.146-.047 6.083-2.364 9.664-9.791 7.722-16.02C75.28 39.128 60.426 36.288 55.963 26.16c-2.306-5.245-1.493-11.851-5.208-16.219-4.397-5.18-12.503-4.377-19.112-2.786-5.027 1.201-10.01 2.59-15.001 3.962-4.035 1.108-8.256 2.319-11.256 5.236-3.708 3.605-2.218 6.431-2.19 10.665.035 3.734-2.752 6.739-2.237 10.825z"
				/>
				<Path
					fill={gray2}
					d="M59.007 6.87a6.547 6.547 0 0 1-10.221 5.414A6.548 6.548 0 0 1 54.642.687a6.546 6.546 0 0 1 4.365 6.183z"
				/>
				<Path
					fill={gray1}
					d="M57.835 6.873c0 .463-.059.924-.175 1.372a5.373 5.373 0 0 1-5.551 3.993A5.374 5.374 0 0 1 50.78 1.77a5.376 5.376 0 0 1 6.374 2.478c.447.803.682 1.706.681 2.625z"
				/>
				<Path
					fill={gray2}
					d="M52.468 10.976a4.104 4.104 0 0 0 4.098-4.109 4.103 4.103 0 0 0-4.098-4.109 4.104 4.104 0 0 0-4.098 4.109 4.104 4.104 0 0 0 4.098 4.109z"
				/>
				<Path
					fill={gray1}
					d="M52.467 9.705c1.565 0 2.833-1.272 2.833-2.841s-1.268-2.841-2.833-2.841c-1.565 0-2.833 1.272-2.833 2.841s1.268 2.841 2.833 2.841z"
				/>
				<Path
					fill={gray2}
					d="M52.466 8.346c.814 0 1.473-.662 1.473-1.478s-.66-1.478-1.473-1.478c-.814 0-1.473.662-1.473 1.478s.659 1.478 1.473 1.478zM37.618 19.662a.684.684 0 0 1-.257-.05c-.881-.352-4.472-3.889-5.975-5.391a.682.682 0 0 1-.149-.749.698.698 0 0 1 .372-.372.678.678 0 0 1 .526 0 .698.698 0 0 1 .223.149c2.312 2.312 5.008 4.847 5.52 5.089a.682.682 0 0 1 .424.772.686.686 0 0 1-.684.552z"
				/>
				<Path
					fill={gray2}
					d="M31.702 19.504a.687.687 0 0 1-.527-1.13c1.362-1.628 3.899-4.656 6.748-4.918a.688.688 0 1 1 .126 1.372c-2.288.209-4.586 2.955-5.822 4.431a.693.693 0 0 1-.525.245z"
				/>
				<Path
					fill={gray3}
					d="M41.929 9.468A.726.726 0 0 1 41.253 9a.726.726 0 0 1 .203-.796l1.788-1.551c-.738-.639-1.573-1.348-1.83-1.529a.717.717 0 0 1-.152-.994.716.716 0 0 1 .989-.178c.493.351 2.35 1.976 2.561 2.161a.72.72 0 0 1 0 1.086L42.4 9.291a.717.717 0 0 1-.471.177z"
				/>
				<Path
					fill={gray3}
					d="M24.029 67.688c-.43.001-.86-.022-1.288-.067a.72.72 0 0 1 .159-1.431c2.639.293 6.685-.508 8.989-2.57 1.183-1.058 1.782-2.341 1.782-3.812 0-2.298-2.118-4.003-4.36-5.809-2.604-2.097-5.555-4.473-4.918-8.142.711-4.091 5.429-5.296 9.991-6.46 3.971-1.013 7.722-1.971 8.245-4.62.845-4.275-3.271-6.596-7.63-9.053-4.097-2.309-8.333-4.697-8.452-8.973-.097-3.48.739-6.033 2.554-7.805 2.506-2.445 6.828-3.367 14.014-2.989a.724.724 0 0 1 .667.756.722.722 0 0 1-.742.683c-6.748-.356-10.739.44-12.932 2.58-1.512 1.475-2.206 3.678-2.121 6.734.096 3.461 3.799 5.548 7.72 7.758 2.235 1.258 4.548 2.563 6.176 4.174 1.926 1.913 2.635 4.015 2.16 6.416-.7 3.546-5.074 4.657-9.302 5.737-4.117 1.05-8.376 2.138-8.928 5.309-.493 2.834 1.885 4.749 4.401 6.774 2.402 1.944 4.896 3.944 4.896 6.931 0 1.899-.761 3.546-2.261 4.886-2.287 2.045-5.924 2.993-8.82 2.993z"
				/>
				<Path
					fill={gray2}
					d="M40.585 51.582a.748.748 0 0 1-.276-.053c-1.037-.415-5.543-4.872-6.899-6.229a.734.734 0 0 1 .02-1.022.74.74 0 0 1 1.023-.019c2.691 2.691 5.824 5.635 6.405 5.904a.734.734 0 0 1 .191 1.253.73.73 0 0 1-.469.167h.005z"
				/>
				<Path
					fill={gray2}
					d="M33.736 51.386a.737.737 0 0 1-.564-1.21c1.571-1.878 4.498-5.376 7.778-5.673a.748.748 0 0 1 .538.167.742.742 0 0 1 .232.786.75.75 0 0 1-.36.434.741.741 0 0 1-.276.082c-2.673.245-5.346 3.431-6.781 5.147a.74.74 0 0 1-.253.198.792.792 0 0 1-.314.069zM35.533 36.285a.743.743 0 0 1-.275-.053c-1.037-.415-5.543-4.872-6.899-6.228a.738.738 0 0 1 1.042-1.043c2.692 2.692 5.825 5.636 6.405 5.904a.733.733 0 0 1 .446.825.741.741 0 0 1-.726.595h.007z"
				/>
				<Path
					fill={gray2}
					d="M28.682 36.087a.735.735 0 0 1-.668-.426.74.74 0 0 1 .103-.784c1.572-1.878 4.498-5.376 7.777-5.673a.737.737 0 0 1 .133 1.468c-2.673.244-5.346 3.431-6.781 5.147a.726.726 0 0 1-.564.268z"
				/>
			</Svg>
		</View>
	);
}
