import { noop } from 'lodash';

// There is no web crashlytics module
const crashlytics = {
	crash: noop,
	log: noop,
	recordError: noop,
};

export default crashlytics;
