import { yupResolver } from '@hookform/resolvers/yup';
import {
	InputCalendar,
	InputText,
	useControlledCalendarInputProps,
	useControlledInputProps,
} from '@mobe/components/input';
import InputDualUnitNumeric from '@mobe/components/input/InputDualUnitNumeric';
import { Text } from '@mobe/components/text';
import { emptyStringToNull } from '@mobe/utils/i18n';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { ITrackerForm, IUseTrackerForm } from '../../useTrackerContent';

const GOAL_INPUT_MAX_LENGTH = 8;

export default function useIntegerForm({
	tracker,
	trackerEntry,
	isGoal,
	goal,
	onKeyboardSubmit: onSubmit = noop,
}: IUseTrackerForm): ITrackerForm {
	const { t } = useTranslation();
	const { vr, constrainText } = useStyleHelpers();

	// let trackerSchema: Yup.AnySchema = Yup.number().required().label(tracker.inputLabel);
	let trackerDefaultValue = null;

	const trackerSchema = Yup.number()
		.required()
		.label(tracker.inputLabel)
		.nullable()
		.transform(emptyStringToNull)
		.typeError(t('forms.errors.invalidType'));

	if (trackerEntry) {
		trackerDefaultValue = trackerEntry.value;
	}

	if (isGoal && typeof goal === 'number') {
		trackerDefaultValue = goal;
	}

	const validationSchema = Yup.object({
		date: Yup.date().required(),
		tracker: trackerSchema,
	});

	const form = useForm<Yup.InferType<typeof validationSchema>>({
		mode: 'all',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: trackerEntry ? new Date(trackerEntry.activityDateTime) : new Date(Date.now()),
			tracker: trackerDefaultValue ?? undefined,
		},
	});

	const dateInputProps = useControlledCalendarInputProps({
		name: 'date',
		control: form.control,
	});

	const trackerInputProps = useControlledInputProps({
		name: 'tracker',
		control: form.control,
	});

	function renderForm() {
		return (
			<>
				{isGoal ? (
					<Text weight="medium" size="lg" align="center" style={[constrainText(300), vr(7)]}>
						{tracker.goalQuestion}
					</Text>
				) : (
					<View style={vr(2)}>
						<InputCalendar
							label={t('track.entry.dateLabel')}
							popupLabel={t('track.entry.calendarTitle')}
							errorMessage={form.formState.errors.date?.message}
							{...dateInputProps}
						/>
					</View>
				)}
				{tracker.secondaryInput ? (
					<InputDualUnitNumeric
						convertValueToSecondaryUnit
						label={tracker.inputLabel}
						errorMessage={form.formState.errors.tracker?.message}
						primaryUnit={tracker.unit(0)}
						secondaryUnit={tracker.secondaryInput?.unit}
						conversionFactor={tracker.secondaryInput?.conversionFactor || 1}
						onSubmitEditing={onSubmit}
						maxValue={tracker.maxValue}
						{...trackerInputProps}
					/>
				) : (
					<InputText
						type="integer"
						label={tracker.inputLabel}
						errorMessage={form.formState.errors.tracker?.message}
						inputRight={() => (
							<Text weight="semiBold">{isGoal ? tracker.goalUnit : tracker.unit(0)}</Text>
						)}
						maxLength={isGoal ? GOAL_INPUT_MAX_LENGTH : 10}
						onSubmitEditing={onSubmit}
						{...trackerInputProps}
					/>
				)}
			</>
		);
	}

	return { form, renderForm };
}
