import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export enum CelebrationsImageType {
	STGoalComplete = 'STGoalComplete',
	LTGoalComplete = 'LTGoalComplete',
	ActionStepComplete = 'ActionStepComplete',
}

export interface ICelebrations {
	title: string;
	message: string;
	imageType: CelebrationsImageType | null;
	hasConfetti: boolean;
	buttonTitle?: string;
	buttonPath?: string;
}

export async function getCelebrations() {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<ICelebrations>(`celebrations`);

				return mobeStandardSuccessProcessor<ICelebrations>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<ICelebrations>(error);
			}
		})()
	);
}
