import Box from '@mobe/components/box/Box';
import Heading from '@mobe/components/heading/Heading';
import Row from '@mobe/components/layout/Row';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import useColorScheme from '@mobe/utils/useColorScheme';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { PlanScreenNavigationProp } from '../../navigation/bottomTabs/types';

interface IConnectBox {
	title: string;
	graphic: React.ReactNode;
}

export default function ConnectBox({ title, graphic }: IConnectBox) {
	const { constrain } = useStyleHelpers();
	const { t } = useTranslation();
	const colorScheme = useColorScheme();
	const { isLargeDisplay } = useLayout();
	const styleRules = useStyleRules();
	const navigation = useNavigation<PlanScreenNavigationProp>();

	function onPress() {
		navigation.navigate('MY_GUIDE_SCREEN');
	}

	return (
		<Box
			style={{
				backgroundColor: colorScheme === 'dark' ? styleRules.colors.cardBackground : '#F3F3F3',
			}}
		>
			<Row>
				<Row.Item>
					<View style={constrain(isLargeDisplay ? 100 : 70)}>{graphic}</View>
				</Row.Item>
				<Row.Item fill>
					<Heading level="h4">{title}</Heading>
					<TextButton title={t('plan.connectBox.button')} onPress={onPress} />
				</Row.Item>
			</Row>
		</Box>
	);
}
