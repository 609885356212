import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';
export default function ProgressPharmacistMedicineGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(150, 265.6);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#003265"
					d="M71.2 8.9S72-1.7 59.4.3c-9.6 1.5-17.3 13.3-18.3 20.9-1 7.6 2.8 17.6-1.9 23s-16.8 4.5-14.7 25.7c2.1 21.2 24.7 8.3 24.7 8.3l20.9-14.4-4.2-33 5.3-21.9z"
				/>
				<Path
					d="M65.4 13s-4.2-11.6 5.7-9.9c14.5 2.4 11.3 20.3 10.6 22.8-.7 9 .5 18.1 3.6 26.7 3.7 4.7 5.9 10.5 6.2 16.6L76.1 50.4 65.4 13z"
					fill="url(#Path_2499_2_)"
				/>
				<Path
					fill="#003265"
					d="M71.9 50.2s.8-8.6 5.5-7.6c4.7.9.5 1.3 7.5 7.6 5.2 4 7.7 10.7 6.4 17.2-1.3 3-12.6 0-12.6 0l-6.8-17.2z"
				/>
				<Path
					fill="#3E619E"
					d="M88.7 160.6c10.5 68.5 13.8 85.3 12.6 96.9-.2 1.5-1.1 3.5-4.8 8.1H60.9c6.5-9.9 8.5-22.1 5.4-33.8-6-22.4-13.5-55.1-9.1-65.8 1.9-4.8 17.8-5.5 26.2-5.5h1.1c2.6 0 4.2.1 4.2.1z"
				/>
				<Path
					fill="#3E619E"
					d="M65.3 256.8c10.4-12.7 14.7-12 14.8-28.6.1-18.5-1.4-41.7-8.8-51-3.5-4.3-9-4.1-15.3-1.5 1.5 19 5 37.8 10.4 56 3.7 13.7 1.6 11.8-7.8 22.3-4.9 5.3 2.8 7.6 6.7 2.8z"
				/>
				<Path
					d="M34.2 164.2c12.1-8.4 17.9.1 25.2 9.6l.7 1c1.9 2.4 3.8 4.8 6.1 7 6 6 7.3 27.3 7.4 47.1v2.4c0 16.4-.8 31.3-.3 34.4H47.1c-2.2-7.3-7-22.4-11.3-37.1l-.7-2.2c-4-13.7-7.5-26.7-7.9-32.4 0-10.5 2.4-20.7 7-29.8z"
					fill="#718BC6"
				/>
				<Path
					d="M101.5 95.9c-7.1-31.8-15.6-32.2-15.6-32.2s-11.2 29.8 1.9 40.1c11.8 9.3 15.3-.5 13.7-7.9z"
					fill="#E8AC4B"
				/>
				<Path
					fill="#FAC4B6"
					d="M54.7 38.2s1.6 21.2-.3 24.5c-1.8 3.3 8.8 24.5 11.7 26.6 3 2.1 29.2-2.5 13.1-25.8-3.3-5.3-5.5-11.3-6.2-17.6-1.4-8.2-18.3-7.7-18.3-7.7z"
				/>
				<Path
					fill="#F5B3A6"
					d="M75 54.6c-.9-2.6-1.5-5.3-2-8.1-1.5-8.2-18.3-7.7-18.3-7.7s.5 6 .5 11.6c5.1 3.7 11.2 5.5 17.4 5.1.9 0 1.7-.4 2.4-.9z"
				/>
				<Path
					fill="#FAC4B6"
					d="M84.5 16.4c.7 12.6-1.3 30.9-9.6 31.1-10.7.3-25.8-9.2-26.5-21.8-.7-12.6 6.4-24.6 14.6-25s20.8 3.1 21.5 15.7z"
				/>
				<Path
					fill="#FEC14D"
					d="M41.8 64.1c4-1.9 8.5-2.7 12.9-2 0 0 10.1 23.1 24.8 21.3 1.7-6.1 1.6-12.7-.4-18.7l-.9-2.9c2.4 0 4.8.5 7 1.6l.6.3c4.4 9.4 7.2 19.5 8.2 29.9-.3 9.1-1.3 18.1-3 27.1-1.8 20.3 5.1 52.8 5.1 52.8s-4.6 5.8-28.3 8.5c-13.7 1.6-37.7-7.6-37.7-7.6s6.4-17.7 10.2-26.6c2.9-6.8.2-12.6 1.9-25.1.9-6.8-2.4-16.3-2.7-22.2 0-1.4 8.5-19.4 2.3-36.4z"
				/>
				<Path
					opacity="0.5"
					fill="#FEC14D"
					d="M91.1 120.7c.1-1.6.4-3.2.6-4.9 0-.3-.1-.6-.3-.8-.2-.2-.6-.2-.8-.1h-.1c-5 2.5-10.7 3.5-16.3 2.9-3.4-1.4-5.7.5-3 1.8 3.6 2.1 7.9 3 12 2.3 4.6-1.2 5.1 4.5 5.6 5.4.6.9 1.8 30.6 1.6 35.1-2.3 7.8-8.5 13.7-16.3 15.5-7.4 1-14.9 1.3-22.4 1-.1 0-.2 0-.3.1-.3.1-.5.5-.5.9s.3.7.7.7c5.3 1.2 10.8 1.7 16.2 1.3 20.2-2.3 26.5-6.8 27.9-8.1.2-.2.3-.5.3-.8-.7-3.9-6.5-33.4-4.9-52.3z"
				/>
				<Path
					d="M145.6 139.9L38.3 195.5c-4 2.1-8.8.4-10.8-3.6L6 148.3c-2-4.1-.4-9 3.5-11.1l107.2-55.6c4-2.1 8.8-.4 10.8 3.6l21.5 43.6c1 2 1.1 4.2.5 6.3-.6 2.1-2 3.8-3.9 4.8z"
					fill="#1A317A"
				/>
				<Path
					d="M140.4 137.8L33.2 193.4c-1.9 1-4.1 1.2-6.1.5s-3.7-2.2-4.7-4.1L.9 146.2c-1-2-1.1-4.2-.5-6.3s2.1-3.8 4-4.8l107.2-55.6c4-2.1 8.8-.4 10.8 3.6l21.5 43.6c2.1 4.1.5 9.1-3.5 11.1z"
					fill="#C7D9F8"
				/>
				<Path
					d="M27.5 136c-4.8-1.4-9.9 1.3-11.6 6.1-1.7 4.8.5 10.2 5.1 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8L22.8 155c3.7.8 7.5-.7 9.7-3.8 2.2-3.1 2.5-7.3.6-10.7zm7 21.1c-4.8-1.4-9.8 1.3-11.5 6.1-1.7 4.8.5 10.1 5 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.2-3.2 2.4-7.4.5-10.7zm9.5-44.5c-4.8-1.4-9.9 1.3-11.6 6.1-1.7 4.8.5 10.2 5.1 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.2-3.1 2.4-7.3.5-10.7zm7 21.1c-4.8-1.4-9.9 1.3-11.6 6.1-1.7 4.8.5 10.2 5.1 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.2-3.2 2.4-7.3.5-10.7zm9.6-44.5c-4.8-1.4-9.8 1.3-11.5 6.1-1.7 4.8.5 10.1 5 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.1-3.1 2.3-7.3.5-10.7zm7 21.1c-4.8-1.4-9.9 1.3-11.6 6.1-1.7 4.8.5 10.2 5.1 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8L91 151.8c3.7.8 7.5-.7 9.7-3.8 2.2-3.2 2.4-7.3.5-10.7zm9.5-44.5c-4.8-1.4-9.9 1.3-11.6 6.1-1.7 4.8.5 10.2 5.1 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.2-3.1 2.4-7.3.5-10.7zm7 21.1c-4.8-1.4-9.8 1.3-11.5 6.1s.5 10.1 5 12.3l6.5-18.4zm5.6 4.5c-.9-1.6-2.2-2.9-3.8-3.8l-6.4 18.3c3.7.8 7.5-.7 9.7-3.8 2.2-3.2 2.4-7.3.5-10.7z"
					fill="#628CD2"
				/>
				<Path
					d="M26.5 135.8c-4.7-1.4-9.6 1.2-11.3 5.9s.5 9.9 5 12l6.3-17.9zm5.4 4.4c-.9-1.6-2.2-2.8-3.7-3.7l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7 2.1-3.1 2.3-7.1.5-10.4zm7.2 21.2c-4.7-1.4-9.6 1.2-11.3 5.9s.5 9.9 5 12l6.3-17.9zm5.4 4.4c-.9-1.6-2.2-2.8-3.7-3.7l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7s2.4-7.2.5-10.4zm9.7-44.4c-4.7-1.4-9.7 1.2-11.4 5.9-1.7 4.7.6 10 5.1 12l6.3-17.9zm5.5 4.4c-.9-1.6-2.2-2.8-3.7-3.6L49.7 140c3.6.8 7.3-.7 9.5-3.7 2.1-3.2 2.3-7.2.5-10.5zm7.1 21.2c-4.7-1.4-9.7 1.2-11.3 5.9-1.7 4.7.5 10 5 12l6.3-17.9zm5.5 4.4c-.9-1.6-2.2-2.8-3.7-3.7l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7 2.1-3.1 2.3-7.2.5-10.4zM82 107c-4.7-1.4-9.6 1.2-11.3 5.9s.5 9.9 5 12L82 107zm5.4 4.4c-.9-1.6-2.2-2.8-3.7-3.7l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7s2.4-7.1.5-10.4zm7.2 21.2c-4.7-1.4-9.7 1.2-11.3 5.9-1.7 4.7.5 10 5 12l6.3-17.9zm5.4 4.4c-.9-1.6-2.2-2.8-3.7-3.7L90 151.1c3.6.8 7.3-.7 9.5-3.7 2.2-3.1 2.4-7.2.5-10.4zm9.7-44.4c-4.7-1.4-9.7 1.2-11.3 5.9-1.7 4.7.5 10 5 12l6.3-17.9zm5.5 4.4c-.9-1.6-2.2-2.8-3.7-3.7l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7 2.1-3.1 2.3-7.1.5-10.4zm7.1 21.2c-4.7-1.4-9.7 1.2-11.3 5.9-1.7 4.7.5 10 5 12l6.3-17.9zm5.5 4.4c-.9-1.6-2.2-2.8-3.7-3.6l-6.3 17.8c3.6.8 7.3-.7 9.5-3.7s2.3-7.3.5-10.5z"
					fill="#FFF"
				/>
				<Path
					d="M125.7 110.6L20.6 165.1c-.8.4-1.7.1-2.1-.7-.4-.8-.1-1.7.7-2.1l105.1-54.5c.8-.4 1.7-.1 2.1.7.4.8.1 1.7-.7 2.1z"
					fill="#E0EAFF"
				/>
				<Path
					fill="#FAC4B6"
					d="M97.3 164.3c.2.1.4.2.5.5s0 .5-.2.7c-2.7 2.6-5.7 4.8-9 6.5-7.2-.3-14.2-2.1-20.7-5.1-2.3-2.2 2.1-10.1 2.1-10.1l7.2-1.9c.3-.1.6 0 .8.1.2.2.4.5.4.8 0 .9-.3 1.6 1.7 2.9 5.5 2.6 11.3 4.5 17.2 5.6z"
				/>
				<Path
					fill="#F5B3A6"
					d="M88.1 170.2c-6.9-1.5-13.8-3.5-20.4-5.9l-.3-.1c-.2 1 0 2 .6 2.7 6.5 3.1 13.5 4.8 20.7 5.1 1.1-.4 2.2-1 3.1-1.8-1.3.1-2.5.1-3.7 0z"
				/>
				<Path
					fill="#FEC14D"
					d="M25.2 129.1s11-19.9 19.2-34.9c3.7-6.8 9.5-26.7 2.2-30.1-5.6-2.6-11.9 7.9-20 18.6-4.7 6.1-24.3 44.5-20.6 53.5 2 4.8 13.4 12.1 24.1 16.5 16 6.5 37.2 13.5 37.2 13.5.1-4.2 2.4-8.1 6-10.1l-48.1-27z"
				/>
				<Path
					opacity="0.5"
					fill="#FEC14D"
					d="M67.6 164.2c-3.8-1.1-20.1-9.5-33.8-14.3-22.1-7.6-24.5-18.5-22.4-23.7 19.7-50 31.9-51.7 34.5-58.2.7-1.2.8-2.7.3-4-5.4-2.1-11.4 7.5-19 17.7l-.8 1c-2.8 4.3-5.3 8.7-7.6 13.3-.6 1.3-1.3 2.6-1.9 3.9-2 4.1-4 8.4-5.7 12.7-.6 1.4-1.1 2.9-1.7 4.3-.5 1.4-1 2.8-1.5 4.1-.8 2.4-1.5 4.9-2.1 7.4.1.6 0 1.1-.1 1.6-.2 1-.2 1.9-.3 2.9-.1 1.1.1 2.2.5 3.3.1.2.1.3.2.5s.2.3.3.5c.2.3.4.7.7 1 .4.5.9 1.1 1.4 1.5l1.1 1.1.6.6c.4.4.9.7 1.4 1.1 5.7 4.2 11.9 7.7 18.5 10.4 16 6.5 37.2 13.5 37.2 13.5 0-.8.1-1.5.2-2.2z"
				/>
				<Path
					fill="#003265"
					d="M77.4 1.6s-5.3-2.6-6.6.3c-1.3 2.9 2.8 8.4 5.6 9.8 5.1 2.9 8.1 8.5 7.8 14.4 0 0 4-18.7-6.8-24.5z"
				/>
				<Path
					fill="#003265"
					d="M68.7.1c5.2.3 7 9.5-6 12.7-5.5 1.4-9 4-7.4 8.8.2.6 3.3 5.6 3.4 6.3 3 13.3-12 13.3-12 13.3S34.1-1.8 68.7.1z"
				/>
			</Svg>
		</View>
	);
}
