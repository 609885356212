import { useAssessmentMutation, useAssessmentQuery } from '@mobe/api/assessment/AssessmentApiHooks';
import LikertScaleInput from '@mobe/components/input/InputLikertScale';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingStressScreenNavigationProp,
	OnboardingStressScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import OnboardingStressGraphic from '../components/graphics/OnboardingStressGraphic';
import useShouldDelayMutation from '../useShouldDelayMutation';

interface IOnboardingStressScreenProps {
	navigation: OnboardingStressScreenNavigationProp;
	route: OnboardingStressScreenRouteProp;
}

export default function OnboardingStressScreen({ route }: IOnboardingStressScreenProps) {
	const shouldDelay = useShouldDelayMutation();
	const assessmentMutation = useAssessmentMutation(shouldDelay);
	const assessmentQuery = useAssessmentQuery();
	const value = assessmentQuery.data?.stress;
	const { t } = useTranslation();
	const inputLabels = t('onboarding.stressScreen', { returnObjects: true });

	function handleOnPress(value: number) {
		assessmentMutation.mutate({ stress: value });
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={<OnboardingStressGraphic />}
			value={value}
			isLoading={assessmentMutation.isLoading}
		>
			<LikertScaleInput
				{...inputLabels}
				value={value}
				onPress={handleOnPress}
				accessibilityAutoFocus
			/>
		</OnboardingScreenTemplate>
	);
}
