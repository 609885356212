import config from '../../package.json';
import IEnv, { EnvType } from './IEnv';

const prodEnvConfig: IEnv = {
	ENV_NAME: 'Production',
	ENV_TYPE: EnvType.Prod,
	APP_VERSION: config.version,

	BUILD_DATE: process.env.BUILD_DATE,

	MOBE_API_URL: 'https://consumer-api.mobeforlife.com/api/v2',
	SIGNALR_URL: 'https://real-time.mobeforlife.com/chat',
	VALIDIC_ORG_ID: '60c11bb7ac0f730001e233b1',
	WEB_STATIC_ASSETS_URL: 'https://connect.mobeforlife.com/static',
	TERMS_AND_CONDITIONS_URL: 'https://connect.mobeforlife.com/static/pdfs/terms.pdf',
	PRIVACY_POLICY_URL: 'https://connect.mobeforlife.com/static/pdfs/privacy.pdf',

	REDIRECT_TO_SUCCESS_SCREEN_URL: 'https://connect.mobeforlife.com/success',
	REDIRECT_TO_CONNECT_HEALTH_DATA_URL:
		'https://applink.mobeforlife.com/redirect-connect-health-data',

	DEFAULT_LOGIN_USERNAME: '',
	DEFAULT_LOGIN_PASSWORD: '',

	//                         ms * sec * min
	INACTIVITY_TIMEOUT_IN_MS: 1000 * 60 * 30,

	ENABLE_ANALYTICS: true,
	ENABLE_ANALYTICS_DEBUG: false,
};

export default prodEnvConfig;
