import Analytics from '@mobe/utils/analytics';

export function toggleRememberMe(checked: boolean) {
	Analytics.logEvent('auth_sign_in_remember_me_toggle', { checked });
}

export function toggleBioAuth(checked: boolean) {
	Analytics.logEvent('auth_sign_in_bio_auth_toggle', { checked });
}

export function eligibilityCheckError() {
	Analytics.logEvent('auth_eligibility_check_error');
}
