import { CoachType, ICoach } from '@mobe/api/guides/guidesApi';
import Avatar from '@mobe/components/avatar/Avatar';
import CardButton from '@mobe/components/cardButton/CardButton';
import Icon from '@mobe/components/icon/Icon';
import { Text } from '@mobe/components/text';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';
import { useModalNavigation } from '../../navigation/modal/ModalNavigationProvider';
import * as HomeAnalytics from '../analyticsEvents';

interface IGuideScheduleCardProps {
	guide: ICoach;
	style?: StyleProp<ViewStyle>;
}

export function GuideScheduleCard({ guide, style }: IGuideScheduleCardProps) {
	const { t } = useTranslation();
	const navigation = useModalNavigation();

	function handlePress() {
		GlobalAnalyticsEvents.startGuideSchedulingPress(guide.coachType);
		HomeAnalytics.scheduleCallCtaPress();
		navigation.navigate('APPOINTMENT_SCREEN', { coachType: guide.coachType });
	}

	return (
		<CardButton
			style={style}
			onPress={handlePress}
			cardButtonRight={<Icon name="right" size={15} />}
			cardButtonLeft={
				<Avatar
					imageSrc={guide.avatarUrl ? { uri: guide.avatarUrl } : undefined}
					letter={guide.preferredName ? guide.preferredName[0] : ''}
					backgroundColor={guide.coachType === CoachType.Pharmacist ? 'tertiary' : undefined}
				/>
			}
		>
			<Text weight="semiBold">
				{t('appointments.appointmentCard.schedule', {
					guideName: guide.preferredName,
				})}
			</Text>
		</CardButton>
	);
}
