import { random } from 'lodash';
import * as React from 'react';
import { SvgProps } from 'react-native-svg';
import AS1Graphic from './AS1Graphic';
import AS2Graphic from './AS2Graphic';
import AS3Graphic from './AS3Graphic';

export default function ASCompleteGraphic(props: SvgProps) {
	const graphics = [
		<AS1Graphic {...props} key={'mountain'} />,
		<AS2Graphic {...props} key={'compass'} />,
		<AS3Graphic {...props} key={'path'} />,
	];

	return graphics[random(graphics.length - 1)];
}
