import axios, { AxiosError } from 'axios';
import { useAuth } from './AuthContext';

const interceptors: number[] = [];

/**
 * This function is an initializer for an axios interceptor that will detect an error response
 * with a 401 status code.  When a user is logged in, and any endpoint results in a 401, the
 * user should be logged out as this is an indication that their token has expired.
 */

export default function initSessionTerminationInterceptor(auth: ReturnType<typeof useAuth>) {
	return () => {
		const interceptorId = axios.interceptors.response.use(undefined, (error: AxiosError) => {
			const isUnauthorized = error.response?.status === 401;
			const isCallToLogout = error.config.url?.indexOf('logout') !== -1;

			if (isUnauthorized && !isCallToLogout) {
				auth.triggerSessionExpiration();
			}

			throw error;
		});

		interceptors.push(interceptorId);

		return () => {
			interceptors.forEach((interceptorId) => axios.interceptors.response.eject(interceptorId));
		};
	};
}
