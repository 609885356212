import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(70.5, 48);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#FFB136" d="M70.5 14.327l-2.105.899-4.925-2.851 1.79-.806z" />
				<Path fill="#001731" d="M68.414 15.234l-.26.127-.036-.021.276-.118z" />
				<Path fill="#FFB136" d="M66.059 4.429l-1.448 5.622-.011-.029-1.779.794 1.165-5.409z" />
				<Path fill="#001731" d="M65.952 16.29l-.148.034.12-.052z" />
				<Path fill="#FFB136" d="M65.802 16.326l-2.18.928-4.668-2.847 1.714-.773 4.913 2.742z" />
				<Path fill="#001731" d="M63.994 5.382l-.006.027-.268.126.01-.04z" />
				<Path fill="#FFB136" d="M63.72 5.535l-1.253 5.44-2.088.933 1.186-5.353z" />
				<Path fill="#001731" d="M61.569 6.52l-.008.035-.119.057z" />
				<Path fill="#DF9634" d="M63.47 12.37l4.924 2.852-.275.118-5.009-2.807z" />
				<Path fill="#FFB136" d="M68.118 15.34l-2.196.935-4.89-2.805 2.081-.937z" />
				<Path
					fill="#DF9634"
					d="M61.028 13.47l4.89 2.805-.12.05-.222.05-4.913-2.742zM63.985 5.408l-1.164 5.409-.357.158 1.254-5.44zM61.26 6.742l.182-.131.12-.057-1.186 5.353-.356.158z"
				/>
				<Path fill="#FFB136" d="M61.443 6.612l-.183.131-1.235 5.323-1.728.771 1.004-5.214z" />
				<Path
					fill="#DD3626"
					d="M57.62 15.988c4.358 12.496-2.11 26.2-14.458 30.636-12.359 4.408-25.915-2.131-30.302-14.616-4.359-12.496 2.109-26.2 14.458-30.636 11.42-4.071 24.042 1.187 29.312 12.21l-4.353 1.948c-4.26-8.726-14.296-12.858-23.373-9.623-9.876 3.548-15.048 14.508-11.562 24.502 3.509 9.985 14.35 15.215 24.234 11.69 9.876-3.548 15.048-14.508 11.562-24.502a18.565 18.565 0 00-.177-.485l4.348-1.959c.108.273.21.552.311.835z"
				/>
				<Path
					fill="#FFF"
					d="M53.137 17.592c3.487 9.993-1.686 20.954-11.562 24.502-9.884 3.525-20.725-1.705-24.234-11.69-3.486-9.994 1.686-20.955 11.562-24.502 9.077-3.235 19.113.897 23.372 9.622l-4.353 1.943c-3.249-6.427-10.699-9.433-17.432-7.034-7.404 2.659-11.281 10.875-8.668 18.366 2.628 7.486 10.756 11.408 18.165 8.764 7.403-2.658 11.281-10.874 8.668-18.365l-.046-.129 4.35-1.96c.063.158.121.32.178.483z"
				/>
				<Path
					fill="#DD3626"
					d="M48.656 19.197c2.613 7.491-1.265 15.706-8.668 18.365-7.409 2.644-15.537-1.278-18.165-8.764-2.613-7.491 1.265-15.707 8.668-18.365 6.733-2.399 14.183.608 17.432 7.034l-4.361 1.948c-2.232-4.129-7.095-6.013-11.484-4.449-4.93 1.771-7.512 7.241-5.772 12.23 1.752 4.984 7.163 7.594 12.097 5.834 4.841-1.739 7.434-7.058 5.849-11.999l4.359-1.964c.014.043.031.086.045.13z"
				/>
				<Path
					fill="#FFF"
					d="M44.25 21.032c1.585 4.941-1.007 10.26-5.849 11.999-4.934 1.76-10.346-.85-12.097-5.834-1.74-4.988.842-10.459 5.772-12.23 4.389-1.564 9.252.319 11.484 4.448l-4.38 1.956a4.696 4.696 0 00-5.517-1.873 4.741 4.741 0 00-2.688 2.444 4.826 4.826 0 00-.191 3.65c.872 2.486 3.571 3.788 6.031 2.909 2.232-.799 3.533-3.146 3.042-5.488l4.393-1.981z"
				/>
				<Path
					fill="#DD3626"
					d="M39.858 23.012c.491 2.342-.81 4.689-3.042 5.488-2.46.88-5.16-.422-6.031-2.909a4.822 4.822 0 01.191-3.65 4.741 4.741 0 012.688-2.444 4.695 4.695 0 015.517 1.873l-4.348 1.941a.89.89 0 00-.528.519.906.906 0 00.509 1.163c.222.087.469.08.685-.021l4.359-1.96z"
				/>
				<Path
					fill="#132D36"
					d="M64.612 10.051l-.012-.028-1.778.794-.356.158-2.087.933-.356.158-1.728.772-1.667.743-4.353 1.948-4.353 1.943-4.361 1.948-4.38 1.955-4.348 1.941a.89.89 0 00-.528.519.906.906 0 00.509 1.163c.222.087.469.08.685-.021l4.359-1.964 4.393-1.981 4.359-1.964 4.35-1.96 4.348-1.959 1.646-.742 1.714-.773.36-.163 2.081-.937.359-.162 1.79-.806.016-.007-.662-1.508z"
				/>
			</Svg>
		</View>
	);
}
