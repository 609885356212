import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IPathwayBlobGraphicProps extends SvgProps {
	isComplete?: boolean;
}

export default function PathwayBlobGraphic({
	isComplete = false,
	...props
}: IPathwayBlobGraphicProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(54, 52);
	const styleRules = useStyleRules();

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={isComplete ? styleRules.colors.strokeLight : styleRules.colors.backgroundPrimary}
					d="M11.245 45.207c1.272 1.877 2.762 2.9 3.948 3.716 6.897 4.737 19.63 3.935 28.619-1.926 1.084-.709 10.599-7.048 9.42-12.8-.769-3.218-2.783-3.65-3.929-5.932-2.455-4.901 3.722-9.228 2.117-16.338-.72-3.19-2.619-5.245-3.654-6.355C42.2-.429 33.914-.062 31.549.044c-2.748.122-4.747.209-6.96 1.44-5.212 2.889-4.823 2.215-9.396 4.088-3.316 1.359-4.597 4.973-8.702 7.023a11.72 11.72 0 0 0-3.929 3.16c-3.659 4.634-3.217 11.93.164 15.983 1.964 2.356 3.146 1.483 4.952 4.021 2.346 3.296 1.126 5.835 3.567 9.448z"
				/>
			</Svg>
		</View>
	);
}
