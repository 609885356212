import { GenderPreference, LanguagePreference } from '@mobe/api/appointments/appointmentsService';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import VrArray from '@mobe/components/layout/VrArray';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GuideMatchLanguageScreenNavigationProp,
	GuideMatchLanguageScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchLanguageScreenProps {
	navigation: GuideMatchLanguageScreenNavigationProp;
	route: GuideMatchLanguageScreenRouteProp;
}

export default function GuideMatchLanguageScreen({ route }: IGuideMatchLanguageScreenProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const formController = useGuideMatchForm();
	const languageControl = formController.languageControl;

	const languageOptions: Array<{ label: string; value: LanguagePreference }> = [
		{
			label: t('appointments.guideMatch.languageStep.options.english'),
			value: LanguagePreference.English,
		},
		{
			label: t('appointments.guideMatch.languageStep.options.spanish'),
			value: LanguagePreference.Spanish,
		},
	];

	function handleLanguageSelection(value: LanguagePreference) {
		languageControl.field.onChange(value);
		formController.form.setValue('gender', GenderPreference.NoPreference);
	}

	return (
		<GuideMatchScreenTemplate
			routeName={route.name}
			canProgress={!languageControl.fieldState.error}
		>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(8)}
			>
				{t('appointments.guideMatch.languageStep.description')}
			</Text>
			<VrArray role="radiogroup">
				{languageOptions.map((option) => (
					<BoxButton
						key={option.value}
						selected={option.value === languageControl.field.value}
						onPress={() => handleLanguageSelection(option.value)}
						role="radio"
					>
						<Text size="lg" weight="medium">
							{option.label}
						</Text>
					</BoxButton>
				))}
			</VrArray>
		</GuideMatchScreenTemplate>
	);
}
