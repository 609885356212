import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';

export default function useCanScheduleAppointment() {
	const { maxCallCountGuide, maxCallCountPharmacist } = useRemoteConfigData();
	const appointmentsQuery = useAppointmentsQuery();
	const futureAppointments = appointmentsQuery.data?.futureAppointments || [];

	const guideQuery = useGuideQuery();
	const pharmacistQuery = usePharmacistQuery();

	const hasGuide = Boolean(guideQuery.data);
	const hasPharmacist = Boolean(pharmacistQuery.data);

	const canScheduleGuide =
		(futureAppointments.filter((appt) => appt.guideDIKey === guideQuery.data?.guideDIKey) || [])
			.length < maxCallCountGuide && hasGuide;
	const canSchedulePharmacist =
		(
			futureAppointments.filter((appt) => appt.guideDIKey === pharmacistQuery.data?.guideDIKey) ||
			[]
		).length < maxCallCountPharmacist && hasPharmacist;
	const canScheduleAppt = canScheduleGuide || canSchedulePharmacist;

	return {
		canScheduleGuide,
		canSchedulePharmacist,
		canScheduleAppt,
	};
}
