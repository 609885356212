import { ITrackerDetail, TrackerAbbreviation } from '@mobe/api/track/trackService';
import Button from '@mobe/components/button/Button';
import Heading from '@mobe/components/heading/Heading';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	TrackEntryScreenNavigationProp,
	TrackEntryScreenRouteProp,
} from '../../navigation/modal/types';
import useTrackEntryScreenController from './useTrackEntryScreenController';

export interface ITrackEntryScreenParams {
	trackerAbbreviation: TrackerAbbreviation;
	displaySuccessToast?: boolean;

	/**
	 * If trackerEntry is passed as a prop, the trackEntryScreen will be in an "edit" mode.
	 * The form will pre-populate with the entry data and the footer will have delete/update options.
	 */
	trackerEntry?: ITrackerDetail;
}

export interface ITrackEntryScreenProps {
	navigation: TrackEntryScreenNavigationProp;
	route: TrackEntryScreenRouteProp;
}

export default function TrackEntryScreen({ navigation, route }: ITrackEntryScreenProps) {
	const { t } = useTranslation();
	const { wrapperNarrow, constrain, vr } = useStyleHelpers();
	const {
		trackerContent,
		form,
		handleSubmit,
		handleDelete,
		renderForm,
		auxiliaryForm,
		trackersUpdateEntryMutation,
		trackersAddEntryMutation,
		trackersDeleteEntryMutation,
		isUpdatingNonManualTracker,
	} = useTrackEntryScreenController({
		navigation,
		route,
	});

	return (
		<ScreenTemplateWithFooter
			noPadding
			footer={
				route.params.trackerEntry ? (
					<ButtonRow>
						<Button
							variant="secondary"
							title={t('track.updateEntry.deleteButton')}
							onPress={handleDelete}
							disabled={isUpdatingNonManualTracker || trackersUpdateEntryMutation.isLoading}
							loading={!isUpdatingNonManualTracker && trackersDeleteEntryMutation.isLoading}
						/>
						<Button
							title={t('track.updateEntry.submitButton')}
							onPress={handleSubmit}
							disabled={
								(!isUpdatingNonManualTracker && trackersDeleteEntryMutation.isLoading) ||
								!form.formState.isDirty
							}
							loading={isUpdatingNonManualTracker || trackersUpdateEntryMutation.isLoading}
						/>
					</ButtonRow>
				) : (
					<Button
						title={t('track.entry.submitButton')}
						onPress={handleSubmit}
						loading={trackersAddEntryMutation.isLoading}
						disabled={!form.formState.isValid}
					/>
				)
			}
		>
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 20,
					marginBottom: 4,
				}}
			>
				<View style={[constrain(100), vr(2)]}>{trackerContent.graphic}</View>
				<Heading accessibilityAutoFocus>{trackerContent.title}</Heading>
			</View>
			<View style={wrapperNarrow}>
				{renderForm()}
				{auxiliaryForm?.renderForm(route.params.trackerEntry ? 'editEntry' : 'addEntry')}
			</View>
		</ScreenTemplateWithFooter>
	);
}

export function useTrackEntryScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	return ({ route }: ITrackEntryScreenProps): StackNavigationOptions => ({
		title: route.params.trackerEntry
			? t('track.updateEntry.screenTitle')
			: t('track.entry.screenTitle'),
		...modalStyleProps,
	});
}
