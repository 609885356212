import Analytics from '@mobe/utils/analytics';

export function searchFocus() {
	Analytics.logEvent('explore_search_focused');
}

export function searchResultPress(contentId: number) {
	Analytics.logEvent('explore_search_result_press', { contentId });
}

export function guideConnectPress() {
	Analytics.logEvent('explore_guide_connect_press');
}

export function pharmacistConnectPress() {
	Analytics.logEvent('explore_pharmacist_connect_press');
}
