import { useAllFeaturedSharedContentQuery } from '@mobe/api/explore/exploreApiHooks';
import Heading from '@mobe/components/heading/Heading';
import MobeVideoPlayer from '@mobe/components/mobeVideoPlayer/MobeVideoPlayer';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import * as HomeAnalyticsEvents from '../analyticsEvents';

export default function IntroVideo({ style }: { style?: StyleProp<ViewStyle> }) {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const { isWebDesktop } = useLayout();
	const persistentState = usePersistentState();
	const featuredSharedContentQuery = useAllFeaturedSharedContentQuery();
	const videoUrl = featuredSharedContentQuery.data?.welcomeVideo?.contentUrl;
	const styles = useStyles();
	const { wrapperHorizontal } = useStyleHelpers();
	const [forceRerenderCounter, setForceRerenderCounter] = React.useState(0);
	const [containerWidth, setContainerWidth] = React.useState(0);

	useFocusEffect(
		React.useCallback(() => {
			// Force a re-render of the web video player to solve issue where iframe loses it's height and width identity on screen change
			if (Platform.OS === 'web') {
				setForceRerenderCounter((forceRerenderCounter) => forceRerenderCounter + 1);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	return (
		<View style={[style, wrapperHorizontal]}>
			<View
				style={styles.videoWrapper}
				onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)}
			>
				<View key={forceRerenderCounter}>
					<MobeVideoPlayer
						uri={videoUrl ?? ''}
						type="wistia"
						onPlayPress={() => HomeAnalyticsEvents.videoPlayPress()}
						renderedWidth={containerWidth}
						onWatched={() => persistentState.setHasSeenWelcomeVideo(true)}
					/>
				</View>
				<View style={styles.messageBody}>
					<Heading level={isWebDesktop ? 'h3' : 'h4'} align="center" style={vr(1)}>
						{t('home.welcomeOne')}
					</Heading>
					<Heading level={isWebDesktop ? 'h3' : 'h4'} align="center">
						{t('home.welcomeTwo')}
					</Heading>
				</View>
			</View>
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();
	const { isWebDesktop } = useLayout();

	return StyleSheet.create({
		videoWrapper: {
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			position: 'relative',
			alignItems: 'center',
			alignSelf: 'center',
			backgroundColor: rules.colors.cardBackground,
			borderRadius: rules.borderRadius,
			...cardShadow,
		},
		messageBody: {
			paddingVertical: isWebDesktop ? 24 : 16,
			paddingHorizontal: 8,
		},
	});
}
