import { useAssessmentMutation, useAssessmentQuery } from '@mobe/api/assessment/AssessmentApiHooks';
import LikertScaleInput from '@mobe/components/input/InputLikertScale';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingNutritionScreenNavigationProp,
	OnboardingNutritionScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import OnboardingNutritionGraphic from '../components/graphics/OnboardingNutritionGraphic';
import useShouldDelayMutation from '../useShouldDelayMutation';

interface IOnboardingNutritionScreenProps {
	navigation: OnboardingNutritionScreenNavigationProp;
	route: OnboardingNutritionScreenRouteProp;
}

export default function OnboardingNutritionScreen({ route }: IOnboardingNutritionScreenProps) {
	const shouldDelay = useShouldDelayMutation();
	const assessmentMutation = useAssessmentMutation(shouldDelay);
	const assessmentQuery = useAssessmentQuery();
	const value = assessmentQuery.data?.nutrition;
	const { t } = useTranslation();
	const inputLabels = t('onboarding.nutritionScreen', { returnObjects: true });

	function handleOnPress(value: number) {
		assessmentMutation.mutate({ nutrition: value });
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={<OnboardingNutritionGraphic />}
			value={value}
			isLoading={assessmentMutation.isLoading}
		>
			<LikertScaleInput
				{...inputLabels}
				value={value}
				onPress={handleOnPress}
				accessibilityAutoFocus
			/>
		</OnboardingScreenTemplate>
	);
}
