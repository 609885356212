import IconButton, { IIconButtonProps } from '@mobe/components/iconButton/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IExploreFavoriteButtonProps extends Partial<IIconButtonProps> {
	favorited?: boolean;
}

export default function ExploreFavoriteButton({
	favorited = false,
	...iconButtonProps
}: IExploreFavoriteButtonProps) {
	const { t } = useTranslation();

	return (
		<IconButton
			role="checkbox"
			size={25}
			color={favorited ? 'favorite' : 'textLight'}
			name={favorited ? 'star_solid' : 'star'}
			accessibilityLabel={t('explore.favoriteButtonAccessibilityLabel')}
			aria-label={t('explore.favoriteButtonAccessibilityLabel')}
			aria-checked={favorited}
			{...iconButtonProps}
		/>
	);
}
