import { useAuth } from '@mobe/api/authentication/AuthContext';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { CommonActions, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Pressable, StyleSheet, View } from 'react-native';
import { Button } from '../button';
import { MoodGraphic } from '../graphics';
import Heading from '../heading/Heading';
import Icon from '../icon/Icon';
import IconButton from '../iconButton/IconButton';
import Row from '../layout/Row';
import { Text } from '../text';
import { useAppFeedback } from './AppFeedbackProvider';
import * as AppFeedbackAnalytics from './analyticsEvents';
import { storeRatingReviewPrompt } from './storeReviewRatingPrompt';

export default function AppFeedbackPopup() {
	const { t } = useTranslation();
	const styles = useStyles();
	const { constrainText, constrain, vr } = useStyleHelpers();
	const remoteConfigData = useRemoteConfigData();
	const appFeedback = useAppFeedback();
	const auth = useAuth();
	const [viewState, setViewState] = React.useState<'idle' | 'negative'>('idle');
	const navigation = useNavigation();

	async function handlePositivePress() {
		appFeedback.feedbackProvided();
		AppFeedbackAnalytics.appFeedbackPositivePress();

		await storeRatingReviewPrompt();
		appFeedback.dismiss();
	}

	function handleNegativePress() {
		appFeedback.feedbackProvided();
		AppFeedbackAnalytics.appFeedbackNegativePress();
		setViewState('negative');
	}

	async function handleFeedbackPress() {
		AppFeedbackAnalytics.appFeedbackProvideFeedbackPress();

		if (remoteConfigData.appFeedback.surveyID) {
			navigation.dispatch(
				CommonActions.navigate('ASSIGN_SURVEY_SCREEN', {
					surveyId: remoteConfigData.appFeedback.surveyID,
				})
			);
		}

		setTimeout(appFeedback.dismiss, 1000);
	}

	function handleClosePress() {
		appFeedback.dismiss();
		AppFeedbackAnalytics.appFeedbackDismissPress();
	}

	return (
		<Modal
			animationType="fade"
			transparent
			statusBarTranslucent
			visible={appFeedback.isFeedbackShown && auth.isAuthenticated}
		>
			<View style={styles.container}>
				<View style={styles.containerInner}>
					{viewState === 'idle' ? (
						<>
							<Heading
								level="h2"
								accessibilityAutoFocus
								style={[constrainText(250), vr(8)]}
								align="center"
							>
								{t('appReview.heading')}
							</Heading>
							<Row style={constrain(300)} gutterSize={30}>
								<Row.Item>
									<Pressable
										role="button"
										aria-label={t('appReview.negativeButton')}
										onPress={handleNegativePress}
									>
										<View style={[styles.thumbButton, styles.thumbButton_down]}>
											<Icon name="thumbsDown" size={40} color="textLight" />
										</View>
										<Text weight="semiBold" color="light" align="center">
											{t('appReview.negativeButton')}
										</Text>
									</Pressable>
								</Row.Item>
								<Row.Item>
									<Pressable
										role="button"
										aria-label={t('appReview.positiveButton')}
										onPress={handlePositivePress}
									>
										<View style={[styles.thumbButton, styles.thumbButton_up]}>
											<Icon name="thumbsUp" size={40} color="primary" />
										</View>
										<Text weight="semiBold" color="primary" align="center">
											{t('appReview.positiveButton')}
										</Text>
									</Pressable>
								</Row.Item>
							</Row>
						</>
					) : viewState === 'negative' ? (
						<View>
							<View
								accessibilityElementsHidden
								importantForAccessibility="no-hide-descendants"
								style={[constrain(100), vr(6)]}
							>
								<MoodGraphic expression={1} />
							</View>
							<Text
								accessibilityAutoFocus
								size="lg"
								align="center"
								style={[constrainText(280), vr(8)]}
							>
								{t('appReview.feedbackPrompt')}
							</Text>
							<Button
								variant="secondary"
								title={t('appReview.feedbackButton')}
								onPress={handleFeedbackPress}
								style={constrainText(250)}
							/>
						</View>
					) : null}
					<IconButton
						style={styles.dismiss}
						name="close"
						onPress={handleClosePress}
						size={10}
						hitSlop={19}
						color="text"
						aria-label={t('accessibility.closeButtonLabel')}
					/>
				</View>
			</View>
		</Modal>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		container: {
			flex: 1,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			backgroundColor: rules.colors.sneezeGuard,
		},
		containerInner: {
			overflow: 'hidden',
			paddingHorizontal: 20,
			paddingVertical: 40,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,
			width: '100%',
			maxWidth: 500,
		},
		thumbButton: {
			padding: 25,
			marginBottom: 10,
			backgroundColor: rules.colors.backgroundPrimary,
			borderRadius: 100,
		},
		thumbButton_up: {
			paddingTop: 23,
			paddingBottom: 27,
		},
		thumbButton_down: {
			paddingTop: 27,
			paddingBottom: 23,
			backgroundColor: rules.colors.strokeLight,
		},
		dismiss: {
			position: 'absolute',
			top: 5,
			left: 5,
			alignItems: 'center',
			justifyContent: 'center',
			height: 24,
			width: 24,
		},
	});
}
