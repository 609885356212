import { useSubmitSurveyMutation, useSurveyQuery } from '@mobe/api/survey/surveyApiHooks';
import { SurveyResponses } from '@mobe/api/survey/surveyService';
import { Button } from '@mobe/components/button';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import { ActivitiesSurveyGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Survey, { SurveyErrorView } from '@mobe/components/survey/Survey';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ActivitiesDetailScreenNavigationProp } from '../../navigation/modal/types';
import * as ActivitiesAnalyticsEvents from '../analyticsEvents';

interface ISurveyViewProps {
	token: string;
	title: string;
	description: string | null;

	/**
	 * Callback for immediately after survey is complete, when confirmation screen is visible.
	 */
	onSurveyComplete?: () => void;
}

export default function SurveyView({
	token,
	title,
	description,
	onSurveyComplete = noop,
}: ISurveyViewProps) {
	const { t } = useTranslation();
	const { constrain, vr } = useStyleHelpers();
	const surveyQuery = useSurveyQuery(token);
	const submitSurveyMutation = useSubmitSurveyMutation();
	const navigation = useNavigation<ActivitiesDetailScreenNavigationProp>();
	const { mobeAlert } = useAlert();
	const genericErrorAlert = useGenericErrorAlert();

	// Simple state machine to manage views
	const [currentView, setCurrentView] = React.useState<'intro' | 'survey' | 'complete'>('intro');

	function handleOnSubmit(answerData: SurveyResponses) {
		submitSurveyMutation.mutate(
			{ token, responses: answerData },
			{
				onSuccess: () => {
					setCurrentView('complete');
					onSurveyComplete();
				},
				onError: () => {
					genericErrorAlert(navigation.popToTop);
				},
			}
		);
	}

	// Trigger alert before leaving survey to indicate that progress will be lost
	React.useEffect(() => {
		if (currentView !== 'survey') {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			if (surveyQuery.isError) {
				onSurveyComplete();
				return;
			}

			event.preventDefault();

			mobeAlert(t('activities.survey.exitAlertTitle'), t('activities.survey.exitAlertBody'), [
				{
					style: 'destructive',
					text: t('activities.survey.exitAlertLeave'),
					onPress: () => {
						ActivitiesAnalyticsEvents.surveyExitConfirm(title);
						navigation.dispatch(event.data.action);
					},
				},
				{
					style: 'cancel',
					text: t('activities.survey.exitAlertStay'),
					onPress: () => ActivitiesAnalyticsEvents.surveyExitCancel(title),
				},
			]);
		});

		return removeListener;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentView, surveyQuery.isError]);

	/**
	 * View that displays before the survey.
	 */
	function renderSurveyIntroView() {
		return (
			<ScreenTemplateWithFooter
				fixedPositionFooter
				centered
				footer={
					<Button
						title={t('activities.survey.startSurvey')}
						onPress={() => setCurrentView('survey')}
					/>
				}
			>
				<View style={[constrain(280), vr(8)]}>
					<ActivitiesSurveyGraphic />
				</View>
				<Heading accessibilityAutoFocus align="center" level="h2" style={vr(4)}>
					{title}
				</Heading>
				<Text size="lg" weight="medium" align="center">
					{description}
				</Text>
			</ScreenTemplateWithFooter>
		);
	}

	return (
		<>
			{currentView === 'intro' && renderSurveyIntroView()}
			{(currentView === 'survey' || currentView === 'complete') && (
				<DeferredLoadingIndicator isLoading={surveyQuery.isLoading}>
					{surveyQuery.isSuccess ? (
						<Survey surveyData={surveyQuery.data?.questions} onSubmit={handleOnSubmit} />
					) : (
						<SurveyErrorView />
					)}
				</DeferredLoadingIndicator>
			)}
		</>
	);
}
