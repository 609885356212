import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import Svg, { LinearGradient, Path, Stop, SvgProps } from 'react-native-svg';

const CurvedDivider = (props: SvgProps) => {
	const styleRules = useStyleRules();

	return (
		<Svg
			width="100%"
			height={90}
			viewBox="0 0 436 110"
			preserveAspectRatio="none"
			fill="none"
			{...props}
		>
			<LinearGradient
				id="a"
				x1={218}
				x2={218}
				y1={10.505}
				y2={57.004}
				gradientUnits="userSpaceOnUse"
			>
				<Stop offset={0} stopColor={styleRules.colors.cardBackground} stopOpacity={0} />
				<Stop offset={1} stopColor={styleRules.colors.cardBackground} />
			</LinearGradient>
			<Path fill="url(#a)" d="M0 0h436v110H0z" />
			<LinearGradient
				id="b"
				x1={218}
				x2={218}
				y1={106.174}
				y2={74.552}
				gradientUnits="userSpaceOnUse"
			>
				<Stop offset={0} stopColor={styleRules.colors.background} />
				<Stop offset={1} stopColor="#f4f4f4" />
			</LinearGradient>
			<Path
				fill="url(#b)"
				d="M296.868 43.084c-55.381 29.357-122.842 32.285-157.455 22.124C92.355 53.86 28.824 64.113 0 91.705V110h436V27.434c-23.302-7.016-83.75-13.707-139.132 15.65z"
			/>
		</Svg>
	);
};

export default CurvedDivider;
