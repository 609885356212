import {
	IMobeAPIStandardError,
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

interface IAPIExploreContentCategory {
	/**
	 * Content items
	 */
	consumerSharedContents: IExploreContentItemSummary[];
	pathwayName: string;
}

export interface IExploreContentItemSummary {
	// Common properties for summary and full
	title: string;
	contentType: ExploreContentType;
	shareableContentId: number;
	sharedContentId: number;
	isFavorite: boolean;
	isViewed: boolean;
	imageUrl: string | null;

	/**
	 * Time in minutes
	 */
	timeToConsume: number | null;
}

export interface IExploreHelpfulResponse {
	sharedContentId: number;
	isHelpful: boolean;
}

interface IExploreContentItemDetailsResponse {
	sharedContentDetailsModel: IExploreContentItemFull;
}

export enum ExploreContentType {
	Article = 'Article',
	Video = 'Video',
	Recipe = 'Recipe',
	Audio = 'Audio',
	Habits = 'Habits',
}

export interface IExploreContentItemFull extends IExploreContentItemSummary {
	contentUrl: string;
	fileUrl: string;
	contentHtml: string;
	description: string;
	isHelpful: boolean | null;
	helpfulCount: number;
	callToActions: Array<'PharmacistProfile' | 'GuideProfile'>;
}

export interface IFeaturedSharedContentItemSummary extends IExploreContentItemSummary {
	contentUrl: string;
	contentHtml: string;
}

export enum ExploreContentItemDetailsError {
	ItemDoesNotExist = 'SHARED_CONTENT_DOES_NOT_EXIST',
}

export interface IExploreContentCategory {
	id: string;
	items: IExploreContentItemSummary[];
}

export interface IExploreFavoriteResponse {
	sharedContentId: number;
	isFavorite: boolean;
}

export interface IContentModule {
	contentModuleId: number;
	title: string;
	screenTitle: string;
	shortDescription: string;
	longDescription: string;
	heroDescription: string;
	button: string;
	imageUrl: string;
	backgroundColor: string;
	sharedContent: IExploreContentItemSummary[];
}

export async function getAllContent() {
	try {
		const data = await mobeAuthenticatedAPI.get<IAPIExploreContentCategory[]>('shared-contents');

		const mappedData: IExploreContentCategory[] = data.map((item) => ({
			id: item.pathwayName,
			items: item.consumerSharedContents,
		}));

		return mobeStandardSuccessProcessor<IExploreContentCategory[]>(mappedData);
	} catch (error) {
		return mobeStandardErrorProcessor<IExploreContentCategory[]>(error);
	}
}

export async function getAllFeaturedSharedContent() {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IFeaturedSharedContentItemSummary[]>(
					'featured-shared-contents'
				);

				return mobeStandardSuccessProcessor<IFeaturedSharedContentItemSummary[]>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IFeaturedSharedContentItemSummary[]>(error);
			}
		})()
	);
}

export interface ISetFavoriteStatus {
	sharedContentId: number;
	isFavorite: boolean;
}

export async function setFavoriteStatus({ sharedContentId, isFavorite }: ISetFavoriteStatus) {
	try {
		const data = await mobeAuthenticatedAPI.put<IExploreFavoriteResponse>(
			`shared-contents/${sharedContentId}/favorite`,
			{
				isFavorite,
			}
		);

		return mobeStandardSuccessProcessor<IExploreFavoriteResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IExploreFavoriteResponse>(error);
	}
}

export interface ISetHelpfulStatus {
	sharedContentId: number;
	isHelpful: boolean;
}

export async function setHelpfulStatus({ sharedContentId, isHelpful }: ISetHelpfulStatus) {
	try {
		const data = await mobeAuthenticatedAPI.patch<IExploreHelpfulResponse>(
			`shared-contents/${sharedContentId}`,
			{
				isHelpful,
			}
		);

		return mobeStandardSuccessProcessor<IExploreHelpfulResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IExploreHelpfulResponse>(error);
	}
}

export async function getContentDetail(sharedContentId: number) {
	try {
		const data = await mobeAuthenticatedAPI.get<IExploreContentItemDetailsResponse>(
			`shared-contents/${sharedContentId}/details`
		);

		return mobeStandardSuccessProcessor<
			IExploreContentItemFull,
			ExploreContentItemDetailsError,
			IMobeAPIStandardError
		>(data.sharedContentDetailsModel);
	} catch (error) {
		return mobeStandardErrorProcessor<
			IExploreContentItemFull,
			ExploreContentItemDetailsError,
			IMobeAPIStandardError
		>(error);
	}
}

export interface ISetViewTime {
	sharedContentId: number;
	viewTime: number;
}

export async function setViewTime({ sharedContentId, viewTime }: ISetViewTime) {
	try {
		await mobeAuthenticatedAPI.post<IExploreFavoriteResponse>(
			`shared-contents/${sharedContentId}/view-histories`,
			{
				viewTime,
			}
		);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function getContentModules() {
	return await mobeAuthenticatedAPI.get<IContentModule[]>(`shared-contents/content-modules`);
}
