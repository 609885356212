import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { Text } from '@mobe/components/text/index';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ContactOptionsView from '../../../components/ContactOptionsView';
import {
	GuideMatchContactScreenNavigationProp,
	GuideMatchContactScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchContactScreenProps {
	navigation: GuideMatchContactScreenNavigationProp;
	route: GuideMatchContactScreenRouteProp;
}

export default function GuideMatchContactScreen({ route }: IGuideMatchContactScreenProps) {
	const formController = useGuideMatchForm();
	const profileQuery = useProfileQuery();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<GuideMatchScreenTemplate routeName={route.name} canProgress={formController.canSubmit}>
			{profileQuery.data?.phoneNumber ? (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(8)}
				>
					{t('appointments.guideMatch.contactStep.hasPrimaryNumberDescription')}
				</Text>
			) : (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(8)}
				>
					{t('appointments.guideMatch.contactStep.noPrimaryNumberDescription')}
				</Text>
			)}

			<ContactOptionsView
				contactOptionControl={formController.contactOptionControl}
				primaryPhoneNumberProps={formController.primaryPhoneNumberProps}
				primaryPhoneNumberErrorMessage={
					formController.form.formState.errors.primaryPhoneNumber?.message
				}
				otherPhoneNumberProps={formController.otherPhoneNumberProps}
				otherPhoneNumberErrorMessage={
					formController.form.formState.errors.otherPhoneNumber?.message
				}
				saveOtherAsAlternatePhoneNumberProps={formController.saveOtherAsAlternatePhoneNumberProps}
			/>
		</GuideMatchScreenTemplate>
	);
}
