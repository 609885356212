import { yupResolver } from '@hookform/resolvers/yup';
import { APIResponse } from '@mobe/api/APIResponse';
import { useGetEmailVerified, useVerifyEmail } from '@mobe/api/account/accountApiHooks';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import {
	IAuthenticationError,
	IAuthenticationResponse,
	LoginAPIErrorCode,
} from '@mobe/api/authentication/authenticationService';
import env from '@mobe/env/env';
import { useAlert } from '@mobe/utils/useAlert';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import useRedirectLinkTo from '@mobe/utils/useRedirectLinkTo';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform } from 'react-native';
import * as Yup from 'yup';
import { getPathAndSearchParamsFromURL, isURLAuthScreenURL } from '../../navigation/linkingConfig';
import * as AuthAnalyticsEvents from '../analyticsEvents';
import { IAuthScreenAlert } from '../components/authScreenTemplate/AuthScreenTemplate';
import { useBioAuthContent } from '../useBioAuthContent';
import useBioAuthService from '../useBioAuthService';
import { ILoginScreenProps } from './LoginScreen';

// For web, get the initial location href so we can use this for redirecting to screens that need auth...
let initialWebURL = Platform.OS === 'web' ? location.href : '';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		email: Yup.string().email().trim().required().label(t('auth.login.emailInputLabel')),
		password: Yup.string().required().label(t('auth.login.passwordInputLabel')),
		rememberMe: Yup.boolean(),
		useBiometricLogin: Yup.boolean(),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function useLoginController({ navigation, route }: ILoginScreenProps) {
	const getEmailVerified = useGetEmailVerified();
	const verifyEmail = useVerifyEmail();
	const persistentState = usePersistentState();
	const auth = useAuth();
	const bioAuthService = useBioAuthService();
	const { t } = useTranslation();
	const [canUseBioAuth, setCanUseBioAuth] = React.useState(false);
	const [shouldShowBioAuthSwitch, setShouldShowBioAuthSwitch] = React.useState(false);
	const emailVerificationGuid = route.params?.emailVerificationGuid || '';
	const bioAuthContent = useBioAuthContent();
	const redirectTo = useRedirectLinkTo();
	const { mobeAlert } = useAlert();
	const [bioAuthSignInPending, setBioAuthSignInPending] = React.useState(false);

	// Try to verify email on open
	const tryToVerifyEmail = React.useCallback(async () => {
		if (!emailVerificationGuid) {
			return;
		}

		// If this request succeeds we can try to post to verify
		const canVerifyEmailResponse = await getEmailVerified.execute(emailVerificationGuid);

		if (canVerifyEmailResponse.success) {
			const verifyEmailResponse = await verifyEmail.execute(emailVerificationGuid);
			navigation.navigate('VERIFIED_EMAIL_SCREEN', { success: verifyEmailResponse.success });
		} else {
			navigation.navigate('VERIFIED_EMAIL_SCREEN', { success: false });
		}

		navigation.setParams({ emailVerificationGuid: undefined });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailVerificationGuid]);

	React.useEffect(() => {
		tryToVerifyEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailVerificationGuid]);

	// Web redirectTo functionality
	React.useEffect(() => {
		if (Platform.OS !== 'web') return;

		if (initialWebURL && !isURLAuthScreenURL(getPathAndSearchParamsFromURL(initialWebURL))) {
			try {
				// Do not use react-native-url-polyfill for URL here
				const url = new URL(initialWebURL);
				const redirectPath = `${url.pathname}${url.search}`;
				if (redirectPath === '/') return;
				navigation.setParams({ redirectTo: redirectPath });
				initialWebURL = '';
			} catch (error) {
				console.warn('error setting initial web redirect url', error);
			}
		}
	}, []);

	/**
	 * General helper
	 */

	function resetStateVariables() {
		// reset various things in case they were set when the user was brought back to the login screen
		auth.resetStateVariables();

		setUserDisabledErrorMessage('');
		setRemainingLoginAttempts(-1);
		setLoginError(LoginAPIErrorCode.None);
	}

	/**
	 * General login
	 */

	function beginResetExpiredPasswordProcess(resetToken: string) {
		resetStateVariables();

		auth.resetPasswordToken = resetToken;

		navigation.navigate('RESET_PASSWORD_SCREEN', {});

		// Reset form password value so when the user comes back from the reset password screen
		// the old value will be cleared
		setTimeout(() => {
			form.setValue('password', '');
		}, 1000);
	}

	function handleLoginResponse(
		response: APIResponse<IAuthenticationResponse, LoginAPIErrorCode, IAuthenticationError>
	) {
		if (response.success) {
			// upon any successful login, we'll set this flag to be false to disallow showing of the bio-auth prompt again
			// this later gets conditionally reset to true in auth upon logout
			auth.shouldShowBioAuthPrompt = false;

			// This handles forwarding to the redirectTo screen path on login screen
			// Pushing this to the end of the event cycle to fix issue with web not redirecting
			setTimeout(() => {
				if (route.params?.redirectTo) {
					redirectTo(route.params.redirectTo);
				}
			}, 0);
		} else {
			setLoginError(response.errorCode);

			switch (response.errorCode) {
				case LoginAPIErrorCode.Mismatch:
					setRemainingLoginAttempts(Number(response.errorData.remaining));
					break;
				case LoginAPIErrorCode.PasswordExpired:
					beginResetExpiredPasswordProcess(response.errorData.data?.resetPasswordGuid || '');
					break;
				case LoginAPIErrorCode.AccountDisabledOnServer:
					setUserDisabledErrorMessage(String(response.errorData.message));
					break;
				case LoginAPIErrorCode.NeedsValidation:
					navigation.navigate('VERIFY_EMAIL_PROMPT_SCREEN', {
						accessToken: response.errorData.data?.token ?? '',
					});
					break;
			}
		}
	}

	/**
	 * Bio-auth related functionality
	 */

	/**
	 * Update canUseBioAuth
	 */
	React.useEffect(() => {
		setCanUseBioAuth(bioAuthService.isBioAuthAvailable && persistentState.enableBiometricLogin);
		setShouldShowBioAuthSwitch(
			bioAuthService.isBioAuthAvailable && !persistentState.enableBiometricLogin
		);
	}, [bioAuthService.isBioAuthAvailable, persistentState.enableBiometricLogin]);

	/**
	 * BioAuth Login
	 */
	async function attemptBioAuthLogin() {
		if (!canUseBioAuth) {
			return;
		}

		const response = await auth.attemptBioAuthLogin(() => setBioAuthSignInPending(true));

		setBioAuthSignInPending(false);

		// if we get a response, a login attempt was made
		// if not, there was a problem somewhere; take no action, let focus fall to the login screen
		if (response) {
			handleLoginResponse(response);
		}
	}

	/**
	 * Attempt to raise a bio-auth prompt automatically on app-launch
	 */
	React.useEffect(() => {
		// only run this on the first time this controller runs, which would be when the app first launches
		if (canUseBioAuth && auth.shouldShowBioAuthPrompt && !auth.isAuthenticated) {
			attemptBioAuthLogin();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canUseBioAuth, auth.shouldShowBioAuthPrompt]);

	/**
	 * Validation setup
	 */

	const validationSchema = useValidationSchema();

	let email = '';

	if (env.DEFAULT_LOGIN_USERNAME) {
		email = env.DEFAULT_LOGIN_USERNAME;
	} else if (auth.firstTimeUsername) {
		email = auth.firstTimeUsername;
	} else if (persistentState.rememberMe && persistentState.savedUsername) {
		email = persistentState.savedUsername;
	}

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email,
			password: env.DEFAULT_LOGIN_PASSWORD || '',
			rememberMe: persistentState.rememberMe,
			useBiometricLogin: persistentState.enableBiometricLogin,
		},
	});

	// Screen blur handler
	React.useEffect(() => {
		const unsubscribe = navigation.addListener('blur', () => {
			// Reset the password field after any blur of login screen
			setTimeout(() => {
				form.resetField('password');
			}, 1000);
		});

		return unsubscribe;
	}, [navigation]);

	// Set form values based on that which is stored in settings; based on how the persistentState and settings hooks are created,
	// these values should be readily available before the app loads, and thus no need to include them as deps
	React.useEffect(() => {
		if (!env.DEFAULT_LOGIN_USERNAME && persistentState.savedUsername) {
			form.setValue('email', persistentState.savedUsername);
		}

		form.setValue('rememberMe', persistentState.rememberMe);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// If initial username needs to be gotten from auth context after form has already been instantiated,
	// then field must be updated manually rather than via the initial input default
	React.useEffect(() => {
		if (auth.firstTimeUsername) {
			form.setValue('email', auth.firstTimeUsername);

			// If email has an error, revalidate to ensure there's no stale error after field population
			if (form.formState.errors.email !== undefined) {
				form.trigger('email');
			}
		}
	}, [auth.firstTimeUsername]);

	const [loginError, setLoginError] = React.useState<LoginAPIErrorCode>(LoginAPIErrorCode.None);
	const [userDisabledErrorMessage, setUserDisabledErrorMessage] = React.useState<string>('');
	const [remainingLoginAttempts, setRemainingLoginAttempts] = React.useState(-1);

	async function attemptLogin({ email, password, rememberMe, useBiometricLogin }: FormSchema) {
		resetStateVariables();

		// attempt login
		const response = await auth.login.execute(
			String(email),
			password,
			Boolean(rememberMe),
			Boolean(useBiometricLogin),
			() => {
				return new Promise<boolean>((resolve) => {
					// Prompt PPT to enable bio auth if they've signed in at least once but haven't turned on bio auth
					if (
						bioAuthService.isBioAuthAvailable &&
						!useBiometricLogin &&
						persistentState.hasEverLoggedIn &&
						!persistentState.hasSeenBioAuthPrompt
					) {
						persistentState.setHasSeenBioAuthPrompt(true);
						mobeAlert(
							t('auth.biometricAlert.title'),
							t('auth.biometricAlert.message', { biometricMessage: bioAuthContent.settingsLabel }),
							[
								{
									text: t('auth.biometricAlert.decline'),
									onPress: () => resolve(false),
								},
								{
									text: t('auth.biometricAlert.enable'),
									onPress: () => resolve(true),
								},
							]
						);
					} else {
						resolve(Boolean(useBiometricLogin));
					}
				});
			}
		);

		handleLoginResponse(response);
	}

	function initiateSignUp() {
		resetStateVariables();
		Keyboard.dismiss();

		// Pushed to end of event cycle to avoid keyboard offset issue on Android (DIG-684)
		setTimeout(() => {
			navigation.navigate('ELIGIBILITY_CHECK_SCREEN');
		}, 0);
	}

	function handleSubmitPress() {
		Keyboard.dismiss();

		if (auth.login.isPending) {
			return;
		}

		form.handleSubmit(attemptLogin)();
	}

	function handleBioAuthLoginPress() {
		attemptBioAuthLogin();
	}

	function handleOnChangeRememberMe() {
		const { rememberMe } = form.getValues();

		if (rememberMe === false) {
			form.setValue('useBiometricLogin', false);
		}

		AuthAnalyticsEvents.toggleRememberMe(rememberMe || false);
	}

	function onPressChangeEmail() {
		form.setValue('email', '');
		navigation.setParams({
			shouldObfuscate: false,
			shouldDisable: false,
		});

		form.setValue('useBiometricLogin', false);
		form.setValue('rememberMe', false);
	}

	function handleOnChangeBioAuth() {
		const { useBiometricLogin } = form.getValues();

		if (useBiometricLogin === true) {
			form.setValue('rememberMe', true);
		}

		AuthAnalyticsEvents.toggleBioAuth(useBiometricLogin || false);
	}

	function handleForgotPasswordPress() {
		resetStateVariables();
		Keyboard.dismiss();

		// Pushed to end of event cycle to avoid keyboard offset issue on Android (DIG-1362)
		setTimeout(() => {
			navigation.navigate('FORGOT_PASSWORD_SCREEN');
		}, 0);
	}

	function alert(): IAuthScreenAlert | undefined {
		if (controller.loginError === LoginAPIErrorCode.AccountDisabledOnServer) {
			return {
				alertType: 'error',
				message: controller.userDisabledErrorMessage,
			};
		}

		if (controller.loginError === LoginAPIErrorCode.AccountLocked) {
			return {
				alertType: 'error',
				message: t('auth.login.errors.accountLocked'),
				onPress: () => {
					resetStateVariables();
					navigation.navigate('FORGOT_PASSWORD_SCREEN', { context: 'lockedAccount' });
				},
			};
		}

		if (controller.loginError === LoginAPIErrorCode.Mismatch) {
			return {
				alertType: 'error',
				message: t('auth.login.errors.mismatch', {
					remainingLoginAttempts: controller.remainingLoginAttempts,
				}),
			};
		}

		if (controller.loginError === LoginAPIErrorCode.PasswordExpired) {
			return {
				alertType: 'error',
				message: t('auth.login.errors.passwordExpired'),
			};
		}

		if (controller.loginError === LoginAPIErrorCode.Unknown) {
			return {
				alertType: 'error',
				message: t('auth.login.errors.unknown'),
			};
		}

		if (auth.forgotPasswordWasSubmitted) {
			return {
				alertType: 'standard',
				message: t('auth.forgotPassword.submissionMessage'),
			};
		}

		if (auth.userHasResetPassword) {
			return {
				alertType: 'standard',
				message: t('auth.resetPassword.submissionMessage'),
			};
		}
	}

	const controller = {
		initiateSignUp,
		handleSubmitPress,
		handleBioAuthLoginPress,
		handleForgotPasswordPress,
		handleOnChangeRememberMe,
		handleOnChangeBioAuth,
		onPressChangeEmail,
		alert,
		canUseBioAuth,
		shouldShowBioAuthSwitch,
		form,
		loginError,
		userDisabledErrorMessage,
		remainingLoginAttempts,
		isFetching: auth.login.isPending || bioAuthSignInPending,
	};

	return controller;
}
