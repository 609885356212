import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestMindsetGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(54, 49);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M15.14 35.651L8.783 47.274a1.13 1.13 0 00-.103.892c.087.3.294.55.572.693a1.186 1.186 0 001.602-.483l9.797-17.891c1.55.932 3.496.92 5.036-.029a2.48 2.48 0 012.61 0 4.921 4.921 0 005.035.029l9.476 17.351a1.206 1.206 0 001.597.472c.277-.148.483-.4.572-.699a1.13 1.13 0 00-.097-.887l-6.038-11.07 5.15-9.39 7.789 14.209c.32.555 1.024.76 1.596.466.279-.144.486-.394.573-.694a1.13 1.13 0 00-.098-.892L45.017 23.25a1.186 1.186 0 00-1.036-.606c-.43 0-.827.232-1.036.606L37.498 33.2l-9.328-17.05v-5.115L38.934 6.79a1.17 1.17 0 00.744-1.088c0-.48-.295-.911-.744-1.088L27.444.067l-.23-.063a.536.536 0 00-.137 0h-.315l-.274.097c-.074.04-.145.086-.212.136a.737.737 0 00-.097.086l-.08.09a.705.705 0 00-.115.165l-.068.137-.07.244v15.192l-9.327 17.05-5.482-9.951a1.232 1.232 0 00-2.071 0L.142 39.368a1.167 1.167 0 00.475 1.586 1.2 1.2 0 001.596-.466l7.789-14.209 5.138 9.372zm6.638-7.224l5.213-9.53 5.213 9.53-.062.057a2.48 2.48 0 01-2.61 0 4.835 4.835 0 00-5.093 0 2.47 2.47 0 01-2.598 0l-.063-.057zM28.17 8.535V2.91l7.142 2.841-7.142 2.785z"
					fill="#1A7EAD"
					fillRule="nonzero"
				/>
			</Svg>
		</View>
	);
}
