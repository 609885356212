import { useUnreadChatCountQuery } from '@mobe/api/chat/chatApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery } from '@mobe/api/guides/guidesApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import { useAnnounceForAccessibilityEffect } from '@mobe/utils/a11y';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useAppStateChange } from '@mobe/utils/useAppStateChange';
import { CommonActions, useNavigation } from '@react-navigation/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import Icon from '../icon/Icon';
import Text from '../text/Text';

export default function ChatButton() {
	const navigation = useNavigation();
	const styles = useChatButtonStyles();
	const guideQuery = useGuideQuery();
	const permissionsQuery = usePermissionsQuery();
	const unreadChatCountQuery = useUnreadChatCountQuery();
	const unreadChatCount = unreadChatCountQuery.data?.numberOfUnreadMessages;
	const { t } = useTranslation();
	const shouldShowChatButton = guideQuery.isSuccess && permissionsQuery.isSuccess;

	useAppStateChange({ onActive: unreadChatCountQuery.refetch });

	useAnnounceForAccessibilityEffect(
		unreadChatCount && unreadChatCount > 0
			? t('chat.newChatAccessibilityLabel', { count: unreadChatCount })
			: ''
	);

	function handlePress() {
		GlobalAnalyticsEvents.chatPress(unreadChatCount || 0);

		if (permissionsQuery.data?.hasMedServicesAccess) {
			navigation.dispatch(CommonActions.navigate('CHAT_SCREEN'));
		} else if (guideQuery.data) {
			navigation.dispatch(
				CommonActions.navigate('CHAT_SCREEN', { channelId: guideQuery.data.chatChannelId })
			);
		} else {
			navigation.dispatch(
				CommonActions.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM })
			);
		}
	}

	return shouldShowChatButton ? (
		<Pressable
			role="button"
			aria-label={t('chat.headerButtonAccessibilityLabel', {
				count: unreadChatCount,
			})}
			style={styles.container}
			onPress={handlePress}
			hitSlop={10}
		>
			<Icon name="chat" size={26} />
			{/* Not using border, because the background color bleeds around it with border radius  */}
			{unreadChatCount ? (
				<View style={styles.countContainer}>
					<View style={styles.countInner}>
						<Text color="inverted" weight="bold" align="center" size="xs" style={styles.count}>
							{unreadChatCount}
						</Text>
					</View>
				</View>
			) : null}
		</Pressable>
	) : null;
}

function useChatButtonStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			position: 'relative',
		},
		countContainer: {
			backgroundColor: styleRules.colors.cardBackground,
			borderRadius: 17,
			width: Math.min(30 * styleRules.settings.fontScale, 33),
			height: Math.min(24 * styleRules.settings.fontScale, 26),
			position: 'absolute',
			left: -15,
			top: -10,
		},
		countInner: {
			backgroundColor: styleRules.colors.primary,
			borderRadius: 15,
			position: 'absolute',
			top: 3,
			left: 3,
			right: 3,
			bottom: 3,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		count: {
			lineHeight: 17.5,
			fontSize: Math.min(styleRules.fontSizes.xs, styleRules.staticFontSizes.md),
			textAlignVertical: 'center',
		},
	});
}
