import { createIconSet } from '@expo/vector-icons';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { IColors } from '@mobe/utils/styles/styleRules/types';
import * as React from 'react';
import { StyleProp, TextStyle, ViewProps } from 'react-native';
import iconMap from './iconMap';

export type IconNames = keyof typeof iconMap;
export type IconColors = keyof IColors;

export interface IIconProps extends ViewProps {
	name: IconNames;
	color?: IconColors;
	size?: number;
	style?: StyleProp<TextStyle>;
}

const CustomIcon = createIconSet(iconMap, 'icons', 'icons.ttf');

export default function Icon({
	name,
	color = 'textLight',
	size = 20,
	style,
	...viewProps
}: IIconProps) {
	const styleRules = useStyleRules();

	return (
		<CustomIcon
			name={name}
			size={size}
			color={styleRules.colors[color]}
			style={style}
			{...viewProps}
		/>
	);
}
