import { yupResolver } from '@hookform/resolvers/yup';
import {
	MIN_DOB_AGE_YEARS,
	US_DATE_FORMAT_REGEX,
	validateDateOfBirth,
} from '@mobe/utils/validationUtils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function useEligibilityCheckFormSchema() {
	const { t } = useTranslation();

	return Yup.object({
		firstName: Yup.string().required().label(t('auth.eligibilityCheck.firstNameInputLabel')),
		lastName: Yup.string().required().label(t('auth.eligibilityCheck.lastNameInputLabel')),
		dateOfBirth: Yup.string()
			.matches(US_DATE_FORMAT_REGEX, {
				message: t('forms.errors.invalidDateOfBirthFormat'),
			})
			.test({
				test: validateDateOfBirth,
				message: t('forms.errors.invalidDateOfBirthAge', { age: MIN_DOB_AGE_YEARS }),
			})
			.required()
			.label(t('auth.eligibilityCheck.dateOfBirthInputLabel')),
	});
}

export type EligibilityCheckFormSchema = Yup.InferType<
	ReturnType<typeof useEligibilityCheckFormSchema>
>;

export default function useEligibilityCheckForm() {
	const validationSchema = useEligibilityCheckFormSchema();

	return useForm<EligibilityCheckFormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			firstName: '',
			lastName: '',
			dateOfBirth: '',
		},
	});
}
