import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingSleepGraphic(props: SvgProps) {
	const rules = useStyleRules();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(600, 287);

	return (
		<View style={[wrapperStyles, { maxWidth: rules.spacing.modalMaxWidth, width: '140%' }]}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#F9F9F9" d="M0 0h600v287H0z" />
				<Path
					fill="#F1D4A6"
					d="m383.425 232.881 8.306 18.142 16.007 34.954a1.66 1.66 0 0 0 1.509.972h.129a1.8 1.8 0 0 0 1.406-.71 1.791 1.791 0 0 0 .304-1.545l-12.705-45.47-1.853-6.633-13.103.29zM469.226 232.881l8.306 18.142 16.007 34.954a1.66 1.66 0 0 0 1.509.972h.129a1.797 1.797 0 0 0 1.71-2.255l-12.705-45.47-1.853-6.633-13.103.29z"
				/>
				<Path
					fill="#E5C48E"
					d="M477.521 251.023c-.792-8.246 6.65-11.796 6.65-11.796l-1.853-6.633-13.103.287 8.306 18.142z"
				/>
				<Path
					fill="#E6C48E"
					d="M391.95 251.912c-.792-8.246 6.65-11.795 6.65-11.795l-1.853-6.633-13.103.286 8.306 18.142z"
				/>
				<Path
					fill="#F1D4A6"
					d="M493.525 197.145H114.484a3.43 3.43 0 0 0-3.431 3.431v30.304a3.43 3.43 0 0 0 3.431 3.431h379.041a3.43 3.43 0 0 0 3.43-3.431v-30.304a3.43 3.43 0 0 0-3.43-3.431z"
				/>
				<Path
					fill="#D0B08D"
					d="m128.203 232.594-1.85 6.633-12.701 45.47a1.793 1.793 0 0 0 1.71 2.255h.133a1.66 1.66 0 0 0 1.509-.971l16.003-34.954 8.306-18.142-13.11-.291zM214.383 232.594l-1.854 6.633-12.704 45.47a1.793 1.793 0 0 0 1.71 2.255h.129a1.658 1.658 0 0 0 1.509-.971l16.006-34.954 8.307-18.142-13.103-.291z"
				/>
				<Path
					fill="#D0B08D"
					d="M251.084 105.091H110.519a4.61 4.61 0 0 0-4.61 4.611v119.999a4.61 4.61 0 0 0 4.61 4.611h140.565a4.61 4.61 0 0 0 4.61-4.611V109.702a4.61 4.61 0 0 0-4.61-4.611z"
				/>
				<Path
					fill="#F4DCB4"
					d="M257.408 105.091H114.484a3.43 3.43 0 0 0-3.431 3.431v103.775a3.432 3.432 0 0 0 3.431 3.432h142.924a3.431 3.431 0 0 0 3.43-3.432V108.522a3.43 3.43 0 0 0-3.43-3.431z"
				/>
				<Path
					fill="#F1D4A6"
					d="M260.842 172.897v39.4a3.44 3.44 0 0 1-3.431 3.432H114.484a3.443 3.443 0 0 1-3.431-3.432V108.518a3.442 3.442 0 0 1 3.431-3.431h15.856c9.98 12.71 46.303 53.136 110.348 61.78 8.514 1.147 15.124 3.234 20.154 6.03z"
				/>
				<Path
					fill="#F1D4A6"
					d="M138.288 207.056s-26.299-2.098-27.245-2.743v-24.141c5.546-5.245 16.433-11.215 16.433-11.215l10.812 38.099z"
				/>
				<Path
					fill="#D0B08D"
					d="M146.214 135.965a2 2 0 1 1 4-.002 2 2 0 0 1-4 .002zM226.251 135.965a2 2 0 1 1 4-.002 2 2 0 0 1-4 .002z"
				/>
				<Path
					fill="#4250CF"
					d="M146.214 173.701a2.007 2.007 0 0 1 1.237-1.846 1.997 1.997 0 0 1 2.179.436 2.004 2.004 0 0 1 .433 2.18 2.006 2.006 0 0 1-1.848 1.234 1.995 1.995 0 0 1-1.415-.587 1.99 1.99 0 0 1-.433-.65 1.985 1.985 0 0 1-.153-.767zM226.251 173.701a2.007 2.007 0 0 1 1.237-1.846 1.998 1.998 0 0 1 2.179.436 1.997 1.997 0 0 1-.305 3.077 1.994 1.994 0 0 1-1.877.184 2.009 2.009 0 0 1-1.234-1.851z"
				/>
				<Path
					fill="#D0B08D"
					d="M370.332 200.576v30.304a3.463 3.463 0 0 1-3.48 3.431H110.846a3.464 3.464 0 0 1-3.478-3.431v-30.304a3.465 3.465 0 0 1 3.478-3.431h255.996c.917-.006 1.8.351 2.455.994a3.47 3.47 0 0 1 1.035 2.437z"
				/>
				<Path
					fill="#010117"
					d="M190.807 169.7s-2.574 21.153 0 28.209h9.622s-2.667-24.398-9.622-28.209z"
				/>
				<Path
					fill="#6D89CF"
					d="M190.807 169.7s26.299-6.576 45.17-7.433c0 0 17.437 14.582 19.717 34.879l-57.384.763s-.358-15.636-7.503-28.209z"
				/>
				<Path
					fill="#EFF6F9"
					d="M127.487 168.957s-5.112 17.992 7.371 28.206l29.823-.114s-30.236-24.281-37.194-28.092z"
				/>
				<Path
					fill="#FFF"
					d="M127.487 168.957s26.299-6.575 45.17-7.432c0 0 18.036 15.342 20.323 35.639h-32.107s-23.761-5.163-33.386-28.207z"
				/>
				<Path
					fill="#A77038"
					d="M190.728 165.462c-7.654 1.681-11.59-1.714-14.698-5.217a51.994 51.994 0 0 1-.746-.86c-2.943-3.449-16.91-15.224-24.288-6.515a13.42 13.42 0 0 0-1.907 3.051s-2.384.868-3.026 1.703a2.845 2.845 0 0 1 1.344-.452c-1.276 1.076-2.918 2.847-3.585 5.059-.358 1.209 0 3.145.936 5.447 1.129 2.839 3.083 6.242 5.503 9.544 5.116 6.974 12.321 13.499 18.283 13.356-1.588-.585-4.062-1.955-7.697-5.142.509.402 5.252 4.109 9.274 4.948 11.318-2.151 30.719 10.796 33.236 13.929 0 0 5.406-12.19 5.786-17.066.743-9.215-2.129-25.37-18.415-21.785z"
				/>
				<Path
					fill="#F2D5A5"
					d="M158.736 194.356s-3.377-1.075-5.847-1.144c-2.47-.068-8.22 3.901-7.862 3.944.359.043 16.491 0 16.491 0l-2.782-2.8zM169.466 190.52c-.021.05 2.509 5.378 6.6 2.104 2.692-2.151 6.252-9.877 3.99-11.874-1.076-.954-5.316-2.363-10.615-8.767l-.409.502-6.861 8.48s2.344 2.431 4.381 4.861c1.71 2.023 3.201 4.066 2.914 4.694z"
				/>
				<Path
					fill="#A77038"
					d="M169.014 170.653s-1.183-5.737-3.585-9.322c-1.717-2.553-10.615-9.555-17.606-.563a15.338 15.338 0 0 0-2.9 6.138c-.613 2.89-.677 7.913 8.962 12.212l3.033 1.667 12.096-10.132z"
				/>
				<Path
					fill="#F2D5A5"
					d="M163.451 184.783s10.353-9.68 4.234-16.026-8.905-11.886-15.774-5.981-5.8 9.548-4.273 12.169c1.527 2.621 12.977 12.173 15.813 9.838z"
				/>
				<Path
					fill="#A77038"
					d="M147.114 167.082s9.98 4.758 15.326 1.725c5.345-3.033 6.094-5.973 6.094-5.973a16.872 16.872 0 0 1-10.884-3.338s-11.852 2.011-10.536 7.586z"
				/>
				<Path
					fill="#A77038"
					d="M148.39 167.021s-.48 3.675.574 5.78c1.054 2.104 4.037 5.658 2.958 6.482 0 0-6.99-3.037-7.392-8.182-.406-5.453 3.86-4.08 3.86-4.08z"
				/>
				<Path
					fill="#5979A4"
					d="M421.511 220.465s-28.533-19.644-52.448-22.871a47.013 47.013 0 0 0-6.295-.448c-11.317 0-27.79 1.792-44.955 5.804-20.545 4.783-42.087 12.696-56.971 24.381-27.328 21.437-86.727 30.013-86.727 30.013-32.157-29.604-20.151-54.885-19.29-59.431.057-.271.161-.529.308-.764 2.983-5.087 24.779-17.127 22.169-30.278-2.319-11.682 7.6-15.844 25.119-18.261 3.266-.448 6.811-.839 10.568-1.204 45.561-4.436 81.625 16.18 115.051 14.865 8.974-.37 17.963-.044 26.887.975l.756.089a.384.384 0 0 1 .083 0c51.734 6.264 77.305 34.567 77.305 34.567.872 11.326-11.56 22.563-11.56 22.563z"
				/>
				<Path
					fill="#FFF"
					d="M236.605 18.011a1.01 1.01 0 0 1 1.719-.718 1.013 1.013 0 0 1-.151 1.552 1.008 1.008 0 0 1-1.568-.834z"
				/>
				<Path
					fill="#6585B0"
					d="M354.917 163.242c-15.164-1.097-66.883 27.249-87.629 8.458-21.151-19.153-64.887-23.093-64.887-23.093 3.266-.449 6.811-.839 10.568-1.205 45.561-4.435 81.625 16.181 115.051 14.865a173.99 173.99 0 0 1 26.897.975zM433.087 197.909c-23.614 11.319-63.335-32.243-77.305-34.567 51.734 6.264 77.305 34.567 77.305 34.567z"
				/>
				<Path
					fill="#3E5870"
					d="M317.813 202.95c-20.545 4.783-42.087 12.696-56.971 24.381-27.328 21.437-86.727 30.013-86.727 30.013-32.157-29.604-20.151-54.885-19.29-59.431.057-.271.161-.529.308-.764 0 0 11.26 15.722 37.843 17.153 19.62 1.05 81.481-5.973 124.837-11.352z"
				/>
				<Path
					fill="#A77038"
					d="M176.03 160.245s-10.5-4.363-14.501-6.841c-4.001-2.477-10.533-.534-10.533-.534 7.378-8.709 21.345 3.066 24.288 6.515.251.301.499.573.746.86z"
				/>
			</Svg>
		</View>
	);
}
