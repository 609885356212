import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View, ViewProps } from 'react-native';

type boxVariant = 'standard' | 'outline';

interface IBoxProps extends ViewProps {
	row?: boolean;
	variant?: boxVariant;
	children: React.ReactNode;
}

export default function Box({
	row = false,
	variant = 'standard',
	children,
	style,
	...viewProps
}: IBoxProps) {
	const styleRules = useStyleRules();
	return (
		<View
			style={[
				{
					backgroundColor: styleRules.colors.background,
					padding: styleRules.spacing.appHorizontalMargin,
					flexDirection: row ? 'row' : 'column',
					justifyContent: 'center',
					flexWrap: row ? 'wrap' : undefined,
					borderRadius: styleRules.borderRadius,

					...(variant === 'outline' && {
						borderWidth: 1,
						borderColor: styleRules.colors.strokeLight,
						backgroundColor: styleRules.colors.cardBackground,
					}),
				},
				style,
			]}
			{...viewProps}
		>
			{children}
		</View>
	);
}
