import env from '@mobe/env/env';
import AsyncStorage, { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const ASYNC_STORAGE_KEY = 'asyncStorage';

export function useAsyncStorageQuery<StoredValueType>(
	key: string,
	initialData?: StoredValueType,
	placeholderData?: StoredValueType
) {
	const asyncStorage = useAsyncStorage(key);

	return useQuery<StoredValueType>(
		[ASYNC_STORAGE_KEY, key],
		async () => {
			const value = await asyncStorage.getItem();

			if (value) {
				return JSON.parse(value);
			}

			if (initialData) {
				asyncStorage.setItem(JSON.stringify(initialData));
				return initialData;
			}
		},
		{
			staleTime: Infinity,
			keepPreviousData: true,
			placeholderData,
		}
	);
}

export function useAsyncStorageMutation<NewValueType>(key: string) {
	const queryClient = useQueryClient();
	const asyncStorage = useAsyncStorage(key);

	return useMutation<void, unknown, NewValueType>(
		async (newValue) => {
			return await asyncStorage.setItem(JSON.stringify(newValue));
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([ASYNC_STORAGE_KEY, key]);
			},
		}
	);
}

export function useRemoveFromAsyncStorage() {
	const queryClient = useQueryClient();

	return (queryKeys: string[] = []) => {
		queryKeys.forEach(async (key) => {
			if (env.isDev) {
				console.warn(`Removing: ${key} from async storage`);
			}

			await AsyncStorage.removeItem(key);
			queryClient.invalidateQueries([ASYNC_STORAGE_KEY, key]);

			if (env.isDev) {
				console.info(`Removed: ${key} from async storage`);
			}
		});
	};
}
