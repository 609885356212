import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useContentWrapper } from '@mobe/features/explore/components/useContentWrapper';
import { noop } from 'lodash';
import * as React from 'react';
import { View } from 'react-native';
import { TVideoFormats, useMobeVideoPlayer } from './useMobeVideoPlayer';
import { useMobeVideoPlayerStyles } from './useMobeVideoPlayerStyles';

interface IMobeVideoPlayerProps {
	uri: string;
	poster?: string;
	type: TVideoFormats;

	/**
	 * This value should represent the onLayout rendered width of the parent container.
	 * This is necessary to properly calculate the the aspect ratio of the video.
	 */
	renderedWidth: number;
	onPlayPress?: () => void;
	onWatched?: () => void;
}

// currently only Wistia is accounted for
export default function MobeVideoPlayer({
	uri,
	type = 'file',
	renderedWidth,
	onPlayPress = noop,
	onWatched = noop,
}: IMobeVideoPlayerProps) {
	const auth = useAuth();
	const styles = useMobeVideoPlayerStyles();
	const { isWistia, wistiaId } = useMobeVideoPlayer(type, uri);
	const videoEmbedWidth = Math.floor(renderedWidth);
	const videoEmbedHeight = Math.floor(videoEmbedWidth * 0.5625);

	const wistiaWrappedContent = useContentWrapper(`
		<script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
		<div style="overflow: hidden">
			<div class="wistia_embed wistia_async_${wistiaId}" style="width:${videoEmbedWidth}px; position: relative; height: ${videoEmbedHeight}px"></div>
		</div>
		<script>
			window._wq = window._wq || [];
			_wq.push({
				id: '${wistiaId}',
				onReady: function(video) {
					video.bind("play", function() {
						window.parent.postMessage({message: "videoHasBeenSeen"},"*")
					});
				}
			});
		</script>
	`);

	React.useEffect(() => {
		function handleMessage(event: MessageEvent) {
			const messageData = event.data.message;

			if (!messageData) {
				return;
			}

			if (messageData === 'videoHasBeenSeen') {
				onPlayPress();
				onWatched();
			}

			// Fire user activity event to refresh app timeout
			if (messageData === 'play' || messageData === 'watching') {
				auth.onUserActivity();
			}
		}

		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, []);

	return (
		<View style={[styles.videoPlayerWrapper, { width: videoEmbedWidth, height: videoEmbedHeight }]}>
			{isWistia && (
				<View style={styles.videoPlayer}>
					<iframe
						height={videoEmbedHeight}
						width={videoEmbedWidth}
						srcDoc={wistiaWrappedContent}
						style={{ border: 0 }}
					/>
				</View>
			)}
		</View>
	);
}
