import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { Announce } from '../announce/Announce';

export interface IStepIndicator {
	totalSteps: number;
	currentStepIndex: number;
	variant?: 'headerBackground' | 'standalone';
}

export default function StepIndicator({
	totalSteps,
	currentStepIndex,
	variant = 'headerBackground',
}: IStepIndicator) {
	const styles = useStyles();
	const { t } = useTranslation();
	const steps = Array(totalSteps)
		.fill('')
		.map((value, index) => index);

	const stepProgressLabel = t('stepIndicator.stepsRemainingLabel', {
		currentStep: currentStepIndex + 1,
		totalSteps,
	});

	return (
		<View style={variant === 'headerBackground' && styles.background}>
			<View
				style={styles.stepContainer}
				accessible
				aria-label={t('stepIndicator.label')}
				role="progressbar"
				aria-valuetext={stepProgressLabel}
				aria-valuenow={currentStepIndex + 1}
				aria-valuemin={1}
				aria-valuemax={totalSteps}
			>
				{steps.map((value, index) => (
					<View
						key={value}
						style={[styles.step, currentStepIndex >= index ? styles.step_current : {}]}
					></View>
				))}
			</View>

			<Announce message={stepProgressLabel} />
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { headerShadow } = useStyleHelpers();
	const gutterSize = 6;

	return StyleSheet.create({
		background: {
			flex: 1,
			justifyContent: 'flex-end',
			backgroundColor: rules.colors.cardBackground,
			...headerShadow,

			...(Platform.OS !== 'web' && {
				borderTopLeftRadius: rules.borderRadiusModal,
				borderTopRightRadius: rules.borderRadiusModal,
			}),
		},
		stepContainer: {
			height: 4,
			flexGrow: 1,
			flexDirection: 'row',
			alignItems: 'flex-end',
			marginLeft: 0 - gutterSize,
		},
		step: {
			height: 4,
			flexGrow: 1,
			marginLeft: gutterSize,
			backgroundColor: rules.colors.strokeLight,
		},
		step_current: {
			backgroundColor: rules.colors.primary,
		},
	});
}
