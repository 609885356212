import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(172, 115);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="none" fillRule="evenodd">
					<Path
						d="M4.013 44.015a1.65 1.65 0 01-1.432 1.838 1.643 1.643 0 01-1.835-1.43C-1.869 23.272 12.791 3.817 33.845.5c.899-.14 1.738.473 1.88 1.37.14.9-.475 1.74-1.373 1.883C15.053 6.793 1.615 24.626 4.013 44.015zm10.825-1.53a1.65 1.65 0 01-1.402 1.86c-.9.127-1.732-.5-1.858-1.4-2.136-15.302 8.533-29.46 23.831-31.624.9-.127 1.732.5 1.858 1.4a1.65 1.65 0 01-1.402 1.86c-13.5 1.907-22.915 14.402-21.027 27.903zm10.75-2.109a1.646 1.646 0 01-3.258.462c-1.26-9.001 5.017-17.33 14.017-18.602a1.646 1.646 0 01.454 3.259c-7.198 1.018-12.22 7.68-11.213 14.88v.001z"
						fill="#DEEFF7"
						fillRule="nonzero"
					/>
					<Path
						d="M91.484 73.57a1.606 1.606 0 01.584 2.36L63.389 115H1l46.211-62.951c.621-.84 3.63-.124 4.583.38l39.69 21.14z"
						fill="#F7A882"
					/>
					<Path
						d="M91.484 73.57a1.606 1.606 0 01.584 2.36L63.389 115H44.031l34.83-47.43h1.311l11.312 6z"
						fill="#E97F59"
						opacity={0.337}
					/>
					<Path
						d="M47.393 51.883l-.68.85-5.155 7.017a.835.835 0 00.032.273s2.227-1.488 6.07-.417c.081.615.156 1.236.242 1.846.016.123.041.245.075.364a2.323 2.323 0 001.102 1.327l22.158 12.09a2.848 2.848 0 003.555-.866l.632-.846c6.285 4.72 5.321 15.168 4.818 18.454l2.917-3.982a87.398 87.398 0 004.042-16.714L51.767 52.407c-.84-.444-3.517-1.092-4.374-.524z"
						fill="#E97F59"
						opacity={0.337}
					/>
					<Path
						d="M117.31 13.406c1.279-.653 2.612-1.659 6.423.07 3.812 1.729 9.995 6.893 10.616 9.853.16.76.198 5.86-1.386 7.6-.364.402-.771.803-1.221 1.215 0 0-1.028-.535 0 0s4.32.225 6.703 3.747c2.382 3.521-1.548 8.873-2.87 11.485s-3.458 12.626-7.329 14.611c-3.87 1.986-12.505 3.115-15.477 5.46-2.97 2.344-11.054 4.656-13.083 4.929-2.03.273-8.657.803-8.657.803-9.144-8.366-8.373-17.507-5.509-24.368l-2.874-1.43-2.142 3.388-10.043-5.004-2.082 3.318-13.047-6.497 1.606-2.949s.241-6.396 3.212-11.24C63.121 23.555 77.817 5.54 77.817 5.54s2.933-2.885 7.65-.755a15.525 15.525 0 014.208 2.773l.273-.332s2.928-2.885 7.644-.755a15.76 15.76 0 014.631 3.168l.428-.535s2.934-2.884 7.65-.754c.89.412 7.072 4.88 7.008 5.057z"
						fill="#F7A882"
					/>
					<Path
						d="M132.957 30.93c-.364.4-.77.802-1.22 1.214 0 0-1.028-.535 0 0s4.32.225 6.702 3.747c2.382 3.521-1.547 8.873-2.87 11.485-1.322 2.612-3.458 12.626-7.328 14.611-3.87 1.986-12.506 3.115-15.477 5.46-2.971 2.344-11.055 4.656-13.084 4.929-2.029.273-8.656.803-8.656.803-5.552-5.08-7.447-10.442-7.442-15.404 1.633-1.686-3.983-9.559 5.664-3.982 9.647 5.577 26.377-16.591 28.818-20.31 2.441-3.72 6.96-11.861 9.358-11.412 4.743.889 5.273 2.623 5.535 8.858z"
						fill="#E97F59"
						opacity={0.337}
					/>
					<Path
						d="M62.736 40.199a9.751 9.751 0 00-6.124-.321c-1.638 1.996-10.525 12.898-12.313 15.393 0 .101-.065.278-.075.347a1.14 1.14 0 000 .466s2.42-1.648 6.574-.268L62.736 40.2z"
						fill="#578FB4"
					/>
					<Path
						d="M63.24 38.444a9.751 9.751 0 00-6.13-.316L45.14 53.825a4.19 4.19 0 00-.879 1.793c.075-.278.536-.979 1.847-1.477a7.304 7.304 0 015.22-.08l11.911-15.617z"
						fill="#6FA3C5"
					/>
					<Path
						d="M86.87 50.464L66.45 39.316a2.838 2.838 0 00-3.538.856L51.419 55.324l-1.178-.059c.102.733.187 1.483.295 2.216.017.123.042.244.074.364.174.56.558 1.032 1.071 1.317l22.03 12.026c1.233.603 2.72.24 3.538-.862l11.954-15.783a2.087 2.087 0 00.413-1.569c-.123-.834-.22-1.685-.343-2.526l-1.119.37-1.408-1.07.123.716z"
						fill="#E6E6E6"
					/>
					<Path
						d="M86.87 50.464l-13.288-7.246 3.662 27.156.032-.043L89.23 54.548c.1-.13.185-.272.252-.423L89 50.55l-.819.273-1.408-1.07.096.711zM69.251 40.873l-2.816-1.536a2.838 2.838 0 00-3.538.857l-11.478 15.13-.712-.037.466 3.478c.16.156.34.289.535.397l21.601 11.774-4.058-30.063z"
						fill="#CCC"
					/>
					<Path
						d="M88.138 48.902L66.103 36.875a2.832 2.832 0 00-3.538.862L50.61 53.52a2.14 2.14 0 00.766 3.212l22.035 12.004a2.832 2.832 0 003.533-.861l11.96-15.762a2.14 2.14 0 00-.766-3.211z"
						fill="#FFF"
					/>
					<Path
						d="M87.865 49.389a1.606 1.606 0 01.578 2.354L76.49 67.527a2.265 2.265 0 01-2.805.706L51.649 56.207a1.606 1.606 0 01-.578-2.36l11.954-15.783a2.265 2.265 0 012.805-.702L87.865 49.39z"
						fill="#366"
					/>
					<Path
						d="M87.619 49.067a1.606 1.606 0 01.578 2.36l-11.778 15.56c-.867 1.14-1.809 1.236-2.805.7L51.826 55.982a1.606 1.606 0 01-.584-2.36L63.02 38.064a2.27 2.27 0 012.805-.702l21.794 11.705z"
						fill="#172D2D"
					/>
					<Path
						d="M64.772 50.573c-.638 1.022-.635 2.25-.09 3.153.124.206-.053.49-.292.468-1.052-.094-2.156.442-2.795 1.464-.878 1.406-.543 3.2.749 4.008.375.234.789.358 1.209.384 1.264.183 5.722-1.454 7.007-1.94a.502.502 0 00.315-.506c-.105-1.135-.455-4.576-.981-6.32-.133-.732-.54-1.388-1.191-1.795-1.292-.807-3.052-.322-3.93 1.084M71.832 43.598l-2.12 3.394a1.5 1.5 0 01-2.544-1.59l2.12-3.394a1.5 1.5 0 012.544 1.59"
						fill="#FC4725"
					/>
					<Path
						d="M75.832 46.598l-2.12 3.394a1.5 1.5 0 01-2.544-1.59l2.12-3.394a1.5 1.5 0 012.544 1.59"
						fill="#57CCCC"
					/>
					<Path
						d="M80.832 49.598l-2.12 3.394a1.5 1.5 0 01-2.544-1.59l2.12-3.394a1.5 1.5 0 012.544 1.59"
						fill="#2DB151"
					/>
					<Path
						d="M89.236 54.446c3.26 5.144-2.195 29.212-5.536 33.381l-.696.873-.171.139-.284.043s2.832-13.74-4.641-19.348l-.145-1.07 11.473-14.018z"
						fill="#578FB4"
					/>
					<Path
						d="M89.086 53.37c2.735 2.436 5.862 9.1 4.978 18.733-3.265 6.653-8.645 14.45-10.776 16.372a3.678 3.678 0 01-.455.348 4.816 4.816 0 001.135-2.382c1.204-5.887-.086-15.473-5.354-18.973l10.472-14.097z"
						fill="#6FA3C5"
					/>
					<Path
						d="M91.13 55.95L80.82 69.56a9.437 9.437 0 00-2.211-2.076l10.209-13.766.273-.358c.806.756 1.494 1.63 2.04 2.59z"
						fill="#578FB4"
					/>
					<Path
						d="M85.103 55.517l-6.5 8.563c-2.676-2.574-5.026-5.55-2.874-8.446 2.59-3.478 6.96-1.327 6.96-1.327l2.414 1.21z"
						fill="#102121"
					/>
					<Path
						d="M82.512 67.313a25.932 25.932 0 01-2.27-1.702l-.091-.08 5.62-6.873.05 4.282-3.31 4.373z"
						fill="#366"
					/>
					<Path
						d="M89.187 80.65c.295-8.146-4.282-12.015-6.665-13.337a9.952 9.952 0 01-2.27-1.702l-.15-.14 8.984-12.1c2.735 2.435 5.862 9.098 4.978 18.732a87.576 87.576 0 01-4.877 8.547z"
						fill="#578FB4"
					/>
					<Path
						d="M89.739 57.797l-7.227 9.516a25.932 25.932 0 01-2.27-1.702l-.15-.14 6.75-9.098 2.897 1.424z"
						fill="#578FB4"
					/>
					<Path
						d="M172 62.228c-4.475-4.966-7.725-8.857-8.806-9.746-4.781-3.944-8.502-5.293-11.087-7.84-2.292-2.26-2.795-2.035-5.354-3.255-1.488-.712-8.651.3-12.66 2.007-4.01 1.708-10.381 5.272-13.08 8.028-11.429 3.956-12.27 8.29-12.19 9.254l-27.088-9.2s-4.577-1.574-6.718 2.242c-2.142 3.816 2.238 6.851 5.782 9.292 3.544 2.44 24.92 11.533 25.942 11.544 1.023.01 14.455 8.564 14.455 8.564l-10.332-.798a11.748 11.748 0 00-7.003-2.14c-4.379 0-10.948 2.204-10.434 7.101.407 3.87 5.46 3.645 7.56 3.383.562-.07.91-.145.91-.145 2.221 1.306 3.581 2.232 6.66 3.918.262.145 10.02 2.071 11.654 2.29 1.632.22 5.583 3.175 7.11 3.651 5.256 1.643 22.387-1.846 25.043-1.606 1.36.113 10.75 7.295 19.636 14.312V62.228z"
						fill="#F7A882"
					/>
					<Path
						d="M172 100.913c-5.889-4.008-10.975-7.37-12.688-8.28-4.94-2.633-6.895-1.466-8.453-.535-4.454 2.676-20.766 5.754-25.621 2.291-2.506-1.788-3.7-2.216-4.733-2.376-.508-.075-11.542-3.886-18.202-4.314-3.062-.198-6.258.904-8.817-.086.664 3.511 5.477 3.297 7.495 3.04.562-.07.91-.144.91-.144 2.222 1.306 3.582 2.232 6.66 3.918.262.144 10.022 2.07 11.654 2.29 1.633.22 5.584 3.174 7.11 3.65l.257.075.273.065.283.058.306.054.315.043.338.043.342.032h4.551l.466-.027.47-.032.477-.032.482-.038.482-.043.492-.042.493-.049.492-.048.493-.048.498-.053.498-.06.497-.053.498-.059.493-.059.492-.064.493-.059.487-.064.482-.059.482-.064.476-.064.466-.06.466-.063.455-.06.45-.063.438-.06.434-.058.423-.054.412-.059.407-.053.39-.048.38-.054.37-.048.354-.043.342-.043.332-.037.31-.032.3-.032.284-.032H152.358c1.36.112 10.75 7.295 19.637 14.311l.005-12.219zM74.664 54.51c-1.12 3.415 2.858 6.209 6.145 8.473 3.539 2.446 24.92 11.534 25.943 11.544 1.023.011 14.454 8.564 14.454 8.564 4.594.572 8.127.535 8.288-.06.401-1.498-2.484-3.746-5.691-5.726s-12.848-6.214-15.638-7.418c-2.789-1.204-24.46-8.215-27.27-9.634-2.27-1.118-5.188-3.42-6.231-5.742z"
						fill="#E97F59"
						opacity={0.337}
					/>
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
