import { FlexAlignType, StyleSheet } from 'react-native';

export type TextButtonAlign = 'left' | 'center' | 'right';

const alignmentMap: { [key in TextButtonAlign]: FlexAlignType } = {
	left: 'flex-start',
	center: 'center',
	right: 'flex-end',
};

export default function useTextButtonStyles({
	active,
	disabled,
	inline,
	align,
}: {
	active: boolean;
	disabled: boolean;
	inline: boolean;
	align: TextButtonAlign;
}) {
	const textButtonStyles = StyleSheet.create({
		pressable: {
			opacity: active ? 0.8 : 1,
			alignSelf: alignmentMap[align],
		},
		textButton: {
			opacity: disabled ? 0.8 : 1,
			...(inline && { lineHeight: undefined }), // not working in Android
		},
	});

	return textButtonStyles;
}
