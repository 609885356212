import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export type ClientProgramReferral = {
	id: number;
	programName: string;
	contentHtml: string;
	url: string | null;
	phone: string | null;
	fileUrl: string | null;
	imageUrl: string;
	logoUrl: string;
};

export type ClientProgramReferralsResponse = ClientProgramReferral[];

export async function getClientProgramReferrals() {
	return mobeStandardQueryAndMutationProcessor<ClientProgramReferralsResponse>(
		await (async () => {
			try {
				const response = await mobeAuthenticatedAPI.get<ClientProgramReferralsResponse>(
					'client-program-referrals'
				);

				return mobeStandardSuccessProcessor<ClientProgramReferralsResponse>(response);
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}
