import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingActivityGraphic(props: SvgProps) {
	const rules = useStyleRules();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(600, 287);

	return (
		<View style={[wrapperStyles, { maxWidth: rules.spacing.modalMaxWidth, width: '140%' }]}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#F9F9F9" d="M0 0h600v287H0z" />
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#62A684"
					d="M18.851 150.89A31.476 31.476 0 0 0 0 157.124v50.8a31.478 31.478 0 0 0 18.851 6.234c17.467 0 31.626-14.163 31.626-31.634s-14.16-31.634-31.626-31.634z"
				/>
				<Path
					fill="#62A684"
					d="M202.461 129.924c-.934-20.683-17.748-38.348-38.921-38.348-10.769 0-20.494 4.447-27.458 11.598C129.053 95.106 118.713 90 107.175 90c-1.339 0-2.661.075-3.964.21-20.366 1.634-36.388 18.667-36.388 39.454 0 21.867 17.727 39.594 39.594 39.594 11.616 0 22.063-5.002 29.306-12.969 6.987 7.374 16.858 11.984 27.817 11.984 21.173-.001 39.878-17.19 38.921-38.349z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#62A684"
					d="M356.289 186.184c0 33.913-27.485 61.406-61.39 61.406s-61.39-27.493-61.39-61.406c0-33.914 27.485-61.406 61.39-61.406s61.39 27.492 61.39 61.406z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M288.145 157.929c-2.267 0-4.481.218-6.633.616-7.467-21.117-27.593-36.25-51.263-36.25a54.087 54.087 0 0 0-29.529 8.73 44.462 44.462 0 0 0-20.105-4.784c-14.408 0-27.214 6.83-35.383 17.421-5.431-3.4-11.765-5.473-18.558-5.827.565-2.139.869-4.385.869-6.701 0-14.46-11.722-26.183-26.183-26.183-9.832 0-18.394 5.422-22.87 13.437a53.464 53.464 0 0 0-8.943-.763c-29.391 0-53.216 23.826-53.216 53.216 0 1.313.064 2.61.157 3.899-.053 0-.105-.004-.157-.004A27.302 27.302 0 0 0 0 180.129v44.065a27.302 27.302 0 0 0 16.331 5.393c10.367 0 19.389-5.754 24.051-14.241a52.952 52.952 0 0 0 29.165 8.711 52.991 52.991 0 0 0 23.452-5.442v17.343h111.527l8.803-7.593a54.274 54.274 0 0 0 16.92 2.699 54.127 54.127 0 0 0 32.542-10.812c6.545 6.388 15.488 10.329 25.354 10.329 20.056 0 36.316-16.264 36.316-36.325 0-20.063-16.26-36.327-36.316-36.327z"
				/>
				<Path fillRule="evenodd" clipRule="evenodd" fill="#F5D4B4" d="M0 287h600v-74H0v74z" />
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#2D393F"
					d="M366.28 80.835c-.774-.809-5.489-1.044-7.244 1.201-1.465 1.875-1.153 4.969-3.735 3.879-4.605-1.944-7.238-.477-9.78 6.061-.659.67-1.69.749-2.623.853-8.127.741-10.513 10.054-3.681 12.865 2.633 1.083 1.945 3.238 1.679 5.827-.178 7.98 9.992 11.246 15.93 6.939 3.247-2.62 4.906-1.933 8.567-2.659 3.765-1.167 5.368-5.443 5.188-9.11-.263-4.524-.091-4.531 2.614-7.902 1.941-3.269-.741-7.935 1.36-11.105.483-.729 1.198-1.697.596-2.331l-8.871-4.518z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#403981"
					d="M409.904 127.329s-22.123-2.972-28.793-1.487c-1.682-5.648-2.059-15.524-2.654-19.358-1.737-11.201-12.483-10.047-11.287 4.507 1.52 18.506 7.057 23.851 13.321 23.832 5.361.268 28.444-4.69 28.444-4.69l.969-2.804z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#AA765C"
					d="m403.526 164.266-2.013 11.599-3.216 20.547-6.896 5.749s-2.665-12.151-3-23.75c-.185-6.201 1.753-9.681 1.537-15.932l-17.315 5.559-7.85-19.744s21.396.766 33.233 3.248c7.329 1.539 5.52 12.724 5.52 12.724z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7A4692"
					d="m403.526 164.266-1.816 10.465c-4.373.688-10.491.792-13.661 1.369.643-6.532 2.435-8.813-1.162-10.732-3.201-1.707-13.771 3.615-13.771 3.615l-6.451-21.296s21.594-1.13 32.877 3.222c7.778 3.002 3.984 13.357 3.984 13.357z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M387.66 210.988a1.581 1.581 0 0 1-.727-1.74 1.58 1.58 0 0 1 1.907-1.161l15.535 3.836a1.574 1.574 0 0 1 1.149 1.912 1.577 1.577 0 0 1-1.906 1.16l-15.536-3.836a1.57 1.57 0 0 1-.422-.171z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="m390.723 201.935-.569-6.295s5.038 1.174 8.196.684c-.398 1.532-1.123 6.92-1.123 6.92s-1.622 1.177-3.561 1.092c-1.939-.086-2.943-2.401-2.943-2.401z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#0F2C5D"
					d="M405.312 211.551c-.106.239-.214.391-.289.417-.256.091-7.612-1.64-12.648-2.904l-.707-.179a131.934 131.934 0 0 1-3.213-.849c.005-.216.015-.425.031-.628.28-3.539 2.238-5.473 2.238-5.473 1.572 1.399 3.577 2.224 6.504 1.309a8.527 8.527 0 0 0 2.212 1.784h.002a6.993 6.993 0 0 0 .443.235c.376.185.746.339 1.11.475h.002c1.476.549 2.815.807 3.691 1.664 1.347 1.318 1.003 3.294.624 4.149z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#2A4070"
					d="M405.312 211.551c-.106.24-.214.391-.289.417-.256.091-7.612-1.64-12.648-2.904l-.707-.179a131.934 131.934 0 0 1-3.213-.849c.005-.216.015-.425.031-.628l16.826 4.143z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#686E9E"
					d="M399.44 205.028c-.011.002-.023.002-.036.003-.034 0-.881.071-1.467.692a.392.392 0 0 1-.57-.537c.387-.41.809-.633 1.153-.754.305.228.613.423.92.596zM400.995 205.738h-.01a2.23 2.23 0 0 0-1.368.681.391.391 0 1 1-.569-.538 2.947 2.947 0 0 1 .837-.618c.376.185.746.339 1.11.475z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M368.457 201.149c-4.363 0-7.484-.624-7.666-.66l.926-4.535c.896.181 22.079 4.258 29.007-11.485 7.909-17.976 19.331-24.541 27.52-26.882l1.273 4.45c-7.237 2.068-17.371 7.962-24.557 24.295-3.52 8.002-10.61 12.98-20.501 14.401a42.352 42.352 0 0 1-6.002.416z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="m378.628 215.041-.605-4.588c14.241-1.878 22.355-3.581 23.505-16.714 2.029-23.143 17.885-29.93 18.559-30.206l1.762 4.278c-.56.237-13.938 6.106-15.71 26.333-1.531 17.471-14.739 19.213-27.511 20.897z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M414.502 151.542c-.598-4.136-11.806-13.01-10.193-18.163a4.74 4.74 0 0 1 2.525-2.928c4.985-2.422 13.608 1.042 14.813 1.535l-.222 3.264c-4.058-1.659-10.416-3.33-13.232-1.962-.476.231-.773.526-.875.977-.886 3.922 9.837 9.051 10.618 16.924l-3.434.353zM373.348 214.319c-4.323 0-7.839 3.534-7.839 7.88 0 4.345 3.516 7.881 7.839 7.881s7.839-3.536 7.839-7.881c0-4.346-3.516-7.88-7.839-7.88zm0 17.289c-5.16 0-9.359-4.221-9.359-9.409s4.199-9.408 9.359-9.408c5.161 0 9.359 4.22 9.359 9.408s-4.198 9.409-9.359 9.409z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#113453"
					d="M447.028 222.386c0 2.619-2.111 4.743-4.718 4.743s-4.72-2.124-4.72-4.743c0-2.622 2.113-4.744 4.72-4.744s4.718 2.122 4.718 4.744zM325.187 223.498c-.617 2.546-3.171 4.113-5.704 3.497-2.533-.613-4.085-3.175-3.468-5.722.618-2.546 3.172-4.112 5.704-3.498 2.533.615 4.086 3.177 3.468 5.723z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M327.391 194.391c-15.354-3.724-30.891 5.804-34.636 21.24-3.744 15.437 5.702 31.023 21.056 34.749 15.355 3.724 30.892-5.805 34.636-21.24 3.744-15.436-5.701-31.025-21.056-34.749zm-14.512 59.833c-17.463-4.237-28.205-21.964-23.947-39.52s21.929-28.392 39.392-24.157c17.462 4.236 28.205 21.964 23.946 39.52-4.258 17.556-21.928 28.393-39.391 24.157zM442.31 193.579c-15.799 0-28.653 12.923-28.653 28.807s12.854 28.804 28.653 28.804c15.8 0 28.653-12.92 28.653-28.804s-12.853-28.807-28.653-28.807zm0 61.567c-17.969 0-32.588-14.696-32.588-32.76s14.619-32.762 32.588-32.762c17.97 0 32.588 14.698 32.588 32.762s-14.618 32.76-32.588 32.76z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M367.515 211.281a1.582 1.582 0 0 1-1.469 2.146 1.565 1.565 0 0 1-1.47-1.024l-13.849-36.827-28.552 47.28c-.272.313-3.103-1.15-2.531-1.81l29.498-48.849c-.075-.196-2.627-7.827-2.627-7.827a1.592 1.592 0 0 1 .915-2.041 1.575 1.575 0 0 1 2.03.919l2.534 6.74 1.192 3.165h-.006l14.335 38.128zM442.719 223.794a1.707 1.707 0 0 1-.525.092 1.567 1.567 0 0 1-1.482-1.053l-19.061-52.845 3.575.075 1.429 4.038 17.019 47.668a1.582 1.582 0 0 1-.955 2.025z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M357.248 166.14h-15.88a3.156 3.156 0 0 1-3.148-3.163 3.156 3.156 0 0 1 3.148-3.165h15.88a3.155 3.155 0 0 1 3.147 3.165 3.155 3.155 0 0 1-3.147 3.163z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M383.406 221.475c-.453 1.844-62.905 2.429-66.797 1.193 0-9.099 57.364-17.941 66.344-14.809 2.808.979 1.273 10.283.453 13.616z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#B67F63"
					d="M382.975 187.188c-.411 1.908-2.228 7.713-5.294 16.074-3.155 8.607-5.896 19.854-7.48 25.606 0 0-2.576 1.287-3.93.926-1.261-.336-2.7-2.41-2.7-2.41.307-9.295.778-18.221 2.068-26.101 1.006-6.12 2.328-11.485 2.976-13.632.226-.729 13.397-5.127 13.397-5.127s1.298 3.096.963 4.664z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M360.337 236.54a1.58 1.58 0 0 1-.726-1.74 1.577 1.577 0 0 1 1.906-1.16l15.536 3.835a1.576 1.576 0 0 1 1.148 1.914 1.577 1.577 0 0 1-1.906 1.158l-15.535-3.836a1.502 1.502 0 0 1-.423-.171z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="m363.571 227.384.249-6.427s5.052 1.264 8.28.789c-.419 1.562-1.899 7.027-1.899 7.027s-1.666 1.189-3.645 1.085c-1.979-.103-2.985-2.474-2.985-2.474z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#8D5EA2"
					d="m377.681 203.262-12.042-1.979c.42-5.311 2.642-11.823 1.245-13.576-14.116-17.719-31.981-27.895-30.689-40.02h30.47s18.785 24.417 17.165 36.197c-.709 5.151-2.812 12.945-6.149 19.378z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#0F2C5D"
					d="M378.388 237.319c-.11.243-.222.398-.299.423-.262.09-7.758-1.735-12.889-3.067l-.721-.188a127.367 127.367 0 0 1-3.273-.894c.007-.219.019-.433.037-.641.315-3.61 2.329-5.568 2.329-5.568 1.593 1.44 3.634 2.298 6.629 1.389a8.766 8.766 0 0 0 2.244 1.839h.002c.15.087.299.168.449.243h.002c.382.192.759.352 1.13.494h.001c1.503.572 2.868.846 3.755 1.728 1.365 1.357.997 3.372.604 4.242z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#2A4070"
					d="M378.388 237.319c-.11.243-.222.398-.299.423-.262.09-7.758-1.735-12.889-3.067l-.721-.188a131.686 131.686 0 0 1-3.273-.894c.007-.219.019-.433.037-.641l17.145 4.367z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M372.445 230.612c-.011.003-.024.003-.036.003-.035 0-.9.064-1.504.694a.4.4 0 0 1-.577-.553 2.98 2.98 0 0 1 1.182-.761c.311.237.624.438.935.617zM374.028 231.349h-.012a2.28 2.28 0 0 0-1.401.684.4.4 0 0 1-.577-.553 2.99 2.99 0 0 1 .858-.625h.002c.382.192.759.352 1.13.494z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#B67F63"
					d="M414.162 134.227c1.88-.327 3.125-3.295 3.603-3.407.483-.115.856.008 1.12.37.255.369 2.762 2.983 2.762 2.983.416 1.424 1.689.668 1.774-.708.07-1.148-.022-2.805-.68-3.801-1.032-1.562-2.853-2.014-3.471-2.142-5.639-.6-9.079.42-10.571.072-.743 2.97-1.568 4.727-.705 5.051.882.365 4.288 1.905 6.168 1.582z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#062440"
					d="M421.425 135.25a3.526 3.526 0 0 1-4.922.881 3.564 3.564 0 0 1-.872-4.947 3.525 3.525 0 0 1 4.922-.881 3.564 3.564 0 0 1 .872 4.947z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#AA765C"
					d="m378.316 90.759-1.564 6.581-8.995-3.147 4.187-13.358 6.372 9.924z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#B67F63"
					d="M380.968 91.558c-5.519.462-10.367-3.637-10.829-9.156l-.563-6.73c-.462-5.519 3.637-10.368 9.156-10.829 5.519-.462 10.368 3.638 10.83 9.157l.563 6.73c.462 5.518-3.638 10.366-9.157 10.828z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#2D393F"
					d="M371.132 78.824s.868-5.189 5.62-5.104c7.827.14 8.412-4.465 8.412-4.465s.956 2.645 4.346 4.269c2.778-7.205-4.346-13.199-13.45-11.098-7.117-1.493-12.584 6.069-12.559 14.085.025 8.016 6.463 10.371 6.463 10.371l1.168-8.058z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#B67F63"
					d="M372.338 80.238c.573 1.818 0 3.619-1.281 4.024-1.28.404-2.783-.742-3.357-2.561-.573-1.818 0-3.619 1.281-4.023 1.28-.405 2.784.742 3.357 2.56z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="m425.384 170.509-3.404.298-8.076-19.265 4.176-.372 7.304 19.339z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#4D4692"
					d="M336.197 147.685c2.157-10.251 10.529-13.559 13.111-21.795 4.233-13.504 5.101-30.097 18.565-32.829l8.879 4.279c-.469 2.306 3.349 8.854 3.884 13.995.472 4.528-.539 10.791-2.657 14.821l-11.321 21.541-30.461-.012z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#4D4692"
					d="M409.231 127.329s-26.786 1.466-28.445.775c-1.889-6.222-3.095-17.295-3.645-21.541-1.587-12.244-15.625-12.766-14.172 3.288 1.848 20.412 9.335 28.697 16.155 28.634 5.84.261 30.658-4.768 30.658-4.768l-.551-6.388z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M421.135 142.605a4.171 4.171 0 1 1-8.343 0 4.171 4.171 0 0 1 8.343 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M424.164 144.58c0 2.305-1.868 4.171-4.172 4.171a4.17 4.17 0 1 1 0-8.343 4.173 4.173 0 0 1 4.172 4.172zM444.102 144.69a4.17 4.17 0 1 1-8.343 0 4.171 4.171 0 0 1 8.343 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M446.004 143.095a3.17 3.17 0 1 1-6.34-.001 3.17 3.17 0 0 1 6.34.001zM430.257 143.453a4.172 4.172 0 1 1-8.343 0 4.172 4.172 0 0 1 8.343 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M433.726 139.282a3.046 3.046 0 1 1-6.09 0 3.046 3.046 0 0 1 6.09 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M437.343 144.69a4.281 4.281 0 0 1-4.282 4.282 4.28 4.28 0 0 1-4.282-4.282 4.281 4.281 0 1 1 8.564 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M437.955 141.735a3.066 3.066 0 1 1-6.133 0 3.066 3.066 0 0 1 6.133 0z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M440.109 166.752h-16.145l-8.008-20.438 27.905-.19-3.752 20.628z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M437.504 137.32c0 2.845-2.931 5.153-2.931 5.153s-2.932-2.308-2.932-5.153c0-2.846 2.932-5.153 2.932-5.153s2.931 2.307 2.931 5.153z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M440.387 141.316c-2.153 1.864-5.814 1.157-5.814 1.157s-.173-3.725 1.978-5.587c2.153-1.863 5.815-1.157 5.815-1.157s.173 3.725-1.979 5.587zM420.106 141.017c1.526 1.15 1.578 3.654 1.578 3.654s-2.422.638-3.947-.513-1.577-3.654-1.577-3.654 2.421-.637 3.946.513z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#E54B33"
					d="M423.414 141.086c.129 1.907-1.73 3.585-1.73 3.585s-2.067-1.414-2.195-3.32c-.129-1.906 1.73-3.585 1.73-3.585s2.067 1.415 2.195 3.32z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#7DC6AC"
					d="M442.572 153.419h-21.169v-1.158h21.387l-.218 1.158zM441.422 159.742H424.26v-1.158h17.355l-.193 1.158z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M329.112 244.429c-20.246 8.065-38.451-16.08-25.105-33.315-9.511 17.741 5.445 37.569 25.105 33.315z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M326.738 237.391c-13.462 6.156-26.474-11.019-16.813-22.313-5.792 11.872 3.843 24.611 16.813 22.313zM442.657 244.37c-21.735 1.602-31.833-26.901-13.921-39.323-14.407 14.059-6.108 37.466 13.921 39.323z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M442.51 236.945c-14.69 1.822-21.933-18.474-9.323-26.337-9.095 9.58-3.738 24.626 9.323 26.337z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#4D4692"
					d="M370.197 86.408s-4.173-1-6.081-6.14c-1.243 4.347 5.424 8.236 5.424 8.236l.657-2.096z"
				/>
			</Svg>
		</View>
	);
}
