import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { useActionStepsQuery, useGoalsQuery } from '@mobe/api/goals/goalsApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import { useIncentiveQuery } from '@mobe/api/incentive/incentiveApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import { useProgressQuery } from '@mobe/api/progress/progressApiHooks';
import { IMedication, IProgress } from '@mobe/api/progress/progressService';
import CardButton from '@mobe/components/cardButton/CardButton';
import DataCard, { IDataCardProps } from '@mobe/components/dataCard/DataCard';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import GoalTargetGraphic from '@mobe/components/graphics/GoalTargetGraphic';
import MedicineGraphic from '@mobe/components/graphics/MedicineGraphic';
import ProgressGuideCall from '@mobe/components/graphics/ProgressGuideCall';
import ProgressPharmacistCall from '@mobe/components/graphics/ProgressPharmacistCall';
import ProgressPharmacistMedicineGraphic from '@mobe/components/graphics/ProgressPharmacistMedicineGraphic';
import ProgressTrophy from '@mobe/components/graphics/ProgressTrophy';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import Blocks from '@mobe/components/layout/Blocks';
import VrArray from '@mobe/components/layout/VrArray';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { useFocusEffect } from '@react-navigation/native';
import { differenceInDays, format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import BottomTabScreenTemplate from '../navigation/bottomTabs/components/BottomTabScreenTemplate';
import { useModalNavigation } from '../navigation/modal/ModalNavigationProvider';
import * as ProgressAnalyticsEvents from './analyticsEvents';
import IncentiveModule from './components/IncentiveModule';

const INCENTIVE_MODULE_DAYS_SHOWN_AFTER_COMPLETION = 28;

export default function ProgressScreen() {
	const { t } = useTranslation();
	const { wrapper, constrain, vr } = useStyleHelpers();
	const { isLargeFontScale } = useLayout();
	const styleRules = useStyleRules();
	const modalNavigation = useModalNavigation();
	const [isShowingAll, setIsShowingAll] = React.useState<boolean>(false);
	const progressQuery = useProgressQuery();
	const appointmentsQuery = useAppointmentsQuery();
	const guideQuery = useGuideQuery();
	const pharmacistQuery = usePharmacistQuery();
	const permissionsQuery = usePermissionsQuery();
	const completedLongTermGoalsQuery = useGoalsQuery({ type: 'LongTerm', status: 'Completed' });
	const completedShortTermGoalsQuery = useGoalsQuery({ type: 'ShortTerm', status: 'Completed' });
	const completedActionStepsQuery = useActionStepsQuery({ status: 'Completed' });
	const incentiveQuery = useIncentiveQuery();

	const shouldDisplayIncentiveModule =
		incentiveQuery.data &&
		(incentiveQuery.data.dateCompleted === null ||
			differenceInDays(new Date(Date.now()), new Date(incentiveQuery.data.dateCompleted)) <
				INCENTIVE_MODULE_DAYS_SHOWN_AFTER_COMPLETION);

	const hasGuide = guideQuery.isSuccess && Boolean(guideQuery.data);
	const hasPharmacist = pharmacistQuery.isSuccess && Boolean(pharmacistQuery.data);
	const futureAppointments = appointmentsQuery.data?.futureAppointments;
	const upcomingPharmacistAppointments =
		futureAppointments?.filter((appt) => appt.isPharmacist) || [];
	const upcomingGuideAppointments = futureAppointments?.filter((appt) => appt.isGuide) || [];

	const isLoading =
		progressQuery.isLoading ||
		completedLongTermGoalsQuery.isLoading ||
		completedShortTermGoalsQuery.isLoading ||
		completedActionStepsQuery.isLoading ||
		incentiveQuery.isLoading;

	let data: IProgress = {
		activitiesAllTime: 0,
		activitiesThisWeek: 0,
		callsWithGuide: 0,
		callsWithPharmacist: 0,
		goals: 0,
		lastGuideCall: null,
		lastPharmacistCall: null,
		medications: [],
		trackingEntries: 0,
	};

	if (progressQuery.data) {
		data = progressQuery.data;
	}

	const preparedMedicationReviews = isShowingAll ? data.medications : [data.medications[0]];
	const optionalGuideProperties: Partial<IDataCardProps> = {};
	const optionalPharmacistProperties: Partial<IDataCardProps> = {};
	const optionalReviewProperties: Partial<IDataCardProps> = {};

	useFocusEffect(
		React.useCallback(() => {
			progressQuery.refetch();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	const onPressShowAll = () => {
		if (!isShowingAll) {
			ProgressAnalyticsEvents.pharmacistSummariesShowAllPress();
		}

		setIsShowingAll(!isShowingAll);
	};

	const lastPharmacistReviewDate = data.medications.length
		? `${t('progress.lastReview')}: ${format(
				new Date(data.medications[0].reviewDate as string),
				'MM/dd/yyyy'
		  )}`
		: '';

	if (data.lastGuideCall) {
		optionalGuideProperties.detail = `${t('progress.lastCall')}: ${format(
			new Date(data.lastGuideCall),
			'MM/dd/yyyy'
		)}`;
	}

	if (data.lastPharmacistCall) {
		optionalPharmacistProperties.detail = `${t('progress.lastCall')}: ${format(
			new Date(data.lastPharmacistCall),
			'MM/dd/yyyy'
		)}`;
	}

	const onPressEmptyCta = (coachType: CoachType) => {
		if (!data.callsWithPharmacist && !hasPharmacist) {
			GlobalAnalyticsEvents.startGuideMatchingPress(coachType);
		} else {
			GlobalAnalyticsEvents.startGuideSchedulingPress(coachType);
		}

		modalNavigation.navigate('APPOINTMENT_SCREEN', {
			coachType,
		});
	};

	const onPressChooseGuide = () => {
		modalNavigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM });
	};

	const onPressUpcomingCta = (confirmationId: string) => {
		modalNavigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
			confirmationId,
		});
	};

	// ---- Optional Guide Properties ----

	// No guide calls and no guide -> guide match
	if (!data.callsWithGuide && !hasGuide) {
		optionalGuideProperties.renderDetails = () => (
			<View>
				<Text size={'md'}>{t('progress.guideChooseNote')}</Text>
				<TextButton title={t('progress.guideChooseCta')} onPress={() => onPressChooseGuide()} />
			</View>
		);
	}

	// No guide calls yet, but has guide matched -> View upcoming call
	if (!data.callsWithGuide && upcomingGuideAppointments.length) {
		optionalGuideProperties.renderDetails = () => (
			<View>
				<Text size={'md'}>{t('progress.guideEmptyNote')}</Text>
				<TextButton
					title={t('progress.viewUpcomingCallCta')}
					onPress={() => onPressUpcomingCta(upcomingGuideAppointments[0].confirmationId)}
				/>
			</View>
		);
	}

	// ---- Optional Pharmacist Properties ----

	// No pharmacist calls and no pharmacist -> pharmacist match
	if (!data.callsWithPharmacist && !hasPharmacist) {
		optionalPharmacistProperties.renderDetails = () => (
			<View>
				<Text size={'md'}>{t('progress.pharmacistEmptyNote')}</Text>
				<TextButton
					title={t('progress.scheduleCallCta')}
					onPress={() => onPressEmptyCta(CoachType.Pharmacist)}
				/>
			</View>
		);
	}

	// No pharmacist calls yet, but has pharmacist matched -> View upcoming call
	if (!data.callsWithPharmacist && upcomingPharmacistAppointments.length) {
		optionalPharmacistProperties.renderDetails = () => (
			<View>
				<Text size={'md'}>{t('progress.pharmacistEmptyNote')}</Text>
				<TextButton
					title={t('progress.viewUpcomingCallCta')}
					onPress={() => onPressUpcomingCta(upcomingPharmacistAppointments[0].confirmationId)}
				/>
			</View>
		);
	}

	// ---- Optional Review Properties ----

	// No reviews, no upcoming pharmacist call -> pharmacist schedule or pharmacist match
	if (!data.medications?.length && !upcomingPharmacistAppointments.length) {
		optionalReviewProperties.renderImage = () => <ProgressPharmacistMedicineGraphic />;
		optionalReviewProperties.renderDetails = () => (
			<View>
				<TextButton
					title={t('progress.scheduleReviewCta')}
					onPress={() => onPressEmptyCta(CoachType.Pharmacist)}
				/>
			</View>
		);
	}

	// No reviews, but has upcoming call with pharmacist -> View upcoming call
	if (!data.medications?.length && upcomingPharmacistAppointments.length) {
		optionalReviewProperties.renderImage = () => <ProgressPharmacistMedicineGraphic />;
		optionalReviewProperties.renderDetails = () => (
			<View>
				<TextButton
					title={t('progress.viewUpcomingCallCta')}
					onPress={() => onPressUpcomingCta(upcomingPharmacistAppointments[0].confirmationId)}
				/>
			</View>
		);
	}

	if (data.medications?.length) {
		optionalReviewProperties.renderFooter = () => (
			<VrArray>
				{preparedMedicationReviews.map((medication: IMedication) => {
					return (
						<CardButton
							key={medication.reviewDate}
							cardButtonLeft={
								<View style={constrain(20)}>
									<MedicineGraphic />
								</View>
							}
							cardButtonRight={<Icon name={'right'} color="text" size={13} />}
							onPress={() =>
								modalNavigation.navigate('FILE_VIEWER_SCREEN', {
									uri: medication.reviewPdfLink,
									isSharable: true,
									title: `${t('progress.medicationReviewSummary')}`,
								})
							}
							style={{ borderColor: styleRules.colors.strokeLight, borderWidth: 1 }}
						>
							<Text weight="semiBold" size={'sm'}>
								{t('progress.medicationList')}
							</Text>
							<Text color="light" size={'sm'}>
								{`${t('progress.pharmacistVisitOn')} ${format(
									new Date(medication.reviewDate as string),
									'MM/dd/yyyy'
								)}`}
							</Text>
						</CardButton>
					);
				})}
			</VrArray>
		);
	}

	return (
		<BottomTabScreenTemplate screenTitle={t('progress.screenTitle')}>
			<ScrollView>
				<DeferredLoadingIndicator isLoading={isLoading}>
					{shouldDisplayIncentiveModule && incentiveQuery.data && (
						<IncentiveModule {...incentiveQuery.data} />
					)}

					<View style={wrapper}>
						<Heading
							accessibilityAutoFocus={!shouldDisplayIncentiveModule}
							level="h3"
							align="left"
							style={vr(5)}
						>
							{t('progress.callsHeading')}
						</Heading>

						<VrArray style={vr(10)}>
							<DataCard
								count={data.callsWithGuide}
								title={t('progress.callsWithMyGuide')}
								imageWidth={67}
								renderImage={() => <ProgressGuideCall />}
								{...optionalGuideProperties}
							/>

							{permissionsQuery.isSuccess && permissionsQuery.data.hasMedServicesAccess ? (
								<>
									<DataCard
										count={data.callsWithPharmacist}
										title={t('progress.callsWithMyPharmacist')}
										imageWidth={67}
										renderImage={() => <ProgressPharmacistCall />}
										{...optionalPharmacistProperties}
									/>
									<DataCard
										count={data.medications ? data.medications.length : 0}
										title={t('progress.pharmacistSummaries')}
										detail={lastPharmacistReviewDate}
										ctaLabel={isShowingAll ? t('progress.showLess') : t('progress.showAll')}
										imageWidth={60}
										onPressCta={onPressShowAll}
										{...optionalReviewProperties}
									/>
								</>
							) : null}
						</VrArray>

						<Heading level="h3" align="left" style={vr(5)}>
							{t('progress.statsHeading')}
						</Heading>

						<Blocks style={vr(10)} blocksPerRow={isLargeFontScale ? 1 : 2}>
							<DataCard
								count={completedLongTermGoalsQuery.data?.goals.length || 0}
								title={t('progress.longTermGoalsCompletedTitle')}
								layout="block"
							/>
							<DataCard
								count={completedShortTermGoalsQuery.data?.goals.length || 0}
								title={t('progress.shortTermGoalsCompletedTitle')}
								layout="block"
							/>
							<DataCard
								count={completedActionStepsQuery.data?.actionSteps.length || 0}
								title={t('progress.actionStepsCompletedTitle')}
								layout="block"
							/>
							<DataCard
								count={data.activitiesAllTime}
								title={t('progress.activitiesCompletedTitle')}
								layout="block"
								imageWidth={50}
								renderImage={() => <ProgressTrophy />}
							/>
						</Blocks>

						<Heading level="h3" align="left" style={vr(5)}>
							{t('progress.tracking')}
						</Heading>

						<Blocks blocksPerRow={isLargeFontScale ? 1 : 2}>
							<DataCard
								count={data.trackingEntries}
								title={t('progress.entriesSoFar')}
								layout="block"
							/>
							<DataCard
								count={data.goals}
								title={t('progress.goals')}
								layout="block"
								imageWidth={70}
								renderImage={() => <GoalTargetGraphic />}
							/>
						</Blocks>
					</View>
				</DeferredLoadingIndicator>
			</ScrollView>
		</BottomTabScreenTemplate>
	);
}
