import IconButton from '@mobe/components/iconButton/IconButton';
import useLayout from '@mobe/utils/styles/useLayout';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Keyboard, View } from 'react-native';
import { BottomTabStackScreenNavigationProp } from '../../menu/types';

export default function HeaderLeft() {
	const navigation = useNavigation<BottomTabStackScreenNavigationProp>();
	const layout = useLayout();

	function handleMenuButtonPress() {
		Keyboard.dismiss();
		navigation.dispatch(DrawerActions.toggleDrawer());
	}

	return layout.isWebDesktop ? (
		<View style={{ width: 25 }} />
	) : (
		<IconButton
			aria-label="Open menu"
			role="button"
			testID="menu"
			name="menu"
			size={25}
			onPress={handleMenuButtonPress}
		/>
	);
}
