import { ITrackerDetail, TrackerSource } from '@mobe/api/track/trackService';
import { Text } from '@mobe/components/text';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useTrackerDisplayUtils from '../useTrackerDisplayUtils';
import TrackerTable, { ITrackerTableProps } from './TrackerTable';

export interface ISleepTrackerTableProps extends Partial<ITrackerTableProps> {
	entries?: ITrackerDetail[];
	unit: string;
}

export default function SleepTrackerTable({
	entries,
	...trackerTableProps
}: ISleepTrackerTableProps) {
	const { t } = useTranslation();
	const trackerDisplayUtils = useTrackerDisplayUtils();

	return (
		<TrackerTable
			{...trackerTableProps}
			tableColumnHeadings={[
				t('track.detail.dateTableHeading'),
				t('trackers.sleep.title'),
				t('track.detail.sourceTableHeading'),
			]}
			columnStyles={[{ width: '25%' }, { width: '45%' }]}
			tableRows={entries?.map((entry) => {
				const hours = Math.floor(entry.value / 60);
				const minutes = entry.value % 60;

				let sourceContent;
				switch (entry.source) {
					case TrackerSource.Manual:
						sourceContent = t('track.detail.sources.manual');
						break;
					case TrackerSource.Guide:
						sourceContent = t('track.detail.sources.guide');
						break;
					default:
						sourceContent = trackerDisplayUtils.getTrackerName(entry.source);
						break;
				}

				return {
					entry,
					columns: [
						<Text key={`${entry.consumerTrackerId}-1`}>
							{format(new Date(entry.activityDateTime), 'LL/dd/yy')}
						</Text>,
						<Text key={`${entry.consumerTrackerId}-3`}>
							{`${hours} ${t('trackers.sleep.unit', { count: hours })}`}{' '}
							{`${minutes} ${t('trackers.sleep.secondaryUnit', { count: minutes })}`}
						</Text>,
						<Text key={`${entry.consumerTrackerId}-4`}>{sourceContent}</Text>,
					],
				};
			})}
		/>
	);
}
