import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import ChatGuideCard from '../components/ChatGuideCard';

export default function ChatRootScreen() {
	const styleRules = useStyleRules();
	const permissionsQuery = usePermissionsQuery();

	return (
		<ScreenTemplate noPadding scrollViewBackgroundColor={styleRules.colors.background}>
			<ChatGuideCard type="guide" accessibilityAutoFocus />
			{permissionsQuery.isSuccess && permissionsQuery.data.hasMedServicesAccess ? (
				<ChatGuideCard type="pharmacist" />
			) : null}
		</ScreenTemplate>
	);
}

export function useChatRootScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();
	const subScreenOptions = useSubScreenStyleProps({
		backIcon: Platform.OS === 'web' ? 'close' : 'left',
	});

	return {
		title: t('chat.screenTitle'),
		...subScreenOptions,
	};
}
