import React, { createContext, useContext, useMemo, useState } from 'react';
import { AlertButton } from 'react-native';

interface IAlertModal {
	isVisible: boolean;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
	title: string;
	setTitle: React.Dispatch<React.SetStateAction<string>>;
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
	buttons: IButtons[];
	setButtons: React.Dispatch<React.SetStateAction<IButtons[]>>;
}

export interface IButtons extends Omit<AlertButton, 'onPress'> {
	onPress: (value?: string) => void;
}

const AlertModalContext = createContext<IAlertModal | undefined>({} as IAlertModal);

export function ModalProvider({ children }: { children: React.ReactNode }) {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [buttons, setButtons] = useState<IButtons[]>([]);

	const contextValue = useMemo(
		() => ({
			isVisible,
			setIsVisible,
			title,
			setTitle,
			message,
			setMessage,
			buttons,
			setButtons,
		}),
		[buttons, message, isVisible, title]
	);

	return <AlertModalContext.Provider value={contextValue}>{children}</AlertModalContext.Provider>;
}

export function useAlertModal() {
	const context = useContext(AlertModalContext);

	if (!context) {
		throw new Error('AlertModalContext must be in scope when using "useModal"');
	}

	return context;
}
