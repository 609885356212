import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function STGoalSimpleGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(80, 68);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const gray1 = isDarkTheme ? '#AAAAAA' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#666666' : '#D3D3D3';
	const gray3 = isDarkTheme ? '#CCCCCC' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={gray2}
					d="M15.938 60.704a.867.867 0 0 1-.291-.161c-.802-.662-3.318-5.444-4.362-7.471a.88.88 0 0 1 1.565-.806c1.573 3.054 3.47 6.475 3.928 6.929a.88.88 0 0 1-.84 1.509z"
				/>
				<Path
					fill={gray2}
					d="M9.982 58.607a.878.878 0 0 1-.202-1.581c1.933-1.223 5.53-3.501 8.576-2.825a.883.883 0 0 1 .641 1.043.882.882 0 0 1-1.023.674c-2.336-.519-5.535 1.506-7.254 2.593a.87.87 0 0 1-.738.096zM71.791 10.559c-.581 1.809-1.802 3.345-3.433 4.32s-3.562 1.321-5.431.975a7.817 7.817 0 0 1-4.15-13.165 7.817 7.817 0 0 1 13.014 7.87z"
				/>
				<Path
					fill={gray1}
					d="M70.457 10.137a6.54 6.54 0 0 1-.698 1.497 6.413 6.413 0 0 1-7.761 2.522 6.41 6.41 0 0 1-3.957-7.137 6.42 6.42 0 0 1 6.25-5.248 6.419 6.419 0 0 1 6.343 5.134 6.398 6.398 0 0 1-.177 3.232z"
				/>
				<Path
					fill={gray2}
					d="M62.865 12.85a4.897 4.897 0 0 0 6.152-3.182 4.898 4.898 0 0 0-3.165-6.161A4.899 4.899 0 0 0 59.7 6.69a4.896 4.896 0 0 0 3.165 6.16z"
				/>
				<Path
					fill={gray1}
					d="M63.325 11.405a3.385 3.385 0 0 0 4.253-2.2 3.385 3.385 0 0 0-2.188-4.259c-1.779-.569-3.683.416-4.253 2.2s.41 3.69 2.188 4.259z"
				/>
				<Path
					fill={gray2}
					d="M63.819 9.859a1.761 1.761 0 0 0 2.212-1.144A1.76 1.76 0 0 0 64.894 6.5a1.761 1.761 0 0 0-2.212 1.144 1.76 1.76 0 0 0 1.137 2.215zM42.828 17.327a.81.81 0 0 1-.274-.151c-.873-.721-3.67-6.046-4.833-8.299a.832.832 0 0 1-.052-.626.827.827 0 0 1 .406-.479.814.814 0 0 1 .626-.052.827.827 0 0 1 .48.406c1.787 3.468 3.932 7.33 4.425 7.79a.822.822 0 0 1 .202 1.031.82.82 0 0 1-.98.38z"
				/>
				<Path
					fill={gray2}
					d="M36.161 14.997a.826.826 0 0 1-.568-.678.82.82 0 0 1 .378-.799c2.14-1.355 6.124-3.876 9.458-3.138a.824.824 0 0 1 .264 1.497.82.82 0 0 1-.62.109c-2.676-.593-6.288 1.693-8.228 2.921a.815.815 0 0 1-.684.088z"
				/>
				<Path
					fill={gray3}
					d="M51.432 7.306a.86.86 0 0 1-.078-1.609l2.596-1.113c-.606-.995-1.298-2.104-1.524-2.403a.862.862 0 0 1 .188-1.185.856.856 0 0 1 1.189.157c.433.578 1.953 3.101 2.126 3.387a.864.864 0 0 1-.395 1.236l-3.503 1.503a.87.87 0 0 1-.599.027z"
				/>
				<Path
					fill={gray3}
					d="M9.926 66.981c-.489-.155-.97-.337-1.439-.545a.86.86 0 0 1 .7-1.57c2.893 1.292 7.784 1.852 11.152.345 1.729-.773 2.877-2.014 3.411-3.686.835-2.612-.953-5.32-2.845-8.188-2.198-3.33-4.689-7.104-2.631-11.043 2.296-4.392 8.096-4.046 13.704-3.712 4.882.291 9.494.566 11.052-2.255 2.515-4.553-1.321-8.687-5.383-13.064-3.818-4.114-7.766-8.368-6.347-13.272 1.154-3.992 3.033-6.59 5.74-7.944C40.777.178 46.026.701 54.057 3.743a.861.861 0 0 1 .041 1.578.859.859 0 0 1-.649.03c-7.542-2.855-12.368-3.4-15.639-1.765-2.255 1.128-3.845 3.379-4.858 6.885-1.149 3.969 2.303 7.688 5.956 11.625 2.083 2.242 4.238 4.566 5.504 6.989 1.494 2.875 1.536 5.521.124 8.079-2.084 3.776-7.46 3.45-12.659 3.141-5.062-.303-10.298-.614-12.079 2.79-1.59 3.043.417 6.083 2.541 9.3 2.024 3.083 4.133 6.263 3.047 9.659-.69 2.158-2.153 3.754-4.346 4.732-3.343 1.491-7.822 1.247-11.114.195z"
				/>
				<Path
					fill={gray2}
					d="M34.599 54.69a.915.915 0 0 1-.294-.16c-1.028-.849-4.53-7.553-5.579-9.588a.883.883 0 0 1 1.049-1.216.88.88 0 0 1 .514.411c2.081 4.037 4.572 8.522 5.135 9.039a.882.882 0 0 1 .208 1.099.885.885 0 0 1-1.04.413l.007.002z"
				/>
				<Path
					fill={gray2}
					d="M26.885 51.977a.877.877 0 0 1-.602-.727.88.88 0 0 1 .401-.854c2.469-1.564 7.067-4.476 10.903-3.622a.884.884 0 0 1 .551.386.882.882 0 0 1-.27 1.214.875.875 0 0 1-.663.116c-3.127-.693-7.324 1.957-9.579 3.386a.893.893 0 0 1-.741.101zM34.416 35.465a.864.864 0 0 1-.293-.16c-1.028-.849-4.53-7.553-5.579-9.587a.878.878 0 0 1 1.049-1.217.881.881 0 0 1 .515.41c2.082 4.039 4.573 8.524 5.135 9.039a.881.881 0 0 1 .208 1.1.878.878 0 0 1-1.041.412l.006.003z"
				/>
				<Path
					fill={gray2}
					d="M26.7 32.75a.886.886 0 0 1-.604-.726.883.883 0 0 1 .403-.854c2.469-1.564 7.067-4.476 10.903-3.622a.883.883 0 0 1 .641 1.043.882.882 0 0 1-1.023.674c-3.127-.695-7.324 1.957-9.579 3.386a.877.877 0 0 1-.741.099z"
				/>
			</Svg>
		</View>
	);
}
