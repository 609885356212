import Text from '@mobe/components/text/Text';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

interface ISectionHeaderProps {
	index: number;
	title: string;
}

export default function SectionHeader({ index, title }: ISectionHeaderProps) {
	const styles = useSectionHeaderStyles();

	return (
		<View style={styles.container}>
			<Text role="heading" aria-level="2" accessibilityAutoFocus={index === 0} weight="bold">
				{title}
			</Text>
		</View>
	);
}

function useSectionHeaderStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: {
			height: 60,
			justifyContent: 'center',
			backgroundColor: styleRules.colors.background,
			paddingHorizontal: styleRules.spacing.appHorizontalMargin,
		},
	});
}
