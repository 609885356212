import Analytics from '@mobe/utils/analytics';

export function validicUserProvision() {
	Analytics.logEvent('validic_user_provision');
}

export function connectHealthDataEarlyExitConfirm() {
	Analytics.logEvent('validic_connect_early_exit_confirm');
}

export function connectHealthDataEarlyExitCancel() {
	Analytics.logEvent('validic_connect_early_exit_cancel');
}

export function connectHealthDataSuccess() {
	Analytics.logEvent('validic_connect_success');
}

export function disconnectHealthDataConfirm() {
	Analytics.logEvent('validic_disconnect_alert_confirm');
}

export function disconnectHealthDataCancel() {
	Analytics.logEvent('validic_disconnect_alert_cancel');
}

export function disconnectHealthDataSuccess() {
	Analytics.logEvent('validic_disconnect_success');
}
