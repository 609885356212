import useButtonActive from '@mobe/utils/useButtonActive';
import * as React from 'react';
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';
import Text from '../text/Text';
import { TextWeight } from '../text/textStyles';
import useTextButtonStyles, { TextButtonAlign } from './textButtonStyles';

export interface TextButtonProps extends PressableProps {
	disabled?: boolean;
	loading?: boolean;

	/**
	 * If true, TextButton alignment will be improved when used within a text node.
	 */
	inline?: boolean;

	/**
	 * Text to display within button
	 */
	title: string;
	align?: TextButtonAlign;
	weight?: TextWeight;
	style?: StyleProp<ViewStyle>;
	inverted?: boolean;
}

export default function TextButton({
	disabled = false,
	loading = false,
	inline = false,
	inverted = false,
	title,
	align = 'left',
	weight = 'semiBold',
	style,
	onPress,
	...pressableProps
}: TextButtonProps) {
	const { active, handlePressIn, handlePressOut } = useButtonActive(disabled || loading);
	const styles = useTextButtonStyles({ inline, align, disabled, active: active || loading });

	return (
		<Pressable
			disabled={disabled || loading}
			onPress={onPress}
			onPressIn={handlePressIn}
			onPressOut={handlePressOut}
			style={[styles.pressable, style]}
			role="button"
			aria-label={title}
			aria-disabled={disabled}
			hitSlop={10}
			{...pressableProps}
		>
			<Text
				importantForAccessibility="no-hide-descendants"
				weight={weight}
				style={styles.textButton}
				color={inverted ? 'inverted' : 'primary'}
			>
				{title}
			</Text>
		</Pressable>
	);
}
