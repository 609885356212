import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

interface IPharmacistIntroGraphicProps extends SvgProps {
	backgroundColor?: string;
}

export default function GuideMatchGraphic({
	backgroundColor = '#E4F3FF',
	...props
}: IPharmacistIntroGraphicProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(79, 94);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle fill={backgroundColor} cx={39.167} cy={54.833} r={39.167} />
				<Path
					fill="#5A8DB3"
					d="M52.264 80.515c.308-4.492-.337-7.44-.663-9.992H24.63s-.816 8.909-.816 9.738c0 .682-.728 6.388-.875 10.216a38.902 38.902 0 0 0 12.597 3.348l1.131-5.883 1.658-.207.288 1.295c.119 1.53.271 3.273.448 4.966.036 0 .071.003.107.003 4.279 0 8.393-.696 12.247-1.964l.026-.277c.321-3.598.769-10.442.823-11.243z"
				/>
				<Path
					fill="#F7A578"
					d="m52.785 30.566-2.909-.712s3.935 12.059 4.378 12.567c.443.508 6.875 4.948 6.93 5.075.054.127 2.78 2.285 3.109 2.477.329.192 3.627.889 4.06.889.433 0 3.238-.192 3.492-1.143.254-.951-3.3-7.997-4.381-8.567S52.785 30.566 52.785 30.566zM46.014 28.854s-1.899-.606-2.762-1.22l-.018-4.425-9.285.396s-.288 3.712-.365 4.072c-.078.36-2.591 1.176-2.591 1.176s2.479 4.627 7.743 4.886c4.076.18 7.404-3.442 7.278-4.885z"
				/>
				<Path
					fill="url(#a)"
					d="M30.646 78.268s.236 1.521-1.938 3.028c-1.951 1.355-4.749.586-4.749.586s2.316-.326 3.676-1.143c1.361-.816 3.011-2.471 3.011-2.471z"
				/>
				<Path
					fill="#E54B33"
					d="M24.975 42.618H23.56c-.381-2.425-.368-11.111-.368-11.111 2.635-1.205 8.132-2.808 8.132-2.808s1.531 4.43 7.153 4.738c5.622.308 7.443-4.738 7.443-4.738l7.539 2.036c-.207.412-.311 14.893-.298 15.127.456 7.339-.205 21.481-.92 35.09-1.689.334-11.894.404-14.344.213-2.404-.187-6.083-.663-7.285-2.749-3.938-.321-6.793-.707-6.806-1.497-.189-12.14.358-18.924 1.65-31.605.068-.694-.243-1.637-.481-2.696z"
				/>
				<Path
					fill="#FFAF83"
					d="M21.073 74.129s1.132 3.85 1.257 4.469c.124.619 2.332 3.166 2.332 3.166s2.031-.018 3.238-.896a10.324 10.324 0 0 0 2.316-2.269s-.733-1.787-1.295-2.16c-.427-.282-1.445-.627-2.098-1.743a22.247 22.247 0 0 1-1.609-3.627l-4.141 3.06z"
				/>
				<Path
					fill="#FFAF83"
					d="M29.28 79.187a4.867 4.867 0 0 1-4.694 2.538s-1.479-1.852-1.681-2.145c-.202-.293.728-1.448 3.026-1.448 2.443.001 3.349 1.055 3.349 1.055z"
				/>
				<Path
					fill="#FFAF83"
					d="M26.667 74.382c.333.605.82 1.112 1.412 1.469.181.104.746.373.956.518.21.145 1.487 2.109 1.487 2.109a14.023 14.023 0 0 1-2.559 2.453c-1.49 1.07-3.35.837-3.35.837l-.619-.777s1.503.409 2.666.039 3.15-2.64 3.15-2.64-3.422-2.961-3.79-3.627c-.369-.666.647-.381.647-.381z"
				/>
				<Path
					fill="#FFAF83"
					d="M22.478 32.869s2.223 7.492 2.85 9.479c.627 1.987-.943 9.158-.943 9.158l-.87 4.964s.518 9.067 1.013 11.43c.205.972 2.137 6.448 2.137 6.448s-2.479.448-3.534.852c-1.054.404-1.44 1.003-1.44 1.003s-3.448-11.29-4.008-13.059c-1.254-3.971-1.238-5.181-1.054-7.417.174-2.106 1.241-7.943 2.111-12.909.87-4.967 2.559-8.397 3.738-9.949z"
				/>
				<Path
					fill="#FFAF83"
					d="M25.112 41.731a46.454 46.454 0 0 1-.111 8.145s-1.363 5.562-1.456 6.585c0 0 .64 7.831 1.378 11.399a50.422 50.422 0 0 0 1.764 6.585s-.202.863-.658.313-4.629-13.377-4.922-18.496c-.293-5.119 2.982-6.523 4.005-14.531z"
				/>
				<Path
					fill="#E54B33"
					d="M17.423 47.851s2.427-14.982 5.769-16.344c0 0 1.943 6.806 2.129 7.653.187.847.135 5.466.135 5.466l-.029 5.101a37.729 37.729 0 0 0-8.004-1.876zM45.92 28.698a8.526 8.526 0 0 1-7.075 3.85c-4.331.049-7.21-3.168-7.513-3.85l-1.687.518s2.699 5.181 9.534 5.181c5.917 0 8.684-5.166 8.684-5.166l-1.943-.533z"
				/>
				<Path
					fill="#FFAF83"
					d="m25.065 49.223-.243 1.383s-4.145-1.438-7.147-1.787l.321-1.813 7.069 2.217z"
				/>
				<Path
					fill="#E54B33"
					d="M25.079 49.602s-4.451-1.339-7.655-1.751l.259-1.503c2.641.242 5.242.809 7.743 1.689l-.347 1.565z"
				/>
				<Path
					fill="#E54B33"
					d="M25.089 42.645c-.067-1.018-.207-3.15.101-2.769.308.381 1.036 6.122 1.619 8.093.409 1.368-2.554 10.544-2.554 10.544s.495-6.819.617-8.917c.178-3.147.535-2.189.217-6.951z"
				/>
				<Path
					fill="#FFAF83"
					d="M47.962 14.924c.972.179.759.839.334 2-.412 3.078-1.205 3.692-1.541 3.505-.615-.344.033-5.722 1.207-5.505zM29.669 14.901c-.979.135-.806.806-.448 1.982.236 3.093.992 3.743 1.339 3.573.633-.317.296-5.718-.891-5.555z"
				/>
				<Path
					fill="#FFAF83"
					d="M30.434 14.751c-.29-7.254 2.264-7.513 2.896-8.254 1.295-1.503 3.179-2.663 6.64-2.591 0 0 4.308.161 5.44 2.629 1.205 2.64 1.686 6.052 1.58 9.456-.13 4.132-.394 5.243-.754 6.109a3.418 3.418 0 0 1-1.179 1.585c-1.295 1.013-2.969 2.922-4.008 3.756-.417.337-3.22 1.054-5.425-.44a25.585 25.585 0 0 1-3.764-3.181c-.624-.624-1.036-1.635-1.036-2.14 0-.58-.347-2.777-.324-3.21.12-2.323.011-1.709-.066-3.719z"
				/>
				<Path
					fill="#273138"
					d="M29.669 14.902c.259-2.513.145-6.979.259-8.422 0 0 .135-2.746.381-3.29a2.874 2.874 0 0 1 1.443-1.399c-.399 1.497.668 1.971 1.091 2.122 0 0-.99-1.223 0-3.443a2.452 2.452 0 0 0 1.572 1.958c.142-.71-.371-1.692-1.409-2.407 4.003-.241 5.373 2.85 5.373 2.85s.124-1.466-2.088-2.557c2.922.218 5.147 1.679 6 3.334 0-.614-.487-1.769-2.267-2.591 5.262.35 6.502 4.837 6.502 4.837a3.196 3.196 0 0 0-.974-2.508c2.199.699 2.769 3.091 2.769 3.091.044.578-.158 2.899-.184 3.189-.021 1.355-.168 2.368-.179 5.259 0 0-.619.039-.777 1.145-.08.541-.207 1.521-.28 2.023-.161-1.5-.552-3.267-.681-4.054-.275-1.65-.57-5.236-1.311-5.863-.583-.207-5.645-1.707-12.233-.238-.713.505-1.425 5.666-1.534 6.249-.282 1.51-.49 2.412-.676 3.798-.004 0 .029-3.133-.797-3.083z"
				/>
				<Path
					fill="#E54B33"
					d="M51.827 35.294c.482-1.997-1.067-5.357 1.259-4.769 1.655.417 7.642 6.186 9.373 7.422-3.655 3.262-3.8 9.067-3.8 9.067-3.294-3.616-10.454-7.056-6.832-11.72z"
				/>
				<Path
					fill="#E54B33"
					d="M57.954 43.9s-3.707-3.085-4.311-3.052c-.547.031-1.313 6.145-1.313 6.878 0 1.127.915 9.585.915 9.585s.295-13.212.938-13.792c.062-.057 2.679 2.088 2.679 2.088l1.092-1.707z"
				/>
				<Path
					fill="#E54B33"
					d="m63.57 36.299-1.389-1.005s-3.562 5.142-5.321 10.3c.285.228 1.018.777 1.798 1.391a108.887 108.887 0 0 1 4.912-10.686z"
				/>
				<Path
					fill="#E54B33"
					d="M58.477 34.419s-4.285-3.57-5.391-3.901-5.205-1.295-5.205-1.295l12.842 12.886.14-5.896-2.386-1.794z"
				/>
				<Path
					fill="#FFAF83"
					d="M55.197 28.618s3.168 5.876 3.42 6.42c.251.544 2.352 8.29 2.94 9.523.588 1.233 3.762 5.181 4.798 5.313 1.036.132 4.958.697 5.482-.145.225-.368.039-1.814.013-2.415-.114-2.469-1.894-5.409-2.319-6.332-.609-1.324-7.171-10.65-7.461-11.14-.29-.49-2.43-3.433-2.43-3.433l-4.443 2.209zM53.294 20.984c0-.259-.387-2.238-.41-2.482-.023-.243-2.316-2.072-2.316-2.072l4.381 1.052 1.238 2.153c-.892.587-2.893 1.349-2.893 1.349z"
				/>
				<Path
					fill="#FFAF83"
					d="M47.384 21.215a1.408 1.408 0 0 1 1.521.106c.63.394 3.01 2.269 3.01 2.269l1.531-1.461s-.104-.71-.132-1.163c1.054-.321 1.36-1.813 2.254-2.394l.674 1.08 2.826 7.611-3.627 1.78s-.567-.943-.733-1c-.448-.156-3.275-2.104-3.627-2.536a1.038 1.038 0 0 0-.197-.308c-.119-.117-3.256-2.925-3.443-3.052-.187-.127-.446-.72-.057-.932z"
				/>
				<Path
					fill="#252827"
					d="M48.376 19.092c.021-.054.979-3.109 1.461-4.492a3.711 3.711 0 0 1-.218-.751c.163.014.324.043.482.085.065.018.534.93 1.114 1.901.674 1.122 1.458 2.303 1.495 2.389.036.086-.503 1.521-1.036 2.956-.518 1.373-.992 2.712-1.036 2.705a.838.838 0 0 1-.482-.086c-.127-.073-.049-.399.044-.694-.259-.518-.58-1.238-.904-1.938-.457-1.005-.933-2.041-.92-2.075z"
				/>
				<Path
					fill="#403F47"
					d="M52.228 18.139c-.036-.085-.821-1.267-1.495-2.391-.583-.969-1.06-1.912-1.114-1.899-.111.026-1.697 5.085-1.725 5.158-.013.031.456 1.07.92 2.073.611 1.321 1.238 2.702 1.345 2.72.039 0 .518-1.332 1.036-2.705.53-1.435 1.056-2.899 1.033-2.956z"
				/>
				<Path
					fill="#C8D8EE"
					d="M48.757 20.653c.536 1.15 1.202 2.541 1.262 2.562.06.021.417-1.243.826-2.446.472-1.399.984-2.774.956-2.85 0-.028-.088-.171-.205-.383l-2.839 3.117z"
				/>
				<Path
					fill="#C8D8EE"
					d="M51.596 17.541c-.518-.943-1.85-3.264-1.92-3.215-.07.049-1.58 4.764-1.591 4.826 0 .023.3.705.671 1.5l2.84-3.111z"
				/>
				<Path
					fill="#FFAF83"
					d="M54.562 15.463c-.194-.083-2.223-1.005-2.381-1.036-.158-.031-2.003 0-2.109.067a1.892 1.892 0 0 0-.342.339c-.091.124.047.666.192.762.145.096 6.43 1.775 6.43 1.775s-.948-1.383-1.15-1.554a2.757 2.757 0 0 0-.64-.353z"
				/>
				<Path
					fill="#FFAF83"
					d="M50.234 15.419c-.422.018-.619.075-.658.303-.039.228 0 .938.425 1.013.631.033 1.264.023 1.894-.031l4.865 1.282s-1.036-1.775-1.257-1.86c-.22-.086-2.269-.666-2.448-.697-.179-.031-2.821-.01-2.821-.01zM48.767 21.428c-.192-.111-.749-.295-1.013-.202-.264.093-.474.223-.404.503.07.28 2.36 2.137 2.36 2.137s-.754-2.324-.943-2.438z"
				/>
				<Path
					fill="#FFAF83"
					d="M59.64 26.41s-.565-2.648-.591-2.886c-.026-.238-.909-3.528-1.08-3.751s-1.961-2.953-2.031-2.966c-.07-.013-2.111-.749-2.471-.813-.36-.065-1.878-.054-2.298-.057-.42-.003-.44.117-.554.495-.114.378.64.72 1.236.777 0 0 .927.166 1.228.052l1.192.58 1.036 1.295.808 1.479a3.768 3.768 0 0 0-.554 1.541c-.078.829-.168 2.907 0 3.531.168.624.301 1.466.697 1.583.395.117 2.789-.106 3.382-.86z"
				/>
			</Svg>
		</View>
	);
}
