import crashlytics from '@mobe/utils/crashlytics';
import { useAlert } from '@mobe/utils/useAlert';
import * as Sharing from 'expo-sharing';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Share } from 'react-native';
import IconButton from '../iconButton/IconButton';

export interface IShareButtonProps {
	url: string;
	showAlert?: boolean;
	continueLabel?: string;
	alertTitle?: string;
	alertBody?: string;
	isSecurePdf?: boolean;
	onSharePress?: () => void;
}

export default function ShareButton({
	url,
	showAlert,
	continueLabel,
	alertTitle = '',
	alertBody,
	isSecurePdf = false,
	onSharePress = noop,
}: IShareButtonProps) {
	const { t } = useTranslation();
	const isIOS = Platform.OS === 'ios';
	const iconName = isIOS ? 'shareIos' : 'shareAndroid';
	const { mobeAlert } = useAlert();

	const onShare = async () => {
		crashlytics.log('Share button pressed');
		onSharePress();
		try {
			// expo-sharing for some reason doesn't show apple mail and text for sharing options
			// falling back to RN Share which doesn't allow file sharing on Android
			// but does allow apple mail and text works for iOS...
			// https://github.com/expo/expo/issues/7991
			if (Platform.OS === 'ios') {
				return await Share.share({ url });
			}

			const isSharingAvailable = await Sharing.isAvailableAsync();

			if (!isSharingAvailable) {
				crashlytics.log('Sharing is not available');
				crashlytics.recordError(new Error(''), 'Sharing Availability Error');
				return;
			}

			// Use expo-sharing for android and web
			return Sharing.shareAsync(url);
		} catch (error) {
			if (error instanceof Error) {
				crashlytics.recordError(error, 'Share Button Generic Error');
			}
		}
	};

	const onPressShare = () => {
		if (!showAlert) {
			onShare();

			return;
		}

		mobeAlert(alertTitle, alertBody, [
			{
				text: t('share.cancelButton'),
				style: 'cancel',
				onPress: noop,
			},
			{
				text: continueLabel,
				onPress: () => {
					onShare();
				},
			},
		]);
	};

	return (
		<IconButton
			name={iconName}
			onPress={onPressShare}
			color="textLight"
			size={25}
			aria-label={t('fileViewer.share')}
		/>
	);
}
