import { useAvailableGuidesQuery } from '@mobe/api/appointments/appointmentApiHooks';
import Avatar from '@mobe/components/avatar/Avatar';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Row from '@mobe/components/layout/Row';
import VrArray from '@mobe/components/layout/VrArray';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import Toggle from '@mobe/components/toggle/Toggle';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GuideMatchSelectionScreenNavigationProp,
	GuideMatchSelectionScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchSelectionScreenProps {
	navigation: GuideMatchSelectionScreenNavigationProp;
	route: GuideMatchSelectionScreenRouteProp;
}

export default function GuideMatchSelectionScreen({ route }: IGuideMatchSelectionScreenProps) {
	const formController = useGuideMatchForm();
	const guideIdControl = formController.guideIdControl;
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const canProgress = !guideIdControl.fieldState.error && guideIdControl.fieldState.isDirty;

	const formValues = formController.form.getValues();
	const availableGuidesQuery = useAvailableGuidesQuery({
		guideDiKeys: formController.availableGuideDiKeys,
		interestIds: formValues.interestIds,
		preferredGender: formValues.gender,
		preferredLanguage: formValues.language,
	});
	const availableGuidesData = availableGuidesQuery.data || [];

	// Clear selection when navigating back to this step after leaving via previous button
	React.useEffect(() => {
		guideIdControl.field.onChange(null);
	}, []); // eslint-disable-line

	return (
		<GuideMatchScreenTemplate
			routeName={route.name}
			canProgress={canProgress}
			fixedPositionFooter={canProgress}
		>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(6)}
			>
				{t('appointments.guideMatch.guideMatchStep.description')}
			</Text>
			<DeferredLoadingIndicator isLoading={availableGuidesQuery.isLoading}>
				<VrArray role="radiogroup">
					{availableGuidesData.map((guide) => (
						<BoxButton
							key={guide.guideDiKey}
							role="radio"
							selected={guideIdControl.field.value === guide.guideDiKey}
							onPress={() => guideIdControl.field.onChange(guide.guideDiKey)}
						>
							<Row gutterSize={12} style={vr(2)}>
								<Row.Item>
									<Avatar letter={guide.preferredName[0]} />
								</Row.Item>
								<Row.Item fill>
									<Text size="lg" weight="medium">
										{guide.preferredName}
									</Text>
								</Row.Item>
							</Row>
							<Toggle>
								{(on, setOn) => (
									<>
										<Text numberOfLines={on ? undefined : 4} style={vr(2)}>
											{guide.bio}
										</Text>
										<TextButton
											title={
												on
													? t('appointments.guideMatch.guideMatchStep.showLess')
													: t('appointments.guideMatch.guideMatchStep.showMore')
											}
											onPress={() => setOn(!on)}
											hitSlop={20}
										/>
									</>
								)}
							</Toggle>
						</BoxButton>
					))}
				</VrArray>
			</DeferredLoadingIndicator>
		</GuideMatchScreenTemplate>
	);
}
