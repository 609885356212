import useMonitoredPromise from '@mobe/utils/useMonitoredPromise';
import { addMinutes, compareAsc, isAfter, minutesToMilliseconds } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIErrorData } from '../APIResponse';
import { useAuth } from '../authentication/AuthContext';
import { CoachType, ICoach } from '../guides/guidesApi';
import {
	GenderPreference,
	IGetAvailableGuides,
	IGetInitialGuideTimeSlots,
	IParticipantInterest,
	IScheduleInitialAppointment,
	IScheduledAppointmentResponse,
	ITimeSlots,
	ITrackConsumerSchedulingActivity,
	IUpdateAppointment,
	LanguagePreference,
	deleteAppointment,
	getAllInterests,
	getAppointments,
	getAvailableGuides,
	getFollowUpTimeSlots,
	getInitialGuideTimeSlots,
	getInitialPharmacistTimeSlots,
	scheduleFollowUp,
	scheduleInitialAppointment,
	scheduleInitialAppointmentWithPharmacist,
	trackConsumerSchedulingActivity,
	updateAppointment,
} from './appointmentsService';

export interface IOrderedGuidePriorityArgs {
	guideDiKeys: string[];
	preferredGender: GenderPreference;
	preferredLanguage: LanguagePreference;
	interestIds: number[];
}

interface IDeleteAppointmentArgs {
	confirmationId: string;
}

export enum AppointmentQueryKeys {
	AllAppointments = 'appointments.allAppointments',
	Interests = 'appointments.interests',
	GuideMatchTimeSlots = 'appointments.guideMatchTimeSlots',
	AvailableGuides = 'appointments.availableGuides',
	FollowUpTimeSlots = 'appointments.followUpTimeSlotsForCalendar',
}

export enum AppointmentMutationKeys {
	InitialAppointment = 'appointments.initialAppointment',
}

export interface IAppointment extends Omit<ICoach, 'bio' | 'displayOrder' | 'chatChannelId'> {
	confirmationId: string;
	appointmentStartDate: string;
	phoneNumber: string | null;
	durationInMinutes: number;
	isGuide: boolean;
	isPharmacist: boolean;
}

export function useGuideMatchTimeSlotsQuery(params: IGetInitialGuideTimeSlots) {
	return useQuery(
		AppointmentQueryKeys.GuideMatchTimeSlots,
		async () => {
			const response = await getInitialGuideTimeSlots(params);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{ refetchOnWindowFocus: false, refetchInterval: minutesToMilliseconds(5) }
	);
}

export function useAllInterestsQuery() {
	return useQuery<IParticipantInterest[], APIErrorData>(
		AppointmentQueryKeys.Interests,
		async () => {
			const response = await getAllInterests();

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{ refetchOnWindowFocus: false, staleTime: Infinity }
	);
}

export function useAvailableGuidesQuery(params: IGetAvailableGuides) {
	return useQuery(
		AppointmentQueryKeys.AvailableGuides,
		async () => {
			const response = await getAvailableGuides(params);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{ refetchOnWindowFocus: false }
	);
}

export function useScheduleFollowUp() {
	return useMonitoredPromise<typeof scheduleFollowUp>(scheduleFollowUp);
}

export function useAppointmentTimeSlotsQuery(
	guide: ICoach | IAppointment | null,
	isInitialPharmacistAppt?: boolean,
	ApptToRescheduleConfirmationId?: string
) {
	const today = new Date(Date.now());

	return useQuery<ITimeSlots, APIErrorData>(
		[AppointmentQueryKeys.FollowUpTimeSlots, guide?.guideDIKey],
		async () => {
			let response;

			if (isInitialPharmacistAppt) {
				response = await getInitialPharmacistTimeSlots(today.toISOString());
			}

			if (guide) {
				response = await getFollowUpTimeSlots({
					guideDiKey: guide.guideDIKey,
					coachTypeId: guide.coachTypeId,
					desiredAppointmentMonthDateTime: today.toISOString(),
					confirmationId: ApptToRescheduleConfirmationId,
				});
			}

			if (!response) {
				throw new Error('A guide object must be supplied if not initial Rx appt');
			}

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			// Disabled if guide is null and it's not the initial Rx appt.
			// This is useful in dependent query scenarios.
			enabled: Boolean(guide) || isInitialPharmacistAppt,
		}
	);
}

export async function fetchAppointments() {
	const response = await getAppointments();
	return response.data;
}

export interface IAppointments {
	allAppointments: IAppointment[];
	futureAppointments: IAppointment[];
	pastAppointments: IAppointment[];
}

export function useAppointmentsQuery() {
	const auth = useAuth();

	return useQuery(AppointmentQueryKeys.AllAppointments, fetchAppointments, {
		select: (data): IAppointments => {
			const allAppointments = data
				.map((appointment) => ({
					...appointment,
					avatarUrl: appointment.avatarUrl ? appointment.avatarUrl : '',
					isGuide: appointment.coachType !== CoachType.Pharmacist,
					isPharmacist: appointment.coachType === CoachType.Pharmacist,
				}))
				.sort((a, b) =>
					compareAsc(new Date(a.appointmentStartDate), new Date(b.appointmentStartDate))
				);

			const futureAppointments = allAppointments.filter((appointment) =>
				isAfter(new Date(appointment.appointmentStartDate), new Date(Date.now()))
			);

			const pastAppointments = allAppointments.filter((appointment) =>
				isAfter(
					new Date(Date.now()),
					addMinutes(new Date(appointment.appointmentStartDate), appointment.durationInMinutes)
				)
			);

			return {
				allAppointments,
				futureAppointments,
				pastAppointments,
			};
		},
		staleTime: 30 * 1000,
		enabled: auth.isAuthenticated,
	});
}

export function useScheduleInitialAppointmentMutation() {
	return useMutation<IScheduledAppointmentResponse, APIErrorData, IScheduleInitialAppointment>(
		async (params) => {
			const response = await scheduleInitialAppointment(params);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{ mutationKey: AppointmentMutationKeys.InitialAppointment }
	);
}

export function useScheduleInitialAppointmentWithPharmacist() {
	return useMonitoredPromise<typeof scheduleInitialAppointmentWithPharmacist>(
		scheduleInitialAppointmentWithPharmacist
	);
}

export function useDeleteAppointmentMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, IDeleteAppointmentArgs>(
		async ({ confirmationId }: IDeleteAppointmentArgs) => {
			const response = await deleteAppointment(confirmationId);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(AppointmentQueryKeys.AllAppointments);
			},
		}
	);
}

export function useUpdateAppointmentMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, IUpdateAppointment>(
		async (params: IUpdateAppointment) => {
			const response = await updateAppointment(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(AppointmentQueryKeys.AllAppointments);
			},
		}
	);
}

export function useTrackConsumerSchedulingActivityMutation() {
	return useMutation(async (params: ITrackConsumerSchedulingActivity) => {
		const response = await trackConsumerSchedulingActivity(params);

		if (!response.success) {
			throw response.error;
		}

		return response.data;
	});
}
