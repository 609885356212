import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestNutritionGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(49, 50);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M16.966 43.661c-.79.858-2.024 4.021-2.024 5.33.005.272.12.53.32.72.2.19.468.293.747.289h16.994c.278.004.546-.1.745-.29.199-.189.313-.448.316-.72 0-1.308-1.24-4.471-2.03-5.329C41.884 41.873 49.021 33.458 49 23.66v-1.934a2.855 2.855 0 00-.852-2.047 2.984 2.984 0 00-2.082-.858 6.631 6.631 0 00.053-5.31A6.849 6.849 0 0042.238 9.8a6.944 6.944 0 00-1.972-.417 7.57 7.57 0 00-.363-5.278A7.726 7.726 0 0035.557.02a.99.99 0 00-1.128.607c-.17.448.02.95.447 1.18 2.926 1.13 4.443 4.296 3.46 7.218a6.89 6.89 0 00-6.324-1.981c-2.317.46-4.223 2.065-5.033 4.237-.777 2.443-1.117 5-1.003 7.556h-3.46a4.747 4.747 0 00.68-4.636c-.607-1.557-1.99-2.698-3.66-3.021a6.739 6.739 0 00-1.683-3.643.987.987 0 00-1.384-.073.955.955 0 00-.074 1.364c.6.675.998 1.498 1.153 2.38a4.906 4.906 0 00-3.537 3.05 4.746 4.746 0 00.689 4.562h-3.943a4.747 4.747 0 00.68-4.634c-.604-1.556-1.985-2.699-3.655-3.023A6.739 6.739 0 006.1 7.52a.987.987 0 00-1.384-.073.955.955 0 00-.074 1.364c.6.675.998 1.498 1.153 2.38-1.635.366-2.968 1.52-3.539 3.061a4.741 4.741 0 00.714 4.568H2.94c-1.63.01-2.946 1.31-2.94 2.905v1.934c-.02 9.798 7.117 18.213 16.966 20.001zm.288 4.314c.438-1.178.709-3.98 2.882-3.98h8.722c2.456 0 2.462 2.819 2.882 3.98H17.254zm11.53-36.01c.649-1.681 2.208-2.863 4.034-3.057 1.826-.194 3.609.632 4.612 2.137a.99.99 0 001.06.406 4.956 4.956 0 013.004.158c2.6.987 4.105 3.981 2.358 7.212H27.959a20.358 20.358 0 01.836-6.857h-.012zM18.62 13.007a2.978 2.978 0 012.773 1.774 2.863 2.863 0 01-.625 3.183 3.023 3.023 0 01-3.248.642 2.91 2.91 0 01-1.84-2.695c-.006-1.596 1.309-2.895 2.94-2.904zm-11.76 0a2.978 2.978 0 012.773 1.774 2.863 2.863 0 01-.625 3.183 3.023 3.023 0 01-3.248.642 2.91 2.91 0 01-1.84-2.695c-.006-1.596 1.31-2.895 2.94-2.904zm-4.9 8.718a.954.954 0 01.285-.684.997.997 0 01.695-.286h43.12a.997.997 0 01.695.286.954.954 0 01.285.684v1.934c.041 10.106-8.289 18.338-18.62 18.4h-7.84c-10.331-.062-18.661-8.294-18.62-18.4v-1.934z"
					fill="#1A7EAD"
					fillRule="nonzero"
				/>
			</Svg>
		</View>
	);
}
