import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { ActivityIndicator, Animated, Modal, ModalProps, StyleSheet, View } from 'react-native';

export default function LoadingOverlay({ visible, ...modalProps }: ModalProps) {
	const styleRules = useStyleRules();
	const styles = useStyles();
	const fadeInSpinnerAnimation = React.useRef(new Animated.Value(0)).current;
	const isFocused = useIsFocused();

	React.useEffect(() => {
		if (visible) {
			Animated.sequence([
				Animated.delay(300),
				Animated.timing(fadeInSpinnerAnimation, {
					useNativeDriver: true,
					toValue: 1,
					duration: 600,
				}),
			]).start();
		}
	}, [fadeInSpinnerAnimation, visible]);

	return (
		<Modal
			animationType="fade"
			transparent
			statusBarTranslucent
			visible={visible && isFocused}
			{...modalProps}
		>
			<View style={styles.container}>
				<Animated.View style={{ opacity: fadeInSpinnerAnimation }}>
					<ActivityIndicator size="large" color={styleRules.colors.primary} />
				</Animated.View>
			</View>
		</Modal>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			backgroundColor: rules.colors.sneezeGuard,
		},
	});
}
