import * as Haptics from 'expo-haptics';
import { random } from 'lodash';
import * as React from 'react';
import {
	Animated,
	Easing,
	Modal,
	StyleSheet,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from '../graphics/utils';

const CONFETTI_COLORS = ['#41B6E6', '#F2C75C', '#F93822', '#FF8200', '#00B388'];

function randomConfettiColor() {
	return CONFETTI_COLORS[random(CONFETTI_COLORS.length - 1)];
}

/** Initial horizontal spread of confetti relative to screen width. A value of 1 equals 100% width of screen. */
const CONFETTI_INITIAL_SPREAD = 1.2;

/** Max radius for the swinging flutter animation */
const MAX_FLUTTER_ARC = 125;

/** Max rotation in degrees for the flutter animation */
const MAX_FLUTTER_ROTATION = 45;

/** Max distance a confetto can drift to the left or right of its starting position */
const MAX_X_DRIFT = 250;

const ANIMATION_DURATION = 3000;

const FADE_OUT_DURATION = 1000;

interface IConfettiProps {
	count?: number;
}

export interface IConfettiMethods {
	showConfetti: (onConfettiComplete?: () => void) => void;
}

function Confetti({ count = 40 }: IConfettiProps, ref: React.Ref<IConfettiMethods>) {
	const [shouldShowConfetti, setShouldShowConfetti] = React.useState(false);
	const fadeOutAnimationRef = React.useRef(new Animated.Value(0)).current;
	const fadeOutAnimation = Animated.timing(fadeOutAnimationRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: FADE_OUT_DURATION,
		delay: ANIMATION_DURATION - FADE_OUT_DURATION,
	});

	function showConfetti(onConfettiComplete?: () => void) {
		fadeOutAnimationRef.setValue(0);
		setShouldShowConfetti(true);
		Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
		fadeOutAnimation.start(() => {
			setShouldShowConfetti(false);
			if (typeof onConfettiComplete === 'function') {
				onConfettiComplete();
			}
		});
	}

	React.useImperativeHandle(ref, () => ({
		showConfetti,
	}));

	return (
		<>
			{shouldShowConfetti && (
				<Modal transparent statusBarTranslucent>
					<Animated.View
						style={{
							opacity: fadeOutAnimationRef.interpolate({
								inputRange: [0, 1],
								outputRange: [1, 0],
							}),
						}}
					>
						{Array(count)
							.fill('')
							.map((unused, index) => (
								<Confetto key={index} />
							))}
					</Animated.View>
				</Modal>
			)}
		</>
	);
}

export default React.forwardRef(Confetti);

/**
 * Singular confetti
 */
function Confetto() {
	const arcSize = random(MAX_FLUTTER_ARC / 2, MAX_FLUTTER_ARC);
	const flutterMultiplier = arcSize / MAX_FLUTTER_ARC;
	const flutterRotation = MAX_FLUTTER_ROTATION * flutterMultiplier;
	const styles = useConfettoStyle(arcSize);
	const windowDimensions = useWindowDimensions();
	const fallingAnimationRef = React.useRef(new Animated.Value(0)).current;
	const fallingAnimation = Animated.timing(fallingAnimationRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: random(ANIMATION_DURATION, ANIMATION_DURATION * 2),
		delay: random(ANIMATION_DURATION / 3),
		easing: Easing.out(Easing.quad),
	});

	const flutterAnimationRef = React.useRef(new Animated.Value(0)).current;
	const flutterAnimation = Animated.timing(flutterAnimationRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: 600 * flutterMultiplier,
		easing: Easing.inOut(Easing.quad),
	});
	const flutterAnimationOut = Animated.timing(flutterAnimationRef, {
		useNativeDriver: true,
		toValue: 0,
		duration: 600 * flutterMultiplier,
		easing: Easing.inOut(Easing.quad),
	});

	const initialRotation = random(-flutterRotation, flutterRotation);
	const leftmostXPos = windowDimensions.width * ((1 - CONFETTI_INITIAL_SPREAD) / 2);
	const rightmostXPos = leftmostXPos + windowDimensions.width * CONFETTI_INITIAL_SPREAD;
	const initialXPos = random(leftmostXPos, rightmostXPos);

	const confettoAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				translateX: fallingAnimationRef.interpolate({
					inputRange: [0, 1],
					outputRange: [initialXPos, initialXPos + random(-MAX_X_DRIFT, MAX_X_DRIFT)],
				}),
			},
			{
				translateY: fallingAnimationRef.interpolate({
					inputRange: [0, 1],
					outputRange: [-MAX_FLUTTER_ARC, windowDimensions.height * 0.8],
				}),
			},
			{
				rotate: flutterAnimationRef.interpolate({
					inputRange: [0, 1],
					outputRange: [`${initialRotation}deg`, `${initialRotation * -1}deg`],
				}),
			},
		],
	};

	React.useEffect(() => {
		const fullAnimation = Animated.parallel([
			Animated.loop(Animated.sequence([flutterAnimation, flutterAnimationOut])),
			fallingAnimation,
		]);

		fullAnimation.start(() => {
			flutterAnimation.reset();
			flutterAnimationOut.reset();
		});

		return fullAnimation.stop;
	}, [fallingAnimation, flutterAnimation, flutterAnimationOut]);

	return (
		<Animated.View style={[styles.confettoWrapper, confettoAnimatedStyles]}>
			<View style={styles.confetto}>
				{random(1, 2) === 1 ? <ConfettoGraphicLong /> : <ConfettoGraphicShort />}
			</View>
		</Animated.View>
	);
}

function useConfettoStyle(arcSize: number) {
	const confettoHeight = 10;
	const confettoWidth = 30;

	return StyleSheet.create({
		confettoWrapper: {
			position: 'absolute',
			paddingTop: arcSize,
			marginLeft: -confettoWidth / 2,
			marginTop: -confettoHeight,
		},
		confetto: {
			width: confettoWidth,
			height: confettoHeight,
			alignItems: 'center',
			justifyContent: 'center',
		},
	});
}

function ConfettoGraphicLong(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(17, 7);

	return (
		<View style={[wrapperStyles, { width: random(15, 25) }]}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={randomConfettiColor()}
					d="M16.58.142C10.219 1.421 6.78 1.418.425.129.422 2.437.42 3.593.42 5.901c6.356 1.289 9.795 1.292 16.155.013L16.58.142z"
				/>
			</Svg>
		</View>
	);
}

function ConfettoGraphicShort(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(12, 8);

	return (
		<View style={[wrapperStyles, { width: random(8, 18) }]}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={randomConfettiColor()}
					d="M.066.064c4.669 1.494 7.19 1.488 11.848-.041l.021 6.799C7.278 8.351 4.757 8.36.089 6.862L.066.064z"
				/>
			</Svg>
		</View>
	);
}
