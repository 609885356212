import {
	useTrackerAggregatedEntriesQuery,
	useTrackersDetailQuery,
} from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import {
	TrackerAbbreviation,
	TrackerAggregatedEntriesInterval,
	intervalFromCustomDateRange,
} from '@mobe/api/track/trackService';
import { Button } from '@mobe/components/button';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import Icon from '@mobe/components/icon/Icon';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import ConnectHealthData from '../../track/components/ConnectHealthData';
import { TRACKER_BAR_CHART_MIN_HEIGHT } from '../../track/components/chart/TrackerBarChart';
import useShouldShowConnectHealthData from '../../track/useShouldShowConnectHealthData';
import useTrackerContent from '../../track/useTrackerContent';

interface ITrackerModuleProps {
	trackerAbbreviation: TrackerAbbreviation;
	startDate: Date;
	endDate: Date;
	onViewDetailPress: () => void;
	onAddEntryPress: () => void;
	style?: StyleProp<ViewStyle>;
}

export default function TrackerModule({
	trackerAbbreviation,
	startDate,
	endDate,
	onViewDetailPress,
	onAddEntryPress,
	style,
}: ITrackerModuleProps) {
	const { vr, vrTop } = useStyleHelpers();
	const styles = useStyles();
	const { t } = useTranslation();
	const trackerContent = useTrackerContent();
	const tracker = trackerContent[trackerAbbreviation];
	const shouldShowConnectHealthData = useShouldShowConnectHealthData(trackerAbbreviation);
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerTypeId = getTrackerIdFromAbbreviation(trackerAbbreviation);
	const trackerDetailQuery = useTrackersDetailQuery(trackerTypeId);

	const trackerAggregatedEntriesQuery = useTrackerAggregatedEntriesQuery({
		trackerTypeId,
		startDate,
		endDate,
		interval: intervalFromCustomDateRange(startDate, endDate),
	});

	const chartLabel =
		trackerAggregatedEntriesQuery.data?.interval === TrackerAggregatedEntriesInterval.Day
			? tracker.chartLabel
			: tracker.chartLabelDaysCombined;

	if (!Object.values(TrackerAbbreviation).includes(trackerAbbreviation)) {
		return null;
	}

	return (
		<View style={style}>
			<ModuleHeading
				title={t('plan.trackerModule.heading', { trackerName: tracker.title })}
				buttonTitle={t('plan.trackerModule.detailScreenButton')}
				onButtonPress={onViewDetailPress}
				headingLevel="h3"
				rowAlign="middle"
				style={vr(3)}
			/>
			<Text style={vr(5)}>{t('plan.trackerModule.description')}</Text>
			<View style={styles.box}>
				{shouldShowConnectHealthData && (
					<ConnectHealthData trackerAbbreviation={trackerAbbreviation} style={vr(5)} />
				)}
				<Text weight="semiBold" align="center">
					{chartLabel}
				</Text>
				<Text size="sm" color="light" align="center" style={vr(2)}>{`${format(
					startDate,
					'MMMM d, yyyy'
				)} – ${format(endDate, 'MMMM d, yyyy')}`}</Text>
				<FetchingIndicator
					isFetching={trackerDetailQuery.isFetching || trackerAggregatedEntriesQuery.isFetching}
					style={[vr(2), styles.chartContainer]}
				>
					{trackerAggregatedEntriesQuery.data
						? tracker.renderChart({
								rawData: trackerAggregatedEntriesQuery.data.data,
								interval: trackerAggregatedEntriesQuery.data.interval,
								hideLegend: true,
								padding: { left: 15 }, // Extra padding for x axis in case tick label is rendered off the chart
						  })
						: null}
				</FetchingIndicator>
				<Button
					variant="secondary"
					size="sm"
					style={{ width: '100%', alignSelf: 'center' }}
					title={t('plan.trackerModule.addEntryButton')}
					onPress={onAddEntryPress}
					iconLeft={<Icon name="add" color="primary" style={{ marginRight: 10 }} />}
				/>
				{trackerDetailQuery.data?.currentSource &&
				trackerDetailQuery.data.currentSource !== 'None' ? (
					<Text align="center" color="light" style={vrTop(4)}>
						{t('plan.trackerModule.connectedSource')}
						<Text weight="medium">{trackerDetailQuery.data.currentSource}</Text>
					</Text>
				) : null}
			</View>
		</View>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		box: {
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,
			borderWidth: 1,
			borderColor: rules.colors.strokeLight,
			paddingHorizontal: 12,
			paddingVertical: 16,
		},
		chartContainer: {
			minHeight: TRACKER_BAR_CHART_MIN_HEIGHT,
		},
	});
}
