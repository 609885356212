import { GoalStatus, GoalType } from '@mobe/api/goals/goalsService';
import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface IGoalDetailGraphicProps extends SvgProps {
	goalType: GoalType;
	goalStatus: GoalStatus;
}

export default function GoalDetailGraphic({
	goalType = 'LongTerm',
	goalStatus = 'InProgress',
	...props
}: IGoalDetailGraphicProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(800, 70);
	const colorScheme = useColorScheme();
	const lightSchemeForegroundWaveColor = goalStatus === 'InProgress' ? '#81C9F2' : '#E9E8E2';
	const lightSchemeBackgroundWaveColor = goalStatus === 'InProgress' ? '#C7E5F6' : '#F7F7F5';
	const darkSchemeForegroundWaveColor = goalStatus === 'InProgress' ? '#27414F' : '#333333';
	const darkSchemeBackgroundWaveColor = goalStatus === 'InProgress' ? '#22303A' : '#444444';
	const opacity = goalType === 'LongTerm' ? 1 : 0.5;

	return (
		<View style={[wrapperStyles, { opacity }]}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={
						colorScheme === 'light' ? lightSchemeBackgroundWaveColor : darkSchemeBackgroundWaveColor
					}
					d="M638.082 70c-31.686-22.453-92.764-30.382-138.66-20.457-35.723 9.405-105.348 6.695-162.505-20.476C279.76 1.895 218.741 9.756 193.324 14.582c-25.441 4.831-65.657 23.669-91.462 31.257C77.891 52.888 42.47 53.014 22.855 70h615.227z"
				/>
				<Path
					fill={
						colorScheme === 'light' ? lightSchemeForegroundWaveColor : darkSchemeForegroundWaveColor
					}
					d="M740.839 70c-13.511-13.45-35.476-29.844-69.763-42.596-46.323-17.228-109.029-21.072-171.371 8.253-62.342 29.326-138.283 24.75-177.246 14.6C274.041 39.896 210.126 47.56 174.716 70h566.123z"
				/>
			</Svg>
		</View>
	);
}
