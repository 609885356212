import { FieldValues, RefCallBack, useController, UseControllerProps } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';

export interface IControlledInputProps {
	ref: RefCallBack;
	value: string | undefined;
	onChangeText: (text: string) => void;
	onBlur: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
	hasError: boolean;
}

export default function useControlledInputProps<TFieldValues extends FieldValues = FieldValues>({
	name,
	control,
}: UseControllerProps<TFieldValues>) {
	const { field, fieldState } = useController<TFieldValues>({ name, control });

	// Yup can coerce strings into numbers, but textInputs don't do the reverse so numbers have be converted to strings
	const value = typeof field.value === 'number' ? field.value.toString() : field.value;

	const inputProps: IControlledInputProps = {
		ref: field.ref,
		value,
		onChangeText: field.onChange,
		onBlur: field.onBlur,
		hasError: Boolean(fieldState.error),
	};

	return inputProps;
}
