import { CoachType } from '@mobe/api/guides/guidesApi';
import Analytics from './analytics';

export function supportNumberPress() {
	Analytics.logEvent('support_number_press');
}

export function chatPress(unreadChatMessageCount: number) {
	Analytics.logEvent('chat_press', { unreadChatMessageCount });
}

export function startGuideMatchingPress(coachType?: CoachType) {
	Analytics.logEvent('guide_match_init_press', { coachType });
}

export function startGuideSchedulingPress(coachType?: CoachType) {
	Analytics.logEvent('guide_scheduling_init_press', { coachType });
}
