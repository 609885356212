import Analytics from '@mobe/utils/analytics';

export function videoPlayPress() {
	Analytics.logEvent('home_video_play_press');
}

export function moodTrackerPress() {
	Analytics.logEvent('home_mood_tracker_press');
}

export function dismissContentModule(contentModuleId: number) {
	Analytics.logEvent('home_dismiss_content_module', { contentModuleId });
}

export function connectHealthCtaPress() {
	Analytics.logEvent('validic_home_cta_press');
}

export function connectHealthCtaDismiss() {
	Analytics.logEvent('validic_home_cta_dismiss');
}

export function guideMatchCtaPress() {
	Analytics.logEvent('guide_match_cta_press');
}

export function guideMatchCtaDismiss() {
	Analytics.logEvent('guide_match_cta_dismiss');
}

export function pharmacistMatchCtaPress() {
	Analytics.logEvent('pharmacist_match_cta_press');
}

export function pharmacistMatchCtaDismiss() {
	Analytics.logEvent('pharmacist_match_cta_dismiss');
}

export function guideOrPharmacistMatchCtaPress() {
	Analytics.logEvent('guide_or_pharmacist_cta_press');
}

export function scheduleCallCtaPress() {
	Analytics.logEvent('home_schedule_call_cta_press');
}
