import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';
import { useToast } from './Toast';

export default function useActivityToast() {
	const toast = useToast();
	const { t } = useTranslation();

	return () =>
		toast.show({
			toastLeft: <Icon name="checkmark" color="textInverted" />,
			message: t('activities.activityCompleteToast'),
		});
}
