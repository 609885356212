import { usePersistentState } from '@mobe/utils/usePersistentState';
import useTransitions from '@mobe/utils/useTransitions';
import { RouteProp } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import AppIntroScreen from '../appIntro/AppIntroScreen';
import MainContentView from '../navigation/MainContentView';
import UnauthenticatedSurveyScreen, {
	IUnauthenticatedSurveyScreenParams,
	useUnauthenticatedSurveyScreenOptions,
} from '../surveys/UnauthenticatedSurveyScreen';
import ClaimAccountScreen from './claimAccount/ClaimAccountScreen';
import EligibilityCheckScreen from './eligibilityCheck/EligibilityCheckScreen';
import ForgotPasswordScreen, {
	IForgotPasswordScreenParams,
	useForgotPasswordScreenOptions,
} from './forgot-password/ForgotPasswordScreen';
import LoginScreen, { useLoginScreenOptions } from './login/LoginScreen';
import ResetPasswordScreen, {
	useResetPasswordScreenOptions,
} from './reset-password/ResetPasswordScreen';
import SMSPromptScreen, { useSMSPromptScreenOptions } from './smsPrompt/SMSPromptScreen';
import SMSVerifyScreen, { useSMSVerifyScreenOptions } from './smsVerify/SMSVerifyScreen';
import SuccessCloseTabScreen, {
	useSuccessCloseTabScreenOptions,
} from './successCloseTab/SuccessCloseTabScreen';
import TermsAndConditionsScreen, {
	useTermsAndConditionsScreenOptions,
} from './termsAndConditions/TermsAndConditionsScreen';
import VerifiedEmailScreen, {
	useVerifiedEmailScreenOptions,
} from './verifyEmail/VerifiedEmailScreen';
import VerifyEmailPromptScreen, {
	useVerifyEmailPromptScreenOptions,
} from './verifyEmail/VerifyEmailPromptScreen';

export interface ILoginScreenParams {
	shouldObfuscate: boolean;
	shouldDisable: boolean;
	emailVerificationGuid?: string;
	redirectTo?: string;
}

export interface ITermsAndConditionsScreenParams {
	view: 'termsAndConditions' | 'privacyPolicy';
}

export type AuthenticationStackParamList = {
	APP_INTRO_SCREEN: undefined;

	// legacy account - SMS validation screens
	PROMPT_FOR_SMS_CODE_SCREEN: { shouldObfuscate?: boolean };
	VERIFY_SMS_SCREEN: undefined;

	// eligibility
	ELIGIBILITY_CHECK_SCREEN: undefined;
	CLAIM_ACCOUNT_SCREEN: { accessToken: string };

	// verify email screen
	VERIFY_EMAIL_PROMPT_SCREEN: { accessToken: string };
	VERIFIED_EMAIL_SCREEN: { success?: boolean };

	// login screen related
	LOGIN_SCREEN: ILoginScreenParams | undefined;
	TERMS_AND_CONDITIONS_MODAL_SCREEN: ITermsAndConditionsScreenParams;

	// account/password retrieval
	FORGOT_PASSWORD_SCREEN: IForgotPasswordScreenParams | undefined;
	RESET_PASSWORD_SCREEN: {
		resetToken?: string;
	};

	// screen for redirecting upon success and initiate its closing
	SUCCESS_CLOSE_TAB_SCREEN: undefined;

	UNAUTHENTICATED_SURVEY_SCREEN: IUnauthenticatedSurveyScreenParams;

	SCHEDULE_SCREEN: undefined;
};

type AuthStackNavigationProps<T extends keyof AuthenticationStackParamList> = StackNavigationProp<
	AuthenticationStackParamList,
	T
>;
type AuthStackRouteProps<T extends keyof AuthenticationStackParamList> = RouteProp<
	AuthenticationStackParamList,
	T
>;

/** Generic AuthStack nav prop for use with useNavigation<>() */
export type AuthStackScreenNavigationProp = AuthStackNavigationProps<
	keyof AuthenticationStackParamList
>;

export type AppIntroScreenNavigationProp = AuthStackNavigationProps<'APP_INTRO_SCREEN'>;
export type AppIntroScreenRouteProp = AuthStackRouteProps<'APP_INTRO_SCREEN'>;

export type SMSPromptScreenNavigationProp = AuthStackNavigationProps<'PROMPT_FOR_SMS_CODE_SCREEN'>;
export type SMSPromptScreenRouteProp = AuthStackRouteProps<'PROMPT_FOR_SMS_CODE_SCREEN'>;

export type SMSVerifyScreenNavigationProp = AuthStackNavigationProps<'VERIFY_SMS_SCREEN'>;
export type SMSVerifyScreenRouteProp = AuthStackRouteProps<'VERIFY_SMS_SCREEN'>;

export type EligibilityCheckScreenNavigationProp =
	AuthStackNavigationProps<'ELIGIBILITY_CHECK_SCREEN'>;
export type EligibilityCheckScreenRouteProp = AuthStackRouteProps<'ELIGIBILITY_CHECK_SCREEN'>;

export type ClaimAccountScreenNavigationProp = AuthStackNavigationProps<'CLAIM_ACCOUNT_SCREEN'>;
export type ClaimAccountScreenRouteProp = AuthStackRouteProps<'CLAIM_ACCOUNT_SCREEN'>;

export type VerifyEmailPromptScreenNavigationProp =
	AuthStackNavigationProps<'VERIFY_EMAIL_PROMPT_SCREEN'>;
export type VerifyEmailPromptScreenScreenRouteProp =
	AuthStackRouteProps<'VERIFY_EMAIL_PROMPT_SCREEN'>;

export type VerifiedEmailScreenNavigationProp = AuthStackNavigationProps<'VERIFIED_EMAIL_SCREEN'>;
export type VerifiedEmailScreenRouteProp = AuthStackRouteProps<'VERIFIED_EMAIL_SCREEN'>;

export type LoginScreenNavigationProp = AuthStackNavigationProps<'LOGIN_SCREEN'>;
export type LoginScreenRouteProp = AuthStackRouteProps<'LOGIN_SCREEN'>;

export type TermsAndConditionsScreenNavigationProp =
	AuthStackNavigationProps<'TERMS_AND_CONDITIONS_MODAL_SCREEN'>;
export type TermsAndConditionsScreenRouteProp =
	AuthStackRouteProps<'TERMS_AND_CONDITIONS_MODAL_SCREEN'>;

export type ForgotPasswordScreenNavigationProp = AuthStackNavigationProps<'FORGOT_PASSWORD_SCREEN'>;
export type ForgotPasswordScreenRouteProp = AuthStackRouteProps<'FORGOT_PASSWORD_SCREEN'>;

export type ResetPasswordScreenNavigationProp = AuthStackNavigationProps<'RESET_PASSWORD_SCREEN'>;
export type ResetPasswordScreenRouteProp = AuthStackRouteProps<'RESET_PASSWORD_SCREEN'>;

export type SuccessCloseTabScreenNavigationProp =
	AuthStackNavigationProps<'SUCCESS_CLOSE_TAB_SCREEN'>;
export type SuccessCloseTabScreenRouteProp = AuthStackRouteProps<'SUCCESS_CLOSE_TAB_SCREEN'>;

export type UnauthenticatedSurveyScreenNavigationProp =
	AuthStackNavigationProps<'UNAUTHENTICATED_SURVEY_SCREEN'>;
export type UnauthenticatedSurveyScreenRouteProp =
	AuthStackRouteProps<'UNAUTHENTICATED_SURVEY_SCREEN'>;

const EligibilityGuideMatchScreen = React.lazy(
	() => import('../eligibilityGuideMatch/EligibilityGuideMatchScreen')
);

const AuthenticationStack = createStackNavigator<AuthenticationStackParamList>();

export default function AuthenticationStackScreen() {
	const persistentState = usePersistentState();
	const loginScreenOptions = useLoginScreenOptions();
	const verifyEmailPromptScreenOptions = useVerifyEmailPromptScreenOptions();
	const verifiedEmailScreenOptions = useVerifiedEmailScreenOptions();
	const SMSPromptScreenOptions = useSMSPromptScreenOptions();
	const SMSVerifyScreenOptions = useSMSVerifyScreenOptions();
	const forgotPasswordScreenOptions = useForgotPasswordScreenOptions();
	const resetPasswordScreenOptions = useResetPasswordScreenOptions();
	const termsAndConditionsScreenOptions = useTermsAndConditionsScreenOptions();
	const successCloseTabScreenOptions = useSuccessCloseTabScreenOptions();
	const unauthenticatedSurveyScreenOptions = useUnauthenticatedSurveyScreenOptions();
	const { t } = useTranslation();
	const transitions = useTransitions();

	const initialRouteName =
		Platform.OS === 'web'
			? 'LOGIN_SCREEN'
			: !persistentState.hasViewedAppIntro
			? 'APP_INTRO_SCREEN'
			: persistentState.hasEverLoggedIn
			? 'LOGIN_SCREEN'
			: 'ELIGIBILITY_CHECK_SCREEN';

	// React.Suspense is here for lazy imported EligibilityGuideMatchScreen, and currently has null fallback component
	// If we start doing more lazy loaded screens we may want to figure out a fallback component and
	// consider using the `startTransition` api if we're navigating between stack screens and the lazy ones
	// https://reactjs.org/docs/code-splitting.html#reactlazy
	return (
		<React.Suspense fallback={null}>
			<MainContentView>
				<AuthenticationStack.Navigator
					initialRouteName={initialRouteName}
					screenOptions={{ ...transitions.DefaultTransition, presentation: 'modal' }}
				>
					<AuthenticationStack.Screen
						name="APP_INTRO_SCREEN"
						component={AppIntroScreen}
						options={{ headerShown: false, title: t('appIntro.screenTitle') }}
					/>
					<AuthenticationStack.Screen
						name="PROMPT_FOR_SMS_CODE_SCREEN"
						component={SMSPromptScreen}
						options={SMSPromptScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="VERIFY_SMS_SCREEN"
						component={SMSVerifyScreen}
						options={SMSVerifyScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="ELIGIBILITY_CHECK_SCREEN"
						component={EligibilityCheckScreen}
						options={{
							headerShown: false,
							title: t('auth.eligibilityCheck.screenTitle'),
						}}
					/>
					<AuthenticationStack.Screen
						name="CLAIM_ACCOUNT_SCREEN"
						component={ClaimAccountScreen}
						options={{
							headerShown: false,
							title: t('auth.claimAccount.screenTitle'),
						}}
					/>
					<AuthenticationStack.Screen
						name="LOGIN_SCREEN"
						component={LoginScreen}
						options={loginScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="VERIFY_EMAIL_PROMPT_SCREEN"
						component={VerifyEmailPromptScreen}
						options={verifyEmailPromptScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="VERIFIED_EMAIL_SCREEN"
						component={VerifiedEmailScreen}
						options={verifiedEmailScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="FORGOT_PASSWORD_SCREEN"
						component={ForgotPasswordScreen}
						options={forgotPasswordScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="RESET_PASSWORD_SCREEN"
						component={ResetPasswordScreen}
						options={resetPasswordScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="TERMS_AND_CONDITIONS_MODAL_SCREEN"
						component={TermsAndConditionsScreen}
						options={termsAndConditionsScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="SUCCESS_CLOSE_TAB_SCREEN"
						component={SuccessCloseTabScreen}
						options={successCloseTabScreenOptions}
					/>
					<AuthenticationStack.Screen
						name="UNAUTHENTICATED_SURVEY_SCREEN"
						component={UnauthenticatedSurveyScreen}
						options={unauthenticatedSurveyScreenOptions}
					/>
					{Platform.OS === 'web' && (
						<AuthenticationStack.Screen
							name="SCHEDULE_SCREEN"
							component={EligibilityGuideMatchScreen}
							options={{ header: () => null, title: t('eligibilityGuideMatch.screenTitle') }}
						/>
					)}
				</AuthenticationStack.Navigator>
			</MainContentView>
		</React.Suspense>
	);
}
