import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import Box from '@mobe/components/box/Box';
import BulletListItem from '@mobe/components/bulletListItem/BulletListItem';
import { Button } from '@mobe/components/button';
import { CalendarGraphic } from '@mobe/components/graphics';
import CalendarAltGraphic from '@mobe/components/graphics/calendarAltGraphic';
import Heading from '@mobe/components/heading/Heading';
import Row from '@mobe/components/layout/Row';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler, Platform, View } from 'react-native';
import { ApptStackScreenNavigationProp } from '../../navigation/modal/types';
import {
	ApptConfirmationScreenNavigationProp,
	ApptConfirmationScreenRouteProp,
} from '../AppointmentStackScreen';
import ApptConfirmationBox from '../components/ApptConfirmationBox';
import { GuideApptCard } from '../components/GuideApptCard';
import useCanScheduleAppointment from '../useCanScheduleAppointment';
import useGuidePhoneNumber from '../useGuidePhoneNumber';

export interface IApptConfirmationScreenParams {
	apptID: string | undefined;

	/** This property is set via initialParams within the parent stack navigator */
	isInitialAppt?: boolean;
}

interface IApptConfirmationScreenProps {
	navigation: ApptConfirmationScreenNavigationProp;
	route: ApptConfirmationScreenRouteProp;
}

export default function ApptConfirmationScreen({
	navigation,
	route,
}: IApptConfirmationScreenProps) {
	const styleRules = useStyleRules();
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { canScheduleGuide } = useCanScheduleAppointment();
	const { maxCallCountGuide } = useRemoteConfigData();
	const appointmentsQuery = useAppointmentsQuery();
	const allAppointments = appointmentsQuery.data?.allAppointments || [];
	const appointment = allAppointments.find(
		(appointment) => appointment.confirmationId === route.params.apptID
	);
	const { t } = useTranslation();
	const parentNavigation: ApptStackScreenNavigationProp = navigation.getParent();
	const shouldShowScheduleAnother = appointment?.isGuide && !route.params.isInitialAppt;
	const guidePhoneNumber = useGuidePhoneNumber(appointment?.coachType);

	// Pop screen on native back button press
	useFocusEffect(
		React.useCallback(() => {
			if (Platform.OS === 'web') {
				return;
			}

			const onBackPress = () => {
				parentNavigation.pop();

				// returning true denotes event is handled, react-navigation's listener won't be called
				return true;
			};

			BackHandler.addEventListener('hardwareBackPress', onBackPress);

			return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	function handleApptCardPress() {
		parentNavigation.pop();
		setTimeout(() => {
			navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
				confirmationId: appointment?.confirmationId || '',
			});
		}, 250);
	}

	function handleScheduleAnotherPress() {
		parentNavigation.pop();
		setTimeout(() => {
			navigation.navigate('APPOINTMENT_SCREEN', {
				coachType: appointment?.coachType || CoachType.GSM,
			});
		}, 250);
	}

	if (!appointment) {
		return null;
	}

	return (
		<ScreenTemplateWithFooter
			footer={
				<Button
					title={t('appointments.confirmationView.bottomButton')}
					onPress={() => parentNavigation.pop()}
				/>
			}
		>
			{route.params.isInitialAppt && (
				<View style={[vr(4), constrain(200)]}>
					<CalendarGraphic />
				</View>
			)}

			<View style={[constrainText(300), vr(6)]}>
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					weight="medium"
					size="lg"
					align="center"
				>
					{t('appointments.confirmationView.heading', {
						guideName: appointment.preferredName,
					})}
				</Text>
			</View>

			<GuideApptCard appointment={appointment} onPress={handleApptCardPress} style={vr(8)} />

			<Text role="heading" aria-level="3" size="lg" weight="bold" style={vr(5)}>
				{t('appointments.confirmationView.secondaryHeading')}
			</Text>

			<BulletListItem style={vr(5)}>
				<Text weight="medium">
					{t('appointments.confirmationView.bulletPhoneNumber', {
						phoneNumber: guidePhoneNumber,
					})}
				</Text>
			</BulletListItem>

			<BulletListItem style={vr(5)}>
				<Text weight="medium">
					{t('appointments.confirmationView.bulletSavePhoneNumberPrompt')}
				</Text>
			</BulletListItem>

			{route.params.isInitialAppt && appointment.isGuide && (
				<View style={vr(3)}>
					{t('appointments.confirmationView.guideMatchBullets', {
						returnObjects: true,
					}).map((text, i) => (
						<BulletListItem key={i} style={vr(5)}>
							<Text weight="medium">{text}</Text>
						</BulletListItem>
					))}
				</View>
			)}

			{/* These bullets only shown for initial pharmacist matching state,
			when the follow up context doesn't have a selected guide */}
			{route.params.isInitialAppt && appointment.isPharmacist && (
				<View style={vr(3)}>
					{t('appointments.confirmationView.pharmacistMatchBullets', {
						returnObjects: true,
					}).map((text, i) => (
						<BulletListItem key={i} style={vr(5)}>
							<Text weight="medium">{text}</Text>
						</BulletListItem>
					))}
				</View>
			)}
			{shouldShowScheduleAnother && (
				<Box style={[{ backgroundColor: styleRules.colors.backgroundPrimary }, vr(6)]}>
					<Row style={vr(4)}>
						<Row.Item>
							<View style={constrain(74)}>
								<CalendarAltGraphic />
							</View>
						</Row.Item>
						<Row.Item fill>
							{canScheduleGuide ? (
								<>
									<Heading level="h4" style={vr(1)}>
										{t('appointments.scheduleAdditionalCallBox.canSchedule.title')}
									</Heading>
									<Text>
										{t('appointments.scheduleAdditionalCallBox.canSchedule.description', {
											count: maxCallCountGuide,
										})}
									</Text>
								</>
							) : (
								<>
									<Heading level="h4" style={vr(1)}>
										{t('appointments.scheduleAdditionalCallBox.cannotSchedule.title', {
											count: maxCallCountGuide,
										})}
									</Heading>
									<Text>
										{t('appointments.scheduleAdditionalCallBox.cannotSchedule.description')}
									</Text>
								</>
							)}
						</Row.Item>
					</Row>
					<Button
						title={t('appointments.scheduleAdditionalCallBox.button')}
						variant="secondary"
						disabled={!canScheduleGuide}
						onPress={handleScheduleAnotherPress}
					/>
				</Box>
			)}
			<ApptConfirmationBox
				confirmationId={appointment.confirmationId}
				confirmationMessage={t('appointments.confirmationView.confirmationMessage')}
			/>
		</ScreenTemplateWithFooter>
	);
}
