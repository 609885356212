import Analytics from '@mobe/utils/analytics';

export function appFeedbackPrompt() {
	Analytics.logEvent('app_feedback_prompt');
}

export function appFeedbackDismissPress() {
	Analytics.logEvent('app_feedback_dismiss_press');
}

export function appFeedbackPositivePress() {
	Analytics.logEvent('app_feedback_positive_press');
}

export function appFeedbackNegativePress() {
	Analytics.logEvent('app_feedback_negative_press');
}

export function appFeedbackProvideFeedbackPress() {
	Analytics.logEvent('app_feedback_provide_feedback_press');
}
