import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(62, 50);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="none" fillRule="evenodd">
					<Path
						d="M35.25 6.854l22.016 35.511A5 5 0 0153.017 50H8.983a5 5 0 01-4.25-7.635L26.75 6.855a5 5 0 018.5 0z"
						fill="#FFB135"
					/>
					<Path
						d="M31.5 36a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm.727-18a2.5 2.5 0 012.487 2.758l-1.069 10.308a2.157 2.157 0 01-4.29 0l-1.069-10.308A2.5 2.5 0 0130.773 18h1.454z"
						fill="#FFF"
					/>
				</G>
			</Svg>
		</View>
	);
}

export default SvgComponent;
