import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function Error404(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(288, 288);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props} viewBox="0 0 432 364" fill="none">
				<Path
					d="M419 319.56c6.972.012 12.657-5.588 12.75-12.56.53-34.64 3.32-85.74-61.93-99.88C317 195.72 348.33 101.88 206.14 108c-102.08 4.35-75 90.38-154.32 97.55C-1 210.27-1.45 277.06.58 307.66c.437 6.697 5.999 11.904 12.71 11.9H419Z"
					fill="#F1F1EF"
				/>
				<Path
					opacity={0.191}
					d="M369.82 207.15C317 195.72 348.33 101.88 206.14 108c-24.28 1-41.24 6.69-54.07 14.81 4.32-.57 8.91-1 13.8-1.17 142.19-6.07 110.89 87.76 163.69 99.2 64.55 14 62.5 64.15 61.94 98.77H419c6.992.012 12.684-5.618 12.75-12.61.53-34.61 3.32-85.71-61.93-99.85Z"
					fill="#E4E4E3"
				/>
				<Path
					d="m321.73 104-167-19.49a13 13 0 0 0-14.42 11.41L127.9 202.63a13 13 0 0 0 11.41 14.42l167 19.49a12.999 12.999 0 0 0 14.42-11.41l12.46-106.76c.801-7.132-4.328-13.565-11.46-14.37Z"
					fill="#fff"
				/>
				<Path
					d="m331.91 128.94 1.23-10.57c.803-7.115-4.299-13.539-11.41-14.37l-167-19.49a13 13 0 0 0-14.42 11.41l-1.24 10.57 192.84 22.45Z"
					fill="#E9E9E8"
				/>
				<Path
					d="m166.14 171.08 21.16-37.29a6.818 6.818 0 0 1 6.74-3.41 6.825 6.825 0 0 1 4.575 2.543 6.825 6.825 0 0 1 1.425 5.037l-4.4 37.72h.19A4.73 4.73 0 0 1 200 181a4.75 4.75 0 0 1-5.26 4.16h-.19l-.8 6.87a5.11 5.11 0 0 1-5.67 4.49 5.106 5.106 0 0 1-4.49-5.67l.8-6.87-15.23-1.78a4.741 4.741 0 0 1-4.17-5.26l.41-3.46a5.924 5.924 0 0 1 .74-2.4Zm19.4 2.79 2.73-23.39a.15.15 0 0 0-.28-.09l-12.5 22.06a.63.63 0 0 0 .47.93l8.89 1a.63.63 0 0 0 .69-.51ZM259.53 182l21.16-37.29a6.852 6.852 0 0 1 6.74-3.41 6.841 6.841 0 0 1 6 7.58l-4.43 37.7h.19a4.738 4.738 0 0 1 3.163 1.765 4.735 4.735 0 0 1 .987 3.485 4.732 4.732 0 0 1-5.25 4.16h-.19l-.8 6.87a5.12 5.12 0 0 1-5.68 4.48 5.1 5.1 0 0 1-4.48-5.67l.8-6.87-15.21-1.8a4.735 4.735 0 0 1-3.17-1.768 4.732 4.732 0 0 1-.99-3.492l.4-3.46a6.27 6.27 0 0 1 .76-2.28Zm19.41 2.79 2.73-23.39a.15.15 0 0 0-.104-.159.148.148 0 0 0-.176.069l-12.5 22.06a.619.619 0 0 0 .47.93l8.88 1a.63.63 0 0 0 .7-.53v.02ZM217.55 149.14c1.23-10.53 7.48-15.91 17.64-14.72 10.16 1.19 15 7.85 13.78 18.39l-4 34.25c-1.24 10.54-7.49 15.91-17.65 14.73-10.16-1.18-15-7.86-13.78-18.4l4.01-34.25Zm6.27 36.12c-.55 4.71 1.31 6.73 4.61 7.12 3.3.39 5.57-1.16 6.12-5.87L238.7 151c.55-4.71-1.31-6.74-4.61-7.12-3.3-.38-5.57 1.16-6.12 5.86l-4.15 35.52Z"
					fill="#267EA9"
				/>
				<Path
					d="M158.81 99.21a3.5 3.5 0 1 1-6.957-.789 3.5 3.5 0 0 1 6.957.79ZM175.55 101.17a3.49 3.49 0 1 1-6.932-.829 3.49 3.49 0 0 1 6.932.829ZM192.91 103.19a3.49 3.49 0 1 1-3.07-3.87 3.5 3.5 0 0 1 3.07 3.87Z"
					fill="#fff"
				/>
				<Path
					d="M110.75 224.79a90.188 90.188 0 0 0-1.88 20.21c.34 9.15-.75 69.31-.75 69.31h7.35s10.46-47.11 10.85-50.83c.39-3.72 5.68-40.92 5.68-40.92l-21.25 2.23Z"
					fill="#355389"
				/>
				<Path
					d="M115.64 222.05s1.69 12.85 4.56 22c2.87 9.15 17 70.28 17 70.28h7.35s-1.35-47.11-1.86-50.83c-.51-3.72-4.23-40.93-4.23-40.93l-22.82-.52Z"
					fill="#466EB6"
				/>
				<Path
					d="M137.81 314.31v5.13h14a.8.8 0 0 0 .44-1.47c-2-1.36-5.32-3.24-8.37-3.66h-6.07ZM108.49 314.31v5.13h14a.8.8 0 0 0 .45-1.47c-2.05-1.36-5.33-3.24-8.37-3.66h-6.08Z"
					fill="#184655"
				/>
				<Path
					d="M133.73 162.26s4.81-7.31-.71-8c-4.24-.55-6 .71-6 .71s-4.88-.53-7.55 1.78c-1.83 1.59-8.56 13.9 2.31 18.35 10.87 4.45 8.56-7 8.56-7l3.39-5.84Z"
					fill="#13181C"
				/>
				<Path
					d="M133 154.24c-4.24-.55-6 .71-6 .71s-4.88-.53-7.55 1.78c-1.83 1.59-8.56 13.9 2.31 18.35 5.11 2.1 7.31.69 8.2-1.4-10.31 2.91-11.43-9.52-9.73-13.06 1.7-3.54 5.87-1.93 5.87-1.93 1.63-4.09 6.91-3.64 9.09-3.33a3.002 3.002 0 0 0-2.19-1.12Z"
					fill="#13181C"
				/>
				<Path
					d="M127 165.82s-1.42-1.43-2.49 0c-1.07 1.43-.36 4.63 1.42 4.81 0 0-.53 4.63-4.1 4.45v3.75h8.38V176s5.17-2.13 5-6.41a23.56 23.56 0 0 0-1.43-7.3 11.673 11.673 0 0 1-6.78 3.53Z"
					fill="#FFAE73"
				/>
				<Path
					d="M128.24 177.17c.83.35.69.87.72 1.66h1.21V176s-2 .65-5-1a13.209 13.209 0 0 0 3.07 2.17Z"
					fill="#EEA067"
				/>
				<Path
					d="M130 159s-4.34-1.73-4.85-.43c-.33.85.51 1.58 1.67 2.32l-1 .16a.269.269 0 0 0-.224.197.267.267 0 0 0 .094.283 5.758 5.758 0 0 0 2.71.44L130 159Z"
					fill="#FFAE73"
				/>
				<Path
					d="M161 169.87c-8.27-6.13-30.88-11-30.88-11l-1.86 3.4s20.52 8 19.16 9.14c-.79.7-10.82 5.29-14.22 6.84a5.44 5.44 0 0 1-2.33.48l-10.39-.06a16.411 16.411 0 0 0-13.4 5.88c-5.27 6.07-14.23 20.12-14.23 20.12l15.1 22.2 1.67 2.45v.18l1.49.15c4.85.45 20.4 1.68 28.63-.07l.23-.05-.39-36.42a141.264 141.264 0 0 0 21.67-21 1.534 1.534 0 0 0-.25-2.24Zm-50.7 49.67-1.48-3.22-4.69-10.25 7.79-8.72-1.4 19.13-.22 3.06Z"
					fill="#F47458"
				/>
				<Path d="m108.82 216.32 1.48 3.22-.72 9.71-.01.03-1.67-2.45.92-10.51Z" fill="#E07057" />
				<Path
					d="M67.5 296.49s-2.26-2.11-.74-10.13c1.52-8.02 5.37-15.73 3.48-24.44-1.89-8.71-16.72-30.65-16.77-32.11-.05-1.46-4.58 25.53 3.09 36.19 7.67 10.66 8.6 14.33 8.26 19.92-.34 5.59.07 10.1.92 11.4l1.76-.83Z"
					fill="#2AA48E"
				/>
				<Path
					d="M66.1 280.36c-.89 3.58-1.43 12.55 0 14.7.14.2.33.09.57-.29-.44-1.48-.72-4.08.1-8.41 1.52-8 5.37-15.73 3.48-24.44-1.89-8.71-16.72-30.65-16.77-32.11 0 0 2.23 18.28 9.76 30.87 5.38 9.01 3.76 16.1 2.86 19.68Z"
					fill="#259782"
				/>
				<Path
					d="M70.21 295.94s-4.36-10.88 3-20.3 19.19-21.52 19.43-26.31c0 0 3 22.75-6.58 29.19-9.58 6.44-16.32 7.48-14.66 17.38l-1.19.04Z"
					fill="#2AA48E"
				/>
				<Path
					d="M80.58 274c-10.72 7.8-11.59 16.79-9.93 20.95.113.314.376.55.7.63-1.44-9.56 5.25-10.65 14.74-17s6.58-29.19 6.58-29.19S91.3 266.14 80.58 274Z"
					fill="#259782"
				/>
				<Path
					d="M63 296.18a37.892 37.892 0 0 0 .65-12c-.74-6.22-7.24-9.72-13.31-16.29-3.12-3.38-4-21.25-2.11-22.91 0 0-10.72 13-9.06 22.5 1.66 9.5 19.18 15.71 20.83 20.3a25.61 25.61 0 0 1 1.49 8.46l1.51-.06Z"
					fill="#2AA48E"
				/>
				<Path
					d="M44.65 250.11c-3.54 9.59.09 21.21 11.86 28.43 8.66 5.31 5.9 16.36 5.9 16.36l1.42-4.36a35.12 35.12 0 0 0-.14-6.34c-.74-6.22-7.24-9.72-13.31-16.29-3-3.26-4-20-2.3-22.67a58.94 58.94 0 0 0-3.43 4.87Z"
					fill="#259782"
				/>
				<Path d="m48.08 245.24.19-.24c-.076.07-.14.151-.19.24Z" fill="#49B4D6" />
				<Path
					d="M72.45 295.46s2.51-9.32 8.73-10.57c6.22-1.25 13.34-10.44 13.73-12.24 0 0-1.05 17.35-22.46 22.81Z"
					fill="#2AA48E"
				/>
				<Path
					d="M80.82 288c-3.81 1.9-5.39 4.46-5.95 6.79 19.06-6.13 20-22.11 20-22.11a6.99 6.99 0 0 1-1 1.82c-1.8 3.25-6.33 10.11-13.05 13.5Z"
					fill="#259782"
				/>
				<Path
					d="M85.89 292H53.4l3.86 24.27A3.79 3.79 0 0 0 61 319.5h17.29a3.8 3.8 0 0 0 3.71-3.2l3.89-24.3Z"
					fill="#B87154"
				/>
				<Path d="m85.26 296.05.63-4.02H53.4l.64 4.02h31.22Z" fill="#AE6A4F" />
				<Path
					d="m78 292-3.84 24.3a3.79 3.79 0 0 1-3.74 3.2h7.87a3.8 3.8 0 0 0 3.71-3.2l3.89-24.3H78Z"
					fill="#AE6A4F"
				/>
				<Path d="M310.02 248.75H163.16v29.86h146.86v-29.86Z" fill="#FEC14D" />
				<Path
					d="M175.93 248.75h13.4l-3.59 7.25h-13.41l3.6-7.25ZM206.13 248.75h13.4l-3.59 7.25h-13.4l3.59-7.25ZM236.33 248.75h13.4l-3.59 7.25h-13.4l3.59-7.25ZM266.53 248.75h13.39l-3.59 7.25h-13.4l3.6-7.25ZM310.02 248.75v.2l-3.5 7.05h-13.39l3.6-7.25h13.29Z"
					fill="#F77E2D"
				/>
				<Path d="M191.79 278.61h-10.35v40.83h10.35v-40.83Z" fill="#C7C5C5" />
				<Path d="M191.79 278.61h-2.35v40.83h2.35v-40.83Z" fill="#BEBBBB" />
				<Path d="M292.54 278.61h-10.35v40.83h10.35v-40.83Z" fill="#C7C5C5" />
				<Path
					d="M292.54 278.61h-3.06v40.83h3.06v-40.83ZM191.79 278.58h-10.35v5.14h10.35v-5.14Z"
					fill="#BEBBBB"
				/>
				<Path d="M292.54 278.58h-10.35v5.14h10.35v-5.14Z" fill="#BEBBBB" />
				<Path d="M191.79 244.08h-10.35v4.64h10.35v-4.64Z" fill="#C7C5C5" />
				<Path d="M191.79 244.08h-2.35v4.64h2.35v-4.64Z" fill="#BEBBBB" />
				<Path d="M292.54 244.08h-10.35v4.64h10.35v-4.64Z" fill="#C7C5C5" />
				<Path d="M292.54 244.08h-3.06v4.64h3.06v-4.64Z" fill="#BEBBBB" />
				<Path
					d="m189.33 248.75-14.81 29.86h-11.36v-4.12l12.77-25.74h13.4ZM206.13 248.75l-14.81 29.86h13.4l14.81-29.86h-13.4ZM249.73 248.75l-14.81 29.86h-13.4l14.81-29.86h13.4ZM279.92 248.75l-14.8 29.86h-13.4l14.81-29.86h13.39ZM296.73 248.75l-14.81 29.86h13.39l14.71-29.66-13.29-.2Z"
					fill="#DBA33E"
				/>
				<Path
					d="M365.66 316.27h-46.77l7.11-17.34 2.88-7 .79-1.91 1.3-3.13.26-.63 2.88-7 .53-1.27 5.1-12.38a2.699 2.699 0 0 1 5 0l5.1 12.38.52 1.27 2.88 7 .27.63 1.29 3.13.79 1.91 2.88 7 7.19 17.34Z"
					fill="#FFBF4D"
				/>
				<Path d="m321.96 308.84-3.07 7.43h46.78l-3.07-7.43h-40.64Z" fill="#FEC552" opacity={0.29} />
				<Path d="M368.72 311.83h-53.57v7.57h53.57v-7.57Z" fill="#C7C5C5" />
				<Path d="M368.72 311.83h-11.57v7.57h11.57v-7.57Z" fill="#BEBBBB" />
				<Path
					d="M363.82 311.83h-6.61l-5.32-12.89-2.88-7-.79-1.92-1.29-3.12-.26-.64-2.88-7-.53-1.27-4.26-10.43.81-2a2.699 2.699 0 0 1 5 0l5.1 12.38.52 1.27 2.88 7 .27.64 1.29 3.12.79 1.92 2.88 7 5.28 12.94Z"
					fill="#FEC552"
					opacity={0.29}
				/>
				<Path
					d="M353.28 286.25h-22.01l2.88-7h16.25l2.88 7ZM358.51 298.92v.01h-32.47v-.01l2.88-7h26.71l2.88 7Z"
					fill="#DBA33E"
				/>
				<Path
					d="M353.28 286.25h-6.61l-2.88-7h6.61l2.88 7ZM358.51 298.92v.01h-6.62v-.01l-2.88-7h6.62l2.88 7Z"
					fill="#CD9736"
				/>
				<Path
					d="M67.52 146.21a7 7 0 0 0 7-6c.089-.629.132-1.264.13-1.9 0-7.732-6.268-14-14-14h-.94c-.71-7.886-7.49-13.823-15.401-13.486-7.91.337-14.161 6.828-14.2 14.746v1.08a14.83 14.83 0 0 0-22 11.87c.006.06.006.12 0 .18a7.071 7.071 0 0 0 7.08 7.44l52.33.07ZM400.65 157.17c11 0 11.65-16.61.62-17.15h-.9a18.24 18.24 0 0 0-5.65.89c-1.531-9.254-9.606-15.993-18.984-15.846-9.379.148-17.237 7.138-18.476 16.436a17.577 17.577 0 0 0-11.9-1.15c-9.65 2.48-7.63 16.84 2.34 16.84l52.95-.02ZM268.09 75.32c11 0 11.65-16.6.63-17.14h-.91a18.442 18.442 0 0 0-5.64.88c-1.519-9.264-9.597-16.016-18.984-15.866-9.387.15-17.245 7.158-18.466 16.466a17.51 17.51 0 0 0-11.9-1.16c-9.65 2.49-7.62 16.84 2.34 16.84l52.93-.02Z"
					fill="#E4F3FF"
				/>
				<Path
					d="M92.4 106.01c14.608 0 26.45-11.842 26.45-26.45 0-14.608-11.842-26.45-26.45-26.45-14.608 0-26.45 11.842-26.45 26.45 0 14.608 11.842 26.45 26.45 26.45Z"
					fill="#FEC14D"
				/>
			</Svg>
		</View>
	);
}
