import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function LandscapeGraphic(props: SvgProps) {
	return (
		<Svg {...props}>
			<Path fill="#DEF1FA" d="M0 0h414v155H0z" />
			<Path
				fill="#41B5E6"
				d="M55.1 16c-2.2 0-4.1 1.9-4.1 4.1s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1c-.1-2.3-1.9-4.1-4.1-4.1zm0 6.8a2.732 2.732 0 0 1-2.7-2.7 2.732 2.732 0 0 1 2.7-2.7c1.5 0 2.6 1.2 2.6 2.7 0 1.5-1.2 2.7-2.6 2.7z"
			/>
			<Path
				fill="#28AA91"
				d="M13.5 37.5c-3 0-5.5 2.5-5.5 5.6s2.5 5.6 5.5 5.6c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.6-5.6-5.6zm0 9.2c-1 0-1.9-.4-2.6-1.1-.7-.6-1-1.6-1-2.5 0-1 .4-1.9 1.1-2.6.7-.7 1.6-1.1 2.6-1.1 2 0 3.6 1.7 3.6 3.7-.1 2-1.7 3.6-3.7 3.6z"
			/>
			<Path fill="#90775B" d="m367 130.4-.5-5.6h-2.6l-.4 6.2c1.2-.3 2.4-.5 3.5-.6z" />
			<Path
				fill="#7DC6AC"
				d="M365.2 103.3c1.4-1.9 2.9-2 3.6.3 1.6 5.1 2.4 10.4 2.3 15.8-.1 4.4-1.7 7.6-6.3 7.5-3.8 0-5-1.8-5.1-6.6-.1-6.1 1.8-12 5.5-17z"
			/>
			<Path
				fill="#63A481"
				d="M30.4 106.3c-.7-1.1-1.7-1.9-2.9-2.3 2.6-3.5-2.4-23.8-9.3-22.9-3.6.5-4.3 4.5-4.4 7.9-7.3.5-8.3 13.7-5.1 19-2.3 3.3-2.1 7.7-.3 11.2 13.3 25.4 26.1-6.5 22-12.9z"
			/>
			<Path
				fill="#F77C0C"
				d="M399.6 50c-4.7 0-8.5 3.7-8.6 8.4 0 4.7 3.7 8.5 8.4 8.6 4.7.1 8.5-3.7 8.6-8.4 0-2.3-.8-4.4-2.4-6.1-1.6-1.6-3.7-2.5-6-2.5zm-.1 14c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5z"
			/>
			<Path fill="none" d="M414 134.2V0H0v155h268.7c39.7-22.1 89.5-30.9 145.3-20.8z" />
			<Path fill="#63A481" d="M268.7 155H414v-20.8c-55.8-10.1-105.6-1.3-145.3 20.8z" />
			<Path fill="none" d="M0 0v136.6c98.3-28.6 207.6-6.9 275.4 18.4H414V0H0z" />
			<Path fill="#7DC6AC" d="M0 136.6V155h275.4C207.6 129.7 98.3 107.9 0 136.6z" />
			<Path fill="#90775B" d="M387.5 123.5h-2.6l-.7 12.2c-.1 2 4.6 1.9 4.4-.1l-1.1-12.1z" />
			<Path
				fill="#7DC6AC"
				d="M386.8 91.7c2.2-3 4.5-3.1 5.6.4 2.6 8 3.8 16.4 3.6 24.7-.2 7-2.6 11.9-9.7 11.8-5.9 0-7.7-2.8-7.9-10.3-.2-9.5 2.8-18.8 8.4-26.6z"
			/>
			<Path
				fill="#90775B"
				d="M14.4 115c1.1 1.9 2.1 3.4 3.2 6l.8-18c.1-2.5 1.1-2.5 1.2 0l.4 10.2c.5-1.2 1.1-2.4 1.7-3.5.1-.1.2-.2.4-.1.1.1.2.2.1.4-3 6.9-1.9 8.5-1.3 26.9.1 2.8-4.2 2.8-4.1 0 .2-12.6 1.8-10.5-3.1-21.6-.1-.1 0-.3.1-.4s.5 0 .6.1z"
			/>
		</Svg>
	);
}

export default LandscapeGraphic;
