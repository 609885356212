import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from '../../graphics/utils';

export default function AS2Graphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(200, 100);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m99.902 16.511 2.238 16.699 2.139 16.782-2.139 16.699-2.238 16.79-2.139-16.79-2.147-16.699 2.147-16.782 2.139-16.699z"
					clipRule="evenodd"
				/>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m116.691 20.973-6.516 15.625-6.433 15.542-10.266 13.388L83.21 79.015l6.516-15.626 6.426-15.535 10.273-13.486 10.266-13.395z"
					clipRule="evenodd"
				/>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m128.923 33.209-13.395 10.266-13.388 10.266-15.625 6.524-15.542 6.426 13.395-10.183 13.395-10.266 15.625-6.516 15.535-6.517z"
					clipRule="evenodd"
				/>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m133.481 49.992-16.79 2.147-16.79 2.139-16.691-2.139-16.79-2.147 16.79-2.139 16.691-2.238 16.79 2.238 16.79 2.139z"
					clipRule="evenodd"
				/>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m128.923 66.691-15.535-6.426-15.625-6.524-13.396-10.266-13.395-10.266 15.542 6.516 15.625 6.516 13.388 10.266 13.396 10.184z"
					clipRule="evenodd"
				/>
				<Path
					fill="#AAD5EA"
					fillRule="evenodd"
					d="m116.691 79.015-10.266-13.486L96.152 52.14l-6.426-15.542-6.516-15.625 10.266 13.395 10.266 13.486 6.433 15.535 6.516 15.626z"
					clipRule="evenodd"
				/>
				<Path
					fill="#FFB136"
					fillRule="evenodd"
					d="M99.902 4.008a45.995 45.995 0 0 1 45.218 54.907 45.988 45.988 0 0 1-62.702 33.583 45.99 45.99 0 0 1-28.409-42.506A45.98 45.98 0 0 1 99.902 4.008zm0 6.161a39.82 39.82 0 0 1 36.835 24.542 39.824 39.824 0 1 1-76.598 15.282A39.8 39.8 0 0 1 99.871 10.17h.031z"
					clipRule="evenodd"
				/>
				<Path
					fill="#6FB8DB"
					fillRule="evenodd"
					d="m83.21 20.973 11.422 12.768 11.43 12.685 5.36 16.253 5.269 16.336-11.43-12.768-11.43-12.677-5.36-16.344-5.261-16.253z"
					clipRule="evenodd"
				/>
				<Path
					fill="#399DCE"
					fillRule="evenodd"
					d="m83.21 20.973 11.422 12.768 11.43 12.685-12.231 7.144-5.36-16.344-5.261-16.253z"
					clipRule="evenodd"
				/>
				<Path
					fill="#FFB136"
					fillRule="evenodd"
					d="m99.902 17.94 3.576-3.576 3.659-3.568-3.659-3.576h-7.144l-3.576 3.576 3.576 3.568 3.568 3.576zM99.902 81.962l3.576 3.659 3.659 3.576-3.659 3.568h-7.144l-3.576-3.568 3.576-3.576 3.568-3.659zM131.962 49.991l3.567 3.576 3.576 3.568 3.568-3.568v-7.144l-3.568-3.576-3.576 3.576-3.567 3.568zM67.94 49.991l-3.576 3.576-3.568 3.568-3.576-3.568v-7.144l3.576-3.576 3.568 3.576 3.576 3.568z"
					clipRule="evenodd"
				/>
			</Svg>
		</View>
	);
}
