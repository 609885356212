import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import useColorScheme from '../../useColorScheme';
import { colorSchemeEvent, fontScaleEvent } from './analyticEvents';
import buildStyleRules from './buildStyleRules';
import { IStyleRules } from './types';

const StyleRulesContext = React.createContext<IStyleRules | undefined>(undefined);

/**
 * Provider for the governing styles rules of the application.
 */
function StyleRulesProvider({ children }: { children: React.ReactNode }) {
	const colorScheme = useColorScheme();
	const { fontScale } = useWindowDimensions();
	const styleRules = React.useMemo(
		() => buildStyleRules({ colorScheme, fontScale }),
		[colorScheme, fontScale]
	);

	React.useEffect(() => fontScaleEvent(fontScale), [fontScale]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => colorSchemeEvent(colorScheme), []);

	return <StyleRulesContext.Provider value={styleRules}>{children}</StyleRulesContext.Provider>;
}

function useStyleRules(): IStyleRules {
	const context = React.useContext(StyleRulesContext);
	if (context === undefined) {
		throw new Error('useStyleRules must be used within a StyleRulesProvider');
	}
	return context;
}

export { StyleRulesProvider, useStyleRules };
