import { useCongratsPopupStore } from '@mobe/components/congratsPopup/useCongratsPopupStore';
import React from 'react';
import { useIsFetching, useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { CelebrationsImageType, getCelebrations } from './celebrationsService';

const celebrationsQueryKey = 'Celebrations';

export function useCelebrationsQuery(enabled: boolean) {
	const auth = useAuth();
	const congratsPopupStore = useCongratsPopupStore();
	const isFetching = useIsFetching();
	const [isDoneFetching, setIsDoneFetching] = React.useState(false);

	React.useEffect(() => {
		if (!isFetching) setIsDoneFetching(true);
	}, [isFetching]);

	return useQuery([celebrationsQueryKey], getCelebrations, {
		enabled: auth.isAuthenticated && isDoneFetching && enabled,
		staleTime: Infinity,
		onSuccess: (messageData) => {
			if (messageData.title && messageData.message) {
				congratsPopupStore.setMessage(messageData);
			}
		},
		// Convert imageType to null if string value is unrecognized
		select: (messageData) => {
			return {
				...messageData,
				imageType:
					messageData.imageType !== null &&
					Object.values(CelebrationsImageType).includes(messageData.imageType)
						? messageData.imageType
						: null,
			};
		},
	});
}
