import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import Button from '@mobe/components/button/Button';
import { PharmacistIntroGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import ProfileHeader from '@mobe/components/profileHeader/ProfileHeader';
import ScreenTemplateCentered from '@mobe/components/screenTemplate/ScreenTemplateCentered';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import Text from '@mobe/components/text/Text';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { MyPharmacistScreenNavigationProp } from '../navigation/menu/types';

interface IScheduleAppointmentViewProps {
	navigation: MyPharmacistScreenNavigationProp;
}

interface IPharmacistProfileViewProps {
	navigation: MyPharmacistScreenNavigationProp;
}

function ScheduleAppointmentView({ navigation }: IScheduleAppointmentViewProps) {
	const { vr, constrainText, constrain } = useStyleHelpers();
	const { t } = useTranslation();

	function handleSelectPharmacistPress() {
		GlobalAnalyticsEvents.startGuideMatchingPress(CoachType.Pharmacist);
		navigation.navigate('APPOINTMENT_SCREEN', {
			coachType: CoachType.Pharmacist,
		});
	}

	return (
		<ScreenTemplateCentered>
			<View style={[vr(8), constrain(280)]}>
				<PharmacistIntroGraphic />
			</View>
			<View style={[vr(8), constrainText(350)]}>
				<Text accessibilityAutoFocus align="center" color="light">
					{t('myPharmacist.selectPharmacistView.description')}
				</Text>
			</View>
			<Button
				title={t('myPharmacist.selectPharmacistView.button')}
				onPress={handleSelectPharmacistPress}
			/>
		</ScreenTemplateCentered>
	);
}

function PharmacistProfileView({ navigation }: IPharmacistProfileViewProps) {
	const { vr, wrapperHorizontal } = useStyleHelpers();
	const { t } = useTranslation();
	const pharmacistQuery = usePharmacistQuery();
	const appointmentsQuery = useAppointmentsQuery();
	const futureAppointments = appointmentsQuery.data?.futureAppointments || [];
	const apptsWithPharmacist = futureAppointments.filter(
		(appointment) => appointment.guideDIKey === pharmacistQuery.data?.guideDIKey
	);

	function handleViewUpcomingAppointmentButtonPress() {
		navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
			confirmationId: apptsWithPharmacist[0].confirmationId,
		});
	}

	function handleViewAllUpcomingAppointmentsButtonPress() {
		navigation.navigate('CALLS_SCREEN');
	}

	function handleScheduleAppointmentButtonPress() {
		if (!pharmacistQuery.data) {
			return;
		}

		GlobalAnalyticsEvents.startGuideSchedulingPress(pharmacistQuery.data.coachType);
		navigation.navigate('APPOINTMENT_SCREEN', {
			coachType: pharmacistQuery.data.coachType,
			allowCoachToggle: false,
		});
	}

	function renderFooterButton() {
		if (apptsWithPharmacist.length > 1) {
			return (
				<Button
					title={t('myGuide.guideProfileView.viewAllScheduledAppointmentsButton')}
					onPress={handleViewAllUpcomingAppointmentsButtonPress}
				/>
			);
		}

		if (apptsWithPharmacist.length) {
			return (
				<Button
					title={t('myGuide.guideProfileView.viewScheduledAppointmentButton')}
					onPress={handleViewUpcomingAppointmentButtonPress}
				/>
			);
		}

		return (
			<Button
				title={t('myGuide.guideProfileView.scheduleAppointmentButton')}
				onPress={handleScheduleAppointmentButtonPress}
			/>
		);
	}

	return pharmacistQuery.isSuccess && pharmacistQuery.data ? (
		<ScreenTemplateWithFooter fixedPositionFooter noPadding footer={renderFooterButton()}>
			<ProfileHeader
				headerColor="tertiary"
				imageSrc={
					pharmacistQuery.data?.avatarUrl ? { uri: pharmacistQuery.data.avatarUrl } : undefined
				}
				style={vr(5)}
			/>
			<View style={[wrapperHorizontal, vr(10)]}>
				<Heading level="h3" accessibilityAutoFocus style={vr(3)}>
					{pharmacistQuery.data?.preferredName}
				</Heading>
				<Text>{pharmacistQuery.data?.bio}</Text>
			</View>
		</ScreenTemplateWithFooter>
	) : null;
}

export default function MyPharmacistScreen({ navigation }: IPharmacistProfileViewProps) {
	const pharmacistQuery = usePharmacistQuery(true);

	return pharmacistQuery.isSuccess && pharmacistQuery.data ? (
		<PharmacistProfileView navigation={navigation} />
	) : pharmacistQuery.isSuccess && !pharmacistQuery.data ? (
		<ScheduleAppointmentView navigation={navigation} />
	) : null;
}

export function useMyPharmacistScreenOptions(): DrawerNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('myPharmacist.screenTitle'),
	};
}
