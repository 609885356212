import { OnboardingParamList } from './OnboardingStackScreen';

export const assessmentScreenSequence: Array<keyof OnboardingParamList> = [
	'ONBOARDING_ACTIVITY_SCREEN',
	'ONBOARDING_NUTRITION_SCREEN',
	'ONBOARDING_SLEEP_SCREEN',
	'ONBOARDING_STRESS_SCREEN',
	'ONBOARDING_MEDICATION_SCREEN',
	'ONBOARDING_NOTES_SCREEN',
];
