import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export type ProfileHeaderGraphicColors = 'primary' | 'secondary' | 'tertiary';

interface IProfileHeaderGraphicProps extends SvgProps {
	color?: ProfileHeaderGraphicColors;
}

interface IGraphicSubColors {
	bottom: string;
	middle: string;
	top: string;
}

const colorMap: { [key in ProfileHeaderGraphicColors]: IGraphicSubColors } = {
	primary: {
		bottom: '#28ABE2',
		middle: '#1CA2D9',
		top: '#009CDC',
	},
	secondary: {
		bottom: '#F0D498',
		middle: '#F5CB77',
		top: '#FDC04C',
	},
	tertiary: {
		bottom: '#67BE9D',
		middle: '#5AB993',
		top: '#4DB388',
	},
};

function ProfileHeaderGraphic({ color = 'primary', ...svgProps }: IProfileHeaderGraphicProps) {
	return (
		<Svg {...svgProps} viewBox={`0 0 419.9 141.6`} preserveAspectRatio="none">
			<Path fill={colorMap[color].bottom} d="M0 0h419.9v141.6H0z" />
			<Path
				fill={colorMap[color].middle}
				d="M0 0v141.6h245.4c6.9-2.1 13.2-4.2 18.4-6.3 31-12.5 62.7-26.6 108.6-35.5 19.4-3.8 35.2-7.2 47.4-10.1V0H0z"
			/>
			<Path
				fill={colorMap[color].top}
				d="M419.9 0v48.4c-64.1-.8-153.5 18-202.2 33.6C168 98 133 101.1 96.2 96.3 75.7 93.6 33.4 92.2 0 91.5V0h419.9z"
			/>
		</Svg>
	);
}

export default ProfileHeaderGraphic;
