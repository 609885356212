import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIErrorData } from '../APIResponse';
import { useAuth } from '../authentication/AuthContext';
import { ActivitiesQueryKeys } from './activitiesQueryKeys';
import {
	ActivityType,
	IActivityFull,
	IGetActivitiesResponse,
	getAllActivities,
	markActivityComplete,
	markActivityDismissed,
} from './activitiesService';

export interface IActivitiesData {
	activities: IActivityFull[];
	summary: {
		currentWeekCount: number;
		lifetimeCount: number;
	};
}

export function useAllActivitiesQuery() {
	const auth = useAuth();

	return useQuery<IGetActivitiesResponse, APIErrorData, IActivitiesData>(
		ActivitiesQueryKeys.AllActivities,
		getAllActivities,
		{
			enabled: auth.isAuthenticated,
			staleTime: 30 * 1000,
			// Filter out any unaccounted for activity types for backwards compatibility with API
			select: (data) => {
				return {
					activities: [...data.active, ...data.completed].filter((activity) =>
						Object.values(ActivityType).includes(activity.type)
					),
					summary: data.completedSummary,
				};
			},
		}
	);
}

export function useRefreshAllActivities() {
	const queryClient = useQueryClient();

	return () => queryClient.invalidateQueries(ActivitiesQueryKeys.AllActivities);
}

export function useMarkActivityCompleteMutation() {
	const queryClient = useQueryClient();
	const appFeedback = useAppFeedback();

	return useMutation<void, void, { activityId: number }>(
		async ({ activityId }) => {
			await markActivityComplete(activityId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(ActivitiesQueryKeys.AllActivities);
				appFeedback.show();
			},
		}
	);
}

export function useMarkActivityDismissedMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, void, { activityId: number }>(
		async ({ activityId }) => {
			await markActivityDismissed(activityId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(ActivitiesQueryKeys.AllActivities);
			},
		}
	);
}
