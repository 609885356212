import { IButtons, useAlertModal } from '@mobe/features/alertModal';
import { Alert, AlertButton, Platform } from 'react-native';

export function useAlert() {
	const { setIsVisible, setTitle, setMessage, setButtons } = useAlertModal();

	// Browser Alert Modal dialog
	const webAlert = (title: string, message?: string, buttons?: IButtons[]): void => {
		setTitle(title);
		setMessage(message ? message : '');
		setButtons(buttons ? buttons : []);
		setIsVisible(true);
		return;
	};

	// iOS and Android Alert dialog
	const nativeAlert = (title: string, message?: string, buttons?: AlertButton[]) => {
		Alert.alert(title, message, buttons);
		return;
	};

	return {
		mobeAlert: Platform.OS === 'web' ? webAlert : nativeAlert,
	};
}
