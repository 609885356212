import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function TrackerStressManagementGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(240, 240);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#3B2E30"
					d="M119.35.84c2.1-.6 6.76-1.59 12.04.12 7.42 2.42 10.95 8.55 11.92 10.22 0 0 3.39 5.88 4.16 20.57.04.65.05 1.28.05 1.28.03 2.09-.1 3.64-.12 3.89-.18 2.23.38 9.66 1.1 13.47 2.09 11.09 9.3 11.77 10.7 22.05.52 3.82.56 11.32-2.92 12.97-5.85 2.77-20.97-11.36-22.7-9.41-.18.21-.38.78-.97.97-.73.25-1.57-.24-2.27-.64-.59-.35-1.02-.71-1.3-.97-.92-.42-3.38-1.71-4.87-4.55-1.49-2.83-1.17-5.63-1.04-6.71.06-.53.15-.98.22-1.3 1.01-.63 2.54-1.68 4.16-3.27 5.01-4.92 6.5-10.78 7.13-13.37.61-2.51.71-4.26.9-7.72.45-8.24-1.01-11.02-2.03-12.47-.22-.31-.42-.59-.87-1.1-1.26-1.42-2.69-2.39-7.16-6.6-.49-.47-.93-.91-1.35-1.35 0 0-1.66-1.76-2.75-3.3-1.34-1.9-2.28-6.67-2.03-12.75"
				/>
				<Path
					fill="#3B2E30"
					d="M124.8 1.93c-10.78-2.48-16.27.51-18.46 2.07-6.04 4.27-7.52 12.07-10.22 26.23 0 0-1.66 9.86-4.64 21.74-.46 1.81-1.04 3.97-2.24 6.64-1.7 3.75-4 7.04-7.07 10.5-2.7 3.05-5.58 5.34-6.02 9.49-.33 3.2.97 5.76 2.1 7.98 1 1.94 4.3 8.42 11.57 10.73 1.05.33 7.62 2.22 13.47-1.61.93-.61 3.3-2.21 4.87-5.3 1.21-2.4 1.34-4.71 1.58-8.1.15-2.18.24-3.52-.02-5.36-.2-1.45-.61-3.13-.71-5.36-.02-.49-.39-1.19-.05-1.6.52-.63 1.71.23 2.52-.2 1.51-.79-.13-5.06-.05-11.46.03-3.15.44-3.33.24-5.78-.32-3.67-1.48-6.23-1.99-7.48-2.27-5.68-3.31-15.75.64-20.36 2.52-2.94 5.28-1.26 10.97-3.96 5.41-2.58 8.94-6.78 11.1-10.02l-7.58-8.78"
				/>
				<Path
					fill="#E3E2E2"
					d="M47.59 236.03c14.88 2.34 27.5 3.23 61.3 3.76 43.11.69 77.47.11 111.39-9.14 10.09-2.75 18.64-5.66 18.44-8.76-.27-4.11-15.78-6.64-31-8.72-38.94-5.32-58.41-7.99-91.42-7.85-33.79.14-53.77 3.34-93.75 9.74-10.72 1.71-21.3 3.62-21.55 6.84-.49 6.6 43.13 13.59 46.58 14.13"
				/>
				<Path
					fill="#38B8E8"
					d="M120.13 81.64c8.07-.13 8.76-7.08 18.91-7.46 5.52-.21 10.21 1.69 13.73 3.05 5.39 2.08 9.38 4.88 11.96 6.99-.14 2.87-.57 5.13-.91 6.59-.38 1.66-.78 2.67-3.57 10.15-2.97 7.92-4.48 11.47-5.52 14.26-5.52 14.83-4.31 29.71-3.74 36.8.35 4.2 6.16 28.03 8.14 32.05-7.15 8.17-8.01 12.45-6.78 14.83 2.7 5.23 16.19 2.31 17.57 7.23.68 2.44-1.9 5.17-2.63 5.99-3.36 3.82-7.29 2.23-14.72 6.32-3.67 2.02-3.74 2.97-5.79 3.13-4.54.37-7.26-4.03-10.04-2.74-1.77.82-1.29 2.91-3.45 4.25-2.38 1.48-5.38.17-6.23.2-10.75.34-27.77.4-55.13-.87.02-2.8.14-5.15.26-6.87.2-2.83.44-6.31 1.32-10.74.67-3.37 1.26-5.43 1.26-5.43.03-.5.15-1.2.6-1.85.94-1.37 2.43-1.12 3.05-2.07.7-1.06-.24-2.84-1.35-4.81-1.57-2.77-4.81-8.44-5.09-8.26-.31.19 3.82 7 3.73 7.05 0 0 .41-1 1.05-2.65 3.05-7.77 4.32-13.06 6.58-20.75 1.9-6.47 2.94-9.39 3.56-11.46 2.33-7.8 3.81-20.78-.51-43.24-4.19-6.01-5.8-8.58-5.82-9.02v-.05c0-.17-.04-.69-.08-1.36-.02-.24-.03-.43-.03-.43-.05-.55 2.54-7.51 9.24-24.48 1.01-.42 2.42-.96 4.15-1.44 0 0 1.92-.54 3.73-.85 5.17-.87 14.1 8.11 22.55 7.97"
				/>
				<Path
					fill="#999"
					d="M195.26 167.62c-7.7-.08-13.71.87-17.03 1.72-2.21.57-5.96 1.8-8.87 2.99-.13.05-.27.11-.47.2-1 .45-1.9.88-2.76 1.33-1.59.82-3.05 1.72-3.43 1.95-1.2.72-2.96 1.71-5.25 2.84-.28-1.08-.57-2.15-.85-3.23a91.473 91.473 0 0 1-19.84 5.35c-7.12 1.08-12.56 1.04-17.8 1.01-6.17-.04-12.84-.08-21.37-1.78a90.39 90.39 0 0 1-16.79-5.08c-1.95 3.47-5.31 8.13-10.85 10.34-7.81 3.11-16.19-.06-15.74.09 21.41 7.92 42.82 15.82 64.23 23.73l64.82-23.92c4-5.85 8.01-11.69 12.01-17.54"
				/>
				<Path
					fill="#A67A6B"
					d="M138.58 218.32c3.78 1.81 6.63 2.48 11.12 3.31 4.49.83 15.81 2.08 21.03 1.74 5.23-.35 11.94-.98 14.1.26 2.18 1.24 8.05 2.82 10.33 2.83 2.29 0 1.75-.67 1.75-.67s1.49.27 2.29-.39 1.13-1.7.88-1.99l-.25-.3s1.62-1.01 1.51-1.8c-.11-.8-.49-1.76-.49-1.76s.73-1.92.35-2.83c-.38-.91-2.31-3.45-3.48-4.33-1.16-.9-2.85-1.6-3.45-1.66-.59-.05-1.83-.13-1.83-.13s-1.37-1.19-2.1-1.85c-.72-.67-2.19-1.77-3.42-1.93-1.23-.16-14.99.42-17.29.29-2.29-.12-14.13-.38-16.46-.73s-4.48-2.5-6.13-2.65c-1.65-.15-5.29.35-5.29.35l-3.19 14.24h.02z"
				/>
				<Path
					fill="#BAB8B8"
					d="M43.28 170.7c-4.25.9-7.44 2.55-9.25 3.49-2.73 1.43-4.17 2.09-4.99 3.18-4.29 5.61.29 16.23.75 17.27 1.12 2.53 4.92 9.51 19.48 16.7 13.05 6.45 24.43 7.94 36.09 9.37 16.44 2.01 24.67 3.02 35.55 1.14 7.92-1.37 18.01-4.33 32.12-2.16 2.23.35 4.05.74 5.26 1.02v-.16c.07-2.08.05-3.05.1-4.04.17-3.44.55-6.57 1.02-9.37a377.424 377.424 0 0 1-30.9-7.66c-10.33-3.03-15.49-4.55-20.97-6.96-8.55-3.75-12.64-6.78-28.45-14.38-1.75-.84-5.43-2.59-10.5-4.33-3.89-1.33-7.93-2.69-13.4-3.31-3.49-.39-7.18-.81-11.91.2z"
				/>
				<Path
					fill="#A67A6B"
					d="M133.26 71.75c-.24-.24-.49-.53-.75-.85-1.86-2.34-2.78-5.41-2.62-11.48.09-3.64.57-8.89 2.41-15.13h-21.62c.57 5.95.56 10.99.38 14.88-.22 4.77-.7 8.14-2.91 11.33-.47.68-.93 1.22-1.35 1.67l-9.62 1.62c.46 3.18 1.68 8.43 5.36 13.64 1.04 1.48 2.4 3.4 4.58 5.09 6.23 4.81 16.18 5.55 23.66 1.52 7.13-3.85 10.08-10.8 11.32-13.73.97-2.3 1.48-4.33 1.78-5.78-6.53-1.85-9.58-2.61-10.61-2.77l-.01-.01z"
				/>
				<Path
					fill="#999"
					d="M48.51 208.44c8.11 3.05 11.38.63 26.38 1.85 1.97.16 3.71.33 5.61-.53 1.07-.49 1.72-1.1 3.23-1.46.17-.04 1.9-.44 3.56-.24 5.76.71 3.17 1.26 6.56 4.32 2.22 2 5.93 3.54 35.96 9.14 2.92.54 6.8 1.26 11.39 2.08a979.962 979.962 0 0 0-48.51-3.57c-8.16-.4-16.19-.69-24.08-.88-6.45-2.38-11.93-4.66-16.25-6.56-11.17-4.92-15.34-7.59-18.77-11.89a29.79 29.79 0 0 1-3.22-4.99c7 7.87 14.2 11.24 18.15 12.73"
				/>
				<Path
					fill="#7D5E54"
					d="M133.43 52.38c-.032.05.012.169.092.321l.808.019c-.53-.32-.83-.43-.9-.34zM130.141 64.975c-.223-1.527-.31-3.339-.251-5.555a57.01 57.01 0 0 1 .581-6.791l-19.329-.458c.057 2.621.012 4.97-.082 6.999-.081 1.753-.199 3.315-.423 4.751 2.443 1.648 5.787 3.158 9.993 3.299 3.875.124 7.085-.959 9.511-2.245z"
				/>
				<Path
					fill="#F7AB78"
					d="M108.84 38.79c-.18.02-.99.08-1.45.6-.11.13-.31.35-.31.65 0 0 0 .16.08.3.28.46 1.33.62 1.33.62-.23-.19-.31-.38-.35-.49-.17-.52.14-1.01.26-1.19.15-.24.33-.39.45-.49"
				/>
				<Path
					fill="#A67A6B"
					d="M142.43 30.64c-.59-.28-1.29-.06-1.49.02-.15.06-.44.16-.79.38 0 0-.27.19-.57.48-.11-5.94-1.24-13.64-6.48-19.24-2.52-2.7-5.24-4.11-7.09-4.86-7.68-1.79-12.32.47-13.91 1.38-7.92 4.53-9.61 15.18-10.17 22.45-.62-.75-1.3-.97-1.54-1.06-.2-.08-.9-.3-1.49-.02-1.23.58-1.7 3.21-1.5 5.25.13 1.41.98 2.98 2.66 6.11.63 1.18 1.13 1.99 1.92 2.48.06.04.13.07.2.11.15.73.32 1.48.55 2.25.6 2.06 3.53 12.74 12.29 15.47.49.15 9.7 3.7 17.06-2.35 4.49-3.69 6.09-9.14 6.79-14.76.17-.07.34-.15.49-.24.79-.49 1.29-1.31 1.92-2.48 1.69-3.14 2.53-4.71 2.66-6.12.2-2.04-.27-4.67-1.5-5.25h-.01zM93.39 225.64c-3.75 1.65-6.56 2.23-10.98 2.91-4.43.68-15.53 1.56-20.64 1.07-5.1-.5-11.65-1.31-13.8-.16-2.17 1.15-7.96 2.52-10.2 2.46-2.24-.07-1.69-.71-1.69-.71s-1.48.22-2.24-.45c-.76-.68-1.05-1.7-.8-1.99l.25-.28s-1.55-1.03-1.41-1.81c.13-.78.53-1.7.53-1.7s-.66-1.89-.27-2.78c.4-.88 2.37-3.3 3.53-4.14 1.17-.84 2.84-1.48 3.42-1.52.59-.04 1.8-.07 1.8-.07s1.37-1.12 2.1-1.75c.73-.63 2.2-1.67 3.41-1.79 1.21-.12 14.66.86 16.91.81 2.25-.05 13.84.05 16.13-.23s4.46-2.31 6.08-2.4c1.62-.1 5.17.49 5.17.49l2.69 14.04h.01z"
				/>
				<Path
					fill="#BAB8B8"
					d="M197.18 167.71c4.11.51 7.27 1.8 9.06 2.54 2.71 1.12 4.11 1.63 4.99 2.58 4.25 4.63 1.15 14.7.63 16.23-.86 2.53-4.19 11.06-19.59 20.66-4.1 2.55-14.54 8.59-32.02 12.47-4.74 1.05-11.17 2.2-41.93 3.68-10.94.53-20.01.86-26.29 1.06a72.114 72.114 0 0 0-.63-7.29c-.4-3.05-.93-5.91-1.38-8.11 10.23-3.35 19.72-6.78 28.43-10.18 10.37-4.04 15.53-6.1 19.39-8.16 7.83-4.19 11.47-7.35 25.9-15.7 1.6-.93 4.96-2.86 9.64-4.88 3.59-1.55 7.32-3.14 12.46-4.15 3.29-.64 6.75-1.33 11.33-.75"
				/>
				<Path
					fill="#999"
					d="M183.49 209.87c-5.05 1.7-14.17 4.59-26.2 7.22-16.21 3.54-21.63 3.1-53.97 7.93-4.73.71-8.58 1.31-11.05 1.7l46.89-2.13c7.46-.9 14.92-1.8 22.38-2.69 3.29-.82 14.54-3.7 24.08-8.21 5.4-2.55 8.54-4.81 10.19-6.09a48.36 48.36 0 0 0 4.82-4.25c-4.37 1.85-10.18 4.17-17.14 6.52zM90.27 211.59c2.27-.47 4.52-1 6.75-1.59 2.11-.56 4.22-1.18 6.3-1.85 2.26-.72 4.49-1.51 6.71-2.36 2.06-.79 4.09-1.64 6.1-2.54 2.43-1.08 4.82-2.25 7.18-3.48.32-.17 3.81-2.29 4.13-2.47.29-.15-.27-2.41-.57-2.26-2.38 1.27-9.53 5.05-11.98 6.17-2.05.94-4.13 1.83-6.23 2.65-2.16.84-4.34 1.63-6.54 2.35-2.03.66-4.07 1.27-6.13 1.83-2.32.64-4.65 1.2-7 1.7-.24.05 1.38 1.31 1.15 1.36-.33.06-.19.54.13.48"
				/>
				<Path
					fill="#A67A6B"
					d="M148.79 75.87c-1.12 9.04-.22 15.96.85 20.69 1.26 5.58 3.06 9.38 4.58 18.31.71 4.16.7 5.58 1.22 9.68.69 5.33 2 13 4.76 22.42 4.55 5.58 9.08 9 12.29 11.06 2.36 1.52 1.82.89 20.58 8.45 0 0 4.07.03 13.2 3.84 1.85.77 4 1.23 6.3.46s7.22-5.84 7.22-5.84 2.31-3.84 3.38-5.6c.88-1.44 1.85-2.41 1.61-2.94-.06-.14-.2-.2-.38-.29-.71-.37-1.77-.27-2.39.31-.49.46-.53 1.06-.97 1.08a.447.447 0 0 1-.33-.09c-.69-.38-1.77.46-1.77.46-.78.55-1.28 1.1-1.6 1.51-.46.57-.89 1.27-.89 1.27-.41.64-.38.71-.58.9-.35.33-.64.31-1.59.71-.46.19-.84.35-1.11.45-.3-.35-.92-.92-.92-1.77 0-1.3.38-3.46 1-4.22.61-.77 1.85-1.77 1.99-2.91.16-1.15.85-1.85-.31-2.69-1.15-.84-2.46.46-2.46.46s-1.69 1.3-1.92 2.15c-.23.84-.23 1.38-.85 2.15-.61.76-.69 1-1.77 1.38-1.07.38-4.45 1.69-5.37 2.3s-1.3 1.22-2.15 1.22c-.85 0-11.67-7.06-14.13-9.21-2.46-2.15-12.9-13.21-12.9-13.21-1.55-6.82-2.25-12.39-2.61-16.04-1.16-12.04.06-17.69-2.5-28.83-1.87-8.09-3.79-10.64-5.33-12.18-4.75-4.77-11.25-5.38-14.17-5.46"
				/>
				<Path
					fill="#7D5E54"
					d="m163.04 140.14.014.002-.004-.002zM219.11 157.95c-1.12-1.91-2.27-3.14-3.09-3.9-.9-.84-1.69-1.41-2.07-1.08-.61.53-.69 1.08-.61 1.84.08.77 1.38 1.46 1.61 2.39.24.92 1.15 2.45 1.08 3.3-.07.85-.15 1.08-.15 1.08.2-.19.48-.47.79-.86.35-.43.54-.79.64-.93.27-.46.79-1.1 1.81-1.82"
				/>
				<Path
					fill="#A67A6B"
					d="M92.11 75.2c.59 3.71 1.41 10.48.72 17.64-.29 2.97-.49 5.07-1.36 7.86-.69 2.21-1.27 3.16-2.2 6.43-.41 1.44-.64 2.54-1.16 4.74-.43 1.87-.86 3.96-1.27 6.34-.71 4.16-.7 5.58-1.23 9.68-.69 5.33-2 13-4.76 22.42-4.55 5.58-9.08 9-12.29 11.06-2.36 1.52-1.82.89-20.57 8.45 0 0-4.08.03-13.21 3.84-1.85.77-4 1.23-6.3.46s-7.22-5.84-7.22-5.84-2.3-3.83-3.38-5.6c-.87-1.44-1.85-2.41-1.61-2.94.06-.14.2-.2.38-.29.71-.37 1.77-.27 2.39.31.49.46.53 1.06.97 1.08.14.02.24-.03.34-.09.69-.38 1.76.46 1.76.46.78.56 1.28 1.11 1.6 1.51.46.57.89 1.27.89 1.27.41.64.38.71.59.9.34.33.64.31 1.58.71.46.19.84.35 1.11.45.3-.35.92-.92.92-1.77 0-1.3-.38-3.46-1-4.22-.61-.77-1.85-1.77-1.99-2.91-.16-1.15-.85-1.85.31-2.69 1.15-.84 2.46.46 2.46.46s1.69 1.3 1.92 2.15c.24.84.24 1.38.85 2.15.61.76.69 1 1.77 1.38 1.08.38 4.45 1.69 5.37 2.3s1.3 1.23 2.15 1.23c.85 0 11.67-7.06 14.13-9.21 2.46-2.15 12.9-13.21 12.9-13.21 1.55-6.82 2.25-12.38 2.61-16.04 1.23-12.74.03-17.87 2.51-28.83 2.43-10.78 5.69-14.26 7-15.55 4.24-4.14 9.33-5.55 12.34-6.1"
				/>
				<Path
					fill="#7D5E54"
					d="m77.71 142.97-.003.002.014-.002zM21.55 160.84c1.12-1.91 2.27-3.14 3.09-3.9.9-.84 1.69-1.41 2.07-1.08.61.53.69 1.08.61 1.84-.08.77-1.38 1.46-1.61 2.39-.24.92-1.15 2.45-1.08 3.3.07.85.15 1.08.15 1.08-.2-.19-.48-.47-.79-.86-.35-.43-.54-.79-.64-.93-.27-.46-.79-1.1-1.81-1.82M87.35 116.57a58.64 58.64 0 0 0 4.3-12.89c1.3-6.27 1.37-11.74 1.27-11.75-.05 0-.13 2.14-.76 5.3-.99 4.86-2.84 9.7-3.57 11.59-.4 1.04-1.25 3.2-1.3 6.1-.02.69.02 1.26.05 1.64M154.44 116.15a64.243 64.243 0 0 1-4.3-12.89c-1.4-6.41-1.61-12.11-1.47-12.13.1 0 .26 2.62 1.24 6.67 1.39 5.7 3.28 10.6 3.28 10.6.38 1 1.25 3.16 1.3 6.1.02.69-.02 1.26-.05 1.64"
				/>
				<Path
					fill="#3B2E30"
					d="M139.18 32.03s.04-.08.07-.12c.38-.53.91-.89.91-.89.35-.22.64-.31.79-.38.2-.08.9-.3 1.49-.02.2.09.36.25.52.43.03-.14.06-.29.09-.44.87-4.56.11-8.54-.2-10.13-1.44-7.54-5.58-11.79-7.19-13.45C129.99 1.17 123.85.84 121.6.76c-5.07-.17-8.85 1.74-10.07 2.4-2.07 1.12-9.49 5.18-12.07 14.79-1.19 4.47-.93 8.61-.7 12.12 0 .05 0 .11.01.16.05-.03.09-.08.14-.1.59-.28 1.29-.06 1.49.02.27.1 1.04.35 1.7 1.26.1.14.19.29.27.44 1.32-2.16 2.75-3.5 3.37-4.05 4.76-4.15 3.79-10.16 7.4-11.03 2.72-.64 3.68 2.71 8.22 2.45 1.38-.08 2.75-.47 4.89 0 .69.15 1.77.39 2.89 1.11 1.88 1.19 1.7 2.48 3.48 4.15 2.24 2.09 3.49 1.62 4.89 3.41.66.86 1.21 1.94 1.66 4.12l.01.02zM100.83 42.82l.02.07v-.05s-.01-.02-.02-.02zM139.82 44.12c0 .15.01.23.04.23s.07-.13.11-.38l-.15.15z"
				/>
				<Path
					fill="#7D5E54"
					d="M173.737 138.737c-2.064.674-4.411 1.279-5.527 1.493-1.837.342-5.011-.069-5.156-.088 2.67 1.409 6.717 2.508 9.216 1.468.998-.415 2.015-.802 2.996-1.261a818.73 818.73 0 0 1-1.529-1.612zM67.624 141.759c-.156.166-.738.781-1.581 1.668.809.354 1.634.675 2.447 1.013 2.499 1.039 6.546-.059 9.216-1.468-.147.019-3.32.43-5.156.088-1.008-.193-3.023-.707-4.926-1.301z"
				/>
			</Svg>
		</View>
	);
}
