import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IScreenTemplateCenteredWithCard {
	children: React.ReactNode;
	card: React.ReactNode;
}

export default function ScreenTemplateCenteredWithCard({
	children,
	card,
}: IScreenTemplateCenteredWithCard) {
	const safeInsets = useSafeAreaInsets();
	const rules = useStyleRules();

	const styles = StyleSheet.create({
		scrollView: {
			backgroundColor: rules.colors.background,
		},
		scrollViewContainer: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			paddingTop: rules.spacing.appVerticalMargin,
			paddingBottom: Math.max(rules.spacing.appVerticalMargin, safeInsets.bottom),
			flexGrow: 1,
		},
		body: {
			flexGrow: 1,
			justifyContent: 'center',
			alignSelf: 'center',
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
		},
		cardContainer: {},
	});

	return (
		<ScrollView
			overScrollMode="never"
			bounces={false}
			bouncesZoom={false}
			style={styles.scrollView}
			contentContainerStyle={styles.scrollViewContainer}
		>
			<View style={styles.body}>
				<View>{children}</View>
			</View>
			<View style={styles.cardContainer}>{card}</View>
		</ScrollView>
	);
}
