import { Platform, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

export function createFlexibleGraphicProps(
	/**
	 * Original viewport width of the SVG. Width and height are required to infer aspect ratio.
	 */
	originalWidth: number,

	/**
	 * Original viewport height of the SVG. Width and height are required to infer aspect ratio.
	 */
	originalHeight: number
): {
	/**
	 * These styles are intended to be applied to the direct parent <View> of the SVG.
	 */
	wrapperStyles: ViewStyle;

	/**
	 * These props are intended to be spread onto the <Svg>.
	 */
	dimensionProps: SvgProps;
} {
	const aspectRatio = originalWidth / originalHeight;

	return {
		wrapperStyles: {
			width: '100%',
			aspectRatio,
		},
		dimensionProps: {
			width: '100%',
			height: Platform.OS !== 'web' ? '100%' : undefined,
			viewBox: `0 0 ${originalWidth} ${originalHeight}`,
		},
	};
}
