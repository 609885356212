import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useSendVerificationCode, useVerifyCode } from '@mobe/api/authentication/authApiHooks';
import { VerifyCodeErrorCode } from '@mobe/api/authentication/authenticationService';
import Box from '@mobe/components/box/Box';
import { Button } from '@mobe/components/button';
import { InputText } from '@mobe/components/input';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useAlert } from '@mobe/utils/useAlert';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform, useWindowDimensions } from 'react-native';
import {
	SMSVerifyScreenNavigationProp,
	SMSVerifyScreenRouteProp,
} from '../AuthenticationStackScreen';
import AcceptNote from '../components/acceptNote/AcceptNote';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export default function SMSVerifyScreen(props: {
	navigation: SMSVerifyScreenNavigationProp;
	route: SMSVerifyScreenRouteProp;
}) {
	const auth = useAuth();
	const verifyCode = useVerifyCode();
	const sendVerificationCode = useSendVerificationCode();
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const dimensions = useWindowDimensions();
	const { mobeAlert } = useAlert();

	const [verificationCode, setVerificationCode] = React.useState('');

	return (
		<AuthScreenTemplate
			heading={t('auth.smsVerify.heading')}
			header={
				<Box row>
					<Text weight="semiBold">{t('auth.common.newParticipant')} </Text>
					<TextButton
						onPress={() => {
							props.navigation.navigate('ELIGIBILITY_CHECK_SCREEN');
						}}
						title={t('auth.common.newParticipantButton')}
					></TextButton>
				</Box>
			}
		>
			<Text
				weight="medium"
				align="center"
				style={{
					paddingHorizontal: Platform.OS !== 'web' ? dimensions.width / 6 : undefined,
					...vr(6),
				}}
			>
				{t('auth.smsVerify.description')}
			</Text>

			<InputText
				label={t('auth.smsVerify.verificationCodeInputLabel')}
				type="integer"
				textContentType="oneTimeCode"
				enterKeyHint="go"
				value={verificationCode}
				onChangeText={(value) => {
					setVerificationCode(value);
				}}
			/>

			<Button
				title={t('auth.smsVerify.verifyButtonLabel')}
				style={vr(5)}
				loading={verifyCode.isPending}
				onPress={async () => {
					const response = await verifyCode.execute(auth.firstTimePhoneNumber, verificationCode);

					if (response.success) {
						Keyboard.dismiss();

						// Pushed to end of event cycle to avoid keyboard offset issue on Android
						setTimeout(() => {
							props.navigation.navigate('CLAIM_ACCOUNT_SCREEN', {
								accessToken: response.data.accessToken,
							});
						}, 0);
					} else {
						if (response.errorCode === VerifyCodeErrorCode.INVALID_VERIFICATION_CODE) {
							mobeAlert('', t('auth.smsVerify.error.invalidAuthCode'));
						}
					}
				}}
			/>

			<TextButton
				title={t('auth.smsVerify.resendVerificationCodeButtonLabel')}
				style={{ alignSelf: 'center', ...vr(3) }}
				loading={sendVerificationCode.isPending}
				onPress={async () => {
					const response = await sendVerificationCode.execute(auth.firstTimePhoneNumber);

					if (response.success) {
						mobeAlert('', t('auth.smsVerify.resendConfirmationMessage'));
					} else {
						mobeAlert('', t('auth.smsPrompt.error.problemSendingVerificationCode'));
					}
				}}
			/>

			<AcceptNote />
		</AuthScreenTemplate>
	);
}

export function useSMSVerifyScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.smsVerify.screenTitle'),
		headerShown: false,
	};
}
