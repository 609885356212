import * as Localization from 'expo-localization';
import i18n from 'i18next';
import 'intl-pluralrules'; // Need this polyfill for new plurals https://github.com/eemeli/intl-pluralrules
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import enTranslations from '../locales/en/translation.json';

// These could be loaded and set from a backend
// https://www.i18next.com/how-to/add-or-load-translations
export const resources = {
	en: {
		translation: enTranslations,
	},
} as const;

function getLanguageCode() {
	try {
		// Expo docs claim this function will always return at least one locale object
		const locales = Localization.getLocales();

		if (Array.isArray(locales) && locales.length > 0) {
			const languageCode = locales[0].languageCode;

			if (languageCode === null) {
				return undefined;
			}

			return languageCode;
		} else {
			return undefined;
		}
	} catch (error) {
		return undefined;
	}
}

i18n.use(initReactI18next).init(
	{
		lng: getLanguageCode(), // Use locale from user device
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // React already saves us from xss
		},
		resources,
	},
	(error, t) => {
		// Add overrides to default yup error messages from dictionary
		setLocale({
			mixed: {
				required: ({ label }: { label: string }) => t('forms.errors.required', { label }),
			},
			string: {
				email: ({ label }: { label: string }) => t('forms.errors.email', { label }),
			},
		});
	}
);

export function emptyStringToNull(value: string, originalValue: string) {
	if (typeof originalValue === 'string' && originalValue === '') {
		return null;
	}
	return value;
}
