import { mobeAuthenticatedAPI } from '@mobe/api/mobeAPI';
import axios from 'axios';
import React from 'react';
import { ImageResizeMode, Platform } from 'react-native';
import { useQuery } from 'react-query';

const CACHE_KEY = 'MOBE.imageCache';

// Try to remove old base64 data cached on user
try {
	if (Platform.OS === 'web' && caches) {
		caches.delete(CACHE_KEY);
	}
} catch (error) {
	console.warn('caches does not exist within this context');
}

function useAuthenticatedImageQuery(uri: string) {
	return useQuery(
		['image', uri],
		async () => {
			// Get image with uri and auth headers, this should return blob
			const response = await axios.get(uri, {
				responseType: 'blob',
				headers: mobeAuthenticatedAPI.headers,
			});

			// Inspired by: (also reason why we went away from storing base64 strings in cache)
			// https://alphahydrae.com/2021/02/how-to-display-an-image-protected-by-header-based-authentication/
			//
			// WARNING!:
			// We are not currently revoking these urls, we are try to keep the same blob pointer in RQ cache during user session
			// so we can use it to retrieve the image from memory when a screen is revisited/rerendered
			// if there is ever an issue with performance or invalidation we may need to revoke these urls in the RQ cache
			// at time of writing there is not a large amount of images on web app
			return URL.createObjectURL(response.data);
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
		}
	);
}

/**
 * Web only component for handling protected images in a performant way
 */
export default function MobeFetchedImage({
	uri,
	width,
	height,
	resizeMode = 'cover',
}: {
	uri: string;
	width?: string | number;
	height?: string | number;
	resizeMode?: ImageResizeMode;
}) {
	const imageQuery = useAuthenticatedImageQuery(uri);

	if (Platform.OS !== 'web') {
		return null;
	}

	/**
	 * Map RN image resizeMode to CSS objectFit.
	 * Only supports 'contain' and 'cover', anything else will fallback to 'cover'.
	 */
	function getResizeMode(mode: ImageResizeMode): 'contain' | 'cover' {
		if (mode === 'contain' || mode === 'cover') {
			return mode;
		}

		return 'cover';
	}

	return imageQuery.isSuccess ? (
		<img
			src={imageQuery.data}
			width={width}
			height={height}
			style={{
				width: width ?? '100%',
				height: height ?? '100%',
				objectFit: getResizeMode(resizeMode),
			}}
		/>
	) : null;
}
