export const isNumberOnly = (val: string) => {
	return /^[0-9]*$/.test(val);
};

export const obfuscateUser = (val: string) => {
	return val.length ? `${val.slice(0, 5)}*****` : '';
};

export const obfuscatePhone = (val: string) => {
	return `***-***-${val.slice(6)}`;
};
