import {
	useChatsQuery,
	useConsumerReceivedGuideMessagesMutation,
	useConsumerReceivedMessageMutation,
	useSendChatAttachmentMessageMutation,
	useSendChatMessageMutation,
} from '@mobe/api/chat/chatApiHooks';
import { IChatResponse, IServerChatMessage } from '@mobe/api/chat/chatService';
import { ChatQueryKeys } from '@mobe/api/chat/types';
import { announceForAccessibility, useAccessibilityFocus } from '@mobe/utils/a11y';
import { IMediaSelection } from '@mobe/utils/useImageAcquisitionService';
import { differenceInMinutes, format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteData, useQueryClient } from 'react-query';
import { ChatHubEvents, useChatHubConnection } from '../chatHubConnectionContext';
import { IMessagesSet } from './Message';

export default function useChat({ channelId }: { channelId: number }) {
	const [currentMessage, setCurrentMessage] = React.useState('');
	const [attachmentMediaUri, setAttachmentMediaUri] = React.useState('');
	const [attachmentFile, setAttachmentFile] = React.useState<undefined | File>(undefined);
	const queryClient = useQueryClient();
	const chatHubConnection = useChatHubConnection();
	const [messageInputRef, setFocusToMessageInput] = useAccessibilityFocus();
	const { t } = useTranslation();

	const [paginationStartDate, setPaginationStartDate] = React.useState(() => {
		return new Date(Date.now()).toISOString();
	});

	const chatsQuery = useChatsQuery(paginationStartDate, channelId);
	const consumerReceivedGuideMessagesMutation = useConsumerReceivedGuideMessagesMutation(channelId);
	const consumerReceivedMessageMutation = useConsumerReceivedMessageMutation(channelId);
	const sendChatMessageMutation = useSendChatMessageMutation(channelId);
	const sendChatAttachmentMessageMutation = useSendChatAttachmentMessageMutation(channelId);

	// Create section data for section list from chats query
	const currentChatsAsSectionData =
		chatsQuery.data?.pages
			.flatMap((page) => page.chatMessages.slice().reverse())
			.reduce((accumulation: { title: string; data: IMessagesSet[] }[], currentMessage) => {
				const currentMessageDate = new Date(currentMessage.messageDate);
				const title = format(currentMessageDate, 'MMMM dd, yyyy');

				let section = accumulation.find((section) => section.title === title);

				if (!section) {
					section = {
						title,
						data: [],
					};

					accumulation.push(section);
				}

				// Get last message set in the section
				let messageSet = section.data[section.data.length - 1];

				// Does the last message set not exist? Is it not from the same user?
				// Is the last message (actually first in array) date greater than or equal to 5 since the current message?
				// Push new message set
				if (
					!messageSet ||
					messageSet.fromUserId !== currentMessage.fromUserId ||
					differenceInMinutes(new Date(messageSet.messages[0].createdAt), currentMessageDate) >= 5
				) {
					messageSet = {
						id: currentMessage.id,
						fromUserId: currentMessage.fromUserId || -1,
						isSentByCoach: currentMessage.isSentByCoach,
						messages: [],
					};

					section.data.push(messageSet);
				}

				// Add current message to beginning of message set messages
				messageSet.messages.unshift({
					id: currentMessage.id,
					attachmentId: currentMessage.imageAttachmentFileId,
					text: currentMessage.messageText || '',
					createdAt: currentMessage.messageDate,
				});

				return accumulation;
			}, []) || [];

	// Listen to real time events from signalr and update chats query data as needed
	React.useEffect(() => {
		async function handleMessageReceived(message: IServerChatMessage) {
			await queryClient.cancelQueries([ChatQueryKeys.Chats, message.channelId]);

			const previousData = queryClient.getQueryData<InfiniteData<IChatResponse>>([
				ChatQueryKeys.Chats,
				message.channelId,
			]);

			if (previousData) {
				const nextData = {
					...previousData,
				};
				nextData.pages[0].chatMessages.push(message);

				queryClient.setQueryData<InfiniteData<IChatResponse>>(
					[ChatQueryKeys.Chats, message.channelId],
					nextData
				);
			}

			if (message.isSentByCoach) {
				// Mark message read by user in guide portal
				consumerReceivedGuideMessagesMutation.mutate();

				// Mark message read by user, this affects database
				consumerReceivedMessageMutation.mutate({ messageId: message.id });

				if (message.messageText) {
					// Announce message from guide, timeout here because ios needed extra help
					setTimeout(() => {
						announceForAccessibility(
							t('chat.messageReceivedFromGuideAnnouncement', {
								date: format(new Date(message.messageDate), 'p'),
								message: message.messageText,
							})
						);
					}, 250);
				}
			} else {
				if (message.messageText) {
					// Announce last message sent to guide, timeout here because ios needed extra help
					setTimeout(() => {
						announceForAccessibility(
							t('chat.messageReceivedFromUserAnnouncement', { message: message.messageText })
						);
					}, 250);
				}
			}
		}

		chatHubConnection.connection?.on(ChatHubEvents.GuideSentMessage, handleMessageReceived);
		chatHubConnection.connection?.on(ChatHubEvents.ConsumerSentMessage, handleMessageReceived);

		return () => {
			chatHubConnection.connection?.off(ChatHubEvents.GuideSentMessage, handleMessageReceived);
			chatHubConnection.connection?.off(ChatHubEvents.ConsumerSentMessage, handleMessageReceived);
		};
	}, []);

	React.useEffect(() => {
		// On first load move focus to chat input
		setFocusToMessageInput();
	}, []);

	function handleChangeText(text: string) {
		setCurrentMessage(text);
	}

	function handleEndReached() {
		if (chatsQuery.hasNextPage) {
			chatsQuery.fetchNextPage();
		}
	}

	async function handleSendMessage() {
		if (attachmentMediaUri) {
			sendAttachmentMessage();
			return;
		}

		if (currentMessage) {
			sendMessage();
		}
	}

	async function sendAttachmentMessage() {
		const attachment = attachmentFile ?? attachmentMediaUri;

		sendChatAttachmentMessageMutation.mutate(
			{ attachment, messageText: currentMessage },
			{
				onSuccess: () => {
					setAttachmentMediaUri('');
					setCurrentMessage('');
					setAttachmentFile(undefined);
				},
			}
		);
	}

	async function sendMessage() {
		const previousMessage = currentMessage;

		setCurrentMessage('');

		sendChatMessageMutation.mutate(
			{ messageText: previousMessage },
			{
				onSuccess: () => setFocusToMessageInput(),
				onError: () => setCurrentMessage(previousMessage),
			}
		);
	}

	async function processMedia(mediaSelection: IMediaSelection) {
		setAttachmentMediaUri(mediaSelection.uri);
		mediaSelection.file && setAttachmentFile(mediaSelection.file);
	}

	function handleRemoveAttachment() {
		setAttachmentMediaUri('');
	}

	return {
		messageInputRef,
		currentMessage,
		attachmentMediaUri,
		chatsQuery,
		currentChatsAsSectionData,
		sendChatMessageMutation,
		sendChatAttachmentMessageMutation,
		isSendingMessage:
			sendChatMessageMutation.isLoading || sendChatAttachmentMessageMutation.isLoading,
		handleSendMessage,
		handleChangeText,
		handleRemoveAttachment,
		handleEndReached,
		processMedia,
	};
}
