import { ITrackerType } from '@mobe/api/track/trackService';
import CardButton from '@mobe/components/cardButton/CardButton';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import { Text } from '@mobe/components/text';
import { useAccessibilityAutoFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { format } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useMenuNavigation } from '../../navigation/menu/MenuNavigationProvider';
import { useModalNavigation } from '../../navigation/modal/ModalNavigationProvider';
import useTrackerContent from '../useTrackerContent';
import AddEntryButton from './AddEntryButton';

interface ITrackerCardProps {
	tracker: ITrackerType;
	accessibilityAutoFocus?: boolean;
}

export default function TrackerCard({
	tracker,
	accessibilityAutoFocus = false,
}: ITrackerCardProps) {
	const { constrain, vr } = useStyleHelpers();
	const styles = useStyles();
	const trackerContentMap = useTrackerContent();
	const trackerContent = trackerContentMap[tracker.trackerAbbreviation];
	const modalNavigation = useModalNavigation();
	const menuNavigation = useMenuNavigation();
	const { t } = useTranslation();
	const cardRef = useAccessibilityAutoFocus(accessibilityAutoFocus);

	function handleCardButtonPress() {
		menuNavigation.navigate('TRACK_DETAIL_SCREEN', {
			trackerAbbreviation: tracker.trackerAbbreviation,
		});
	}

	function handleAddEntryButtonPress() {
		modalNavigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
			trackerAbbreviation: tracker.trackerAbbreviation,
		});
	}

	return (
		<View key={tracker.trackerAbbreviation} style={styles.container}>
			<CardButton
				ref={cardRef}
				style={styles.cardButton}
				cardBodyStyle={styles.cardButtonBody}
				onPress={handleCardButtonPress}
				accessible
			>
				<View style={[constrain(65), vr(2), styles.graphic]}>{trackerContent.graphic}</View>
				<Heading level="h4" style={styles.heading}>
					{trackerContent.title}
				</Heading>
				{tracker.isFavorite ? (
					<Icon
						aria-label={t('track.isFavoriteAccessibilityLabel')}
						name="star_solid"
						color="goal"
						style={styles.favoriteIcon}
					/>
				) : null}
				{tracker.lastEntry ? (
					<View style={vr(2)}>
						<View aria-label={t('track.mostRecentEntryAccessibilityLabel')} />
						{trackerContent.lastEntry(tracker.lastValue ? Number(tracker.lastValue) : undefined)}
						<Text size="sm" color="light">
							{format(new Date(tracker.lastEntry), 'MM/dd/yyyy')}
						</Text>
					</View>
				) : (
					<Text color="light" style={vr(2)}>
						{trackerContent.shortDescription}
					</Text>
				)}
			</CardButton>
			<AddEntryButton
				onPress={handleAddEntryButtonPress}
				aria-label={t('track.detail.addEntryAccessibilityLabel', {
					trackerTitle: trackerContent.title,
				})}
			/>
		</View>
	);
}

function useStyles() {
	const styleRules = useStyleRules();

	return StyleSheet.create({
		container: { flexGrow: 1, marginRight: 10, marginBottom: 13 },
		cardButton: { flexGrow: 1 },
		cardButtonBody: { paddingTop: 8 },
		graphic: { alignSelf: 'flex-start' },
		favoriteIcon: { position: 'absolute', top: 0, right: 0 },
		heading: { fontSize: styleRules.fontSizes.lg },
	});
}
