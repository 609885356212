// This interface defines information/functionality relevant specifically to the project
export default interface IEnv {
	ENV_NAME: string;
	ENV_TYPE: EnvType;
	APP_VERSION: string;
	BUILD_DATE: string | undefined;
	MOBE_API_URL: string;
	SIGNALR_URL: string;
	VALIDIC_ORG_ID: string;
	WEB_STATIC_ASSETS_URL: string;
	TERMS_AND_CONDITIONS_URL: string;
	PRIVACY_POLICY_URL: string;

	REDIRECT_TO_SUCCESS_SCREEN_URL: string;
	REDIRECT_TO_CONNECT_HEALTH_DATA_URL: string;

	// defaults only used in dev at this point
	DEFAULT_LOGIN_USERNAME: string;
	DEFAULT_LOGIN_PASSWORD: string;

	INACTIVITY_TIMEOUT_IN_MS: number;

	ENABLE_ANALYTICS: boolean;
	ENABLE_ANALYTICS_DEBUG: boolean;
}

export enum EnvType {
	Unset = 'Unset',
	Dev = 'Dev',
	Training = 'Training',
	Prod = 'Prod',
}
