import { ExploreContentType, IExploreContentItemSummary } from '@mobe/api/explore/exploreService';
import PlayGraphic from '@mobe/components/graphics/PlayGraphic';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GestureResponderEvent,
	Platform,
	Pressable,
	StyleSheet,
	View,
	useWindowDimensions,
} from 'react-native';
import useTimeToConsumeMessage from '../useTimeToConsumeMessage';
import ExploreFavoriteButton from './ExploreFavoriteButton';

type CardVariant = 'regular' | 'compact' | 'featured';

/**
 * Props relevant to fetching/pre-fetching ContentCard images
 */
export function contentCardImageProps(
	url: string | null,
	windowWidth: number,
	cardVariant?: CardVariant
) {
	const isFeatured = cardVariant === 'featured';
	const renderWidth = isFeatured ? Math.min(windowWidth - 32, 864) : windowWidth * 0.4;
	const sizesArray = isFeatured ? [748, 796, 864] : [300, 331];

	return {
		source: { uri: url || '' },
		renderWidth,
		sizesArray,
	};
}

interface IContentCardProps {
	variant?: CardVariant;
	canFavorite?: boolean;
	onPress?: (event: GestureResponderEvent) => void;
	onFavoritePress?: () => void;
	contentItem: IExploreContentItemSummary;
	description?: string;
}

function ContentCard(
	{
		variant = 'regular',
		canFavorite = false,
		onPress = noop,
		onFavoritePress = noop,
		contentItem,
		description,
	}: IContentCardProps,
	ref: any
) {
	const windowDimensions = useWindowDimensions();
	const timeToConsumeMessage = useTimeToConsumeMessage({
		contentType: contentItem.contentType,
		timeToConsume: contentItem.timeToConsume || 0,
	});
	const styles = useContentCardStyles(variant);
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<Pressable ref={ref} onPress={onPress} style={styles.wrapper} role="button" accessible>
			<View style={styles.card}>
				<View style={styles.cardMedia}>
					<View style={styles.cardMediaInner}>
						<MobeImage
							{...contentCardImageProps(contentItem.imageUrl, windowDimensions.width, variant)}
							style={{ width: '100%', height: '100%' }}
						/>
					</View>
					{contentItem.isViewed && (
						<View style={[styles.cardStatus, styles.cardStatus_left]}>
							<Icon name="checkmark" color="primary" size={13} />
						</View>
					)}
					{contentItem.isFavorite && (
						<View style={[styles.cardStatus, styles.cardStatus_right]}>
							<Icon
								name="star_solid"
								color="favorite"
								aria-label={t('explore.isFavoriteAccessibilityLabel')}
								size={13}
							/>
						</View>
					)}
					{contentItem.contentType === ExploreContentType.Video && (
						<View style={styles.playOverlay}>
							<View style={styles.playIcon}>
								<PlayGraphic />
							</View>
						</View>
					)}
				</View>
				<View style={styles.cardBody}>
					<View style={styles.cardBodyHeading}>
						<Heading level="h4" role="none" numberOfLines={4} style={vr(2)}>
							{contentItem.title}
						</Heading>
						{variant === 'featured' && description && (
							<Text color="light" size="sm" weight="medium" numberOfLines={3}>
								{description}
							</Text>
						)}
					</View>
					{contentItem.timeToConsume && <Text color="light">{timeToConsumeMessage}</Text>}
				</View>
				{canFavorite && (
					<View style={styles.cardFavorite}>
						<ExploreFavoriteButton
							favorited={contentItem.isFavorite}
							size={20}
							onPress={onFavoritePress}
							hitSlop={10}
						/>
					</View>
				)}
			</View>
		</Pressable>
	);
}

export default React.forwardRef(ContentCard);

function useContentCardStyles(variant: CardVariant) {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		wrapper: {
			flexGrow: 1,
			flexShrink: 1,
		},
		card: {
			flexGrow: 1,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,
			...cardShadow,

			...(variant === 'compact' && {
				flexDirection: 'row',
				alignItems: 'center',
				paddingHorizontal: 10,
			}),
		},
		cardMedia: {
			aspectRatio: variant === 'featured' ? 2.2 : 1.5,

			// On web, padding is calculated relative to the elements height.
			// This allows for the emulation of aspect ratio via padding.
			...(Platform.OS === 'web' && {
				paddingBottom: variant === 'featured' ? '45%' : '66.66%',
			}),

			...(variant === 'compact' && {
				width: 100,
				marginRight: 10,
				marginVertical: 15,
				paddingBottom: undefined,
			}),
		},
		cardMediaInner: {
			backgroundColor: rules.colors.stroke,
			position: 'absolute',
			width: '100%',
			height: '100%',
			borderTopLeftRadius: rules.borderRadius,
			borderTopRightRadius: rules.borderRadius,
			overflow: 'hidden',

			...(variant === 'compact' && {
				borderTopLeftRadius: 2,
				borderTopRightRadius: 2,
				borderRadius: 2,
			}),
		},
		cardBody: {
			flexShrink: 1,
			flexGrow: 1,
			padding: 6,
			paddingTop: 8,
			minHeight: 100 * rules.settings.fontScale,

			...(variant === 'compact' && {
				paddingTop: 6,
				minHeight: undefined,
			}),
		},
		cardBodyHeading: {
			flexGrow: 1,
			marginBottom: 6,

			...(variant === 'compact' && {
				flexGrow: undefined,
				marginBottom: 0,
			}),
		},
		cardStatus: {
			position: 'absolute',
			top: 0,
			alignItems: 'center',
			justifyContent: 'center',
			width: 25,
			height: 25,
			opacity: 0.9,
			backgroundColor: rules.colors.cardBackground,
		},
		cardStatus_right: {
			right: 0,
			borderBottomLeftRadius: 4,
		},
		cardStatus_left: {
			left: 0,
			borderBottomRightRadius: 4,
		},
		cardFavorite: {
			alignSelf: 'center',
		},
		playOverlay: {
			flex: 1,
			position: 'absolute',
			width: '100%',
			height: '100%',
			alignContent: 'center',
			alignItems: 'center',
			justifyContent: 'center',
		},
		playIcon: {
			width: 50,

			...(variant === 'compact' && {
				width: 40,
			}),
		},
	});
}
