import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useButtonActive from '@mobe/utils/useButtonActive';
import * as React from 'react';
import { ActivityIndicator, Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';
import Text from '../text/Text';
import useButtonStyles, { ButtonSizes, ButtonVariants } from './buttonStyles';

export interface ButtonProps extends PressableProps {
	title: string;
	accessibilityLabel?: string;
	disabled?: boolean;
	loading?: boolean;
	variant?: ButtonVariants;
	size?: ButtonSizes;
	style?: StyleProp<ViewStyle>;
	iconLeft?: JSX.Element;
}

function Button(
	{
		title = '',
		disabled = false,
		loading = false,
		variant = 'primary',
		size = 'regular',
		accessibilityLabel,
		style = {},
		iconLeft,
		...pressableProps
	}: ButtonProps,
	ref: any
) {
	const { active, handlePressIn, handlePressOut } = useButtonActive(disabled);
	const styles = useButtonStyles({ variant, size, disabled, active });
	const rules = useStyleRules();

	return (
		<Pressable
			ref={ref}
			onPressIn={handlePressIn}
			onPressOut={handlePressOut}
			disabled={disabled || loading}
			style={[styles.button, style]}
			role="button"
			aria-label={accessibilityLabel || title}
			aria-disabled={disabled}
			aria-busy={loading}
			hitSlop={size === 'sm' ? 5 : 0}
			{...pressableProps}
		>
			{iconLeft ?? null}
			<Text style={styles.buttonText} numberOfLines={1}>
				{title}
			</Text>
			{loading && (
				<ActivityIndicator
					color={variant === 'primary' ? rules.colors.textInverted : rules.colors.primary}
					style={{ marginLeft: 10 }}
				/>
			)}
		</Pressable>
	);
}

export default React.forwardRef(Button);
