import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { ScrollView, ScrollViewProps, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AutoKeyboardAvoidingView from '../autoKeyboardAvoidingView/AutoKeyboardAvoidingView';

export interface IScreenTemplateWithFooterProps extends ScrollViewProps {
	children?: React.ReactNode;
	noPadding?: boolean;
	scrollViewBackgroundColor?: string;
}

export default function ScreenTemplate({
	children,
	noPadding = false,
	scrollViewBackgroundColor,
	...scrollViewProps
}: IScreenTemplateWithFooterProps) {
	const styles = useScreenTemplateStyles({ noPadding, scrollViewBackgroundColor });

	return (
		<AutoKeyboardAvoidingView bottomPadding={0}>
			<ScrollView
				overScrollMode="never"
				bounces={false}
				bouncesZoom={false}
				style={styles.scrollView}
				contentContainerStyle={styles.scrollViewContent}
				keyboardShouldPersistTaps="handled"
				{...scrollViewProps}
			>
				<View style={styles.body}>{children}</View>
			</ScrollView>
		</AutoKeyboardAvoidingView>
	);
}

export function useScreenTemplateStyles({
	noPadding = false,
	scrollViewBackgroundColor,
}: {
	noPadding?: boolean;
	scrollViewBackgroundColor?: string;
} = {}) {
	const rules = useStyleRules();
	const safeInsets = useSafeAreaInsets();

	return StyleSheet.create({
		scrollView: {
			backgroundColor: scrollViewBackgroundColor
				? scrollViewBackgroundColor
				: rules.colors.background,
		},
		scrollViewContent: {
			flexGrow: 1,
		},
		body: {
			backgroundColor: scrollViewBackgroundColor
				? scrollViewBackgroundColor
				: rules.colors.cardBackground,
			paddingTop: noPadding ? 0 : rules.spacing.appVerticalMargin,
			paddingBottom: noPadding
				? 0
				: Math.max(rules.spacing.appVerticalMargin, safeInsets.bottom + 12),
			paddingHorizontal: noPadding ? 0 : rules.spacing.appHorizontalMargin,
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			flexGrow: 1,
			alignSelf: 'center',
		},
	});
}
