import useMonitoredPromise from '@mobe/utils/useMonitoredPromise';
import authenticationService from './authenticationService';

export function useSendVerificationCode() {
	return useMonitoredPromise<typeof authenticationService.sendVerificationCode>(
		authenticationService.sendVerificationCode
	);
}

export function useVerifyCode() {
	return useMonitoredPromise<typeof authenticationService.verifyCode>(
		authenticationService.verifyCode
	);
}

export function useLogin() {
	return useMonitoredPromise<typeof authenticationService.login>(authenticationService.login);
}

export function useLogout() {
	return useMonitoredPromise<typeof authenticationService.logout>(authenticationService.logout);
}
