import CardButton from '@mobe/components/cardButton/CardButton';
import PhoneGraphic from '@mobe/components/graphics/PhoneGraphic';
import Icon from '@mobe/components/icon/Icon';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface IScheduleCallButtonProps {
	onPress: () => void;
}

export default function ScheduleCallButton({ onPress }: IScheduleCallButtonProps) {
	const { constrain } = useStyleHelpers();
	const { isLargeDisplay } = useLayout();
	const { t } = useTranslation();

	return (
		<CardButton
			cardBodyStyle={{
				...(!isLargeDisplay && { paddingTop: 10, paddingBottom: 8 }),
				minHeight: 'auto',
			}}
			cardButtonRight={<Icon name="right" size={15} />}
			cardButtonLeft={
				<View style={[constrain(48), { marginRight: 4 }]}>
					<PhoneGraphic />
				</View>
			}
			onPress={onPress}
		>
			<Text weight="medium">{t('home.scheduleCallCta')}</Text>
		</CardButton>
	);
}
