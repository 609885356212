import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

export interface IControlledCheckboxProps {
	checked: boolean;
	onPress: () => void;
	hasError: boolean;
}

export default function useControlledCheckboxProps<TFieldValues extends FieldValues = FieldValues>({
	name,
	control,
}: UseControllerProps<TFieldValues>) {
	const { field, fieldState } = useController({ name, control });
	const checked = Boolean(field.value);

	const checkboxProps: IControlledCheckboxProps = {
		checked,
		onPress: () => {
			field.onChange(!field.value);

			// Also fire blur to trigger onTouched
			field.onBlur();
		},
		hasError: Boolean(fieldState.error),
	};

	return checkboxProps;
}
