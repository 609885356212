import { useAlert } from '@mobe/utils/useAlert';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as AppointmentsAnalyticsEvents from './analyticsEvents';

interface IConfirmCancelAlert {
	apptDate?: Date;
	guideName: string;
	onConfirm: () => void;
}

export default function useConfirmCancelAlert() {
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();

	function confirmCancelAlert({ apptDate, guideName, onConfirm }: IConfirmCancelAlert) {
		mobeAlert(
			t('appointments.reschedule.cancelApptAlert.title'),
			t('appointments.reschedule.cancelApptAlert.description', {
				guideName,
				date: apptDate && format(apptDate, 'MMM d'),
				time: apptDate && format(apptDate, 'p'),
			}),
			[
				{
					text: t('appointments.reschedule.cancelApptAlert.deny'),
					style: 'cancel',
					onPress: noop,
				},
				{
					text: t('appointments.reschedule.cancelApptAlert.confirm'),
					style: 'destructive',
					onPress: () => {
						AppointmentsAnalyticsEvents.appointmentCancelConfirmation();
						onConfirm();
					},
				},
			]
		);
	}

	return confirmCancelAlert;
}
