import * as React from 'react';
import { View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import LandscapeDayGraphic from './LandscapeDayGraphic';
import LandscapeEveningGraphic from './LandscapeEveningGraphic';
import LandscapeMorningGraphic from './LandscapeMorningGraphic';

function HeroGraphic({ timeOfDay }: { timeOfDay: 'morning' | 'day' | 'evening' }) {
	const svgProps: SvgProps = {
		width: '100%',
		viewBox: `0 0 414 155`,
		style: {
			aspectRatio: 414 / 155,
		},
	};

	return (
		<View style={{ flexGrow: 1, width: '100%' }}>
			{timeOfDay === 'morning' && <LandscapeMorningGraphic {...svgProps} />}
			{timeOfDay === 'day' && <LandscapeDayGraphic {...svgProps} />}
			{timeOfDay === 'evening' && <LandscapeEveningGraphic {...svgProps} />}
		</View>
	);
}

export default HeroGraphic;
