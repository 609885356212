import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type HTMLViewProps = {
	html: string;
};

export default function HTMLView({ html }: HTMLViewProps) {
	const safeInsets = useSafeAreaInsets();
	const { wrapperHorizontal } = useStyleHelpers();

	return (
		<View
			style={[
				wrapperHorizontal,
				{ paddingTop: 16, paddingBottom: Math.max(safeInsets.bottom, 24) },
			]}
		>
			<div
				id="explore-content"
				className="mobe-content"
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</View>
	);
}
