import { Ionicons } from '@expo/vector-icons';
import { Text } from '@mobe/components/text';
import { useInternetConnection } from '@mobe/utils/internetConnectionContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function NoConnectionWarning() {
	const internetConnection = useInternetConnection();
	const safeAreaInsets = useSafeAreaInsets();
	const { t } = useTranslation();

	if (Platform.OS === 'web') {
		return null;
	}

	return internetConnection.isConnected === false ? (
		<View
			style={[
				styles.container,
				{
					top: Platform.select({
						ios: safeAreaInsets.top + 3,
						android: safeAreaInsets.top + 8,
					}),
				},
			]}
		>
			<Ionicons name="alert-circle-outline" color="white" size={18} style={{ marginRight: 5 }} />
			<Text size="sm" color="inverted" weight="bold">
				{t('connectivity.alertLabel')}
			</Text>
		</View>
	) : null;
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'rgba(255, 0, 0, 0.9)',
		flexDirection: 'row',
		alignSelf: 'center',
		position: 'absolute',
		zIndex: 1,
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 5,
	},
});
