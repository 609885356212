import * as React from 'react';

export default function Toggle({
	children,
}: {
	children: (on: boolean, setOn: (on: boolean) => void) => JSX.Element;
}) {
	const [on, setOn] = React.useState(false);

	return children(on, setOn);
}
