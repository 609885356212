import { ColorSchemeName, Platform, useColorScheme as useRNColorScheme } from 'react-native';
import { useAsyncStorageMutation, useAsyncStorageQuery } from './asyncStorage';

export const COLOR_SCHEME_PREFERENCE_KEY = 'colorSchemePreference';

export enum ColorSchemePreference {
	Light = 'light',
	Dark = 'dark',
	Auto = 'auto',
}

const INITIAL_DATA = ColorSchemePreference.Light;
const PLACEHOLDER_DATA = ColorSchemePreference.Auto;

// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
function useDeviceColorScheme(): NonNullable<ColorSchemeName> {
	return useRNColorScheme() as NonNullable<ColorSchemeName>;
}

function useColorSchemePreferenceQuery() {
	return useAsyncStorageQuery<`${ColorSchemePreference}`>(
		COLOR_SCHEME_PREFERENCE_KEY,
		INITIAL_DATA,
		PLACEHOLDER_DATA
	);
}

export function useColorSchemePreferenceMutation() {
	return useAsyncStorageMutation<`${ColorSchemePreference}`>(COLOR_SCHEME_PREFERENCE_KEY);
}

/**
 * Returns the user's color scheme preference of 'light', 'dark', or 'auto'.
 */
export function useColorSchemePreference() {
	const colorSchemePreferenceQuery = useColorSchemePreferenceQuery();

	return colorSchemePreferenceQuery.data || `${PLACEHOLDER_DATA}`;
}

/**
 * Returns 'light' or 'dark' based on the user's color scheme preference.
 * Returns the device color scheme if the user preference is set to 'auto'.
 */
export default function useColorScheme(): NonNullable<ColorSchemeName> {
	const deviceColorScheme = useDeviceColorScheme();
	const colorSchemePreference = useColorSchemePreference();

	if (Platform.OS === 'web') {
		return 'light';
	}

	return colorSchemePreference === 'auto' ? deviceColorScheme : colorSchemePreference;
}
