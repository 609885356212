import Compressor from 'compressorjs';
import * as ImagePicker from 'expo-image-picker';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import ImageResizer, { Response as ImageResizerResponse } from 'react-native-image-resizer';
import { useAlert } from './useAlert';
import useMonitoredPromise from './useMonitoredPromise';

export interface IMediaSelection {
	uri: string;
	file?: File;
	width?: number;
	height?: number;
	base64?: string | null | undefined;
	type?: 'image' | 'video';
	exif?: Record<string, any> | null | undefined;
}

type ResizeFormat = 'JPEG' | 'PNG';

const commonOptions: Partial<ImagePicker.ImagePickerOptions> = {
	base64: true,
	mediaTypes: ImagePicker.MediaTypeOptions.Images,
	quality: 1,
};

export default function useImageAcquisitionService() {
	const { t } = useTranslation();
	const { mobeAlert } = useAlert();

	const provideCameraOptedOutFeedback = () => {
		mobeAlert('', t('permissions.camera.permissionOptedOutFeedback'));
	};

	const provideMediaLibraryOptedOutFeedback = () => {
		mobeAlert('', t('permissions.mediaLibrary.permissionOptedOutFeedback'));
	};

	const resizeImage = async (
		/**
		 * Path of image file, or a base64 encoded image string prefixed
		 * with 'data:image/imagetype' where imagetype is jpeg or png.
		 */
		imageUri: string,
		/**
		 * Width to resize to
		 */
		maxWidth: number,
		/**
		 * Height to resize to
		 */
		maxHeight: number = maxWidth * 2,
		/**
		 * Can be either JPEG, PNG
		 */
		format: ResizeFormat = 'JPEG',
		/**
		 * A number between 0 and 100. Used for the JPEG compression.
		 */
		quality = 80
	) => {
		return await ImageResizer.createResizedImage(imageUri, maxWidth, maxHeight, format, quality);
	};

	/**
	 * Native only image requisition from camera
	 * @param options Options for the native image picker
	 * @param resizeOptions Post requisition image resizing options
	 */
	const getImageFromCamera = async (resizeOptions?: {
		maxWidth: number;
		maxHeight?: number;
		cropToSquare?: boolean;
		format?: ResizeFormat;
		quality?: number;
	}): Promise<IMediaSelection | undefined> => {
		try {
			// ios needs a specific request call to work; simply attempting to launch the camera doesn't prompt for permission
			if (Platform.OS === 'ios') {
				await ImagePicker.requestCameraPermissionsAsync();
			}

			const result = await ImagePicker.launchCameraAsync({
				...commonOptions,
				aspect: resizeOptions?.cropToSquare ? [1, 1] : undefined,
				allowsEditing: resizeOptions?.cropToSquare ?? false,
			});

			if (result.canceled) {
				return;
			}

			let resizedImage: ImageResizerResponse | undefined;
			const asset = result.assets[0];

			if (resizeOptions && resizeOptions.maxWidth) {
				resizedImage = await resizeImage(
					asset.uri,
					resizeOptions.maxWidth,
					resizeOptions.maxHeight,
					resizeOptions.format,
					resizeOptions.quality
				);
			}

			return {
				// Selectively picking props we want to match custom interface for easier overriding
				uri: asset.uri,
				width: asset.width,
				height: asset.height,
				base64: asset.base64,
				type: asset.type,
				exif: asset.exif,

				...resizedImage,
			};
		} catch (error) {
			provideCameraOptedOutFeedback();
		}
	};

	async function getResizedImageFromLibraryOnWeb(
		maxWidth: number,
		maxHeight = maxWidth * 2,
		targetQuality?: number,
		cropToSquare?: boolean,
		event?: React.ChangeEvent<HTMLInputElement>
	) {
		const defaultQuality = 0.8;
		const quality = targetQuality ? targetQuality / 100 : defaultQuality;
		const file = event?.target.files ? event.target.files[0] : undefined;

		if (file) {
			return new Promise<IMediaSelection>((resolve, reject) => {
				new Compressor(file, {
					quality,
					maxHeight,
					maxWidth,
					...(cropToSquare
						? {
								width: maxWidth,
								height: maxWidth,
								resize: 'cover',
						  }
						: {}),
					convertTypes: ['image/png', 'image/webp'],
					success: (compressedFile: File) => {
						resolve({ file: compressedFile, uri: URL.createObjectURL(compressedFile) });
					},
					error: reject,
				});
			});
		}

		return { file: undefined, uri: '' };
	}

	const getResizedImageFromLibraryOnMobile = async (resizeOptions: {
		maxWidth: number;
		maxHeight?: number;
		cropToSquare?: boolean;
		format?: ResizeFormat;
		quality?: number;
	}): Promise<IMediaSelection | undefined> => {
		try {
			const result = await ImagePicker.launchImageLibraryAsync({
				...commonOptions,
				aspect: resizeOptions?.cropToSquare ? [1, 1] : undefined,
				allowsEditing: resizeOptions?.cropToSquare ?? false,
			});

			if (result.canceled) {
				return;
			}

			let resizedImage: ImageResizerResponse | undefined;
			const asset = result.assets[0];

			if (resizeOptions && resizeOptions.maxWidth) {
				resizedImage = await resizeImage(
					asset.uri,
					resizeOptions.maxWidth,
					resizeOptions.maxHeight,
					resizeOptions.format,
					resizeOptions.quality
				);
			}

			return {
				// Selectively picking props we want to match custom interface for easier overriding
				uri: asset.uri,
				width: asset.width,
				height: asset.height,
				base64: asset.base64,
				type: asset.type,
				exif: asset.exif,

				...resizedImage,
			};
		} catch (error) {
			provideMediaLibraryOptedOutFeedback();
		}
	};

	/**
	 * @param resizeOptions Applicable to native and web
	 * @param options Options for the native image picker
	 * @param event onChange event for the web fileInput
	 */
	const getImageFromLibrary = async (
		resizeOptions: {
			maxWidth: number;
			maxHeight?: number;
			format?: ResizeFormat;
			quality?: number;
			cropToSquare?: boolean;
		},
		event?: React.ChangeEvent<HTMLInputElement> | undefined
	) =>
		Platform.OS === 'web'
			? await getResizedImageFromLibraryOnWeb(
					resizeOptions.maxWidth,
					resizeOptions.maxHeight,
					resizeOptions.quality,
					resizeOptions.cropToSquare,
					event
			  )
			: await getResizedImageFromLibraryOnMobile(resizeOptions);

	return {
		getImageFromCamera: useMonitoredPromise<typeof getImageFromCamera>(getImageFromCamera),
		getImageFromLibrary: useMonitoredPromise<typeof getImageFromLibrary>(getImageFromLibrary),
	};
}
