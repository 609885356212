import { useAppFeedback } from '@mobe/components/appFeedbackPopup/AppFeedbackProvider';
import useActivityToast from '@mobe/components/toast/useActivityToast';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { format } from 'date-fns';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRefreshAllActivities } from '../activities/activitiesApiHooks';
import { ActivitiesQueryKeys } from '../activities/activitiesQueryKeys';
import { IGetActivitiesResponse } from '../activities/activitiesService';
import { useAuth } from '../authentication/AuthContext';
import {
	IPaginationRequest,
	metadataToPagination,
	paginationToMetadataRequest,
} from '../paginationUtils';
import { useGetTrackerAbbreviationFromId } from './trackApiUtils';
import {
	IFavoriteTrackerResponse,
	IMarketplaceTracker,
	ISetTrackerFavoriteStatus,
	ISetTrackerGoal,
	ISetTrackerTypeSource,
	ITrackerDetailsResponse,
	ITrackerItem,
	ITrackerType,
	ITrackerTypeSource,
	IUpdateTrackerData,
	TrackerAbbreviation,
	TrackerAggregatedEntriesInterval,
	addTrackerData,
	deleteTrackerData,
	getAllTrackerTypes,
	getTrackerAggregatedEntries,
	getTrackerDetails,
	getTrackerEntries,
	getTrackerTypeSources,
	getValidicUserPlatforms,
	setTrackerFavoriteStatus,
	setTrackerGoal,
	setTrackerTypeSource,
	updateTrackerData,
} from './trackService';
import { TrackQueryKeys } from './types';

export interface ITrackerDetails extends ITrackerDetailsResponse {
	trackerAbbreviation: TrackerAbbreviation;
}

export function useTrackersQuery() {
	const auth = useAuth();

	return useQuery<ITrackerType[], Error>(TrackQueryKeys.AllTrackers, getAllTrackerTypes, {
		enabled: auth.isAuthenticated,
		staleTime: 1000 * 30,
		// Filter out any unaccounted for tracker types for backwards compatibility with API
		select: (data) =>
			data.filter((tracker) =>
				Object.values(TrackerAbbreviation).includes(tracker.trackerAbbreviation)
			),
	});
}

interface IUseTrackerEntriesQuery extends IPaginationRequest {
	trackerTypeId: number;
	startDate: Date;
	endDate: Date;
}

export function useTrackerEntriesQuery({
	trackerTypeId,
	startDate,
	endDate,
	limit = 12,
	page = 1,
}: IUseTrackerEntriesQuery) {
	const auth = useAuth();
	const startDateString = startDate.toISOString();
	const endDateString = endDate.toISOString();

	return useQuery(
		[TrackQueryKeys.TrackerEntries, trackerTypeId, startDateString, endDateString, limit, page],
		async () =>
			await getTrackerEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				...paginationToMetadataRequest({ limit, page }),
			}),
		{
			enabled: auth.isAuthenticated,
			keepPreviousData: true,
			select: (data) => ({
				pagination: metadataToPagination(data.metadata),
				data: data.data,
			}),
		}
	);
}

export function useTrackerEntriesInfiniteQuery({
	trackerTypeId,
	startDate,
	endDate,
	limit = 12,
	page = 1,
}: IUseTrackerEntriesQuery) {
	const auth = useAuth();
	const startDateString = format(startDate, 'yyyy-MM-dd');
	const endDateString = format(endDate, 'yyyy-MM-dd');

	return useInfiniteQuery(
		[TrackQueryKeys.TrackerEntries, trackerTypeId, startDateString, endDateString, limit, page],
		async ({ pageParam = page }) =>
			await getTrackerEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				...paginationToMetadataRequest({ limit, page: pageParam }),
			}),
		{
			staleTime: 1000 * 30,
			keepPreviousData: true,
			enabled: auth.isAuthenticated,
			getNextPageParam: (lastPage) => {
				const pagination = metadataToPagination(lastPage.metadata);

				if (pagination.page * pagination.limit < pagination.total) {
					return pagination.page + 1;
				}
			},
		}
	);
}

interface IUseTrackerAggregatedEntriesQuery {
	trackerTypeId: number;
	startDate: Date;
	endDate: Date;
	interval: TrackerAggregatedEntriesInterval;
	enabled?: boolean;
}

export function useTrackerAggregatedEntriesQuery({
	trackerTypeId,
	startDate,
	endDate,
	interval,
	enabled = true,
}: IUseTrackerAggregatedEntriesQuery) {
	const auth = useAuth();
	const startDateString = format(startDate, 'yyyy-MM-dd');
	const endDateString = format(endDate, 'yyyy-MM-dd');

	return useQuery(
		[TrackQueryKeys.TrackerAggregatedEntries, trackerTypeId, startDateString, endDateString],
		async () =>
			await getTrackerAggregatedEntries(trackerTypeId, {
				startDate: startDateString,
				endDate: endDateString,
				interval,
			}),
		{
			staleTime: 1000 * 30,
			enabled: auth.isAuthenticated && enabled,
			keepPreviousData: true,
		}
	);
}

export function useTrackersDetailQuery(trackerTypeId: number) {
	return useQuery<ITrackerDetailsResponse, Error, ITrackerDetails>(
		[TrackQueryKeys.TrackerDetail, trackerTypeId],
		async () => {
			const response = await getTrackerDetails(trackerTypeId);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			keepPreviousData: true,
		}
	);
}

export function useTrackersFavoritesMutation() {
	const queryClient = useQueryClient();

	return useMutation<IFavoriteTrackerResponse, Error, ISetTrackerFavoriteStatus>(
		async (params: ISetTrackerFavoriteStatus) => {
			const response = await setTrackerFavoriteStatus(params);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			// Optimistically update isFavorited so that UI responds immediately rather than waiting for success
			onMutate: async ({ trackerTypeId, isFavorite }) => {
				await queryClient.cancelQueries(TrackQueryKeys.AllTrackers);

				const previousTrackers = queryClient.getQueryData<ITrackerType[]>(
					TrackQueryKeys.AllTrackers
				);

				if (previousTrackers) {
					const optimisticUpdate = [...previousTrackers];
					queryClient.setQueryData<ITrackerType[]>(TrackQueryKeys.AllTrackers, () => {
						optimisticUpdate.forEach((tracker, i) => {
							if (tracker.trackerTypeId === trackerTypeId) {
								optimisticUpdate[i].isFavorite = isFavorite;
							}
						});
						return optimisticUpdate;
					});
				}

				return previousTrackers;
			},
			onSettled: () => {
				queryClient.invalidateQueries(TrackQueryKeys.AllTrackers);
			},
		}
	);
}

export function useTrackersAddEntryMutation() {
	const getTrackerAbbreviationFromId = useGetTrackerAbbreviationFromId();
	const queryClient = useQueryClient();
	const refreshAllActivities = useRefreshAllActivities();
	const activityToast = useActivityToast();
	const appFeedback = useAppFeedback();

	return useMutation(
		async ({ trackerEntry }: { trackerEntry: ITrackerItem }) => {
			const response = await addTrackerData(trackerEntry);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: (data, args) => {
				queryClient.invalidateQueries(TrackQueryKeys.AllTrackers);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerDetail,
					args.trackerEntry.trackerTypeId,
				]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerEntries,
					args.trackerEntry.trackerTypeId,
				]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerAggregatedEntries,
					args.trackerEntry.trackerTypeId,
				]);

				// Invalidate activities query if the tracker type matches an uncompleted activity
				const trackerAbbreviation = getTrackerAbbreviationFromId(args.trackerEntry.trackerTypeId);
				const newActivities = queryClient.getQueryData<IGetActivitiesResponse>(
					ActivitiesQueryKeys.AllActivities
				)?.active;
				if (newActivities?.find((activity) => activity.activityTargetUrl === trackerAbbreviation)) {
					refreshAllActivities();
					activityToast();
					appFeedback.show();
				}
			},
		}
	);
}

export function useTrackersUpdateEntryMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, IUpdateTrackerData>(
		async (params: IUpdateTrackerData) => {
			const response = await updateTrackerData(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: (data, args) => {
				queryClient.invalidateQueries(TrackQueryKeys.AllTrackers);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerDetail,
					args.trackerEntry.trackerTypeId,
				]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerEntries,
					args.trackerEntry.trackerTypeId,
				]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerAggregatedEntries,
					args.trackerEntry.trackerTypeId,
				]);
			},
		}
	);
}

export function useTrackersDeleteEntryMutation() {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ consumerTrackerId }: { consumerTrackerId: number }) => {
			const response = await deleteTrackerData(consumerTrackerId);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(TrackQueryKeys.AllTrackers);
				queryClient.invalidateQueries(TrackQueryKeys.TrackerDetail);
				queryClient.invalidateQueries(TrackQueryKeys.TrackerEntries);
				queryClient.invalidateQueries(TrackQueryKeys.TrackerAggregatedEntries);
			},
		}
	);
}

export function useTrackersGoalMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, ISetTrackerGoal>(
		async (params: ISetTrackerGoal) => {
			const response = await setTrackerGoal(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: (data, args) => {
				queryClient.invalidateQueries([TrackQueryKeys.TrackerDetail, args.trackerTypeId]);
				queryClient.invalidateQueries([TrackQueryKeys.TrackerEntries, args.trackerTypeId]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerAggregatedEntries,
					args.trackerTypeId,
				]);
			},
		}
	);
}

export function useValidicUserPlatformsQuery() {
	const auth = useAuth();
	const remoteConfigData = useRemoteConfigData();
	const omittedSources = remoteConfigData.featureIntegratedTrackers.omittedSources || [];

	return useQuery<IMarketplaceTracker[], Error>(
		TrackQueryKeys.ValidicPlatforms,
		async () => {
			const response = await getValidicUserPlatforms();

			if (!response.success) {
				throw response.error;
			}

			return response.data.filter(
				(platform) => !omittedSources.includes(platform.trackerSourceName)
			);
		},
		{
			enabled: auth.isAuthenticated,
			// Re-fetching is handled explicitly when connection status changes
			staleTime: Infinity,
		}
	);
}

export function useTrackerTypeSourcesQuery(trackerTypeId: number) {
	return useQuery<ITrackerTypeSource[], Error>(
		[TrackQueryKeys.TrackerSources, trackerTypeId],
		async () => {
			const response = await getTrackerTypeSources(trackerTypeId);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		}
	);
}

export function useSetTrackerTypeSourceMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, ISetTrackerTypeSource>(
		async (params: ISetTrackerTypeSource) => {
			const response = await setTrackerTypeSource(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: (data, args) => {
				queryClient.invalidateQueries([TrackQueryKeys.TrackerSources, args.trackerTypeId]);
				queryClient.invalidateQueries([TrackQueryKeys.TrackerDetail, args.trackerTypeId]);
				queryClient.invalidateQueries([TrackQueryKeys.TrackerEntries, args.trackerTypeId]);
				queryClient.invalidateQueries([
					TrackQueryKeys.TrackerAggregatedEntries,
					args.trackerTypeId,
				]);
			},
		}
	);
}
