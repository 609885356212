import Heading from '@mobe/components/heading/Heading';
import MobePdf from '@mobe/components/mobePdf/MobePdf';
import ShareButton from '@mobe/components/shareButton/ShareButton';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import {
	FileViewerScreenNavigationProp,
	FileViewerScreenRouteProp,
} from '../navigation/modal/types';
import * as FileViewerAnalyticsEvents from './analyticsEvents';

export interface IFileViewerScreenParams {
	uri: string;
	isSharable: boolean;
	title: string;
}

export interface IFileViewerScreenProps {
	navigation: FileViewerScreenNavigationProp;
	route: FileViewerScreenRouteProp;
}

export default function FileViewerScreen({ route, navigation }: IFileViewerScreenProps) {
	const { uri, isSharable, title } = route.params;
	const { t } = useTranslation();
	const [localFileUrl, setLocalFileUrl] = React.useState<string>('');
	const containerStyle =
		Platform.OS === 'web'
			? [fileViewerScreenStyles.pdfWrapper, fileViewerScreenStyles.container]
			: fileViewerScreenStyles.container;

	React.useEffect(() => {
		if (!localFileUrl) {
			return;
		}

		navigation.setOptions({
			headerRight: () =>
				isSharable ? (
					<ShareButton
						url={localFileUrl}
						showAlert={true}
						continueLabel={t('fileViewer.continueLabel')}
						alertTitle={t('fileViewer.shareNoticeTitle')}
						alertBody={t('fileViewer.shareNoticeBody')}
						onSharePress={() => FileViewerAnalyticsEvents.fileSharePress(uri, title)}
					/>
				) : null,
		});
	}, [localFileUrl]);

	return (
		<View style={containerStyle}>
			<MobePdf
				title={title}
				url={uri}
				style={fileViewerScreenStyles.pdfWrapper}
				cache={isSharable}
				onLoadComplete={(numberOfPages, cacheFilePath) => {
					if (!isSharable) {
						return;
					}
					setLocalFileUrl(`file://${cacheFilePath}`);
				}}
			/>
		</View>
	);
}

export function useFileViewerScreenOptions() {
	const modalStyleProps = useModalStyleProps();

	return ({ route }: IFileViewerScreenProps): StackNavigationOptions => {
		const { title } = route.params;

		return {
			...modalStyleProps,
			title,
			headerTitle: () => (
				<Heading style={{ fontSize: 18 }} align="center">
					{title}
				</Heading>
			),
		};
	};
}

const fileViewerScreenStyles = StyleSheet.create({
	pdfWrapper: { width: '100%', height: '100%' },
	container: { flex: 1, backgroundColor: 'white' },
});
