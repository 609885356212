import { useDeleteAccountMutation } from '@mobe/api/account/accountApiHooks';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import BulletListItem from '@mobe/components/bulletListItem/BulletListItem';
import Button from '@mobe/components/button/Button';
import { WarningGraphic } from '@mobe/components/graphics';
import IconButton from '@mobe/components/iconButton/IconButton';
import VrArray from '@mobe/components/layout/VrArray';
import LoadingOverlay from '@mobe/components/loadingOverlay/LoadingOverlay';
import Popup from '@mobe/components/popup/Popup';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import linking from '@mobe/utils/linking';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	SettingsAccountScreenNavigationProp,
	SettingsAccountScreenRouteProp,
} from '../SettingsStackScreen';
import * as SettingsAnalyticsEvents from '../analyticsEvents';
import DeleteAccountGraphic from '../components/DeleteAccountGraphic';

export interface ISettingsAccountScreenProps {
	navigation: SettingsAccountScreenNavigationProp;
	route: SettingsAccountScreenRouteProp;
}

export default function SettingsAccountScreen({ navigation, route }: ISettingsAccountScreenProps) {
	const styleRules = useStyleRules();
	const { vr, constrain, constrainText } = useStyleHelpers();
	const auth = useAuth();
	const persistentState = usePersistentState();
	const { mobeAlert } = useAlert();
	const genericErrorAlert = useGenericErrorAlert();
	const deleteAccountMutation = useDeleteAccountMutation();
	const remoteConfigData = useRemoteConfigData();
	const [viewState, setViewState] = React.useState<'idle' | 'prompt'>('idle');
	const { t } = useTranslation();

	const styles = StyleSheet.create({
		warningGraphic: {
			width: 114,
			height: 114,
			borderRadius: 57,
			backgroundColor: styleRules.colors.background,
			alignSelf: 'center',
			alignItems: 'center',
			marginBottom: 20,
			padding: 18,
		},
		dismiss: {
			position: 'absolute',
			top: 5,
			left: 5,
			alignItems: 'center',
			justifyContent: 'center',
			height: 24,
			width: 24,
		},
	});

	function handleCancelPress() {
		if (viewState === 'prompt') {
			setViewState('idle');
		}
	}

	async function handleDeleteAccount() {
		deleteAccountMutation.mutate(undefined, {
			onSuccess: () => {
				setViewState('idle');
				persistentState.resetSavedLoginCredentials();

				// Prompt for feedback
				mobeAlert(
					t('settings.account.feedbackPrompt.heading'),
					t('settings.account.feedbackPrompt.body'),
					[
						{
							text: t('settings.account.feedbackPrompt.confirmButton'),
							isPreferred: true,
							onPress: () => {
								if (remoteConfigData.appSurveyLink.url) {
									SettingsAnalyticsEvents.deleteAccountFeedbackConfirm();
									linking.openUrl(remoteConfigData.appSurveyLink.url);
								}

								auth.logout();
							},
						},
						{
							text: t('settings.account.feedbackPrompt.cancelButton'),
							onPress: () => {
								SettingsAnalyticsEvents.deleteAccountFeedbackCancel();
								auth.logout();
							},
						},
					]
				);
			},
			onError: () => {
				genericErrorAlert();
			},
		});
	}

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			centered
			footer={
				<Button title={t('settings.account.deleteButton')} onPress={() => setViewState('prompt')} />
			}
		>
			<View style={[vr(8), constrain(200)]}>
				<DeleteAccountGraphic />
			</View>
			<View style={constrainText(350)}>
				<Text accessibilityAutoFocus color="light" style={vr(2)}>
					{t('settings.account.descriptionTop')}
				</Text>
				{t('settings.account.descriptionBullets', { returnObjects: true }).map((text, i) => (
					<BulletListItem key={i} style={vr(2)}>
						<Text color="light">{text}</Text>
					</BulletListItem>
				))}
			</View>

			{viewState === 'prompt' ? (
				<Popup
					showHeader={false}
					onClosePress={handleCancelPress}
					footer={
						<>
							<VrArray>
								<Button
									title={t('settings.account.confirmation.confirmButton')}
									onPress={handleDeleteAccount}
								/>
								<Button
									variant="secondary"
									title={t('settings.account.confirmation.cancelButton')}
									onPress={handleCancelPress}
								/>
							</VrArray>
						</>
					}
				>
					<LoadingOverlay visible={deleteAccountMutation.isLoading} />

					<View
						accessibilityElementsHidden
						importantForAccessibility="no-hide-descendants"
						style={styles.warningGraphic}
					>
						<WarningGraphic />
					</View>
					<Text align="center" size="lg" weight="bold" style={[constrainText(250), vr(3)]}>
						{t('settings.account.confirmation.heading')}
					</Text>
					<IconButton
						name="close"
						aria-label={t('accessibility.closeButtonLabel')}
						color="text"
						size={10}
						hitSlop={19}
						onPress={handleCancelPress}
						style={styles.dismiss}
					/>
				</Popup>
			) : null}
		</ScreenTemplateWithFooter>
	);
}

export function useSettingsAccountScreenOptions() {
	const { t } = useTranslation();

	return ({ navigation }: ISettingsAccountScreenProps): StackNavigationOptions => ({
		title: t('settings.account.screenTitle'),
		headerRight: undefined,
	});
}
