import env from '@mobe/env/env';
import { buildCustomEventName, IAnalyticsModule } from './analyticsUtils';
import { analytics as WebFirebaseAnalytics } from './firebase.web';

const analytics: IAnalyticsModule = {
	logEvent: async (eventName, eventParams = {}) => {
		if (env.ENABLE_ANALYTICS_DEBUG) {
			console.info('Analytics event: ', buildCustomEventName(eventName), eventParams);
			return Promise.resolve();
		}

		WebFirebaseAnalytics.logEvent(buildCustomEventName(eventName), eventParams);
		return Promise.resolve();
	},

	logScreenView: async (screenName) => {
		if (!screenName) {
			return;
		}

		if (env.ENABLE_ANALYTICS_DEBUG) {
			console.info('Analytics screen view: ', screenName);
			return Promise.resolve();
		}

		WebFirebaseAnalytics.setCurrentScreen(screenName);
		return Promise.resolve();
	},

	logLogin: async ({ method = 'app' }) => {
		if (env.ENABLE_ANALYTICS_DEBUG) {
			console.info('Analytics event: ', 'login', { method });
			return Promise.resolve();
		}

		WebFirebaseAnalytics.logEvent('login', { method });
		return Promise.resolve();
	},
};

export default analytics;
