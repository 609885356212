import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#13375B"
					d="M46.215 2.891a5.32 5.32 0 00-6.269 3.035 5.523 5.523 0 00-4.048 1.556c-2.062 2.046-2.516 6.374.02 7.989 1.06 2.071 2.4 3.575 4.591 3.026 1.917 2.032 6.12 1.663 7.857-1.176 2.2.106 4.062-1.628 5.045-3.612.232-.468.355-1.141.461-1.254 0 0 4.979-2.345 3.166-7.287-1.898-5.177-8.376-7.377-10.823-2.277z"
				/>
				<Path
					fill="#F8A492"
					d="M30.691 70.901c.088 1.601.301 3.192.638 4.76.031.121.42.6.456.727a7.722 7.722 0 01-4.284 5.224c.527-1.379 1.511-2.91 1.45-3.24a42.308 42.308 0 00-1.414-5.99c1.366.019 1.951-.773 3.154-1.481z"
				/>
				<Path
					fill="#355389"
					d="M37.349 39.071l8.357 4.546s-4.239 4.179-5.828 5.732c-1.589 1.553-9.589 7.15-9.73 7.508.229.647.405 1.313.523 1.989.351 2.874.747 8.464.865 13.257-.905.949-2.865 1.174-4.037 1.472-2.882-5.89-3.924-17.2-3.91-18.553.022-2.212 13.76-15.951 13.76-15.951z"
				/>
				<Path
					fill="#F8A492"
					d="M61.83 51.623a40.215 40.215 0 00-4.029 5.234 15.838 15.838 0 00-2.311-2.529 29.769 29.769 0 003.9-4.653c.729-1.731 1.18-1.9 1.18-1.9s5.36 3.43 5.622 4.1c.262.67-3.035-1.101-4.362-.252z"
				/>
				<Path
					fill="#184655"
					d="M59.231 50.06c-.451-.105.5-2.118.712-2.584.236-.524.578-.617.658-.619.218 0 6.175 3.807 7.086 4.885.07.1.126.209.168.324.344 1.306-5.025.267-5.68-.424a4.453 4.453 0 00-2.944-1.582z"
				/>
				<Path
					fill="#466EB6"
					d="M37.349 39.07l6.119-3.687c-.03.013.038.316.047.339 1.19 2.714 3.986 2.632 5.286 7.035 1.028 3.481.455 15.136.455 15.136s3.465-2.526 7.1-5.221c.331-.246 2.935 2.65 2.935 2.65-4.951 8.3-12.608 11.492-13.578 11.109-3.31-1.308-8.228-23.562-8.219-23.56-.635-1.333-.145-3.801-.145-3.801z"
				/>
				<Path
					fill="#F8A492"
					d="M43.127 20.162s6.754-3.819 8.357-5.089 6.719-9.18 6.9-9.4-.735-3.962-.259-3.916.372 1.933.912 1.889S62.37-.068 62.567.229c.369.556-1.621 2.315-1.4 2.544s1.9-2.034 2.4-1.779-1.8 1.987-1.718 2.236 2.386-1.333 2.516-.782c.071.3-2.088 1.355-1.831 1.612s2.042-.291 2.091.06-1.565.256-2.282.911-1.587.747-2.412 2.135-4.827 9.3-7.349 11.452a44.585 44.585 0 01-6.4 4.25l-3.055-2.706zM38.872 24.362s-7.409-5.6-9.111-6.735-7.084-6.25-7.287-6.455.407-4.009-.063-3.924-.212 1.957-.754 1.957-3.626-3.428-3.8-3.116c-.322.585 1.805 2.174 1.605 2.421s-2.061-1.871-2.537-1.577 1.953 1.833 1.9 2.088-2.487-1.132-2.571-.572c-.046.3 2.192 1.18 1.957 1.456s-2.059-.122-2.079.232 1.581.126 2.35.721 1.643.614 2.579 1.929 3.68 6.354 6.37 8.29 8.621 6.23 8.621 6.23l2.82-2.945z"
				/>
				<Path
					fill="#F47458"
					d="M35.901 33.828c-.387-1.558-1.8-5.5-1.968-6.008-1.133-1.3-1.53-2.4-2.15-2.881 0 0 .905-3.436 2.45-4.145 0 0 1.589.084 3.115.169.016-.013-.18.087 0 0a37.311 37.311 0 015.654-1.967l2.342 6.112c-.04.483 3.92 9.308 3.028 15.17-.129.845-9.763-.374-11.2-.158-1.9.284-.504-3.205-1.271-6.292z"
				/>
				<Path
					fill="#F8A492"
					d="M40.309 15.71a20.41 20.41 0 00.506 3.521c.338.947.693 4.184-1.27 4.693-1.963.509-1.381-3.88-1.437-4.877a39.268 39.268 0 00-.758-3.951l2.959.614z"
				/>
				<Path
					fill="#F47458"
					d="M37.682 16.583a72.869 72.869 0 00-.333-1.487l2.96.614s.034.527.1 1.191a2.327 2.327 0 01-2.727-.318z"
				/>
				<Path
					fill="#F8A492"
					d="M39.551 8.679a3.487 3.487 0 011.859 1.513c.695 1.181 1.107 4.58-1.313 6.04s-4.219-.859-4.2-3.033.548-5.054 3.654-4.52z"
				/>
				<Path
					fill="#F8A492"
					d="M35.373 12.492c.19.534.585.879.883.771s.383-.63.194-1.164-.586-.879-.883-.771-.384.627-.194 1.164zM41.373 13.215c-.187.556-.042 1.12.323 1.26s.812-.2 1-.756c.188-.556.042-1.12-.322-1.26s-.814.199-1.001.756z"
				/>
				<Path
					fill="#F47458"
					d="M40.966 20.089a3.646 3.646 0 011.146-.795c1.84-.914 4.926-2.477 4.926-2.477s2.652 3.61 2.49 4.858c0 0-4.52 3.08-4.183 3.432l-4.379-5.018z"
				/>
				<Path
					fill="#184655"
					d="M31.34 75.731c.224-.422 1.76 1.226 2.129 1.567.26.195.404.509.382.834-.056.214-5.1 5.235-6.472 5.838a.706.706 0 01-.454-.015c-.672-.38 1.046-4.811 2.087-5.592a5.831 5.831 0 002.328-2.632z"
				/>
				<Path
					fill="#13375B"
					d="M43.127 9.011s.454 3.793 1.619 4.488.658 2.37.658 2.37 1.1-3.892.187-5.154a5.121 5.121 0 00-2.464-1.704z"
				/>
			</Svg>
		</View>
	);
}

export default SvgComponent;
