import { useValidicUserPlatformsQuery } from '@mobe/api/track/trackApiHooks';
import { TrackerSource } from '@mobe/api/track/trackService';
import useNativeTrackers from './useNativeTrackers';

export default function useSelectedDevice(trackerSourceName: TrackerSource) {
	const nativeTrackers = useNativeTrackers();
	const validicUserPlatforms = useValidicUserPlatformsQuery().data || [];

	return {
		selectedNativeTracker: nativeTrackers.find(
			(tracker) => tracker.trackerSourceName === trackerSourceName
		),
		selectedMarketplaceTracker: validicUserPlatforms.find(
			(tracker) => tracker.trackerSourceName === trackerSourceName
		),
		selectedDevice:
			nativeTrackers.find((tracker) => tracker.trackerSourceName === trackerSourceName) ||
			validicUserPlatforms.find((tracker) => tracker.trackerSourceName === trackerSourceName),
	};
}
