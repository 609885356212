import { useGoalsQuery } from '@mobe/api/goals/goalsApiHooks';
import { Button } from '@mobe/components/button';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Heading from '@mobe/components/heading/Heading';
import Blocks from '@mobe/components/layout/Blocks';
import ProgressCard from '@mobe/components/progressCard/ProgressCard';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { useAccessibilityFocus, useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	GoalHistoryScreenNavigationProp,
	GoalHistoryScreenRouteProp,
} from '../../navigation/modal/types';
import GoalCard from '../components/GoalCard';

const GOAL_LIMIT = 12;

interface IGoalHistoryScreenProps {
	navigation: GoalHistoryScreenNavigationProp;
	route: GoalHistoryScreenRouteProp;
}

export default function GoalHistoryScreen({ navigation }: IGoalHistoryScreenProps) {
	const { vr, vrTop } = useStyleHelpers();
	const styleRules = useStyleRules();
	const { isLargeDisplay } = useLayout();
	const { t } = useTranslation();

	const [LTGoalLimit, setLTGoalLimit] = React.useState<number>(GOAL_LIMIT);
	const [STGoalLimit, setSTGoalLimit] = React.useState<number>(GOAL_LIMIT);
	const LTGoalsQuery = useGoalsQuery({ status: 'Completed', type: 'LongTerm', limit: LTGoalLimit });
	const STGoalsQuery = useGoalsQuery({
		status: 'Completed',
		type: 'ShortTerm',
		limit: STGoalLimit,
	});

	const [refLT, focusRefLT] = useAccessibilityFocus();
	const [refST, focusRefST] = useAccessibilityFocus();

	const { storeFocusTarget } = useRestoreFocus();

	return (
		<ScreenTemplate scrollViewBackgroundColor={styleRules.colors.background}>
			<DeferredLoadingIndicator isLoading={LTGoalsQuery.isLoading || STGoalsQuery.isLoading}>
				{LTGoalsQuery.isSuccess && STGoalsQuery.isSuccess ? (
					<>
						<Blocks style={vr(10)}>
							<ProgressCard
								count={STGoalsQuery.data.pagination.total}
								title={t('plan.goalHistory.shortTermTotalCompleted')}
							/>
							<ProgressCard
								count={LTGoalsQuery.data.pagination.total}
								title={t('plan.goalHistory.longTermTotalCompleted')}
							/>
						</Blocks>
						<Heading level="h4" style={vr(4)}>
							{t('plan.goalHistory.longTermHeading')}
						</Heading>
						<View style={vr(10)}>
							<Blocks blocksPerRow={isLargeDisplay ? 3 : 2}>
								{LTGoalsQuery.data.goals.map((goal, index) => (
									<View key={goal.id} style={{ flexGrow: 1 }}>
										<GoalCard
											goalData={goal}
											onPress={(event) => {
												storeFocusTarget(event);
												navigation.navigate('GOAL_DETAIL_SCREEN', { goalId: goal.id });
											}}
										/>
										{index === GOAL_LIMIT - 1 && <View ref={refLT} accessible focusable />}
									</View>
								))}
							</Blocks>
							{LTGoalsQuery.data.pagination.total > LTGoalsQuery.data.goals.length && (
								<Button
									title={t('plan.viewAllButton')}
									variant="secondary"
									style={[vrTop(6), { alignSelf: 'center' }]}
									onPress={() => {
										focusRefLT();
										setLTGoalLimit(LTGoalsQuery.data.pagination.total);
									}}
									size="sm"
								/>
							)}
						</View>
						<Heading level="h4" style={vr(4)}>
							{t('plan.goalHistory.shortTermHeading')}
						</Heading>
						<Blocks blocksPerRow={isLargeDisplay ? 3 : 2}>
							{STGoalsQuery.data.goals.map((goal, index) => (
								<View key={goal.id} style={{ flexGrow: 1 }}>
									<GoalCard
										goalData={goal}
										onPress={(event) => {
											storeFocusTarget(event);
											navigation.navigate('GOAL_DETAIL_SCREEN', { goalId: goal.id });
										}}
									/>
									{index === GOAL_LIMIT - 1 && <View ref={refST} accessible focusable />}
								</View>
							))}
						</Blocks>
						{STGoalsQuery.data.pagination.total > STGoalsQuery.data.goals.length && (
							<Button
								title={t('plan.viewAllButton')}
								variant="secondary"
								style={[vrTop(6), { alignSelf: 'center' }]}
								onPress={() => {
									focusRefST();
									setSTGoalLimit(STGoalsQuery.data.pagination.total);
								}}
								size="sm"
							/>
						)}
					</>
				) : null}
			</DeferredLoadingIndicator>
		</ScreenTemplate>
	);
}

export function useGoalHistoryScreenOptions() {
	const { t } = useTranslation();
	const subScreenStyleProps = useSubScreenStyleProps();

	return (): StackNavigationOptions => ({
		title: t('plan.goalHistory.screenTitle'),
		headerRight: () => <ChatButton />,
		...subScreenStyleProps,
	});
}
