import { noop } from 'lodash';
import * as React from 'react';
import { Animated } from 'react-native';
import useDismissible from './useDismissible';

interface IDismissibleProps {
	onAnimationComplete?: () => void;
	children: (dismiss: () => void) => React.ReactNode;
}

export default function Dismissible({ onAnimationComplete = noop, children }: IDismissibleProps) {
	const { animatedViewProps, dismiss } = useDismissible({ onAnimationComplete });

	return <Animated.View {...animatedViewProps}>{children(dismiss)}</Animated.View>;
}
