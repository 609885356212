import { useCreateValidicUser } from '@mobe/api/account/accountApiHooks';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useAllFeaturedSharedContentQuery } from '@mobe/api/explore/exploreApiHooks';
import Button from '@mobe/components/button/Button';
import { IntegratedDevicesGraphicWithBackground } from '@mobe/components/graphics';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import ScreenTemplateCenteredWithCard from '@mobe/components/screenTemplate/ScreenTemplateCenteredWithCard';
import { Text } from '@mobe/components/text';
import useTextStyles from '@mobe/components/text/textStyles';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ContentCard from '../../explore/components/ContentCard';
import { ConnectHealthDataScreenNavigationProp } from '../../navigation/menu/types';
import * as ConnectHealthDataAnalyticsEvents from '../analyticsEvents';

export default function ConnectHealthDataIntroView({
	navigation,
}: {
	navigation: ConnectHealthDataScreenNavigationProp;
}) {
	const { constrain, constrainText, vr, wrapperHorizontal } = useStyleHelpers();
	const inlineButtonStyles = useTextStyles({ color: 'primary', size: 'sm' });
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();
	const featuredSharedContentQuery = useAllFeaturedSharedContentQuery();
	const auth = useAuth();
	const createValidicUser = useCreateValidicUser();
	const contentItem = featuredSharedContentQuery.data?.trackingProgressArticle;
	const [isProvisioning, setIsProvisioning] = React.useState(false);

	async function buttonPressHandler() {
		if (auth.hasValidValidicData || isProvisioning) {
			return;
		}

		setIsProvisioning(true);

		const response = await createValidicUser.execute();

		if (!response.success) {
			setIsProvisioning(false);

			genericErrorAlert();
			return;
		}

		auth.validicData = response.data;

		ConnectHealthDataAnalyticsEvents.validicUserProvision();
	}

	function handleTermsAndConditionsPress() {
		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'termsAndConditions' });
	}

	function handlePrivacyPolicyPress() {
		navigation.navigate('TERMS_AND_CONDITIONS_MODAL_SCREEN', { view: 'privacyPolicy' });
	}

	return (
		<ScreenTemplateCenteredWithCard
			card={
				contentItem && (
					<View style={wrapperHorizontal}>
						<ContentCard
							contentItem={contentItem}
							onPress={() =>
								navigation.navigate('EXPLORE_DETAIL_SCREEN', {
									contentId: contentItem.sharedContentId,
								})
							}
							variant="compact"
						/>
					</View>
				)
			}
		>
			<View style={[constrain(250), vr(5)]}>
				<IntegratedDevicesGraphicWithBackground />
			</View>
			<Text accessibilityAutoFocus color="light" align="center" style={[constrainText(350), vr(4)]}>
				{t('connectHealthData.intro.body')}
			</Text>
			<Button
				size="sm"
				title={t('connectHealthData.intro.connectCTA')}
				onPress={buttonPressHandler}
				style={[vr(5), { alignSelf: 'center' }]}
				loading={isProvisioning}
			/>
			<Text color="light" size="sm" align="center" style={constrainText(350)}>
				<MobeParsedText
					parse={[
						{
							pattern: new RegExp(t('connectHealthData.intro.termsAndConditionsButton'), 'i'),
							style: inlineButtonStyles,
							onPress: () => handleTermsAndConditionsPress(),
						},
						{
							pattern: new RegExp(t('connectHealthData.intro.privacyPolicyButton'), 'i'),
							style: inlineButtonStyles,
							onPress: () => handlePrivacyPolicyPress(),
						},
					]}
				>
					{t('connectHealthData.intro.legal', {
						termsAndConditionsButton: t('connectHealthData.intro.termsAndConditionsButton'),
						privacyPolicyButton: t('connectHealthData.intro.privacyPolicyButton'),
					})}
				</MobeParsedText>
			</Text>
		</ScreenTemplateCenteredWithCard>
	);
}
