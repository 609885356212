import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import DelayedDisplay from '../delayedDisplay/DelayedDisplay';
import { AvatarGraphic } from '../graphics';
import ProfileHeaderGraphic, { ProfileHeaderGraphicColors } from '../graphics/ProfileHeaderGraphic';
import IconButton from '../iconButton/IconButton';
import MobeImage from '../mobeImage/MobeImage';
import { MobeImageSource } from '../mobeImage/utils';
import useProfileHeaderStyles from './profileHeaderStyles';

interface IProfileHeaderProps {
	imageSrc?: MobeImageSource;
	style?: StyleProp<ViewStyle>;
	headerColor?: ProfileHeaderGraphicColors;
	showImageUploadButton?: boolean;
	imageUploadButtonRef?: React.MutableRefObject<any>;
	onImageUploadPress?: () => void;
	isUploading?: boolean;
}

export default function ProfileHeader({
	imageSrc,
	style,
	headerColor,
	showImageUploadButton = false,
	imageUploadButtonRef = undefined,
	onImageUploadPress,
	isUploading = false,
}: IProfileHeaderProps) {
	const styles = useProfileHeaderStyles();
	const { t } = useTranslation();

	const absoluteFill = StyleSheet.absoluteFill as ViewStyle;

	// NOTE: Need these styles directly imported here in order for them to render on the web (spreading them in StyleSheet doesn't work for web)
	const { cardShadow } = useStyleHelpers();

	return (
		<View style={[styles.container, style]}>
			<View style={styles.header}>
				<ProfileHeaderGraphic color={headerColor} />
			</View>
			<View style={[styles.imageContainer, cardShadow]}>
				<View style={styles.imageCircleMask}>
					{imageSrc && (
						<MobeImage
							source={imageSrc}
							renderWidth={144}
							resizeMode="cover"
							style={styles.image}
						/>
					)}
					{!imageSrc && (
						<View style={styles.profilePlaceholderGraphic}>
							<AvatarGraphic />
						</View>
					)}
					{isUploading && (
						<DelayedDisplay>
							<View style={{ ...absoluteFill, backgroundColor: '#ffffffcc' }}></View>
						</DelayedDisplay>
					)}
				</View>

				{showImageUploadButton && (
					<View style={styles.imageUploadContainer}>
						<IconButton
							ref={imageUploadButtonRef}
							aria-label={t('profile.uploadProfilePictureLabel')}
							style={styles.imageUploadButton}
							name="camera"
							color="textInverted"
							onPress={onImageUploadPress || noop}
							hitSlop={20}
						/>
					</View>
				)}
			</View>
		</View>
	);
}
