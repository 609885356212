import { useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { getPermissions, Permissions } from './permissionsService';
import { PERMISSIONS_QUERY_KEY } from './types';

export function usePermissionsQuery(forceQueryToBeEnabled = false) {
	const auth = useAuth();

	// We use this query outside of being authenticated
	const enabled = forceQueryToBeEnabled ? true : auth.isAuthenticated;

	return useQuery([PERMISSIONS_QUERY_KEY], getPermissions, {
		enabled,
		select: (data) => ({
			canSelectGsmOrMskLanguage: data.includes(Permissions.CanSelectGSMOrMSKLanguage),
			canSelectGsmOrMskGender: data.includes(Permissions.CanSelectGSMOrMSKGender),
			canSelectGsmOrMskSpanishGender: data.includes(Permissions.CanSelectGsmOrMskSpanishGender),
			canSelectPharmacistLanguage: data.includes(Permissions.CanSelectPharmacistLanguage),
			canSelectPharmacistGender: data.includes(Permissions.CanSelectPharmacistGender),
			hasMedServicesAccess: !data.includes(Permissions.HidePharmacistFeatures),
		}),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
}
