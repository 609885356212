import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useCelebrationsQuery } from '@mobe/api/celebrations/celebrationsApiHooks';
import {
	useAllFeaturedSharedContentQuery,
	useContentModulesQuery,
} from '@mobe/api/explore/exploreApiHooks';
import { useActionStepsQuery } from '@mobe/api/goals/goalsApiHooks';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import HorizontalContentView from '@mobe/features/explore/components/HorizontalContentView';
import { useRestoreFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { ScrollView, View } from 'react-native';
import ActivitiesModule from '../activities/components/ActivitiesModule';
import useInAppActivities from '../activities/useInAppActivities';
import ContentModuleCard from '../explore/components/ContentModuleCard';
import BottomTabScreenTemplate from '../navigation/bottomTabs/components/BottomTabScreenTemplate';
import { HomeScreenNavigationProp, HomeScreenRouteProp } from '../navigation/bottomTabs/types';
import * as PlanAnalyticsEvents from '../plan/analyticsEvents';
import ActionStepsModule from '../plan/components/ActionStepsModule';
import * as HomeAnalytics from './analyticsEvents';
import CallsModule from './components/CallsModule';
import ConnectHealthData from './components/ConnectHealthData';
import GuideMatchCta from './components/GuideMatchCta';
import Hero from './components/Hero';
import HomeMoodPicker from './components/HomeMoodPicker';
import IntroVideo from './components/IntroVideo';
import PharmacistMatchCta from './components/PharmacistMatchCta';

type HomeScreenProps = {
	route: HomeScreenRouteProp;
	navigation: HomeScreenNavigationProp;
};

export default function HomeScreen({ navigation }: HomeScreenProps) {
	const persistentState = usePersistentState();
	const { vr, wrapperHorizontal } = useStyleHelpers();
	const remoteConfigData = useRemoteConfigData();
	const auth = useAuth();
	const featuredSharedContentQuery = useAllFeaturedSharedContentQuery();
	const profileQuery = useProfileQuery();
	const activitiesQuery = useAllActivitiesQuery();
	const appointmentsQuery = useAppointmentsQuery();
	const actionStepsQuery = useActionStepsQuery({ status: 'InProgress' });
	const completedActionStepsQuery = useActionStepsQuery({ status: 'Completed' });
	const guideQuery = useGuideQuery();
	const pharmacistQuery = usePharmacistQuery();
	const permissionsQuery = usePermissionsQuery();
	const contentModulesQuery = useContentModulesQuery();
	const contentModulesData = contentModulesQuery.data || [];
	const nonDismissedContentModules = contentModulesData.filter(
		(contentModule) =>
			!persistentState.dismissedContentModuleIds.includes(contentModule.contentModuleId)
	);
	const [hasSeenVideo, setHasSeenVideo] = React.useState(persistentState.hasSeenWelcomeVideo);
	const inAppActivities = useInAppActivities();
	const hasNewActivities =
		[...(activitiesQuery.data?.activities ?? []), ...inAppActivities].filter(
			(activity) => !activity.isCompleted
		).length > 0;
	const featuredContent = featuredSharedContentQuery.data?.featuredContent ?? [];
	const shouldShowGuideMatchCta = !(guideQuery.data || persistentState.hasDismissedGuideMatch);
	const shouldShowPharmacistMatchCta = !(
		pharmacistQuery.data ||
		!permissionsQuery.data?.hasMedServicesAccess ||
		persistentState.hasDismissedPharmacistMatch
	);
	const isFirstSignIn = !(Boolean(guideQuery.data) || persistentState.loginCount > 1);
	const { storeFocusTarget, hasStoredFocusTarget } = useRestoreFocus();

	/**
	 * Explicitly monitors any query that affects content above the fold, but nothing more.
	 */
	const isLoading =
		featuredSharedContentQuery.isLoading ||
		profileQuery.isLoading ||
		guideQuery.isLoading ||
		pharmacistQuery.isLoading ||
		appointmentsQuery.isLoading ||
		actionStepsQuery.isLoading ||
		completedActionStepsQuery.isLoading ||
		activitiesQuery.isLoading ||
		permissionsQuery.isLoading;

	// Call the celebration query after the majority of the app is loaded.
	// If an app tour is added in the future, logic will need to be added to prioritize these.
	useCelebrationsQuery(!isLoading);

	useRefetchStaleQueryOnFocusEffect([
		featuredSharedContentQuery,
		actionStepsQuery,
		completedActionStepsQuery,
		activitiesQuery,
		appointmentsQuery,
	]);

	useFocusEffect(
		React.useCallback(() => {
			if (remoteConfigData.reduceHomeComplexityVariant && persistentState.loginCount > 5) {
				persistentState.setHasSeenWelcomeVideo(true);
				setHasSeenVideo(true);
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	return (
		<BottomTabScreenTemplate>
			<ScrollView>
				<Hero accessibilityAutoFocus={!hasStoredFocusTarget} style={vr(hasSeenVideo ? 10 : 6)} />
				<DeferredLoadingIndicator isLoading={isLoading} deferDuration={3000}>
					{!hasSeenVideo && <IntroVideo style={vr(10)} />}

					<View style={wrapperHorizontal}>
						{guideQuery.isSuccess && pharmacistQuery.isSuccess && (
							<>
								{(guideQuery.data || pharmacistQuery.data) && <CallsModule style={vr(10)} />}

								{shouldShowGuideMatchCta && (
									<GuideMatchCta style={vr(shouldShowPharmacistMatchCta ? 6 : 10)} />
								)}

								{shouldShowPharmacistMatchCta && <PharmacistMatchCta style={vr(10)} />}
							</>
						)}

						{actionStepsQuery.isSuccess && completedActionStepsQuery.isSuccess && (
							<ActionStepsModule
								style={vr(10)}
								actionSteps={actionStepsQuery.data.actionSteps.slice(0, 3)}
								hasCompletedActionSteps={completedActionStepsQuery.data.pagination.total > 0}
								headerButtonType="viewAll"
								count={actionStepsQuery.data.pagination.total}
								onComplete={PlanAnalyticsEvents.actionStepCompletePressHome}
								onCardPress={storeFocusTarget}
							/>
						)}

						{!persistentState.hasDismissedTrackerCTAFromHome &&
							!auth.hasValidValidicData &&
							!(isFirstSignIn && remoteConfigData.reduceHomeComplexityVariant) && (
								<ConnectHealthData style={vr(10)} />
							)}

						{!(remoteConfigData.reduceHomeComplexityVariant && !hasNewActivities) && (
							<ActivitiesModule style={vr(10)} context="home" onCardPress={storeFocusTarget} />
						)}

						{nonDismissedContentModules.length > 0 && (
							<ContentModuleCard
								onPress={(contentModuleId, event) => {
									storeFocusTarget(event);
									navigation.navigate('EXPLORE_CONTENT_MODULE_SCREEN', { contentModuleId });
								}}
								contentModule={nonDismissedContentModules[0]}
								dismissable
								onDismiss={(contentModuleId) => {
									HomeAnalytics.dismissContentModule(contentModuleId);
								}}
								style={vr(12)}
							/>
						)}

						{!(isFirstSignIn && remoteConfigData.reduceHomeComplexityVariant) && (
							<HomeMoodPicker style={vr(12)} />
						)}
					</View>

					<HorizontalContentView
						onCardPress={(contentId, event) => {
							storeFocusTarget(event);
							navigation.navigate('EXPLORE_DETAIL_SCREEN', { contentId });
						}}
						onViewAllPress={() => navigation.navigate('EXPLORE_SCREEN')}
						alwaysShowViewAll
						id={'Featured Resources'}
						items={featuredContent}
						style={vr(10)}
					/>
				</DeferredLoadingIndicator>
			</ScrollView>
		</BottomTabScreenTemplate>
	);
}
