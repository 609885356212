import Analytics from '@mobe/utils/analytics';

export function surveyExitCancel(title: string) {
	Analytics.logEvent('activities_survey_early_exit_cancel', { title });
}

export function surveyExitConfirm(title: string) {
	Analytics.logEvent('activities_survey_early_exit_confirm', { title });
}

export function activityCardPressHome() {
	Analytics.logEvent('activity_card_press_home');
}

export function activityCardPressPlan() {
	Analytics.logEvent('activity_card_press_plan');
}

export function activityGuideMatchPress() {
	Analytics.logEvent('activity_guide_match_press');
}
