import { LightBulb } from '@mobe/components/graphics';
import Icon from '@mobe/components/icon/Icon';
import Row from '@mobe/components/layout/Row';
import Text from '@mobe/components/text/Text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';

function MoodInfoBox() {
	const { t } = useTranslation();
	const rules = useStyleRules();
	const { constrain } = useStyleHelpers();
	const [descriptionVisible, setDescriptionVisible] = React.useState(false);

	const styles = StyleSheet.create({
		pressableWrapper: {
			backgroundColor: rules.colors.background,
			borderRadius: rules.borderRadius,
			marginTop: 32,
			marginHorizontal: 8,
			paddingHorizontal: 10,
		},
		row: {
			paddingVertical: 10,
		},
		description: {
			paddingTop: 8,
			paddingBottom: 16,
		},
	});

	return (
		<Pressable
			onPress={() => setDescriptionVisible(!descriptionVisible)}
			aria-expanded={descriptionVisible}
			style={styles.pressableWrapper}
		>
			<Row style={styles.row} gutterSize={8}>
				<Row.Item>
					<View style={constrain(24)}>
						<LightBulb width={24} />
					</View>
				</Row.Item>
				<Row.Item fill>
					<Text color="light" weight="semiBold">
						{t('home.moodContextTitle')}
					</Text>
				</Row.Item>
				<Row.Item>
					<View style={constrain(15)}>
						<Icon size={15} name="down" />
					</View>
				</Row.Item>
			</Row>
			{descriptionVisible && (
				<View style={styles.description}>
					<Text color="light">{t('home.moodContextContent')}</Text>
				</View>
			)}
		</Pressable>
	);
}

export default MoodInfoBox;
