import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

interface IChannelUnreadChatMessagesCount {
	channelId: number;
	numberOfUnreadMessages: number;
}

interface IUnreadChatMessagesResponse {
	mostRecentUnreadMessageCreatedDate: string;
	numberOfUnreadMessages: number;
	numberOfUnreadMessagesByChannels: IChannelUnreadChatMessagesCount[];
}

export interface IChatResponse {
	hasMoreMessages: boolean;
	nextPageDate: string | null;
	chatMessages: IServerChatMessage[];
}

export enum ChatMessageType {
	Message = 'Message',
	Attachment = 'Attachment',
	AttachmentText = 'AttachmentText',
	// NOTE: we aren't concerned with any other message types at this time
}

export interface IServerChatMessage {
	channelId: number | null;
	dateRead: string | null;
	fromUserId: number | null;
	toUserId: number | null;
	id: number;
	imageAttachmentFileId: number | null;
	imageAttachmentFileName: string | null;
	isIncoming: boolean;
	isReceived: boolean;
	isSentByCoach: boolean;
	messageDate: string;
	messageText: string | null;
	messageType: ChatMessageType;

	// NOTE: these are relics of the past usage of the chat and not used at this time
	consumerSurvey: null;
	eConsultDocument: null;
	sharedContent: null;
}

export class ChatService {
	public loadMessages = async (paginationStartDate: string, channelId: number) => {
		try {
			// Page url path will always need to be zero for this endpoint to work with a date pagination
			// this is not noted in the swagger docs
			const data = await mobeAuthenticatedAPI.get<IChatResponse>(`chat/${0}`, {
				pageStartDate: paginationStartDate,
				channelId,
			});

			return mobeStandardSuccessProcessor<IChatResponse>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<IChatResponse>(error);
		}
	};

	public async sendAttachment(attachment: string | File, channelId: number) {
		try {
			await mobeAuthenticatedAPI.postBlob(
				'chat/attachment',
				{
					attachment,
				},
				{ channelId }
			);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	public async sendAttachmentAndText(
		attachment: string | File,
		textMessage: string,
		channelId: number
	) {
		try {
			await mobeAuthenticatedAPI.postBlob(
				'chat/attachment-and-text',
				{
					attachment,
				},
				{
					channelId,
					textMessage,
				}
			);

			return mobeStandardSuccessProcessor();
		} catch (error) {
			return mobeStandardErrorProcessor(error);
		}
	}

	public async getUnreadMessageInformation() {
		try {
			const data = await mobeAuthenticatedAPI.get<IUnreadChatMessagesResponse>(
				'chat/unread-chat-messages-info'
			);

			return mobeStandardSuccessProcessor<IUnreadChatMessagesResponse>(data);
		} catch (error) {
			return mobeStandardErrorProcessor<IUnreadChatMessagesResponse>(error);
		}
	}
}

const chatService = new ChatService();
export default chatService;
