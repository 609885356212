import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function ProgressGuideCall(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(70.5, 98);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#FDCCB8"
					d="M15.5 27.7s4.3-4.7 4.9-4.9c.4-.2.9 0 1.1.4 0 .1.1.2.1.3l-.3 1c1 0 0 1.4-.5 1.7v.1c.7-.1 2-.3 2.1-.1.9.9.8 6-.1 6.6s-3.3 4.3-5.3 5.2c-1.4 3.3-6.1 10.5-7.1 13.4l2.9 1.7c-1 4.1-1.8 6.3-2.5 9.6-.6 2.7-1.4.9-7.5-1.5-11.2-4.8 9.8-25 9.8-25l2.4-8.5z"
				/>
				<Path
					d="M15.3 30.6l7.8-14c.4-.7 1.3-1 2.1-.6l6.4 3.5c.7.4 1 1.3.6 2.1l-7.8 14c-.4.7-1.3 1-2.1.6l-6.4-3.5c-.8-.4-1-1.4-.6-2.1z"
					fill="#263338"
				/>
				<Path
					fill="#FDCCB8"
					d="M34.4 27.1c1.8 2.1 3.8 4.1 5.9 5.9 0 0 .9 8.7 1 9.2-.1 1.3-.3 2.6-.6 3.8l-4.3 6.3-3.5 2.5-1.8-2.8-1-4c-.7-1.6 0-14.2 0-16.4 0-.2 1.2-3.6 1.2-3.6l3.1-.9z"
				/>
				<Path
					d="M31.1 36.8c2.4-.2 5.1-3.7 5.8-5 1.2-1.7 2.2-3.5 2.9-5.4l.1-.1s-1.8 3.4 2.5 9.5 1.6 3.6 1.4 3.6c-1 1.6-2 3.2-2.8 4.8-3.8-15.1.1-5.3-9.9-7.4z"
					fill="#E6BEAD"
				/>
				<Path
					d="M51.1 42c-3.2-1.4-6-3.4-8.5-5.8 1.1 9.7-12.8 22.7-12.8 5.2-3.8 1.9-6.9 5-7.3 7.5 0 0-8.1 2.8-9 3.3C10.6 56.7 10 65 9.9 65.7c-.1.8 3.5-1 9.4 2.7.2 8.2 1.2 17.3-1.2 22.8-2.4 5.6 30.9 1.5 44.1 2.2 5.2.1 7.8-8.8 8.3-18.6.7-13.8-7-26.7-19.4-32.8z"
					fill="#47B7E3"
				/>
				<Path
					fill="#44A8CF"
					d="M55.1 67.7c2.3 11.2 5.5 26.9 5.5 26.9 0 .3-.2.6-.4.8s-.6.3-.9.3h-.2l-22.5-2.4c-.5-.1-.9-.6-.9-1.1 0-.5.3-1 .7-1.2.5-.4 14.3-3.2 14.1-4 3.7-3.8 2.6-25.1 4.6-19.3z"
				/>
				<Path
					fill="#44A8CF"
					d="M19.6 68.8C21.9 80 21.9 94 21.9 94c-.1.7-3.7-1.9-3.8-2.7 1.7-3.6 1.3-15.1 1.5-22.5z"
				/>
				<Path
					fill="#FDCCB8"
					d="M55.4 85.7c1.4-.1 2.4-4.7 2.4-4.7 2 0 8.2-.6 12.6-3.6-.4 4.2-1.5 10-1.5 10-1.6 5.9-7.2 9.9-13.3 9.4-8.5-.5-17-.5-25.5-.1-1 .2-13.3 1.9-12.7 1-.6-.1-1.2-.4-1.6-.8-1-.3-3.9-1.1-2.9-3-4-1.8 1.4-4.7 2.7-6 .4-1.2 1.6-2 2.9-1.8 2.6.3 5.1.8 7.7 1.5 2 2 21.6.1 29.2-1.9z"
				/>
				<Path
					d="M23.1 15c2.7 1.2 8.3 1.8 10 .2.4 1.7.3 11.6 2.3 10.1.6-.5 3.9-4 4.7-.5v.4c2.7-2.3 5.3-6.5 5.7-10 .8-6.2-2.8-6.3-4.4-6.5-.3 0-.6-.2-.7-.5-1.8-4-15.7-14.3-21.1-3.3-2.2 4.5 1.3 10.9 1.8 12.6.1.2.2.4.5.5 0-.9 0-1.6.1-2.3 0-.5.5-.9.9-.8 0 0 .1.1.2.1z"
					fill="#FFBB39"
				/>
				<Path
					fill="#FDCCB8"
					d="M21.3 15.4c1.4-6.5 10.8-4.7 12.4 0C35.3 18.6 35 25 35 25c4.4-6.2 7.4.1 3.6 4.1l-1.5 2.3c-2 4.2-6.2 6-10.8 5.8-3.4-.1-4-6.7-4.1-8.3-4.1.6-1.4-2.1-.8-6.4-.3-1.5-.1-7.1-.1-7.1z"
				/>
			</Svg>
		</View>
	);
}
