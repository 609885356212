import { useResendWelcomeEmail } from '@mobe/api/account/accountApiHooks';
import { Button } from '@mobe/components/button';
import EnvelopeGraphic from '@mobe/components/graphics/EnvelopeGraphic';
import Heading from '@mobe/components/heading/Heading';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useAlert } from '@mobe/utils/useAlert';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	VerifyEmailPromptScreenNavigationProp,
	VerifyEmailPromptScreenScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export default function VerifyEmailPromptScreen(props: {
	navigation: VerifyEmailPromptScreenNavigationProp;
	route: VerifyEmailPromptScreenScreenRouteProp;
}) {
	const resendWelcomeEmail = useResendWelcomeEmail();
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { mobeAlert } = useAlert();
	const { t } = useTranslation();

	return (
		<AuthScreenTemplate center>
			<View style={[constrain(200), vr(10)]}>
				<EnvelopeGraphic />
			</View>
			<Heading level="h2" align="center" style={vr(5)}>
				{t('auth.verifyEmailPrompt.title')}
			</Heading>
			<Text weight="medium" align="center" style={[constrainText(350), vr(8)]}>
				{t('auth.verifyEmailPrompt.description')}
			</Text>
			<Button
				title={t('auth.verifyEmailPrompt.returnButton')}
				style={vr(5)}
				onPress={() => {
					props.navigation.navigate('LOGIN_SCREEN');
				}}
			/>
			{Boolean(props.route.params.accessToken) && (
				<TextButton
					align={'center'}
					title={t('auth.verifyEmailPrompt.resendButton')}
					loading={resendWelcomeEmail.isPending}
					onPress={async () => {
						const response = await resendWelcomeEmail.execute(props.route.params.accessToken);

						if (response.success) {
							mobeAlert('', t('auth.verifyEmailPrompt.resendConfirmationMessage'));
						} else {
							mobeAlert('', t('auth.verifyEmailPrompt.error.problemSendingEmail'));
						}
					}}
				/>
			)}
		</AuthScreenTemplate>
	);
}

export function useVerifyEmailPromptScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.verifyEmailPrompt.screenTitle'),
		headerShown: false,
	};
}
