import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestSleepGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(48, 48);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M1.145 48H46.86A1.15 1.15 0 0048 46.838v-9.723c0-1.72-1.366-3.115-3.052-3.118h-4.846a16.758 16.758 0 00-5.388-9.532c.872-.191 1.709-.4 2.466-.621a3.547 3.547 0 001.185-.581l1.953 2.068c.319.352.818.469 1.255.293.438-.177.724-.61.721-1.089a1.174 1.174 0 00-.325-.83l-2.277-2.41c.096-.343.146-.696.148-1.052V5.46c-.001-.36-.051-.717-.148-1.063l2.277-2.41c.218-.219.34-.517.34-.828 0-.311-.122-.61-.34-.828A1.128 1.128 0 0041.152 0a1.13 1.13 0 00-.806.361L38.393 2.43a3.392 3.392 0 00-1.201-.615 48.984 48.984 0 00-26.338 0c-.43.127-.831.336-1.184.615L7.688.361A1.135 1.135 0 006.583.036c-.399.1-.715.41-.828.813a1.19 1.19 0 00.276 1.139l2.278 2.404c-.097.344-.147.7-.149 1.057v14.753c.002.36.052.717.149 1.063l-2.278 2.41a1.174 1.174 0 00.034 1.656c.221.21.512.326.814.325.305-.006.595-.133.809-.354l1.953-2.068c.358.271.764.469 1.196.58.752.222 1.56.425 2.454.622a16.758 16.758 0 00-5.387 9.531H3.052C1.366 33.971 0 35.367 0 37.087v9.74c-.003.31.116.61.332.83.215.221.508.345.813.343zM24.003 8.173c4.088 0 7.403 3.381 7.403 7.551s-3.315 7.55-7.403 7.55c-4.089 0-7.403-3.38-7.403-7.55s3.314-7.55 7.403-7.55zm8.109 14.422c-.245-.14-.495-.279-.746-.406 3.383-4.004 3.092-10.01-.661-13.655a9.584 9.584 0 00-13.404 0c-3.754 3.644-4.045 9.65-.661 13.655-.25.127-.501.267-.746.406a42.768 42.768 0 01-4.42-1.005c-.596-.186-1-.752-.99-1.388V5.45a1.415 1.415 0 01.99-1.376 46.654 46.654 0 0125.108 0c.601.18 1.01.75.997 1.388v14.753a1.42 1.42 0 01-.991 1.376c-1.474.415-2.968.75-4.476 1.005zm-13.621 1.301a9.526 9.526 0 0011.019 0c4.193 1.804 7.268 5.569 8.251 10.1H10.245c.982-4.53 4.055-8.294 8.246-10.1zM3.052 36.343h41.901a.77.77 0 01.758.772v8.538H2.295v-8.538a.77.77 0 01.757-.772z"
					fill="#1A7EAD"
					fillRule="nonzero"
				/>
			</Svg>
		</View>
	);
}
