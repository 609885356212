import { yupResolver } from '@hookform/resolvers/yup';
import { validatePassword } from '@mobe/utils/validationUtils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function useClaimAccountFormSchema() {
	const { t } = useTranslation();

	return Yup.object({
		email: Yup.string().email().required().label(t('auth.claimAccount.emailInputLabel')),
		password: Yup.string()
			.required()
			.test(
				'valid password',
				({ label }) => t('auth.claimAccount.errors.passwordDoesNotMeetRequirements', { label }),
				(value) => validatePassword(value).valid
			)
			.label(t('auth.claimAccount.passwordInputLabel')),
	});
}

export type ClaimAccountFormSchema = Yup.InferType<ReturnType<typeof useClaimAccountFormSchema>>;

export default function useClaimAccountForm(
	defaultValues: ClaimAccountFormSchema = {
		email: '',
		password: '',
	}
) {
	const validationSchema = useClaimAccountFormSchema();

	return useForm<ClaimAccountFormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues,
	});
}
