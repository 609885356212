import { yupResolver } from '@hookform/resolvers/yup';
import { useForgotPasswordMutation } from '@mobe/api/account/accountApiHooks';
import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import * as Yup from 'yup';
import { IForgotPasswordScreenProps } from './ForgotPasswordScreen';

function useValidationSchema() {
	const { t } = useTranslation();

	return Yup.object({
		email: Yup.string().email().required().label(t('auth.forgotPassword.emailInputLabel')),
	});
}

type FormSchema = Yup.InferType<ReturnType<typeof useValidationSchema>>;

export default function useForgotPasswordController({ navigation }: IForgotPasswordScreenProps) {
	const auth = useAuth();
	const validationSchema = useValidationSchema();
	const forgotPasswordMutation = useForgotPasswordMutation();

	const form = useForm<FormSchema>({
		mode: 'onTouched',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email: '',
		},
	});

	async function submit({ email }: FormSchema) {
		forgotPasswordMutation.mutate(
			{ email },
			{
				onSuccess: () => {
					// This makes the alert box on sign in screen for now
					auth.forgotPasswordWasSubmitted = true;
				},
			}
		);
	}

	function handleSubmitPress() {
		Keyboard.dismiss();
		form.handleSubmit(submit)();
	}

	function handleBackPress() {
		Keyboard.dismiss();
		navigation.navigate('LOGIN_SCREEN');
	}

	return {
		handleSubmitPress,
		handleBackPress,
		forgotPasswordMutation,
		form,
	};
}
