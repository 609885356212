import { useChatHubConnection } from '@mobe/features/chat/chatHubConnectionContext';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import chatService from './chatService';
import { ChatQueryKeys } from './types';

export function useChatsQuery(paginationStartDate: string, channelId: number) {
	const queryClient = useQueryClient();

	return useInfiniteQuery(
		[ChatQueryKeys.Chats, channelId],
		async ({ pageParam = paginationStartDate }) => {
			const nextPaginationStartDate = pageParam;
			const response = await chatService.loadMessages(nextPaginationStartDate, channelId);

			if (response.success) {
				return response.data;
			}

			throw response.error;
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.hasMoreMessages ? lastPage.nextPageDate : undefined,
			onSuccess: () => {
				queryClient.invalidateQueries(ChatQueryKeys.UnreadChatCount);
			},
		}
	);
}

export function useUnreadChatCountQuery() {
	const auth = useAuth();

	return useQuery(
		ChatQueryKeys.UnreadChatCount,
		async () => {
			const response = await chatService.getUnreadMessageInformation();

			if (response.success) {
				return response.data;
			}

			throw response.error;
		},
		{
			enabled: auth.isAuthenticated,

			// Gets invalidated on logout and heavily by signalr events
			staleTime: Infinity,
		}
	);
}

interface ISendChatMessageMutationVariables {
	messageText: string;
}

export function useSendChatMessageMutation(channelId: number) {
	const { sendMessage } = useChatHubConnection();

	return useMutation(async ({ messageText }: ISendChatMessageMutationVariables) => {
		try {
			await sendMessage(messageText, channelId);
		} catch {
			throw Error('Message failed to send.');
		}
	});
}

// Mark message read by user in guide portal
export function useConsumerReceivedGuideMessagesMutation(channelId: number) {
	const { consumerReceivedGuideMessages } = useChatHubConnection();

	return useMutation(async () => {
		try {
			await consumerReceivedGuideMessages(channelId);
		} catch {
			throw Error('"consumerReceivedGuideMessages" failed to send.');
		}
	});
}

interface IConsumerReceivedMessageMutationVariables {
	messageId: number;
}

// Mark message read by user, this affects database
export function useConsumerReceivedMessageMutation(channelId: number) {
	const queryClient = useQueryClient();
	const { consumerReceivedMessage } = useChatHubConnection();

	return useMutation(
		async ({ messageId }: IConsumerReceivedMessageMutationVariables) => {
			try {
				await consumerReceivedMessage(messageId, channelId);
			} catch {
				throw Error('"consumerReceivedMessage" failed to send.');
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(ChatQueryKeys.UnreadChatCount);
			},
		}
	);
}

interface ISendChatAttachmentMessageMutationVariables {
	attachment: string | File;
	messageText: string;
}

export function useSendChatAttachmentMessageMutation(channelId: number) {
	return useMutation(
		async ({ attachment, messageText }: ISendChatAttachmentMessageMutationVariables) => {
			const response = await chatService.sendAttachmentAndText(attachment, messageText, channelId);

			if (response.success) {
				return response.data;
			}

			throw response.error;
		}
	);
}
