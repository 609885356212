import { useAuth } from '@mobe/api/authentication/AuthContext';
import { useSendVerificationCode } from '@mobe/api/authentication/authApiHooks';
import Box from '@mobe/components/box/Box';
import { Button } from '@mobe/components/button';
import { InputText } from '@mobe/components/input';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useAlert } from '@mobe/utils/useAlert';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, useWindowDimensions } from 'react-native';
import {
	SMSPromptScreenNavigationProp,
	SMSPromptScreenRouteProp,
} from '../AuthenticationStackScreen';
import { obfuscatePhone } from '../authUtility';
import AcceptNote from '../components/acceptNote/AcceptNote';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';

export default function SMSPromptScreen(props: {
	navigation: SMSPromptScreenNavigationProp;
	route: SMSPromptScreenRouteProp;
}) {
	const auth = useAuth();
	const sendVerificationCode = useSendVerificationCode();
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const dimensions = useWindowDimensions();
	const { mobeAlert } = useAlert();

	const phoneNumber = auth.firstTimePhoneNumber;
	const shouldObfuscate = Boolean(props.route.params.shouldObfuscate);

	return (
		<AuthScreenTemplate
			heading={t('auth.smsPrompt.heading')}
			header={
				<Box row>
					<Text weight="semiBold">{t('auth.common.newParticipant')} </Text>
					<TextButton
						onPress={() => {
							props.navigation.navigate('ELIGIBILITY_CHECK_SCREEN');
						}}
						title={t('auth.common.newParticipantButton')}
					></TextButton>
				</Box>
			}
		>
			<Text
				weight="medium"
				align="center"
				style={{
					paddingHorizontal: Platform.OS !== 'web' ? dimensions.width / 6 : undefined,
					...vr(6),
				}}
			>
				{t('auth.smsPrompt.description')}
			</Text>

			<InputText
				label={t('auth.smsPrompt.phoneInputLabel')}
				type={shouldObfuscate ? 'text' : 'phone'}
				disabled={true}
				value={shouldObfuscate ? obfuscatePhone(phoneNumber) : phoneNumber}
			/>

			<Button
				title={t('auth.smsPrompt.continueButtonLabel')}
				style={vr(4)}
				loading={sendVerificationCode.isPending}
				onPress={async () => {
					const response = await sendVerificationCode.execute(phoneNumber);

					if (response.success) {
						props.navigation.navigate('VERIFY_SMS_SCREEN');
					} else {
						mobeAlert('', t('auth.smsPrompt.error.problemSendingVerificationCode'));
					}
				}}
			/>

			<AcceptNote />
		</AuthScreenTemplate>
	);
}

export function useSMSPromptScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('auth.smsPrompt.screenTitle'),
		headerShown: false,
	};
}
