import { useGuideMatchTimeSlotsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { GenderPreference, LanguagePreference } from '@mobe/api/appointments/appointmentsService';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import Text from '@mobe/components/text/Text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleApptView from '../../../components/ScheduleApptView';
import {
	GuideMatchScheduleScreenNavigationProp,
	GuideMatchScheduleScreenRouteProp,
} from '../GuideMatchStackScreen';
import GuideMatchScreenTemplate from '../components/GuideMatchScreenTemplate';
import { useGuideMatchForm } from '../guideMatchProvider';

interface IGuideMatchScheduleScreenProps {
	navigation: GuideMatchScheduleScreenNavigationProp;
	route: GuideMatchScheduleScreenRouteProp;
}

export default function GuideMatchScheduleScreen({ route }: IGuideMatchScheduleScreenProps) {
	const { t } = useTranslation();
	const { vr } = useStyleHelpers();
	const formController = useGuideMatchForm();
	const apptDateControl = formController.apptDateControl;
	const canProgress = !apptDateControl.fieldState.error && apptDateControl.fieldState.isDirty;
	const [nextButtonRef, setFocusToNextButton] = useAccessibilityFocus();

	const formValues = formController.form.getValues();
	const guideMatchTimeSlotsQuery = useGuideMatchTimeSlotsQuery({
		genderPreference: formValues.gender || GenderPreference.NoPreference,
		languagePreference: formValues.language || LanguagePreference.English,
		desiredAppointmentMonthDateTime: new Date(Date.now()).toISOString(),
	});

	return (
		<GuideMatchScreenTemplate
			nextButtonRef={nextButtonRef}
			routeName={route.name}
			canProgress={canProgress}
			fixedPositionFooter={canProgress}
		>
			<Text
				accessibilityAutoFocus
				role="heading"
				aria-level="2"
				size="lg"
				weight="medium"
				align="center"
				style={vr(6)}
			>
				{t('appointments.guideMatch.scheduleStep.description')}
			</Text>

			<DeferredLoadingIndicator isLoading={guideMatchTimeSlotsQuery.isLoading}>
				{guideMatchTimeSlotsQuery.isSuccess && (
					<FetchingIndicator isFetching={guideMatchTimeSlotsQuery.isFetching}>
						<ScheduleApptView
							apptDates={guideMatchTimeSlotsQuery.data.availableDays}
							apptDurationMinutes={guideMatchTimeSlotsQuery.data.appointmentDurationInMinutes}
							selectedApptDate={formController.apptDateControl.field.value}
							onApptSelection={formController.apptDateControl.field.onChange}
							onTimeSelection={(availableGuideDiKeys) => {
								formController.handleTimeSelection(availableGuideDiKeys);

								setTimeout(() => {
									// Focus next step button
									setFocusToNextButton();
								}, 500);
							}}
							onRetry={guideMatchTimeSlotsQuery.refetch}
						/>
					</FetchingIndicator>
				)}
			</DeferredLoadingIndicator>
		</GuideMatchScreenTemplate>
	);
}
