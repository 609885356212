import { CalendarTheme } from 'react-native-calendars';
import { useStyleRules } from './styleRules/StyleRulesProvider';

export default function useCalendarTheme(): CalendarTheme {
	const rules = useStyleRules();

	return {
		textDayFontSize: Math.min(rules.fontSizes.md, rules.staticFontSizes.xxl),
		textDayStyle: { marginTop: 'auto', marginBottom: 'auto' },
		dotStyle: { position: 'absolute', bottom: 2 },
		dayTextColor: rules.colors.text,
		textDayFontFamily: rules.fontFamilies.sans,
		textMonthFontFamily: rules.fontFamilies.sansMedium,
		textDayHeaderFontFamily: rules.fontFamilies.sans,
		todayTextColor: rules.colors.text,
		monthTextColor: rules.colors.text,
		selectedDayTextColor: rules.colors.textInverted,
		calendarBackground: rules.colors.cardBackground,

		// This partial opacity middle grey works for light and dark color schemes
		textDisabledColor: '#99999999',
	};
}
