import { useAuth } from '@mobe/api/authentication/AuthContext';
import { Button } from '@mobe/components/button';
import { Error404 } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import HeaderGraphic from '../auth/components/headerGraphic/HeaderGraphic';
import MainContentView from './MainContentView';
import { RootStackParamList } from './Navigation';

type NotFoundScreenNavigationProp = StackNavigationProp<RootStackParamList, 'not-found'>;

export default function NotFoundScreen({
	navigation,
}: {
	navigation: NotFoundScreenNavigationProp;
}) {
	const auth = useAuth();
	const { constrain, vr } = useStyleHelpers();
	const { styles, scrollViewBackgroundColor } = useNotFoundScreenStyles();
	const { t } = useTranslation();

	// Force user to auth stack if not authenticated
	React.useEffect(() => {
		if (!auth.isAuthenticated) {
			setTimeout(() => {
				navigation.replace('AUTH_STACK');
			}, 0);
		}
	}, [auth.isAuthenticated]);

	const handleSupportPress = () => {
		GlobalAnalyticsEvents.supportNumberPress();
		Linking.openURL(`tel:${t('notFound.callForHelpNumber')}`);
	};

	const handleButtonPress = () => {
		if (!auth.isAuthenticated) {
			navigation.navigate('AUTH_STACK');
		} else {
			navigation.navigate('MODAL_STACK');
		}
	};

	// Don't render anything because we're going to force user to auth stack if not authenticated
	if (!auth.isAuthenticated) {
		return null;
	}

	return (
		<MainContentView>
			<ScreenTemplate noPadding scrollViewBackgroundColor={scrollViewBackgroundColor}>
				<View style={styles.container}>
					<View>
						<View style={styles.graphicRow}>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
							<View style={styles.graphicRowItem}>
								<HeaderGraphic />
							</View>
						</View>

						<View style={[constrain(280), vr(8)]}>
							<Error404 />
						</View>

						<Heading level="h1" align="center" style={vr(5)}>
							{t('notFound.heading')}
						</Heading>

						<Text weight="medium" align="center" style={vr(6)}>
							{t('notFound.subHeading')}
						</Text>

						<Button title={t('notFound.buttonText')} style={vr(4)} onPress={handleButtonPress} />
					</View>

					<View style={styles.footer}>
						<TextButton
							style={styles.pageFooterHelpButton}
							title={t('notFound.callForHelp')}
							align="center"
							onPress={handleSupportPress}
						/>
					</View>
				</View>
			</ScreenTemplate>
		</MainContentView>
	);
}

const useNotFoundScreenStyles = () => {
	const rules = useStyleRules();
	const { top: topSafeInset } = useSafeAreaInsets();

	const scrollViewBackgroundColor = rules.colors.cardBackground;

	const styles = StyleSheet.create({
		container: {
			minHeight: topSafeInset + 70,
			marginBottom: 15,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			maxWidth: rules.spacing.maxWidthNarrow,
			width: '100%',
			alignSelf: 'center',
			flex: 1,
		},
		footer: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			marginTop: 'auto',
		},
		pageFooterHelpButton: {
			marginTop: 30,
		},
		graphicRow: {
			flexDirection: 'row',
			justifyContent: 'center',
			marginBottom: 30,
		},
		graphicRowItem: {
			height: 100,
			alignItems: 'stretch',
			flexDirection: 'row',
		},
	});

	return { styles, scrollViewBackgroundColor };
};
