import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function OnboardingStressGraphic(props: SvgProps) {
	const rules = useStyleRules();
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(600, 287);

	return (
		<View style={[wrapperStyles, { maxWidth: rules.spacing.modalMaxWidth, width: '140%' }]}>
			<Svg {...dimensionProps} {...props}>
				<Path fill="#F9F9F9" d="M0 0h600v287H0z" />
				<Path
					fill="#8D5EA2"
					d="m205.548 196.09-8.919 3.643s3.07 7.49-4.66 10.808a7.807 7.807 0 0 1-8.875-1.71 8.62 8.62 0 0 1-2.318-7.482c.487-3.113 2.882-6.738 9.32-8.14l-8.251-20.137-8.816 3.608 4.814 11.775s-11.056 8.259-5.481 21.889c5.336 13.04 18.273 11.287 23.275 9.235 3.857-1.565 16.52-7.824 9.911-23.489zM171.268 172.569l8.951-3.662-3.419-8.357-8.951 3.662 3.419 8.357z"
				/>
				<Path
					fill="#64B4E2"
					d="m385.699 15.614 6.25 37.512 6.273-2.933 9.691 30.889-5.942-43.146-6.464 3.019-9.808-25.341zM522.669 52.671l-35.047 14.761 4.305 5.424-27.808 16.576 40.6-15.765-4.433-5.59 22.383-15.406z"
				/>
				<Path
					fill="#7CC5AB"
					d="m271.849 202.706-5.873 37.506 6.614-.79-.701 32.325 7.89-42.744-6.814.81-1.116-27.107zM353.861 233.77l-12.456-15.518-2.029 2.841-12.274-11.684 13.772 18.148 2.092-2.926 10.895 9.139z"
				/>
				<Path
					fill="#0F2C5D"
					d="m454.174 33.886-10.047 28.38a4.874 4.874 0 0 0 2.42 5.986 4.862 4.862 0 0 0 6.421-1.967l14.921-26.456a3.818 3.818 0 0 0-.543-4.518 3.813 3.813 0 0 0-1.33-.912l-6.78-2.779a3.829 3.829 0 0 0-5.062 2.266zM447.231 86.918a7.334 7.334 0 0 1-11.338-.415 7.337 7.337 0 0 1 10.706-9.931 7.341 7.341 0 0 1 2.452 5.042 7.34 7.34 0 0 1-1.82 5.304z"
				/>
				<Path
					fill="#7CC5AB"
					d="M159.187 236.462a9.954 9.954 0 0 0-6.312-5.85c-2.459-.701-5.341-.424-7.261-2.112-.91-.77-1.449-1.942-2.251-2.851a9.136 9.136 0 0 0-5.758-2.698c-7.199-.971-13.804 3.954-16.433 9.719-2.944 1.95-5.935 3.939-8.163 6.683-2.227 2.744-3.592 6.405-2.767 9.835.601 2.52 2.312 4.663 2.929 7.183.57 2.313.193 4.718.37 7.076.177 2.359 1.187 4.941 3.391 5.804 2.721 1.064 6.089-.933 8.594.555 1.703 1.01 2.258 3.222 3.646 4.625 2.42 2.451 6.49 1.819 9.743.693a52.422 52.422 0 0 0 10.752-5.156 21.315 21.315 0 0 0 5.896-5.041 9.767 9.767 0 0 0 2.081-7.291c-.262-1.542-1.01-3.037-1.11-4.625-.169-2.783 1.665-5.241 2.659-7.854a12.077 12.077 0 0 0-.006-8.695z"
				/>
				<Path
					fill="#FFF"
					d="M130.891 249.257a2.908 2.908 0 0 1-1.79 2.691 2.91 2.91 0 0 1-3.8-3.798 2.908 2.908 0 0 1 5.368-.002c.148.352.222.729.222 1.109zM139.917 249.257a2.895 2.895 0 0 1-3.463 2.843 2.901 2.901 0 0 1-2.277-2.277 2.902 2.902 0 0 1 1.232-2.975 2.893 2.893 0 0 1 3.659.36c.544.544.849 1.281.849 2.049zM148.905 249.257a2.905 2.905 0 0 1-3.469 2.859 2.904 2.904 0 0 1-1.053-5.266 2.903 2.903 0 0 1 2.726-.272 2.893 2.893 0 0 1 1.796 2.679z"
				/>
				<Path
					fill="#8D5EA2"
					d="M496.881 171.851c-1.115-1.797-3.504-2.229-5.604-2.413a92.323 92.323 0 0 0-22.901.787c-2.625.432-6.484.196-6.746-2.505-.158-1.587 1.312-2.911 2.769-3.488 2.625-.984 5.473-.512 8.242-.577 2.769-.066 5.919-.984 7.034-3.515 1.693-3.855-2.703-7.868-6.863-8.445-4.161-.577-8.531.551-12.547-.695l-8.754.184a76.152 76.152 0 0 0-19.135 2.78c-1.745.485-3.793 1.416-3.937 3.213-.145 1.796 1.706 2.976 2.546 4.537 1.693 3.134-1.076 7.094-4.423 8.327-3.347 1.233-7.048.735-10.604 1.01-3.557.275-7.534 1.77-8.702 5.127-.931 2.623.184 5.692-.63 8.38-1.036 3.423-4.816 5.246-8.333 5.849-3.518.603-7.245.524-10.5 2.006a11.57 11.57 0 0 0-6.558 12.853 11.568 11.568 0 0 0 11.086 9.244c2.52.065 4.987-.695 7.507-.826a8.642 8.642 0 0 1 5.25 1.311 13.263 13.263 0 0 0 9.187 11.934c3.478.931 7.205.341 10.735 1.088 3.531.748 7.14 3.724 6.47 7.265-.472 2.518-2.979 4.367-3.189 6.937-.236 3.121 3.163 5.416 6.287 5.692 3.123.275 6.273-.669 9.41-.486 3.136.184 6.641 2.177 6.706 5.246.079 3.488-4.029 5.954-4.108 9.442-.065 2.924 2.743 5.088 5.473 6.15 2.73 1.062 5.788 1.6 7.94 3.58 2.153 1.98 2.52 6.216-.157 7.396 4.318-.157 6.864-6.425 3.858-9.546-1.85-1.928-4.974-2.453-6.417-4.708-1.588-2.518-.197-6.006 2.099-7.869 2.297-1.862 5.25-2.727 8.006-3.934 2.756-1.206 5.46-3.16 6.155-6.058 1.063-4.341-3.11-8.459-7.48-9.429-4.371-.97-8.885.118-13.348.393-4.462.276-9.554-.642-12.074-4.34l14.594-6.937a5.529 5.529 0 0 0 3.255-2.807c.801-2.504-2.061-4.55-4.449-5.639l-9.515-4.314a5.85 5.85 0 0 0-.368-1.692c5.762-2.373 12.285-1.455 18.466-.629 6.182.826 12.796 1.495 18.374-1.312 5.578-2.806 9.187-10.386 5.591-15.487-1.943-2.754-5.683-4.629-5.762-7.999-.065-2.78 2.625-4.892 5.25-5.784 2.625-.891 5.538-1.062 7.979-2.399 2.441-1.338 4.305-4.538 2.835-6.898z"
				/>
				<Path
					fill="#FFF"
					d="M422.431 197.363a5.918 5.918 0 1 0 0-11.838 5.918 5.918 0 0 0 0 11.838zM447.092 191.445a5.92 5.92 0 0 1-3.653 5.468 5.923 5.923 0 0 1-6.451-1.283 5.923 5.923 0 0 1 .897-9.107 5.924 5.924 0 0 1 7.474.736 5.92 5.92 0 0 1 1.733 4.186zM459.916 197.363a5.919 5.919 0 1 0 0-11.838 5.919 5.919 0 0 0 0 11.838z"
				/>
				<Path
					fill="#EEB157"
					d="M199.443 84.446c-3.691-9.14-11.881-15.961-20.911-20.104 5.754-5.145 6.467-14.57 2.372-21.085s-12.189-10.015-19.932-9.625-15.018 4.291-20.496 9.731a15.843 15.843 0 0 0-6.544-7.973 16.066 16.066 0 0 0-19.505 2.039 15.765 15.765 0 0 0-4.722 9.15l-.851.453A21.956 21.956 0 0 0 96.8 50.576c-1.391.905-2.249 1.586-3.8 3.143-17.141 17.207-18.367 59.707 0 69.42 3.636 1.923 6.677 1.786 10.1 1.548 10.636-.738 20.209-8.434 24.144-18.323a21.605 21.605 0 0 0 7.679 10.271 21.935 21.935 0 0 0 24.626.767 21.637 21.637 0 0 0 8.314-9.773c4.712 8.687 18.082 10.142 25.814 3.88 7.734-6.262 9.457-17.922 5.766-27.063z"
				/>
				<Path
					fill="#FFF"
					d="m111.799 66.486 8.029 14.151a2.636 2.636 0 0 0 4.772-2.19l-5.516-15.467a2.078 2.078 0 0 0-2.881-1.153l-3.497 1.796a2.07 2.07 0 0 0-.907 2.863zM130.995 88.093a3.956 3.956 0 0 1-1.955 3.938 3.953 3.953 0 0 1-5.722-2.163 3.954 3.954 0 0 1 1.139-4.245 3.962 3.962 0 0 1 5.024-.162 3.968 3.968 0 0 1 1.514 2.632zM145.055 59.501l.257 14.04a2.271 2.271 0 0 0 3.669 1.752c.464-.362.77-.888.855-1.47l2.189-13.997a1.804 1.804 0 0 0-.394-1.413 1.787 1.787 0 0 0-1.316-.647l-3.42-.103a1.79 1.79 0 0 0-1.318.52 1.781 1.781 0 0 0-.522 1.318zM150.706 83.811a3.418 3.418 0 0 1-3.101 2.194 3.422 3.422 0 0 1-3.464-3.992 3.42 3.42 0 0 1 4.59-2.623 3.424 3.424 0 0 1 2.201 3.104c.012.449-.065.897-.226 1.317z"
				/>
				<Path
					fill="#62A684"
					d="m287.688 188.723 14.571 39.186a6.769 6.769 0 0 0 7.86 4.277 6.783 6.783 0 0 0 5.101-7.822l-7.696-41.536a5.326 5.326 0 0 0-2.501-3.58 5.314 5.314 0 0 0-4.339-.501l-9.714 3.076a5.312 5.312 0 0 0-3.109 2.752 5.314 5.314 0 0 0-.173 4.148zM327.589 251.478a10.192 10.192 0 0 1-6.544 9.251 10.188 10.188 0 0 1-5.905.422 10.188 10.188 0 0 1-7.793-8.226 10.19 10.19 0 0 1 4.621-10.347 10.196 10.196 0 0 1 5.711-1.561 10.212 10.212 0 0 1 9.91 10.461z"
				/>
				<Path
					fill="#E54B33"
					d="m262.802 73.439 9.023 2.647s2.441-8.321 10.373-6.164a7.672 7.672 0 0 1 5.571 7.199 9.993 9.993 0 0 1-2.999 7.831c-2.34 2.326-6.442 3.857-12.178.892l-6.554 22.346 8.911 2.613 3.826-13.044s13.563.119 18.004-15.019c4.247-14.481-6.614-21.296-11.682-22.783-3.832-1.209-17.343-4.059-22.295 13.482zM273.733 115.443l-9.067-2.66-2.725 9.29 9.067 2.66 2.725-9.29z"
				/>
			</Svg>
		</View>
	);
}
