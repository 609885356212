import { APIResponse } from '@mobe/api/APIResponse';
import { mobeAuthenticatedAPI, mobeStandardSuccessProcessor } from '@mobe/api/mobeAPI';

class FileService {
	public async getBlobUrl(url: string) {
		try {
			const data = await mobeAuthenticatedAPI.getBlob(url);
			const file = new Blob([data], { type: 'application/pdf' });

			return file;
		} catch (error) {
			return APIResponse.error('UNKNOWN', null);
		}
	}

	//Dummy method implemented so that same method be used on web
	public async getFileByUrl(url: string) {
		return mobeStandardSuccessProcessor({ foo: 'bar' });
	}

	//Dummy method implemented so that same method be used on web
	public async getPDFData(url: string) {
		return APIResponse.error('UNKNOWN', null);
	}
}

const fileServiceWeb = new FileService();
export default fileServiceWeb;
