import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { noop, round } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { IInputTextProps } from '.';
import Row from '../layout/Row';
import { Text } from '../text';
import BaseInput from './BaseInput';
import BaseInputError from './BaseInputError';
import BaseInputLabel from './BaseInputLabel';

export interface IInputDualUnitNumeric extends IInputTextProps {
	primaryUnit: string;
	secondaryUnit: string;

	/**
	 * Factor for conversion from and to primary value.
	 * For minutes as a sub-unit of hours, this would be 60.
	 */
	conversionFactor: number;

	/**
	 * If this is true, the returned value will be converted to the secondary unit.
	 * E.g. with ft/in, it would return 60 inches rather than the default of 5 feet.
	 */
	convertValueToSecondaryUnit?: boolean;
	maxValue?: number;
}

function InputDualUnitNumeric(
	{
		convertValueToSecondaryUnit = false,
		label,
		hasError = false,
		errorMessage,
		collapseEmptyError = false,
		primaryUnit,
		secondaryUnit,
		conversionFactor,
		maxValue,
		value,
		onChangeText,
		onBlur = noop,
		onSubmitEditing = noop,
	}: IInputDualUnitNumeric,
	ref: any
) {
	const { t } = useTranslation();
	const styleHelpers = useStyleHelpers();
	const shouldRenderErrorComponent = (!collapseEmptyError && !errorMessage) || errorMessage;
	const [secondaryInputHasFocus, setSecondaryInputHasFocus] = React.useState(false);
	const secondaryRef: React.MutableRefObject<any> = React.useRef();

	let convertedValue: number | null = null;
	let primaryValue: number | null = null;
	let secondaryValue: number | null = null;

	if (value !== null) {
		convertedValue = convertValueToSecondaryUnit
			? (Number(value) || 0) / conversionFactor
			: Number(value);
		primaryValue = Math.floor(convertedValue);
		secondaryValue = Math.round((convertedValue - primaryValue) * conversionFactor);
	}

	function handleChange(combinedValues: number) {
		const unitConvertedNumber = convertValueToSecondaryUnit
			? combinedValues * conversionFactor
			: combinedValues;
		const roundedValue = round(unitConvertedNumber, 10);
		let limitedValue = roundedValue;

		if (maxValue && roundedValue > maxValue) {
			limitedValue = maxValue;
		}

		const newValue = limitedValue >= 0 ? limitedValue.toString() : '';

		if (onChangeText) {
			onChangeText(newValue);
		}
	}

	function handlePrimaryChange(value: string) {
		const combinedValues = Number(value) + (secondaryValue || 0) / conversionFactor;

		handleChange(combinedValues);
	}

	function handleSecondaryChange(value: string) {
		if (Number(value) >= conversionFactor) {
			return;
		}

		const combinedValues = (primaryValue || 0) + Number(value) / conversionFactor;

		handleChange(combinedValues);
	}

	function handleSecondaryFocus() {
		setSecondaryInputHasFocus(true);
	}

	function handleSecondaryBlur(event: NativeSyntheticEvent<TextInputFocusEventData>) {
		setSecondaryInputHasFocus(false);
		onBlur(event);
	}

	return (
		<>
			<BaseInputLabel color={hasError ? 'error' : 'regular'} style={styleHelpers.vr(1)}>
				{label}
			</BaseInputLabel>
			<Row>
				<Row.Item fill>
					<BaseInput
						ref={ref}
						type="integer"
						hasError={hasError}
						aria-label={label}
						inputRight={() => <Text weight="semiBold">{primaryUnit}</Text>}
						value={primaryValue !== null ? primaryValue.toString() : ''}
						onChangeText={handlePrimaryChange}
						onBlur={onBlur}
						enterKeyHint="next"
						onSubmitEditing={() => secondaryRef.current.focus()}
						maxLength={10}
					/>
				</Row.Item>
				<Row.Item fill>
					<BaseInput
						ref={secondaryRef}
						type="integer"
						hasError={hasError}
						aria-label={secondaryUnit}
						value={
							// visually clear input if value is 0 and has focus
							secondaryValue !== null && !(secondaryInputHasFocus && secondaryValue === 0)
								? secondaryValue.toString()
								: ''
						}
						onChangeText={handleSecondaryChange}
						onBlur={handleSecondaryBlur}
						onFocus={handleSecondaryFocus}
						inputRight={() => <Text weight="semiBold">{secondaryUnit}</Text>}
						maxLength={secondaryValue !== null ? secondaryValue.toString().length + 1 : 2}
						onSubmitEditing={onSubmitEditing}
					/>
				</Row.Item>
			</Row>

			{shouldRenderErrorComponent && <BaseInputError>{errorMessage}</BaseInputError>}
		</>
	);
}

export default React.forwardRef(InputDualUnitNumeric);
