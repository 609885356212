import * as LocalAuthentication from 'expo-local-authentication';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

export interface IBioAuthContentObj {
	settingsLabel: string;
	cancelLabel: string;
	promptMessage: string;
}

export const useAuthenticationTypeContent = () => {
	const { t } = useTranslation();

	const isIOS = Platform.OS === 'ios';

	return (authType: LocalAuthentication.AuthenticationType | null) => {
		switch (authType) {
			case LocalAuthentication.AuthenticationType.FINGERPRINT:
				return t('auth.biometric.fingerprint', { returnObjects: true }) as IBioAuthContentObj;
			case LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION:
				if (isIOS) {
					return t('auth.biometric.appleFace', { returnObjects: true }) as IBioAuthContentObj;
				} else {
					return t('auth.biometric.face', { returnObjects: true }) as IBioAuthContentObj;
				}
			case LocalAuthentication.AuthenticationType.IRIS:
				return t('auth.biometric.iris', { returnObjects: true }) as IBioAuthContentObj;
			default:
				return t('auth.biometric.generic', { returnObjects: true }) as IBioAuthContentObj;
		}
	};
};

export default function useBioAuthService() {
	const [isBioAuthAvailable, setIsBioAuthAvailable] = React.useState(false);

	const authenticationTypeContent = useAuthenticationTypeContent();

	/**
	 * Initialization
	 */
	const detectAbility = async () => {
		// NOTE: need both of these to be true to auth
		const hasHardware = await LocalAuthentication.hasHardwareAsync();
		const isEnrolled = await LocalAuthentication.isEnrolledAsync();

		setIsBioAuthAvailable(hasHardware && isEnrolled);
	};

	React.useEffect(() => {
		detectAbility();
	}, []);

	const getBioAuthMethodContent = async () => {
		const availableAuthenticationTypes =
			await LocalAuthentication.supportedAuthenticationTypesAsync();

		if (availableAuthenticationTypes.length !== 1) {
			return authenticationTypeContent(null);
		}

		return authenticationTypeContent(availableAuthenticationTypes[0]);
	};

	const attemptBioAuth = async () => {
		if (!isBioAuthAvailable) {
			return;
		}

		const content = await getBioAuthMethodContent();

		const bioAuthResponse = await LocalAuthentication.authenticateAsync({
			promptMessage: content.promptMessage,
			disableDeviceFallback: true,
			//cancelLabel fixes app crashing when bio auth activated
			cancelLabel: content.cancelLabel,
		});

		return bioAuthResponse.success;
	};

	return {
		isBioAuthAvailable,
		attemptBioAuth,
		getBioAuthMethodContent,
	};
}
