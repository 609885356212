import { compareAsc } from 'date-fns';
import qs from 'qs';
import { CoachType } from '../guides/guidesApi';
import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export enum GenderPreference {
	NoPreference = 'NoPreference',
	Female = 'Female',
	Male = 'Male',
}

export enum LanguagePreference {
	English = 'English',
	Spanish = 'Spanish',
}

export enum ContactOptions {
	Primary = 'Primary',
	Alternate = 'Alternate',
	Other = 'Other',
}

interface IAvailableTimeSlotResponse {
	utcDateTime: string;
	availableGuideDiKeys: string[];
}

export interface IAvailableTimeSlot extends IAvailableTimeSlotResponse {
	utcDateTimeObj: Date;
}

interface ITimeSlotsResponse {
	appointmentDurationInMinutes: number;
	availableDays: IAvailableTimeSlotResponse[];
}

export interface ITimeSlots {
	appointmentDurationInMinutes: number;
	availableDays: IAvailableTimeSlot[];
}

export interface IParticipantInterest {
	id: number;
	name: string;
}

export interface IMatchedGuide {
	guideDiKey: string;
	firstName: string;
	lastName: string;
	preferredName: string;
	bio: string;
	avatarUrl: string;
}

export interface IScheduledAppointmentResponse {
	isAppointmentScheduleSuccessful: boolean;
	appointmentUtcDateTime: string;
	appointmentConfirmationId: number | null;
}

interface IAppointmentResponse {
	avatarUrl: string | null;
	coachId: number;
	guideDIKey: string;
	preferredName: string;
	coachType: CoachType;
	coachTypeId: number;
	coachTypeDisplayName: string;
	isAssigned: boolean;
	confirmationId: string;
	appointmentStartDate: string;
	phoneNumber: string;
	durationInMinutes: number;
}

interface IAppointmentsResponse {
	appointments: IAppointmentResponse[];
}

function getTimeZoneOffsetInMinutes() {
	return -new Date(Date.now()).getTimezoneOffset();
}

function mapUtcDateTimeObject(timeSlots: ITimeSlotsResponse): ITimeSlots {
	return {
		appointmentDurationInMinutes: timeSlots.appointmentDurationInMinutes,
		availableDays: timeSlots.availableDays
			.map((slot) => ({
				utcDateTimeObj: new Date(slot.utcDateTime),
				...slot,
			}))
			.sort((a, b) => compareAsc(a.utcDateTimeObj, b.utcDateTimeObj)),
	};
}

export interface IGetFollowUpTimeSlots {
	guideDiKey: string;
	coachTypeId: number;
	desiredAppointmentMonthDateTime: string;
	confirmationId?: string;
}

export async function getFollowUpTimeSlots(params: IGetFollowUpTimeSlots) {
	try {
		const data = await mobeAuthenticatedAPI.get<ITimeSlotsResponse>(
			'appointment-types/follow-up/available-time-slots',
			{
				GuideDiKey: params.guideDiKey,
				CoachTypeId: params.coachTypeId,
				AppointmentDateTime: params.desiredAppointmentMonthDateTime,
				ConfirmationId: params.confirmationId,
			}
		);

		return mobeStandardSuccessProcessor<ITimeSlots>(mapUtcDateTimeObject(data));
	} catch (error) {
		return mobeStandardErrorProcessor<ITimeSlots>(error);
	}
}

export async function getInitialPharmacistTimeSlots(desiredAppointmentMonthDateTime: string) {
	try {
		const data = await mobeAuthenticatedAPI.get<ITimeSlotsResponse>(
			'guide-types/pharmacist/appointment-types/new-consultation/available-time-slots',
			{
				AppointmentDateTime: desiredAppointmentMonthDateTime,
			}
		);

		return mobeStandardSuccessProcessor<ITimeSlots>(mapUtcDateTimeObject(data));
	} catch (error) {
		return mobeStandardErrorProcessor<ITimeSlots>(error);
	}
}

export interface IGetInitialGuideTimeSlots {
	genderPreference: GenderPreference;
	languagePreference: LanguagePreference;
	desiredAppointmentMonthDateTime: string;
}

/**
 * Returns time slots for guides who match preferred gender and language.
 * This is only ever used during guide selection.
 */
export async function getInitialGuideTimeSlots(params: IGetInitialGuideTimeSlots) {
	try {
		const data = await mobeAuthenticatedAPI.get<ITimeSlotsResponse>(
			'guide-types/guide/appointment-types/new-consultation/available-time-slots',
			{
				GuideGenderPreference: params.genderPreference,
				Language: params.languagePreference,
				AppointmentDateTime: params.desiredAppointmentMonthDateTime,
			}
		);

		return mobeStandardSuccessProcessor<ITimeSlots>(mapUtcDateTimeObject(data));
	} catch (error) {
		return mobeStandardErrorProcessor<ITimeSlots>(error);
	}
}

export async function getAllInterests() {
	try {
		const data = await mobeAuthenticatedAPI.get<IParticipantInterest[]>('interests/all');

		return mobeStandardSuccessProcessor<IParticipantInterest[]>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IParticipantInterest[]>(error);
	}
}

export interface IGetAvailableGuides {
	guideDiKeys: string[];
	preferredGender: GenderPreference;
	preferredLanguage: LanguagePreference;
	interestIds: number[];
}

/**
 * Returns a ranked list of guides to conclude the guide matching sequence.
 */
export async function getAvailableGuides(params: IGetAvailableGuides) {
	try {
		const data = await mobeAuthenticatedAPI.get<IMatchedGuide[]>(
			'schedulings/new-consultation/available-guides',
			{
				AvailableGuideDiKeys: params.guideDiKeys,
				GuideGender: params.preferredGender,
				Language: params.preferredLanguage,
				InterestIds: params.interestIds,
			},
			{
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: 'repeat' });
				},
			}
		);

		return mobeStandardSuccessProcessor<IMatchedGuide[]>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IMatchedGuide[]>(error);
	}
}

// Different contexts/places in application you can schedule
export enum SchedulingContext {
	AppGuideMatchForm = 'app.guideMatchForm',
	AppGuideFollowUpForm = 'app.guideFollowUpForm',
	ConnectGuideMatchForm = 'connect.guideMatchForm',
	ConnectGuideFollowUpForm = 'connect.guideFollowUpForm',
	SchedulingForm = 'schedulingForm',
}

export interface IScheduleInitialAppointment {
	guideDiKey: string;
	appointmentDateTime: string;
	interestIds: number[];
	gender: GenderPreference;
	language: LanguagePreference;
	email?: string;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

export async function scheduleInitialAppointment(params: IScheduleInitialAppointment) {
	try {
		const data = await mobeAuthenticatedAPI.post<IScheduledAppointmentResponse>(
			'schedulings/new-consultation/schedule-appointment-with-guide',
			{
				...params,
				timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
			}
		);

		return mobeStandardSuccessProcessor<IScheduledAppointmentResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IScheduledAppointmentResponse>(error);
	}
}

export interface IScheduleFollowUp {
	guideDiKey: string;
	appointmentDateTime: string;
	coachTypeId: number;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

// NOTE: error format for this endpoint is not consistent with the structure of the others for this API
// NOTE: if we need specific information about how the endpoint fails, this will need to be resolved
export async function scheduleFollowUp(params: IScheduleFollowUp) {
	try {
		const data = await mobeAuthenticatedAPI.post<IScheduledAppointmentResponse>(
			'schedulings/follow-up/schedule-appointment',
			{
				...params,
				timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
			}
		);

		return mobeStandardSuccessProcessor<IScheduledAppointmentResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IScheduledAppointmentResponse>(error);
	}
}

export interface IScheduleInitialAppointmentWithPharmacist {
	guideDiKey: string;
	appointmentDateTime: string;
	phoneNumber: string;
	schedulingLocation: SchedulingContext;
}

export async function scheduleInitialAppointmentWithPharmacist(
	params: IScheduleInitialAppointmentWithPharmacist
) {
	try {
		const data = await mobeAuthenticatedAPI.post<IScheduledAppointmentResponse>(
			'schedulings/new-consultation/schedule-appointment-with-pharmacist-guide',
			{
				...params,
				timeZoneOffsetInMinutes: getTimeZoneOffsetInMinutes(),
			}
		);

		return mobeStandardSuccessProcessor<IScheduledAppointmentResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IScheduledAppointmentResponse>(error);
	}
}

export async function deleteAppointment(confirmationId: string) {
	try {
		await mobeAuthenticatedAPI.delete(`appointments/${confirmationId}`);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

interface IUpdatedAppointmentPayload {
	newPhoneNumber?: string;
	startDate?: string;
	timeZoneOffsetInMinutes?: number;
	email?: string;
	isPhoneNumberTemporary?: boolean;
}

export interface IUpdateAppointment {
	confirmationId: string;
	updatedAppointmentPayload: IUpdatedAppointmentPayload;
}

export async function updateAppointment(params: IUpdateAppointment) {
	try {
		await mobeAuthenticatedAPI.patch<unknown>(
			`appointments/${params.confirmationId}`,
			params.updatedAppointmentPayload
		);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function getAppointments() {
	const { appointments } = await mobeAuthenticatedAPI.get<IAppointmentsResponse>('appointments');

	return mobeStandardSuccessProcessor<IAppointmentResponse[]>(appointments);
}

export interface ITrackConsumerSchedulingActivity {
	trackingSessionIdentifier: string;
	trackingSessionPageAccessedDate: string;
	firstName: string | null;
	preferredName: string | null;
	lastName: string | null;
	email: string | null;
	gender: string | null;
	hasCoach: boolean | null;
	isRegistered: boolean | null;
	deviceUsed: string | null;
	preferredLanguage: string | null;
	preferredGuideGender: string | null;
	interests: string | null; // comma sep string of names/labels... i.e. 'Overall nutrition and mindful eating,Motivation and mindset'
	selectedTimeSlot: string | null;
	selectedTimeZoneOffsetInMinutes: number | null;
	selectedGuideDIKey: string | null;
	droppedOffAtStep: string | null;
	hasUserCompletedSchedulingFlow: boolean | null;
	isAppointmentSuccessfullyScheduled: boolean | null;
	isSpineScheduling: boolean | null;
}

export async function trackConsumerSchedulingActivity(params: ITrackConsumerSchedulingActivity) {
	try {
		const data = await mobeAuthenticatedAPI.post('scheduling-activity-tracks', {
			...params,
		});

		return mobeStandardSuccessProcessor(data);
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}
