import { IActionStep } from '@mobe/api/goals/goalsService';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';
import TextButton from '../textButton/TextButton';
import { useToast } from './Toast';

export default function useActionStepToast() {
	const toast = useToast();
	const { t } = useTranslation();

	return (actionStep: IActionStep, onUndoPress: () => void) => {
		const stepsComplete = actionStep.subStepsComplete + 1;
		const message =
			stepsComplete >= actionStep.subStepsTotal
				? t('plan.actionStepToast')
				: t('plan.actionStepSubStepToast', {
						count: stepsComplete,
						total: actionStep.subStepsTotal,
				  });

		return toast.show({
			toastLeft: <Icon name="checkmark" color="textInverted" />,
			toastRight: (dismissToast) => (
				<TextButton
					title={t('genericUndo').toUpperCase()}
					onPress={() => {
						onUndoPress();
						dismissToast();
					}}
					inverted
				/>
			),
			message,
			delayDuration: 0,
		});
	};
}
