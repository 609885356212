import { minutesToSeconds } from 'date-fns';
import * as React from 'react';
import { Platform } from 'react-native';
import { POST_MESSAGE_STRING_PATH } from './webMessagingUtils';

export const TIME_CHANGE_CALLBACK_DURATION_IN_SECONDS = minutesToSeconds(5);

// Add wistia script tag
// https://docs.wistia.com/docs/javascript-player-api#get-started
export const WISTIA_JS_EXTERNAL_SRC_URL = 'https://fast.wistia.net/assets/external/E-v1.js';
const WISTIA_SCRIPT_ID = 'wistia-dynamic-script';

// Use this hook for web to add wistia scripts to to header and bootstrap common wistia event handlers
export function useSetupWistiaWebSdkScriptsAndHandlers() {
	React.useEffect(() => {
		if (Platform.OS !== 'web') {
			return;
		}

		const wistiaScript = document.createElement('script');
		wistiaScript.setAttribute('id', WISTIA_SCRIPT_ID);
		wistiaScript.setAttribute('src', WISTIA_JS_EXTERNAL_SRC_URL);
		wistiaScript.setAttribute('async', 'true');
		document.head.appendChild(wistiaScript);

		// Keep track of the last time the callback was triggered
		let lastCallbackTime = -1;

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window._wq = window._wq || [];

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		_wq.push({
			id: '_all',
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			onReady: function (video) {
				// Bind to timechange, time event in seconds
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				video.bind('timechange', function (time) {
					if (time - lastCallbackTime >= TIME_CHANGE_CALLBACK_DURATION_IN_SECONDS) {
						// Update the last callback time
						lastCallbackTime = time;

						window.parent.postMessage({ message: 'watching' }, '*');
					}
				});

				// Bind to play event
				video.bind('play', () => {
					window.parent.postMessage({ message: 'play' }, '*');
				});
			},
		});

		return () => {
			if (Platform.OS !== 'web') {
				return;
			}

			const wistiaScript = document.head.querySelector(`#${WISTIA_SCRIPT_ID}`);

			if (wistiaScript) {
				wistiaScript?.remove();
			}
		};
	}, []);
}

// Add script to head
export function programmaticallyAddWistiaWebSdkScriptsToHead() {
	return `
const wistiaScript = document.createElement('script');
wistiaScript.setAttribute('src', '${WISTIA_JS_EXTERNAL_SRC_URL}');
wistiaScript.setAttribute('async', 'true');
document.head.appendChild(wistiaScript);
	`;
}

// Add just script
export function addWistiaWebSdkVideoPlayerScripts() {
	return `<script src="${WISTIA_JS_EXTERNAL_SRC_URL}" async></script>`;
}

export const WISTIA_WEB_SDK_INJECTED = `
${programmaticallyAddWistiaWebSdkScriptsToHead()}
${addWistiaWebEventHandlers()}
`;

function createMessageArg(message: string) {
	return Platform.OS === 'web' ? `{ message: "${message}" }, "*"` : `"${message}"`;
}

// Add wistia event handlers to all videos on page, this will usually only be one
export function addWistiaWebEventHandlers() {
	return `
// Keep track of the last time the callback was triggered
let lastCallbackTime = -1;

window._wq = window._wq || [];
_wq.push({ id: "_all", onReady: function(video) {
	// Bind to timechange event in seconds
	video.bind("timechange", function(time) {
		if (time - lastCallbackTime >= ${TIME_CHANGE_CALLBACK_DURATION_IN_SECONDS}) {
			// Update the last callback time
			lastCallbackTime = time;

			${POST_MESSAGE_STRING_PATH}(${createMessageArg('watching')});
		}
	});

	// Bind to play event
	video.bind("play", () => {
		${POST_MESSAGE_STRING_PATH}(${createMessageArg('play')});
	});
}});
	`;
}
