import { addWeeks, sub } from 'date-fns';
import { uniq } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useSecureStoreState, { SecureStoreState } from './useSecureStoreState';

interface IState {
	hasViewedAppIntro: SecureStoreState<boolean>;
	savedUsername: SecureStoreState<string>;
	savedPassword: SecureStoreState<string>;
	loginCount: SecureStoreState<number>;
	hasSeenBioAuthPrompt: SecureStoreState<boolean>;
	hasSeenEducationalNotice: SecureStoreState<boolean>;
	hasSeenWelcomeVideo: SecureStoreState<boolean>;
	hasDismissedGuideMatch: SecureStoreState<boolean>;
	hasDismissedPharmacistMatch: SecureStoreState<boolean>;
	hasDismissedTrackerCTAFromHome: SecureStoreState<boolean>;
	hasDismissedTrackerCTAFromTrack: SecureStoreState<boolean>;
	hasDismissedTrackerDataLagMessage: SecureStoreState<boolean>;
	loginAlertDismissedUntilDateString: SecureStoreState<string>;
	enableBiometricLogin: SecureStoreState<boolean>;
	rememberMe: SecureStoreState<boolean>;
	dismissedContentModuleIds: SecureStoreState<number[]>;
}

const PersistentStateContext = createContext<IState | undefined>({} as IState);

export function PersistentStateProvider({ children }: { children: React.ReactNode }) {
	const [allStateItemsLoaded, setAllStateItemsLoaded] = useState(false);
	const hasViewedAppIntro = useSecureStoreState<boolean>('hasViewedAppIntro');
	const savedUsername = useSecureStoreState<string>('authentication.credentials.username');
	const savedPassword = useSecureStoreState<string>('authentication.credentials.password');
	const loginCount = useSecureStoreState<number>('persistent.loginCount');
	const hasSeenBioAuthPrompt = useSecureStoreState<boolean>('persistent.hasSeenBioAuthPrompt');
	const hasSeenEducationalNotice = useSecureStoreState<boolean>(
		'persistent.hasSeenEducationalNotice'
	);
	const hasSeenWelcomeVideo = useSecureStoreState<boolean>('persistent.hasSeenWelcomeVideo');
	const hasDismissedGuideMatch = useSecureStoreState<boolean>('persistent.hasDismissedGuideMatch');
	const hasDismissedPharmacistMatch = useSecureStoreState<boolean>(
		'persistent.hasDismissedPharmacistMatch'
	);
	const hasDismissedTrackerCTAFromHome = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerCTAFromHome'
	);
	const hasDismissedTrackerCTAFromTrack = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerCTAFromTrack'
	);
	const hasDismissedTrackerDataLagMessage = useSecureStoreState<boolean>(
		'persistent.hasDismissedTrackerDataLagMessage'
	);
	const loginAlertDismissedUntilDateString = useSecureStoreState<string>(
		'loginAlertDismissedUntilDateString'
	);
	const enableBiometricLogin = useSecureStoreState<boolean>('settings.enableBiometricLogin');
	const rememberMe = useSecureStoreState<boolean>('settings.rememberMe');
	const dismissedContentModuleIds = useSecureStoreState<number[]>(
		'persistent.dismissedContentModuleIds'
	);

	// delay until all persistent state items are loaded
	useEffect(() => {
		Promise.all([
			hasViewedAppIntro.loadValue(),
			savedUsername.loadValue(),
			savedPassword.loadValue(),
			loginCount.loadValue(),
			hasSeenBioAuthPrompt.loadValue(),
			hasSeenEducationalNotice.loadValue(),
			hasSeenWelcomeVideo.loadValue(),
			hasDismissedGuideMatch.loadValue(),
			hasDismissedPharmacistMatch.loadValue(),
			hasDismissedTrackerCTAFromHome.loadValue(),
			hasDismissedTrackerCTAFromTrack.loadValue(),
			hasDismissedTrackerDataLagMessage.loadValue(),
			loginAlertDismissedUntilDateString.loadValue(),
			enableBiometricLogin.loadValue(),
			rememberMe.loadValue(),
			dismissedContentModuleIds.loadValue(),
		]).then(() => {
			setAllStateItemsLoaded(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const contextValue: IState = {
		hasViewedAppIntro,
		savedUsername,
		savedPassword,
		loginCount,
		hasSeenBioAuthPrompt,
		hasSeenEducationalNotice,
		hasSeenWelcomeVideo,
		hasDismissedGuideMatch,
		hasDismissedPharmacistMatch,
		hasDismissedTrackerCTAFromHome,
		hasDismissedTrackerCTAFromTrack,
		hasDismissedTrackerDataLagMessage,
		loginAlertDismissedUntilDateString,
		enableBiometricLogin,
		rememberMe,
		dismissedContentModuleIds,
	};

	if (!allStateItemsLoaded) {
		return null;
	}

	return (
		<PersistentStateContext.Provider value={contextValue}>
			{children}
		</PersistentStateContext.Provider>
	);
}

export function usePersistentState() {
	const context = useContext(PersistentStateContext);

	if (!context) {
		throw new Error('usePersistentState must be used within a PersistentStateProvider');
	}

	// Calculate dismissed until date based on value stored in secure store
	const loginAlertDismissedUntilDate = () => {
		let date = sub(Date.now(), { weeks: 1 }); // Set date back in time by default so alert initially shows

		if (typeof context.loginAlertDismissedUntilDateString.value === 'string') {
			date = new Date(context.loginAlertDismissedUntilDateString.value); // Set date to what was stored in secure store
		}

		return date;
	};

	return {
		hasViewedAppIntro: Boolean(context.hasViewedAppIntro.value),
		setHasViewedAppIntro: (value: boolean) => {
			context.hasViewedAppIntro.setValue(value);
		},
		hasEverLoggedIn: Number(context.loginCount.value) > 0,
		isFirstLogIn: Number(context.loginCount.value) === 1,
		hasSeenEducationalNotice: Boolean(context.hasSeenEducationalNotice.value),
		setHasSeenEducationalNotice: (value: boolean) => {
			context.hasSeenEducationalNotice.setValue(value);
		},
		hasSeenWelcomeVideo: Boolean(context.hasSeenWelcomeVideo.value),
		setHasSeenWelcomeVideo: (value: boolean) => {
			context.hasSeenWelcomeVideo.setValue(value);
		},
		savedUsername: context.savedUsername.value || '',
		setSavedUsername: (value: string) => {
			context.savedUsername.setValue(value);
		},
		savedPassword: context.savedPassword.value || '',
		setSavedPassword: (value: string) => {
			context.savedPassword.setValue(value);
		},
		loginCount: Number(context.loginCount.value) || 0,
		setLoginCount: (value: number) => {
			context.loginCount.setValue(value);
		},
		hasSeenBioAuthPrompt: Boolean(context.hasSeenBioAuthPrompt.value),
		setHasSeenBioAuthPrompt: (value: boolean) => {
			context.hasSeenBioAuthPrompt.setValue(value);
		},
		hasDismissedGuideMatch: Boolean(context.hasDismissedGuideMatch.value),
		setHasDismissedGuideMatch: (value: boolean) => {
			context.hasDismissedGuideMatch.setValue(value);
		},
		hasDismissedPharmacistMatch: Boolean(context.hasDismissedPharmacistMatch.value),
		setHasDismissedPharmacistMatch: (value: boolean) => {
			context.hasDismissedPharmacistMatch.setValue(value);
		},
		hasDismissedTrackerCTAFromHome: Boolean(context.hasDismissedTrackerCTAFromHome.value),
		setHasDismissedTrackerCTAFromHome: (value: boolean) => {
			context.hasDismissedTrackerCTAFromHome.setValue(value);
		},
		hasDismissedTrackerCTAFromTrack: Boolean(context.hasDismissedTrackerCTAFromTrack.value),
		setHasDismissedTrackerCTAFromTrack: (value: boolean) => {
			context.hasDismissedTrackerCTAFromTrack.setValue(value);
		},
		hasDismissedTrackerDataLagMessage: Boolean(context.hasDismissedTrackerDataLagMessage.value),
		setHasDismissedTrackerDataLagMessage: (value: boolean) => {
			context.hasDismissedTrackerDataLagMessage.setValue(value);
		},
		enableBiometricLogin: context.enableBiometricLogin.value || false,
		setEnableBiometricLogin: (value: boolean) => {
			context.enableBiometricLogin.setValue(value);
		},
		rememberMe: context.rememberMe.value || false,
		setRememberMe: (value: boolean) => {
			context.rememberMe.setValue(value);
		},
		loginAlertDismissedUntilDate: loginAlertDismissedUntilDate(),
		toggleEnableBiometricLogin: () => {
			// Automatically turn on rememberMe when bio auth is toggled on
			if (!context.rememberMe.value && !context.enableBiometricLogin.value) {
				context.rememberMe.setValue(true);
			}
			context.enableBiometricLogin.setValue(!context.enableBiometricLogin.value);
		},
		toggleRememberMe: () => {
			// Automatically turn off bio auth when remember me is toggled off
			if (context.rememberMe.value && context.enableBiometricLogin.value) {
				context.enableBiometricLogin.setValue(false);
			}
			context.rememberMe.setValue(!context.rememberMe.value);
		},
		clearAll: () => {
			Object.keys(context).forEach((key) => context[key as keyof IState].removeItem());
		},
		resetSavedLoginCredentials: () => {
			context.savedUsername.setValue('');
			context.savedPassword.setValue('');
			context.rememberMe.setValue(false);
			context.enableBiometricLogin.setValue(false);
		},
		dismissLoginAlert: () => {
			const dismissUntilDateString = addWeeks(Date.now(), 1).toString();
			context.loginAlertDismissedUntilDateString.setValue(dismissUntilDateString);
		},
		resetLoginAlert: () => {
			context.loginAlertDismissedUntilDateString.removeItem();
		},
		dismissedContentModuleIds: context.dismissedContentModuleIds.value || [],
		setDismissedContentModuleIds: (value: number) => {
			context.dismissedContentModuleIds.setValue(
				context.dismissedContentModuleIds.value
					? uniq([...context.dismissedContentModuleIds.value, value])
					: [value]
			);
		},
		resetDismissedContentModuleIds: () => {
			context.dismissedContentModuleIds.setValue([]);
		},
	};
}
