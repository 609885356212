import { Button } from '@mobe/components/button';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import FetchingIndicator from '@mobe/components/fetchingIndicator/FetchingIndicator';
import InputSelect from '@mobe/components/input/InputSelect';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useRemoteConfigData from '@mobe/utils/useRemoteConfigQuery';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ApptGuideSelector from '../../../components/ApptGuideSelector';
import ScheduleApptView from '../../../components/ScheduleApptView';
import useGuideTypeOptions, { GuideTypeValue } from '../../../useGuideTypeOptions';
import { useFollowUpContext } from '../FollowUpProvider';
import {
	FollowUpScheduleScreenNavigationProp,
	FollowUpScheduleScreenRouteProp,
} from '../FollowUpStackScreen';

interface IFollowUpScheduleScreenProps {
	navigation: FollowUpScheduleScreenNavigationProp;
	route: FollowUpScheduleScreenRouteProp;
}

export default function FollowUpScheduleScreen({ navigation }: IFollowUpScheduleScreenProps) {
	const { vr } = useStyleHelpers();
	const guideFollowUpContext = useFollowUpContext();
	const guideTypeOptions = useGuideTypeOptions();
	const { t } = useTranslation();
	const { scheduleCallVariant } = useRemoteConfigData();
	const [submitButtonRef, setFocusToSubmitButton] = useAccessibilityFocus();

	const canProgress =
		!guideFollowUpContext.apptDateControl.fieldState.error &&
		guideFollowUpContext.apptDateControl.fieldState.isDirty;

	useFocusEffect(
		React.useCallback(() => {
			guideFollowUpContext.setCurrentStep(0);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter={canProgress}
			footer={
				<Button
					ref={submitButtonRef}
					title={t('appointments.followUp.nextStepButton')}
					disabled={!canProgress}
					onPress={() => navigation.navigate('FOLLOW_UP_CONTACT_SCREEN')}
				/>
			}
		>
			{guideFollowUpContext.selectedGuide ? (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(5)}
				>
					{t('appointments.followUp.scheduleStep.description')}
				</Text>
			) : (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(6)}
				>
					{t('appointments.pharmacistMatch.scheduleStep.description')}
				</Text>
			)}
			{guideFollowUpContext.selectedGuide && (
				<View style={vr(4)}>
					{scheduleCallVariant ? (
						<ApptGuideSelector
							allowCoachToggle={guideFollowUpContext.allowCoachToggle}
							value={guideFollowUpContext.selectedGuide.coachType}
							onSelect={guideFollowUpContext.handleCoachTypeSelection}
						/>
					) : (
						<InputSelect
							label={t('appointments.scheduleGuideTypeSelect.label')}
							items={guideTypeOptions}
							value={guideFollowUpContext.coachTypeControl.field.value as GuideTypeValue}
							onSelect={guideFollowUpContext.handleCoachTypeSelection}
						/>
					)}
				</View>
			)}

			<DeferredLoadingIndicator isLoading={guideFollowUpContext.apptDatesQuery.isLoading}>
				{guideFollowUpContext.apptDatesQuery.status === 'success' && (
					<FetchingIndicator
						isFetching={guideFollowUpContext.apptDatesQuery.isFetching}
						rerenderOnFetch
					>
						<ScheduleApptView
							apptDates={guideFollowUpContext.apptDatesQuery.data.availableDays}
							apptDurationMinutes={guideFollowUpContext.getApptDurationInMinutes()}
							selectedApptDate={guideFollowUpContext.apptDateControl.field.value}
							onApptSelection={guideFollowUpContext.apptDateControl.field.onChange}
							onTimeSelection={(availableGuideDiKeys) => {
								guideFollowUpContext.handleTimeSelection(availableGuideDiKeys);
								setFocusToSubmitButton();
							}}
							onRetry={guideFollowUpContext.handleNoTimeSlotsRetryPress}
						/>
					</FetchingIndicator>
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	);
}
