import React from 'react';
import { Platform } from 'react-native';
import Svg, { G, Mask, Path, Rect, SvgProps } from 'react-native-svg';

export default function CallsBannerGraphic(props: SvgProps) {
	return (
		<Svg width="100%" height="84" fill="none" {...props}>
			<Mask id="mask0_2524_32685">
				<Rect width="100%" height="84" fill="#F8F0F0" />
			</Mask>
			<G mask="url(#mask0_2524_32685)">
				<Rect width="100%" height="84" fill="#41B5E6" />
				<Path
					scale={Platform.OS !== 'web' ? 1 : 1.5}
					opacity="0.1"
					d="M637.226 -151.935C583.412 -153.469 532.903 -127.72 494.223 -95.0813C455.544 -62.4423 425.747 -22.7614 390.665 12.9232C374.923 28.7999 357.764 44.223 336.041 53.1441C293.628 70.5545 243.322 59.9484 199.829 44.6982C156.337 29.4479 113.204 9.53192 66.3387 8.75429C38.777 8.47632 11.5552 14.613 -13 26.6398V164H712V-133.445C688.59 -144.007 663.47 -151.179 637.226 -151.935Z"
					fill="white"
				/>
			</G>
		</Svg>
	);
}
