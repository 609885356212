import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export interface IEnvelopeGraphic extends SvgProps {
	customWrapperStyles?: StyleProp<ViewStyle>;
	variant?: 'default' | 'success';
}

export default function EnvelopeGraphicError() {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(320, 320);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps}>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.0005 268.886V96.0001L305 96.0001V268.886L156.314 268.886L15.0005 268.886Z"
					fill="#E8AC4B"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M305 270L162.19 141.738L14.9999 270V96L305 96V270Z"
					fill="#FEC14D"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M305 96L158.751 199.603L15.0004 96L305 96Z"
					fill="#FED586"
				/>
				<Path
					d="M160.138 143.202C185.579 143.202 206.203 122.578 206.203 97.1373C206.203 71.6963 185.579 51.0723 160.138 51.0723C134.697 51.0723 114.073 71.6963 114.073 97.1373C114.073 122.578 134.697 143.202 160.138 143.202Z"
					fill="#F77C0C"
				/>
				<Path
					d="M160.138 145.273C140.669 145.273 123.117 133.546 115.666 115.559C108.215 97.5718 112.333 76.8675 126.1 63.1005C139.866 49.3335 160.571 45.215 178.558 52.6654C196.545 60.1159 208.273 77.6681 208.273 97.1374C208.273 123.722 186.723 145.273 160.138 145.273ZM160.138 53.1434C135.838 53.1434 116.138 72.8429 116.138 97.1434C116.138 121.444 135.838 141.143 160.138 141.143C184.439 141.143 204.138 121.444 204.138 97.1434C204.14 85.4755 199.506 74.2848 191.257 66.0332C183.008 57.7816 171.818 53.145 160.15 53.1434H160.138Z"
					fill="white"
				/>
				<Path
					d="M160.564 106.387V104.852C160.564 95.6461 176 95.0616 176 83.2249C176 74.0186 166.978 70 159.436 70C151.612 70 144 74.8954 144 83.7364C144 88.3395 146.749 92.212 151.401 92.212C154.855 92.212 156.899 89.8739 156.899 87.0244C156.899 83.8825 154.573 82.0559 152.247 82.0559C150.696 82.0559 149.427 82.7135 148.3 83.8095C148.793 78.2564 152.035 75.4069 157.463 75.4069C161.269 75.4069 165.991 77.9642 165.991 83.0788C165.991 91.3352 156.053 94.6232 156.053 102.076V106.387H160.564ZM152.952 115.593C152.952 118.662 155.207 121 158.167 121C161.128 121 163.383 118.662 163.383 115.593C163.383 112.524 161.128 110.186 158.167 110.186C155.207 110.186 152.952 112.524 152.952 115.593Z"
					fill="white"
				/>
			</Svg>
		</View>
	);
}
