import { isEmpty } from 'lodash';
import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export interface IIncentiveStep {
	dateCompleted: string | null; // date string
	label: string | null;
}

export interface IIncentive {
	dateCompleted: string | null; // date string
	title: string;
	description: string;
	url: string;
	steps: IIncentiveStep[];
}

export async function getIncentive() {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IIncentive | string>(`incentive-program`);

				// Server returns empty body in the scenario that PPT isn't enrolled in incentive program
				if (isEmpty(data) || typeof data === 'string') {
					return mobeStandardSuccessProcessor<null>(null);
				}

				return mobeStandardSuccessProcessor<IIncentive>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IIncentive>(error);
			}
		})()
	);
}
