import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { getIncentive } from './incentiveService';

const incentiveQueryKey = 'Incentive';

export function useIncentiveQuery() {
	const auth = useAuth();

	return useQuery([incentiveQueryKey], async () => await getIncentive(), {
		enabled: auth.isAuthenticated,
		staleTime: Infinity,
		select: (data) => (isEmpty(data) ? undefined : data),
	});
}
