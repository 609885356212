import { useTrackersAddEntryMutation } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import Heading from '@mobe/components/heading/Heading';
import { Value } from '@mobe/components/input/InputRadioGraphic';
import MoodPicker from '@mobe/components/moodPicker/MoodPicker';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import * as HomeAnalyticsEvents from '../analyticsEvents';
import MoodInfoBox from './MoodInfoBox';

export default function HomeMoodPicker({ style }: { style?: StyleProp<ViewStyle> }) {
	const { t } = useTranslation();
	const styles = useStyles();
	const trackersAddEntryMutation = useTrackersAddEntryMutation();
	const [moodTitle, setMoodTitle] = React.useState(t('home.moodTitle'));
	const [moodLevel, setMoodLevel] = React.useState<number>(0);
	const [isMoodSelected, setIsMoodSelected] = React.useState<boolean>(false);
	const { constrainText } = useStyleHelpers();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const moodTrackerId = getTrackerIdFromAbbreviation(TrackerAbbreviation.Mood);

	const onSelectMoodChange = (value: Value) => {
		HomeAnalyticsEvents.moodTrackerPress();
		setIsMoodSelected(true);

		trackersAddEntryMutation
			.mutateAsync({
				trackerEntry: {
					activityDateTime: new Date(Date.now()).toISOString(),
					value: String(value),
					trackerTypeId: moodTrackerId,
				},
			})
			.then(() => {
				setMoodLevel(value as number);
				setMoodTitle(t('home.moodTitleSuccess'));
			})
			.catch(() => setMoodTitle(t('home.moodTitleError')));
	};

	useFocusEffect(
		React.useCallback(() => {
			setMoodLevel(0);
			setMoodTitle(t('home.moodTitle'));
			setIsMoodSelected(false);
		}, [])
	);

	return (
		<View style={[styles.box, style]}>
			<View style={styles.boxInner}>
				<View style={styles.inputWrapper}>
					<Heading align="center" style={constrainText(300)} level="h3">
						{moodTitle}
					</Heading>
					<MoodPicker
						value={moodLevel}
						onSelect={(value) => onSelectMoodChange(value)}
						label=""
						isDisabled={isMoodSelected}
					/>
				</View>
				<MoodInfoBox />
			</View>
		</View>
	);
}

function useStyles() {
	const styleRules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		box: {
			width: '100%',
			alignSelf: 'center',
			alignItems: 'center',
			borderRadius: styleRules.borderRadius,
			backgroundColor: styleRules.colors.cardBackground,
			paddingTop: 24,
			paddingBottom: 10,
			...cardShadow,
		},
		boxInner: {
			width: '100%',
			maxWidth: 400,
		},
		inputWrapper: {
			paddingHorizontal: 20,
		},
	});
}
