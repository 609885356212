import { useCoachesQuery } from '@mobe/api/guides/guidesApiHooks';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ChatDirectMessageScreenNavigationProp,
	ChatDirectMessageScreenRouteProp,
} from '../ChatStackScreen';
import Chat from '../components/Chat';

interface IChatDirectMessageScreenProps {
	route: ChatDirectMessageScreenRouteProp;
	navigation: ChatDirectMessageScreenNavigationProp;
}

export default function ChatDirectMessageScreen({
	navigation,
	route,
}: IChatDirectMessageScreenProps) {
	return <Chat channelId={route.params.channelId} />;
}

export function useChatDirectMessageScreenOptions() {
	const coachesQuery = useCoachesQuery();
	const { t } = useTranslation();
	const subScreenOptions = useSubScreenStyleProps();

	return ({ route }: IChatDirectMessageScreenProps): StackNavigationOptions => {
		const guideThatMatchesChatChannelId = coachesQuery.data?.find(
			(coach) => coach?.chatChannelId === route.params.channelId
		);

		return {
			title: t('chat.directMessageScreen.screenTitle', {
				guideName: guideThatMatchesChatChannelId?.preferredName || t('chat.defaultGuideName'),
			}),
			...subScreenOptions,
		};
	};
}
