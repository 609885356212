import * as React from 'react';

export default function DelayedDisplay({
	delayInMs = 1000,
	children,
}: {
	delayInMs?: number;
	children: React.ReactNode;
}) {
	const [shouldShowChildren, setShouldShowChildren] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setShouldShowChildren(true);
		}, delayInMs);

		// clear the timeout on unmount
		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{shouldShowChildren && children}</>;
}
