import * as React from 'react';
import { Text } from '.';
import { TextProps } from './Text';

interface IBoldParser extends TextProps {
	text: string;
}

function TextWithBoldParsing({ text, ...textProps }: IBoldParser, ref: any) {
	// Function to split text into segments
	const parseText = (inputText: string): string[] => {
		const regex = /(\*[^*]+\*)/g;
		// const regex = /(?<=\\*)\\b[^*]+\\b(?=\\*)/g;
		return inputText.split(regex);
	};

	// Function to render text segments with styling
	function renderText(segments: string[]) {
		return segments.map((segment, index) => {
			if (segment.startsWith('*') && segment.endsWith('*')) {
				return (
					<Text key={index} {...textProps} weight="semiBold">
						{segment.slice(1, -1)}
					</Text>
				);
			} else {
				return (
					<Text key={index} {...textProps}>
						{segment}
					</Text>
				);
			}
		});
	}

	const segments = parseText(text);

	return (
		<Text ref={ref} {...textProps}>
			{renderText(segments)}
		</Text>
	);
}

export default React.forwardRef(TextWithBoldParsing);
