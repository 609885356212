import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';

export const WEB_MAIN_CONTENT_ID = 'main-content';

// Add <main> wrapper for web, pass thru children on native
export default function MainContentView({ children }: { children: React.ReactNode }) {
	const { t } = useTranslation();

	return Platform.OS === 'web' ? (
		<View
			role="main"
			aria-label={t('accessibility.mainContentLabel')}
			focusable={false}
			id={WEB_MAIN_CONTENT_ID}
			style={{ flex: 1 }}
		>
			{children}
		</View>
	) : (
		<>{children}</>
	);
}
