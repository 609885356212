import { useAnnounceForAccessibilityEffect } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { Platform } from 'react-native';
import { Text } from '../text';

export function Announce({ message = '', ...textProps }: { message?: string }) {
	const styleHelpers = useStyleHelpers();
	useAnnounceForAccessibilityEffect(message);

	return Platform.OS === 'web' ? (
		<Text aria-live="polite" style={styleHelpers.screenReaderOnly} {...textProps}>
			{message}
		</Text>
	) : null;
}
