import {
	useContentDetailQuery,
	useSetFavoriteStatusMutation,
} from '@mobe/api/explore/exploreApiHooks';
import ChatButton from '@mobe/components/chatButton/ChatButton';
import Row from '@mobe/components/layout/Row';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useTransitions from '@mobe/utils/useTransitions';
import { useFocusEffect } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { Platform } from 'react-native';
import {
	ExploreDetailScreensNavigationProp,
	ExploreDetailScreensRouteProp,
} from '../../navigation/modal/types';
import ExploreFavoriteButton from '../components/ExploreFavoriteButton';
import SharedContentView from '../components/SharedContentView';

export interface IExploreDetailScreenParams {
	contentId: number;
}

interface IExploreDetailScreenProps {
	navigation: ExploreDetailScreensNavigationProp;
	route: ExploreDetailScreensRouteProp;
}

export default function ExploreDetailScreen({ navigation, route }: IExploreDetailScreenProps) {
	const contentItem = useContentDetailQuery(route.params.contentId);

	useFocusEffect(() => {
		navigation.setOptions({
			title: contentItem.data?.title || '',
		});
	});

	return <SharedContentView contentId={route.params.contentId} />;
}

export function useExploreDetailScreenOptions() {
	const subScreenStyleProps = useSubScreenStyleProps({ hasExtraIcons: true });
	const transitions = useTransitions();

	return (screenProps: IExploreDetailScreenProps): StackNavigationOptions => ({
		title: '',
		headerTitle: '',
		...subScreenStyleProps,
		...(Platform.OS === 'android' && transitions.DefaultTransition),
		headerRight: function HeaderRight() {
			const contentId = screenProps.route.params.contentId;
			const setFavoriteStatusMutation = useSetFavoriteStatusMutation();
			const favorited = useContentDetailQuery(contentId).data?.isFavorite;

			return (
				<Row gutterSize={20} align="top">
					<Row.Item>
						<ExploreFavoriteButton
							favorited={favorited}
							onPress={() => {
								setFavoriteStatusMutation.mutate({
									sharedContentId: contentId,
									isFavorite: !favorited,
								});
							}}
						/>
					</Row.Item>
					<Row.Item>
						<ChatButton />
					</Row.Item>
				</Row>
			);
		},
	});
}
