import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import Text from '../text/Text';

export type Value = string | number;

export interface IInputRadioGraphicItem {
	graphic: (isSelected: boolean) => React.ReactNode;
	name: Value;
	accessibilityLabel: string;
}

export interface IInputRadioGraphic {
	value?: Value;
	label: string;
	onSelect: (value: Value) => void;
	isDisabled?: boolean;
}

interface IInputRadioGraphicProps extends IInputRadioGraphic {
	options: IInputRadioGraphicItem[];
}

export default function InputRadioGraphic({
	options,
	value,
	onSelect,
	label,
	isDisabled,
}: IInputRadioGraphicProps) {
	const { notLastChild, constrainText, vr } = useStyleHelpers();

	return (
		<>
			{label !== undefined && (
				<Text weight="medium" size="lg" align="center" style={[constrainText(300), vr(4)]}>
					{label}
				</Text>
			)}
			<View
				style={{ flexDirection: 'row', marginHorizontal: -5 }}
				role="radiogroup"
				aria-valuetext={value?.toString()}
			>
				{options.map(({ name, graphic, accessibilityLabel }, index) => {
					const isChecked = value === name;

					return (
						<View
							key={name}
							style={[{ flex: 1 }, notLastChild(options.length, index, { paddingRight: 10 })]}
						>
							<Pressable
								role="radio"
								aria-label={accessibilityLabel}
								aria-checked={isChecked}
								disabled={isDisabled}
								style={{ padding: 5 }}
								onPress={() => onSelect(name)}
							>
								{graphic(isChecked)}
							</Pressable>
						</View>
					);
				})}
			</View>
		</>
	);
}
