import { useProfileQuery, useUpdateProfileMutation } from '@mobe/api/account/accountApiHooks';
import { IAppointment } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { Button } from '@mobe/components/button';
import CardButton from '@mobe/components/cardButton/CardButton';
import Dismissible from '@mobe/components/dismissible/Dismissible';
import Heading from '@mobe/components/heading/Heading';
import Icon from '@mobe/components/icon/Icon';
import ImageSelectSheet from '@mobe/components/imageSelectSheet/ImageSelectSheet';
import MobeImage from '@mobe/components/mobeImage/MobeImage';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import { GuideApptCard } from '../../appointments/components/GuideApptCard';
import { PlanScreenNavigationProp } from '../../navigation/bottomTabs/types';
import * as PlanAnalyticsEvents from '../analyticsEvents';
import {
	useHasDismissedVisionCardMutation,
	useHasDismissedVisionCardQuery,
} from '../useHasDismissedVisionCard';
import VisionBackgroundGraphic from './graphics/VisionBackgroundGraphic';

const VISION_MAX_LINES = 4;
const VISION_IMAGE_WIDTH = 124;
const VISION_IMAGE_WIDTH_LARGE = 180;

export type PlanHeroVisualState = 'noGuide' | 'hasCall' | 'hasGuideNoCall' | 'hasData';

interface IPlanHeroProps {
	visionStatement: string;
	visualState: PlanHeroVisualState;
	appointment?: IAppointment;
	accessibilityAutoFocus?: boolean;
}

export default function PlanHero({
	visionStatement,
	visualState,
	appointment,
	accessibilityAutoFocus,
}: IPlanHeroProps) {
	const { constrainText, vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = useStyles(visualState);
	const navigation = useNavigation<PlanScreenNavigationProp>();
	const { isLargeDisplay } = useLayout();
	const profileQuery = useProfileQuery();
	const updateProfileMutation = useUpdateProfileMutation();
	const visionImage = profileQuery.data?.visionImageUrl;
	const [visionExpanded, setVisionExpanded] = React.useState(true);
	const [visionNumberOfLines, setVisionNumberOfLines] = React.useState(0);
	const [isImageSheetOpen, setIsImageSheetOpen] = React.useState(false);

	const hasDismissedVisionCardQuery = useHasDismissedVisionCardQuery();
	const hasDismissedVisionCardMutation = useHasDismissedVisionCardMutation();

	function processImageSelection(visionImage: string | File) {
		updateProfileMutation.mutate({ updatedProfileData: { visionImage } });
	}

	return (
		<>
			<View style={styles.hero}>
				<View style={styles.heroGraphic}>
					<VisionBackgroundGraphic grayScale={visualState !== 'hasData'} />
				</View>
				{visualState === 'noGuide' && (
					<>
						<Heading accessibilityAutoFocus={accessibilityAutoFocus} level="h3" style={vr(2)}>
							{t('plan.visionHero.noGuide.heading')}
						</Heading>
						<Text align="center" style={[constrainText(320), vr(4)]}>
							{t('plan.visionHero.noGuide.subHeading')}
						</Text>
						<Button
							size="sm"
							style={{ alignSelf: 'center' }}
							title={t('plan.visionHero.noGuide.button')}
							onPress={() =>
								navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM })
							}
						/>
					</>
				)}
				{visualState === 'hasGuideNoCall' && (
					<>
						<Heading accessibilityAutoFocus={accessibilityAutoFocus} level="h3" style={vr(2)}>
							{t('plan.visionHero.hasGuideNoCall.heading')}
						</Heading>
						<Text align="center" style={[constrainText(320), vr(4)]}>
							{t('plan.visionHero.hasGuideNoCall.subHeading')}
						</Text>
						<Button
							size="sm"
							style={{ alignSelf: 'center' }}
							title={t('plan.visionHero.hasGuideNoCall.button')}
							onPress={() =>
								navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM })
							}
						/>
					</>
				)}
				{visualState === 'hasCall' && (
					<>
						<Heading accessibilityAutoFocus={accessibilityAutoFocus} level="h3" style={vr(2)}>
							{t('plan.visionHero.hasCall.heading')}
						</Heading>
						<Text align="center" style={[constrainText(320), vr(4)]}>
							{t('plan.visionHero.hasCall.subHeading')}
						</Text>
						{appointment !== undefined && (
							<View style={styles.appointmentCard}>
								<View style={styles.appointmentCardInner}>
									<GuideApptCard
										appointment={appointment}
										hideCalendarButton
										onPress={() =>
											navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
												confirmationId: appointment.confirmationId,
											})
										}
									/>
								</View>
							</View>
						)}
					</>
				)}
				{visualState === 'hasData' && (
					<View style={styles.visionStatement}>
						<Heading accessibilityAutoFocus={accessibilityAutoFocus} level="h3" style={vr(2)}>
							{t('plan.visionHero.hasData.heading')}
						</Heading>
						<View style={vr(5)}>
							<Text
								numberOfLines={visionExpanded || !visionNumberOfLines ? 0 : VISION_MAX_LINES}
								onTextLayout={(textLayoutEvent) => {
									// onTextLayout doesn't fire on web, so the text is always expanded. This is acceptable.
									if (!visionNumberOfLines) {
										setVisionExpanded(false);
										setVisionNumberOfLines(textLayoutEvent.nativeEvent.lines.length);
									}
								}}
								align="left"
								style={styles.visionStatementText}
							>
								{visionStatement}
							</Text>
							{visionNumberOfLines > VISION_MAX_LINES && (
								<TextButton
									title={
										visionExpanded
											? t('plan.visionHero.hasData.showLess')
											: t('plan.visionHero.hasData.showMore')
									}
									aria-expanded={visionExpanded}
									style={vrTop(3)}
									onPress={() => setVisionExpanded(!visionExpanded)}
								/>
							)}
						</View>
					</View>
				)}
			</View>
			{visualState === 'hasData' && (
				<View style={styles.visionImageWrapper}>
					{visionImage ? (
						<View style={styles.visionImageBox}>
							<Pressable
								aria-label={t('plan.visionHero.hasData.visionCard.heading')}
								role="button"
								onPress={() => {
									setIsImageSheetOpen(true);
									PlanAnalyticsEvents.visionImageUpdatePress();
								}}
							>
								<MobeImage
									style={styles.visionImage}
									source={{ uri: visionImage }}
									renderWidth={isLargeDisplay ? VISION_IMAGE_WIDTH_LARGE : VISION_IMAGE_WIDTH}
									resizeMode="cover"
								/>
							</Pressable>
						</View>
					) : hasDismissedVisionCardQuery.data ? (
						<Pressable
							aria-label={t('plan.visionHero.hasData.visionCard.heading')}
							role="button"
							style={styles.visionMinimizedButton}
							onPress={() => {
								setIsImageSheetOpen(true);
								PlanAnalyticsEvents.visionCardMinimizedUploadPress();
							}}
						>
							<Icon name="camera" size={24} color="primary" />
						</Pressable>
					) : (
						<Dismissible
							onAnimationComplete={() => {
								hasDismissedVisionCardMutation.mutate(true);
								PlanAnalyticsEvents.visionCardMinimize();
							}}
						>
							{(dismiss) => (
								<CardButton onDismissPress={dismiss} style={styles.visionCard}>
									<View style={styles.visionCardBody}>
										<Heading level="h4" style={vr(2)}>
											{t('plan.visionHero.hasData.visionCard.heading')}
										</Heading>
										<Text style={vr(3)}>{t('plan.visionHero.hasData.visionCard.description')}</Text>
										<TextButton
											title={t('plan.visionHero.hasData.visionCard.button')}
											onPress={() => {
												setIsImageSheetOpen(true);
												PlanAnalyticsEvents.visionCardUploadPress();
											}}
										/>
									</View>
								</CardButton>
							)}
						</Dismissible>
					)}
					<ImageSelectSheet
						isOpen={isImageSheetOpen}
						onClose={() => setIsImageSheetOpen(false)}
						heading={t('plan.visionHero.hasData.visionCard.heading')}
						subHeading={t('plan.visionHero.hasData.visionCard.description')}
						onSelect={processImageSelection}
						cropToSquare
					/>
				</View>
			)}
		</>
	);
}

function useStyles(state: PlanHeroVisualState) {
	const rules = useStyleRules();
	const { isLargeDisplay } = useLayout();
	const { cardShadow } = useStyleHelpers();

	const appointmentCardOffset = 35;
	const visionImageSize = isLargeDisplay ? VISION_IMAGE_WIDTH_LARGE : VISION_IMAGE_WIDTH;
	const visionImageBorderWidth = 4;
	const visionImageOffset = (visionImageSize + visionImageBorderWidth * 2) / 2;
	const visionMinimizedButtonHeight = 48;

	return StyleSheet.create({
		hero: {
			minHeight: isLargeDisplay ? 200 : 120,
			justifyContent: 'center',
			alignItems: 'center',
			paddingTop: isLargeDisplay ? 60 : 28,
			paddingBottom: visionImageOffset,
			paddingHorizontal: state === 'hasData' ? 0 : rules.spacing.appHorizontalMargin,
			marginBottom: state === 'hasCall' ? appointmentCardOffset : 0,
			backgroundColor:
				state === 'hasData' ? rules.colors.backgroundPrimary : rules.colors.strokeLight,
		},
		heroGraphic: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			justifyContent: 'flex-end',
			width: '130%',
			maxWidth: 800,
			zIndex: -1,
		},
		visionStatement: {
			width: '100%',
			justifyContent: 'center',
			maxWidth: rules.spacing.maxWidth,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			marginHorizontal: 'auto',
		},
		visionStatementText: {
			maxWidth: 500,
		},
		visionImageWrapper: {
			minHeight: visionImageOffset + visionMinimizedButtonHeight / 2,
			width: '100%',
			maxWidth: rules.spacing.maxWidth,
			alignSelf: 'center',
			marginTop: -visionImageOffset,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		visionCard: {
			marginTop: visionImageOffset - 42,
		},
		visionCardBody: {
			paddingTop: 16,
			paddingHorizontal: 12,
		},
		visionImageBox: {
			width: visionImageSize + visionImageBorderWidth * 2,
			height: visionImageSize + visionImageBorderWidth * 2,
			backgroundColor: rules.colors.cardBackground,
			borderRadius: rules.borderRadius,
			padding: visionImageBorderWidth,
			alignSelf: 'flex-start',
			...cardShadow,
		},
		visionImage: {
			overflow: 'hidden',
			borderRadius: 3,
			width: visionImageSize,
			height: visionImageSize,
		},
		visionMinimizedButton: {
			marginTop: visionImageOffset - visionMinimizedButtonHeight / 2,
			width: visionMinimizedButtonHeight,
			height: visionMinimizedButtonHeight,
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: rules.colors.cardBackground,
			borderRadius: rules.borderRadius,
			...cardShadow,
		},
		appointmentCard: {
			position: 'absolute',
			bottom: -appointmentCardOffset,
			width: '100%',
			alignItems: 'center',
		},
		appointmentCardInner: {
			width: '100%',
			maxWidth: 520,
		},
	});
}
