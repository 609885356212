import { useAllActivitiesQuery } from '@mobe/api/activities/activitiesApiHooks';
import { Button } from '@mobe/components/button';
import Row from '@mobe/components/layout/Row';
import { ModuleHeading } from '@mobe/components/moduleHeading/ModuleHeading';
import { Text } from '@mobe/components/text';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useLayout from '@mobe/utils/styles/useLayout';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, StyleProp, View, ViewStyle } from 'react-native';
import { BottomTabScreenNavigationProp } from '../../navigation/bottomTabs/types';
import ConnectBox from '../../plan/components/ConnectBox';
import ActivitiesGraphic from '../../plan/components/graphics/ActivitiesGraphic';
import ActivitiesSimpleGraphic from '../../plan/components/graphics/ActivitiesSimpleGraphic';
import { activityCardPressHome, activityCardPressPlan } from '../analyticsEvents';
import useInAppActivities from '../useInAppActivities';
import ActivityCard from './ActivityCard';

const HOME_LIMIT = 3;
const INITIAL_LIMIT = 6;

interface IActivitiesModuleProps {
	style?: StyleProp<ViewStyle>;
	context?: 'home' | 'plan';
	onCardPress?: (event: GestureResponderEvent) => void;
}

export default function ActivitiesModule({
	context = 'plan',
	style,
	onCardPress = noop,
}: IActivitiesModuleProps) {
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();
	const [limit, setLimit] = React.useState<number | undefined>(
		context === 'home' ? HOME_LIMIT : INITIAL_LIMIT
	);
	const { isLargeDisplay } = useLayout();
	const navigation = useNavigation<BottomTabScreenNavigationProp>();
	const activitiesQuery = useAllActivitiesQuery();
	const inAppActivities = useInAppActivities();
	const allNewActivities = [...(activitiesQuery.data?.activities ?? []), ...inAppActivities].filter(
		(activity) => !activity.isCompleted
	);
	const hasCompletedActivities = Boolean(activitiesQuery.data?.summary.lifetimeCount);
	const [ref, focusRef] = useAccessibilityFocus();

	const visualState = allNewActivities.length
		? 'hasActiveActivities'
		: hasCompletedActivities
		? 'allActivitiesCompleted'
		: 'noActivities';

	useFocusEffect(
		React.useCallback(() => {
			if (context === 'plan') {
				setLimit(INITIAL_LIMIT);
			}
		}, [])
	);

	function handleHeadingButtonPress() {
		if (context === 'home') {
			navigation.navigate('PLAN_SCREEN', { scrollToModule: 'activities' });
			return;
		}

		navigation.navigate('ACTIVITIES_HISTORY_SCREEN');
	}

	function handleCustomEvent() {
		if (context === 'home') activityCardPressHome();
		if (context === 'plan') activityCardPressPlan();
	}

	return (
		<View style={style}>
			<ModuleHeading
				title={t('plan.activitiesHeading')}
				buttonTitle={context === 'home' ? t('plan.viewAllButton') : t('plan.viewHistoryButton')}
				showButton={
					context === 'home' ? allNewActivities.length > HOME_LIMIT : hasCompletedActivities
				}
				onButtonPress={handleHeadingButtonPress}
				style={vr(visualState !== 'noActivities' ? 2 : 5)}
				count={context === 'home' ? allNewActivities.length : undefined}
			/>
			{visualState !== 'noActivities' && (
				<Text style={vr(5)}>{t('plan.activitiesSubHeading')}</Text>
			)}
			{visualState === 'hasActiveActivities' && (
				<View>
					{allNewActivities.slice(0, limit).map((activity, index) => (
						<View key={activity.id}>
							<ActivityCard
								key={activity.id}
								activityData={activity}
								customEvent={handleCustomEvent}
								onPress={onCardPress}
							/>
							{index === INITIAL_LIMIT - 1 && <View ref={ref} accessible focusable />}
						</View>
					))}
					{typeof limit === 'number' && limit < allNewActivities.length && context === 'plan' && (
						<Button
							title={t('plan.viewAllButton')}
							variant="secondary"
							style={{ alignSelf: 'center' }}
							onPress={() => {
								focusRef();
								setLimit(undefined);
							}}
							size="sm"
						/>
					)}
				</View>
			)}
			{visualState === 'allActivitiesCompleted' && (
				<ConnectBox title={t('plan.connectBox.activity')} graphic={<ActivitiesSimpleGraphic />} />
			)}
			{visualState === 'noActivities' && (
				<Row>
					<Row.Item>
						<View style={constrain(isLargeDisplay ? 100 : 70)}>{<ActivitiesGraphic />}</View>
					</Row.Item>
					<Row.Item fill>
						<Text
							color="light"
							style={[!isLargeDisplay && constrainText(210), { alignSelf: 'flex-start' }]}
						>
							{t('plan.activitiesDescription')}
						</Text>
					</Row.Item>
				</Row>
			)}
		</View>
	);
}
