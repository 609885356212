import useDeviceCalendar from '@mobe/utils/useDeviceCalendar';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { InteractionManager } from 'react-native';

export default function useAddToCalendar(eventParams: {
	startDate: Date;
	duration: number;
	title: string;
	notes?: string | undefined;
}) {
	const [status, setStatus] = React.useState<
		| 'init'
		| 'noPermissions'
		| 'hasExistingEvent'
		| 'noExistingEvent'
		| 'newEventAdded'
		| 'newEventError'
	>('init');
	const deviceCalendar = useDeviceCalendar();

	useFocusEffect(
		React.useCallback(() => {
			// Run after interactions to make sure we get smooth animations on android...
			const task = InteractionManager.runAfterInteractions(isEventAlreadyAdded);

			return () => task.cancel();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	/**
	 * Determine if native calendar already has this event.
	 * NOTE: This check will trigger a calendar permissions request if permission status is unknown!
	 */
	async function isEventAlreadyAdded() {
		const permissionsStatus = await deviceCalendar.getPermissionsStatus();

		// If permission is not granted, assume that the event has not been added to calendar.
		// Doing so ensures that the permissions alert only occurs on direct user action.
		if (permissionsStatus !== 'granted') {
			setStatus('noPermissions');
			return;
		}

		const hasExistingEvent = await deviceCalendar.hasExistingEvents(
			eventParams.startDate,
			eventParams.duration,
			eventParams.title
		);

		if (hasExistingEvent) {
			setStatus('hasExistingEvent');
			return;
		}

		setStatus('noExistingEvent');
	}

	async function addEventToCalendar() {
		if (status === 'hasExistingEvent' || status === 'newEventAdded') {
			return;
		}

		await deviceCalendar.addEventToCalendar({
			...eventParams,
		});
		setStatus('newEventAdded');
	}

	return { status, addEventToCalendar };
}
