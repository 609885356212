import ErrorFallbackView from '@mobe/components/errorFallbackView/ErrorFallbackView';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ScreenTemplate from '@mobe/components/screenTemplate/ScreenTemplate';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import MainContentView from '../../navigation/MainContentView';

export default function MaintenanceScreen() {
	const rules = useStyleRules();
	const scrollViewBackgroundColor = rules.colors.cardBackground;

	return (
		<MainContentView>
			<ScreenTemplate noPadding scrollViewBackgroundColor={scrollViewBackgroundColor}>
				<ErrorFallbackView isMaintenance />
			</ScreenTemplate>
		</MainContentView>
	);
}

export function useMaintenanceScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return {
		title: t('maintenance.screenTitle'),
		headerShown: false,
	};
}
