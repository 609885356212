import { useProfileQuery } from '@mobe/api/account/accountApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { Button } from '@mobe/components/button';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactOptionsView from '../../../components/ContactOptionsView';
import { useFollowUpContext } from '../FollowUpProvider';
import {
	FollowUpContactScreenNavigationProp,
	FollowUpContactScreenRouteProp,
} from '../FollowUpStackScreen';

interface IFollowUpContactScreenProps {
	navigation: FollowUpContactScreenNavigationProp;
	route: FollowUpContactScreenRouteProp;
}

export default function FollowUpContactScreen({ navigation }: IFollowUpContactScreenProps) {
	const guideFollowUpContext = useFollowUpContext();
	const profileQuery = useProfileQuery();
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();

	let guideType = t('pharmacist');
	if (
		guideFollowUpContext.selectedGuide?.coachType &&
		guideFollowUpContext.selectedGuide.coachType !== CoachType.Pharmacist
	) {
		guideType = t('guide');
	}

	useFocusEffect(
		React.useCallback(() => {
			guideFollowUpContext.setCurrentStep(1);
		}, [])
	);

	return (
		<ScreenTemplateWithFooter
			footer={
				<ButtonRow>
					<Button
						variant="secondary"
						title={t('appointments.followUp.previousStepButton')}
						onPress={() => navigation.navigate('FOLLOW_UP_SCHEDULE_SCREEN')}
					/>
					<Button
						title={t('appointments.followUp.lastStepButton')}
						disabled={!guideFollowUpContext.canSubmit || guideFollowUpContext.isFetching}
						loading={guideFollowUpContext.isFetching}
						onPress={guideFollowUpContext.handleSubmitPress}
					/>
				</ButtonRow>
			}
		>
			{profileQuery.data?.phoneNumber ? (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(8)}
				>
					{t('appointments.followUp.contactStep.hasPrimaryNumberDescription', { guideType })}
				</Text>
			) : (
				<Text
					accessibilityAutoFocus
					role="heading"
					aria-level="2"
					size="lg"
					weight="medium"
					align="center"
					style={vr(8)}
				>
					{t('appointments.followUp.contactStep.noPrimaryNumberDescription', { guideType })}
				</Text>
			)}

			<ContactOptionsView
				contactOptionControl={guideFollowUpContext.contactOptionControl}
				primaryPhoneNumberProps={guideFollowUpContext.primaryPhoneNumberProps}
				primaryPhoneNumberErrorMessage={
					guideFollowUpContext.form.formState.errors.primaryPhoneNumber?.message
				}
				otherPhoneNumberProps={guideFollowUpContext.otherPhoneNumberProps}
				otherPhoneNumberErrorMessage={
					guideFollowUpContext.form.formState.errors.otherPhoneNumber?.message
				}
				saveOtherAsAlternatePhoneNumberProps={
					guideFollowUpContext.saveOtherAsAlternatePhoneNumberProps
				}
			/>
		</ScreenTemplateWithFooter>
	);
}
