/**
 * Mapping for custom icon font. This is using a decimal notation for symbol mapping.
 * This could be updated in the future to use hexadecimal -> parseInt('e900', 16);
 */
export default {
	appointment: 59648,
	connectHealthData: 59649,
	guide: 59650,
	pharmacist: 59651,
	profile: 59652,
	settings: 59653,
	survey: 59654,
	close: 59655,
	activities: 59656,
	explore: 59657,
	home: 59658,
	progress: 59659,
	track: 59660,
	visibilityOff: 59661,
	visibilityOn: 59662,
	search: 59663,
	schedule: 59664,
	right: 59665,
	menu: 59666,
	left: 59667,
	image: 59668,
	heart: 59669,
	down: 59670,
	checkmark: 59671,
	chat: 59672,
	camera: 59673,
	add: 59674,
	motivate: 59675,
	spinner: 59680,
	heart_solid: 59681,
	plus: 59682,
	up: 59683,
	shareAndroid: 59684,
	shareIos: 59685,
	thumbsDown: 59686,
	thumbsUp: 59687,
	star_solid: 59688,
	star: 59689,
	move: 59690,
	sleep: 59691,
	smile: 59692,
	medication: 59693,
	eat: 59694,
	plan: 59695,
};
