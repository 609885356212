import Box from '@mobe/components/box/Box';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import * as GlobalAnalyticsEvents from '@mobe/utils/analyticsEvents';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleProp, View, ViewStyle } from 'react-native';

interface IApptConfirmationBoxProps {
	confirmationId: string | null;
	confirmationMessage?: string;
	children?: React.ReactNode;
	containerStyle?: StyleProp<ViewStyle>;
}

export default function ApptConfirmationBox({
	confirmationId,
	confirmationMessage,
	children,
	containerStyle,
}: IApptConfirmationBoxProps) {
	const { vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();

	function handleSupportPress() {
		GlobalAnalyticsEvents.supportNumberPress();
		Linking.openURL(`tel:${t('appointments.confirmationBox.questionsPhoneNumber')}`);
	}

	return (
		<Box style={containerStyle}>
			{Boolean(confirmationId) && (
				<Text weight="semiBold" color="light" align="center" style={vr(2)}>
					{t('appointments.confirmationBox.confirmationId', {
						confirmationId,
					})}
				</Text>
			)}
			{Boolean(confirmationMessage) && (
				<Text color="light" align="center" style={vr(3)}>
					{confirmationMessage}
				</Text>
			)}
			<Row gutterSize={0} wrap style={{ alignSelf: 'center', justifyContent: 'center' }}>
				<Row.Item>
					<Text align="center" weight="medium" style={{ marginRight: 5 }}>
						{t('appointments.confirmationBox.questions')}
					</Text>
				</Row.Item>
				<Row.Item>
					<TextButton
						title={t('appointments.confirmationBox.questionsButton')}
						onPress={handleSupportPress}
						align="center"
					/>
				</Row.Item>
			</Row>
			{children && (
				<View style={[vrTop(6), { maxWidth: 500, width: '100%', alignSelf: 'center' }]}>
					{children}
				</View>
			)}
		</Box>
	);
}
