import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';
import { IMetadataPagination } from '../paginationUtils';
import { TrackerAbbreviation } from '../track/trackService';

export type GoalType = 'LongTerm' | 'ShortTerm';
export type GoalStatus = 'Completed' | 'InProgress';
export type ActionStepStatus = 'Completed' | 'InProgress';
export enum Pathway {
	Eat = 'Eat',
	Move = 'Move',
	Sleep = 'Sleep',
	Smile = 'Smile',
	Medication = 'Medication',
	Motivate = 'Motivate',
}

export interface IActionStep {
	id: number;
	status: ActionStepStatus;
	title: string;
	pathwayName: Pathway | null;
	startDate: string;
	endDate: string;
	trackerAbbreviation: TrackerAbbreviation | null;
	subStepsComplete: number;
	subStepsTotal: number;
}

interface IActionStepsMetadata extends IMetadataPagination {
	thisWeekCount: number;
}

export interface IGetActionStepsResponse {
	metadata: IActionStepsMetadata;
	data: IActionStep[];
}

export interface IGoal {
	id: number;
	title: string;
	pathwayName: Pathway | null;
	goalType: GoalType;
	goalStatus: GoalStatus;
	startDate: string;
	endDate: string;
	trackerAbbreviation: TrackerAbbreviation | null;
	isViewed: boolean;
}

export interface IGoalFull extends IGoal {
	actions: IActionStep[];
}

export interface IGetGoalsResponse {
	metadata: IMetadataPagination;
	data: IGoal[];
}

export interface IGetGoals {
	type: GoalType;
	status: GoalStatus;
	skip?: number;
	take?: number;
}

export async function getGoals(params: IGetGoals) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IGetGoalsResponse>(
					`strategy-goal-types/${params.type}/strategy-goals`,
					params
				);

				return mobeStandardSuccessProcessor<IGetGoalsResponse>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IGetGoalsResponse>(error);
			}
		})()
	);
}

export async function getGoal(id: number) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IGoalFull>(`strategy-goals/${id}`);

				return mobeStandardSuccessProcessor<IGoalFull>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IGoalFull>(error);
			}
		})()
	);
}

export interface ISetGoalStatus {
	goalId: number;
	goalStatus: GoalStatus;
}

export async function setGoalStatus({ goalId, goalStatus }: ISetGoalStatus) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.patch(`strategy-goals/${goalId}`, { goalStatus });

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}

export interface IGetActionSteps {
	status: ActionStepStatus;
	skip?: number;
	take?: number;
}

export async function getActionSteps(params: IGetActionSteps) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IGetActionStepsResponse>(
					'strategy-actions',
					params
				);

				return mobeStandardSuccessProcessor<IGetActionStepsResponse>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IGetActionStepsResponse>(error);
			}
		})()
	);
}

export async function getActionStep(id: number) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IActionStep>(`strategy-actions/${id}`);

				return mobeStandardSuccessProcessor<IActionStep>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IActionStep>(error);
			}
		})()
	);
}

export interface ISetActionStepStatus {
	actionStepId: number;

	/**
	 * Status of the current sub step. This will not effect the status of the action step unless
	 * there are no remaining sub steps. The status essentially increments/decrements sub steps.
	 */
	status: ActionStepStatus;
}

export async function setActionStepStatus({ actionStepId, status }: ISetActionStepStatus) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.patch(`strategy-actions/${actionStepId}`, { status });

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}
