import { noop } from 'lodash';
import * as React from 'react';
import Confetti, { IConfettiMethods } from './Confetti';

interface IConfettiProviderProps {
	children: React.ReactNode;
}

interface IShowParams {
	delay?: number;
	onComplete?: () => void;
}

interface IConfettiProviderContextValue {
	show: (params?: IShowParams) => void;
}

const ConfettiContext = React.createContext<IConfettiProviderContextValue | undefined>(undefined);

function ConfettiProvider({ children }: IConfettiProviderProps) {
	const confettiRef = React.useRef<IConfettiMethods>(null);

	function show({ delay, onComplete = noop }: IShowParams = {}) {
		setTimeout(() => {
			confettiRef.current?.showConfetti(onComplete);
		}, delay ?? 0);
	}

	return (
		<ConfettiContext.Provider value={{ show }}>
			{children}
			<Confetti ref={confettiRef} />
		</ConfettiContext.Provider>
	);
}

function useConfetti() {
	const context = React.useContext(ConfettiContext);

	if (context === undefined) {
		throw new Error('useConfetti must be used within ConfettiProvider');
	}

	return {
		show: context.show,
	};
}

export { ConfettiProvider, useConfetti };
