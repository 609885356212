import Box from '@mobe/components/box/Box';
import Heading from '@mobe/components/heading/Heading';
import { Text } from '@mobe/components/text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const MAX_LINES = 4;

interface ITrackerSummaryProps {
	title: string;
	description: string;
}

export default function TrackerSummary({ title, description }: ITrackerSummaryProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const [expanded, setExpanded] = React.useState(true);
	// Tracks the calculated number of lines before truncation
	const [numberOfLines, setNumberOfLines] = React.useState(0);

	return (
		<Box>
			<Heading style={vr(2)} level="h4">
				{title}
			</Heading>
			<Text
				numberOfLines={expanded || !numberOfLines ? 0 : MAX_LINES}
				onTextLayout={(textLayoutEvent) => {
					// onTextLayout doesn't fire on web, so the text is always expanded. This is acceptable.
					if (!numberOfLines) {
						setExpanded(false);
						setNumberOfLines(textLayoutEvent.nativeEvent.lines.length);
					}
				}}
			>
				{description}
			</Text>
			{/* No need to render "Show more" button if there's nothing more to show */}
			{numberOfLines > MAX_LINES && (
				<TextButton
					title={expanded ? t('track.detail.showLessButton') : t('track.detail.showMoreButton')}
					aria-expanded={expanded}
					style={{
						paddingTop: 8,
					}}
					onPress={() => setExpanded(!expanded)}
				/>
			)}
		</Box>
	);
}
