import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#CFEEFF"
					d="M53.083 42.926l.032.251.031.013c.015-.1.03-.191.043-.287l-.106.023z"
				/>
				<Path
					fill="#CFEEFF"
					d="M29.87 43.688a6.01 6.01 0 01-1.646-2.771c-.653-1.988 2.48-3.286 2.48-3.286s-.729-.217-1.667-2.155c-.938-1.938.363-3.089 2.592-4.195 2.229-1.106 1.68-1.436 1.094-4.518s2.658-4.8 2.651-5.014c-.028-.752 1.73-4.471 8.8-3.778a10.381 10.381 0 018.685 5.215c.119.243.253.483.4.718 2.223 3.679.109 6.025-.066 7.414-.181 1.436 1.408.713 2.451 2.309a3.11 3.11 0 01.38 2.143 1 1 0 011.161.049c.574.715.706 1.688.344 2.53.57.778.508 1.851-.147 2.558l-.914.2a10.98 10.98 0 00-.518 2.019l.346.132a51.715 51.715 0 01-3.243 6.79c1.7.1 3.342.246 4.9.435a29.282 29.282 0 005.723-1.344c1.828.808 2 1.062 2.631.858 1.725-.554-.051-2.339-.051-2.339s6.924-18.308.065-27.865a1.772 1.772 0 00-.129-2.492s-.724-.236-2.287 1.076c-7.774-2.957-37.65-2.419-43.9.409-1.674-1.615-3.152-1.478-2.743-.675l.463 1.885c-4.573 4.172-3.765 19.242.521 28.068a1.013 1.013 0 00.553 1.572c.571.151 1.581-.5 1.428-.909a19.092 19.092 0 005.237 1.83 71.462 71.462 0 013.406-.359c.254-2.274.584-4.543 1-6.51z"
				/>
				<Path
					fill="#453333"
					d="M39.613 34.183c.137-1.005.337-2 .6-2.98l-.153-.177s-1.951-1.034-2.306-2.483 1.331-2.892 2.395-1.965.085 1.539 1.681 1.228 3.461-3.09 3.994-5.784 3.419-3.034 3.509.172c0 0 .354 1.66.816 3.243.071-.355.158-.707.261-1.056.213 0 .622-.33.781.148.294 2.504.344 5.031.148 7.545 0 2.087.082 4.17.245 6.251.75.761 1.259 2.846 1.492 4.6l.106-.025a17.02 17.02 0 00.144-3.3c-.213-.677-.328-1.507 0-1.573s.925 1.265.972 1.735.2-1.674.655-2.5a2.883 2.883 0 011.066-1.19 3.925 3.925 0 00-.38-2.426c-1.035-1.806-2.625-.987-2.445-2.617.175-1.572 2.289-4.228.066-8.394a12.235 12.235 0 01-.4-.813 10.293 10.293 0 00-8.683-5.9c-7.072-.785-8.83 3.426-8.8 4.278.007.239-3.237 2.192-2.651 5.679s1.135 3.863-1.094 5.116c-2.229 1.253-3.529 2.558-2.592 4.75s1.667 2.44 1.667 2.44-3.133 1.47-2.48 3.721a7.042 7.042 0 001.64 3.136c.181-.965.379-1.866.6-2.679a10.476 10.476 0 019.146-8.18z"
				/>
				<Path
					fill="#453333"
					d="M45.466 34.81a9.34 9.34 0 00-.857 1.793 7.901 7.901 0 012.784 3.663l.012.048.151-1.921a.36.36 0 01.355-.331H48a242.03 242.03 0 00-.47-3.574 3.334 3.334 0 01-2.064.322zM57.383 41.894c.659-.838.718-2 .147-2.9-.247.913-.692 1.928-1.062 3.12l.915-.22z"
				/>
				<Path
					fill="#A4614C"
					d="M48.788 33.479a4.927 4.927 0 01-1.183.964l-.077.041c.156 1.127.327 2.4.47 3.574l2.982-.027a.858.858 0 01.608.3 80.416 80.416 0 01-.245-6.25c.195-2.514.144-5.04-.151-7.544-.157-.478-.568-.151-.781-.148-.103.348-.19.7-.261 1.056.06.206.122.41.185.609a7.616 7.616 0 01-1.547 7.425z"
				/>
				<Path
					fill="#DFE2EA"
					d="M36.922 51.977c.917-.02 1.845-.034 2.786-.041a23.14 23.14 0 01-2.243-2.8 91.16 91.16 0 00-.543 2.841zM51.59 38.328a.851.851 0 00-.609-.3l-2.982.027h-.089a.36.36 0 00-.355.333l-.151 1.922-.011-.048a7.914 7.914 0 00-2.784-3.663c-.062.175-.122.356-.178.546-.002.168.017.336.056.5.482 1.965 1.225 4.8-.218 5.214s-4.753-5.368-4.8-7.3a2.504 2.504 0 00-1.448 2.124c.452.2.848.509 1.151.9 0 0 5.748 8.583 6.31 9.873.2.453 1.163.021 2.34-.765 2.168-1.445 5.052-4.083 5.166-4.576l.126.055-.032-.251c-.236-1.745-.744-3.831-1.492-4.591z"
				/>
				<Path
					fill="#B87154"
					d="M44.263 42.858c1.444-.415.7-3.247.218-5.212a2.056 2.056 0 01-.056-.5 7.114 7.114 0 01-3.7-5.27l.019-.054.206.239a8.598 8.598 0 004.512 2.746 3.312 3.312 0 002.063-.325l.077-.041a4.927 4.927 0 001.183-.964 7.619 7.619 0 001.549-7.429c-.063-.2-.125-.4-.185-.609-.462-1.583-.813-3.243-.816-3.243-.089-3.205-2.975-2.867-3.509-.172s-2.4 5.473-3.994 5.784-.618-.3-1.681-1.228-2.749.517-2.395 1.965 2.306 2.483 2.306 2.483l.153.177a24.134 24.134 0 00-.747 4.353c.049 1.929 3.359 7.713 4.797 7.3z"
				/>
				<Path
					fill="#A4614C"
					d="M40.955 32.063l-.207-.239-.019.054a7.117 7.117 0 003.7 5.27c.056-.191.116-.371.178-.546a9.399 9.399 0 01.857-1.793 8.586 8.586 0 01-4.509-2.746z"
				/>
				<Path
					fill="#81CEF8"
					d="M56.669 51.349a62.472 62.472 0 00-4.507-.493 11.422 11.422 0 01-1.33 2.213c-.9 1.07-5.238 3.151-6.854 1.415-.847-.909-2.366-1.936-4.084-3.928-.867.008-1.72.022-2.561.041-2.59.062-5.072.192-7.4.431-1.078.11-2.122.246-3.131.407-9.035 1.449-15.109 5.16-15.109 14.327v2.414h60.615V65.76c-.002-9.345-6.315-13.02-15.639-14.411z"
				/>
				<Path
					fill="#B87154"
					d="M54.961 37.265c-.454.824-.609 2.969-.655 2.5s-.645-1.8-.972-1.735-.213.9 0 1.573a16.941 16.941 0 01-.187 3.587l2.807 1.211c.107-.775.28-1.54.518-2.286.37-1.192.813-2.207 1.062-3.12a3.106 3.106 0 00-.344-2.865.913.913 0 00-1.161-.055c-.462.287-.832.7-1.068 1.19z"
				/>
				<Path
					fill="#FFF"
					d="M55.951 44.401l-2.807-1.211-.031-.013-.124-.055c-.113.492-3 3.131-5.166 4.576-1.176.784-2.142 1.216-2.34.765-.562-1.292-6.31-9.873-6.31-9.873a2.983 2.983 0 00-1.151-.9 2.51 2.51 0 011.448-2.127c.036-.46.085-.918.147-1.374a10.475 10.475 0 00-9.148 8.179c-.217.81-.415 1.712-.6 2.679-.416 2.226-.746 4.8-1 7.367 2.535-.239 5.235-.366 8.053-.431.165-.951.345-1.899.542-2.844a23.14 23.14 0 002.243 2.8c1.869 1.991 3.522 3.017 4.443 3.928 1.756 1.736 6.474-.344 7.456-1.415a11.287 11.287 0 001.447-2.213 62.25 62.25 0 003.243-7.689l-.345-.149z"
				/>
			</Svg>
		</View>
	);
}

export default SvgComponent;
