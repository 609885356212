import { useFocusEffect } from '@react-navigation/native';
import { debounce } from 'lodash';
import * as React from 'react';
import { AccessibilityInfo, GestureResponderEvent, Platform, findNodeHandle } from 'react-native';

const FOCUS_DELAY = 250;
const ANNOUNCE_DELAY = 500;
let SHOULD_AUTO_FOCUS_WEB = false;

/**
 * Use to imperatively announce message to assistive device for native
 * @param message
 * @returns timeoutId
 */
export function announceForAccessibility(message: string) {
	// Uses timeout because announceForAccessibility does not seem to work immediately
	return setTimeout(() => {
		AccessibilityInfo.announceForAccessibility(message);
	}, ANNOUNCE_DELAY);
}

/**
 * Effect hook that will try to announce message when it changes
 * @param message
 */
export function useAnnounceForAccessibilityEffect(message: string) {
	React.useEffect(() => {
		const timeoutId = announceForAccessibility(message);

		return () => clearTimeout(timeoutId);
	}, [message]);
}

/**
 * Use to imperatively focus element ref.current
 * Native will use AccessibilityInfo.setAccessibilityFocus() and web will use focus()
 * @param elementRef ref.current
 */
export function focusElement(elementRef: any) {
	// Web
	if (Platform.OS === 'web') {
		try {
			elementRef.focus();
		} catch (error) {
			console.warn(error);
		}

		return;
	}

	// Native
	const nativeNode = findNodeHandle(elementRef);

	if (!nativeNode) {
		return;
	}

	AccessibilityInfo.setAccessibilityFocus(nativeNode);
}

/**
 * Try to focus first element with id that contains the passed substring i.e. [id*="${substring}"]
 * Remember to add the id attr/prop to your target component, web only
 * @param substring
 */
export function tryToFocusFirstElementWithIdContainingSubstring(substring: string) {
	if (Platform.OS === 'web') {
		setTimeout(() => {
			const queriedElements = document.querySelectorAll(`[id*="${substring}"]`);

			if (queriedElements.length > 0) {
				try {
					const firstElement = queriedElements[0] as HTMLElement;
					firstElement?.focus();
				} catch (error) {
					// noop
				}
			}
		}, FOCUS_DELAY);
	}
}

/**
 * Get callback function to use to manually set focus to a component
 * Remember to add `accessible={true}` to target component
 * @returns [Manual focus ref , focus function]
 */
export function useAccessibilityFocus(
	delay = FOCUS_DELAY,
	existingRef?: React.MutableRefObject<any>
): [React.MutableRefObject<any>, () => void] {
	const ref = React.useRef<any>(null);
	const refToFocus = existingRef || ref;

	const setFocus = React.useCallback(() => {
		setTimeout(() => {
			focusElement(refToFocus.current);
		}, delay);
	}, [delay]);

	return [refToFocus, setFocus];
}

/**
 * Add returned ref to component to try to autofocus component
 * Remember to add `accessible={true}` to target component
 * @returns auto focus ref
 */
export function useAccessibilityAutoFocus(
	isActive = true,
	existingRef?: React.MutableRefObject<any>
) {
	const ref = React.useRef<any>(null);
	const autoFocusRef = existingRef || ref;
	const [isFocused, setIsFocused] = React.useState(false);
	const [isLayoutUpdated, setIsLayoutUpdated] = React.useState(false);

	React.useEffect(() => {
		setIsLayoutUpdated(true);

		return () => {
			setIsLayoutUpdated(false);
		};
	}, []);

	useFocusEffect(
		React.useCallback(() => {
			setIsFocused(true);

			return () => {
				setIsFocused(false);
			};
		}, [])
	);

	React.useEffect(() => {
		if (!isActive || !isFocused || !isLayoutUpdated || !autoFocusRef) {
			return;
		}

		// Prevent programmatic focus on initial web load
		if (Platform.OS === 'web' && !SHOULD_AUTO_FOCUS_WEB) {
			const debouncedEnableAutofocus = debounce(() => (SHOULD_AUTO_FOCUS_WEB = true), 500);
			debouncedEnableAutofocus();
			return;
		}

		const timeoutId = setTimeout(() => {
			focusElement(autoFocusRef.current);
		}, FOCUS_DELAY);

		return () => {
			clearTimeout(timeoutId);
		};
		// isActive is omitted here to prevent immediate focus upon activation
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoFocusRef, isFocused, isLayoutUpdated]);

	return autoFocusRef;
}

/**
 * Utility for handling the restoration of focus to an initiating component upon return focus of screen.
 * An example is the content card opening a modal. Focus returns to card after closing the detail screen.
 */
export function useRestoreFocus(existingRef?: React.MutableRefObject<any>): {
	/** ref to focus upon next screen focus, given storeFocusTarget has been called. */
	restoreFocusRef: React.MutableRefObject<any>;
	/**
	 * Method to arm current screen for specific element refocus upon next screen focus.
	 * If optional event param is provided, the passed event.target will focused rather than the supplied restoreFocusRef.
	 */
	storeFocusTarget: (event?: GestureResponderEvent) => void;
	/** Returns true if hook is armed for refocus on next screen focus. */
	hasStoredFocusTarget: boolean;
} {
	const ref = React.useRef<any>(null);
	const restoreFocusRef = existingRef || ref;
	const [shouldRestoreFocus, setShouldRestoreFocus] = React.useState(false);
	const hasStoredFocusTarget = shouldRestoreFocus;

	useFocusEffect(
		React.useCallback(() => {
			if (!shouldRestoreFocus) return;

			const timeoutId = setTimeout(() => {
				focusElement(restoreFocusRef.current);
				setShouldRestoreFocus(false);
			}, FOCUS_DELAY);

			return () => {
				clearTimeout(timeoutId);
			};
		}, [shouldRestoreFocus, restoreFocusRef])
	);

	function storeFocusTarget(event?: GestureResponderEvent) {
		setShouldRestoreFocus(true);

		if (event?.target) {
			const nodeHandle = findNodeHandle(getClosestButton(event.target));
			restoreFocusRef.current = nodeHandle;
		}
	}

	return { restoreFocusRef, storeFocusTarget, hasStoredFocusTarget };
}

/**
 * When interacting with elements on the web via a screen reader, the event.target is often a
 * child node of the button element. This function returns the nearest button, whether that is
 * the element that has been passed or a parent element up the document tree.
 */
const getClosestButton = (eventTarget: React.Component) => {
	if (!eventTarget) return null;

	try {
		const eventTargetWeb = eventTarget as unknown as HTMLElement;
		const targetElement =
			Platform.OS === 'web' ? eventTargetWeb.closest('button') || null : eventTarget;

		return targetElement as React.Component | null;
	} catch (error) {
		console.warn(error);
		return null;
	}
};
