import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import Hr from '../hr/Hr';
import Text from '../text/Text';
import BaseInputLabel from './BaseInputLabel';

export interface IInputReadOnly {
	/**
	 * String for the input label.
	 */
	label: string;

	/**
	 * Value to be displayed in read only field.
	 */
	value: string;
}

export default function InputReadOnly({ label, value }: IInputReadOnly) {
	const { vr } = useStyleHelpers();

	return (
		<>
			<BaseInputLabel style={vr(1)}>{label}</BaseInputLabel>
			<Text style={vr(3)}>{value || ' '}</Text>
			<Hr style={vr(3)} />
		</>
	);
}
