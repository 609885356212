import { useAccessibilityAutoFocus } from '@mobe/utils/a11y';
import * as React from 'react';
import { Text as RNText } from 'react-native';
import useTextStyles, {
	TextAlign,
	TextColor,
	TextLineHeight,
	TextSizes,
	TextWeight,
} from './textStyles';

type Props = {
	size?: TextSizes;
	weight?: TextWeight;
	color?: TextColor;
	align?: TextAlign;
	lineHeight?: TextLineHeight;
	accessibilityAutoFocus?: boolean;
};

export type TextProps = Props & RNText['props'];

function Text(
	{
		size = 'md',
		weight = 'regular',
		color = 'regular',
		align = 'left',
		lineHeight = 'regular',
		accessibilityAutoFocus = false,
		style,
		...textProps
	}: TextProps,
	ref: any
) {
	const textStyles = useTextStyles({ size, weight, color, align, lineHeight });
	const textAutoFocusRef = useAccessibilityAutoFocus(accessibilityAutoFocus, ref);

	return (
		<RNText
			ref={textAutoFocusRef}
			style={[textStyles, style]}
			allowFontScaling={false}
			accessible={accessibilityAutoFocus ? true : undefined} // add accessible prop explicitly for autofocus
			{...{ tabIndex: accessibilityAutoFocus || textProps.accessible ? -1 : undefined }} // TODO: When RN fixes types for tabIndex please add the prop vs this spread workaround
			{...textProps}
		/>
	);
}

export default React.forwardRef(Text);
