import Box from '@mobe/components/box/Box';
import { GoalTargetGraphic } from '@mobe/components/graphics';
import Heading from '@mobe/components/heading/Heading';
import Hr from '@mobe/components/hr/Hr';
import Row from '@mobe/components/layout/Row';
import MobeParsedText from '@mobe/components/mobeParsedText/MobeParsedText';
import { Text } from '@mobe/components/text';
import useTextStyles from '@mobe/components/text/textStyles';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ViewProps } from 'react-native';
import LTGoalGraphic from '../../plan/components/graphics/LTGoalGraphic';
import { ITrackerContent } from '../useTrackerContent';

interface IGoalModule extends ViewProps {
	tracker: ITrackerContent;
	goalValue?: number | null;
	goalMetCount?: number;
	onUpdatePress: () => void;
}

export default function GoalModule({
	tracker,
	goalValue,
	goalMetCount,
	onUpdatePress,
	...viewProps
}: IGoalModule) {
	const { vr, vrTop, constrain } = useStyleHelpers();
	const boldTextStyle = useTextStyles({ weight: 'semiBold', size: 'sm' });
	const { t } = useTranslation();
	const hasGoal = typeof goalValue === 'number';

	return (
		<Box {...viewProps}>
			<Row style={vr(2)} align="top">
				<Row.Item fill>
					<Heading level="h4">
						{t('track.detail.goalModuleTitle', { trackerTitle: tracker.title.toLowerCase() })}
					</Heading>
				</Row.Item>
				<Row.Item>
					<TextButton
						title={
							hasGoal
								? t('track.detail.goalModuleUpdateButton')
								: t('track.detail.goalModuleSetGoalButton')
						}
						onPress={onUpdatePress}
					/>
				</Row.Item>
			</Row>
			{hasGoal ? (
				<>
					<Row>
						<Row.Item fill>
							<Text size="lg" weight="bold">
								{tracker.goalValue
									? tracker.goalValue(goalValue)
									: `${goalValue.toLocaleString()} ${tracker.unit(goalValue)}`}
							</Text>
							<Text size="sm" color="light">
								{tracker.chartLabel}
							</Text>
						</Row.Item>
						<Row.Item>
							<View style={constrain(50)}>
								<GoalTargetGraphic />
							</View>
						</Row.Item>
					</Row>
					{Boolean(goalMetCount) && (
						<>
							<Hr style={vrTop(4)} />
							<Text size="sm" style={vrTop(4)}>
								<MobeParsedText
									parse={[
										{
											pattern: new RegExp(
												t('track.detail.goalModuleDaysMet', { count: goalMetCount })
											),
											style: boldTextStyle,
										},
									]}
								>
									{t('track.detail.goalModuleDaysMetMessage', { count: goalMetCount })}
								</MobeParsedText>
							</Text>
						</>
					)}
				</>
			) : (
				<Row gutterSize={10}>
					<Row.Item fill>
						<Text color="light" size="sm">
							{t('track.detail.goalModuleNotSetMessage')}
						</Text>
					</Row.Item>
					<Row.Item>
						<View style={constrain(70)}>
							<LTGoalGraphic />
						</View>
					</Row.Item>
				</Row>
			)}
		</Box>
	);
}
