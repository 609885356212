import { useTrackersGoalMutation } from '@mobe/api/track/trackApiHooks';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { useAlert } from '@mobe/utils/useAlert';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useTrackerContent from '../useTrackerContent';
import { ITrackGoalScreenProps } from './TrackGoalScreen';

export default function useGoalScreenController({ navigation, route }: ITrackGoalScreenProps) {
	const trackersGoalMutation = useTrackersGoalMutation();
	const trackerContent = useTrackerContent()[route.params.trackerAbbreviation];
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const trackerTypeId = getTrackerIdFromAbbreviation(route.params.trackerAbbreviation);
	const { t } = useTranslation();
	const genericErrorAlert = useGenericErrorAlert();
	const { form, renderForm } = trackerContent.useForm({
		tracker: trackerContent,
		isGoal: true,
		goal: route.params.goal,
		onKeyboardSubmit: handleSubmit,
	});

	// For unbinding navigation listener after form submit
	const [formSubmitted, setFormSubmitted] = React.useState(false);

	const shouldAlertBeforeExit = form.formState.isDirty && form.formState.isValid && !formSubmitted;
	const { mobeAlert } = useAlert();

	// Display a confirmation alert if screen is popped before submission with a dirty form
	React.useEffect(() => {
		if (!shouldAlertBeforeExit) {
			return;
		}

		const removeListener = navigation.addListener('beforeRemove', (event) => {
			event.preventDefault();

			mobeAlert(t('track.entry.exitAlertTitle'), '', [
				{
					text: t('track.entry.exitAlertDiscard'),
					style: 'destructive',
					onPress: () => navigation.dispatch(event.data.action),
				},
				{
					text: t('track.entry.exitAlertSave'),
					onPress: handleSubmit,
				},
			]);
		});

		return removeListener;
	}, [navigation, shouldAlertBeforeExit]);

	// Pop screen after form is submitted
	React.useEffect(() => {
		if (formSubmitted) {
			navigation.pop();
		}
	}, [formSubmitted, navigation]);

	/**
	 * Adds entry via trackersAddEntryMutation.
	 */
	function addGoal(goalValue: number) {
		trackersGoalMutation
			.mutateAsync({
				trackerTypeId,
				goalValue,
			})
			.then(() => setFormSubmitted(true))
			.catch(handleError);
	}

	/**
	 * Deletes entry via trackersDeleteEntryMutation.
	 */
	function deleteGoal() {
		trackersGoalMutation
			.mutateAsync({
				trackerTypeId,
				goalValue: null,
			})
			.then(() => setFormSubmitted(true))
			.catch(handleError);
	}

	/**
	 * Generic error alert which pops screen on confirmation.
	 */
	function handleError() {
		genericErrorAlert(() => setFormSubmitted(true));
	}

	/**
	 * Handler for both addEntry and updateEntry. If entry ID exists as a route param, it updates.
	 */
	function handleSubmit() {
		form.handleSubmit((data) => {
			addGoal(data.tracker);
		})();
	}

	/**
	 * Handler for deleting an entry. Confirms deletion with a native alert dialog.
	 */
	function handleDelete() {
		mobeAlert(t('track.updateEntry.deleteEntryAlertTitle'), '', [
			{
				text: t('track.updateEntry.deleteEntryAlertCancel'),
				style: 'cancel',
				onPress: noop,
			},
			{
				text: t('track.updateEntry.deleteEntryAlertConfirm'),
				style: 'destructive',
				onPress: deleteGoal,
			},
		]);
	}

	return {
		form,
		handleSubmit,
		handleDelete,
		renderForm,
		trackersGoalMutation,
	};
}
