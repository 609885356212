import { Button } from '@mobe/components/button';
import Text from '@mobe/components/text/Text';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

export interface IActionSectionWithInstructionsProps {
	type: 'buttonWithInstructions';
	label: string;
	onPress: () => void;
	buttonTitle: string;
}

export default function ActionSectionWithInstructions({
	label,
	onPress,
	buttonTitle = '',
}: IActionSectionWithInstructionsProps) {
	return (
		<View style={styles.container}>
			<View style={styles.labelContainer}>
				<Text size="lg" align="center">
					{label}
				</Text>
			</View>
			<Button onPress={onPress} title={buttonTitle} size="sm" style={{ alignSelf: 'center' }} />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		minHeight: 72,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingVertical: 32,
		paddingHorizontal: 16,
	},
	labelContainer: {
		marginBottom: 20,
	},
});
