import { INativeTrackerAdapter } from '../validicServiceUtils';

// Mock hook for all platforms besides android, there should be an android specific implementation of this hook at googleFit.android.ts
export default function useGoogleFitAdapter(): INativeTrackerAdapter {
	return {
		isAvailable: false,
		isConnected: false,
		async connect() {
			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		},
		async disconnect() {
			await new Promise((resolve) => {
				setTimeout(resolve, 500);
			});
		},
	};
}
