import { Ionicons } from '@expo/vector-icons';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { validatePassword } from '@mobe/utils/validationUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import Text from '../text/Text';

interface IPasswordRequirementItemProps {
	label: string;
	valid: boolean;
}

function PasswordRequirementItem({ label, valid }: IPasswordRequirementItemProps) {
	const styleRules = useStyleRules();
	const { t } = useTranslation();

	return (
		<View
			style={{ flexDirection: 'row', alignItems: 'baseline' }}
			accessible
			aria-label={`${label}, ${
				valid ? t('auth.passwordRequirements.met') : t('auth.passwordRequirements.notMet')
			}`}
		>
			<View>
				{valid ? (
					<Ionicons name="checkmark-sharp" color={styleRules.colors.successDark} size={16} />
				) : (
					<Ionicons name="close-sharp" color={styleRules.colors.error} size={16} />
				)}
			</View>
			<View>
				<Text size="md">{label}</Text>
			</View>
		</View>
	);
}

interface IPasswordRequirementsListProps {
	password: string;
}

export default function PasswordRequirementsList({ password }: IPasswordRequirementsListProps) {
	const styleRules = useStyleRules();
	const result = validatePassword(password);
	const { t } = useTranslation();

	return (
		<View style={{ backgroundColor: styleRules.colors.background, padding: 8 }}>
			<PasswordRequirementItem
				label={t('auth.passwordRequirements.length')}
				valid={result.length}
			/>
			<PasswordRequirementItem
				label={t('auth.passwordRequirements.lowercase')}
				valid={result.lowercase}
			/>
			<PasswordRequirementItem
				label={t('auth.passwordRequirements.uppercase')}
				valid={result.uppercase}
			/>
			<PasswordRequirementItem
				label={t('auth.passwordRequirements.numeric')}
				valid={result.numeric}
			/>
		</View>
	);
}
