import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function PaperClipGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(13, 25);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#555"
					d="M12.675 18.212 8.213 3.439C6.608-1.673.538-.74.779 4h1.185C1.77.556 5.999.379 7.051 3.784l4.452 14.646c.677 2.194-.128 4.394-2.322 5.072-2.194.677-4.119-.65-4.791-2.875L1.16 10.221c-.225-.731-1.356-.382-1.13.35l3.24 10.534c.889 2.828 3.474 4.548 6.301 3.661l.003-.002c2.822-.858 3.99-3.724 3.101-6.552z"
				/>
			</Svg>
		</View>
	);
}
