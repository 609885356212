import Icon from '@mobe/components/icon/Icon';
import IconButton from '@mobe/components/iconButton/IconButton';
import { BaseInput, IBaseInputProps } from '@mobe/components/input';
import { useAccessibilityFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as ExploreAnalyticsEvents from '../analyticsEvents';

function ExploreSearch(baseInputProps: IBaseInputProps, ref: any) {
	const { wrapperHorizontal } = useStyleHelpers();
	const styleRules = useStyleRules();
	const { t } = useTranslation();
	const [inputRef, setInputFocus] = useAccessibilityFocus(0, ref);

	const styles = StyleSheet.create({
		inputContainer: {
			backgroundColor: styleRules.colors.strokeLight,
			borderBottomColor: styleRules.colors.stroke,
			borderBottomWidth: 1,
			paddingVertical: 10,
		},
		closeButtonCircle: {
			alignItems: 'center',
			justifyContent: 'center',
			width: 18,
			aspectRatio: 1,
			borderRadius: 14,
			backgroundColor: styleRules.colors.textLight,
		},
	});

	function handleFocus() {
		ExploreAnalyticsEvents.searchFocus();
	}

	function handleClearInputPress() {
		if (baseInputProps.onChangeText) {
			baseInputProps.onChangeText('');
			setInputFocus();
		}
	}

	return (
		<>
			<View style={[wrapperHorizontal, styles.inputContainer]}>
				<BaseInput
					ref={inputRef}
					{...baseInputProps}
					role="searchbox"
					placeholder={t('explore.searchInputPlaceholder')}
					inputLeft={() => (
						<Icon
							accessibilityElementsHidden
							importantForAccessibility="no-hide-descendants"
							name="search"
							size={18}
						/>
					)}
					inputRight={() =>
						baseInputProps.value ? (
							<View style={styles.closeButtonCircle}>
								<IconButton
									aria-label={t('explore.clearSearchAccessibilityLabel')}
									name="close"
									size={9}
									color="textInverted"
									hitSlop={15}
									onPress={handleClearInputPress}
								/>
							</View>
						) : null
					}
					onFocus={handleFocus}
				/>
			</View>
		</>
	);
}

export default React.forwardRef(ExploreSearch);
