import IEnv, { EnvType } from './IEnv';

export default class AppEnv implements IEnv {
	ENV_NAME = 'Unnamed';
	ENV_TYPE: EnvType = EnvType.Unset;
	APP_VERSION = '';
	BUILD_DATE = '';
	DEFAULT_LOGIN_PASSWORD = '';
	DEFAULT_LOGIN_USERNAME = '';
	INACTIVITY_TIMEOUT_IN_MS = -1;
	MOBE_API_URL = '';
	SIGNALR_URL = '';
	VALIDIC_ORG_ID = '';
	WEB_STATIC_ASSETS_URL = '';
	TERMS_AND_CONDITIONS_URL = '';
	PRIVACY_POLICY_URL = '';
	ENABLE_ANALYTICS = false;
	ENABLE_ANALYTICS_DEBUG = false;

	REDIRECT_TO_SUCCESS_SCREEN_URL = '';
	REDIRECT_TO_CONNECT_HEALTH_DATA_URL = '';

	constructor(envData: IEnv) {
		this.override(envData);
	}

	override(envData: Partial<IEnv>) {
		Object.assign(this, envData);
	}

	get isDev() {
		return this.ENV_TYPE === EnvType.Dev;
	}
}
