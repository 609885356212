import Icon from '@mobe/components/icon/Icon';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, PressableProps } from 'react-native';

export default function AddEntryButton({ ...pressableProps }: PressableProps) {
	const styleRules = useStyleRules();
	const { popupShadow } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<Pressable
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				width: 36,
				height: 36,
				aspectRatio: 1,
				backgroundColor: styleRules.colors.primary,
				borderRadius: 23,
				position: 'absolute',
				right: -13,
				bottom: -13,
				...popupShadow,
			}}
			role="button"
			hitSlop={5}
			{...pressableProps}
		>
			<Icon name="plus" color="textInverted" />
		</Pressable>
	);
}
