import * as React from 'react';

function write(content: string) {
	return navigator.clipboard.writeText(content);
}

function read() {
	return navigator.clipboard.readText();
}

export function useClipboard() {
	const [value, setValue] = React.useState<string>('');

	React.useEffect(() => {
		async function getInitialClipboardValue() {
			const clipboardValue = await read();
			setValue(clipboardValue);
		}

		getInitialClipboardValue();
	}, []);

	async function writeClipboardValue(content: string) {
		await write(content);
		setValue(content);
	}

	return [value, writeClipboardValue];
}

export default {
	write,
	read,
};
