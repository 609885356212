import { ActivityType, IActivity, IActivityFull } from '@mobe/api/activities/activitiesService';
import { ExploreContentType } from '@mobe/api/explore/exploreService';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGetTrackerIdFromAbbreviation } from '@mobe/api/track/trackApiUtils';
import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import CardButton from '@mobe/components/cardButton/CardButton';
import Icon from '@mobe/components/icon/Icon';
import Note from '@mobe/components/note/Note';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import { useNavigation } from '@react-navigation/native';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GestureResponderEvent, StyleSheet } from 'react-native';
import useTimeToConsumeMessage from '../../explore/useTimeToConsumeMessage';
import { BottomTabStackScreenNavigationProp } from '../../navigation/menu/types';
import * as ActivityAnalytics from '../analyticsEvents';

interface IActivityCardProps {
	activityData: IActivity | IActivityFull;
	completed?: boolean;

	/**
	 * When true, the card can still be clicked after the activity has been completed.
	 */
	remainActiveWhenCompleted?: boolean;
	customEvent?: () => void;
	onPress?: (event: GestureResponderEvent) => void;
}

export default function ActivityCard({
	activityData,
	completed = false,
	remainActiveWhenCompleted = false,
	customEvent = noop,
	onPress = noop,
}: IActivityCardProps) {
	const { vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();
	const navigation = useNavigation<BottomTabStackScreenNavigationProp>();
	const getTrackerIdFromAbbreviation = useGetTrackerIdFromAbbreviation();
	const genericErrorAlert = useGenericErrorAlert();

	const dateCompleted = 'dateCompleted' in activityData ? activityData.dateCompleted : undefined;

	let sharedContentType = activityData.sharedContentType || ExploreContentType.Article;
	sharedContentType = Object.values(ExploreContentType).includes(sharedContentType)
		? sharedContentType
		: ExploreContentType.Article;

	let activityType = activityData.type || ActivityType.SharedContent;
	activityType = Object.values(ActivityType).includes(activityType)
		? activityType
		: ActivityType.SharedContent;

	const sharedContentTimeToConsume = useTimeToConsumeMessage({
		contentType: sharedContentType,
		timeToConsume: activityData.timeToConsumeInMinutes || 0,
	});

	const activityColor = {
		[ActivityType.MedSummary]: '#00b388',
		[ActivityType.Survey]: '#f2c75c',
		[ActivityType.SharedContent]: '#41b6e6',
		[ActivityType.System]: '#ff8200',
		[ActivityType.Assessment]: '#512d6d',
		[ActivityType.GuideMatch]: '#ff8200',
		[ActivityType.Track]: '#512d6d',
		[ActivityType.ClientProgramReferral]: '#e32e11',
	}[activityType];

	const styles = useStyles({ activityColor, completed, remainActiveWhenCompleted });

	/**
	 * Completed message that only displays on completed activities.
	 */
	const completedMessage = dateCompleted
		? t('activities.activityCard.completionDate', {
				date: format(new Date(dateCompleted), 'M/d/yy'),
		  })
		: '';

	/**
	 * Consumption time message that only displays on uncompleted activities.
	 */
	const timeToConsumeMessage = activityData.timeToConsumeInMinutes
		? activityType === ActivityType.SharedContent
			? sharedContentTimeToConsume
			: t('activities.activityCard.timeToConsume.generic', {
					count: activityData.timeToConsumeInMinutes,
			  })
		: '';

	/**
	 * Blurb based on activity type that displays at top of card.
	 * Shared content is identified by its specific content type, e.g. "article", "video".
	 */
	const activityTypeTitle: string = {
		[ActivityType.MedSummary]: t('activities.activityCard.activityTypeTitle.medSummary'),
		[ActivityType.Survey]: t('activities.activityCard.activityTypeTitle.survey'),
		[ActivityType.System]: t('activities.activityCard.activityTypeTitle.system'),
		[ActivityType.SharedContent]: {
			[ExploreContentType.Article]: t('activities.activityCard.activityTypeTitle.article'),
			[ExploreContentType.Video]: t('activities.activityCard.activityTypeTitle.video'),
			[ExploreContentType.Recipe]: t('activities.activityCard.activityTypeTitle.recipe'),
			[ExploreContentType.Audio]: t('activities.activityCard.activityTypeTitle.audio'),
			[ExploreContentType.Habits]: t('activities.activityCard.activityTypeTitle.habits'),
		}[sharedContentType],
		[ActivityType.Assessment]: t('activities.activityCard.activityTypeTitle.assessment'),
		[ActivityType.GuideMatch]: t('activities.activityCard.activityTypeTitle.guideMatch'),
		[ActivityType.Track]: t('activities.activityCard.activityTypeTitle.tracker'),
		[ActivityType.ClientProgramReferral]: t(
			'activities.activityCard.activityTypeTitle.clientProgramReferral'
		),
	}[activityType];

	function onCompletedPress() {
		// Shared content is the only activity type that can be viewed after completion
		if (activityType !== ActivityType.SharedContent) {
			return;
		}

		navigation.navigate('EXPLORE_DETAIL_SCREEN', {
			contentId: Number(activityData.activityTargetId),
		});
	}

	function onActivePress() {
		customEvent();

		if (activityType === ActivityType.GuideMatch) {
			ActivityAnalytics.activityGuideMatchPress();
			navigation.navigate('APPOINTMENT_SCREEN', { coachType: CoachType.GSM });
			return;
		}

		if (activityType === ActivityType.Assessment) {
			navigation.navigate('ONBOARDING_SCREEN');
			return;
		}

		if (activityType === ActivityType.ClientProgramReferral) {
			navigation.navigate('CLIENT_PROGRAM_REFERRALS_DETAIL_SCREEN', {
				programId: Number(activityData.activityTargetId),
				activityId: Number(activityData.id),
			});
			return;
		}

		if (activityType === ActivityType.Track) {
			const trackerAbbreviation = activityData.activityTargetUrl as TrackerAbbreviation;

			if (!Object.values(TrackerAbbreviation).includes(trackerAbbreviation)) {
				genericErrorAlert();
				return;
			}

			const trackerTypeId = getTrackerIdFromAbbreviation(trackerAbbreviation);

			if (!trackerTypeId) {
				genericErrorAlert();
				return;
			}

			navigation.navigate('TRACK_ENTRY_MODAL_SCREEN', {
				trackerAbbreviation,
			});
			return;
		}

		navigation.navigate('ACTIVITIES_DETAIL_SCREEN', { activityId: activityData.id });
	}

	function handleOnPress(event: GestureResponderEvent) {
		onPress(event);

		if (completed) {
			onCompletedPress();
		} else {
			onActivePress();
		}
	}

	return (
		<CardButton
			onPress={handleOnPress}
			style={styles.card}
			cardButtonLeft={completed && <Icon name="checkmark" size={15} />}
			cardButtonRight={completed && remainActiveWhenCompleted && <Icon name="right" />}
			disabled={completed && !remainActiveWhenCompleted}
		>
			<Text weight="medium" color={completed && !remainActiveWhenCompleted ? 'light' : 'regular'}>
				{activityTypeTitle}
			</Text>
			<Text
				weight="semiBold"
				color={completed && !remainActiveWhenCompleted ? 'light' : 'regular'}
				style={vr(1)}
				numberOfLines={2}
			>
				{activityData.title}
			</Text>
			<Text weight="medium" size="sm" color="light">
				{completed ? completedMessage : timeToConsumeMessage}
			</Text>
			{typeof activityData.note === 'string' && (
				<Note style={vrTop(5)} message={activityData.note} />
			)}
		</CardButton>
	);
}

function useStyles({
	activityColor,
	completed,
	remainActiveWhenCompleted,
}: {
	activityColor: string;
	completed: boolean;
	remainActiveWhenCompleted: boolean;
}) {
	const rules = useStyleRules();
	const { noCardShadow } = useStyleHelpers();

	return StyleSheet.create({
		card: {
			marginBottom: rules.spacing.baseline * 5,
			borderColor: activityColor,
			borderLeftWidth: 6,
			borderTopWidth: 0,
			borderRightWidth: 0,
			borderBottomWidth: 0,

			...(completed && {
				borderLeftWidth: 1,
				borderTopWidth: 1,
				borderRightWidth: 1,
				borderBottomWidth: 1,
				borderColor: 'transparent',
			}),

			...(completed &&
				!remainActiveWhenCompleted && {
					borderColor: rules.colors.strokeLight,
					...noCardShadow,
				}),
		},
	});
}
