import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import useGoogleFitAdapter from './validic/googleFit';
import useHealthKitAdapter from './validic/healthKit';
import { IValidicService } from './validicServiceUtils';

const ValidicServiceContext = createContext<IValidicService | undefined>({} as IValidicService);

export function ValidicServiceProvider({ children }: { children: ReactNode }) {
	const healthKitAdapter = useHealthKitAdapter();
	const googleFitAdapter = useGoogleFitAdapter();

	const contextValue = useMemo(
		() => ({
			isConnected: false,
			healthKit: healthKitAdapter,
			googleFit: googleFitAdapter,
		}),
		[googleFitAdapter, healthKitAdapter]
	);

	return (
		<ValidicServiceContext.Provider value={contextValue}>{children}</ValidicServiceContext.Provider>
	);
}

export function useValidicService() {
	const context = useContext(ValidicServiceContext);

	if (!context) {
		throw new Error('useValidicService must be used within a ValidicServiceProvider');
	}

	return context;
}
