import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function MedicineGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(55.1, 91);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M14.8 30.2h25.5c.9 0 1.7-.4 2.3-1 .6-.6.9-1.4.9-2.3V14.5c0-.9-.3-1.7-.9-2.3-.6-.6-1.4-1-2.3-1H14.8c-1.8 0-3.2 1.5-3.2 3.3V27c0 .9.3 1.7.9 2.3.6.6 1.4.9 2.3.9z"
					fill="#2C49A2"
				/>
				<Path
					d="M14.8 11.3h25.5c1.9.1 3.3 1.7 3.2 3.6v6.7H11.6v-6.7c-.1-2 1.3-3.6 3.2-3.6z"
					fill="#003265"
				/>
				<Path
					fill="#3E619E"
					d="M9.5 18.9h37.6c1.7 0 3.1-2.1 3.1-4.7V4.7c0-2.6-1.4-4.7-3.1-4.7H9.5C7.8 0 6.4 2.1 6.4 4.7v9.5c0 2.6 1.4 4.7 3.1 4.7z"
				/>
				<Path
					fill="#718BC6"
					d="M10.6 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.2.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M15.7 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M20.9 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M26 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M30.6 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.2.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M35.7 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.7-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M40.9 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.6-.7 1.1-1.3 1z"
				/>
				<Path
					fill="#718BC6"
					d="M46 16.9c-.6.1-1.2-.4-1.3-1V3.5c.1-.6.7-1 1.3-1 .6-.1 1.2.4 1.3 1v12.4c-.1.7-.6 1.1-1.3 1z"
				/>
				<Path
					fill="#3E619E"
					d="M14.5 91h26.1c8.1-.1 14.6-6.7 14.5-14.8V38.1c.1-8.1-6.4-14.8-14.5-14.8H14.5C6.4 23.3-.1 29.9 0 38.1v38.1C-.1 84.3 6.4 90.9 14.5 91z"
				/>
				<Path
					d="M0 44.4v25.9c.5.2 1.1.3 1.6.3h51.1c.8 0 1.6-.2 2.3-.6V44.7c-.7-.4-1.5-.6-2.3-.6H1.6c-.5 0-1.1.1-1.6.3z"
					fill="#C7D9F8"
				/>
				<Path
					fill="#718BC6"
					d="M7.3 48.9h16.9c.8 0 1.5.7 1.5 1.5v.8c0 .8-.7 1.5-1.5 1.5H7.3c-.8 0-1.5-.7-1.5-1.5v-.8c.1-.8.7-1.5 1.5-1.5z"
				/>
				<Path
					fill="#718BC6"
					d="M28.3 59.1H5.7c-.5 0-1-.2-1.3-.5-.3-.3-.5-.8-.5-1.3s.2-1 .5-1.3.8-.5 1.3-.5h22.6c.5 0 1 .2 1.3.5.3.3.5.8.5 1.3s-.2 1-.5 1.3c-.3.3-.8.5-1.3.5z"
				/>
				<Path
					d="M47.7 40.9c-2-1.4-4.6-.3-5.8 2.4-1.2-2.7-3.8-3.8-5.8-2.4-2.1 1.9-2.6 5-1.4 7.5 2.1 2.7 4.5 5.1 7.4 7.1 2.8-1.9 5.1-4.3 6.9-7.1 1.4-2.6.8-5.7-1.3-7.5z"
					fill="#FFC93B"
				/>
				<Path
					d="M40.6 50.9c-.3 0-.5-.2-.6-.4l-1.7-4.6s0-.1-.1-.1c0 0-.1 0-.1.1l-.9 2.4c-.1.2-.3.4-.6.4H34c-.1 0-.3-.1-.3-.3s.1-.3.3-.3h2.6s.1 0 .1-.1l.9-2.3c.1-.2.3-.4.6-.4s.5.2.6.4l1.7 4.6c0 .1.1.1.1.1s.1 0 .1-.1l.2-1.7c0-.3.3-.6.6-.6h4.7c.1 0 .3.1.3.3s-.1.3-.3.3h-4.7c-.1 0-.1.1-.1.1l-.2 1.7c0 .2-.3.5-.6.5z"
					fill="#FFF"
				/>
			</Svg>
		</View>
	);
}
