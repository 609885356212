import AppEnv from './AppEnv';
import IEnv from './IEnv';
import prodEnvConfig from './_prod';

const env: IEnv = {
	...prodEnvConfig,

	// include any local environment overrides below

};

export default new AppEnv(env);
