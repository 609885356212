import * as React from 'react';
import { DimensionValue, PressableProps, View } from 'react-native';
import { Text } from '../text';
import TextButton from '../textButton/TextButton';
import { useDataCardStyles } from './useDataCardStyles';

export enum CardLayout {
	Block = 'block',
	Row = 'row',
}

export interface IDataCardProps extends PressableProps {
	count: number | null;
	title: string;
	layout?: `${CardLayout}`;
	detail?: string;
	ctaLabel?: string;
	imageWidth?: DimensionValue;
	onPressCta?: () => void;
	renderImage?: () => React.ReactNode;
	renderDetails?: () => React.ReactNode;
	renderFooter?: () => React.ReactNode;
}

export default function DataCard({
	count,
	title,
	detail,
	layout = 'row',
	ctaLabel,
	renderImage,
	renderDetails,
	renderFooter,
	onPressCta,
	imageWidth = '100%',
}: IDataCardProps) {
	const styles = useDataCardStyles();
	const hasData = Boolean(count);
	const shouldShowCTA = Boolean(count && count > 1 && ctaLabel);

	return (
		<View style={styles.wrapper}>
			<View
				style={[
					styles.card,
					hasData ? styles.card_restrict : {},
					layout === CardLayout.Block ? styles.card_block : {},
				]}
			>
				<View style={styles.primaryContainer}>
					<View
						accessible
						style={[
							styles.bodyContainer,
							layout === CardLayout.Block ? styles.bodyContainer_block : {},
						]}
					>
						<View
							style={[
								styles.countContainer,
								layout === CardLayout.Block ? styles.countContainer_block : {},
							]}
						>
							<Text
								aria-label={count ? count.toString() : (0).toString()}
								style={[styles.count, !count ? styles.count_null : {}]}
							>
								{count ? count : '--'}
							</Text>
						</View>
						<View style={styles.detailContainer}>
							<Text weight="semiBold" lineHeight="tight">
								{title}
							</Text>
							{Boolean(detail) && (
								<Text size="sm" weight="medium">
									{detail}
								</Text>
							)}
						</View>
					</View>
					{Boolean(renderDetails) && (
						<View style={styles.noteContainer}>{renderDetails ? renderDetails() : null}</View>
					)}
				</View>
				{Boolean(renderImage) && (
					<View
						style={[
							styles.secondaryContainerImage,
							layout === CardLayout.Block ? styles.secondaryContainerImage_block : {},
						]}
					>
						<View style={{ width: imageWidth }}>{renderImage ? renderImage() : null}</View>
					</View>
				)}
				{shouldShowCTA && (
					<View style={styles.secondaryContainerCta}>
						<TextButton title={ctaLabel as string} onPress={onPressCta} />
					</View>
				)}
			</View>
			{renderFooter && <View style={styles.footer}>{renderFooter ? renderFooter() : null}</View>}
		</View>
	);
}
