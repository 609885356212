import { useAssessmentMutation, useAssessmentQuery } from '@mobe/api/assessment/AssessmentApiHooks';
import LikertScaleInput from '@mobe/components/input/InputLikertScale';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	OnboardingActivityScreenNavigationProp,
	OnboardingActivityScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingScreenTemplate from '../components/OnboardingScreenTemplate';
import OnboardingActivityGraphic from '../components/graphics/OnboardingActivityGraphic';
import useShouldDelayMutation from '../useShouldDelayMutation';

interface IOnboardingActivityScreenProps {
	navigation: OnboardingActivityScreenNavigationProp;
	route: OnboardingActivityScreenRouteProp;
}

export default function OnboardingActivityScreen({ route }: IOnboardingActivityScreenProps) {
	const shouldDelay = useShouldDelayMutation();
	const assessmentMutation = useAssessmentMutation(shouldDelay);
	const assessmentQuery = useAssessmentQuery();
	const value = assessmentQuery.data?.physicalActivity;
	const { t } = useTranslation();
	const inputLabels = t('onboarding.activityScreen', { returnObjects: true });

	function handleOnPress(value: number) {
		assessmentMutation.mutate({ physicalActivity: value });
	}

	return (
		<OnboardingScreenTemplate
			routeName={route.name}
			graphic={<OnboardingActivityGraphic />}
			value={value}
			isLoading={assessmentMutation.isLoading}
		>
			<LikertScaleInput
				{...inputLabels}
				value={value}
				onPress={handleOnPress}
				accessibilityAutoFocus
			/>
		</OnboardingScreenTemplate>
	);
}
