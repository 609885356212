import { useAssignQuery, useSubmitSurveyMutation } from '@mobe/api/survey/surveyApiHooks';
import { SurveyResponses } from '@mobe/api/survey/surveyService';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import Icon from '@mobe/components/icon/Icon';
import Survey, { SurveyErrorView } from '@mobe/components/survey/Survey';
import { useToast } from '@mobe/components/toast/Toast';
import useSubScreenStyleProps from '@mobe/utils/styles/navigation/useSubScreenStyleProps';
import useGenericErrorAlert from '@mobe/utils/useGenericErrorAlert';
import useTransitions from '@mobe/utils/useTransitions';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	AssignSurveyScreenNavigationProp,
	AssignSurveyScreenRouteProp,
} from '../navigation/modal/types';

export interface IAssignSurveyScreenParams {
	surveyId: number;
}

interface IAssignSurveyScreenProps {
	navigation: AssignSurveyScreenNavigationProp;
	route: AssignSurveyScreenRouteProp;
}

export default function AssignSurveyScreen({ navigation, route }: IAssignSurveyScreenProps) {
	const { t } = useTranslation();
	const toast = useToast();
	const surveyId = route.params.surveyId.toString();
	const assignSurveyQuery = useAssignQuery(surveyId);
	const submitSurveyMutation = useSubmitSurveyMutation();
	const genericErrorAlert = useGenericErrorAlert();

	function handleOnSubmit(answerData: SurveyResponses) {
		if (!assignSurveyQuery.data?.token) {
			genericErrorAlert(navigation.pop);
			return;
		}

		submitSurveyMutation.mutate(
			{ token: assignSurveyQuery.data.token, responses: answerData },
			{
				onSuccess: () => {
					navigation.pop();
					toast.show({
						toastLeft: <Icon name="thumbsUp" color="textInverted" />,
						message: t('survey.feedbackToast'),
					});
				},
				onError: () => {
					genericErrorAlert(navigation.pop);
				},
			}
		);
	}

	return (
		<DeferredLoadingIndicator isLoading={assignSurveyQuery.isLoading}>
			{assignSurveyQuery.isSuccess ? (
				<Survey surveyData={assignSurveyQuery.data?.questions} onSubmit={handleOnSubmit} />
			) : (
				<SurveyErrorView />
			)}
		</DeferredLoadingIndicator>
	);
}

export function useAssignSurveyScreenOptions(): StackNavigationOptions {
	const subScreenStyleProps = useSubScreenStyleProps({ backIcon: 'close' });
	const transitions = useTransitions();
	const { t } = useTranslation();

	return {
		title: t('assignSurvey.screenTitle'),
		headerTitle: '',
		...subScreenStyleProps,
		...transitions.NoTransition,
	};
}
