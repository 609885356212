import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function PlayGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(45.1, 45.1);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#ffffff"
					d="M22.6 45.1C10.1 45.1 0 35 0 22.6S10.1 0 22.6 0s22.6 10.1 22.6 22.6C45.1 35 35 45.1 22.6 45.1zm0-44.1C10.7 1 1 10.7 1 22.6s9.7 21.6 21.6 21.6 21.6-9.7 21.6-21.6C44.1 10.7 34.5 1 22.6 1z"
				/>
				<Path fill="#ffffff" d="M15.8 12.7v19l18-8.9z" />
			</Svg>
		</View>
	);
}
