import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { StyleSheet } from 'react-native';

export default function useConnectTrackerStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		cardBody: {
			paddingHorizontal: 0,
			paddingVertical: 0,
		},
		connectionStatusContainer: {
			alignItems: 'center',
			paddingVertical: 16,
			paddingRight: 12,
		},
		deviceLogo: {
			height: 70,
			aspectRatio: 1,
			resizeMode: 'contain',
		},
		deviceLogoContainer: {
			paddingHorizontal: 6,
			borderRightWidth: 1,
			borderTopLeftRadius: rules.borderRadius,
			borderBottomLeftRadius: rules.borderRadius,
			borderRightColor: rules.colors.strokeLight,
			backgroundColor: rules.colors.staticLight,
		},
	});
}
