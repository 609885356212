import { AccountRegistrationErrorCode } from '@mobe/api/account/accountService';
import Box from '@mobe/components/box/Box';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	ClaimAccountScreenNavigationProp,
	ClaimAccountScreenRouteProp,
} from '../AuthenticationStackScreen';
import AuthScreenTemplate, {
	IAuthScreenAlert,
} from '../components/authScreenTemplate/AuthScreenTemplate';
import ClaimAccountForm from './ClaimAccountForm';

export interface IClaimAccountScreenProps {
	navigation: ClaimAccountScreenNavigationProp;
	route: ClaimAccountScreenRouteProp;
}

export default function ClaimAccountScreen({ navigation, route }: IClaimAccountScreenProps) {
	const { t } = useTranslation();
	const [errorCode, setErrorCode] = React.useState(AccountRegistrationErrorCode.None);

	function handleHaveAccountPress() {
		navigation.navigate('LOGIN_SCREEN');
	}

	function handleSubmissionError(errorCode: AccountRegistrationErrorCode) {
		setErrorCode(errorCode);
	}

	function alert(): IAuthScreenAlert | undefined {
		if (
			errorCode === AccountRegistrationErrorCode.AccountAlreadyRegistered ||
			errorCode === AccountRegistrationErrorCode.EmailAlreadyRegistered
		) {
			return {
				alertType: 'error',
				message: t('auth.claimAccount.errors.accountAlreadyExists'),
			};
		} else if (errorCode !== AccountRegistrationErrorCode.None) {
			return {
				alertType: 'error',
				message: t('auth.claimAccount.errors.default'),
			};
		}
	}

	return (
		<AuthScreenTemplate
			heading={t('auth.claimAccount.heading')}
			alert={alert()}
			header={
				<Box row>
					<Text>{t('auth.claimAccount.haveAccount')} </Text>
					<TextButton
						onPress={handleHaveAccountPress}
						title={t('auth.claimAccount.haveAccountButton')}
					></TextButton>
				</Box>
			}
		>
			<ClaimAccountForm
				accessToken={route.params.accessToken}
				onSubmissionError={handleSubmissionError}
			/>
		</AuthScreenTemplate>
	);
}
