import { TrackerAbbreviation } from '@mobe/api/track/trackService';
import { Button } from '@mobe/components/button';
import ButtonRow from '@mobe/components/layout/ButtonRow';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useModalStyleProps from '@mobe/utils/styles/navigation/useModalStyleProps';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	TrackGoalScreenNavigationProp,
	TrackGoalScreenRouteProp,
} from '../../navigation/modal/types';
import TrackerSummary from '../components/TrackerSummary';
import useTrackerContent from '../useTrackerContent';
import useGoalScreenController from './useGoalScreenController';

export interface ITrackGoalScreenParams {
	trackerAbbreviation: TrackerAbbreviation;
	goal?: number;
}

export interface ITrackGoalScreenProps {
	navigation: TrackGoalScreenNavigationProp;
	route: TrackGoalScreenRouteProp;
}

export default function TrackGoalScreen({ navigation, route }: ITrackGoalScreenProps) {
	const { t } = useTranslation();
	const { wrapperNarrow } = useStyleHelpers();
	const trackerContent = useTrackerContent()[route.params.trackerAbbreviation];
	const { form, handleSubmit, handleDelete, renderForm, trackersGoalMutation } =
		useGoalScreenController({
			navigation,
			route,
		});

	// Delete, update, and add are the same endpoint and mutation. This is to differentiate UI loading states.
	const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

	return (
		<ScreenTemplateWithFooter
			noPadding
			footer={
				typeof route.params.goal === 'number' ? (
					<ButtonRow>
						<Button
							variant="secondary"
							title={t('track.goal.update.deleteButton')}
							onPress={() => {
								handleDelete();
								setIsDeleting(true);
							}}
							disabled={trackersGoalMutation.isLoading && !isDeleting}
							loading={trackersGoalMutation.isLoading && isDeleting}
						/>
						<Button
							title={t('track.goal.update.submitButton')}
							onPress={handleSubmit}
							disabled={trackersGoalMutation.isLoading && isDeleting}
							loading={trackersGoalMutation.isLoading && !isDeleting}
						/>
					</ButtonRow>
				) : (
					<Button
						title={t('track.goal.create.submitButton')}
						onPress={handleSubmit}
						loading={trackersGoalMutation.isLoading}
						disabled={!form.formState.isValid}
					/>
				)
			}
		>
			<TrackerSummary title={trackerContent.title} description={trackerContent.longDescription} />
			<View style={wrapperNarrow}>{renderForm()}</View>
		</ScreenTemplateWithFooter>
	);
}

export function useTrackGoalScreenOptions() {
	const { t } = useTranslation();
	const modalStyleProps = useModalStyleProps();

	return ({ route }: ITrackGoalScreenProps): StackNavigationOptions => ({
		title:
			typeof route.params.goal === 'number'
				? t('track.goal.update.screenTitle')
				: t('track.goal.create.screenTitle'),
		...modalStyleProps,
	});
}
