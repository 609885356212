import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function ActivitiesGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(80, 62);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const background = isDarkTheme ? '#333333' : '#F1F1F1';
	const gray1 = isDarkTheme ? '#BBBBBB' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#444444' : '#EAEAEA';
	const gray3 = isDarkTheme ? '#444444' : '#D3D3D3';
	const gray4 = isDarkTheme ? '#444444' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={background}
					d="M.959 32.504a11.794 11.794 0 0 0 6.493 8.974c4.202 2.011 9.197 1.332 13.66 2.677 5.784 1.737 10.004 6.603 15.133 9.807 6.107 3.812 13.379 5.17 20.464 6.46 4.703.855 9.69 1.684 14.145-.047 6.083-2.364 9.664-9.791 7.722-16.02-3.295-10.567-18.149-13.407-22.612-23.535-2.306-5.246-1.493-11.852-5.208-16.22C46.359-.58 38.253.223 31.643 1.814c-5.027 1.201-10.01 2.59-15.001 3.962-4.035 1.107-8.256 2.319-11.256 5.236-3.708 3.605-2.218 6.431-2.19 10.665.035 3.734-2.751 6.74-2.237 10.827z"
				/>
				<Path
					fill={gray1}
					d="M20.004 37.133h37.561c.922 0 1.669.747 1.669 1.669v16.694c0 .922-.747 1.669-1.669 1.669H20.004a1.669 1.669 0 0 1-1.669-1.669V38.802c0-.922.747-1.669 1.669-1.669z"
				/>
				<Path
					fill={gray3}
					d="M57.565 58H20.004a2.506 2.506 0 0 1-2.504-2.504V38.802a2.506 2.506 0 0 1 2.504-2.504h37.561a2.506 2.506 0 0 1 2.504 2.504v16.694A2.506 2.506 0 0 1 57.565 58zM20.004 37.968a.836.836 0 0 0-.835.835v16.694c0 .461.375.835.835.835h37.561a.835.835 0 0 0 .835-.835V38.802a.835.835 0 0 0-.835-.835H20.004z"
				/>
				<Path
					fill={gray2}
					d="M33.847 47.427a.68.68 0 0 0-.68.68v2.719c0 .374-.305.68-.68.68H27.05a.681.681 0 0 1-.68-.68v-5.438c0-.374.305-.68.68-.68h3.738a.68.68 0 1 0 0-1.36H27.05a2.042 2.042 0 0 0-2.039 2.039v5.438c0 1.124.915 2.039 2.039 2.039h5.438a2.042 2.042 0 0 0 2.039-2.039v-2.718a.679.679 0 0 0-.68-.68zM38.267 45.385h9.176a.68.68 0 1 0 0-1.36h-9.176a.68.68 0 1 0 0 1.36zM50.208 47.423H38.267a.68.68 0 1 0 0 1.36h11.941a.68.68 0 1 0 0-1.36zM38.267 52.18h11.917a.68.68 0 1 0 0-1.36H38.267a.68.68 0 1 0 0 1.36z"
				/>
				<Path
					fill={gray4}
					d="m36.086 41.505-6.656 6.657-1.219-1.219a.68.68 0 0 0-.961.961l1.699 1.699a.675.675 0 0 0 .96 0l7.137-7.137a.68.68 0 0 0-.96-.961z"
				/>
				<Path
					fill={gray1}
					d="M20.004 12.092h37.561c.922 0 1.669.747 1.669 1.669v16.694c0 .922-.747 1.669-1.669 1.669H20.004a1.669 1.669 0 0 1-1.669-1.669V13.762c0-.922.747-1.67 1.669-1.67z"
				/>
				<Path
					fill={gray3}
					d="M57.565 32.96H20.004a2.506 2.506 0 0 1-2.504-2.504V13.762a2.506 2.506 0 0 1 2.504-2.504h37.561a2.506 2.506 0 0 1 2.504 2.504v16.694a2.507 2.507 0 0 1-2.504 2.504zM20.004 12.927a.836.836 0 0 0-.835.835v16.694c0 .46.375.835.835.835h37.561a.836.836 0 0 0 .835-.835V13.762a.836.836 0 0 0-.835-.835H20.004z"
				/>
				<Path
					fill={gray2}
					d="M33.847 22.386a.68.68 0 0 0-.68.68v2.719c0 .374-.305.68-.68.68H27.05a.681.681 0 0 1-.68-.68v-5.438c0-.374.305-.68.68-.68h3.738a.68.68 0 1 0 0-1.36H27.05a2.042 2.042 0 0 0-2.039 2.039v5.438c0 1.124.915 2.039 2.039 2.039h5.438a2.042 2.042 0 0 0 2.039-2.039v-2.718a.68.68 0 0 0-.68-.68zM38.267 20.344h9.176a.68.68 0 1 0 0-1.36h-9.176a.68.68 0 1 0 0 1.36zM50.208 22.383H38.267a.68.68 0 1 0 0 1.36h11.941a.68.68 0 1 0 0-1.36zM38.267 27.139h11.917a.68.68 0 1 0 0-1.36H38.267a.68.68 0 1 0 0 1.36z"
				/>
				<Path
					fill={gray4}
					d="m36.086 16.465-6.656 6.657-1.219-1.219a.68.68 0 0 0-.961.961l1.699 1.699a.675.675 0 0 0 .96 0l7.137-7.137a.68.68 0 0 0-.96-.961z"
				/>
			</Svg>
		</View>
	);
}
