import IconButton from '@mobe/components/iconButton/IconButton';
import useTransitions from '@mobe/utils/useTransitions';
import { RouteProp, useNavigation } from '@react-navigation/core';
import {
	StackNavigationOptions,
	StackNavigationProp,
	createStackNavigator,
} from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GuideMatchFormScreenNavigationProp,
	GuideMatchFormScreenRouteProp,
} from '../../AppointmentStackScreen';
import { GuideMatchFormProvider } from './guideMatchProvider';
import GuideMatchContactScreen from './screens/GuideMatchContactScreen';
import GuideMatchGenderScreen from './screens/GuideMatchGenderScreen';
import GuideMatchInterestsScreen from './screens/GuideMatchInterestsScreen';
import GuideMatchLanguageScreen from './screens/GuideMatchLanguageScreen';
import GuideMatchScheduleScreen from './screens/GuideMatchScheduleScreen';
import GuideMatchSelectionScreen from './screens/GuideMatchSelectionScreen';

export interface IGuideMatchScreenParams {
	shouldShowLanguage: boolean;
	shouldShowGender: boolean;
}

export type GuideMatchFormStackParamList = {
	GUIDE_MATCH_LANGUAGE_SCREEN: undefined;
	GUIDE_MATCH_GENDER_SCREEN: undefined;
	GUIDE_MATCH_SCHEDULE_SCREEN: undefined;
	GUIDE_MATCH_INTERESTS_SCREEN: undefined;
	GUIDE_MATCH_SELECTION_SCREEN: undefined;
	GUIDE_MATCH_CONTACT_SCREEN: undefined;
};

export type GuideMatchFormScreen = keyof GuideMatchFormStackParamList;

export type GuideMatchLanguageScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_LANGUAGE_SCREEN'
>;
export type GuideMatchLanguageScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_LANGUAGE_SCREEN'
>;

export type GuideMatchGenderScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_GENDER_SCREEN'
>;
export type GuideMatchGenderScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_GENDER_SCREEN'
>;

export type GuideMatchScheduleScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_SCHEDULE_SCREEN'
>;
export type GuideMatchScheduleScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_SCHEDULE_SCREEN'
>;

export type GuideMatchInterestsScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_INTERESTS_SCREEN'
>;
export type GuideMatchInterestsScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_INTERESTS_SCREEN'
>;

export type GuideMatchSelectionScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_SELECTION_SCREEN'
>;
export type GuideMatchSelectionScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_SELECTION_SCREEN'
>;

export type GuideMatchContactScreenNavigationProp = StackNavigationProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_CONTACT_SCREEN'
>;
export type GuideMatchContactScreenRouteProp = RouteProp<
	GuideMatchFormStackParamList,
	'GUIDE_MATCH_CONTACT_SCREEN'
>;

export interface ITrackEntryScreenProps {
	navigation: GuideMatchFormScreenNavigationProp;
	route: GuideMatchFormScreenRouteProp;
}

const GuideMatchStack = createStackNavigator<GuideMatchFormStackParamList>();

export default function GuideMatchFormScreen({ route }: ITrackEntryScreenProps) {
	const { t } = useTranslation();
	const transitions = useTransitions();

	function guideMatchInitialScreen(): GuideMatchFormScreen {
		if (route.params.shouldShowLanguage) return 'GUIDE_MATCH_LANGUAGE_SCREEN';
		if (route.params.shouldShowGender) return 'GUIDE_MATCH_GENDER_SCREEN';
		return 'GUIDE_MATCH_SCHEDULE_SCREEN';
	}

	return (
		<GuideMatchFormProvider
			shouldShowLanguage={route.params.shouldShowLanguage}
			shouldShowGender={route.params.shouldShowGender}
		>
			<GuideMatchStack.Navigator
				initialRouteName={guideMatchInitialScreen()}
				screenOptions={{
					gestureEnabled: false,
					headerTitleAllowFontScaling: false,
					headerShown: false,
					...transitions.SlideFromRight,
					title: t('appointments.guideMatch.screenTitle'),
				}}
			>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_LANGUAGE_SCREEN"
					component={GuideMatchLanguageScreen}
				/>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_GENDER_SCREEN"
					component={GuideMatchGenderScreen}
				/>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_SCHEDULE_SCREEN"
					component={GuideMatchScheduleScreen}
				/>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_INTERESTS_SCREEN"
					component={GuideMatchInterestsScreen}
				/>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_SELECTION_SCREEN"
					component={GuideMatchSelectionScreen}
				/>
				<GuideMatchStack.Screen
					name="GUIDE_MATCH_CONTACT_SCREEN"
					component={GuideMatchContactScreen}
				/>
			</GuideMatchStack.Navigator>
		</GuideMatchFormProvider>
	);
}

export function useGuideMatchFormStackScreenOptions(): StackNavigationOptions {
	const navigation = useNavigation<GuideMatchFormScreenNavigationProp>();

	return {
		headerLeft: function HeaderBackIcon() {
			return <IconButton name="close" onPress={() => navigation.pop()} />;
		},
	};
}
