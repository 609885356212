import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, Polygon, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function ProgressPharmacistCall(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(187.9, 266);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#263338"
					d="M71.2,24.7c-4.4-0.3-28.3,14.6-29,39.4s-5.8,34.2-14.3,30.7c8.8,11.1,22.3,0.3,26.5-13.7
	c0.9-3.1,2.4-3.8,3.6-0.1c-1.5-4.4,8.5-9.8,6.2-18.7c-4.7-17.2,2.4-23.9,7.1-29.1C75,29,71.2,24.7,71.2,24.7z"
				/>
				<Path
					fill="#263338"
					d="M65.7,53.8c0,0-9.9-13.4-11.7-23.4c-1.3-7.2,2.4-33.8,29.5-30C89,1.1,94.2,3.1,98.8,6.1
	c5.9,3.9,14.3,12.4,4.9,35.6l-2.5,7.8L65.7,53.8z"
				/>
				<Path
					fill="#1A3A5E"
					d="M79.4,266c0.1,0,0.1,0,0.2,0l1.5-38.7l-20.1-0.4c-2.9-11-7.7-18.2-13.2-18.2c-8.9,0-16.1,18.8-16.1,42.1
	c-0.1,4,0.5,10.1,1.5,15.2"
				/>
				<Path fill="#224A70" d="M116.2,266l2.8-46l-74.7,16.6l0,0.1c4.1,12.3,7.9,16.5,11.1,29.3" />
				<Path
					fill="#B87154"
					d="M102.3,93.2c0.6,10.3-5.3,19.8-14.6,24c-6-3.8-12-7.6-16.9-12.6c-4.9-5.1-8.7-11.5-9-18.5
	c-0.1-2.7,0.2-5.5,0.6-8.2c1.3-9,2.5-18.1,3.8-27.1C65.8,53,80.4,53.9,82,54.1c8.4,0.9,11.5,2.2,11.4,11.4
	C93.4,75.2,101.6,83.3,102.3,93.2z"
				/>
				<Path
					fill="#A3604B"
					d="M94.2,70.8c0,0-2,3.5-12.9,2.5S68,60.2,68,60.2l-1.9-9.3c0,0,27.3,12.1,27.3,14.4
	C93.5,67.1,93.7,69,94.2,70.8L94.2,70.8z"
				/>
				<Path
					fill="#F5FBFF"
					d="M104,82l-11.8,29L57,83.8c2,1.5-0.2,19-0.2,21.4l-0.4,48.2c-0.1,15.6,0.3,31.4-0.4,47
	c-0.3,7.2-3.4,14.9,2.8,20c7.8,6.5,26,6,35.5,5.9c8.6-0.1,33.7-0.6,36.2-9.3c0.9-3.1-2.1-10.5-2.7-13.7l-9.3-47.7L104,82z"
				/>
				<Path
					fill="#46A57D"
					d="M128.9,86.1c0,0,24.1,88.4,24.5,93.9c0.4,5.6-14.4,32.2-14.4,32.2L119.3,93.5L128.9,86.1z"
				/>
				<Path
					fill="#4DB388"
					d="M96.9,73.5l32,12.6l1.2,149.3c0,0-21,7-28.5-2.3l11.2-83.8L96.9,73.5z"
				/>
				<Path
					fill="#4DB388"
					d="M47.3,236.6c5,1.9,10.3,3,15.6,3.2c4.6,0.2,9.2,0.1,13.8-0.5c2.6-0.3,14.6-2.9,14.6-4.9
	c0-0.1,0-89.7,0-89.7S70,96.8,66,87.9c-3.9-8.9-2-21.4-2-21.4L40.6,93.4l1.5,35.1l-2.4,41c0,0,0.6,0.1-1.7,28.7
	c-0.6,7.6-1.3,15.1-1.7,22.7C35.7,231.2,38.2,233.1,47.3,236.6z"
				/>
				<Path
					fill="#46A57D"
					d="M62.3,239.8L62.3,239.8c0.3,0,0.6-0.2,0.6-0.5c0-0.2,0-0.3-0.1-0.4l-21.1-25.7
	c-2.5-2.8-6.4-8.4-6.1-12l-2.2,23.2C33.1,238.1,56,239.5,62.3,239.8z"
				/>
				<Path
					fill="#46A57D"
					d="M59,123.7c-1.3,2.1-17.9,24.9-17.9,24.9l-3.4,47.3h1.4c0.2-1.8,0.8-10,1.3-11.7l8.9-36
	L59,123.7z"
				/>
				<Path
					fill="#B87154"
					d="M62.4,47.4c5.2,4.1,3,12.4,8.9,17.1c5.8,4.6,14.5,5.8,20.8,1.7c17.1-11.2,22-63.6-8.5-58.9
	C71.1,9.2,62.2,24.2,65.4,36c-6-9.4-11.5,2.3-6.8,9.5c0.4,0.7,1.1,1.3,1.8,1.6C61,47.2,61.8,47,62.4,47.4z"
				/>
				<Polygon
					fill="#46A57D"
					points="91.4,144.8 53.9,104.8 61.6,100.1 43.6,99 42.5,89.1 63.9,66.8 64.6,83.6 "
				/>
				<Path
					fill="#46A57D"
					d="M110,136l6.8-35l-3.5-3.6l10.7-2.2c0,0-24.3-21.2-29.7-23.8l9.7,37.2L110,136z"
				/>
				<Path
					fill="#263338"
					d="M70,36.9c-4.3,1.3-8.7,1.9-13.2,1.6l-2.2-7c4.4-6,7.3-12.9,8.3-20.3c0,0,12.7-12.4,28.3-5.1
	c2.2-2,6-2.8,11.2,3.3c8.3,9.7,3.1,27.9,3.1,27.9c-3.2,0.4-4.6-11.3-6.3-17C98,16.1,94,11.8,92,9.9c-0.7,2.6-7.3,27.9-24,36.3
	c5.2-5.3,9.7-11.3,13.3-17.8C78.1,32.5,74.3,35.8,70,36.9z"
				/>
				<Path
					fill="#46A57D"
					d="M80.1,201.1c0,0,0.6,4.7-0.4,6.5c-11.9,3.6-43.7,1.9-43.7,1.9L35.7,199L80.1,201.1z"
				/>
				<Polygon fill="#70BFE0" points="72.6,210.6 131.6,223.3 187.9,157.2 126.7,142.8 " />
				<Path
					fill="#B87154"
					d="M90.6,188.1c4.4,0.2,7.9,0.9,11.4,3.8c1.6,1.4,6.2,7.5,4.3,9.6c-1.4,1.6-13.1-0.6-15.4-0.8
	l8.2,2.3c1.2,0.3,2.8,1.3,2.4,2.5c-0.3,0.7-1.1,1-1.8,0.9c-0.7-0.1-1.4-0.4-2-0.7c-4.2-1.8-9.5-0.4-14-0.7c-2.5-0.2-4.7,0-7.2-0.2
	c-3.7-0.2-7.7-0.7-11.3-1.3c0.3-1.9,0.7-3.8,1.2-5.6c0.6-1.8-0.8-4.5,0.9-4.9c3.6-0.9,6.5-3.9,9.8-4.7c6-1.5,12.4-5.8,18.5-6.3
	C94.1,184.1,92.1,186.2,90.6,188.1z"
				/>
				<Path
					fill="#B87154"
					d="M142.5,219.7c-0.8,0.2-4.6-2.7-5.4-3.2c-1.4-0.9-6.3-3.9-6.1-5.8c0.1-1,7.5,1.5,8,1.6
	c-1.2-0.4-5.7-5.1-5.3-6.5c0.7-2.4,7,1.2,8.4,1.7c-1.5-0.6-3.7-1.9-4.5-3.4c-2.7-5.1,6.1-2.2,8.1-1.7c-2.1-1.2-6.2-3.8-3.5-6.2
	c1.3-1.1,11.8-1.2,12.7,0c0.1,0.1,0.5,1.1,0.6,1.2C154.4,205.3,151.6,217.3,142.5,219.7z"
				/>
				<Path
					fill="#4DB388"
					d="M70.1,189.2l4.9,17.2c0,0-83.5,5.9-74.2-45.4c5.5-26.2,41.8-71.9,41.8-71.9l1.6,14.5l1.7,36
	l-16,26L70.1,189.2z"
				/>
			</Svg>
		</View>
	);
}
