import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function LTGoalGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(80, 61);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const background = isDarkTheme ? '#333333' : '#F1F1F1';
	const gray1 = isDarkTheme ? '#AAAAAA' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#666666' : '#D3D3D3';
	const gray3 = isDarkTheme ? '#999999' : '#BFBFBF';
	const gray4 = isDarkTheme ? '#CCCCCC' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={background}
					d="M.959 32.004a11.794 11.794 0 0 0 6.493 8.974c4.202 2.011 9.197 1.332 13.66 2.677 5.784 1.737 10.004 6.603 15.133 9.807 6.107 3.812 13.379 5.17 20.464 6.46 4.703.855 9.69 1.684 14.145-.047 6.083-2.364 9.664-9.791 7.722-16.02-3.295-10.567-18.149-13.407-22.612-23.535-2.306-5.246-1.493-11.852-5.208-16.22-4.397-5.18-12.503-4.377-19.113-2.786-5.027 1.201-10.01 2.59-15.001 3.962-4.035 1.107-8.256 2.319-11.256 5.236-3.708 3.605-2.218 6.431-2.19 10.665.035 3.734-2.751 6.74-2.237 10.827z"
				/>
				<Path fill="none" d="M11.116 6.01h58.172v47.313H11.116z" />
				<Path
					fill={gray2}
					d="m69.288 20.135-2.075.886-4.856-2.81 1.765-.795 5.166 2.719zM64.91 10.378l-1.427 5.541-.012-.028-1.753.783 1.148-5.332 2.044-.964zM64.658 22.101l-2.15.914-4.602-2.806 1.69-.762 4.844 2.702.218-.048zM62.604 11.464l-1.236 5.363-2.058.919 1.169-5.276 2.125-1.006z"
				/>
				<Path fill={gray3} d="m62.358 18.206 4.856 2.81-.272.116-4.939-2.767.355-.159z" />
				<Path fill={gray2} d="m66.94 21.128-2.165.921-4.821-2.764 2.052-.924 4.934 2.767z" />
				<Path
					fill={gray3}
					d="m59.951 19.285 4.821 2.764-.118.051-.219.048-4.844-2.703.36-.16zM62.866 11.339l-1.148 5.332-.351.156 1.236-5.363.263-.125zM60.178 12.657l.18-.129.118-.056-1.169 5.276-.351.156 1.222-5.247z"
				/>
				<Path fill={gray2} d="m60.359 12.526-.18.129-1.218 5.247-1.703.76.991-5.14 2.11-.996z" />
				<Path
					fill={gray2}
					d="M56.59 21.771c4.297 12.317-2.08 25.825-14.256 30.198-12.186 4.345-25.552-2.1-29.877-14.407C8.16 25.244 14.536 11.736 26.712 7.363c11.26-4.013 23.706 1.17 28.901 12.035l-4.292 1.92c-4.2-8.601-14.095-12.673-23.044-9.485-9.738 3.497-14.837 14.301-11.4 24.151 3.459 9.842 14.148 14.997 23.894 11.522 9.738-3.497 14.837-14.301 11.4-24.151-.056-.161-.115-.32-.175-.478l4.286-1.931c.107.269.208.545.308.825z"
				/>
				<Path
					fill={gray1}
					d="M52.169 23.35c3.438 9.85-1.662 20.654-11.4 24.151-9.745 3.475-20.434-1.68-23.894-11.523-3.438-9.851 1.662-20.655 11.4-24.152 8.949-3.189 18.845.884 23.044 9.485l-4.292 1.915c-3.204-6.335-10.549-9.298-17.188-6.934-7.3 2.621-11.123 10.719-8.547 18.103 2.591 7.379 10.605 11.244 17.91 8.639 7.299-2.62 11.123-10.718 8.547-18.102l-.045-.127 4.289-1.932c.063.156.12.315.176.477z"
				/>
				<Path
					fill={gray2}
					d="M47.75 24.937c2.576 7.384-1.248 15.481-8.547 18.102-7.306 2.606-15.319-1.259-17.91-8.639-2.576-7.384 1.247-15.482 8.547-18.103 6.639-2.364 13.984.599 17.188 6.934l-4.3 1.92c-2.2-4.07-6.995-5.927-11.323-4.385-4.861 1.745-7.407 7.138-5.691 12.055 1.727 4.913 7.063 7.486 11.928 5.751 4.773-1.714 7.329-6.957 5.766-11.827l4.298-1.936c.014.042.03.084.044.128z"
				/>
				<Path
					fill={gray1}
					d="M43.408 26.74c1.563 4.87-.993 10.113-5.766 11.827-4.865 1.735-10.201-.838-11.928-5.751-1.715-4.917.831-10.31 5.691-12.055 4.327-1.542 9.122.315 11.323 4.384l-4.319 1.928a4.63 4.63 0 0 0-5.44-1.846 4.676 4.676 0 0 0-2.65 2.409 4.752 4.752 0 0 0-.188 3.597c.859 2.451 3.521 3.734 5.946 2.867 2.201-.788 3.484-3.101 2.999-5.41l4.332-1.95z"
				/>
				<Path
					fill={gray2}
					d="M39.076 28.689c.484 2.308-.799 4.622-2.999 5.41-2.425.868-5.087-.416-5.946-2.867-.412-1.177-.344-2.471.188-3.597s1.485-1.993 2.65-2.409a4.63 4.63 0 0 1 5.44 1.846l-4.287 1.913a.874.874 0 0 0-.52.512.894.894 0 0 0 .015.678.881.881 0 0 0 .486.468.854.854 0 0 0 .676-.021l4.297-1.933z"
				/>
				<Path
					fill={gray4}
					d="m63.483 15.922-.012-.028-1.753.783-.351.156-2.058.919-.351.156-1.703.76-1.644.733-4.292 1.92-4.292 1.915-4.3 1.92-4.319 1.927-4.287 1.913a.874.874 0 0 0-.52.512.9.9 0 0 0 .015.678.881.881 0 0 0 .486.468.854.854 0 0 0 .676-.021l4.298-1.936 4.331-1.952 4.298-1.936 4.289-1.932 4.287-1.931 1.623-.732 1.69-.762.355-.161 2.052-.924.354-.159 1.765-.795.015-.007-.652-1.484z"
				/>
			</Svg>
		</View>
	);
}
