import { noop } from 'lodash';
import * as React from 'react';
import {
	GestureResponderEvent,
	Platform,
	Pressable,
	PressableProps,
	View,
	ViewStyle,
} from 'react-native';
import Icon, { IconColors, IconNames } from '../icon/Icon';

export interface IIconButtonProps extends PressableProps {
	disabled?: boolean;
	color?: IconColors;
	size?: number;

	/**
	 * Name of icon key
	 */
	name: IconNames;
	hitSlop?: number;
	onPress?: (event: GestureResponderEvent) => void;
}

function IconButton(
	{
		disabled = false,
		color,
		size,
		name,
		onPress = noop,
		hitSlop = 12,
		...pressableProps
	}: IIconButtonProps,
	ref: any
) {
	const webHitSlopStyles: ViewStyle = {
		position: 'absolute',
		top: -hitSlop,
		left: -hitSlop,
		right: -hitSlop,
		bottom: -hitSlop,
	};

	return (
		<Pressable
			ref={ref}
			role="button"
			disabled={disabled}
			onPress={onPress}
			hitSlop={hitSlop}
			{...pressableProps}
		>
			<View importantForAccessibility="no-hide-descendants">
				<Icon name={name} color={color} size={size} />
				{Platform.OS === 'web' && <View style={webHitSlopStyles} />}
			</View>
		</Pressable>
	);
}

export default React.forwardRef(IconButton);
