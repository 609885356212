import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';
export default function AvatarGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(52, 68);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					d="M48.2 68H3.775A3.757 3.757 0 011.1 66.88a3.809 3.809 0 01-1.1-2.7v-3.158a27.254 27.254 0 0116.449-25.115A19.57 19.57 0 1146.2 14.782a19.662 19.662 0 01-9.064 21.766A26.121 26.121 0 0152 60.15v4.028a3.815 3.815 0 01-1.106 2.71A3.764 3.764 0 0148.224 68H48.2z"
					fill="#CCC"
					fillRule="nonzero"
				/>
			</Svg>
		</View>
	);
}
