import { merge } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAssessment, IAssessment, IPatchAssessment, patchAssessment } from './AssessmentService';

export const assessmentQueryKey = 'assessment';

/**
 * Query for the "About You" questionnaire. Returns object representing PPT answers.
 */
export function useAssessmentQuery() {
	return useQuery(assessmentQueryKey, getAssessment, {
		staleTime: Infinity,
	});
}

/**
 * Mutation for patching the value of any or all of the questionnaire answers.
 * Utilizes optimistic update so that the query can effectively be the source of truth for the UI.
 * @param shouldDelay Applies a minimum resolution time to the mutation success
 */
export function useAssessmentMutation(shouldDelay?: boolean) {
	const queryClient = useQueryClient();
	const delay = shouldDelay ? 350 : 0;

	return useMutation<void, Error, IPatchAssessment, { previousValue: IAssessment }>(
		async (params: IPatchAssessment) => {
			await Promise.all([patchAssessment(params), new Promise((r) => setTimeout(r, delay))]);
		},
		{
			onMutate: async (newValue) => {
				await queryClient.cancelQueries(assessmentQueryKey);
				const previousValue = queryClient.getQueryData<IAssessment>(assessmentQueryKey) || {};
				queryClient.setQueryData(assessmentQueryKey, merge(previousValue, newValue));

				return { previousValue };
			},
			onError: (err, variables, context) => {
				queryClient.setQueryData<IAssessment>(assessmentQueryKey, context?.previousValue || {});
			},
			onSettled: () => {
				queryClient.invalidateQueries(assessmentQueryKey);
			},
		}
	);
}
