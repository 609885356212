import Button from '@mobe/components/button/Button';
import Icon, { IconNames } from '@mobe/components/icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
interface ILikeDislikeButtonProps {
	variant?: 'like' | 'dislike';
	onPress: () => void;
	isHelpful: boolean | null;
	isLoading: boolean;
}

function LikeDislikeButton({
	variant = 'like',
	onPress,
	isLoading,
	isHelpful,
}: ILikeDislikeButtonProps) {
	const { t } = useTranslation();

	const variantsContent: { title: string; iconName: IconNames } =
		variant === 'like'
			? {
					title: t('likeDislikeArticle.like'),
					iconName: 'thumbsUp',
			  }
			: { title: t('likeDislikeArticle.dislike'), iconName: 'thumbsDown' };

	return (
		<Button
			style={{ paddingHorizontal: 22 }}
			iconLeft={
				<View style={isHelpful === null && styles.iconWrapper}>
					<Icon name={variantsContent.iconName} size={20} />
				</View>
			}
			disabled={isLoading}
			title={isHelpful === null ? variantsContent.title : ''}
			accessibilityLabel={variantsContent.title}
			aria-label={variantsContent.title}
			variant="neutral"
			onPress={onPress}
		/>
	);
}

const styles = StyleSheet.create({
	iconWrapper: { marginRight: 11 },
});

export default LikeDislikeButton;
