import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { TextProps } from '../text/Text';
import BaseInput, { IBaseInputProps } from './BaseInput';
import BaseInputError from './BaseInputError';
import BaseInputLabel from './BaseInputLabel';

export interface IInputTextProps extends IBaseInputProps {
	/**
	 * String for the input label.
	 */
	label: string;

	/**
	 * If true, the label and input will display error styles.
	 */
	hasError?: boolean;

	/**
	 * Error message string to be shown beneath input.
	 */
	errorMessage?: string;

	/**
	 * If false, the height of a one-line error message is displaced beneath the input. This ensures
	 * that the there is no content shift when a short error message appears. If false, this space
	 * will be collapsed.
	 */
	collapseEmptyError?: boolean;

	/**
	 * Base label prop overrides
	 */
	labelProps?: TextProps;
}

export function buildErrorMessageId(label: string) {
	return `${label.toLowerCase().replaceAll(' ', '-')}-error-message`;
}

function InputText(
	{
		label,
		hasError = false,
		errorMessage,
		collapseEmptyError = false,
		labelProps = {},
		...baseInputProps
	}: IInputTextProps,
	ref: any
) {
	const styleHelpers = useStyleHelpers();
	const { t } = useTranslation();
	const shouldRenderErrorComponent = (!collapseEmptyError && !errorMessage) || errorMessage;
	const errorMessageId = buildErrorMessageId(label);

	const webProps =
		Platform.OS === 'web'
			? {
					['aria-invalid']: hasError,
					['aria-describedby']: errorMessageId,
					['aria-errormessage']: errorMessageId,
					['aria-disabled']: baseInputProps.disabled,
			  }
			: {};

	return (
		<>
			<BaseInputLabel
				color={hasError ? 'error' : 'regular'}
				style={styleHelpers.vr(1)}
				{...labelProps}
			>
				{label}
			</BaseInputLabel>
			<BaseInput
				hasError={hasError}
				aria-label={label}
				accessibilityLabel={label}
				accessibilityHint={
					hasError
						? `${t('accessibility.inputErrorMessageHintInvalid')}: ${
								errorMessage ? errorMessage : ''
						  }`
						: undefined
				}
				{...baseInputProps}
				{...webProps}
				ref={ref}
			/>

			{shouldRenderErrorComponent && (
				<BaseInputError id={errorMessageId}>{errorMessage}</BaseInputError>
			)}
		</>
	);
}

export default React.forwardRef(InputText);
