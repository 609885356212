import { debounce } from 'lodash';
import React, { MutableRefObject, useEffect, useState } from 'react';

export const useIntersection = (ref: MutableRefObject<any>, options?: IntersectionObserverInit) => {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const handleObserverEvent: IntersectionObserverCallback = ([entry]) => {
		setIsIntersecting(entry.isIntersecting);
	};
	const debouncedObserverCallback = React.useMemo(() => debounce(handleObserverEvent, 500), []);

	useEffect(() => {
		const element = ref.current;

		const observer = new IntersectionObserver(debouncedObserverCallback, options);

		element && observer.observe(element);

		return () => {
			debouncedObserverCallback.cancel();
			observer.unobserve(element);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
};
