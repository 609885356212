import { CoachType } from '@mobe/api/guides/guidesApi';
import { useGuideQuery, usePharmacistQuery } from '@mobe/api/guides/guidesApiHooks';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import Avatar from '@mobe/components/avatar/Avatar';
import BoxButton from '@mobe/components/boxButton/BoxButton';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import useCanScheduleAppointment from '../useCanScheduleAppointment';
import { GuideTypeValue } from '../useGuideTypeOptions';

interface IApptGuideSelectorProps {
	allowCoachToggle: boolean;
	value: CoachType;
	onSelect: (guideTypeValue: GuideTypeValue) => void;
}

export default function ApptGuideSelector({
	allowCoachToggle,
	value,
	onSelect,
}: IApptGuideSelectorProps) {
	const { t } = useTranslation();
	const { vrTop } = useStyleHelpers();
	const guideQuery = useGuideQuery();
	const guide = guideQuery.data;
	const guideName = guide?.preferredName || '';
	const pharmacistQuery = usePharmacistQuery();
	const pharmacist = pharmacistQuery.data;
	const pharmacistName = pharmacist?.preferredName || '';
	const { canScheduleGuide, canSchedulePharmacist } = useCanScheduleAppointment();
	const permissionsQuery = usePermissionsQuery();

	function handleOptionSelect(coachType: CoachType) {
		if (coachType === CoachType.Pharmacist) {
			onSelect(GuideTypeValue.Pharmacist);
		} else {
			onSelect(GuideTypeValue.Guide);
		}
	}

	return (
		<View>
			{guide && !(value === CoachType.Pharmacist && !allowCoachToggle) && (
				<BoxButton
					role="radio"
					selected={value !== CoachType.Pharmacist}
					onPress={() => handleOptionSelect(guide.coachType)}
					disabled={!canScheduleGuide}
				>
					<Row gutterSize={12}>
						<Row.Item>
							<Avatar
								letter={guideName[0]}
								imageSrc={guide?.avatarUrl ? { uri: guide.avatarUrl } : undefined}
							/>
						</Row.Item>
						<Row.Item fill>
							<Text size="lg" weight="medium">
								{guide.preferredName}
							</Text>
							<Text color="light" size="sm">
								{t('appointments.followUp.guideSelector.guide')}
							</Text>
						</Row.Item>
					</Row>
				</BoxButton>
			)}
			{permissionsQuery.data?.hasMedServicesAccess &&
				pharmacist &&
				!(value !== CoachType.Pharmacist && !allowCoachToggle) && (
					<BoxButton
						role="radio"
						selected={value === CoachType.Pharmacist}
						onPress={() => handleOptionSelect(pharmacist.coachType)}
						style={vrTop(3)}
						disabled={!canSchedulePharmacist}
					>
						<Row gutterSize={12}>
							<Row.Item>
								<Avatar
									letter={pharmacistName[0]}
									imageSrc={pharmacist?.avatarUrl ? { uri: pharmacist.avatarUrl } : undefined}
								/>
							</Row.Item>
							<Row.Item fill>
								<Text size="lg" weight="medium">
									{pharmacist.preferredName}
								</Text>
								<Text color="light" size="sm">
									{t('appointments.followUp.guideSelector.pharmacist')}
								</Text>
							</Row.Item>
						</Row>
					</BoxButton>
				)}
		</View>
	);
}
