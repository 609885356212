import { Text } from '@mobe/components/text';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import urlParser from 'js-video-url-parser';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { IExternalUrlViewProps } from './ExternalUrlView';
import WarningBanner from './WarningBanner';

// used for checking if the link is a youtube link
// and parsing it to be valid for the iframe
const convertYouTubeLinkForEmbedding = (link: string) => {
	const parsedUrl = urlParser.parse(link);

	if (!parsedUrl) {
		return link;
	}

	const isYouTubeLink = parsedUrl.provider === 'youtube';

	if (isYouTubeLink) {
		return `https://www.youtube.com/embed/${parsedUrl.id}`;
	}

	return link;
};

export default function ExternalUrlView({ url }: IExternalUrlViewProps) {
	const navigation = useNavigation();

	useFocusEffect(() => {
		navigation.setOptions({
			headerTitle: () => <Text numberOfLines={1}>{url}</Text>,
		});
	});

	return (
		<View style={styles.wrapper}>
			<WarningBanner />
			<iframe
				height="100%"
				width="100%"
				style={{ border: 0 }}
				src={convertYouTubeLinkForEmbedding(url)}
				frameBorder="0"
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	wrapper: {
		flex: 1,
	},
});
