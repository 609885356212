import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Ellipse, Path, SvgProps } from 'react-native-svg';

export default function OnboardingConfirmationGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(277, 293);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle fill="#E7F3F5" cx={138.5} cy={154.5} r={138.5} />
				<Path
					fill="#AAA789"
					d="M145.053 37.834c0-.442-.525-.834-1.276-.834-.676 0-1.277.344-1.277.834 0 .442.526.834 1.277.834.676-.048 1.276-.392 1.276-.834z"
				/>
				<Path
					fill="#C3CBB4"
					d="m261.242 218.701-57.453-69.088-61.296-65.888-115.27 153.241c15.641 21.07 37.125 37.538 62.054 47.021h98.445c31.746-12.075 57.899-35.483 73.52-65.286z"
				/>
				<Path
					fill="#D9E0CA"
					d="m30.794 241.565 104.337-113.44-24.936-12.688-84.172 119.885a139.798 139.798 0 0 0 4.771 6.243zM266.39 207.739l-55.208-62.264-177.605 99.431c14.9 17.277 33.997 30.827 55.705 39.083h98.435c35.547-13.519 64.085-41.246 78.673-76.25z"
				/>
				<Path
					fill="#ACB49D"
					d="M239.977 248.745 68.815 163.833l-53.317 54.376c15.591 30.04 41.862 53.639 73.784 65.78h98.435c20.072-7.633 37.907-19.796 52.26-35.244z"
				/>
				<Path
					fill="#D9E0CA"
					d="m99.96 287.56 80.84-66.988-134.814 36.99c15.301 13.744 33.678 24.13 53.974 29.998z"
				/>
				<Path
					fill="#EEF3E4"
					d="M158.346 208.473 39.885 251.737a140.108 140.108 0 0 0 6.252 5.959l134.918-37.08-22.709-12.143z"
				/>
				<Path fill="#E1E7D6" d="m111.344 184.931 77.115 23.892-7.659 11.749" />
				<Path fill="#EEF3E4" d="m200.684 151.352-88.028 32.641 20.338 6.009 67.69-38.65z" />
				<Path fill="#E1E7D6" d="m200.684 149.867-13.795 4.904-69.921-35.999 83.716 31.095z" />
				<Path
					fill="#ACB49D"
					d="M247.862 239.484a138.354 138.354 0 0 0 18.573-31.855l-54.995-62.154 36.422 94.009zM241.622 246.946l-53.33-38.055-7.492 11.681 60.822 26.374z"
				/>
				<Path
					fill="#C3CBB4"
					d="m180.8 220.572-82.199 66.584A138.494 138.494 0 0 0 138.5 293c40.962 0 77.765-17.788 103.122-46.054L180.8 220.572zM40.066 251.922l7.488-2.696 20.899-85.393-53.024 54.245a138.917 138.917 0 0 0 24.637 33.844zM117.232 117.309l53.355-4.756-27.082-30.494-26.273 35.25z"
				/>
				<Path fill="#E1E7D6" d="m158.464 100.942-20.608-11.031 14.932 12.984 5.676-1.953z" />
				<Path fill="#ACB49D" d="m161.362 102.084 42.428 47.53-53.631-40.703 11.203-6.827z" />
				<Path fill="#EEF3E4" d="m160.098 101.533-42.666 15.511 9.844 3.399 32.822-18.91z" />
				<Ellipse fill="#AAB39A" fillOpacity={0.62} cx={143.545} cy={86} rx={2.5} ry={1} />
				<Path fill="#89886F" d="M144.389 5.388h-2.694V86h2.694V5.388z" />
				<Path
					fill="#89886F"
					d="M147.083 4.041A4.014 4.014 0 0 0 143.041 0C140.788 0 139 1.788 139 4.041s1.788 4.041 4.041 4.041a4.013 4.013 0 0 0 4.042-4.041z"
				/>
				<Path
					fill="#60A680"
					d="M144.388 8.083v52.435c28.739 9.523 57.478-31.323 86.217-21.8-28.739-27.079-57.478-3.672-86.217-30.635z"
				/>
				<Path
					fill="#71BE94"
					d="M211.745 29.139c-22.487-9.733-44.87 1.405-67.357-18.362v31.206c22.487 4.615 44.87-6.021 67.357-12.844z"
				/>
				<Path
					fill="#89886F"
					d="M141.695 86.667c.458.203 1.113.333 1.85.333.298 0 .579-.024.844-.062v-1.875a5.925 5.925 0 0 0-.844-.062c-.737 0-1.393.13-1.85.333v1.333z"
				/>
			</Svg>
		</View>
	);
}
