import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

function DeleteAccountGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(266, 277);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle fill="#F9F9F9" cx={133} cy={133} r={133} />
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DDDFE3"
					d="M150.99 172.237a3.126 3.126 0 0 0 6.251 0v-11.639a3.125 3.125 0 0 0-6.251 0v11.639zm0 36.261a3.126 3.126 0 0 0 6.251 0v-11.639a3.125 3.125 0 0 0-6.251 0v11.639zm3.126 44.001a3.126 3.126 0 0 1-3.126-3.125v-11.638a3.126 3.126 0 0 1 6.251 0v11.638c0 1.726-1.4 3.125-3.125 3.125zm19.986-57.681a3.125 3.125 0 1 0 6.25 0v-11.639a3.125 3.125 0 1 0-6.25 0v11.639zm3.125 39.386a3.125 3.125 0 0 1-3.125-3.125V219.44a3.125 3.125 0 1 1 6.25 0v11.639a3.125 3.125 0 0 1-3.125 3.125zm-3.125 37.752a3.125 3.125 0 0 0 6.25 0v-11.639a3.125 3.125 0 1 0-6.25 0v11.639zM98.069 177.67a3.125 3.125 0 0 1-3.125-3.125v-11.639a3.125 3.125 0 1 1 6.25 0v11.639a3.125 3.125 0 0 1-3.125 3.125zm-3.125 33.135a3.125 3.125 0 1 0 6.25 0v-11.638a3.125 3.125 0 1 0-6.25 0v11.638zm3.125 44.003a3.126 3.126 0 0 1-3.125-3.126v-11.639a3.125 3.125 0 1 1 6.25 0v11.639a3.126 3.126 0 0 1-3.125 3.126zm23.231-58.433a3.124 3.124 0 0 0 3.125 3.124 3.124 3.124 0 0 0 3.125-3.124v-11.64a3.125 3.125 0 1 0-6.25 0v11.64zm3.125 39.385a3.125 3.125 0 0 1-3.125-3.125v-11.638a3.125 3.125 0 1 1 6.25 0v11.638a3.125 3.125 0 0 1-3.125 3.125zm-3.125 37.752a3.125 3.125 0 1 0 6.25 0v-11.639a3.125 3.125 0 1 0-6.25 0v11.639z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#C1C3C8"
					d="m183.403 110.431-.096-.106.122.073-.026.033zm13.964-43.241a25.478 25.478 0 0 0-6.273.548c-1.8-13.508-13.556-24.183-28.228-24.717-9.907-.361-18.857 3.99-24.519 10.958-2.804-6.411-9.441-10.887-17.144-10.813-8.522.082-15.635 5.703-17.655 13.259-2.92-2.971-7.006-4.893-11.599-5.06-9.291-.339-17.116 6.606-17.477 15.511-.007.174-.006.347-.008.52a25.485 25.485 0 0 0-5.097-.706c-13.476-.491-24.826 9.581-25.349 22.497-.429 10.58 6.543 19.781 16.484 23.035a27.38 27.38 0 0 0-.903 5.942c-.635 15.666 12.104 28.849 28.451 29.445 8.218.299 15.779-2.643 21.352-7.63.866 9.043 8.841 16.082 18.487 15.991 9.347-.09 16.997-6.845 18.092-15.507 4.268 5.308 10.869 8.83 18.405 9.104 10.955.399 20.504-6.182 23.975-15.596a17.301 17.301 0 0 0 7.889 2.211c9.292.338 17.117-6.606 17.477-15.511.164-4.033-1.236-7.773-3.675-10.693 6.236-4.034 10.455-10.76 10.768-18.514.525-12.915-9.977-23.783-23.453-24.274z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#DDDFE3"
					d="M208.694 70.334a22.502 22.502 0 0 1 2.997 12.174c-.314 7.753-4.533 14.479-10.768 18.513 2.439 2.92 3.838 6.66 3.674 10.693-.36 8.905-8.184 15.849-17.476 15.511a17.29 17.29 0 0 1-7.89-2.211c-3.471 9.414-13.02 15.995-23.975 15.597-7.535-.275-14.137-3.797-18.404-9.105-1.095 8.662-8.745 15.417-18.093 15.506a18.96 18.96 0 0 1-7.908-1.63c2.858 6.291 9.426 10.661 17.039 10.588 9.347-.089 16.997-6.844 18.092-15.506 4.268 5.308 10.869 8.83 18.405 9.104 10.955.399 20.504-6.182 23.975-15.596a17.288 17.288 0 0 0 7.889 2.21c9.292.339 17.117-6.605 17.477-15.51.164-4.033-1.236-7.773-3.675-10.693 6.236-4.034 10.454-10.76 10.768-18.513.363-8.955-4.575-16.922-12.127-21.132z"
				/>
				<Path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FFF"
					d="M120.052 84.109c0 2.822-1.357 5.109-3.031 5.109-1.674 0-3.031-2.287-3.031-5.109S115.347 79 117.021 79c1.674 0 3.031 2.287 3.031 5.109zm-4.737 28.566c-1.059 1.029-2.758-.109-2.208-1.48 3.039-7.595 10.464-12.96 19.144-12.96 8.536 0 15.857 5.187 18.989 12.581.571 1.349-1.14 2.517-2.192 1.497-6.02-5.837-18.801-14.143-33.733.362zm32.017-23.457c1.675 0 3.032-2.287 3.032-5.109S149.007 79 147.332 79c-1.673 0-3.031 2.287-3.031 5.109s1.358 5.109 3.031 5.109z"
				/>
			</Svg>
		</View>
	);
}

export default DeleteAccountGraphic;
