import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useLayout from '@mobe/utils/styles/useLayout';
import {
	BottomTabNavigationOptions,
	createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ExploreScreen from '../../explore/ExploreScreen';
import HomeScreen from '../../home/HomeScreen';
import PlanScreen from '../../plan/PlanScreen';
import ProgressScreen from '../../progress/ProgressScreen';
import TrackScreen from '../../track/TrackScreen';
import { MenuNavigationProvider } from '../menu/MenuNavigationProvider';
import { BottomTabStackScreenNavigationProp, BottomTabStackScreenRouteProp } from '../menu/types';
import LoginAlertPopup from './components/LoginAlertPopup';
import TabBarButton from './components/TabBarButton';
import TabBarIcon from './components/TabBarIcon';
import { BottomTabStackParamList } from './types';

const BottomTab = createBottomTabNavigator<BottomTabStackParamList>();

interface IBottomTabStackScreenProps {
	navigation: BottomTabStackScreenNavigationProp;
	route: BottomTabStackScreenRouteProp;
}

/**
 *	Screen for the Tabs Navigator. This screen is a child of the Menu Navigator.
 */
export default function BottomTabStackScreen({ navigation }: IBottomTabStackScreenProps) {
	const { t } = useTranslation();
	const layout = useLayout();
	const tabBarOptions = useTabBarOptions();

	return (
		<MenuNavigationProvider value={navigation}>
			{Platform.OS !== 'web' && <LoginAlertPopup />}
			<BottomTab.Navigator
				initialRouteName="HOME_SCREEN"
				screenOptions={tabBarOptions}
				backBehavior={Platform.OS !== 'web' ? 'initialRoute' : undefined}
				tabBar={
					layout.isWebDesktop
						? () => null // Don't render bottom tabs on web desktop
						: undefined
				}
			>
				<BottomTab.Screen
					name="HOME_SCREEN"
					component={HomeScreen}
					options={{
						title: t('home.screenBottomTabTitle'),
						tabBarAccessibilityLabel: t('home.screenBottomTabTitle'),
						tabBarIcon: ({ focused }) => <TabBarIcon iconName="home" focused={focused} />,
					}}
				/>
				<BottomTab.Screen
					name="TRACK_SCREEN"
					component={TrackScreen}
					options={{
						title: t('track.screenBottomTabTitle'),
						tabBarAccessibilityLabel: t('track.screenBottomTabTitle'),
						tabBarIcon: ({ focused }) => <TabBarIcon iconName="track" focused={focused} />,
					}}
				/>
				<BottomTab.Screen
					name="PLAN_SCREEN"
					component={PlanScreen}
					options={{
						title: t('plan.screenBottomTabTitle'),
						tabBarAccessibilityLabel: t('plan.screenBottomTabTitle'),
						tabBarIcon: ({ focused }) => <TabBarIcon iconName="plan" focused={focused} />,
					}}
				/>
				<BottomTab.Screen
					name="PROGRESS_SCREEN"
					component={ProgressScreen}
					options={{
						title: t('progress.screenBottomTabTitle'),
						tabBarAccessibilityLabel: t('progress.screenBottomTabTitle'),
						tabBarIcon: ({ focused }) => (
							<TabBarIcon iconName="progress" iconWidth={28} focused={focused} />
						),
					}}
				/>
				<BottomTab.Screen
					name="EXPLORE_SCREEN"
					component={ExploreScreen}
					options={{
						title: t('explore.screenBottomTabTitle'),
						tabBarAccessibilityLabel: t('explore.screenBottomTabTitle'),
						tabBarIcon: ({ focused }) => <TabBarIcon iconName="explore" focused={focused} />,
					}}
				/>
			</BottomTab.Navigator>
		</MenuNavigationProvider>
	);
}

/**
 * Screen options for Bottom Tab Navigation screen.
 * These options contain the configurations for the header on all tab screens.
 */
export function useBottomTabStackScreenOptions(): DrawerNavigationOptions {
	return {
		swipeEnabled: true,
		headerShown: false,
		unmountOnBlur: false,
	};
}

function useTabBarOptions(): BottomTabNavigationOptions {
	const rules = useStyleRules();
	const { topShadow } = useStyleHelpers();
	const { isLandscape } = useLayout();
	const { bottom: bottomInset } = useSafeAreaInsets();
	const tabBarHeight = rules.spacing.bottomTabBarHeight;
	const windowDimensions = useWindowDimensions();
	const navMaxWidth = isLandscape ? 600 : 500;
	const bottomPadding = 8;

	return {
		headerShown: false,
		unmountOnBlur: Platform.OS === 'web',
		tabBarButton: (props) => <TabBarButton {...props} />,
		tabBarAllowFontScaling: false,
		tabBarActiveTintColor: rules.colors.primary,
		tabBarInactiveTintColor: rules.colors.textLight,
		tabBarHideOnKeyboard: true,
		tabBarLabelStyle: {
			fontFamily: rules.fontFamilies.sansSemiBold,
			fontSize: Math.min(rules.fontSizes.sm, rules.staticFontSizes.md),
		},
		tabBarItemStyle: {
			paddingTop: 5,
			paddingBottom: bottomInset > 0 ? 0 : bottomPadding,
		},

		// These styles handle the border and shadow above the bottomTabs
		tabBarBackground: () => (
			<View
				style={{
					position: 'absolute',
					top: 0,
					bottom: 0,

					// The width constraint on tabBarStyle prevents this from reaching edges of screen
					left: -windowDimensions.width / 2,
					right: -windowDimensions.width / 2,
					backgroundColor: rules.colors.cardBackground,
					borderTopColor: rules.colors.stroke,
					borderTopWidth: StyleSheet.hairlineWidth,
					...topShadow,
				}}
			></View>
		),
		tabBarStyle: {
			height: bottomInset > 0 ? bottomInset + tabBarHeight - bottomPadding : tabBarHeight,
			paddingBottom: bottomInset,
			maxWidth: navMaxWidth * Math.min(1.1, rules.settings.fontScale),
			width: '100%',
			alignSelf: 'center',
			borderTopWidth: 0,

			...Platform.select({
				web: {
					width: '100%',
					maxWidth: rules.spacing.maxWidth,
					alignSelf: 'center',
					borderTopWidth: 0,
				},
			}),
		},
	};
}
