import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../authentication/AuthContext';
import { assignSurvey, getSurvey, submitSurvey } from './surveyService';

export function useSurveyQuery(token: string) {
	return useQuery(['surveyQueryKey', token], async () => await getSurvey(token));
}

export function useSubmitSurveyMutation() {
	return useMutation(submitSurvey);
}

export function useAssignQuery(surveyId: string) {
	const auth = useAuth();

	return useQuery(['assignSurveyQueryKey', surveyId], async () => await assignSurvey(surveyId), {
		enabled: auth.isAuthenticated,
	});
}
