import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { Platform, StyleSheet, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function useAuthScreenTemplateStyles(center: boolean) {
	const rules = useStyleRules();
	const { height: windowHeight } = useWindowDimensions();
	const { bottom: bottomSafeInset, top: topSafeInset } = useSafeAreaInsets();

	const styles = StyleSheet.create({
		scrollView: {
			backgroundColor: rules.colors.cardBackground,
			height: '100%',
		},
		page: {
			minHeight: windowHeight,
			paddingBottom: bottomSafeInset + 15,
		},
		pageBody: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			maxWidth: rules.spacing.maxWidthNarrow,
			width: '100%',
			alignSelf: 'center',
			marginTop: center && Platform.OS !== 'web' ? 'auto' : undefined,
		},
		pageFooter: {
			paddingHorizontal: rules.spacing.appHorizontalMargin,
			marginTop: 'auto',
		},
		pageFooterHelpButton: {
			marginTop: 30,
		},
		alertContainer: {
			minHeight: topSafeInset + 70,
			marginBottom: 10,
		},
		alert: {
			alignContent: 'center',
			justifyContent: 'center',
			backgroundColor: rules.colors.primary,
			minHeight: 70 + topSafeInset,
			paddingTop: topSafeInset,
			paddingBottom: 10,
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		alert_error: {
			backgroundColor: rules.colors.error,
		},

		// Web only
		graphicRow: {
			flexDirection: 'row',
			justifyContent: 'center',
			marginBottom: 30,
		},
		graphicRowItem: {
			height: 100,
			alignItems: 'stretch',
			flexDirection: 'row',
		},
	});

	return styles;
}
