import { TrackerSource } from '@mobe/api/track/trackService';
import { useTranslation } from 'react-i18next';
import { ImageSourcePropType } from 'react-native';
import { useValidicService } from '../track/ValidicServiceProvider';

export interface ITrackerDevice {
	isAvailable?: boolean;
	isConnected: boolean;
	logoSource?: ImageSourcePropType;
	displayName: string;
	trackerSourceName: TrackerSource;
	connect: CallableFunction;
	disconnect: CallableFunction;
}

export default function useNativeTrackers() {
	const { t } = useTranslation();
	const validicService = useValidicService();

	const nativeTrackers: ITrackerDevice[] = [];

	nativeTrackers.push({
		isAvailable: validicService.healthKit.isAvailable,
		isConnected: validicService.healthKit.isConnected,
		displayName: t('connectHealthData.marketplace.appleDisplayName'),
		trackerSourceName: TrackerSource.AppleHealth,
		logoSource: require('@mobe/assets/images/apple-health.png'),
		connect() {
			validicService.healthKit.connect();
		},
		disconnect() {
			validicService.healthKit.disconnect();
		},
	});

	nativeTrackers.push({
		isAvailable: validicService.googleFit.isAvailable,
		isConnected: validicService.googleFit.isConnected,
		displayName: t('connectHealthData.marketplace.googleDisplayName'),
		trackerSourceName: TrackerSource.GoogleFit,
		logoSource: require('@mobe/assets/images/google-fit.png'),
		connect() {
			validicService.googleFit.connect();
		},
		disconnect() {
			validicService.googleFit.disconnect();
		},
	});

	return nativeTrackers;
}
