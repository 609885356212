import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function CalendarAltGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(74, 74);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Circle fill="#D8EBFA" cx={37} cy={37} r={37} />
				<Path
					fill="#C5DAEB"
					d="M68.032 57.139V12.704c0-2.214-1.76-3.201-3.977-2.293l.473 51.304a37.052 37.052 0 0 0 3.504-4.576z"
				/>
				<Path
					fill="#FFF"
					d="M37 74c12.35 0 23.279-6.057 30-15.355v-43.29a37.17 37.17 0 0 0-4.714-5.354H11.714A37.148 37.148 0 0 0 6 16.811V57.19C12.603 67.307 24.017 74 37 74z"
				/>
				<Path
					fill="#1F6688"
					d="M14.931 59.856 13 58.756l.57-.891 1.13.643 2.022-2.234.84.675-2.631 2.907zM24 56h-5v1h5v-1zM24 59h-5v1h5v-1zM31.931 59.856 30 58.756l.57-.891 1.13.643 2.021-2.234.84.675-2.63 2.907zM41 56h-5v1h5v-1zM41 59h-5v1h5v-1zM48.931 59.856 47 58.756l.57-.891 1.13.643 2.021-2.234.84.675-2.63 2.907zM58 56h-5v1h5v-1zM58 59h-5v1h5v-1z"
				/>
				<Path
					fill="#5A9CC3"
					d="M6 16.434v-5.065A2.008 2.008 0 0 1 8.072 9.43h56.951a2.008 2.008 0 0 1 2.069 1.939v5.065"
				/>
				<Path
					fill="#E7E7E7"
					d="M33.985 22.815h-4.141v3.874h4.141v-3.874zM42.269 22.815h-4.141v3.874h4.141v-3.874zM50.552 22.815h-4.141v3.874h4.141v-3.874zM58.836 22.815h-4.141v3.874h4.141v-3.874zM17.42 31h-4.141v3.874h4.141V31zM25.701 31H21.56v3.874h4.141V31zM33.985 31h-4.141v3.874h4.141V31zM42.269 31h-4.141v3.874h4.141V31zM50.552 31h-4.141v3.874h4.141V31zM58.836 31h-4.141v3.874h4.141V31zM17.42 39h-4.141v3.874h4.141V39zM25.701 39H21.56v3.874h4.141V39zM33.985 39h-4.141v3.874h4.141V39zM42.269 39h-4.141v3.874h4.141V39zM50.552 39h-4.141v3.874h4.141V39zM58.836 39h-4.141v3.874h4.141V39zM17.42 47h-4.141v3.874h4.141V47zM25.701 47H21.56v3.874h4.141V47zM33.985 47h-4.141v3.874h4.141V47z"
				/>
				<Path
					fill="#243942"
					d="M47.418 12.463H28.779v1.408h18.639v-1.408zM12.212 12.463h-2.069v1.408h2.069v-1.408zM62.948 12.463h-2.071v1.408h2.071v-1.408z"
				/>
				<Path fill="#5990B5" d="M67.089 16.36H6v1.359h61.089V16.36z" />
			</Svg>
		</View>
	);
}
