import { ICelebrations } from '@mobe/api/celebrations/celebrationsService';
import { create } from 'zustand';

export interface ICongratsPopupMessage extends ICelebrations {
	onClose?: () => void;
	onUndo?: () => void;
}

interface ICongratsPopupStore {
	message: ICongratsPopupMessage | null;
	setMessage: (message: ICongratsPopupMessage) => void;
	clear: () => void;
}

export const useCongratsPopupStore = create<ICongratsPopupStore>((set) => ({
	message: null,
	setMessage: (message) => set(() => ({ message })),
	clear: () => set({ message: null }),
}));
