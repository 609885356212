import { IAppointment, useAppointmentsQuery } from '@mobe/api/appointments/appointmentApiHooks';
import { CoachType } from '@mobe/api/guides/guidesApi';
import { usePermissionsQuery } from '@mobe/api/permissions/permissionsApiHooks';
import { Button } from '@mobe/components/button';
import DeferredLoadingIndicator from '@mobe/components/deferredLoadingIndicator/deferredLoadingIndicator';
import useHeadingStyles from '@mobe/components/heading/headingStyles';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import { useAccessibilityAutoFocus } from '@mobe/utils/a11y';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import useRefetchStaleQueryOnFocusEffect from '@mobe/utils/useRefetchStaleQueryOnFocusEffect';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { GuideApptCard } from '../appointments/components/GuideApptCard';
import useCanScheduleAppointment from '../appointments/useCanScheduleAppointment';
import { useModalNavigation } from '../navigation/modal/ModalNavigationProvider';
import CallsBannerGraphic from './CallsBannerGraphic';
import EmptyCallsGraphic from './EmptyCallsGraphic';

interface ISortedAppointments {
	title: string;
	appointments: IAppointment[];
}

export default function CallsScreen() {
	const { t } = useTranslation();
	const navigation = useModalNavigation();
	const appointmentsQuery = useAppointmentsQuery();
	const allAppointments = appointmentsQuery.data?.allAppointments || [];
	const { canScheduleGuide, canScheduleAppt } = useCanScheduleAppointment();
	useRefetchStaleQueryOnFocusEffect(appointmentsQuery);
	const styleRules = useStyleRules();
	const { vr, vrTop, wrapperHorizontal } = useStyleHelpers();
	const headingStyles = useHeadingStyles({ level: 'h3', color: 'staticLight' });
	const focusRef = useAccessibilityAutoFocus();

	const appointmentSections =
		allAppointments.reduce((accumulation: ISortedAppointments[], currentAppointment) => {
			const title = format(new Date(currentAppointment.appointmentStartDate), 'MMMM y');
			let section = accumulation.find((section) => section.title === title);

			if (!section) {
				section = {
					title,
					appointments: [],
				};

				accumulation.push(section);
			}

			section.appointments.push(currentAppointment);

			return accumulation;
		}, []) || [];

	return (
		<ScreenTemplateWithFooter
			noPadding
			fixedPositionFooter
			scrollViewBackgroundColor={styleRules.colors.background}
			footer={
				canScheduleAppt ? (
					<Button
						title={t('calls.scheduleCallButton')}
						onPress={() =>
							navigation.navigate('APPOINTMENT_SCREEN', {
								coachType: canScheduleGuide ? CoachType.GSM : CoachType.Pharmacist,
							})
						}
					/>
				) : null
			}
		>
			<DeferredLoadingIndicator isLoading={appointmentsQuery.isLoading}>
				{appointmentSections.length > 0 ? (
					appointmentSections.map((section, index) => (
						<View key={section.title} style={index > 0 ? vrTop(4) : undefined}>
							<View
								ref={index === 0 ? focusRef : undefined}
								accessible
								role="heading"
								aria-level="2"
								{...{ tabIndex: -1 }} // TODO: remove spread when type exports are fixed in RN 0.72.6+
								style={[
									wrapperHorizontal,
									vrTop(4),
									vr(2),
									{ flexDirection: 'row', justifyContent: 'space-between' },
								]}
							>
								<Text style={headingStyles}>{section.title}</Text>
								<Text style={headingStyles}>
									{t('calls.numberOfCallsHeader', { count: section.appointments.length })}
								</Text>
							</View>

							<CallsBannerGraphic style={{ position: 'absolute', zIndex: -1 }} />

							{section.appointments.map((appointment) => (
								<View key={appointment.confirmationId} style={[wrapperHorizontal, vr(4)]}>
									<GuideApptCard
										appointment={appointment}
										onPress={() =>
											navigation.navigate('GUIDE_APPOINTMENT_DETAIL_MODAL_SCREEN', {
												confirmationId: appointment.confirmationId,
											})
										}
									/>
								</View>
							))}
						</View>
					))
				) : (
					<EmptyCallsView />
				)}
			</DeferredLoadingIndicator>
		</ScreenTemplateWithFooter>
	);
}

export function useCallsScreenOptions(): DrawerNavigationOptions {
	const { t } = useTranslation();
	return {
		title: t('calls.screenTitle'),
		headerShown: true,
	};
}

function EmptyCallsView() {
	const { constrain, vr, wrapperHorizontal } = useStyleHelpers();
	const permissionsQuery = usePermissionsQuery();
	const { t } = useTranslation();

	return (
		<View style={{ height: '100%', justifyContent: 'center' }}>
			<View style={[constrain(280), vr(8)]}>
				<EmptyCallsGraphic />
			</View>
			{permissionsQuery.isSuccess ? (
				<Text accessibilityAutoFocus color="light" align="center" style={wrapperHorizontal}>
					{permissionsQuery.data.hasMedServicesAccess
						? t('calls.emptyCalls')
						: t('calls.emptyCallsNoPharmacist')}
				</Text>
			) : null}
		</View>
	);
}
