import { useMutation, useQuery, useQueryClient } from 'react-query';
import { APIErrorData } from '../APIResponse';
import { useAuth } from '../authentication/AuthContext';
import { IMobeAPIStandardError } from '../mobeAPI';
import { ExploreQueryKeys } from './exploreQueryKeys';
import {
	ExploreContentItemDetailsError,
	getAllContent,
	getAllFeaturedSharedContent,
	getContentDetail,
	getContentModules,
	IExploreContentCategory,
	IExploreContentItemFull,
	ISetFavoriteStatus,
	ISetHelpfulStatus,
	ISetViewTime,
	setFavoriteStatus,
	setHelpfulStatus,
	setViewTime,
} from './exploreService';

export function useAllContentQuery() {
	const auth = useAuth();

	return useQuery<IExploreContentCategory[], APIErrorData>(
		ExploreQueryKeys.AllContent,
		async () => {
			const response = await getAllContent();

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			enabled: auth.isAuthenticated,
			staleTime: 30 * 1000,
		}
	);
}

export function useAllFeaturedSharedContentQuery() {
	const auth = useAuth();

	return useQuery(ExploreQueryKeys.AllFeaturedSharedContent, getAllFeaturedSharedContent, {
		enabled: auth.isAuthenticated,
		staleTime: Infinity,
		select: (data) => {
			return {
				welcomeVideo: data[0] || null,
				trackingProgressArticle: data[1] || null,
				featuredContent: data.slice(2),
			};
		},
	});
}

export function useSetFavoriteStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation<
		void,
		Error,
		ISetFavoriteStatus,
		{ previousContent: IExploreContentItemFull | undefined }
	>(
		async (params) => {
			const response = await setFavoriteStatus(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onMutate: async ({ sharedContentId, isFavorite }) => {
				await queryClient.cancelQueries(ExploreQueryKeys.ContentDetail);

				const previousContent = queryClient.getQueryData<IExploreContentItemFull>([
					ExploreQueryKeys.ContentDetail,
					sharedContentId,
				]);

				if (previousContent) {
					queryClient.setQueryData<IExploreContentItemFull>(
						[ExploreQueryKeys.ContentDetail, sharedContentId],
						{
							...previousContent,
							isFavorite: isFavorite,
						}
					);
				}

				return { previousContent };
			},
			onError: (error, args, context) => {
				if (context?.previousContent) {
					queryClient.setQueryData<IExploreContentItemFull>(
						[ExploreQueryKeys.ContentDetail, args.sharedContentId],
						{
							...context.previousContent,
							isFavorite: !args.isFavorite,
						}
					);
				}
			},
			// Always refetch after error or success
			onSettled: (data, error, args) => {
				// To update favorite icons that appear on content
				queryClient.invalidateQueries(ExploreQueryKeys.AllContent);

				// To update favorite icon on detail screen (for Explore and Activities)
				queryClient.invalidateQueries([ExploreQueryKeys.ContentDetail, args.sharedContentId]);

				// To update favorite icons on Featured Shared Content
				queryClient.invalidateQueries(ExploreQueryKeys.AllFeaturedSharedContent, {
					refetchActive: false,
				});
			},
		}
	);
}

export function useSetIsHelpfulStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, ISetHelpfulStatus>(
		async (params) => {
			const response = await setHelpfulStatus(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onMutate: async ({ sharedContentId, isHelpful }) => {
				await queryClient.cancelQueries(ExploreQueryKeys.ContentDetail);

				const previousContent = queryClient.getQueryData<IExploreContentItemFull>([
					ExploreQueryKeys.ContentDetail,
					sharedContentId,
				]);

				if (previousContent) {
					const optimisticUpdate = { ...previousContent };

					queryClient.setQueryData<IExploreContentItemFull>(
						[ExploreQueryKeys.ContentDetail, sharedContentId],
						{
							...optimisticUpdate,
							isHelpful,
							helpfulCount: isHelpful
								? optimisticUpdate.helpfulCount + 1
								: optimisticUpdate.helpfulCount - 1,
						}
					);
				}
			},
			onSettled: (data, err, args) => {
				queryClient.invalidateQueries([ExploreQueryKeys.ContentDetail, args.sharedContentId]);
			},
		}
	);
}

export function useContentDetailQuery(sharedContentId: number | null) {
	return useQuery<
		IExploreContentItemFull,
		APIErrorData<ExploreContentItemDetailsError, IMobeAPIStandardError>
	>(
		[ExploreQueryKeys.ContentDetail, sharedContentId],
		async () => {
			// This can't actually occur, but TS doesn't now that. Query isn't enabled until sharedContentId isn't null
			if (sharedContentId === null) {
				throw new Error();
			}

			const response = await getContentDetail(sharedContentId);

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			retry: false,
			enabled: sharedContentId !== null,
			refetchOnWindowFocus: false,
		}
	);
}

export function useSetViewTimeMutation() {
	const queryClient = useQueryClient();

	return useMutation<void, Error, ISetViewTime>(
		async (params) => {
			const response = await setViewTime(params);

			if (!response.success) {
				throw response.error;
			}
		},
		{
			onSuccess: () => {
				// To update viewed icon on all explore content
				queryClient.invalidateQueries(ExploreQueryKeys.AllContent);

				// To update viewed icon on Featured Shared Content
				queryClient.invalidateQueries(ExploreQueryKeys.AllFeaturedSharedContent, {
					refetchActive: false,
				});
			},
		}
	);
}

export function useContentModulesQuery() {
	const auth = useAuth();

	return useQuery(
		ExploreQueryKeys.ContentModules,
		async () => {
			return await getContentModules();
		},
		{
			enabled: auth.isAuthenticated,
			staleTime: 30 * 1000,
		}
	);
}
