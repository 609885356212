import { TrackerAggregatedEntriesInterval } from '@mobe/api/track/trackService';
import { IDateSelectItem } from '@mobe/components/input/InputDateInterval';
import {
	format,
	isAfter,
	isThisMonth,
	isThisWeek,
	isThisYear,
	lastDayOfMonth,
	lastDayOfWeek,
	lastDayOfYear,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subMonths,
	subWeeks,
	subYears,
} from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type DateRangeSelectionValues = 'week' | 'month' | 'year';

function getInitialSelectedRange(startDate: Date): DateRangeSelectionValues {
	if (isThisWeek(startDate)) {
		return 'week';
	}

	if (isThisMonth(startDate)) {
		return 'month';
	}

	if (isThisYear(startDate)) {
		return 'year';
	}

	// Default to year
	return 'year';
}

export interface IDateRangeSelect {
	selectedRange: DateRangeSelectionValues;
	selectedRangeLabel: string;
	selectedRangeAggregationInterval: TrackerAggregatedEntriesInterval;
	offsetIndex: number;
	items: IDateSelectItem[];
	label: string;
	startDate: Date;
	endDate: Date;
	handleDateRangeSelection: (value: DateRangeSelectionValues) => void;
	getPreviousRange: () => void;
	getNextRange: () => void;
	canGetNextRange: boolean;
}

export default function useDateRangeSelect(
	initialStartDateString: string | null | undefined
): IDateRangeSelect {
	const { t } = useTranslation();

	const initialStartDate = React.useMemo(() => {
		let date = new Date(Date.now());

		if (typeof initialStartDateString === 'string') {
			date = new Date(initialStartDateString);
		}

		return date;
	}, [initialStartDateString]);

	const [offsetIndex, setOffsetIndex] = React.useState<number>(0);
	const [selectedRange, setSelectedRange] = React.useState<DateRangeSelectionValues>(() =>
		getInitialSelectedRange(initialStartDate)
	);

	// Because most of the time the initialStartDate value can be async, this tries to rerun initializing the selectedRange
	React.useEffect(() => {
		setSelectedRange(getInitialSelectedRange(initialStartDate));
		setOffsetIndex(0);
	}, [initialStartDate]);

	const range = {
		label: '',
		startDate: initialStartDate,
		endDate: initialStartDate,
	};

	switch (selectedRange) {
		case 'week':
			range.startDate = startOfWeek(subWeeks(initialStartDate, 1 * offsetIndex));
			range.endDate = lastDayOfWeek(range.startDate);
			range.label = t('dateRangeSelect.rangeStepperLabels.week', {
				range: `${format(range.startDate, 'PP')} – ${format(lastDayOfWeek(range.startDate), 'PP')}`,
			});
			break;
		case 'month':
			range.startDate = startOfMonth(subMonths(initialStartDate, 1 * offsetIndex));
			range.endDate = lastDayOfMonth(range.startDate);
			range.label = t('dateRangeSelect.rangeStepperLabels.month', {
				range: format(range.startDate, 'MMMM yyyy'),
			});
			break;
		case 'year':
			range.startDate = startOfYear(subYears(initialStartDate, 1 * offsetIndex));
			range.endDate = lastDayOfYear(range.startDate);
			range.label = t('dateRangeSelect.rangeStepperLabels.year', {
				range: format(range.startDate, 'yyyy'),
			});
			break;
	}

	const canGetNextRange = isAfter(new Date(Date.now()), range.endDate);

	function handleDateRangeSelection(value: DateRangeSelectionValues) {
		setSelectedRange(value);
		setOffsetIndex(0);
	}

	function getPreviousRange() {
		setOffsetIndex((previousIndex) => {
			return previousIndex + 1;
		});
	}

	function getNextRange() {
		setOffsetIndex((previousIndex) => {
			if (!canGetNextRange) {
				return 0;
			}
			return previousIndex - 1;
		});
	}

	const items: IDateSelectItem[] = [
		{ label: t('dateRangeSelect.itemLabels.week'), value: 'week' },
		{ label: t('dateRangeSelect.itemLabels.month'), value: 'month' },
		{ label: t('dateRangeSelect.itemLabels.year'), value: 'year' },
	];

	return {
		selectedRange,
		offsetIndex,
		items,
		label: range.label,
		selectedRangeLabel:
			items[items.findIndex((item) => item.value === selectedRange)]?.label || 'Custom',
		selectedRangeAggregationInterval:
			selectedRange === 'week'
				? TrackerAggregatedEntriesInterval.Day
				: selectedRange === 'month'
				? TrackerAggregatedEntriesInterval.Day
				: selectedRange === 'year'
				? TrackerAggregatedEntriesInterval.Month
				: TrackerAggregatedEntriesInterval.Day,
		startDate: range.startDate,
		endDate: range.endDate,
		handleDateRangeSelection,
		getPreviousRange,
		getNextRange,
		canGetNextRange,
	};
}
