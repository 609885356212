import Icon from '@mobe/components/icon/Icon';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as Haptics from 'expo-haptics';
import { noop } from 'lodash';
import * as React from 'react';
import {
	Animated,
	Easing,
	Pressable,
	PressableProps,
	StyleSheet,
	View,
	ViewStyle,
} from 'react-native';

interface ICompletionButton extends Omit<PressableProps, 'children'> {
	onPress?: () => void;
	onPressAnimationComplete?: () => void;
	children?: (renderCheckmark: () => React.ReactNode) => React.ReactNode;
}

export default function CompletionButton({
	children,
	onPress = noop,
	onPressAnimationComplete = noop,
	...pressableProps
}: ICompletionButton) {
	const styles = useStyles();

	const completeButtonRef = React.useRef(new Animated.Value(0)).current;
	const completeButtonAnimationIn = Animated.timing(completeButtonRef, {
		useNativeDriver: true,
		toValue: 1,
		duration: 150,
		easing: Easing.out(Easing.cubic),
	});

	const completeButtonAnimationOut = Animated.timing(completeButtonRef, {
		useNativeDriver: true,
		toValue: 2,
		duration: 250,
		easing: Easing.out(Easing.cubic),
	});

	const completeButtonAnimatedBaseStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				scale: completeButtonRef.interpolate({
					inputRange: [0, 1, 2],
					outputRange: [1, 0.9, 1],
				}),
			},
		],
	};

	const completeButtonAnimatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
		transform: [
			{
				scale: completeButtonRef.interpolate({
					inputRange: [0, 1, 2],
					outputRange: [1, 0.9, 1.4],
				}),
			},
		],
		opacity: completeButtonRef.interpolate({
			inputRange: [0, 1, 2],
			outputRange: [0, 1, 0],
		}),
	};

	function handleOnPress() {
		onPress();

		Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
		setTimeout(() => Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium), 170);

		Animated.sequence([
			completeButtonAnimationIn,
			Animated.delay(100),
			completeButtonAnimationOut,
		]).start(() => {
			completeButtonRef.setValue(0);
			onPressAnimationComplete();
		});
	}

	function renderCheckmark() {
		return (
			<View>
				<Animated.View style={[styles.cardCompleteButtonCircle, completeButtonAnimatedBaseStyles]}>
					<Icon name="checkmark" color="primary" size={14} />
				</Animated.View>
				<Animated.View
					style={[
						styles.cardCompleteButtonCircle,
						styles.cardCompleteButtonCircle_complete,
						completeButtonAnimatedStyles,
					]}
				>
					<Icon name="checkmark" color="textInverted" size={14} />
				</Animated.View>
			</View>
		);
	}

	return (
		<Pressable role="button" onPress={handleOnPress} {...pressableProps}>
			{children ? children(renderCheckmark) : renderCheckmark()}
		</Pressable>
	);
}

function useStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		cardCompleteButtonCircle: {
			alignItems: 'center',
			justifyContent: 'center',
			width: 34,
			height: 34,
			borderRadius: 18,
			borderWidth: 2,
			borderColor: rules.colors.primary,
		},
		cardCompleteButtonCircle_complete: {
			position: 'absolute',
			backgroundColor: rules.colors.primary,
		},
	});
}
