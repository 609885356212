import { ExploreNoResultsGraphic } from '@mobe/components/graphics';
import ScreenTemplateCentered from '@mobe/components/screenTemplate/ScreenTemplateCentered';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export default function ExploreNoResultsView({ searchValue }: { searchValue: string }) {
	const { constrain, vr } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<ScreenTemplateCentered>
			<View style={[constrain(280), vr(5)]}>
				<ExploreNoResultsGraphic />
			</View>
			<Text weight="bold" color="light" align="center">
				{t('explore.noMatches', { searchValue })}
			</Text>
			<Text weight="medium" color="light" align="center">
				{t('explore.noMatchesSuggestion')}
			</Text>
		</ScreenTemplateCentered>
	);
}
