import { differenceInYears, parse } from 'date-fns';

export const MIN_DOB_AGE_YEARS = 18;
export const US_DATE_FORMAT_REGEX = /^([0-1][0-9])[-/]([0-3][0-9])[-/]([0-9]{4,4})$/;

/**
 * Expects to parse date format like 05/29/1453
 * @param dateOfBirthString 05/29/1453
 * @returns
 */
export function validateDateOfBirth(dateOfBirthString: string | undefined): boolean {
	if (!dateOfBirthString) {
		return false;
	}

	let dateOfBirthDate: Date;

	try {
		dateOfBirthDate = parse(dateOfBirthString, 'MM/dd/yyyy', new Date());
	} catch (error) {
		console.warn('validateDateOfBirth: ', error);
		return false;
	}

	const diffYears = differenceInYears(new Date(), dateOfBirthDate);

	if (diffYears < 150 && diffYears >= MIN_DOB_AGE_YEARS) {
		return true;
	}

	return false;
}

export function validatePhoneNumber(phoneNumberString: string | undefined = ''): boolean {
	return /^[2-9]{1}[0-9]{9}$/.test(phoneNumberString);
}

export function validatePassword(password: string | undefined = '') {
	const tests = {
		length: /.{8,}/.test(password),
		lowercase: /[a-z]{1,}/.test(password),
		uppercase: /[A-Z]{1,}/.test(password),
		numeric: /\d{1,}/.test(password),
	};

	return { ...tests, valid: Object.values(tests).every((item) => item) };
}

export function convertMMDDYYToServerString(str: string) {
	const match = str.match(US_DATE_FORMAT_REGEX);

	if (match !== null) {
		const month = match[1];
		const day = match[2];
		const year = match[3];
		return `${year}-${month}-${day}`;
	} else {
		// no match
		throw new Error('Invalid date format');
	}
}

export function addProtocolToUrl(url: string) {
	// If the protocol is already present, return the original URL
	if (url.startsWith('https://') || url.startsWith('http://')) {
		return url;
	}

	// If the URL starts with "//", add "https:" as the default protocol
	if (url.startsWith('//')) {
		return `https:${url}`;
	}

	// Otherwise, add "https://" as the default protocol
	return `https://${url}`;
}
