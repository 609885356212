import { IAppointment } from '@mobe/api/appointments/appointmentApiHooks';
import Avatar from '@mobe/components/avatar/Avatar';
import CardButton from '@mobe/components/cardButton/CardButton';
import Icon from '@mobe/components/icon/Icon';
import Row from '@mobe/components/layout/Row';
import { Text } from '@mobe/components/text';
import { useFocusEffect } from '@react-navigation/native';
import { addMinutes, format, isAfter, isToday } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Animated,
	Easing,
	GestureResponderEvent,
	Platform,
	StyleProp,
	ViewStyle,
} from 'react-native';
import * as AppointmentsAnalyticsEvents from '../analyticsEvents';
import useAddToCalendar from '../useAddToCalendar';

interface IGuideApptCardProps {
	appointment: IAppointment;
	eventParams?: {
		notes?: string | undefined;
	};
	style?: StyleProp<ViewStyle>;
	onPress?: (event: GestureResponderEvent) => void;
	hideCalendarButton?: boolean;
}

export function GuideApptCard({
	appointment,
	eventParams,
	style,
	onPress = noop,
	hideCalendarButton = false,
}: IGuideApptCardProps) {
	const { t } = useTranslation();
	const [addToCalendarAnimationState, setAddToCalendarAnimationState] = React.useState<
		'idle' | 'textAnimating' | 'buttonAnimating' | 'done'
	>('idle');
	const fadeOutAnimation = React.useRef(new Animated.Value(0)).current;
	const slideUpAnimation = React.useRef(new Animated.Value(0)).current;

	const textOpacity = fadeOutAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 0],
	});

	const minHeight = slideUpAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [42, 0],
	});

	const paddingVertical = slideUpAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [8, 0],
	});

	const apptDate = new Date(appointment.appointmentStartDate || Date.now());
	const currentTime = new Date();
	const appointmentTimeAfterMeeting = addMinutes(apptDate, appointment.durationInMinutes);
	const isDisabled = isAfter(currentTime, appointmentTimeAfterMeeting);
	const apptDurationInMinutes = appointment.durationInMinutes || 0;
	let calendarEventTitle = '';
	if (appointment.isGuide) {
		calendarEventTitle = t('appointments.appointmentCard.guide.title', {
			guideName: appointment.preferredName,
		});
	}
	if (appointment.isPharmacist) {
		calendarEventTitle = t('appointments.appointmentCard.pharmacist.title', {
			pharmacistName: appointment.preferredName,
		});
	}

	const addToCalendar = useAddToCalendar({
		startDate: apptDate,
		duration: apptDurationInMinutes,
		title: calendarEventTitle,
		...eventParams,
	});

	useFocusEffect(
		React.useCallback(() => {
			// https://reactnative.dev/docs/0.64/animatedvalue#resetanimation does exist on this interface...
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			fadeOutAnimation.resetAnimation();

			// https://reactnative.dev/docs/0.64/animatedvalue#resetanimation does exist on this interface...
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			slideUpAnimation.resetAnimation();

			setAddToCalendarAnimationState('idle');
		}, [])
	);

	async function tryToAddEvent() {
		try {
			await addToCalendar.addEventToCalendar();

			AppointmentsAnalyticsEvents.appointmentAddToCalendar();

			setAddToCalendarAnimationState('textAnimating');

			Animated.sequence([
				Animated.delay(3000),
				Animated.timing(fadeOutAnimation, {
					useNativeDriver: false,
					toValue: 1,
					duration: 300,
					easing: Easing.ease,
				}),
			]).start(() => {
				setAddToCalendarAnimationState('buttonAnimating');

				Animated.sequence([
					Animated.timing(slideUpAnimation, {
						useNativeDriver: false,
						toValue: 1,
						duration: 500,
						easing: Easing.ease,
					}),
				]).start(() => {
					setAddToCalendarAnimationState('done');
				});
			});
		} catch (error) {
			console.error(error);
		}
	}

	function handleAddEventPress() {
		// Return early if button is clicked during other animation states than 'idle'
		if (addToCalendarAnimationState !== 'idle') {
			return;
		}

		tryToAddEvent();
	}

	if (!appointment.appointmentStartDate) {
		return null;
	}

	return (
		<CardButton
			disabled={isDisabled}
			style={style}
			cardButtonRight={!isDisabled ? <Icon name="right" size={15} /> : null}
			onPress={!isDisabled ? onPress : null}
			cardButtonFooterStyles={{ minHeight, paddingVertical }}
			cardButtonLeft={
				<Avatar
					imageSrc={appointment.avatarUrl ? { uri: appointment.avatarUrl } : undefined}
					letter={appointment.preferredName ? appointment.preferredName[0] : ''}
					backgroundColor={appointment.isPharmacist ? 'tertiary' : undefined}
				/>
			}
			cardButtonFooter={
				!(isDisabled || hideCalendarButton) ? (
					Platform.OS !== 'web' &&
					(addToCalendar.status === 'noPermissions' ||
						addToCalendar.status === 'noExistingEvent' ||
						addToCalendar.status === 'newEventAdded') ? (
						<>
							{/* Not added to calendar */}
							{addToCalendarAnimationState === 'idle' ? (
								<Row gutterSize={10}>
									<Row.Item>
										<Text color="primary" weight="bold">
											{t('appointments.appointmentCard.addToCalendar')}
										</Text>
									</Row.Item>
								</Row>
							) : null}

							{/* Adding to calendar, final add to calendar side effect occurs after animations complete */}
							{addToCalendarAnimationState === 'textAnimating' ||
							addToCalendarAnimationState === 'buttonAnimating' ? (
								<Animated.View style={{ opacity: textOpacity }}>
									<Row gutterSize={10}>
										<Row.Item>
											{addToCalendarAnimationState === 'textAnimating' && (
												<Icon size={15} name="checkmark" color="successDark" />
											)}
										</Row.Item>
										<Row.Item>
											{addToCalendarAnimationState === 'textAnimating' && (
												<Text color="successDark" weight="bold">
													{t('appointments.appointmentCard.addedToCalendar')}
												</Text>
											)}
										</Row.Item>
									</Row>
								</Animated.View>
							) : null}
						</>
					) : null
				) : null
			}
			footerPressableProps={{
				onPress: !isDisabled ? handleAddEventPress : null,
			}}
		>
			<Text weight="medium" color={!isDisabled ? 'regular' : 'light'}>
				{appointment.isGuide &&
					t('appointments.appointmentCard.guide.title', { guideName: appointment.preferredName })}
				{appointment.isPharmacist &&
					t('appointments.appointmentCard.pharmacist.title', {
						pharmacistName: appointment.preferredName,
					})}
			</Text>
			<Text weight="semiBold" color={!isDisabled ? 'regular' : 'light'}>
				{t('appointments.appointmentCard.dateTime', {
					date: isToday(apptDate)
						? t('appointments.appointmentCard.todayText')
						: format(apptDate, 'EEEE, MMMM d'),
					time: format(apptDate, 'p'),
				})}
			</Text>
		</CardButton>
	);
}

export function UpcomingGuideApptCard({ appointment, ...guideApptCardProps }: IGuideApptCardProps) {
	return <GuideApptCard appointment={appointment} {...guideApptCardProps} />;
}
