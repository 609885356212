import { Text } from '@mobe/components/text';
import env from '@mobe/env/env';
import * as React from 'react';
import { Platform } from 'react-native';

export default function EnvLabel() {
	const label =
		Platform.OS !== 'web'
			? `API: ${env.ENV_NAME} / App Version: ${env.APP_VERSION}`
			: `API: ${env.ENV_NAME} ${env.BUILD_DATE ? `/ Web Build Date: ${env.BUILD_DATE}` : ''}`;

	return env.isDev ? (
		<Text
			align="center"
			size="xs"
			style={{
				color: '#A9A9A9',
				paddingTop: 20,
			}}
		>
			{label}
		</Text>
	) : null;
}
