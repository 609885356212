import { createFlexibleGraphicProps } from '@mobe/components/graphics/utils';
import useColorScheme from '@mobe/utils/useColorScheme';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export default function LTGoalSimpleGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(73, 60);
	const colorScheme = useColorScheme();
	const isDarkTheme = colorScheme === 'dark';

	const gray1 = isDarkTheme ? '#AAAAAA' : '#FFFFFF';
	const gray2 = isDarkTheme ? '#666666' : '#D3D3D3';
	const gray3 = isDarkTheme ? '#999999' : '#BFBFBF';
	const gray4 = isDarkTheme ? '#CCCCCC' : '#A2A5A5';

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill={gray2}
					d="m72.783 18.108-2.589 1.105-6.058-3.505 2.202-.991 6.445 3.391zM67.32 5.935l-1.781 6.912-.015-.035-2.187.976 1.432-6.651 2.551-1.202zM67.006 20.566l-2.682 1.14-5.741-3.5 2.109-.951 6.042 3.371.272-.06zM64.443 7.295l-1.542 6.689-2.567 1.147 1.458-6.582 2.651-1.254z"
				/>
				<Path fill={gray3} d="m64.135 15.701 6.058 3.505-.339.145-6.16-3.451.441-.199z" />
				<Path fill={gray2} d="m69.853 19.347-2.701 1.149-6.014-3.448 2.56-1.152 6.155 3.451z" />
				<Path
					fill={gray3}
					d="m61.133 17.053 6.014 3.448-.148.064-.273.06-6.042-3.372.449-.2zM64.77 7.138l-1.433 6.651-.438.194 1.542-6.689.329-.156zM61.417 8.776l.225-.161.147-.07-1.458 6.582-.437.194 1.523-6.545z"
				/>
				<Path
					fill={gray2}
					d="m61.643 8.623-.225.161-1.519 6.545-2.125.949 1.236-6.411 2.633-1.244z"
				/>
				<Path
					fill={gray2}
					d="M56.941 20.149c5.36 15.364-2.595 32.215-17.783 37.67-15.201 5.42-31.873-2.62-37.269-17.972-5.361-15.364 2.594-32.215 17.782-37.67 14.046-5.006 29.57 1.459 36.052 15.013l-5.354 2.395C45.13 8.856 32.786 3.775 21.623 7.753 9.476 12.115 3.114 25.592 7.402 37.879c4.315 12.278 17.649 18.708 29.805 14.374 12.147-4.362 18.508-17.839 14.221-30.127-.07-.201-.143-.4-.218-.596l5.347-2.408c.134.335.259.679.384 1.027z"
				/>
				<Path
					fill={gray1}
					d="M51.427 22.119c4.288 12.288-2.073 25.765-14.221 30.127C25.049 56.58 11.716 50.15 7.4 37.872 3.112 25.585 9.474 12.107 21.621 7.745c11.164-3.978 23.507 1.103 28.746 11.831l-5.354 2.389c-3.996-7.902-13.159-11.598-21.44-8.649-9.107 3.269-13.876 13.372-10.662 22.583 3.232 9.205 13.228 14.027 22.342 10.776 9.105-3.269 13.875-13.37 10.661-22.581a9.938 9.938 0 0 0-.056-.158l5.35-2.41c.077.193.148.392.219.593z"
				/>
				<Path
					fill={gray2}
					d="M45.917 24.097c3.213 9.211-1.556 19.312-10.661 22.581-9.113 3.25-19.109-1.571-22.342-10.776C9.7 26.691 14.47 16.589 23.575 13.32c8.281-2.949 17.444.747 21.44 8.649l-5.364 2.395c-2.745-5.076-8.726-7.393-14.124-5.47-6.063 2.177-9.239 8.904-7.099 15.037 2.154 6.129 8.81 9.338 14.879 7.174 5.954-2.139 9.143-8.679 7.193-14.754l5.362-2.415c.016.055.036.108.055.161z"
				/>
				<Path
					fill={gray1}
					d="M40.497 26.347c1.95 6.075-1.239 12.615-7.193 14.754-6.068 2.165-12.725-1.045-14.879-7.174-2.14-6.133 1.036-12.86 7.099-15.037 5.398-1.923 11.379.393 14.124 5.469l-5.387 2.405a5.776 5.776 0 0 0-6.786-2.302 5.83 5.83 0 0 0-3.306 3.005 5.93 5.93 0 0 0-.235 4.487c1.072 3.057 4.392 4.658 7.417 3.576 2.745-.983 4.345-3.869 3.741-6.748l5.405-2.435z"
				/>
				<Path
					fill={gray2}
					d="M35.095 28.788c.604 2.879-.997 5.765-3.741 6.748-3.025 1.082-6.346-.519-7.417-3.576a5.926 5.926 0 0 1 .235-4.487 5.83 5.83 0 0 1 3.306-3.005c2.517-.9 5.316.05 6.786 2.303l-5.347 2.386c-.298.107-.534.34-.649.638-.105.274-.098.578.019.846s.335.479.606.584c.273.107.577.098.843-.026l5.359-2.411z"
				/>
				<Path
					fill={gray4}
					d="m65.54 12.845-.015-.035-2.187.976-.438.194-2.567 1.147-.437.194-2.125.949-2.05.914-5.354 2.395-5.354 2.389-5.363 2.395-5.387 2.404-5.347 2.386a1.09 1.09 0 0 0-.649.638c-.105.273-.098.578.019.846s.335.478.606.584c.273.107.577.098.843-.026l5.361-2.415 5.403-2.435 5.362-2.415 5.35-2.41 5.347-2.408 2.025-.913 2.109-.951.443-.201 2.56-1.152.442-.199 2.201-.991.019-.009-.817-1.851z"
				/>
			</Svg>
		</View>
	);
}
