import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export interface IAssessment {
	physicalActivity?: number;
	nutrition?: number;
	sleep?: number;
	stress?: number;
	medicationSatisfaction?: number;
	additionalNotes?: string;
}

export async function getAssessment() {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IAssessment>('assessments');

				return mobeStandardSuccessProcessor<IAssessment>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IAssessment>(error);
			}
		})()
	);
}

export type IPatchAssessment = Partial<IAssessment>;

export async function patchAssessment(params: IPatchAssessment) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				await mobeAuthenticatedAPI.patch('assessments', params);

				return mobeStandardSuccessProcessor();
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}
