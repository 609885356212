import { ExploreContentType } from '@mobe/api/explore/exploreService';
import { useTranslation } from 'react-i18next';

export default function useTimeToConsumeMessage({
	contentType,
	timeToConsume,
}: {
	contentType?: ExploreContentType;
	timeToConsume?: number;
}) {
	const { t } = useTranslation();

	if (!contentType || !timeToConsume) {
		return '';
	}

	const timeToConsumeMessage = {
		[ExploreContentType.Video]: t('explore.timeToConsume.video', { count: timeToConsume }),
		[ExploreContentType.Article]: t('explore.timeToConsume.article', { count: timeToConsume }),
		[ExploreContentType.Recipe]: t('explore.timeToConsume.recipe', { count: timeToConsume }),
		[ExploreContentType.Habits]: t('explore.timeToConsume.habits', { count: timeToConsume }),
		[ExploreContentType.Audio]: t('explore.timeToConsume.audio', { count: timeToConsume }),
	}[contentType];

	return timeToConsumeMessage;
}
