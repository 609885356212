import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';

export enum Permissions {
	CanSelectGSMOrMSKLanguage = 'canSelectGsmOrMskLanguage',
	CanSelectGSMOrMSKGender = 'canSelectGsmOrMskGender',
	CanSelectGsmOrMskSpanishGender = 'canSelectGsmOrMskSpanishGender',
	CanSelectPharmacistLanguage = 'canSelectPharmacistLanguage',
	CanSelectPharmacistGender = 'canSelectPharmacistGender',
	HidePharmacistFeatures = 'hidePharmacistFeatures',
}

export async function getPermissions() {
	return mobeStandardQueryAndMutationProcessor<Permissions[]>(
		await (async () => {
			try {
				const response = await mobeAuthenticatedAPI.get<Permissions[]>('permissions');

				return mobeStandardSuccessProcessor<Permissions[]>(response);
			} catch (error) {
				return mobeStandardErrorProcessor(error);
			}
		})()
	);
}
