import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

interface IPharmacistIntroGraphicProps extends SvgProps {
	backgroundColor?: string;
}

export default function PharmacistIntroGraphic({
	backgroundColor,
	...props
}: IPharmacistIntroGraphicProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(264, 280);
	const rules = useStyleRules();
	const bgColor = backgroundColor || rules.colors.strokeLight;

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="none">
					<Path
						d="M132 16.554c72.9 0 132 58.85 132 131.445s-59.1 131.445-132 131.445S0 220.594 0 147.999 59.1 16.554 132 16.554z"
						fill={bgColor}
					/>
					<Path
						d="M95.541 212.06c5.486 0 10.33 7.219 13.23 18.219l20.132.352-1.464 48.736c-16.266-.55-31.785-4.03-46.042-9.928a53.272 53.272 0 01-1.92-15.306c0-23.237 7.192-42.073 16.064-42.073z"
						fill="#1A3A5E"
					/>
					<Path
						d="M166.894 220.308l-2.88 55.244A132.815 132.815 0 01132 279.444c-9.731 0-19.216-1.049-28.348-3.039a488.856 488.856 0 00-11.397-39.357l-.043-.126 74.682-16.614z"
						fill="#224A70"
					/>
					<Path
						d="M119.051 25.088c-4.425-.33-28.287 14.57-28.96 39.428-.673 24.858-5.772 34.188-14.3 30.728 8.845 11.149 22.273.291 26.482-13.737.939-3.129 2.42-3.8 3.649-.11-1.469-4.425 8.543-9.849 6.151-18.658-4.662-17.158 2.443-23.924 7.076-29.131 3.748-4.221-.098-8.52-.098-8.52z"
						fill="#263338"
					/>
					<Path
						d="M113.583 54.096s-9.85-13.359-11.658-23.415c-1.29-7.18 2.407-33.795 29.456-29.961a36.992 36.992 0 0115.243 5.7c5.872 3.921 14.348 12.388 4.872 35.614l-2.461 7.845-35.452 4.217z"
						fill="#263338"
					/>
					<Path
						d="M150.17 93.575a24.849 24.849 0 01-14.632 24.02c-5.976-3.76-12.016-7.569-16.925-12.62-4.909-5.051-8.652-11.54-9.006-18.536a43.54 43.54 0 01.618-8.181l3.771-27.086c-.3 2.167 14.284 3.1 15.862 3.267 8.4.875 11.487 2.241 11.43 11.352-.069 9.768 8.124 17.871 8.882 27.784z"
						fill="#B87154"
					/>
					<Path
						d="M142.028 71.182s-2.013 3.49-12.92 2.514c-10.907-.976-13.26-13.177-13.26-13.177l-1.857-9.345s27.313 12.1 27.293 14.409a24.02 24.02 0 00.744 5.599z"
						fill="#A3604B"
					/>
					<Path
						d="M151.893 82.352l-11.771 29.031-35.254-27.192c2 1.544-.169 19-.191 21.425l-.432 48.237c-.14 15.619.287 31.36-.419 46.963-.326 7.188-3.415 14.884 2.8 20.039 7.82 6.48 25.952 6.011 35.5 5.867 8.62-.13 33.65-.569 36.227-9.272.928-3.14-2.055-10.485-2.676-13.664l-9.349-47.723-14.435-73.711z"
						fill="#F5FBFF"
					/>
					<Path
						d="M176.801 86.424s24.095 88.392 24.48 93.945c.385 5.553-14.449 32.238-14.449 32.238L167.125 93.866l9.676-7.442z"
						fill="#46A57D"
					/>
					<Path
						d="M144.768 73.868l32.033 12.556 1.165 149.27s-21.031 6.994-28.486-2.287l11.217-83.783-15.929-75.756zM95.172 236.923a52.649 52.649 0 0015.627 3.229c4.61.24 9.233.087 13.818-.457 2.57-.278 14.6-2.939 14.6-4.865l.046-89.665s-21.45-48.062-25.405-56.914c-3.941-8.87-2.04-21.424-2.04-21.424l-23.384 26.87 1.484 35.075-2.37 41.048s.555.149-1.655 28.7c-.575 7.566-1.3 15.132-1.747 22.7-.61 10.288 1.902 12.213 11.026 15.703z"
						fill="#4DB388"
					/>
					<Path
						d="M110.141 240.16c.314.04.601-.18.643-.493a.558.558 0 00-.126-.432l-21.144-25.713c-2.461-2.761-6.38-8.437-6.121-11.976l-2.248 23.213c-.149 13.672 22.754 15.094 28.996 15.401zm-3.248-116.075c-1.3 2.053-17.891 24.936-17.891 24.936l-3.4 47.273h1.351c.226-1.768.832-9.96 1.292-11.67l8.891-36.023 9.757-24.516z"
						fill="#46A57D"
					/>
					<Path
						d="M110.206 47.793c5.176 4.084 3 12.376 8.931 17.063 5.764 4.563 14.51 5.821 20.834 1.68 17.089-11.186 22.026-63.563-8.466-58.909-12.522 1.911-21.488 16.874-18.3 28.678-6.008-9.361-11.535 2.323-6.831 9.507a3.43 3.43 0 001.847 1.6c.649.167 1.414-.066 1.985.381z"
						fill="#B87154"
					/>
					<Path
						fill="#46A57D"
						d="M139.257 145.162l-37.513-40.027 7.663-4.7-18.003-1.138-1.007-9.885 21.369-22.259.727 16.822zm18.644-8.842l6.806-34.966-3.5-3.578 10.688-2.222s-24.35-21.234-29.7-23.824l9.695 37.161 6.011 27.429z"
					/>
					<Path
						d="M117.861 37.292a38.45 38.45 0 01-13.2 1.622l-2.205-6.975a44.35 44.35 0 008.33-20.262s12.7-12.4 28.264-5.107c2.17-2.04 5.991-2.806 11.177 3.254 8.3 9.7 3.137 27.931 3.137 27.931-3.156.4-4.638-11.291-6.291-17.033-1.218-4.23-5.211-8.539-7.234-10.523-.65 2.611-7.328 27.933-24.006 36.344a83.052 83.052 0 0013.326-17.754c-3.238 4.006-7.039 7.308-11.298 8.503z"
						fill="#263338"
					/>
					<Path
						d="M127.928 201.492s.621 4.663-.448 6.462c-11.876 3.58-43.693 1.906-43.693 1.906l-.239-10.475 44.38 2.107z"
						fill="#46A57D"
					/>
					<Path fill="#70BFE0" d="M120.493 210.954l58.96 12.731 56.261-66.181-61.122-14.331z" />
					<Path
						d="M138.432 188.472c4.41.216 7.932.872 11.442 3.817 1.611 1.353 6.168 7.544 4.266 9.634-1.412 1.552-13.081-.637-15.385-.784l8.156 2.275c1.246.348 2.812 1.26 2.366 2.465a1.693 1.693 0 01-1.8.9 7.4 7.4 0 01-2.036-.718c-4.233-1.8-9.5-.4-14-.673-2.479-.151-4.706-.041-7.232-.185-3.719-.21-7.741-.706-11.339-1.269.296-1.89.7-3.762 1.213-5.606.557-1.829-.779-4.456.9-4.886 3.556-.9 6.49-3.914 9.8-4.74 5.95-1.484 12.354-5.782 18.5-6.312-1.358 2.057-3.345 4.198-4.851 6.082zm51.942 31.562c-.826.207-4.584-2.7-5.4-3.245-1.418-.934-6.293-3.887-6.105-5.791.1-1.029 7.477 1.454 7.968 1.612-1.231-.386-5.732-5.086-5.322-6.452.715-2.382 7.015 1.169 8.362 1.68-1.5-.569-3.716-1.9-4.485-3.358-2.653-5.058 6.11-2.156 8.11-1.731-2.058-1.17-6.2-3.841-3.451-6.207 1.335-1.149 11.825-1.2 12.682.031.071.1.537 1.1.575 1.207-1.088 7.903-3.894 19.866-12.934 22.254z"
						fill="#B87154"
					/>
					<Path
						d="M117.917 189.534l4.863 17.175s-83.528 5.932-74.2-45.361C54.063 135.19 90.4 89.414 90.4 89.414l1.572 14.49 1.748 36.04-16 26.015 40.197 23.575z"
						fill="#4DB388"
					/>
				</G>
			</Svg>
		</View>
	);
}
