import { useAuth } from '@mobe/api/authentication/AuthContext';
import { Button } from '@mobe/components/button';
import { ButtonVariants } from '@mobe/components/button/buttonStyles';
import Popup from '@mobe/components/popup/Popup';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { IButtons, useAlertModal } from './AlertModalProvider';

export function AlertModal() {
	const { isVisible, setIsVisible, title, message, buttons } = useAlertModal();
	const { vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();
	const { isAuthenticated } = useAuth();

	// Dismiss alert automatically when auto logged out
	React.useEffect(() => {
		if (!isAuthenticated) {
			setIsVisible(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	const handleClose = () => {
		setIsVisible(false);
	};

	// The 'cancel' style assumes primary button appearance when the other button is destructive
	const cancelIsPrimary =
		buttons.filter((button) => button.style === 'cancel' || button.style === 'destructive').length >
		1;

	function buttonStyle(style: IButtons['style']): ButtonVariants {
		if (style === 'default' || !style) {
			return 'primary';
		}

		if (style === 'cancel' && cancelIsPrimary) {
			return 'primary';
		}

		return 'secondary';
	}

	return (
		<Popup showClose={false} visible={isVisible} heading={title} showHeader={title.length > 0}>
			{Boolean(message) && (
				<Text accessibilityAutoFocus={!title} style={[vr(3), vrTop(1)]}>
					{message}
				</Text>
			)}
			<View style={[vr(1), vrTop(3)]}>
				{buttons.length > 0 ? (
					<View style={{ flex: 1, flexDirection: 'row' }}>
						{buttons.map((button, i) => {
							return (
								<View key={button.text} style={{ flexGrow: 1, marginLeft: i > 0 ? 16 : 0 }}>
									<Button
										variant={buttonStyle(button.style)}
										title={button.text ? button.text : ' '}
										onPress={() => {
											button.onPress(), handleClose();
										}}
									/>
								</View>
							);
						})}
					</View>
				) : (
					<View>
						<Button
							size="sm"
							title={t('genericConfirmation')}
							onPress={() => handleClose()}
							style={{ alignSelf: 'flex-end' }}
						/>
					</View>
				)}
			</View>
		</Popup>
	);
}
