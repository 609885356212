import { useQuery } from 'react-query';
import { APIErrorData } from '../APIResponse';
import { useAuth } from '../authentication/AuthContext';
import progressService, { IProgress } from './progressService';

export enum ProgressQueryKeys {
	AllProgress = 'progress.allProgress',
}

export function useProgressQuery() {
	const auth = useAuth();

	return useQuery<IProgress, APIErrorData>(
		ProgressQueryKeys.AllProgress,
		async () => {
			const response = await progressService.getProgress();

			if (!response.success) {
				throw response.error;
			}

			return response.data;
		},
		{
			enabled: auth.isAuthenticated,
		}
	);
}
