import { useAuth } from '@mobe/api/authentication/AuthContext';
import { CelebrationsImageType } from '@mobe/api/celebrations/celebrationsService';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, StyleSheet, View } from 'react-native';
import { Button } from '../button';
import Confetti, { IConfettiMethods } from '../confetti/Confetti';
import Heading from '../heading/Heading';
import { Text } from '../text';
import TextButton from '../textButton/TextButton';
import ASCompleteGraphic from './graphics/ASCompleteGraphic';
import LTGoalCompleteGraphic from './graphics/LTGoalCompleteGraphic';
import STGoalCompleteGraphic from './graphics/STGoalCompleteGraphic';
import { useCongratsPopupStore } from './useCongratsPopupStore';

function CongratsPopupGraphic({ imageType }: { imageType: CelebrationsImageType }) {
	const imageMap: Record<CelebrationsImageType, React.ReactNode> = {
		LTGoalComplete: <LTGoalCompleteGraphic />,
		STGoalComplete: <STGoalCompleteGraphic />,
		ActionStepComplete: <ASCompleteGraphic />,
	};

	return imageMap[imageType];
}

export default function CongratsPopup(modalProps: ModalProps) {
	const styles = useStyles();
	const auth = useAuth();
	const { t } = useTranslation();
	const { vr, vrTop, constrain } = useStyleHelpers();
	const congratsPopupStore = useCongratsPopupStore();
	const message = congratsPopupStore.message;
	const confettiRef = React.useRef<IConfettiMethods>(null);

	React.useEffect(() => {
		if (message) {
			// Multiple modals cannot be made visible within the same event loop
			setTimeout(() => {
				confettiRef.current?.showConfetti();
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message]);

	React.useEffect(() => {
		if (!auth.isAuthenticated) closePopup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.isAuthenticated]);

	function closePopup() {
		if (typeof congratsPopupStore.message?.onClose === 'function') {
			congratsPopupStore.message.onClose();
		}

		congratsPopupStore.clear();
	}

	function handleClosePress() {
		closePopup();
	}

	function handleUndoPress() {
		congratsPopupStore.clear();

		if (typeof congratsPopupStore.message?.onUndo === 'function') {
			congratsPopupStore.message.onUndo();
		}
	}

	if (congratsPopupStore.message === null) {
		return null;
	}

	return (
		<Modal
			animationType="fade"
			transparent
			statusBarTranslucent
			visible={congratsPopupStore.message !== null && auth.isAuthenticated}
			{...modalProps}
		>
			<Confetti ref={confettiRef} />
			<View style={styles.container}>
				<View style={styles.sneezeGuard}></View>
				<View style={styles.popup}>
					<View style={styles.popupBody}>
						{congratsPopupStore.message?.imageType !== null && (
							<View style={[constrain(180), vr(6)]}>
								<CongratsPopupGraphic imageType={congratsPopupStore.message.imageType} />
							</View>
						)}

						<Heading level="h3" align="center" style={vr(3)}>
							{congratsPopupStore.message?.title}
						</Heading>
						<Text style={vr(6)} align="center">
							{congratsPopupStore.message?.message}
						</Text>
						<Button
							size="sm"
							title="Got it!"
							style={[constrain(200), { width: '100%' }]}
							onPress={handleClosePress}
						/>
						{Boolean(congratsPopupStore.message?.onUndo) && (
							<TextButton
								align="center"
								title={t('genericUndo')}
								onPress={handleUndoPress}
								style={vrTop(4)}
							/>
						)}
					</View>
				</View>
			</View>
		</Modal>
	);
}

function useStyles() {
	const rules = useStyleRules();
	const { popupShadow } = useStyleHelpers();

	return StyleSheet.create({
		container: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			alignItems: 'center',
			justifyContent: 'center',
			paddingHorizontal: rules.spacing.appHorizontalMargin,
		},
		sneezeGuard: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			backgroundColor: rules.colors.sneezeGuard,
		},
		popup: {
			width: '100%',
			maxWidth: rules.spacing.maxWidthNarrow,
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: rules.colors.cardBackground,
			borderRadius: rules.borderRadius,
			...popupShadow,
		},
		popupHeader: {
			width: '100%',
			padding: 12,
			borderBottomColor: rules.colors.stroke,
			borderBottomWidth: 1,
		},
		popupBody: {
			width: '100%',
			paddingHorizontal: 24,
			paddingVertical: 32,
		},
	});
}
