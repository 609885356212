import { useTrackersFavoritesMutation } from '@mobe/api/track/trackApiHooks';
import IconButton, { IIconButtonProps } from '@mobe/components/iconButton/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IFavoriteButtonProps extends Partial<IIconButtonProps> {
	favorited?: boolean;
	trackerTypeId: number;
}

export default function TrackerFavoriteButton({
	favorited = false,
	trackerTypeId,
	...iconButtonProps
}: IFavoriteButtonProps) {
	const trackersFavoritesMutation = useTrackersFavoritesMutation();
	const { t } = useTranslation();

	return (
		<IconButton
			role="checkbox"
			accessibilityLabel={t('track.favoriteButtonAccessibilityLabel')}
			aria-label={t('track.favoriteButtonAccessibilityLabel')}
			aria-checked={favorited}
			color={favorited ? 'favorite' : 'textLight'}
			name={favorited ? 'star_solid' : 'star'}
			onPress={() => trackersFavoritesMutation.mutate({ trackerTypeId, isFavorite: !favorited })}
			{...iconButtonProps}
		/>
	);
}
