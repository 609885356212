import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { InteractionManager, Platform } from 'react-native';

export default function useScreenTransitioning() {
	// Android is afflicted by app crashing issues when a webview attempts to load during screen transitions.
	// This flag ensures that content components aren't instantiated until after transitions resolve.
	// iOS isn't an issue, so it is not deferred like Android, which improves perceived performance slightly.
	const [isTransitioning, setIsTransitioning] = React.useState(() =>
		Platform.OS === 'android' ? true : false
	);

	useFocusEffect(
		React.useCallback(() => {
			const task = InteractionManager.runAfterInteractions(() => {
				setIsTransitioning(false);
			});

			return () => task.cancel();
		}, [])
	);

	return { isTransitioning, setIsTransitioning };
}
