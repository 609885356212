import * as React from 'react';
import IconButton from '../iconButton/IconButton';
import InputText, { IInputTextProps } from './InputText';

export interface IInputPasswordProps extends IInputTextProps {
	showVisibilityToggle?: boolean;
}

function InputPassword(
	{ showVisibilityToggle = false, ...inputTextProps }: IInputPasswordProps,
	ref: any
) {
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	return (
		<InputText
			{...inputTextProps}
			ref={ref}
			autoComplete="password"
			secureTextEntry={!showPassword}
			autoCorrect={false}
			spellCheck={false}
			textContentType="password"
			autoCapitalize="none"
			inputRight={
				showVisibilityToggle
					? ({ hasError, disabled }) => (
							<IconButton
								name={showPassword ? 'visibilityOn' : 'visibilityOff'}
								role="switch"
								aria-label={showPassword ? 'hide password' : 'show password'}
								onPress={() => setShowPassword(!showPassword)}
							/>
					  )
					: undefined
			}
		/>
	);
}

export default React.forwardRef(InputPassword);
