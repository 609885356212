import { CoachType } from '@mobe/api/guides/guidesApi';
import { Button } from '@mobe/components/button';
import { PharmacistIntroGraphic } from '@mobe/components/graphics';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { PharmacistIntroScreenNavigationProp } from '../AppointmentStackScreen';

export default function PharmacistIntroScreen({
	navigation,
}: {
	navigation: PharmacistIntroScreenNavigationProp;
}) {
	const { vr, constrain, constrainText } = useStyleHelpers();
	const { t } = useTranslation();

	return (
		<ScreenTemplateWithFooter
			fixedPositionFooter
			centered
			footer={
				<Button
					title={t('appointments.pharmacistMatch.introView.button')}
					onPress={() =>
						navigation.replace('APPT_FOLLOW_UP_SCREEN', { coachType: CoachType.Pharmacist })
					}
				/>
			}
		>
			<View style={[vr(8), constrain(250)]}>
				<PharmacistIntroGraphic />
			</View>
			<View style={constrainText(350)}>
				<Text accessibilityAutoFocus align="center" color="light">
					{t('appointments.pharmacistMatch.introView.description')}
				</Text>
			</View>
		</ScreenTemplateWithFooter>
	);
}
