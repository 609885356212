import { intervalToDuration } from 'date-fns';
import { ImageSourcePropType } from 'react-native';
import {
	mobeAuthenticatedAPI,
	mobeStandardErrorProcessor,
	mobeStandardQueryAndMutationProcessor,
	mobeStandardSuccessProcessor,
} from '../mobeAPI';
import { IMetadataPagination } from '../paginationUtils';

export enum TrackerAbbreviation {
	Mood = 'MT',
	Steps = 'Steps',
	Movement = 'MM',
	Weight = 'Weight',
	Nutrition = 'Nutrition',
	Hydration = 'WT',
	Sleep = 'HS',
	StressExperience = 'SE',
	StressManagement = 'SM',
	EmotionalHealth = 'EH',
}

export enum TrackerSource {
	/**
	 * This source is attached to legacy data migrated from the old app
	 */
	Device = 'Device',
	Manual = 'Manual',
	Guide = 'Guide',

	AppleHealth = 'apple_health',
	GoogleFit = 'google_fit_sdk',

	Fitbit = 'fitbit',
	Garmin = 'garmin',
	IHealth = 'ihealth',
	Misfit = 'misfit',
	Omron = 'omron',
	Polar = 'polar',
	UnderArmour = 'underarmour',
	Withings = 'withings',

	None = 'none',
}

export interface ITrackerType {
	/**
	 * This property serves as a non-numerical unique identifier for each tracker
	 */
	trackerAbbreviation: TrackerAbbreviation;
	trackerTypeId: number;
	isFavorite: boolean;
	lastEntry: null | string;
	lastValue: null | string;
}

export interface ITrackerDetailsResponse {
	trackerTypeId: number;
	goal: number | null;
	hasConnectedDevice: boolean;
	interval: string;
	isFavorite: boolean;
	lastEntry: string; // date string
	minValue: number | null;
	maxValue: number | null;
	hasNewSource: boolean;
	currentSource: string;
	consumerTrackers: ITrackerDetail[];
}

export interface ITrackerDetail {
	activityDateTime: string;
	consumerTrackerId: number;
	source: TrackerSource;
	sourceDescription: string | null;
	value: number;
	trackerSourceName: string;
	graphColor: string;
}

export interface IFavoriteTrackerResponse {
	isFavorite: boolean;
	trackerAbbreviation: string;
	trackerTypeId: number;
}

export interface ITrackerItem {
	value: string;
	activityDateTime: string;
	trackerTypeId: number;
}

export interface IMarketplaceTrackerResponse {
	type: TrackerSource;
	isConnected: boolean;
	logoUrl: string;
	displayName: string;
	tagline: string;
	connectUrl?: string;
	disconnectUrl?: string;
}

export interface IMarketplaceTracker extends Omit<IMarketplaceTrackerResponse, 'logoUrl' | 'type'> {
	logoSource: ImageSourcePropType;
	trackerSourceName: TrackerSource;
}

export interface ITrackerTypeSource {
	trackerSourceId: number;
	trackerTypeId: number;
	trackerSourceName: TrackerSource;
	isPrimary: boolean;
}

export interface ITrackerTypeSourcesResponse {
	trackerTypeSources: ITrackerTypeSource[] | null;
}

export interface INativeTrackerConnectedResponse {
	isConnected: boolean;
}

export async function getAllTrackerTypes() {
	return mobeStandardQueryAndMutationProcessor<ITrackerType[]>(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<ITrackerType[]>('tracker-types');

				return mobeStandardSuccessProcessor<ITrackerType[]>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<ITrackerType[]>(error);
			}
		})()
	);
}

export async function getTrackerDetails(trackerTypeId: number) {
	try {
		const data = await mobeAuthenticatedAPI.get<ITrackerDetailsResponse>(
			`tracker-types/${trackerTypeId}/details`,
			{
				// TODO: Remove these params after endpoint deprecates them
				// Passing same start and end date now here because we will no longer
				// be using this endpoint for getting user tracker entries.
				// use tracker entry related endpoints instead
				StartDate: new Date(Date.now()).toISOString(),
				EndDate: new Date(Date.now()).toISOString(),
			}
		);

		return mobeStandardSuccessProcessor<ITrackerDetailsResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<ITrackerDetailsResponse>(error);
	}
}

export interface ISetTrackerFavoriteStatus {
	trackerTypeId: number;
	isFavorite: boolean;
}

export async function setTrackerFavoriteStatus({
	trackerTypeId,
	isFavorite,
}: ISetTrackerFavoriteStatus) {
	try {
		const data = await mobeAuthenticatedAPI.put<IFavoriteTrackerResponse>(
			`tracker-types/${trackerTypeId}/favorite`,
			{
				isFavorite,
			}
		);

		return mobeStandardSuccessProcessor<IFavoriteTrackerResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor<IFavoriteTrackerResponse>(error);
	}
}

export interface ISetTrackerGoal {
	trackerTypeId: number;
	goalValue: number | null;
}

export async function setTrackerGoal({ trackerTypeId, goalValue }: ISetTrackerGoal) {
	try {
		await mobeAuthenticatedAPI.post('goals', {
			trackerTypeId,
			goalValue,
		});

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function addTrackerData(trackerItem: ITrackerItem) {
	try {
		await mobeAuthenticatedAPI.post<ITrackerItem>('trackers', trackerItem);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export interface IGetTrackerEntriesParams {
	startDate?: string;
	endDate?: string;
	skip?: number;
	take?: number;
}

interface IGetTrackerEntriesPagination extends IMetadataPagination {
	startDate: string;
	endDate: string;
}

export interface IGetTrackerEntriesResponse {
	metadata: IGetTrackerEntriesPagination;
	data: ITrackerDetail[];
}

export async function getTrackerEntries(trackerTypeId: number, params: IGetTrackerEntriesParams) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IGetTrackerEntriesResponse>(
					`trackers/${trackerTypeId}/entries`,
					params
				);

				return mobeStandardSuccessProcessor<IGetTrackerEntriesResponse>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IGetTrackerEntriesResponse>(error);
			}
		})()
	);
}

export enum TrackerAggregatedEntriesInterval {
	Day = 'Day',
	Week = 'Week',
	Month = 'Month',
}

export function intervalFromCustomDateRange(
	startDate: Date,
	endDate: Date
): TrackerAggregatedEntriesInterval {
	const duration = intervalToDuration({
		start: startDate,
		end: endDate,
	});

	if (typeof duration.months === 'number' && duration.months >= 2) {
		return TrackerAggregatedEntriesInterval.Week;
	}

	if (typeof duration.months === 'number' && duration.months >= 7) {
		return TrackerAggregatedEntriesInterval.Month;
	}

	return TrackerAggregatedEntriesInterval.Day;
}

export interface IGetTrackerAggregatedEntriesParams {
	startDate: string;
	endDate: string;
	interval: TrackerAggregatedEntriesInterval;
}

export interface ITrackerAggregatedEntryDataItem {
	date: string; // Local date string - YYYY-MM-DDTHH:mm:ss
	value: number | null;
	goalValue: number | null;
}

export interface IGetTrackerAggregatedEntriesResponse {
	startDate: string; // Local date string - YYYY-MM-DDTHH:mm:ss
	endDate: string; // Local date string - YYYY-MM-DDTHH:mm:ss
	interval: TrackerAggregatedEntriesInterval;
	data: ITrackerAggregatedEntryDataItem[];
}

export async function getTrackerAggregatedEntries(
	trackerTypeId: number,
	params: IGetTrackerAggregatedEntriesParams
) {
	return mobeStandardQueryAndMutationProcessor(
		await (async () => {
			try {
				const data = await mobeAuthenticatedAPI.get<IGetTrackerAggregatedEntriesResponse>(
					`trackers/${trackerTypeId}/aggregated-entries`,
					params
				);

				return mobeStandardSuccessProcessor<IGetTrackerAggregatedEntriesResponse>(data);
			} catch (error) {
				return mobeStandardErrorProcessor<IGetTrackerAggregatedEntriesResponse>(error);
			}
		})()
	);
}

export interface IUpdateTrackerData {
	trackerEntry: Partial<ITrackerItem>;
	consumerTrackerId: number;
}

export async function updateTrackerData({ consumerTrackerId, trackerEntry }: IUpdateTrackerData) {
	try {
		await mobeAuthenticatedAPI.patch<unknown>(`trackers/${consumerTrackerId}`, {
			value: trackerEntry.value,
			activityDateTime: trackerEntry.activityDateTime,
		});
		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function deleteTrackerData(consumerTrackerId: number) {
	try {
		await mobeAuthenticatedAPI.delete(`trackers/${consumerTrackerId}`);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function getValidicUserPlatforms() {
	try {
		const data =
			await mobeAuthenticatedAPI.get<IMarketplaceTrackerResponse[]>(`tracking-connections`);

		const dataWithFormattedUrl: IMarketplaceTracker[] = data.map((platform) => {
			const formattedUrl =
				platform.logoUrl.indexOf('//') === 0 ? `https:${platform.logoUrl}` : platform.logoUrl;
			const trackerSourceName = platform.type;
			return { ...platform, logoSource: { uri: formattedUrl }, trackerSourceName };
		});

		return mobeStandardSuccessProcessor<IMarketplaceTracker[]>(dataWithFormattedUrl);
	} catch (error) {
		return mobeStandardErrorProcessor<IMarketplaceTracker[]>(error);
	}
}

export async function getTrackerTypeSources(trackerTypeId: number) {
	try {
		const data = await mobeAuthenticatedAPI.get<ITrackerTypeSourcesResponse>(
			`tracker-types/${trackerTypeId}/tracker-type-sources`
		);

		return mobeStandardSuccessProcessor<ITrackerTypeSource[]>(data.trackerTypeSources || []);
	} catch (error) {
		return mobeStandardErrorProcessor<ITrackerTypeSource[]>(error);
	}
}

export interface ISetTrackerTypeSource {
	trackerTypeId: number;
	trackerSourceId: number;
}

export async function setTrackerTypeSource({
	trackerTypeId,
	trackerSourceId,
}: ISetTrackerTypeSource) {
	try {
		await mobeAuthenticatedAPI.patch<IFavoriteTrackerResponse>(
			`tracker-types/${trackerTypeId}/tracker-type-sources`,
			{
				primaryTrackerSourceId: trackerSourceId,
			}
		);

		return mobeStandardSuccessProcessor();
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}

export async function setNativeTrackerConnection(source: TrackerSource, isConnected: boolean) {
	try {
		const data = await mobeAuthenticatedAPI.patch<INativeTrackerConnectedResponse>(
			`tracking-connections/${source}`,
			{
				isConnected,
			}
		);

		return mobeStandardSuccessProcessor<INativeTrackerConnectedResponse>(data);
	} catch (error) {
		return mobeStandardErrorProcessor(error);
	}
}
