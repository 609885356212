import { getAPIVersion } from '@mobe/api/version/versionService';
import env from '@mobe/env/env';
import * as React from 'react';
import { Platform } from 'react-native';
import { useQuery } from 'react-query';

interface IInternetConnectionContextValue {
	isConnected: boolean | undefined;
}

const CONNECTION_QUERY_KEY = 'connection';

const InternetConnectionContext = React.createContext<IInternetConnectionContextValue | undefined>(
	undefined
);

// Using the api version endpoint to establish our internet and api connection status
function InternetConnectionProvider({ children }: { children: React.ReactNode }) {
	const connectionQuery = useQuery(CONNECTION_QUERY_KEY, getAPIVersion, {
		enabled: Platform.OS !== 'web',
		refetchInterval: env.isDev ? 5000 : 10000,
	});

	const value = React.useMemo(() => {
		return connectionQuery.status === 'idle'
			? {
					isConnected: undefined,
			  }
			: connectionQuery.status === 'error'
			? {
					isConnected: false,
			  }
			: {
					isConnected: true,
			  };
	}, [connectionQuery.status]);

	return (
		<InternetConnectionContext.Provider value={value}>
			{children}
		</InternetConnectionContext.Provider>
	);
}

function useInternetConnection() {
	const context = React.useContext(InternetConnectionContext);

	if (context === undefined) {
		throw new Error('useInternetConnection must be used with a InternetConnectionProvider');
	}

	return context;
}

export { CONNECTION_QUERY_KEY, InternetConnectionProvider, useInternetConnection };
