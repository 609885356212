import { IActionStep } from '@mobe/api/goals/goalsService';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { IColors } from '@mobe/utils/styles/styleRules/types';
import useLayout from '@mobe/utils/styles/useLayout';
import useColorScheme from '@mobe/utils/useColorScheme';
import { differenceInDays } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GestureResponderEvent,
	Pressable,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
} from 'react-native';
import CompletionButton from './CompletionButton';
import PathwayIcon from './PathwayIcon';
import SubStepCount from './SubStepCount';
import PathwayBlobGraphic from './graphics/PathwayBlobGraphic';
import SubStepsGradientGraphic from './graphics/SubStepsGradientGraphic';

interface IActionStepCardProps {
	actionStepData: IActionStep;
	style?: StyleProp<ViewStyle>;
	isComplete?: boolean;
	onPress?: (event: GestureResponderEvent) => void;
	onCompletePress?: () => void;
}

export default function ActionStepCard({
	actionStepData,
	style,
	isComplete = false,
	onPress = noop,
	onCompletePress = noop,
}: IActionStepCardProps) {
	const colorScheme = useColorScheme();
	const { t } = useTranslation();
	const isActive = actionStepData.status === 'InProgress';
	const lightSchemeIconColor: keyof IColors = isActive ? 'primaryLight' : 'textLight';
	const darkSchemeIconColor: keyof IColors = isActive ? 'background' : 'textLight';
	const shouldDisplayNewBadge =
		actionStepData.status === 'InProgress' &&
		actionStepData.subStepsComplete === 0 &&
		differenceInDays(new Date(Date.now()), new Date(actionStepData.startDate)) < 3;
	const styles = useStyles(isComplete);

	return (
		<View style={[styles.wrapper, style]}>
			<View style={styles.card}>
				<Pressable onPress={onPress} role="button" disabled={isComplete} style={styles.cardBody}>
					<View style={styles.cardBodyRow}>
						<View style={styles.pathway}>
							<PathwayBlobGraphic isComplete={isComplete} />
							{actionStepData.pathwayName !== null && (
								<PathwayIcon
									color={colorScheme === 'light' ? lightSchemeIconColor : darkSchemeIconColor}
									style={[styles.pathwayIcon, { opacity: isActive ? 1 : 0.5 }]}
									pathway={actionStepData.pathwayName}
									size={22}
								/>
							)}
						</View>
						<View style={styles.title}>
							<Text weight={isActive ? 'medium' : 'regular'} color={isActive ? 'regular' : 'light'}>
								{actionStepData.title}
							</Text>
						</View>
					</View>
					{actionStepData.subStepsTotal > 1 && (
						<View style={styles.subSteps}>
							<SubStepCount
								totalCount={actionStepData.subStepsTotal}
								completedCount={actionStepData.subStepsComplete}
								overflowMode="scroll"
								isComplete={isComplete}
							/>
							<View style={styles.subStepsGradient}>
								<SubStepsGradientGraphic />
							</View>
						</View>
					)}
				</Pressable>
				{!isComplete && (
					<CompletionButton
						onPressAnimationComplete={onCompletePress}
						style={styles.cardCompleteButton}
						aria-label={
							actionStepData.subStepsComplete + 1 < actionStepData.subStepsTotal
								? t('plan.actionStepDetail.completeSubStepButton', {
										count: actionStepData.subStepsComplete + 1,
										total: actionStepData.subStepsTotal,
								  })
								: t('plan.actionStepDetail.completeButton')
						}
					/>
				)}
				{shouldDisplayNewBadge && (
					<View style={styles.newBadge}>
						<Text color="inverted" weight="medium" size="sm">
							{t('plan.newBadge')}
						</Text>
					</View>
				)}
			</View>
		</View>
	);
}

function useStyles(isComplete: boolean) {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();
	const { isWebDesktop } = useLayout();
	const completeButtonWidth = 66;

	return StyleSheet.create({
		wrapper: {
			alignSelf: 'center',
			width: '100%',
			flexGrow: 1,
			flexShrink: 1,
		},
		card: {
			flexGrow: 1,
			flexDirection: 'row',
			minHeight: 60,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,

			...(isComplete
				? {
						borderWidth: 1,
						borderColor: rules.colors.strokeLight,
				  }
				: cardShadow),
		},
		cardBody: {
			flexGrow: 1,
			flexShrink: 1,
			flexBasis: 0,
			padding: 8,
			paddingVertical: isWebDesktop ? 26 : 16,
			borderRadius: rules.borderRadius,
			overflow: 'hidden',
		},
		cardBodyRow: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		subSteps: {
			marginTop: 5,
			paddingLeft: 50,
		},
		subStepsGradient: {
			position: 'absolute',
			top: 0,
			left: -8,
			bottom: 0,
			width: 58,
		},
		title: {
			flexShrink: 1,
			flexGrow: 1,
			flexBasis: 0,
		},
		cardCompleteButton: {
			alignItems: 'center',
			justifyContent: 'center',
			width: completeButtonWidth,
			borderLeftWidth: 1,
			borderLeftColor: rules.colors.strokeLight,
		},
		pathway: {
			width: 40,
			marginRight: 10,
		},
		pathwayIcon: {
			position: 'absolute',
			top: 8,
			left: 11,
			width: 25,
			height: 25,
		},
		newBadge: {
			position: 'absolute',
			top: -4,
			right: completeButtonWidth + 6,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.primary,
			paddingHorizontal: 6,
			paddingBottom: 1,
			...cardShadow,
		},
	});
}
