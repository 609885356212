import * as React from 'react';
import Svg, { LinearGradient, Path, Stop, SvgProps } from 'react-native-svg';

function LandscapeGraphic(props: SvgProps) {
	return (
		<Svg {...props}>
			<LinearGradient
				id="a"
				gradientUnits="userSpaceOnUse"
				x1={207}
				y1={41.901}
				x2={207}
				y2={-52.858}
				gradientTransform="translate(0 56)"
			>
				<Stop offset={0} stopColor="#514584" />
				<Stop offset={1} stopColor="#392566" />
			</LinearGradient>
			<Path fill="url(#a)" d="M414 0H0v155h414V0z" />
			<Path
				fill="#41B5E6"
				d="M55.1 19c-2.2 0-4.1 1.9-4.1 4.1s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1c-.1-2.3-1.9-4.1-4.1-4.1zm0 6.8a2.732 2.732 0 0 1-2.7-2.7 2.732 2.732 0 0 1 2.7-2.7c1.5 0 2.6 1.2 2.6 2.7 0 1.5-1.2 2.7-2.6 2.7z"
			/>
			<Path
				fill="#28AA91"
				d="M13.5 40.5c-3 0-5.5 2.5-5.5 5.6s2.5 5.6 5.5 5.6c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.6-5.6-5.6zm0 9.2c-1 0-1.9-.4-2.6-1.1-.7-.6-1-1.6-1-2.5 0-1 .4-1.9 1.1-2.6.7-.7 1.6-1.1 2.6-1.1 2 0 3.6 1.7 3.6 3.7-.1 2-1.7 3.6-3.7 3.6z"
			/>
			<Path fill="#140D2C" d="m367 126.4-.5-5.6h-2.6l-.4 6.2c1.2-.3 2.4-.5 3.5-.6z" />
			<Path
				fill="#302459"
				d="M365.2 99.3c1.4-1.9 2.9-2 3.6.3 1.6 5.1 2.4 10.4 2.3 15.8-.1 4.4-1.7 7.6-6.3 7.5-3.8 0-5-1.8-5.1-6.6-.1-6.1 1.8-12 5.5-17z"
			/>
			<Path
				fill="#302459"
				d="M30.4 102.3c-.7-1.1-1.7-1.9-2.9-2.3 2.6-3.5-2.4-23.8-9.3-22.9-3.6.5-4.3 4.5-4.4 7.9-7.3.5-8.3 13.7-5.1 19-2.3 3.3-2.1 7.7-.3 11.2 13.3 25.4 26.1-6.5 22-12.9z"
			/>
			<Path
				opacity={0.18}
				fill="#C9E9FF"
				d="M390.7 33.8c0-7.1-2-9.2-9.2-9.2 7.2 0 9.2-1.9 9.2-9.2 0 7.1 1.9 9.2 9.2 9.2-7.3.1-9.2 2.1-9.2 9.2zM346 99.1c0-7.1-2-9.2-9.2-9.2 7.2 0 9.2-2 9.2-9.2 0 7.1 1.9 9.2 9.2 9.2-7.1 0-9.2 2.1-9.2 9.2zM35.6 71.8c0-7.1-2-9.2-9.2-9.2 7.2 0 9.2-1.9 9.2-9.2 0 7.1 2 9.2 9.2 9.2-7.3 0-9.2 2.1-9.2 9.2z"
			/>
			<Path
				fill="#F77C0C"
				d="M399.6 46c-4.7 0-8.5 3.7-8.6 8.4 0 4.7 3.7 8.5 8.4 8.6 4.7.1 8.5-3.7 8.6-8.4 0-2.3-.8-4.4-2.4-6.1-1.6-1.6-3.7-2.5-6-2.5zm-.1 14c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5z"
			/>
			<Path fill="none" d="M0 0v132.6c103.4-30.1 219-4.4 285.7 22.4H414V0H0z" />
			<Path fill="none" d="M414 130.2V0H0v155h261.8c40.8-25 93.1-35.5 152.2-24.8z" />
			<Path fill="#352960" d="M261.8 155H414v-24.8c-59.1-10.7-111.4-.2-152.2 24.8z" />
			<LinearGradient
				id="b"
				gradientUnits="userSpaceOnUse"
				x1={157.223}
				y1={168.861}
				x2={148.032}
				y2={124.187}
				gradientTransform="translate(0 56)"
			>
				<Stop offset={0} stopColor="#392e5e" />
				<Stop offset={1} stopColor="#403272" />
			</LinearGradient>
			<Path fill="url(#b)" d="M0 132.6V155h285.7C219 128.1 103.4 102.4 0 132.6z" />
			<Path fill="#140D2C" d="M387.5 119.5h-2.6l-.7 12.2c-.1 2 4.6 1.9 4.4-.1l-1.1-12.1z" />
			<Path
				fill="#302459"
				d="M386.8 87.7c2.2-3 4.5-3.1 5.6.4 2.6 8 3.8 16.4 3.6 24.7-.2 7-2.6 11.9-9.7 11.8-5.9 0-7.7-2.8-7.9-10.3-.2-9.5 2.8-18.8 8.4-26.6z"
			/>
			<Path
				fill="#140D2C"
				d="M14.4 111c1.1 1.9 2.1 3.4 3.2 6l.8-18c.1-2.5 1.1-2.5 1.2 0l.4 10.2c.5-1.2 1.1-2.4 1.7-3.5.1-.1.2-.2.4-.1.1.1.2.2.1.4-3 6.9-1.9 8.5-1.3 26.9.1 2.8-4.2 2.8-4.1 0 .2-12.6 1.8-10.5-3.1-21.6-.1-.1 0-.3.1-.4s.5 0 .6.1z"
			/>
		</Svg>
	);
}

export default LandscapeGraphic;
