import { useAuth } from '@mobe/api/authentication/AuthContext';
import Box from '@mobe/components/box/Box';
import Button from '@mobe/components/button/Button';
import { Callout } from '@mobe/components/callout';
import { InputPassword, InputText, useControlledInputProps } from '@mobe/components/input';
import InputSwitch from '@mobe/components/input/InputSwitch';
import LoadingOverlay from '@mobe/components/loadingOverlay/LoadingOverlay';
import Text from '@mobe/components/text/Text';
import TextButton from '@mobe/components/textButton/TextButton';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import useAddMetaDescription from '@mobe/utils/useAddMetaDescription';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { LoginScreenNavigationProp, LoginScreenRouteProp } from '../AuthenticationStackScreen';
import { obfuscateUser } from '../authUtility';
import AcceptNote from '../components/acceptNote/AcceptNote';
import AuthScreenTemplate from '../components/authScreenTemplate/AuthScreenTemplate';
import { useBioAuthContent } from '../useBioAuthContent';
import EnvLabel from './EnvLabel';
import useLoginController from './loginScreenController';

export interface ILoginScreenProps {
	navigation: LoginScreenNavigationProp;
	route: LoginScreenRouteProp;
}

export default function LoginScreen(props: ILoginScreenProps) {
	const persistentState = usePersistentState();
	const bioAuthContent = useBioAuthContent();
	const auth = useAuth();
	const controller = useLoginController(props);
	const alert = controller.alert();
	const { vr, vrTop } = useStyleHelpers();
	const { t } = useTranslation();
	useAddMetaDescription(t('auth.login.webMetaScreenDescription'));

	const emailInputProps = useControlledInputProps({
		name: 'email',
		control: controller.form.control,
	});
	const passwordInputProps = useControlledInputProps({
		name: 'password',
		control: controller.form.control,
	});
	const [isManuallyEnteringEmail, setIsManuallyEnteringEmail] = React.useState(false);

	function isEmailObfuscated() {
		const hasSavedUsername = persistentState.rememberMe && persistentState.savedUsername;
		return Boolean(
			(props.route.params?.shouldObfuscate || hasSavedUsername) && !isManuallyEnteringEmail
		);
	}

	const onPressChangeEmail = () => {
		setIsManuallyEnteringEmail(true);
		setTimeout(() => {
			controller.form.setFocus('email');
		}, 0);

		controller.onPressChangeEmail();
	};

	// Reset isManuallyEnteringEmail if a username is ever passed as a route param
	React.useEffect(() => {
		if (auth.firstTimeUsername) {
			setIsManuallyEnteringEmail(false);
		}
	}, [auth.firstTimeUsername]);

	return (
		<AuthScreenTemplate
			heading={t('auth.login.heading')}
			alert={alert}
			header={
				<Box row>
					<Text weight="semiBold">{t('auth.common.newParticipant')} </Text>
					<TextButton
						onPress={controller.initiateSignUp}
						title={t('auth.common.newParticipantButton')}
					></TextButton>
				</Box>
			}
		>
			<Text weight="medium" align="center" style={vr(6)}>
				{t('auth.login.description')}
			</Text>

			{alert && alert.alertType === 'standard' ? <Callout message={alert.message} /> : null}

			{isEmailObfuscated() && (
				<InputText
					label={t('auth.login.emailInputLabel')}
					type="email"
					textContentType="username"
					autoComplete="username"
					errorMessage={controller.form.formState.errors.email?.message}
					enterKeyHint="next"
					inputRight={() => (
						<TextButton title={t('auth.login.changeEmailLabel')} onPress={onPressChangeEmail} />
					)}
					disabled={true}
					onSubmitEditing={() => controller.form.setFocus('password')}
					value={obfuscateUser(String(emailInputProps.value))}
				/>
			)}
			{!isEmailObfuscated() && (
				<InputText
					label={t('auth.login.emailInputLabel')}
					type="email"
					textContentType="username"
					autoComplete="username"
					errorMessage={controller.form.formState.errors.email?.message}
					enterKeyHint="next"
					testID="loginUsernameInput"
					onSubmitEditing={() => controller.form.setFocus('password')}
					disabled={props.route.params?.shouldDisable} // Only time it is not obfuscated, but is disabled, is during initial login with email
					{...emailInputProps}
					onChangeText={(text) => {
						if (emailInputProps.onChangeText) {
							// Do not want any spaces on leading edges
							emailInputProps.onChangeText(text.trim());
						}
					}}
				/>
			)}

			<InputPassword
				label={t('auth.login.passwordInputLabel')}
				showVisibilityToggle
				collapseEmptyError
				enterKeyHint="go"
				testID="loginPasswordInput"
				errorMessage={controller.form.formState.errors.password?.message}
				onSubmitEditing={controller.handleSubmitPress}
				{...passwordInputProps}
			/>

			<TextButton
				title={t('auth.login.forgotPasswordButton')}
				align="right"
				style={[vr(5), vrTop(2)]}
				onPress={controller.handleForgotPasswordPress}
			/>

			{Platform.OS !== 'web' ? (
				<View style={vr(5)}>
					<InputSwitch
						control={controller.form.control}
						label={t('auth.login.rememberMeInputLabel')}
						name={'rememberMe'}
						onInputChange={controller.handleOnChangeRememberMe}
					/>
				</View>
			) : null}

			{controller.shouldShowBioAuthSwitch && (
				<View style={vr(5)}>
					<InputSwitch
						control={controller.form.control}
						label={bioAuthContent.settingsLabel}
						onInputChange={controller.handleOnChangeBioAuth}
						name={'useBiometricLogin'}
					/>
				</View>
			)}

			{Boolean(controller.canUseBioAuth && controller.form.getValues().rememberMe) && (
				<TextButton
					align="center"
					title={bioAuthContent.promptMessage}
					style={vr(6)}
					onPress={controller.handleBioAuthLoginPress}
				></TextButton>
			)}

			<Button
				title={t('auth.login.submitButton')}
				style={vr(4)}
				onPress={controller.handleSubmitPress}
			></Button>
			<AcceptNote />

			<EnvLabel />

			<LoadingOverlay visible={controller.isFetching} />
		</AuthScreenTemplate>
	);
}

export function useLoginScreenOptions(): StackNavigationOptions {
	const { t } = useTranslation();

	return { title: t('auth.login.screenTitle'), headerShown: false };
}
