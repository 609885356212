import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { StyleSheet } from 'react-native';

export function useMobeVideoPlayerStyles() {
	const rules = useStyleRules();

	return StyleSheet.create({
		videoPlayerWrapper: {
			overflow: 'hidden',
			position: 'relative',
		},
		videoPlayer: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			borderTopLeftRadius: rules.borderRadius,
			borderTopRightRadius: rules.borderRadius,
			overflow: 'hidden',
		},
		video: {
			position: 'relative',
			borderWidth: 0,
			opacity: 0.99,
		},
		overlay: {
			position: 'absolute',
			width: '100%',
			aspectRatio: 1.6,
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'center',
		},
		playButton: {
			width: 50,
			aspectRatio: 1,
		},
	});
}
