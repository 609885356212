import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import Color from 'color';
import * as React from 'react';
import { ActivityIndicator, Animated, Easing, StyleProp, View, ViewStyle } from 'react-native';

interface IFetchingIndicatorProps {
	children: React.ReactNode;
	isFetching: boolean;
	backgroundColor?: string;
	rerenderOnFetch?: boolean;
	style?: StyleProp<ViewStyle>;
	activityIndicatorStyle?: StyleProp<ViewStyle>;
}

export default function FetchingIndicator({
	children,
	isFetching,
	backgroundColor,
	rerenderOnFetch = false,
	style,
	activityIndicatorStyle,
}: IFetchingIndicatorProps) {
	const styleRules = useStyleRules();
	const overlayAnimation = React.useRef(new Animated.Value(0)).current;
	const [showOverlay, setShowOverlay] = React.useState(false);
	const [dynamicKey, setDynamicKey] = React.useState(0);
	const bgColor = backgroundColor || styleRules.colors.cardBackground;

	React.useEffect(() => {
		if (!isFetching) {
			setDynamicKey(dynamicKey + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching]);

	function fadeInOverlay() {
		setShowOverlay(true);
		Animated.timing(overlayAnimation, {
			useNativeDriver: true,
			toValue: 1,
			duration: 400,
			easing: Easing.out(Easing.quad),
		}).start();
	}

	function fadeOutOverlay() {
		Animated.timing(overlayAnimation, {
			useNativeDriver: true,
			toValue: 0,
			duration: 250,
			easing: Easing.out(Easing.quad),
		}).start(() => setShowOverlay(false));
	}

	React.useEffect(() => {
		if (isFetching) {
			fadeInOverlay();
		} else {
			fadeOutOverlay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching]);

	const overlayOpacity = overlayAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [0, 1],
	});

	return (
		<View style={style}>
			<View key={rerenderOnFetch ? dynamicKey : undefined}>{children}</View>
			{showOverlay && (
				<Animated.View
					style={{
						opacity: overlayOpacity,
						top: 0,
						bottom: 0,
						width: '100%',
						position: 'absolute',
						alignItems: 'center',
						backgroundColor: Color(bgColor).alpha(0.6).string(),
					}}
				>
					<ActivityIndicator
						style={[{ top: '15%' }, activityIndicatorStyle]}
						size="large"
						color={styleRules.colors.primary}
					/>
				</Animated.View>
			)}
		</View>
	);
}
