import * as React from 'react';
import { View } from 'react-native';
import Svg, { Ellipse, G, Path, Rect, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function ActivitiesSurveyGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(264, 264);
	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fillRule="nonzero" fill="none">
					<Path
						d="M132 0c72.902 0 132 59.098 132 132s-59.098 132-132 132S0 204.902 0 132 59.098 0 132 0z"
						fill="#E4F3FF"
					/>
					<G transform="translate(14.807 25.435)">
						<Path fill="#FFF" d="M5.744 2.349h107.041V72.84H5.744z" />
						<Ellipse fill="#EEF2F5" cx={88.766} cy={36.159} rx={23.888} ry={26.369} />
						<Path
							fill="#D6E1E9"
							d="M90.724 22.061v15.925l10.7-11.487-8.876 12.271-.261 18.014 12.792-12.793-12.532 14.881v13.837h-4.7l1.044-24.019-13.054-16.709 13.054 14.1z"
						/>
						<Ellipse fill="#EEF2F5" cx={47.777} cy={46.476} rx={17.231} ry={19.02} />
						<Path
							fill="#D6E1E9"
							d="M49.19 36.307v11.487l7.721-8.286-6.4 8.851-.188 12.994 9.228-9.228-9.039 10.734v9.981h-3.389l.753-17.325-9.416-12.052 9.416 10.169z"
						/>
						<Path
							d="M0 5.221V0h117.745v5.221h-4.177v66.052h4.177v4.961H0v-4.961h4.177V5.221H0zm60.7 63.833h50.126v-29.24H60.7v29.24zm0-32.373h50.126V7.44H60.7v29.241zM6.918 69.054h50.126v-29.24H6.918v29.24zm0-32.373h50.126V7.44H6.918v29.241z"
							fill="#D7E2ED"
						/>
					</G>
					<Path
						d="M226.499 217.81h-4.475a1.115 1.115 0 00-.286-.266l-.891-.572h3.785v-.365h-4.353l-1.3-.838h3.612v-.365h-4.252a5.405 5.405 0 00-2.386-.557H48.134a5.4 5.4 0 00-2.386.557h-5.3v.365h4.656l-1.3.838h-5.4v.365h4.829l-.891.572c-.11.071-.207.161-.286.266h-5.519v.365h5.342c-.052.193-.049.397.008.589.13.467.558.788 1.042.78h178.223c.484.007.912-.313 1.042-.78a1.09 1.09 0 00.008-.589h4.3l-.003-.365z"
						fill="#28507E"
					/>
					<Path
						d="M207.3 32.745a10.444 10.444 0 00-2.287 2.436c-2.434 4.076.944 5.694-3.716 9.8-.7.994.521 1.982.387 3.783-.19 2.5-1.275 5.251-1.067 7.839.11 4.983 5.354 1.947 6.378 5.783.542 2.277 1.493 6.415 2.144 8.808.732 1.978 2.671 7.306 5.1 7.137 1.994-.793 1.729-4.347 2.989-6 8.376-5.631 7.558-2.167 9.664-12.769 1.192-2.981 3.514-5.158 4.182-8.389 1.3-6.042-3.824-10.373-9.042-11.8 0 0 0-.008 0 0-11.242-1.411-9.278-10.257-14.732-6.628z"
						fill="#F29973"
					/>
					<Path
						d="M206.069 60.848c.447.415.768.947.927 1.536.065.193.118.4.168.608a11 11 0 01.133.651c.008.06.021.13.031.2a.005.005 0 00.008 0 8.229 8.229 0 004.351-.109c3.7-1.1 6.215-4.789 7.3-6.6.365-.614 3.019-5.756 1.688-5.387-1.157.323-2.272 1.883-3.614 3.051-2.554 2.224-7.481 6.116-10.991 6.043m-.391-.275c.137.082.268.174.391.275"
						fill="#E37D59"
					/>
					<Path
						d="M73.185 188.732l-5.681 21.03a4.38 4.38 0 01-.9 1.955 3.7 3.7 0 01-2.683.881 10.412 10.412 0 00-2.885.173 2.209 2.209 0 00-1.718 2.061l6.534 1.608c1.555.472 3.176.689 4.8.642a7.562 7.562 0 004.873-2.384c1.693-1.837 2.439-4.483 4.417-6.01 1.569-1.212 3.863-1.69 4.7-3.487.757-1.623-.157-3.641-1.62-4.676-.83-.587-1.875-1.006-2.311-1.925a3.931 3.931 0 01-.072-2.366l1.187-6.691c.124-.7.172-1.581-.435-1.949a1.8 1.8 0 00-1.253-.079c-2.44.44-4.886.879-6.953 1.217z"
						fill="#F29973"
					/>
					<Path
						fill="#040505"
						opacity={0.2}
						d="M84.519 189.802l-2.265 4.326L72 192.076l.916-1.744z"
					/>
					<Path
						d="M78.999 153.296a10.1 10.1 0 00-9.078 8.381c-.254 1.776-.182 3.927-1.678 4.917a3.55 3.55 0 01-3.937-.47 26.984 26.984 0 01-2.918-3.048c-2.99-3.058-7.394-4.139-11.495-5.353-4.101-1.214-8.458-2.959-10.582-6.671a1.911 1.911 0 01-.193-2.031c.52-.688 1.584-.479 2.41-.225a40.767 40.767 0 0031.067-3.133 31.69 31.69 0 006.404 7.633z"
						fill="#F29973"
					/>
					<Path
						fill="#040505"
						opacity={0.2}
						d="M80.142 152.275l-4.839 4.27-6.655-9.433 2.51-2.21z"
					/>
					<Path
						d="M160.557 121.71l-6.1 9.835-12.131 19.558-30.494-4.686-22.041 44.893-18.91.036 11.783-30.683 13.529-35.233 40.551-9.3z"
						fill="#103B5E"
					/>
					<Path
						fill="#040505"
						opacity={0.15}
						d="M160.558 121.709l-6.1 9.835-25.114-3.013-46.678 32.131 13.529-35.233 40.551-9.3z"
					/>
					<Rect
						transform="rotate(-13.874 221.846 -593.963)"
						x={2.655}
						y={0.189}
						width={4.334}
						height={22.672}
						rx={2.167}
						fill="#092C5C"
					/>
					<Path
						d="M170.563 127.952l-41.834-5.415-46.3 36.377-14.291-12.777 49.716-43.567a7.361 7.361 0 016.2-1.7l57.293 10.711-10.784 16.371z"
						fill="#103B5E"
					/>
					<Path
						d="M166.218 66.94c-.114.905-.206 1.464-.206 1.464l-6.671 2.667-1.188-4.831-.121-.163-5.695-7.676v-7.336a16.5 16.5 0 01.959-2.733 1.028 1.028 0 011.909.039l1.136 3.027c3 5.674 6 4 6 4a11.855 11.855 0 011.024-7.511.881.881 0 011.645.273 699.85 699.85 0 001.335 7.57c.738 3.442.206 8.684-.127 11.21z"
						fill="#F9AF9B"
					/>
					<Path
						d="M209.406 73.967s8.21-.9 15.823-8.657c2.687 8.359-2.536 18.824-2.536 18.824l-12.649 41.3-42.664-9.134-4.918-21.373-6.185-25.3 11.354-2.976 11.533 29.391s23.171-19.47 30.242-22.075z"
						fill="#575149"
					/>
					<Path
						d="M153.491 155.938l-3.9 8.856-18.053 51.073h-4.091l15.282-53.652 2.2-6.277z"
						fill="#5990B5"
					/>
					<Path
						d="M207.73 81.244s13.221 8.927-8.6 25.131c-21.821 16.204-33.4 6.283-33.4 6.283s16.865 7.276 34.062-9.591c-.006.001 14.56-10.246 7.938-21.823z"
						fill="#040505"
						opacity={0.15}
					/>
					<Path
						d="M207.064 215.864h-4.091l-10.744-45.186-4.511-14.743h8.559l3.4 15.715z"
						fill="#5990B5"
					/>
					<Path
						d="M153.491 155.938l-3.9 8.856c-2.229-.843-4.758-1.8-6.862-2.579l2.2-6.277h8.562z"
						fill="#040505"
						opacity={0.25}
					/>
					<Path
						d="M233.273 70.237c-.526 17.276-7.233 51.6-7.233 51.6l-29.762-3.72.373-.572 31.88-48.8a2.593 2.593 0 014.382.07c.257.428.383.923.36 1.422z"
						fill="#5A9CC3"
					/>
					<Path
						fill="#040505"
						opacity={0.25}
						d="M199.677 171.653l-7.447-.972-4.511-14.743h8.559z"
					/>
					<Path
						d="M227.995 124.948l-13.476 38.967a6.958 6.958 0 01-7.55 4.613l-78.621-11.141a5.048 5.048 0 01-3.6-7.626l.03-.049 22.177-36.41a7.315 7.315 0 017.207-3.447l70.254 9.29a4.407 4.407 0 013.783 3.717c.1.702.03 1.417-.204 2.086z"
						fill="#5990B5"
					/>
					<Path
						d="M225.822 62.473s-12.837 7.016-16.419 7.911c-3.582.895-2.239 5.224-2.239 5.224s17.6-5.6 18.957-10c1.194-3.881-.299-3.135-.299-3.135z"
						fill="#FFF"
					/>
					<Path
						d="M224.774 34.112c-1.493-7.463-12.24-12.091-18.659-10-6.419 2.091 1.045 11.046 1.045 11.046a33.84 33.84 0 0013.351 7.455c-.016 2.713-3.153 4.389-1.237 7.56.216.359.957 1.1 1.644.987 2.3-.776 3.3-3.411 5.535-4.026a2.158 2.158 0 012.728 1.593c.02.099.03.2.027.3.63 4.068-4.983 4.326-5.142 8.18-.268 4.106 3.409 2.7 4.981.859-.058.146-.094.23-.094.23 4.186-5.65 13.274-22.162-4.179-24.184z"
						fill="#341E12"
					/>
				</G>
			</Svg>
		</View>
	);
}
