import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import Text from '../text/Text';
import useCheckboxStyles from './useCheckboxStyles';

export interface ICheckboxProps extends PressableProps {
	checked: boolean;
	label: string | (() => React.ReactNode);
	disabled?: boolean;
	hasError?: boolean;
}

export default function Checkbox({
	checked,
	disabled = false,
	label,
	...pressableProps
}: ICheckboxProps) {
	const styles = useCheckboxStyles(disabled);

	return (
		<Pressable
			style={styles.container}
			disabled={disabled}
			aria-label={typeof label === 'string' ? label : undefined}
			role="checkbox"
			aria-checked={checked}
			aria-disabled={disabled}
			{...pressableProps}
		>
			<View style={styles.checkbox}>
				{checked && <Ionicons name="checkmark-sharp" style={styles.icon} />}
			</View>
			<View style={styles.label}>
				{typeof label === 'function' ? (
					label()
				) : (
					<Text size="md" weight="semiBold">
						{label}
					</Text>
				)}
			</View>
		</Pressable>
	);
}
