import {
	StackCardInterpolatedStyle,
	StackCardInterpolationProps,
	TransitionPreset,
	TransitionPresets,
} from '@react-navigation/stack';
import { Animated, Easing, Platform } from 'react-native';
import { useAccessibilityInfo } from './useAccessibilityInfo';

const NoTransition: TransitionPreset = {
	gestureDirection: 'vertical',
	transitionSpec: {
		open: { animation: 'timing', config: {} },
		close: { animation: 'timing', config: {} },
	},
	cardStyleInterpolator: () => ({}),
	headerStyleInterpolator: () => ({}),
};

const fastFadeTransition: TransitionPreset = {
	gestureDirection: 'vertical',
	transitionSpec: {
		open: {
			animation: 'timing',
			config: {
				duration: 100,
				easing: Easing.out(Easing.linear),
			},
		},
		close: {
			animation: 'timing',
			config: {
				duration: 100,
				easing: Easing.in(Easing.linear),
			},
		},
	},
	cardStyleInterpolator: ({ current }) => ({
		cardStyle: {
			opacity: current.progress,
		},
	}),
	headerStyleInterpolator: () => ({}),
};

function forModalTransition({
	current,
	inverted,
	layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
	const translateY = Animated.multiply(
		current.progress.interpolate({
			inputRange: [0, 1],
			outputRange: [screen.height, 0],
			extrapolate: 'clamp',
		}),
		inverted
	);

	return {
		cardStyle: {
			transform: [{ translateY }],
		},
		overlayStyle: {
			opacity: current.progress,
		},
	};
}

const ModalTransition: TransitionPreset = {
	...TransitionPresets.ModalPresentationIOS,
	cardStyleInterpolator: forModalTransition,
};

export default function useTransitions() {
	const { reduceMotionEnabled } = useAccessibilityInfo();

	return {
		SlideFromRight:
			Platform.OS === 'web' || reduceMotionEnabled
				? NoTransition
				: TransitionPresets.SlideFromRightIOS,
		ModalTransition:
			Platform.OS === 'web' || reduceMotionEnabled
				? TransitionPresets.ModalFadeTransition
				: ModalTransition,
		DefaultTransition: Platform.OS === 'web' ? NoTransition : fastFadeTransition,
		NoTransition,
	};
}
