import { CoachType } from '@mobe/api/guides/guidesApi';
import Analytics from '@mobe/utils/analytics';

export function guideMatchingExitCancel(coachType?: CoachType) {
	Analytics.logEvent('guide_match_early_exit_cancel', { coachType });
}

export function guideMatchingExitConfirm(coachType?: CoachType) {
	Analytics.logEvent('guide_match_early_exit_confirm', { coachType });
}

export function guideMatchSuccess() {
	Analytics.logEvent('guide_match_success');
}

export function pharmacistInitialScheduleSuccess() {
	Analytics.logEvent('pharmacist_initial_schedule_success');
}

export function guideSchedulingExitCancel(coachType: CoachType) {
	Analytics.logEvent('guide_follow_up_early_exit_cancel', { coachType });
}

export function guideSchedulingExitConfirm(coachType: CoachType) {
	Analytics.logEvent('guide_follow_up_early_exit_confirm', { coachType });
}

export function appointmentFollowUpSuccess(coachType: CoachType) {
	Analytics.logEvent('appt_follow_up_schedule_success', { coachType });
}

export function appointmentAddToCalendar() {
	Analytics.logEvent('appt_add_to_calendar_press');
}

export function appointmentCancelPress() {
	Analytics.logEvent('appt_cancel_press');
}

export function appointmentCancelConfirmation() {
	Analytics.logEvent('appt_cancel_confirmation');
}

export function appointmentInitiateReschedulePress() {
	Analytics.logEvent('appt_initiate_reschedule_press');
}

export function appointmentRescheduled() {
	Analytics.logEvent('appt_rescheduled');
}
