import { useInvalidateGoalsQuery } from '@mobe/api/goals/goalsApiHooks';
import { IGoal } from '@mobe/api/goals/goalsService';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useStyleRules } from '@mobe/utils/styles/styleRules/StyleRulesProvider';
import { IColors } from '@mobe/utils/styles/styleRules/types';
import useColorScheme from '@mobe/utils/useColorScheme';
import { differenceInDays } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	GestureResponderEvent,
	Pressable,
	StyleProp,
	StyleSheet,
	View,
	ViewStyle,
} from 'react-native';
import PathwayIcon from './PathwayIcon';
import GoalCardGraphic from './graphics/GoalCardGraphic';

interface IGoalCardProps {
	goalData: IGoal;
	onPress?: (event: GestureResponderEvent) => void;
	style?: StyleProp<ViewStyle>;
}

export default function GoalCard({ goalData, onPress = noop, style }: IGoalCardProps) {
	const { vr } = useStyleHelpers();
	const { t } = useTranslation();
	const styles = useStyles(goalData);
	const isActive = goalData.goalStatus === 'InProgress';
	const colorScheme = useColorScheme();
	const lightSchemeIconColor: keyof IColors =
		goalData.goalStatus === 'InProgress'
			? goalData.goalType === 'LongTerm'
				? 'textInverted'
				: 'primaryLight'
			: 'textLight';
	const darkSchemeIconColor: keyof IColors = 'background';
	const shouldDisplayNewBadge =
		!goalData.isViewed &&
		goalData.goalStatus === 'InProgress' &&
		differenceInDays(new Date(Date.now()), new Date(goalData.startDate)) < 7;
	const invalidateGoalsQuery = useInvalidateGoalsQuery();

	function handleOnPress(event: GestureResponderEvent) {
		onPress(event);

		if (shouldDisplayNewBadge) {
			invalidateGoalsQuery(goalData.goalType, 'InProgress');
		}
	}

	return (
		<Pressable onPress={handleOnPress} style={[styles.pressable, style]} role="button">
			<View style={styles.card}>
				<View style={styles.cardGraphic}>
					<GoalCardGraphic goalType={goalData.goalType} goalStatus={goalData.goalStatus} />
				</View>
				<View style={styles.pathway}>
					{goalData.pathwayName !== null && (
						<PathwayIcon
							pathway={goalData.pathwayName}
							color={colorScheme === 'light' ? lightSchemeIconColor : darkSchemeIconColor}
							size={25}
							style={{ opacity: isActive ? 1 : 0.5 }}
						/>
					)}
				</View>
				<View style={styles.cardBody}>
					<Text
						size={goalData.goalType === 'LongTerm' ? 'lg' : 'md'}
						weight={goalData.goalType === 'LongTerm' ? 'semiBold' : 'medium'}
						style={vr(1)}
						numberOfLines={6}
					>
						{goalData.title}
					</Text>
				</View>
				{shouldDisplayNewBadge && (
					<View style={styles.newBadge}>
						<Text color="inverted" weight="medium" size="sm">
							{t('plan.newBadge')}
						</Text>
					</View>
				)}
			</View>
		</Pressable>
	);
}

function useStyles(goalData: IGoal) {
	const rules = useStyleRules();
	const { cardShadow } = useStyleHelpers();

	return StyleSheet.create({
		pressable: {
			alignSelf: 'center',
			width: '100%',
			maxWidth: 520,
			flexGrow: 1,
			flexShrink: 1,
		},
		card: {
			flexGrow: 1,
			minHeight: 200,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.cardBackground,
			...cardShadow,

			...(goalData.goalStatus === 'Completed' && {
				borderWidth: 1,
				borderColor: rules.colors.strokeLight,
			}),
		},
		cardBody: {
			padding: 16,
			paddingRight: 12,
			paddingBottom: 50,
		},
		cardGraphic: {
			overflow: 'hidden',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			borderBottomLeftRadius: rules.borderRadius,
			borderBottomRightRadius: rules.borderRadius,
		},
		pathway: {
			position: 'absolute',
			right: 10,
			bottom: 10,
			width: 25,
			height: 25,
		},
		newBadge: {
			position: 'absolute',
			top: -4,
			right: -4,
			borderRadius: rules.borderRadius,
			backgroundColor: rules.colors.primary,
			paddingHorizontal: 6,
			paddingBottom: 1,
			...cardShadow,
		},
	});
}
