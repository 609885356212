import Analytics from '@mobe/utils/analytics';

export function toggleRememberMe(checked: boolean) {
	Analytics.logEvent('settings_remember_me_toggle', { checked });
}

export function toggleBioAuth(checked: boolean) {
	Analytics.logEvent('settings_bio_auth_toggle', { checked });
}

export function lightColorSchemeEnabled() {
	Analytics.logEvent('settings_color_scheme_light');
}

export function darkColorSchemeEnabled() {
	Analytics.logEvent('settings_color_scheme_dark');
}

export function autoColorSchemeEnabled() {
	Analytics.logEvent('settings_color_scheme_auto');
}

export function deleteAccountFeedbackConfirm() {
	Analytics.logEvent('delete_account_feedback_confirm');
}

export function deleteAccountFeedbackCancel() {
	Analytics.logEvent('delete_account_feedback_cancel');
}
