import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

function SvgComponent(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(84, 84);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					fill="#BBE7FB"
					d="M63.242 18.023H20.759a2.425 2.425 0 00-2.406 2.439l2.582 43.127a2.432 2.432 0 002.4 2.388h37.324a2.436 2.436 0 002.4-2.394v-.044l2.58-43.078a2.426 2.426 0 00-2.397-2.438z"
				/>
				<Path
					fill="#BBE7FB"
					d="M50.599 23.95v5.027a2.292 2.292 0 01-.912 1.821c.573-.43.912-1.104.915-1.821v-5.026c0-.76-.376-1.472-1.005-1.9a2.295 2.295 0 011.002 1.899z"
				/>
				<Path
					fill="#639DB4"
					d="M36.908 32.311h11.388a3.34 3.34 0 003.338-3.333v-4.887a3.34 3.34 0 00-3.338-3.333H36.908a3.337 3.337 0 00-3.333 3.333v4.887a3.337 3.337 0 003.333 3.333zm-2.301-8.359a2.254 2.254 0 01.814-1.74c.101-.086.209-.164.323-.233a2.245 2.245 0 011.167-.33h11.391a2.317 2.317 0 012.3 2.3v5.026a2.336 2.336 0 01-2.3 2.3H36.911a2.31 2.31 0 01-2.3-2.3l-.004-5.023z"
				/>
				<Path
					fill="#BBE7FB"
					d="M46.572 31.278h1.721a2.28 2.28 0 001.39-.482 2.285 2.285 0 01-1.392.479l-1.719.003z"
				/>
				<Path
					fill="#828282"
					d="M46.572 31.278h1.721a2.28 2.28 0 001.39-.482 2.285 2.285 0 01-1.392.479l-1.719.003z"
				/>
				<Path
					fill="#BBE7FB"
					d="M36.908 31.279h2.512-2.512a2.3 2.3 0 01-2.299-2.3v-5.026a2.283 2.283 0 01-.002 0v5.027a2.309 2.309 0 002.301 2.299z"
				/>
				<Path
					fill="#828282"
					d="M36.908 31.279h2.512-2.512a2.3 2.3 0 01-2.299-2.3v-5.026a2.283 2.283 0 01-.002 0v5.027a2.309 2.309 0 002.301 2.299z"
				/>
				<Path
					fill="#BBE7FB"
					d="M48.295 21.649c.464-.001.917.138 1.3.4a2.28 2.28 0 00-1.3-.407H36.904a2.245 2.245 0 000 .002l11.391.005z"
				/>
				<Path
					fill="#828282"
					d="M48.295 21.649c.464-.001.917.138 1.3.4a2.28 2.28 0 00-1.3-.407H36.904a2.245 2.245 0 000 .002l11.391.005z"
				/>
				<Path
					fill="#FFF"
					d="M34.607 28.976a2.3 2.3 0 002.3 2.3h2.511a3.588 3.588 0 012.961-3.527c.204-.036.411-.054.618-.054.051 0 .1.011.149.013l.434-1.392.189-.605 1.038-3.33a.076.076 0 01.091-.051l.127.032c.038.01.062.048.055.087l-.777 4.094-.251 1.324c.06.019.123.028.182.05.029.009.057.022.086.032a3.584 3.584 0 012.256 3.324h1.719a2.328 2.328 0 002.3-2.3v-5.027a2.31 2.31 0 00-2.3-2.3H36.907a2.255 2.255 0 00-1.167.328 2.376 2.376 0 00-.322.233 2.283 2.283 0 00-.811 1.74v5.029z"
				/>
				<Path
					fill="#BBE7FB"
					d="M45.847 29.115a3.58 3.58 0 00-1.527-1.161c-.029-.011-.057-.023-.086-.032-.059-.021-.123-.031-.182-.049l-.1.5-.224 1.182-.176.927-.025.13-.005.028v.014a.622.622 0 01-.734.377c-.019-.005-.038-.01-.056-.017-.018-.006-.036-.014-.054-.022a.623.623 0 01-.354-.658l.076-.243.3-.957.338-1.083.106-.342c-.05 0-.1-.013-.149-.013a3.558 3.558 0 00-1.605.381 3.591 3.591 0 00-1.974 3.2h7.158a3.56 3.56 0 00-.727-2.162z"
				/>
				<Path
					fill="#006C9F"
					d="M42.701 29.138l-.3.957-.076.243a.623.623 0 00.354.658c.018.008.036.016.054.022a.614.614 0 00.411.003.622.622 0 00.379-.363v-.014l.005-.028.025-.13.176-.927.224-1.182.1-.5.251-1.324.777-4.094a.074.074 0 00-.055-.087l-.127-.032a.076.076 0 00-.091.051l-1.038 3.33-.189.606-.434 1.392-.106.341-.34 1.078zm.511 1.335a.282.282 0 11-.465-.299.281.281 0 01.305-.067.28.28 0 01.162.361l-.002.005z"
				/>
				<Path
					fill="#FFF"
					d="M42.689 30.271a.27.27 0 000 .216.28.28 0 00.52-.013.28.28 0 00-.155-.363l-.005-.002a.283.283 0 00-.365.161v.001h.005z"
				/>
				<Path
					fill="#9BC3D4"
					d="M46.768 39.537c-.85 4.232 3.1 6.286 3.141 13.536.027 4.142-4.157 8.629.984 9.207 4.128.464 6.009-4.078 6.644-10.268.339-3.3 1.394-7.5.378-11.377-.925-3.527-9.963-7.002-11.147-1.098zM37.889 39.537c-1.184-5.9-10.222-2.428-11.146 1.1-1.018 3.882.038 8.075.377 11.377.635 6.19 2.515 10.732 6.643 10.268 5.142-.578.958-5.064.984-9.207.046-7.253 3.991-9.306 3.142-13.538z"
				/>
			</Svg>
		</View>
	);
}

export default SvgComponent;
