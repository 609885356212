import { useAuth } from '@mobe/api/authentication/AuthContext';
import Analytics from '@mobe/utils/analytics';
import { processEventParams } from '@mobe/utils/analyticsUtils';
import { NavigationContainerRef, Route } from '@react-navigation/native';
import React from 'react';
import { RootStackParamList } from './Navigation';

export default function useNavigationHelper() {
	const currentRouteName = React.useRef('initialization');

	const auth = useAuth();

	function handleNavigationOccurred(currentRouteName: string) {
		// on every navigation (even if it's not a new route), we want to keep the session alive
		auth.onUserActivity();
	}

	function logScreenView(
		currentRouteName: string,
		previousRouteName: string,
		// eslint-disable-next-line @typescript-eslint/ban-types
		route: Route<string, object | undefined> | undefined
	) {
		// log default firebase screen_view
		Analytics.logScreenView(currentRouteName);

		// log mobe custom event so we can pass along route/screen params etc
		Analytics.logEvent(currentRouteName, {
			...processEventParams((route?.params as Record<string, unknown>) || {}),
		});
	}

	return {
		updateCurrentRoute(navigationRef: React.RefObject<NavigationContainerRef<RootStackParamList>>) {
			if (!navigationRef || !navigationRef.current) {
				return;
			}

			const newCurrentRoute = navigationRef.current.getCurrentRoute();

			if (!newCurrentRoute) {
				return;
			}

			// if we get here, a new navigation has occurred (though it may be the same route)
			const newCurrentRouteName = newCurrentRoute.name;

			handleNavigationOccurred(currentRouteName.current);

			if (newCurrentRouteName === currentRouteName.current) {
				return;
			}

			currentRouteName.current = newCurrentRouteName;

			// finally, log events about the current screen/route
			logScreenView(newCurrentRouteName, currentRouteName.current, newCurrentRoute);
		},

		get currentRouteName() {
			return currentRouteName;
		},
	};
}
