import * as React from 'react';
import ParsedText, { ParsedTextProps } from 'react-native-parsed-text';

export default function MobeParsedText(parsedTextProps: ParsedTextProps) {
	return (
		<ParsedText
			{...parsedTextProps}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			parse={parsedTextProps.parse?.map((options, index) => ({
				...options,

				// Adding renderText for web, this is hacky but works on web to give keyboard focus
				// and enter + space keyboard triggering of the onPress handler
				renderText: (matchingString, matches) => {
					return (
						<span
							role="button"
							tabIndex={0}
							onKeyDown={(event) => {
								if (event.key === 'Enter' || event.key === ' ') {
									if (options.onPress) {
										options.onPress(matchingString, index);
									}
								}
							}}
						>
							{matchingString}
						</span>
					);
				},
			}))}
		/>
	);
}
