import env from '@mobe/env/env';
import useBioAuthService from '@mobe/features/auth/useBioAuthService';
import Analytics from '@mobe/utils/analytics';
import { ASYNC_STORAGE_KEY } from '@mobe/utils/asyncStorage';
import crashlytics from '@mobe/utils/crashlytics';
import messaging from '@mobe/utils/messaging';
import sessionStorage from '@mobe/utils/sessionStorage';
import { useAppStateChange } from '@mobe/utils/useAppStateChange';
import useMonitoredPromise from '@mobe/utils/useMonitoredPromise';
import { usePersistentState } from '@mobe/utils/usePersistentState';
import { REMOTE_CONFIG_QUERY_KEY } from '@mobe/utils/useRemoteConfigQuery';
import * as React from 'react';
import { Platform } from 'react-native';
import { useQueryClient } from 'react-query';
import { APIResponse } from '../APIResponse';
import { ExploreQueryKeys } from '../explore/exploreQueryKeys';
import { getAllFeaturedSharedContent } from '../explore/exploreService';
import { CoachesQueryKeys, fetchCoaches } from '../guides/guidesApi';
import { mobeAuthenticatedAPI, mobeStandardSuccessProcessor } from '../mobeAPI';
import { getPermissions } from '../permissions/permissionsService';
import { PERMISSIONS_QUERY_KEY } from '../permissions/types';
import { getAllTrackerTypes } from '../track/trackService';
import { TrackQueryKeys } from '../track/types';
import authenticationService, {
	IAuthenticationData,
	IAuthenticationError,
	IAuthenticationResponse,
	IValidicData,
	LoginAPIErrorCode,
	LogoutType,
} from './authenticationService';

interface IAuthContextValue {
	authenticationData: IAuthenticationData;
	validicData: IValidicData | null;
	hasValidValidicData: boolean;
	deviceToken: string;
	isLoadingUserData: boolean;
	isAuthenticated: boolean;
	forgotPasswordWasSubmitted: boolean;
	userHasResetPassword: boolean;
	resetPasswordToken: string;
	shouldShowBioAuthPrompt: boolean;
	firstTimeUsername: string;
	setFirstTimeUsername: React.Dispatch<React.SetStateAction<string>>;
	firstTimePhoneNumber: string;
	setFirstTimePhoneNumber: React.Dispatch<React.SetStateAction<string>>;
	login: {
		isPending: boolean;
		value: any;
		error: unknown;
		execute: (...args: any[]) => Promise<any>;
	};
	logout: (logoutType?: LogoutType) => Promise<void>;
	triggerSessionExpiration: () => void;
	attemptBioAuthLogin: (
		preLoginCallback?: () => void
	) => Promise<void | APIResponse<
		IAuthenticationResponse,
		LoginAPIErrorCode,
		IAuthenticationError
	>>;
	updateSavedCredentials: (
		username: string | null,
		password: string | null,
		rememberMe: boolean | null
	) => Promise<void>;
	onUserActivity: () => void;
	resetStateVariables: () => void;
}

const AuthContext = React.createContext<IAuthContextValue | undefined>(undefined);

const SESSION_ALIVE_POLL_TIME_IN_MS = 5000;
const WEB_AUTH_DATA_SESSION_STORAGE_KEY = 'authData';

const defaultAuthenticationData: IAuthenticationData = {
	loginMessage: '',
	token: '',
	tokenExpirationTimeInSeconds: -1,
};

/**
 * Variable to track the unix timestamp of the user's last activity for use in determining automatic session logout.
 * No components or functionality should be reacting to this variable, and we don't need consumers of auth
 * to re-render unnecessarily every time this value is updated (which occurs on an interval inside auth),
 * therefore we're using a plain variable to track this.
 */
let lastActivityTime = -1;

function AuthProvider({ children }: { children: React.ReactNode }) {
	const queryClient = useQueryClient();

	// dependent services
	const persistentState = usePersistentState();
	const bioAuthService = useBioAuthService();

	// saved credentials
	const [temporarySavedUsername, setTemporarySavedUsername] = React.useState('');
	const [temporarySavedPassword, setTemporarySavedPassword] = React.useState('');

	const [authenticationData, setAuthenticationData] =
		React.useState<IAuthenticationData>(defaultAuthenticationData);
	const [validicData, setValidicData] = React.useState<IValidicData | null>(null);
	const [deviceToken, setDeviceToken] = React.useState<string>('');

	/**
	 * Exposed state
	 */

	// general user state
	const [isAuthenticated, setIsAuthenticated] = React.useState(false);
	const [isLoadingUserData, setIsLoadingUserData] = React.useState(false);

	// transitional state
	const [forgotPasswordWasSubmitted, setForgotPasswordWasSubmitted] = React.useState(false);
	const [userHasResetPassword, setUserHasResetPassword] = React.useState(false);
	const [resetPasswordToken, setResetPasswordToken] = React.useState('');
	const [shouldShowBioAuthPrompt, setShouldShowBioAuthPrompt] = React.useState(true);
	const [firstTimeUsername, setFirstTimeUsername] = React.useState('');
	const [firstTimePhoneNumber, setFirstTimePhoneNumber] = React.useState('');

	// Trigger user activity when the app returns to active state after being in the background
	useAppStateChange({ onActive: onUserActivity });

	/**
	 * Additional data
	 */

	// Try to authenticate with session storage auth data
	React.useEffect(() => {
		async function trySessionStorageAuth() {
			// Only do this on web
			if (Platform.OS !== 'web') {
				return;
			}

			const existingSessionStorageAuthData: IAuthenticationResponse = JSON.parse(
				sessionStorage.getItem(WEB_AUTH_DATA_SESSION_STORAGE_KEY)
			);

			if (existingSessionStorageAuthData) {
				if (env.isDev) {
					console.log('existing session data being used:', existingSessionStorageAuthData);
				}

				// Use session storage auth data to fake a successful api response
				await postLoginActions(
					'',
					'',
					null,
					null,
					mobeStandardSuccessProcessor(existingSessionStorageAuthData)
				);
			}
		}

		trySessionStorageAuth();
	}, []);

	/**
	 * Context functions
	 */

	const loadAdditionalData = async () => {
		await Promise.all([
			queryClient.prefetchQuery(
				ExploreQueryKeys.AllFeaturedSharedContent,
				getAllFeaturedSharedContent
			),
			queryClient.prefetchQuery(CoachesQueryKeys.Coaches, fetchCoaches),
			queryClient.prefetchQuery(PERMISSIONS_QUERY_KEY, getPermissions),
			queryClient.prefetchQuery(TrackQueryKeys.AllTrackers, getAllTrackerTypes),
		]);
	};

	const setMessagingDeviceToken = async () => {
		await messaging.requestUserPermission();
		const token = await messaging.getToken();

		if (token) {
			authenticationService.setDeviceToken(token);
			setDeviceToken(token);
		}
	};

	/**
	 * Login
	 */
	const login = async (
		username: string,
		password: string,
		rememberMe: boolean | null,
		enableBiometricLogin: boolean | null,

		/**
		 * Callback to run after valid authentication, but before postLoginActions.
		 * Returns boolean to indicate whether or not to enable bio auth
		 */
		bioAuthPrompt?: () => Promise<boolean>
	) => {
		try {
			crashlytics.log('login');
			const response = await authenticationService.login(username, password);

			if (response.success) {
				crashlytics.log('login response success');
				// wrapping any follow-up actions in a try/catch to ensure the login() function returns successfully for any external users
				// that depend on the success of the login call itself
				try {
					let shouldEnableRememberMe = rememberMe;
					let shouldEnableBioAuth = enableBiometricLogin;
					if (bioAuthPrompt !== undefined) {
						shouldEnableBioAuth = await bioAuthPrompt();
						shouldEnableRememberMe = shouldEnableBioAuth || rememberMe;
					}

					await postLoginActions(
						username,
						password,
						shouldEnableRememberMe,
						shouldEnableBioAuth,
						response
					);
				} catch (error) {
					crashlytics.log('login response success error');

					if (error instanceof Error) {
						if (env.isDev) {
							console.warn(error);
						}

						crashlytics.recordError(error, 'login response success, then errors');
					}
				}
			}

			return response;
		} catch (error) {
			if (error instanceof Error) {
				if (env.isDev) {
					console.warn(error);
				}

				crashlytics.recordError(error, 'login error');
			}
		}
	};

	const postLoginActions = async (
		username: string,
		password: string,
		rememberMe: boolean | null,
		enableBiometricLogin: boolean | null,
		loginResponse: APIResponse<IAuthenticationResponse, LoginAPIErrorCode, IAuthenticationError>
	) => {
		const authData = loginResponse.data;

		// Set auth data into local state and web session storage
		setAuthenticationData(authData);
		sessionStorage.setItem(WEB_AUTH_DATA_SESSION_STORAGE_KEY, JSON.stringify(authData));

		setValidicData(authData.validicInfo);

		// set auth token
		mobeAuthenticatedAPI.bearerToken = authData.token;

		// update persistent state
		persistentState.setLoginCount(persistentState.loginCount + 1);

		if (rememberMe !== null) {
			persistentState.setRememberMe(Boolean(rememberMe));
		}

		if (enableBiometricLogin !== null) {
			persistentState.setEnableBiometricLogin(Boolean(enableBiometricLogin));
		}

		// credential management
		updateSavedCredentials(username, password, rememberMe);

		// set isLoadingUserData flag prior to isAuthenticated to prevent potential flashes in UI
		setIsLoadingUserData(true);

		// User is able to log in now so these can be reset
		if (firstTimeUsername) {
			setFirstTimeUsername('');
		}

		if (firstTimePhoneNumber) {
			setFirstTimePhoneNumber('');
		}

		// perform loading of additional crucial user data
		await loadAdditionalData();

		// This flag triggers the initialization of the main app via the top-level Navigation component
		setIsAuthenticated(true);

		// trigger user data loading state off
		setIsLoadingUserData(false);

		// trigger that activity has occurred
		onUserActivity();

		// log login analytics
		Analytics.logLogin({ method: 'app' });

		await setMessagingDeviceToken();

		// Force refetch of remote config
		queryClient.invalidateQueries(REMOTE_CONFIG_QUERY_KEY);
	};

	/**
	 * Bio Auth Login
	 */

	const attemptBioAuthLogin = async (preLoginCallback?: () => void) => {
		const username = persistentState.savedUsername;
		const password = persistentState.savedPassword;

		if (!username || !password) {
			return Promise.resolve();
		}

		const bioAuthWasSuccessful = await bioAuthService.attemptBioAuth();

		if (!bioAuthWasSuccessful) {
			return Promise.resolve();
		}

		if (preLoginCallback) {
			preLoginCallback();
		}

		return await login(username, password, null, null);
	};

	/**
	 * Logout
	 */
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const logout = async (logoutType = LogoutType.Manual) => {
		await authenticationService.logout(logoutType);

		// reset the ability to raise a bio-auth prompt if the user did not manually log out
		if (logoutType !== LogoutType.Manual) {
			setShouldShowBioAuthPrompt(true);
		}

		// set internal data
		setIsAuthenticated(false);
		lastActivityTime = -1;

		// set up/initialize external systems
		mobeAuthenticatedAPI.bearerToken = '';

		contextValue.resetStateVariables();

		// Remove auth data from web session storage
		sessionStorage.removeItem(WEB_AUTH_DATA_SESSION_STORAGE_KEY);

		// Remove queries from RQ cache
		// Omit the below queries, we want to keep these queries cached even between different users
		const omittedQueryKeys = [ASYNC_STORAGE_KEY, REMOTE_CONFIG_QUERY_KEY];

		queryClient.removeQueries({
			predicate: (query) => {
				const queryKey = query.queryKey;

				// Some queries are arrays of values
				if (Array.isArray(queryKey)) {
					return !queryKey.some((value) => omittedQueryKeys.includes(value));
				}

				if (typeof queryKey === 'string') {
					return !omittedQueryKeys.includes(queryKey);
				}

				return false;
			},
		});
	};

	/**
	 * Credential management
	 */
	const updateSavedCredentials = async (
		username: string | null,
		password: string | null,
		rememberMe: boolean | null
	) => {
		// no matter what, if provided, we need to save the credentials to the temporary storage for later use with remember me functionality
		// if a null is provided for a value, we'll skip that to allow partial updating of credentials

		if (username !== null) {
			setTemporarySavedUsername(username);
		}

		if (password !== null) {
			setTemporarySavedPassword(password);
		}

		if (rememberMe === true) {
			persistentState.setSavedUsername(username ? username : temporarySavedUsername);
			persistentState.setSavedPassword(password ? password : temporarySavedPassword);
		} else if (rememberMe === false) {
			persistentState.setSavedUsername('');
			persistentState.setSavedPassword('');
		}
	};

	/**
	 * Session expiration trigger
	 */
	const triggerSessionExpiration = React.useCallback(() => {
		logout(LogoutType.SessionExpired);
	}, [logout]);

	/**
	 * Session expiration determination
	 */
	const checkSessionExpiration = () => {
		const timeSinceLastActivity = Date.now() - lastActivityTime;
		const isSessionExpired = timeSinceLastActivity > env.INACTIVITY_TIMEOUT_IN_MS;

		if (isSessionExpired) {
			triggerSessionExpiration();
		}
	};

	/**
	 * User activity
	 */
	function onUserActivity() {
		lastActivityTime = Date.now();
	}

	// session life poll
	React.useEffect(() => {
		const interval = setInterval(() => {
			if (isAuthenticated) {
				checkSessionExpiration();
			}
		}, SESSION_ALIVE_POLL_TIME_IN_MS);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	/**
	 * Create context value
	 */

	const contextValue = {
		// authenticationData
		get authenticationData() {
			return authenticationData;
		},

		// validicData
		get validicData() {
			return validicData;
		},

		set validicData(value) {
			setValidicData(value);
		},

		get hasValidValidicData() {
			return Boolean(validicData);
		},

		get deviceToken() {
			return deviceToken;
		},

		// isLoadingUserData
		get isLoadingUserData() {
			return isLoadingUserData;
		},

		// isAuthenticated
		get isAuthenticated() {
			return isAuthenticated;
		},

		// forgotPasswordWasSubmitted
		set forgotPasswordWasSubmitted(value) {
			setForgotPasswordWasSubmitted(value);
		},

		get forgotPasswordWasSubmitted() {
			return forgotPasswordWasSubmitted;
		},

		// userHasResetPassword
		set userHasResetPassword(value) {
			setUserHasResetPassword(value);
		},

		get userHasResetPassword() {
			return userHasResetPassword;
		},

		set resetPasswordToken(value) {
			setResetPasswordToken(value);
		},

		get resetPasswordToken() {
			return resetPasswordToken;
		},

		set shouldShowBioAuthPrompt(value) {
			setShouldShowBioAuthPrompt(value);
		},

		get shouldShowBioAuthPrompt() {
			return shouldShowBioAuthPrompt;
		},

		firstTimeUsername,
		setFirstTimeUsername,
		firstTimePhoneNumber,
		setFirstTimePhoneNumber,

		/**
		 * Functionality
		 */

		login: useMonitoredPromise(login),
		logout,
		triggerSessionExpiration,
		attemptBioAuthLogin,
		updateSavedCredentials,
		onUserActivity,
		resetStateVariables() {
			this.forgotPasswordWasSubmitted = false;
			this.userHasResetPassword = false;
		},
	};

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

function useAuth() {
	const context = React.useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth must be used with a AuthProvider');
	}

	return context;
}

export { AuthProvider, useAuth };
