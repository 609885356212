import * as React from 'react';
import { View } from 'react-native';
import Svg, { Circle, Defs, Ellipse, G, Mask, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function ExploreNoResultsGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(288, 288);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<Path
					id="prefix__Path_506-2"
					d="M144 4.9c77.3 0 140 62.3 140 139.1s-62.7 139.1-140 139.1S4 220.9 4 144.1 66.7 4.9 144 4.9z"
					fill="#f1f1ef"
				/>
				<G id="prefix__Group-3" transform="translate(147.752)">
					<Ellipse
						id="prefix__Oval"
						transform="rotate(-74.989 92.64 75.008)"
						cx={92.6}
						cy={75}
						rx={47.8}
						ry={47.1}
						fill="#709fc1"
					/>
					<Ellipse
						id="prefix__Oval-2"
						transform="rotate(-74.989 89.89 68.825)"
						cx={89.9}
						cy={68.8}
						rx={47.8}
						ry={47.1}
						fill="#d8e9f8"
						stroke="#fff"
						strokeWidth={3}
						strokeMiterlimit={4}
					/>
					<G id="prefix__Group_728" transform="translate(24.542 25.318)" opacity={0.59}>
						<Path
							id="prefix__Path_1138"
							d="M65.3 10.5c18.4 0 33.3 14.9 33.3 33.3S83.7 77 65.3 77 32.1 62.1 32.1 43.8 47 10.5 65.3 10.5z"
							fill="#fff"
						/>
						<G id="prefix__Group_728-2-Clipped">
							<Defs></Defs>
							<Mask x={32.1} y={-8.4} width={74.1} height={105.5} id="prefix__mask-4_2_">
								<G>
									<Circle
										id="prefix__path-3_2_"
										cx={65.3}
										cy={43.8}
										r={33.3}
										fillRule="evenodd"
										clipRule="evenodd"
										fill="#fff"
									/>
								</G>
							</Mask>
							<G id="prefix__Group_728-2" opacity={0.6} mask="url(#prefix__mask-4_2_)">
								<G transform="translate(0 -18.913)" id="prefix__Group_572">
									<Path id="prefix__Path_575" d="M61.4 10.5L32.1 116l12-.4 38-94.4z" />
									<Path id="prefix__Path_576" d="M98.6 32.5l-45.2 73.6 5.4.4 47.4-70.4z" />
								</G>
							</G>
						</G>
					</G>
					<Ellipse
						id="prefix__Oval-3"
						transform="rotate(-74.989 89.888 68.823)"
						cx={89.9}
						cy={68.8}
						rx={32.5}
						ry={32.9}
						fill="none"
						stroke="#fff"
						strokeWidth={1}
						strokeMiterlimit={4}
					/>
				</G>
				<G id="prefix__Group-25" transform="translate(104.246 96.958)">
					<Path
						id="prefix__Rectangle"
						d="M84.9 33.5l15-19.2c1.6-2.1 4.7-2.5 6.8-.8 2.1 1.6 2.5 4.7.8 6.8l-15 19.2c-1.6 2.1-4.7 2.5-6.8.8-2-1.6-2.4-4.7-.8-6.8z"
						fill="#d7e3ff"
					/>
					<Path
						id="prefix__Rectangle-2"
						d="M36.5 91.7l51.2-65.5c2.5-3.2 7-3.7 10.2-1.2 3.2 2.5 3.7 7 1.2 10.2l-51.2 65.5c-2.5 3.2-7 3.7-10.2 1.2-3.1-2.5-3.6-7.1-1.2-10.2z"
						fill="#103b5e"
					/>
				</G>
				<Path
					id="prefix__Path-88"
					d="M93.3 181.6l41.6-32.4c.4-.3.7-.5 1.1-.8l21.8-16.2c5.9-4.4 13.6-5.9 20.7-3.9l8.6 2.4 23.7-6.1c1.2-.3 2.4-.1 3.4.5s1.8 1.6 2.1 2.8c.1.2.1.5.1.7.3 3.3-1.1 6.4-3.8 8.3l-7.1 5c4 1.9 5.6 6.7 3.7 10.7-.5 1.1-1.3 2.1-2.3 2.9l-4.4 3.3c3.2 2 4.2 6.2 2.2 9.5-.5.7-1.1 1.4-1.8 1.9l-6.3 4.7c.8 4.1-1.2 8.2-5 9.9l-.9.4c-4.8 2.3-10 3.5-15.4 3.6l-31.1.3-28.3 31.9-22.6-39.4z"
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#ffb391"
				/>
				<Path
					id="prefix__Path-95"
					d="M171.7 136.4l-7.1 3.7 2.1-3.6c1.6-2.7 4.4-4.5 7.5-4.8l12.5-1.1-15 5.8z"
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#ff9b6f"
				/>
				<Path
					d="M110.1 166.7l-77.3 61.9c12.3 15.9 27.9 29.1 45.9 38.5l55.6-63.3c-.5-13.7-8.5-26.1-24.2-37.1z"
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#4db388"
				/>
			</Svg>
		</View>
	);
}
