import { useAsyncStorageMutation, useAsyncStorageQuery } from '@mobe/utils/asyncStorage';

export const HAS_DISMISSED_VISION_CARD_KEY = 'hasDismissedVisionImage';

export function useHasDismissedVisionCardQuery() {
	return useAsyncStorageQuery(HAS_DISMISSED_VISION_CARD_KEY, false, true);
}

export function useHasDismissedVisionCardMutation() {
	return useAsyncStorageMutation<boolean>(HAS_DISMISSED_VISION_CARD_KEY);
}
