import { Button } from '@mobe/components/button';
import Heading from '@mobe/components/heading/Heading';
import ScreenTemplateWithFooter from '@mobe/components/screenTemplate/ScreenTemplateWithFooter';
import { Text } from '@mobe/components/text';
import useStyleHelpers from '@mobe/utils/styles/helpers/styleHelpers';
import { useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
	OnboardingConfirmationScreenNavigationProp,
	OnboardingConfirmationScreenRouteProp,
} from '../OnboardingStackScreen';
import OnboardingConfirmationGraphic from '../components/graphics/OnboardingConfirmationGraphic';
import { useOnboardingContext } from '../useOnboardingContext';

interface IOnboardingConfirmationScreenProps {
	navigation: OnboardingConfirmationScreenNavigationProp;
	route: OnboardingConfirmationScreenRouteProp;
}

export default function OnboardingConfirmationScreen({
	navigation,
}: IOnboardingConfirmationScreenProps) {
	const { constrainText, constrain, vr } = useStyleHelpers();
	const parentNavigation = navigation.getParent();
	const onboardingContext = useOnboardingContext();
	const { t } = useTranslation();

	useFocusEffect(
		React.useCallback(() => {
			if (!parentNavigation) {
				return;
			}

			parentNavigation.setOptions({
				headerBackground: undefined,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
	);

	function handleDonePress() {
		if (onboardingContext === 'activity') {
			navigation.navigate('MENU_STACK', {
				screen: 'BOTTOM_TAB_STACK',
				params: {
					screen: 'PLAN_SCREEN',
				},
			});

			return;
		}

		if (parentNavigation?.canGoBack()) {
			parentNavigation.goBack();
		} else {
			navigation.replace('MENU_STACK');
		}
	}

	return (
		<ScreenTemplateWithFooter
			centered
			fixedPositionFooter
			footer={
				<Button
					title={t('onboarding.confirmationScreen.completeButton')}
					onPress={handleDonePress}
				/>
			}
		>
			<Heading level="h1" align="center" style={[constrainText(340), vr(8)]} accessibilityAutoFocus>
				{t('onboarding.confirmationScreen.heading')}
			</Heading>
			<View style={[constrain(250), vr(8)]}>
				<OnboardingConfirmationGraphic />
			</View>
			<Text align="center" weight="medium" style={constrainText(350)}>
				{t('onboarding.confirmationScreen.description')}
			</Text>
		</ScreenTemplateWithFooter>
	);
}
