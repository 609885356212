import * as React from 'react';
import { Animated, Easing, ViewStyle } from 'react-native';
import { useAccessibilityInfo } from './useAccessibilityInfo';

export default function usePageTransition() {
	const fadeAnim = React.useRef(new Animated.Value(1)).current;
	const translateAnim = React.useRef(new Animated.Value(0)).current;
	const { reduceMotionEnabled, screenReaderEnabled } = useAccessibilityInfo();

	function startFadeOutAnimation(onAnimationComplete?: () => void) {
		if (screenReaderEnabled) {
			typeof onAnimationComplete === 'function' && onAnimationComplete();
			return;
		}

		Animated.parallel([
			Animated.timing(fadeAnim, {
				toValue: 0,
				duration: 200,
				useNativeDriver: true,
			}),
			Animated.timing(translateAnim, {
				toValue: -45,
				duration: 200,
				easing: Easing.in(Easing.quad),
				useNativeDriver: true,
			}),
		]).start(onAnimationComplete);
	}

	function startFadeInAnimation(onAnimationComplete?: () => void) {
		if (screenReaderEnabled) {
			typeof onAnimationComplete === 'function' && onAnimationComplete();
			return;
		}

		translateAnim.setValue(45);

		Animated.parallel([
			Animated.timing(fadeAnim, {
				toValue: 1,
				duration: 200,
				delay: 100,
				useNativeDriver: true,
			}),
			Animated.timing(translateAnim, {
				toValue: 0,
				duration: 200,
				delay: 100,
				easing: Easing.out(Easing.quad),
				useNativeDriver: true,
			}),
		]).start(onAnimationComplete);
	}

	const animatedViewStyles: Animated.WithAnimatedObject<ViewStyle> = !screenReaderEnabled
		? {
				opacity: fadeAnim,
				transform: !reduceMotionEnabled ? [{ translateX: translateAnim }] : undefined,
		  }
		: {};

	return { startFadeOutAnimation, startFadeInAnimation, animatedViewStyles };
}
