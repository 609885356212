import * as React from 'react';
import { View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { createFlexibleGraphicProps } from './utils';

export default function InterestGeneralGraphic(props: SvgProps) {
	const { wrapperStyles, dimensionProps } = createFlexibleGraphicProps(49, 51);

	return (
		<View style={wrapperStyles}>
			<Svg {...dimensionProps} {...props}>
				<G fill="#1A7EAD" fillRule="nonzero">
					<Path d="M20.812 11.154a.585.585 0 01.35.133c.22.179.499.277.786.278a1.21 1.21 0 001.126-.765c.175-.456.04-.97-.34-1.29a3.046 3.046 0 00-1.894-.682c-.715.02-1.406.26-1.969.688-.243.203-.393.49-.419.8-.032.469.229.912.663 1.124.435.211.958.151 1.33-.153l-.259-.289.264.29a.673.673 0 01.362-.134zM28.045 11.282c.1-.08.226-.126.356-.128.131 0 .258.048.356.133.22.178.499.276.786.278h.12c.486-.04.9-.358 1.05-.806.15-.449.009-.94-.36-1.249a3.307 3.307 0 00-1.992-.682 3.021 3.021 0 00-1.89.688 1.152 1.152 0 00-.132 1.666 1.276 1.276 0 001.706.1zM21.053 16.2c-.403.515-.3 1.247.23 1.638a5.114 5.114 0 006.348 0 1.138 1.138 0 00.235-1.638 1.247 1.247 0 00-1.722-.228 2.631 2.631 0 01-3.444 0 1.233 1.233 0 00-1.647.229zM36.885 41.029c.187.25.47.419.787.466h.195c.593.001 1.097-.416 1.188-.983a9.05 9.05 0 00-.453-4.708c-1.172-2.97-3.99-5.035-7.262-5.324a7.323 7.323 0 00-5.58 1.555 6.655 6.655 0 00-3.56-1.555 7.223 7.223 0 00-5.412 1.11 9.26 9.26 0 00-.953.728 1.143 1.143 0 00-.084 1.652 1.23 1.23 0 001.703.075 5.078 5.078 0 014.408-1.26 3.904 3.904 0 012.87 1.665c.233.325.616.52 1.025.52.41 0 .792-.195 1.025-.52.804-1.238 2.41-1.86 4.305-1.666 2.367.204 4.405 1.699 5.247 3.848a6.8 6.8 0 01.339 3.542c-.05.3.027.608.212.855z" />
					<Path d="M33.544 21.342c-.415.002-.8.212-1.016.555-1.815 2.441-4.744 3.876-7.853 3.847-5.74 0-8.088-3.658-8.186-3.808a1.215 1.215 0 00-1.039-.556c-2.905 0-7.25 1.56-9.937 5.952-.872 1.427-8.789 18.144-3.967 22.208 2.578 2.182 8.881 1.893 18.783-.838.536-.247.809-.833.643-1.385-.165-.55-.72-.905-1.308-.836-8.548 2.32-14.725 2.798-16.528 1.277C.416 45.47 5.714 31.552 7.591 28.48c1.507-2.606 4.196-4.373 7.256-4.769.982 1.222 4.059 4.353 9.828 4.353 3.672.01 7.15-1.593 9.455-4.358 3.066.395 5.76 2.164 7.273 4.774 1.877 3.07 7.204 16.99 4.455 19.277-2.991 2.52-15.838-.772-23.76-3.598a1.402 1.402 0 00-.219-.061l-5.706-.916a31.604 31.604 0 01-2.87-3.187c-.12-.75-.855-4.047-.574-5.147 1.114-.732 1.464 2.443 2.158 2.67 0 0 1.625.123 1.946.795.178.409.379.807.603 1.194.236.371.471.743.7 1.077.81 1.177 2.55 1.565 3.818 1.848l.396.09c1.309.31 14.507 1.665 17.141 1.959h.133c.617 0 1.135-.45 1.2-1.044.078-.633-.382-1.21-1.034-1.299-7.56-.76-16.028-1.665-16.826-1.882l-.43-.1c-.62-.139-2.067-.46-2.348-.866a21.22 21.22 0 01-.643-1c-1.039-1.665-2.113-3.33-3.737-2.842-.155.062-.3.144-.43.244v-.1a2.64 2.64 0 00-.804-1.982 2.612 2.612 0 00-1.786-.705 2.291 2.291 0 00-1.637.637 2.14 2.14 0 00-.659 1.584c0 .044.299 5.191.62 5.83.322.638 3.267 4.091 4.133 4.441.081.033.166.056.253.067l5.844.938C24.985 47.703 34.922 51 41.776 51c2.411 0 4.443-.41 5.678-1.46 4.822-4.075-3.095-20.792-3.967-22.208-2.692-4.43-7.038-5.99-9.943-5.99z" />
					<Path d="M13.59 11.659c0 6.43 4.8 11.66 10.7 11.66 5.902 0 10.7-5.236 10.7-11.66S30.193 0 24.29 0c-5.9 0-10.7 5.23-10.7 11.659zm18.984 0c0 5.141-3.714 9.327-8.284 9.327-4.569 0-8.295-4.186-8.295-9.327s3.72-9.327 8.29-9.327c4.57 0 8.283 4.186 8.283 9.327h.006z" />
				</G>
			</Svg>
		</View>
	);
}
